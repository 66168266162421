export * from './api-client'
export * from './query-keys'
export * from './pagination'
export * from './types'

export function getApiErrorMessage(error: unknown) {
    if (error instanceof Error) {
        return error.message
    }

    return 'An error occurred'
}
