import { useCallback } from 'react'

import { useProductFormContext } from './useProductForm'

import { useLanguage } from '~/common/hooks'
import { getTranslation } from '~/tools/utils'

export function useDimensionHelpers() {
    const language = useLanguage()
    const { getValues } = useProductFormContext()

    const getDimensionOptionName = useCallback(
        (dimensionIndex: number, optionId: string | number) => {
            const dimension = getValues('dimensions').at(dimensionIndex)
            const option = dimension?.options.find(({ id }) => id === optionId) ?? ''

            return getTranslation(option, language, 'option')
        },
        [getValues, language]
    )

    return { getDimensionOptionName }
}
