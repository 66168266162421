import { useState, useMemo, useCallback } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import SearchIcon from '@material-ui/icons/Search'

import { useQueryClient } from '@tanstack/react-query'

import UserDeleteConfirm from './user-delete-confirm'
import UserDetailDialog from './user-detail-dialog'
import UserList from './user-list'
import UsersNotFound from './users-not-found'

import { api, queryKeys } from '~/api'
import { useAppSelector } from '~/store'

export default function Users() {
    const accountOwnerId = useAppSelector((state) => state.initial.accountOwner?.id)
    const queryClient = useQueryClient()
    const queryKey = queryKeys.users.accountUserListing(accountOwnerId).queryKey

    const { data, isLoading, error } = api.users.accountUserListing.useQuery(queryKey, {
        params: { id: accountOwnerId },
    })

    const [searchTerm, setSearchTerm] = useState('')
    const [dialog, setDialog] = useState('')
    const [isCreatingUser, setIsCreatingUser] = useState(false)
    const [selectedUserId, setSelectedUserId] = useState(-1)

    const filteredUsers = useMemo(() => {
        if (!data?.body) {
            return []
        } else if (!searchTerm) {
            return data.body
        }

        const text = searchTerm.toLowerCase()
        const regex = new RegExp(text, 'i')

        return data.body.filter((u) => {
            return (
                regex.test(u.first_name) ||
                regex.test(u.last_name) ||
                regex.test(u.email) ||
                regex.test(u.username)
            )
        })
    }, [data?.body, searchTerm])

    const selectedUser = useMemo(() => {
        return data?.body.find((u) => u.id === selectedUserId)
    }, [data?.body, selectedUserId])

    function onEditUserList(user: { id: number }) {
        setIsCreatingUser(false)
        setSelectedUserId(user.id)
        setDialog('user-detail')
    }

    function onDeleteUserList(user: { id: number }) {
        setSelectedUserId(user.id)
        setDialog('user-delete-confirm')
    }

    function onCloseUserDetailDialog(user: unknown) {
        if (user) {
            void queryClient.invalidateQueries({ queryKey })
        }

        setDialog('')
        setSelectedUserId(-1)
    }

    const renderUserList = useCallback(() => {
        if (isLoading) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress size={30} color="secondary" />
                </Box>
            )
        }

        if (error) {
            return (
                <Typography color="error">
                    {error.status === 403
                        ? gettext(error.body.error)
                        : gettext('Something went wrong')}
                </Typography>
            )
        }

        if (!filteredUsers.length) {
            return <UsersNotFound />
        }

        return (
            <UserList users={filteredUsers} onEdit={onEditUserList} onDelete={onDeleteUserList} />
        )
    }, [error, filteredUsers, isLoading])

    return (
        <Box sx={{ p: 4, display: 'grid', gridGap: '1rem' }}>
            <Typography variant="h5">{gettext('Users')}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    gridGap: '1rem',
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <TextField
                    fullWidth
                    placeholder={gettext('Search')}
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="disabled" />
                            </InputAdornment>
                        ),
                        endAdornment: searchTerm ? (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={() => setSearchTerm('')}>
                                    <CancelIcon color="disabled" />
                                </IconButton>
                            </InputAdornment>
                        ) : null,
                    }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                <Box clone sx={{ flexShrink: 0 }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setIsCreatingUser(true)
                            setDialog('user-detail')
                        }}
                    >
                        {gettext('Add user')}
                    </Button>
                </Box>
            </Box>

            {renderUserList()}

            <UserDetailDialog
                open={dialog === 'user-detail'}
                isCreateUser={isCreatingUser}
                user={selectedUser}
                onClose={onCloseUserDetailDialog}
            />

            {selectedUser && (
                <UserDeleteConfirm
                    open={dialog === 'user-delete-confirm'}
                    user={selectedUser}
                    onClose={() => setDialog('')}
                    onExited={() => setSelectedUserId(-1)}
                />
            )}
        </Box>
    )
}
