import { connect } from 'react-redux'

import ChannelInstructions from '../components/channel-instructions'

const mapStateToProps = (state) => ({
    channelAPIURL: state.initial.endpoints.channelAPIURL,
    channelAuthLinkURL: state.initial.endpoints.channelAuthLinkURL,
    language: state.initial.language,
    user: state.initial.user,
})

export default connect(mapStateToProps)(ChannelInstructions)
