import { useEffect, type ChangeEvent } from 'react'

import Button from '@material-ui/core/Button'
import { lighten, makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import Skeleton from '@material-ui/lab/Skeleton'

import { useDebounce } from '@react-hook/debounce'
import usePrevious from '@react-hook/previous'
import classNames from 'classnames'

import { type FilterValue, type Filter } from '~/api/product-api'
import FilterSelect from '~/oakra/components/filter-select'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight: 'var(--products-toolbar-max-height)',
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },
    title: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '80%',
    },
    toolbarItems: {
        display: 'flex',
        flexGrow: 1,
        flexWrap: 'wrap',
        marginTop: 10,
        marginBottom: 10,
    },
    searchBar: {
        width: 150,
        marginTop: 2.5,
        marginBottom: 2.5,
        marginRight: 7,
    },
    statusSelectorSelect: {
        height: 38,
    },
    actions: {
        display: 'flex',
    },
    addButton: {
        flexShrink: 0,
    },
    dropdownButton: {
        height: 38,
        alignSelf: 'center',
        padding: 5,
    },
    dropdownIcon: {
        color: theme.palette.grey[400],
        marginRight: 10,
    },
}))

type Props = {
    numSelected?: number
    page?: number
    count?: number
    loading?: boolean
    filterOptions?: Filter[]
    filterValues: Record<string, unknown>
    onDelete: () => void
    onNewProductClick: () => void
    onMakeActive: () => void
    onMakeInactive: () => void
    onSearchChange: (value: string) => void
    onFilterChange: (field: Filter) => (option: FilterValue | null) => void
    onSelectExclusive: (field: Filter) => (e: ChangeEvent<HTMLInputElement>) => void
}

export default function ProductsToolbar({
    numSelected = 0,
    loading = false,
    filterOptions,
    filterValues,
    onFilterChange,
    onSelectExclusive,
    onNewProductClick,
    onDelete,
    onMakeActive,
    onMakeInactive,
    onSearchChange,
}: Props) {
    const classes = useStyles()

    const [debouncedSearch, setDebouncedSearched] = useDebounce('', 500)
    const lastSearch = usePrevious(debouncedSearch) ?? ''

    useEffect(() => {
        if (lastSearch === undefined || lastSearch === debouncedSearch) {
            return
        }

        onSearchChange(debouncedSearch)
    }, [debouncedSearch, lastSearch, onSearchChange])

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {interpolate(pgettext('Number selected', '%s selected'), [numSelected])}
                    </Typography>
                ) : (
                    <div className={classes.toolbarItems}>
                        {loading &&
                            Array.from({ length: 8 }).map((_, i) => (
                                <Skeleton
                                    key={i}
                                    width={150}
                                    height={36}
                                    className={classes.searchBar}
                                />
                            ))}
                        {filterOptions && (
                            <>
                                <TextField
                                    disabled={loading}
                                    id="search"
                                    type="search"
                                    className={classes.searchBar}
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={gettext('Search products')}
                                    InputProps={{ style: { height: 30, fontSize: 13 } }}
                                    inputProps={{ style: { paddingLeft: 9 } }}
                                    onChange={(e) => setDebouncedSearched(e.target.value)}
                                />
                                {filterOptions.map((field) => (
                                    <FilterSelect
                                        disabled={loading}
                                        key={field.lookup}
                                        field={field}
                                        value={filterValues[field.lookup]}
                                        handleFilterChange={onFilterChange}
                                        handleFilterSelectExclusive={onSelectExclusive}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                )}
            </div>

            {numSelected > 0 ? (
                <div className={classes.actions}>
                    <Button color="secondary" onClick={onMakeActive}>
                        {gettext('Make active')}
                    </Button>
                    <Button color="secondary" onClick={onMakeInactive}>
                        {gettext('Make inactive')}
                    </Button>
                    <Button color="secondary" onClick={onDelete}>
                        {gettext('Delete')}
                    </Button>
                </div>
            ) : (
                <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={classes.addButton}
                    startIcon={<AddIcon />}
                    onClick={onNewProductClick}
                >
                    {gettext('New product')}
                </Button>
            )}
        </Toolbar>
    )
}
