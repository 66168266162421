import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
    dialogPaper: {
        overflowY: 'auto',
        height: '100vh',
    },
    dialogTitleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    dialogTitle: {
        marginLeft: 30,
        marginRight: 30,
    },
    dialogContent: {
        overflowY: 'visible',
        marginLeft: 30,
        marginRight: 30,
    },
    dialogFooter: {
        marginLeft: 30,
        marginRight: 30,
        marginTop: 20,
        marginBottom: 30,
    },
}))

export default function Dialog({ open, title, children, actions, fullScreen = false }) {
    const classes = useStyles()

    return (
        <MuiDialog
            fullWidth
            open={open}
            fullScreen={fullScreen}
            maxWidth="md"
            classes={{ paper: classes.dialogPaper }}
        >
            <div className={classes.dialogTitleContainer}>
                <MuiDialogTitle>{title}</MuiDialogTitle>
            </div>
            <MuiDialogContent className={classes.dialogContent}>{children}</MuiDialogContent>
            <MuiDialogActions className={classes.dialogFooter}>{actions}</MuiDialogActions>
        </MuiDialog>
    )
}

Dialog.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    actions: PropTypes.node.isRequired,
    fullScreen: PropTypes.bool,
}
