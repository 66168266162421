import { connect } from 'react-redux'

import ShippingInfoManager from '../components/shipping-info-manager'

const mapStateToProps = (state) => ({
    pickPackBatchCreateAPIURL: state.initial.endpoints.pickPackBatchCreateAPIURL,
    initShippingInfoAPIURL: state.initial.endpoints.initShippingInfoAPIURL,
    initShippingInfoFieldsAPIURL: state.initial.endpoints.initShippingInfoFieldsAPIURL,
    stores: state.initial.stores,
    userID: state.initial.user.id,
    language: state.initial.language,
})

export default connect(mapStateToProps)(ShippingInfoManager)
