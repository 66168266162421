import Select from 'react-select'

import { useTheme } from '@material-ui/core/styles'

function SelectField({containerStyle, width, ...props}) {
    const theme = useTheme()

    return (
        <Select
            styles={{
                container: (provided) => ({
                    ...provided,
                    marginRight: 7,
                    marginTop: 2.5,
                    marginBottom: 2.5,
                    fontSize: 13,
                    ...containerStyle
                }),
                control: (provided, state) => ({
                    ...provided,
                    minHeight: 30,
                    width: width,
                    borderColor: state.isFocused ? theme.palette.primary.main : 'hsl(0,0%,80%)',
                    boxShadow: state.isFocused ? theme.palette.primary.main : 'none',
                    '&:hover': {
                        borderColor: theme.palette.primary.main,
                    },
                }),
                dropdownIndicator: (provided) => ({
                    ...provided,
                    padding: 4,
                }),
                clearIndicator: (provided) => ({
                    ...provided,
                    padding: 4,
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    padding: '0px 6px',
                }),
                input: (provided) => ({
                    ...provided,
                    margin: 0,
                    padding: 0,
                }),
                placeholder: (provided) => ({
                    ...provided,
                    paddingTop: 1,
                }),
                menu: (provided) => ({
                    ...provided,
                    zIndex: 3,
                }),
            }}

            {...props}
        />
    )
}

export default SelectField
