import { z } from 'zod'

export type PaginatedResponse<T> = {
    count: number
    next: string | null
    previous: string | null
    results: T[]
}

export const PaginatedParameters = z.object({
    orderBy: z.string().optional(),
    limit: z.number().optional(),
    offset: z.number().optional(),
})
export type PaginatedParameters = z.infer<typeof PaginatedParameters>
