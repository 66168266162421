import { copyObject } from '../../tools/utils'
import { LOGGED_IN } from '../actions/login'

const login = (
    state = {
        loggedIn: window.__INITIAL.loggedIn,
    },
    action
) => {
    let newState
    switch (action.type) {
        case LOGGED_IN:
            newState = copyObject(state)
            newState.loggedIn = action.loggedIn
            return newState
        default:
            return state
    }
}

export default login
