import { useAppSelector } from '~/store'

export default function useUser() {
    const user = useAppSelector((state) => state.initial.user)
    if (!user) {
        throw new Error('Cannot get user')
    }

    return user
}
