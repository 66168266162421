import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import CategoryPickerColumn from './category-picker-column'

import { api, type ApiResponses } from '~/api'
import { categoryKeys } from '~/products/api/query-keys-factory'
import { type ProductFormSchema } from '~/products/hooks/useProductForm'
import { useAppSelector } from '~/store'
import { getTranslation } from '~/tools/utils'

type CategoryData = ApiResponses['channels']['categoryDetail']['body']
export type Category = CategoryData & {
    children: CategoryData[]
}

const useStyles = makeStyles({
    scrollControlLeft: {
        display: 'flex',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    scrollControlRight: {
        display: 'flex',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 'auto',
    },
    recentCategories: {
        padding: '0 48px 24px 48px',
        display: 'flex',
        alignItems: 'center',
    },
    recentCategoriesLabel: {
        color: 'gray',
        marginRight: 5,
    },
    categoryContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    categoryContent: {
        display: 'flex',
        flex: '1 1 auto',
        overflowY: 'hidden',
        WebkitOverflowScrolling: 'touch', // Add iOS momentum scrolling.
        '&:first-child': {
            paddingTop: 24,
        },
    },
    spinnerContainer: {
        flex: '1 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '150px 0',
    },
    categoryInnerContainer: {
        minWidth: 'min-content',
        display: 'flex',
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    selectedIndicator: {
        display: 'flex',
    },
    selectedIndicatorLabel: {
        marginRight: 10,
        color: 'gray',
    },
    breadcrumbs: {
        display: 'flex',
        alignItems: 'center',
    },
    breadcrumbSegment: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 2,
    },
})

const SCROLL_STEP = 300

type Props = {
    channel: ProductFormSchema['channels'][0]
    shopsForChannel: Array<{ id: number }>
    display: boolean
    initialCategory?: CategoryData | null
    onClose: () => void
    onAfterClose: () => void
    onCategoryPick: (category: CategoryData) => void
}

export default function CategoryPicker({
    channel,
    shopsForChannel,
    display,
    initialCategory,
    onClose,
    onAfterClose,
    onCategoryPick,
}: Props) {
    const classes = useStyles()
    const language = useAppSelector((state) => state.initial.language)
    const scrollRef = useRef<HTMLDivElement>(null)

    const {
        categories,
        selectedChildCategory,
        isFetchingInitially,
        isFetchingMore,
        selectChildCategory,
    } = useCategoryPicker(channel, shopsForChannel, initialCategory)

    const breadCrumbs = useMemo(() => {
        return categories.slice(1).map((category) => {
            return {
                id: category.category_id,
                name: getTranslation(category, language, 'name'),
            }
        })
    }, [categories, language])

    const isSelected = useCallback(
        (category: Category) => categories.some((c) => c.category_id === category.category_id),
        [categories]
    )

    return (
        <Dialog
            open={display}
            fullWidth={true}
            maxWidth="md"
            TransitionProps={{ onExited: onAfterClose }}
        >
            <DialogTitle>{gettext('Select a category for this product')}</DialogTitle>
            <Divider />
            <div className={classes.categoryContainer}>
                <div className={classes.scrollControlLeft}>
                    <IconButton
                        onClick={() => {
                            scrollRef.current?.scrollTo({
                                left: scrollRef.current.scrollLeft - SCROLL_STEP,
                                behavior: 'smooth',
                            })
                        }}
                    >
                        <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                </div>
                <div ref={scrollRef} className={classes.categoryContent}>
                    {isFetchingInitially && (
                        <div className={classes.spinnerContainer}>
                            <CircularProgress size={25} color="secondary" />
                        </div>
                    )}

                    {categories.map((category) => (
                        <CategoryPickerColumn
                            key={category.category_id}
                            category={category}
                            isLoading={isFetchingMore && !category.children}
                            isSelected={isSelected}
                            onSelectCategory={selectChildCategory}
                        />
                    ))}
                </div>

                <div className={classes.scrollControlRight}>
                    <IconButton
                        onClick={() => {
                            scrollRef.current?.scrollTo({
                                left: scrollRef.current.scrollLeft + SCROLL_STEP,
                                behavior: 'smooth',
                            })
                        }}
                    >
                        <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                </div>
            </div>
            <Divider />
            <DialogActions>
                {selectedChildCategory && (
                    <div className={classes.selectedIndicator}>
                        <Typography className={classes.selectedIndicatorLabel}>
                            {gettext('Selected category')}
                        </Typography>
                        <div className={classes.breadcrumbs}>
                            {breadCrumbs.map((c, index, arr) => {
                                return index < arr.length - 1 ? (
                                    <span key={c.id} className={classes.breadcrumbSegment}>
                                        <Typography style={{ marginRight: 2 }}>{c.name}</Typography>
                                        <ArrowForwardIcon style={{ fontSize: 10 }} />
                                    </span>
                                ) : (
                                    <span key={c.id} className={classes.breadcrumbSegment}>
                                        <Typography>{c.name}</Typography>
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                )}
                <Button onClick={onClose} color="primary">
                    {gettext('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!selectedChildCategory}
                    onClick={() => {
                        if (!selectedChildCategory || !selectedChildCategory.is_leaf) {
                            return
                        }

                        onCategoryPick(selectedChildCategory)
                    }}
                >
                    {gettext('Save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export function useCategoryPicker(
    channel: ProductFormSchema['channels'][0],
    shopsForChannel: Array<{ id: number }>,
    initialCategory?: CategoryData | null
) {
    const language = useAppSelector((state) => state.initial.language)

    const [categories, setCategories] = useState<Category[]>([])
    const [selectedChildCategory, setSelectedChildCategory] = useState(initialCategory ?? null)

    const initialCategoryId = initialCategory?.category_id ?? 0
    const { data: ancestorCategoryData, isFetching: isFetchingAncestors } =
        api.channels.categoryAncestors.useQuery(
            categoryKeys.ancestors(initialCategoryId, channel.id),
            { params: { channelId: channel.id, categoryId: initialCategoryId } },
            { enabled: !!initialCategory }
        )

    // Reset categories when ancestor data changes
    useEffect(() => {
        if (!ancestorCategoryData) {
            return
        }

        const sortedData = ancestorCategoryData.body
            .map((category) => ({
                ...category,
                children: sortByLocaleName(category.children, language, 'name'),
            }))
            .sort((a, b) => a.depth - b.depth)

        setCategories(sortedData)
    }, [ancestorCategoryData, language])

    const categoryId = selectedChildCategory?.category_id ?? 0
    const shopIds = channel.categories_are_shop_level ? shopsForChannel.map((shop) => shop.id) : []
    const { data: childrenCategoryData, isFetching: isFetchingChildrenCategories } =
        api.channels.categoryChildren.useQuery(
            categoryKeys.children(categoryId, channel.id),
            {
                params: { channelId: channel.id, categoryId: categoryId.toString() },
                query: {
                    shop_id: shopIds.join('&shop_id=') || undefined,
                },
            },
            { enabled: !selectedChildCategory?.is_leaf }
        )

    // Synchronize children categories with selected category
    useEffect(() => {
        if (!childrenCategoryData) {
            return
        }

        const childrenCategories = sortByLocaleName(
            childrenCategoryData.body.results,
            language,
            'name'
        )

        setCategories((categories) => {
            if (!selectedChildCategory) {
                return [
                    {
                        category_id: 0,
                        channel: channel.id,
                        depth: 0,
                        id: 0,
                        children: childrenCategories,
                        translations: {},
                        is_leaf: false,
                        updating: false,
                        supports_multiple_stock_units:
                            channel.category?.supports_multiple_stock_units ?? false,
                    },
                ]
            }
            const depthIndex = selectedChildCategory.depth - 1

            return [
                ...categories.slice(0, depthIndex),
                {
                    ...selectedChildCategory,
                    children: selectedChildCategory?.is_leaf ? [] : childrenCategories,
                },
            ]
        })
    }, [
        channel.category?.supports_multiple_stock_units,
        channel.id,
        childrenCategoryData,
        language,
        selectedChildCategory,
    ])

    const selectChildCategory = useCallback(
        (category: Category) => {
            if (selectedChildCategory?.category_id === category.category_id) {
                return
            }

            setSelectedChildCategory(category)
            setCategories((categories) => [...categories.slice(0, category.depth - 1), category])
        },
        [selectedChildCategory?.category_id]
    )

    const isFetchingInitially =
        isFetchingAncestors || (!selectedChildCategory && isFetchingChildrenCategories)

    return {
        categories,
        isFetchingInitially,
        isFetchingMore: !isFetchingInitially && isFetchingChildrenCategories,
        selectedChildCategory,
        selectChildCategory,
    }
}

function sortByLocaleName<T extends { translations: Record<string, Record<string, string>> }>(
    items: T[],
    language: string,
    key: string
) {
    return [...items].sort((a, b) => {
        const aText = getTranslation(a, language, key)
        const bText = getTranslation(b, language, key)
        return aText.localeCompare(bText)
    })
}
