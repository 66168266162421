import React from 'react'

import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import GetAppIcon from '@material-ui/icons/GetApp'

import classNames from 'classnames'

import BaseTable from './table'

export default class LowStockTable extends BaseTable {
    columns = [
        {
            id: 'days_till_empty',
            align: 'center',
            label: gettext('Days till empty'),
            sort: true,
            width: 200,
        },
    ]

    renderToolbarRight = () => {
        const { classes, selected, onAction } = this.props

        return selected.length > 0 ? (
            <Button color="secondary" onClick={() => onAction('disable_low_stock')}>
                {gettext('Disable low stock alert')}
            </Button>
        ) : (
            <Button
                variant="outlined"
                color="primary"
                className={classes.toolbarActionButton}
                onClick={() => onAction('export')}
            >
                <GetAppIcon /> {gettext('Export')}
            </Button>
        )
    }

    renderCells = (row) => {
        const { classes } = this.props

        return (
            <React.Fragment>
                <TableCell className={classNames(classes.tableCell, classes.centerAlign)}>
                    <Tooltip title={`Estimate ${Math.round(row.days_till_empty)} days till empty`}>
                        <div>{Math.round(row.days_till_empty)}</div>
                    </Tooltip>
                </TableCell>
            </React.Fragment>
        )
    }
}
