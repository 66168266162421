import Select from 'react-select'

import PropTypes from 'prop-types'

import { theme } from '../../../../theme'

export default function PermissionsSelectLevel({ onChange, value }) {
    const options = [
        { label: 'Store', value: 'store' },
        { label: 'Shop', value: 'shop' },
    ]

    return (
        <Select
            // className={.selectGroup}
            isDisabled={options.length === 0}
            options={options}
            onChange={onChange}
            styles={{
                // menu: provided => ({ ...provided, zIndex: 9999 }),
                control: (base) => ({
                    ...base,
                    borderColor: theme.palette.secondary.main,
                    width: '270px',
                }),
                option: (base, state) => ({
                    ...base,
                    color: state.isFocused ? '#fff' : '#000',
                    backgroundColor: state.isFocused ? theme.palette.secondary.main : 'transparent',
                }),
            }}
            placeholder={gettext('Add user to group')}
            value={options.find((o) => o.value === value)}
        />
    )
}

PermissionsSelectLevel.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}
