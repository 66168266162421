import { useQuery } from '@tanstack/react-query'

import { productKeys } from '../api/query-keys-factory'

import { productApi } from '~/api/product-api'
import useUser from '~/common/hooks/useUser'

export function useProductFilter() {
    const user = useUser()

    return useQuery({
        queryKey: productKeys.filters(user.id),
        queryFn: () => productApi.getProductFilters(user.id),
    })
}
