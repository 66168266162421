import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import type { Attribute, ChannelAttributeValueName } from './types'

import { useProductFormContext } from '~/products/hooks/useProductForm'
import { guards } from '~/products/utils'

type Props = {
    name: ChannelAttributeValueName
    attribute: Attribute
}

export default function AttributeOption({ name, attribute }: Props) {
    const { control } = useProductFormContext()
    const { field, fieldState } = useController({
        control,
        name,
        rules: {
            required:
                attribute.required && interpolate(gettext('%s is required'), [attribute.name]),
        },
    })
    const optionMap = new Map(attribute.options.map((option) => [option.value, option]))
    const value = useMemo(() => {
        if (guards.isOption(field.value)) {
            return field.value.value
        }

        if (typeof field.value === 'string') {
            return field.value
        }

        return ''
    }, [field.value])

    return (
        <TextField
            select
            fullWidth
            margin="dense"
            variant="outlined"
            size="small"
            value={value}
            label={attribute.name}
            required={attribute.required}
            error={!!fieldState.error}
            helperText={fieldState.error?.message ?? attribute.tip}
            onChange={(e) => {
                const option = optionMap.get(e.target.value)
                if (option) {
                    field.onChange(option)
                }
            }}
        >
            {attribute.options.map((option) => (
                <MenuItem disabled={option.inactive} key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    )
}
