import { connect } from 'react-redux'

import PriceManager from '../components/price-manager'

const mapStateToProps = (state) => ({
    language: state.initial.language,
    user: state.initial.user,
    stores: state.initial.stores,
    productsForUserAPIURL: state.initial.endpoints.productsForUserAPIURL,
    productAPIURL: state.initial.endpoints.productAPIURL,
    stockUnitAPIURL: state.initial.endpoints.stockUnitAPIURL,
    productStockRecordsAPIURL: state.initial.endpoints.productStockRecordsAPIURL,
    userLocationsAPIURL: state.initial.endpoints.userLocationsAPIURL,
    updateStockRecordsAPIURL: state.initial.endpoints.updateStockRecordsAPIURL,
    uploadPricesURL: state.initial.endpoints.uploadPricesURL,
    stockUpdateTemplateURL: state.initial.endpoints.stockUpdateTemplateURL,
    exportPricesURL: state.initial.endpoints.exportPricesURL,
})

export default connect(mapStateToProps)(PriceManager)
