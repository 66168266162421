import { useMutation } from '@tanstack/react-query'
import Cookie from 'js-cookie'
import { z } from 'zod'

import { useAppSelector } from '~/store'

const PresignedImageResponse = z.object({
    filename: z.string(),
    original_filename: z.string(),
    presigned_url: z.string(),
})

type ImagesData = {
    filename: string
    content_type: string
}[]

export class ImageUploadError extends Error {
    constructor(readonly images: Set<string>, message: string) {
        super(message)
    }
}

const getUploadURLs = async (
    uploadImageSignatureAPIURL: string,
    imagesData: ImagesData,
    storeId: number
) => {
    const res = await fetch(uploadImageSignatureAPIURL.replace('store_id', storeId.toString()), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRFToken': Cookie.get('csrftoken') ?? '',
        },
        body: JSON.stringify(imagesData),
    })

    if (!res.ok) {
        throw new ImageUploadError(
            new Set(imagesData.map((image) => image.filename)),
            'Failed to get upload URLs'
        )
    }

    return PresignedImageResponse.array().promise().parse(res.json())
}

export function useUploadImagesMutation(storeId: number) {
    const uploadImageSignatureAPIURL = useAppSelector(
        (state) => state.initial.endpoints.uploadImageSignatureAPIURL
    )

    const { mutate, isLoading } = useMutation({
        mutationFn: async ({
            images,
        }: {
            images: Array<{ name: string; contentType: string; file: File }>
        }) => {
            const payload = images.map(({ name, contentType }) => ({
                filename: name,
                content_type: contentType,
            }))
            const uploadUrlInfos = await getUploadURLs(uploadImageSignatureAPIURL, payload, storeId)
            const uploadInfoByFilename = new Map(
                uploadUrlInfos.map((info) => [info.original_filename, info])
            )

            const results = await Promise.allSettled(
                images.map(async (image) => {
                    const uploadInfo = uploadInfoByFilename.get(image.name)
                    if (!uploadInfo) {
                        throw new Error()
                    }

                    const res = await fetch(uploadInfo.presigned_url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': image.contentType,
                        },
                        body: image.file,
                    })

                    if (!res.ok) {
                        throw new Error()
                    }

                    return {
                        filename: uploadInfo.filename,
                        original_filename: uploadInfo.original_filename,
                    }
                })
            )

            const errors: Set<string> = new Set()
            const success: Map<string, string> = new Map()

            results.forEach((result, index) => {
                if (result.status === 'rejected') {
                    const image = images[index]
                    errors.add(image.name)
                } else {
                    success.set(result.value.original_filename, result.value.filename)
                }
            })

            if (errors.size > 0) {
                throw new ImageUploadError(errors, 'Failed to upload images')
            }

            return success
        },
    })

    return { mutate, isLoading }
}

export class MediaUploadError extends Error {
    constructor(readonly mediaObjs: Set<string>, message: string) {
        super(message)
    }
}

export function useUploadMediaMutation(storeId: number) {
    const uploadImageSignatureAPIURL = useAppSelector(
        (state) => state.initial.endpoints.uploadImageSignatureAPIURL
    )
    const createMediaAPIURL = useAppSelector((state) => state.initial.endpoints.createMediaAPIURL)

    const { mutate, isLoading } = useMutation({
        mutationFn: async ({
            mediaObjs,
        }: {
            mediaObjs: Array<{ name: string; content_type: string; media_type: string; file: File }>
        }) => {
            const payload = mediaObjs.map(({ name, content_type }) => ({
                filename: name,
                content_type: content_type,
            }))
            const uploadUrlInfos = await getUploadURLs(uploadImageSignatureAPIURL, payload, storeId)
            const uploadInfoByFilename = new Map(
                uploadUrlInfos.map((info) => [info.original_filename, info])
            )

            const s3Results = await Promise.allSettled(
                mediaObjs.map(async (media) => {
                    const uploadInfo = uploadInfoByFilename.get(media.name)
                    if (!uploadInfo) {
                        throw new Error()
                    }

                    const res = await fetch(uploadInfo.presigned_url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': media.content_type,
                        },
                        body: media.file,
                    })

                    if (!res.ok) {
                        throw new Error()
                    }

                    return {
                        filename: uploadInfo.filename,
                        original_filename: uploadInfo.original_filename,
                    }
                })
            )

            const errors: Set<string> = new Set()
            const mediaObjsToUpload: Array<{
                originalFilename: string
                filename: string
                mediaType: string
            }> = []

            s3Results.forEach((result, index) => {
                if (result.status === 'rejected') {
                    const media = mediaObjs[index]
                    errors.add(media.name)
                } else {
                    const media = mediaObjs[index]
                    mediaObjsToUpload.push({
                        originalFilename: result.value.original_filename,
                        filename: result.value.filename,
                        mediaType: media.media_type,
                    })
                }
            })

            const createMediaResults = await Promise.allSettled(
                mediaObjsToUpload.map(async (media) => {
                    const res = await fetch(createMediaAPIURL, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'X-CSRFToken': Cookie.get('csrftoken') ?? '',
                        },
                        body: JSON.stringify({
                            file: media.filename,
                            media_type: media.mediaType,
                            store_id: storeId,
                        }),
                    })

                    if (!res.ok) {
                        throw new Error()
                    }
                    const data = await res.json()

                    return {
                        id: data.id,
                        file: data.file,
                    }
                })
            )

            const success: Map<string, { id: string; file: string }> = new Map()

            createMediaResults.forEach((result, index) => {
                const media = mediaObjsToUpload[index]
                if (result.status === 'rejected') {
                    errors.add(media.originalFilename)
                } else {
                    success.set(media.originalFilename, {
                        id: result.value.id,
                        file: result.value.file,
                    })
                }
            })
            if (errors.size > 0) {
                throw new MediaUploadError(errors, 'Failed to upload media')
            }

            return success
        },
    })

    return { mutate, isLoading }
}
