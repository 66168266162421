import React from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'

import { post } from '../../../tools/request'

const styles = () => ({
    dialogRoot: {
        minWidth: 400,
        minHeight: 200,
    },
    loadingIcon: {
        verticalAlign: 'middle',
        marginRight: 8,
    },
})

class CancelSaleButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            cancelReason:
                props.cancelSaleReasons?.length > 0 ? props.cancelSaleReasons[0].value : null,
            showDialog: false,
            error: null,
            loading: false,
        }
    }

    handleChange = (e) => {
        const { value } = e.target
        this.setState({ cancelReason: value })
    }

    handleSubmit = () => {
        const { cancelReason } = this.state
        const { saleId, cancelSaleAPIURL, onSaleCanceled } = this.props

        if (!cancelReason) {
            this.setState({ error: gettext('Please select cancel reason') })
            return
        }

        this.setState({ loading: true, error: null })

        post(cancelSaleAPIURL.replace('sale_pk', saleId), {
            cancel_reason: cancelReason,
        }).then((response) => {
            this.setState({ loading: false })

            if (response.ok) {
                this.setState({ showDialog: false })
                onSaleCanceled && onSaleCanceled(saleId)
            } else {
                this.setState({ error: response.error })
            }
        })
    }

    handleClose = () => {
        this.setState({ showDialog: false, error: null })
    }

    render() {
        const { cancelReason, showDialog, error, loading } = this.state
        const { classes, btnProps, cancelSaleReasons } = this.props

        return (
            <React.Fragment>
                <Button
                    color="secondary"
                    className={classes.button}
                    {...btnProps}
                    onClick={() => this.setState({ showDialog: true })}
                >
                    {gettext('Cancel Order')}
                </Button>

                <Dialog open={showDialog} classes={{ paper: classes.dialogRoot }}>
                    <DialogTitle>{gettext('Select cancel reason')}</DialogTitle>

                    <DialogContent>
                        {error && (
                            <Alert severity="error" style={{ marginBottom: 10 }}>
                                {error}
                            </Alert>
                        )}

                        <TextField
                            select
                            size="small"
                            variant="outlined"
                            value={cancelReason}
                            style={{ width: '100%' }}
                            placeholder={gettext('Select cancel reason')}
                            onChange={this.handleChange}
                        >
                            {cancelSaleReasons.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </DialogContent>

                    <DialogActions>
                        {loading && (
                            <CircularProgress
                                className={classes.loadingIcon}
                                color="secondary"
                                size={16}
                            />
                        )}

                        <Button
                            onClick={this.handleClose}
                            color="secondary"
                            size="small"
                            disabled={loading}
                        >
                            {gettext('Close')}
                        </Button>

                        <Button
                            onClick={this.handleSubmit}
                            variant="contained"
                            color="secondary"
                            disabled={loading}
                            size="small"
                        >
                            {loading ? gettext('Submiting') : gettext('Confirm Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(CancelSaleButton)
