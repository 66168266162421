import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import Select from 'react-select'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import DescriptionIcon from '@material-ui/icons/Description'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'

import Paper from '@mui/material/Paper'
import classNames from 'classnames'
import Cookie from 'js-cookie'

import StockManagerTable from './stock-manager-table'

import './stock-manager.css'
import StockItemFilter from '~/stock/stock-item-filter'
import { useAppSelector } from '~/store'
// Bulk update

const useBulkUpdateStyles = makeStyles((theme) => ({
    dialogRoot: {
        minWidth: 600,
    },
    storeSelectTitle: {
        fontWeight: 500,
        marginBottom: 5,
    },
    storeSelect: {
        marginBottom: 15,
    },
    downloadTemplate: {
        marginBottom: 15,
    },
    dropzone: {
        height: 100,
        backgroundColor: grey[100],
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        overflow: 'hidden',
    },
    dropzoneActive: {
        backgroundColor: grey[400],
    },
    uploadMessage: {
        display: 'flex',
        alignItems: 'center',
    },
    fileIcon: {
        color: grey[500],
        marginRight: 10,
    },
    confirmationSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: 10,
        marginTop: 15,
    },
    uploadButtonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    uploadProgress: {
        color: theme.palette.secondary.main,
        marginRight: 10,
    },
    uploadSummary: {
        border: `1px solid ${grey[500]}`,
        borderRadius: 5,
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    uploadSummaryTitle: {
        fontWeight: 500,
        marginBottom: 10,
    },
    uploadError: {
        color: theme.palette.error.main,
        textAlign: 'center',
        marginTop: 10,
    },
}))

const UPDATE_MODES = {
    ADD: 'add',
    DEDUCT: 'deduct',
    UPDATE: 'update',
}

const UPDATE_MODE_LABELS = {
    [UPDATE_MODES.ADD]: gettext('Add Stock'),
    [UPDATE_MODES.DEDUCT]: gettext('Deduct Stock'),
    [UPDATE_MODES.UPDATE]: gettext('Update Stock'),
}

const UPDATE_MODE_EXPLANATIONS = {
    [UPDATE_MODES.ADD]: gettext(
        'To add stock, download the template CSV file and enter the number of units you want to add to existing stock for each SKU.'
    ),
    [UPDATE_MODES.DEDUCT]: gettext(
        'To deduct stock, download the template CSV file and enter the number of units you want to deduct from existing stock for each SKU.'
    ),
    [UPDATE_MODES.UPDATE]: gettext(
        'To update stock, download the template CSV file and enter the exact new stock level for each SKU.'
    ),
}

const BulkUpdateManager = (props) => {
    const { open, storeOptions, handleClickClose, updateStockAPIURL, stockForStoreURL } = props
    const classes = useBulkUpdateStyles()

    const [updateMode, setUpdateMode] = useState(UPDATE_MODES.ADD)
    const [uploadSummary, setUploadSummary] = useState(null)
    const [selectedStore, setSelectedStore] = useState(null)
    const [fileToUpload, setFileToUpload] = useState(null)
    const [uploading, setUploading] = useState(false)

    const handleTabChange = (_, newValue) => {
        setUpdateMode(newValue)
    }

    const handleDownloadTemplate = () => {
        const url = stockForStoreURL.replace('store_pk', selectedStore.id)
        window.location.href = `${url}?update_mode=${updateMode}`
    }

    const handleSelectStore = (storeOption) => {
        const selectedStore = storeOptions.find((store) => store.id === storeOption.value)
        setSelectedStore(selectedStore)
    }

    const onDrop = (files) => {
        setFileToUpload(files[0])
    }

    const handleUpload = () => {
        setUploading(true)
        setUploadSummary(null)
    }

    useEffect(() => {
        if (uploading) {
            window.analytics.track('Upload stock sheet', {
                page: window.location.href,
                feature: 'stock',
                sub_feature: 'update',
                type: 'bulk update',
                trigger: 'button click',
                store_id: selectedStore.id,
                update_mode: updateMode,
            })

            const url = updateStockAPIURL
                .replace('store_pk', selectedStore.id)
                .replace('update_mode', updateMode)
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': fileToUpload.type,
                    'X-CSRFToken': Cookie.get('csrftoken'),
                },
                body: fileToUpload,
            }).then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        setUploading(false)
                        setUploadSummary(data.summary)
                        setFileToUpload(null)
                    })
                } else {
                    response.json().then((data) => {
                        setUploading(false)
                        setUploadSummary(data.summary)
                    })
                }
            })
        }
    }, [uploading])

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.dialogRoot }}
            TransitionProps={{
                onExited: () => {
                    setUploadSummary(null)
                    setSelectedStore(null)
                },
            }}
        >
            <Tabs value={updateMode} onChange={handleTabChange} centered>
                <Tab value={UPDATE_MODES.ADD} label={UPDATE_MODE_LABELS[UPDATE_MODES.ADD]} />
                <Tab value={UPDATE_MODES.DEDUCT} label={UPDATE_MODE_LABELS[UPDATE_MODES.DEDUCT]} />
                <Tab value={UPDATE_MODES.UPDATE} label={UPDATE_MODE_LABELS[UPDATE_MODES.UPDATE]} />
            </Tabs>
            <DialogTitle>
                {interpolate(gettext('Bulk %s'), [UPDATE_MODE_LABELS[updateMode]])}
            </DialogTitle>
            <DialogContent>
                {!uploadSummary && (
                    <React.Fragment>
                        <DialogContentText>
                            {UPDATE_MODE_EXPLANATIONS[updateMode]}
                        </DialogContentText>
                        <Typography className={classes.storeSelectTitle} variant="body2">
                            {gettext('Select store')}
                        </Typography>
                        <Select
                            className={classes.storeSelect}
                            value={selectedStore}
                            options={storeOptions}
                            onChange={handleSelectStore}
                        />
                        <Button
                            className={classes.downloadTemplate}
                            variant="outlined"
                            startIcon={<GetAppIcon />}
                            size="small"
                            onClick={handleDownloadTemplate}
                            disabled={!selectedStore}
                        >
                            {interpolate(gettext('Download %s Template'), [
                                UPDATE_MODE_LABELS[updateMode],
                            ])}
                        </Button>
                        <Dropzone
                            onDropAccepted={onDrop}
                            maxSize={1000000}
                            accept="text/csv,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/x-csv," // comma at end implies empty string; file type may be empty string on Windows
                        >
                            {({ getRootProps, getInputProps, isDragActive }) => (
                                <div
                                    className={classNames(classes.dropzone, {
                                        [classes.dropzoneActive]: isDragActive,
                                    })}
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <div className={classes.uploadMessage}>
                                        {isDragActive ? (
                                            <Typography
                                                className={classes.dropMessage}
                                                variant="body2"
                                            >
                                                {gettext('Drop file here')}
                                            </Typography>
                                        ) : (
                                            <React.Fragment>
                                                <DescriptionIcon className={classes.fileIcon} />
                                                <Typography variant="body2">
                                                    {gettext('Drop file here or click to select')}
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        {fileToUpload && (
                            <div className={classes.confirmationSection}>
                                <Typography>{fileToUpload.name}</Typography>
                                <div className={classes.uploadButtonContainer}>
                                    <CircularProgress
                                        className={classes.uploadProgress}
                                        size={20}
                                        style={{ opacity: uploading ? 1 : 0 }}
                                    />
                                    <Button
                                        startIcon={<PublishIcon />}
                                        color="primary"
                                        variant="outlined"
                                        onClick={handleUpload}
                                        disabled={uploading || !selectedStore}
                                    >
                                        {gettext('Upload')}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
                {uploadSummary && (
                    <div className={classes.uploadSummary}>
                        <Typography className={classes.uploadSummaryTitle}>
                            {gettext('Update summary')}
                        </Typography>
                        {
                            <Typography>
                                {interpolate(gettext('Updated stock for %s stock units'), [
                                    uploadSummary.successful_updates,
                                ])}
                            </Typography>
                        }
                        {uploadSummary.successful_updates === 0 && (
                            <Typography>{gettext('No quantities changed')}</Typography>
                        )}
                        {uploadSummary.not_found_rows.length > 0 && (
                            <Typography className={classes.uploadError}>
                                {interpolate(
                                    ngettext(
                                        'The SKU in row %s was not found for the store and location you specified',
                                        'The SKUs in rows %s were not found for the store and location you specified',
                                        uploadSummary.not_found_rows.length
                                    ),
                                    [uploadSummary.not_found_rows.join(', ')]
                                )}
                            </Typography>
                        )}
                        {uploadSummary.error_rows.length > 0 && (
                            <Typography className={classes.uploadError}>
                                {interpolate(
                                    gettext(
                                        'The item in rows %s were not updated because of errors. Check the quantity you entered.'
                                    ),
                                    [uploadSummary.error_rows.join(', ')]
                                )}
                            </Typography>
                        )}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClickClose}>
                    {gettext('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

// Download

const useDownloadStyles = makeStyles({
    dialogRoot: {
        minWidth: 600,
        minHeight: 400,
    },
    dialogContentRoot: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    storeSelectorContainer: {
        width: '100%',
    },
    storeSelectTitle: {
        fontWeight: 500,
        marginBottom: 5,
    },
    storeSelect: {
        marginBottom: 15,
    },
})

const DownloadManager = (props) => {
    const { open, storeOptions, handleClickClose, stockForStoreURL } = props
    const classes = useDownloadStyles()

    const [selectedStore, setSelectedStore] = useState(null)

    const handleSelectStore = (storeOption) => {
        const selectedStore = storeOptions.find((store) => store.id === storeOption.value)
        setSelectedStore(selectedStore)
    }

    const handleDownloadStock = () => {
        window.location.href = stockForStoreURL.replace('store_pk', selectedStore.id)
    }

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.dialogRoot }}
            TransitionProps={{
                onExited: () => setSelectedStore(null),
            }}
        >
            <DialogTitle>{gettext('Download stock data')}</DialogTitle>
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <div className={classes.storeSelectorContainer}>
                    <Typography className={classes.storeSelectTitle} variant="body2">
                        {gettext('Select store')}
                    </Typography>
                    <Select
                        className={classes.storeSelect}
                        value={selectedStore}
                        options={storeOptions}
                        onChange={handleSelectStore}
                    />
                </div>
                <Button
                    className={classes.downloadButton}
                    color="secondary"
                    variant="outlined"
                    startIcon={<GetAppIcon />}
                    onClick={handleDownloadStock}
                    disabled={!selectedStore}
                >
                    {gettext('Download stock for store')}
                </Button>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClickClose}>
                    {gettext('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 20,
        flex: '1 0 auto',
    },
    spacer: {
        flex: '1 1 auto',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    inputSection: {
        padding: 20,
        width: '100%',
        display: 'grid',
        gap: 20,
    },
}))

export default function StockManagerPage() {
    const classes = useStyles()

    const { updateStockAPIURL, stockForStoreURL } = useAppSelector(
        (state) => state.initial.endpoints
    )
    const storeOptions = useAppSelector((state) => state.initial.stores).map((store) => ({
        ...store,
        value: store.id,
        label: store.name,
    }))

    const [bulkUpdateOpen, setBulkUpdateOpen] = useState(false)
    const [downloadOpen, setDownloadOpen] = useState(false)

    return (
        <div className={classes.container}>
            <Paper
                sx={{ padding: 2, width: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}
            >
                <div className={classes.titleContainer}>
                    <StockItemFilter />
                    <div className={classes.spacer} />
                    <Button
                        className={classes.bulkUpdateButton}
                        startIcon={<PublishIcon />}
                        color="secondary"
                        onClick={() => {
                            setBulkUpdateOpen(true)
                        }}
                    >
                        {gettext('Bulk update')}
                    </Button>
                    <Button
                        className={classes.downloadButton}
                        startIcon={<GetAppIcon />}
                        color="secondary"
                        onClick={() => {
                            setDownloadOpen(true)
                        }}
                    >
                        {gettext('Download')}
                    </Button>
                </div>

                <StockManagerTable />
            </Paper>

            <BulkUpdateManager
                open={bulkUpdateOpen}
                handleClickClose={() => {
                    setBulkUpdateOpen(false)
                }}
                storeOptions={storeOptions}
                updateStockAPIURL={updateStockAPIURL}
                stockForStoreURL={stockForStoreURL}
            />
            <DownloadManager
                open={downloadOpen}
                storeOptions={storeOptions}
                handleClickClose={() => {
                    setDownloadOpen(false)
                }}
                stockForStoreURL={stockForStoreURL}
            />
        </div>
    )
}
