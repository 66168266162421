import Button from '@material-ui/core/Button'
import grey from '@material-ui/core/colors/grey'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import StoreIcon from '@material-ui/icons/Store'

import { getPath } from '../../oakra/components/base-app'

import AddChannelStepper from './add-channel-stepper'

const styles = (theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    stepper: {
        borderBottom: '1px solid ' + grey[200],
        padding: 20,
    },
    storePicker: {
        marginTop: 50,
        width: '75%',
    },
    selectShopMessage: {
        marginTop: 30,
        textAlign: 'center',
    },
    stores: {
        display: 'flex',
        marginBottom: 50,
        marginTop: 30,
        justifyContent: 'center',
    },
    storeButton: {
        marginRight: 15,
    },
})

function StorePicker(props) {
    const { stores, classes } = props

    const { channel_id } = props.match.params

    return (
        <div className={classes.container}>
            <Paper className={classes.storePicker}>
                <div className={classes.stepper}>
                    <AddChannelStepper activeStep={1} />
                </div>
                <Typography className={classes.selectShopMessage} variant="body1">
                    {gettext('Select the store you want to add a new integration to:')}
                </Typography>
                <div className={classes.stores}>
                    {stores.map((store) => (
                        <Button
                            key={store.id}
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.storeButton}
                            href={getPath('addChannelInstructions')
                                .replace('channel_id', channel_id)
                                .replace('store_id', store.id)}
                        >
                            <StoreIcon />
                            {store.name}
                        </Button>
                    ))}
                </div>
            </Paper>
        </div>
    )
}

StorePicker = withStyles(styles)(StorePicker)

export default StorePicker
