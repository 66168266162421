import { Route, Link, useRouteMatch } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { Groups } from '../components/Groups'
import { Users } from '../components/Users'
import StoreSettings from '../containers/store-settings'

const SETTINGS_PATHS = {
    stores: 'stores',
    users: 'users',
    groups: 'groups',
}

export default function Settings() {
    const match = useRouteMatch()

    return (
        <Box clone sx={{ mt: 3, p: 5 }}>
            <Paper component="section">
                <Typography variant="h5">{gettext('Settings')}</Typography>
                <Box
                    sx={{
                        mt: 4,
                        display: 'flex',
                        minHeight: 350,
                        gridGap: 8,
                    }}
                >
                    <Box
                        clone
                        sx={{
                            width: 200,
                            flexShrink: 0,
                        }}
                    >
                        <List component="nav">
                            <SettingsNavItem
                                to={`${match.url}/` + SETTINGS_PATHS.stores}
                                label={gettext('Stores')}
                            />
                            <SettingsNavItem
                                to={`${match.url}/` + SETTINGS_PATHS.users}
                                label={gettext('Users')}
                            />
                            <SettingsNavItem
                                to={`${match.url}/` + SETTINGS_PATHS.groups}
                                label={gettext('Groups')}
                            />
                        </List>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box sx={{ flexGrow: 1 }}>
                        <Route
                            path={`${match.url}/` + SETTINGS_PATHS.stores}
                            component={StoreSettings}
                        />
                        <Route path={`${match.url}/` + SETTINGS_PATHS.users} component={Users} />
                        <Route path={`${match.url}/` + SETTINGS_PATHS.groups} component={Groups} />
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

function SettingsNavItem({ to, label }) {
    return (
        <Route exact path={to}>
            {({ match }) => (
                <Box clone sx={{ textDecoration: 'none', color: 'inherit' }}>
                    <Link to={to}>
                        <ListItem dense button>
                            <ListItemText
                                primary={label}
                                primaryTypographyProps={{
                                    variant: 'subtitle1',
                                    color: match ? 'primary' : 'textPrimary',
                                }}
                            />
                        </ListItem>
                    </Link>
                </Box>
            )}
        </Route>
    )
}
