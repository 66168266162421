import Cookies from 'js-cookie'

type ImageData = {
    filename: string
    content_type: string
}

export async function getPresignedUrls(url: RequestInfo | URL, imagesData: ImageData[]) {
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRFToken': Cookies.get('csrftoken') ?? '',
        },
        body: JSON.stringify(imagesData),
    })

    return (await res.json()) as Promise<
        {
            presigned_url: string
            original_filename: string
            filename: string
        }[]
    >
}

export async function uploadImageToS3(payload: { presignedUrl: string; file: File }) {
    const { file, presignedUrl } = payload
    await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
            'Content-Type': file.type,
        },
        body: file,
    })
}
