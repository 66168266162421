import { useState, useMemo, type ReactNode, type ChangeEvent } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import SnackbarAlert from '../../../oakra/components/snackbar-alert'

import { api } from '~/api'
import { useAppSelector } from '~/store'
import { formatDate } from '~/utils/date'

const ACTIONS = {
    retail_price_list_code: 'sync_retail_prices_from_system',
    marketplace_price_list_code: 'sync_marketplace_prices_from_system',
    branch_code: 'sync_stock_from_system',
} as const

type ActionKey = keyof typeof ACTIONS

export function JaymartSettings() {
    const [showMessage, setShowMessage] = useState(false)
    const staticRoot = useAppSelector((state) => state.initial.staticRoot)
    const { data, error, status } = api.integrations.jaymart.settings.useQuery([
        'settings',
        { name: 'Jaymart' },
    ])

    const [settings, setSettings] = useState({} as Record<string, string>)
    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        setSettings((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }))
    }

    function isSettingSyncing(field: ActionKey) {
        return settings[`${ACTIONS[field]}_status`] === 'syncing'
    }

    const syncStatusData = useMemo(() => {
        if (!data) {
            return undefined
        }

        return Object.entries(ACTIONS).reduce(
            (result, [key, value]) => {
                const status = settings[`${value}_status`] ?? data.body[`${value}_status`]
                const lastUpdatedTime = data.body[`${value}_last_updated_time`]
                const message = data.body[`${value}_message`]

                result[key as ActionKey] = {
                    status: typeof status === 'string' ? status : '',
                    text: getSyncStatusText(
                        typeof status === 'string' ? status : undefined,
                        typeof lastUpdatedTime === 'string' ? lastUpdatedTime : undefined,
                        typeof message === 'string' ? message : undefined
                    ),
                    shouldShowIndicator: !!status,
                }

                return result
            },
            {} as Record<ActionKey, { status: string; text: string; shouldShowIndicator: boolean }>
        )
    }, [data, settings])

    const { mutate } = api.integrations.jaymart.updateSetting.useMutation()
    function updateSetting(field: ActionKey) {
        if (!data) {
            return
        }

        const setting = settings[field] ?? data.body[field]
        mutate(
            {
                body: {
                    settings: {
                        [field]: setting,
                    },
                },
            },
            {
                onSuccess() {
                    setSettings((prev) => ({
                        ...prev,
                        [`${ACTIONS[field]}_status`]: 'syncing',
                    }))
                    setShowMessage(true)
                },
            }
        )
    }

    const { mutate: syncStock, status: syncStockStatus } =
        api.integrations.jaymart.syncStock.useMutation({ onSuccess: () => setShowMessage(true) })

    return (
        <Paper sx={{ minWidth: 800, margin: '0 auto', mt: 2.5, padding: 2.5, width: '60%' }}>
            <SnackbarAlert
                autoHideDuration={6000}
                open={showMessage}
                severity="success"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setShowMessage(false)}
            >
                {gettext('System is syncing')}
            </SnackbarAlert>

            <Stack spacing={2}>
                <Box sx={{ height: 30 }}>
                    <img height="100%" src={staticRoot + 'web/img/jaymart.png'} />
                </Box>

                {status === 'loading' &&
                    Array.from({ length: 4 }).map((_, index) => (
                        <SettingRow
                            key={index}
                            label={<Skeleton variant="text" height={32} width={200} />}
                            input={<Skeleton height={64} />}
                            action={<Skeleton height={64} width={200} />}
                        />
                    ))}
                {status === 'error' && (
                    <Typography align="center" color="error">
                        {error.body instanceof Error ? error.body.message : error.body}
                    </Typography>
                )}
                {status === 'success' && (
                    <Stack gap={1}>
                        {/* <SettingRow
                            label={gettext('Retail price list')}
                            input={
                                <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="retail_price_list_code"
                                    value={
                                        settings.retail_price_list_code ??
                                        data.body.retail_price_list_code
                                    }
                                    disabled={isSettingSyncing('retail_price_list_code')}
                                    onChange={handleChange}
                                >
                                    {data.body.price_lists.map((item) => (
                                        <MenuItem
                                            key={item.pricelistCode}
                                            value={item.pricelistCode}
                                        >
                                            {item.pricelistCode} - {item.pricelistDesc}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }
                            action={
                                <SettingAction
                                    actionLabel={gettext('Save and sync')}
                                    status={syncStatusData?.retail_price_list_code}
                                    disabled={isSettingSyncing('retail_price_list_code')}
                                    onClick={() => updateSetting('retail_price_list_code')}
                                />
                            }
                        /> */}

                        {/* <SettingRow
                            label={gettext('Market place price list')}
                            input={
                                <TextField
                                    select
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    name="marketplace_price_list_code"
                                    value={
                                        settings.marketplace_price_list_code ??
                                        data.body.marketplace_price_list_code
                                    }
                                    disabled={isSettingSyncing('marketplace_price_list_code')}
                                    onChange={handleChange}
                                >
                                    {data.body.price_lists.map((item) => (
                                        <MenuItem
                                            key={item.pricelistCode}
                                            value={item.pricelistCode}
                                        >
                                            {item.pricelistCode} - {item.pricelistDesc}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }
                            action={
                                <SettingAction
                                    actionLabel={gettext('Save')}
                                    status={syncStatusData?.marketplace_price_list_code}
                                    disabled={isSettingSyncing('marketplace_price_list_code')}
                                    onClick={() => updateSetting('marketplace_price_list_code')}
                                />
                            }
                        /> */}

                        <SettingRow
                            label={gettext('Sub-inventory code')}
                            input={
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    value={settings.branch_code ?? data.body.branch_code}
                                    placeholder={gettext('Input sub-inventory code')}
                                    name="branch_code"
                                    disabled={isSettingSyncing('branch_code')}
                                    onChange={handleChange}
                                />
                            }
                            action={
                                <SettingAction
                                    actionLabel="Save and sync"
                                    status={syncStatusData?.branch_code}
                                    disabled={isSettingSyncing('branch_code')}
                                    onClick={() => updateSetting('branch_code')}
                                />
                            }
                        />

                        <SettingRow
                            label={gettext('Sync stock')}
                            input={
                                <Stack direction="row" alignItems="center" gap={2}>
                                    <SettingButton
                                        disabled={
                                            syncStockStatus === 'loading' ||
                                            syncStockStatus === 'success'
                                        }
                                        onClick={() => syncStock({})}
                                    >
                                        {gettext('Sync')}
                                    </SettingButton>
                                    <Typography color="textSecondary" variant="caption">
                                        {syncStockStatus === 'success' &&
                                            getSyncStatusText('syncing')}
                                        {syncStockStatus === 'idle' &&
                                            typeof data.body
                                                .sync_stock_from_system_last_updated_time ===
                                                'string' &&
                                            getSyncStatusText(
                                                'synced',
                                                data.body.sync_stock_from_system_last_updated_time
                                            )}
                                    </Typography>
                                </Stack>
                            }
                        />
                    </Stack>
                )}
            </Stack>
        </Paper>
    )
}

function SettingRow({
    label,
    input,
    action,
}: {
    label: ReactNode
    input?: ReactNode
    action?: ReactNode
}) {
    return (
        <Grid container spacing={3}>
            <Box
                item
                component={Grid}
                sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}
                xs={3}
            >
                <FormLabel>{label}</FormLabel>
            </Box>
            <Grid item xs={6}>
                {input}
            </Grid>
            <Grid item xs={3}>
                {action}
            </Grid>
        </Grid>
    )
}

function SettingAction({
    actionLabel,
    status,
    disabled,
    onClick,
}: {
    actionLabel: string
    status: { shouldShowIndicator: boolean; text: string; status: string } | undefined
    disabled?: boolean
    onClick: () => void
}) {
    return (
        <Tooltip title={status?.text ?? ''}>
            <Stack direction="row" alignItems="center" gap={1}>
                <SettingButton disabled={disabled} onClick={onClick}>
                    {actionLabel}
                </SettingButton>
                {status?.shouldShowIndicator && status.text && (
                    <StatusIndicator status={status.status} />
                )}
            </Stack>
        </Tooltip>
    )
}

function SettingButton(props: { children: ReactNode; disabled?: boolean; onClick?: () => void }) {
    return (
        <Button fullWidth sx={{ maxWidth: 200 }} variant="outlined" color="secondary" {...props} />
    )
}

function StatusIndicator({ status }: { status: string }) {
    return (
        <Box flexShrink={0}>
            {status === 'syncing' && (
                <CircularProgress variant="indeterminate" size={16} color="secondary" />
            )}

            {status === 'synced' && (
                <Box
                    sx={{
                        bgcolor: 'primary.main',
                        width: 16,
                        height: 16,
                        borderRadius: 2,
                    }}
                />
            )}

            {status === 'error' && (
                <Box
                    sx={{
                        bgcolor: 'error.main',
                        width: 16,
                        height: 16,
                        borderRadius: 2,
                    }}
                />
            )}
        </Box>
    )
}

function getSyncStatusText(status?: string, time?: string, message?: string) {
    const timeStr = time ? formatDate(time, 'YYYY-MM-DD hh:mm') : ''

    switch (status) {
        case 'syncing':
            return gettext('System is syncing. Please reload this page after a few moments.')
        case 'synced':
            return interpolate(gettext('Successfully synced at %s'), [timeStr])
        case 'error':
            return interpolate(gettext('Error syncing at %s: %s'), [timeStr, message])
        default:
            return ''
    }
}
