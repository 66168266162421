import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Timeline from '@material-ui/lab/Timeline'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'

import classNames from 'classnames'
import moment from 'moment'

import { commonStyles } from '../sale'

const HistoryTimeLine = withStyles({
    root: {
        padding: '6px 8px',
    },
})(Timeline)

// Sale History
const historySyles = (theme) => ({
    ...commonStyles,
})

let SaleHistory = ({ classes, saleEvents, saleStatusMap }) => {
    return (
        <Paper className={classNames(classes.basicInfo, classes.section)}>
            <Typography variant="subtitle1" className={classes.sectionTitle}>
                {gettext('History')}
            </Typography>
            <SaleHistoryTimeline saleEvents={saleEvents} saleStatusMap={saleStatusMap} />
        </Paper>
    )
}

SaleHistory = withStyles(historySyles)(SaleHistory)
export default SaleHistory

const saleHistoryTimeLineStyles = (theme) => ({
    ...commonStyles,
    status: {
        fontSize: '0.8rem',
    },
    timestamp: {
        fontSize: '0.8rem',
    },
})

let SaleHistoryTimeline = ({ classes, saleEvents, saleStatusMap }) => (
    <HistoryTimeLine align="left">
        {saleEvents &&
            saleEvents.map((saleEvent, i) => (
                <TimelineItem key={i}>
                    <TimelineOppositeContent>
                        <Typography className={classes.status}>
                            {saleStatusMap.get(saleEvent.status)}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot variant="outlined" color="primary" />
                        {(i + 1) % saleEvents.length ? <TimelineConnector /> : undefined}
                    </TimelineSeparator>
                    <TimelineContent className={classes.bodyText}>
                        <Typography className={classes.timestamp}>
                            {moment(saleEvent.date).format('ll HH:mm')}
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
            ))}
    </HistoryTimeLine>
)
SaleHistoryTimeline = withStyles(saleHistoryTimeLineStyles)(SaleHistoryTimeline)
