import { type SetStateAction, useCallback, useState, type Dispatch, useMemo } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

import { useQueryClient } from '@tanstack/react-query'
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table'

import Dialog from '../../../components/Dialog'
import FormSearchField from '../../../components/FormSearchField'
import { useTableStyles } from '../../../theme'
import { UsersNotFound } from '../Users'
import { filterUsers } from '../utils'

import { api, queryKeys } from '~/api'
import { useAppSelector } from '~/store'

type User = {
    id: number
    email: string
    first_name: string | null
    last_name: string | null
    username: string | null
}

const helper = createColumnHelper<User>()
const columns = [
    helper.display({
        id: 'Add user',
        header: ({ table }) => (
            <Checkbox
                indeterminate={table.getIsSomeRowsSelected()}
                checked={table.getIsAllRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
            />
        ),
        cell: ({ row }) => (
            <Checkbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
        ),
        size: 42,
    }),
    helper.accessor((row) => `${row?.first_name ?? ''} ${row?.last_name ?? ''}`, {
        header: gettext('Name'),
    }),
    helper.accessor('username', { header: gettext('Username') }),
    helper.accessor('email', { header: gettext('Email') }),
]

type Props = {
    open: boolean
    group: { id: number; name: string; members: Array<{ id: number }> }
    onClose: () => void
}

export default function GroupAddUsers({ open = false, group, onClose }: Props) {
    const accountOwnerId = useAppSelector((state) => state.initial.accountOwner.id)
    const queryClient = useQueryClient()
    const { data, isLoading, error } = api.users.accountUserListing.useQuery(
        queryKeys.users.accountUserListing(accountOwnerId).queryKey,
        { params: { id: accountOwnerId } },
        {
            enabled: open,
            select: (data) => {
                const existingGroupMembers = new Set(group.members.map(({ id }) => id))

                return {
                    ...data,
                    body: data.body.filter((user) => !existingGroupMembers.has(user.id)),
                }
            },
        }
    )

    const { mutate, isLoading: isAddingUsersToGroup } = api.groups.addUsers.useMutation({
        onSuccess: async () => {
            await queryClient.invalidateQueries(queryKeys.groups.detail(group.id).queryKey)

            setUserIdSelection({})
            onClose()
        },
    })

    const [searchTerm, setSearchTerm] = useState('')
    const [userIdSelection, setUserIdSelection] = useState<Record<string, boolean>>({})
    const hasSelectedUser = Object.values(userIdSelection).some((value) => value === true)

    const filteredUsers = useMemo(
        () => filterUsers(data?.body ?? [], searchTerm),
        [data?.body, searchTerm]
    )

    const renderUserList = useCallback(() => {
        if (isLoading) {
            return <UsersTable isLoading />
        }

        if (error) {
            return <Typography color="error">{gettext('Something went wrong')}</Typography>
        }

        if (filteredUsers.length === 0) {
            return <UsersNotFound />
        }

        return (
            <UsersTable
                users={filteredUsers}
                rowSelection={userIdSelection}
                onRowSelectionChange={setUserIdSelection}
            />
        )
    }, [isLoading, error, filteredUsers, userIdSelection])

    return (
        <Dialog
            open={open}
            title={gettext(`Add users to ${group.name} group`)}
            actions={
                <>
                    <Button onClick={onClose}>{gettext('Cancel')}</Button>
                    <Button
                        disabled={!hasSelectedUser || isAddingUsersToGroup}
                        color="secondary"
                        variant="contained"
                        startIcon={isAddingUsersToGroup && <CircularProgress size={16} />}
                        onClick={() => {
                            const selectedUserIds: number[] = []
                            filteredUsers.forEach((user, index) => {
                                if (userIdSelection[index]) {
                                    selectedUserIds.push(user.id)
                                }
                            })
                            if (!selectedUserIds.length) {
                                return
                            }

                            mutate({
                                body: { users: selectedUserIds },
                                params: { id: group.id },
                            })
                        }}
                    >
                        {gettext('Add to group')}
                    </Button>
                </>
            }
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                    }}
                >
                    <FormSearchField
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClear={() => setSearchTerm('')}
                    />
                </Box>
            </Box>

            {renderUserList()}
        </Dialog>
    )
}

type UsersTableLoadingProps = {
    isLoading: true
    users?: never
    rowSelection?: never
    onRowSelectionChange?: never
}

type UsersTableProps = {
    isLoading?: never
    users: User[]
    rowSelection: Record<string, boolean>
    onRowSelectionChange: Dispatch<SetStateAction<Record<string, boolean>>>
}

function UsersTable({
    users,
    isLoading,
    rowSelection = {},
    onRowSelectionChange,
}: UsersTableProps | UsersTableLoadingProps) {
    const tableClasses = useTableStyles()

    const table = useReactTable<User>({
        data: isLoading ? Array(10).fill({}) : users,
        columns: isLoading
            ? columns.map((col) => ({
                  ...col,
                  cell: ({ column }) => <Skeleton width={column.getSize()} height={32} />,
              }))
            : columns,
        getCoreRowModel: getCoreRowModel(),
        state: { rowSelection },
        onRowSelectionChange,
    })

    return (
        <TableContainer className={tableClasses.tableContainer}>
            <Table className={tableClasses.table} size="small">
                <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <TableCell key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                          )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>

                <TableBody>
                    {table.getRowModel().rows.map((row) => (
                        <TableRow key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
