// Sale Header
import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import classNames from 'classnames'

import { getPath } from '../../../oakra/components/base-app'
import ChannelIcons from '../../../products/components/channel-icons'
import CancelButton from '../../containers/cancel-button'
import SaleCancelConfirmation from '../../containers/sale-cancel-confirmation'
import { commonStyles, SALE_FORM_MODES } from '../sale'

import ShopSelect from './shop-selector'

const useStyles = makeStyles(() => ({
    ...commonStyles,
    status: {
        fontWeight: 'normal',
        marginLeft: 10,
    },
    button: {
        height: 30,
    },
    loadingIcon: {
        verticalAlign: 'middle',
        marginRight: 8,
    },
    cancelConfirmSection: {
        marginBottom: 10,
        padding: '6px 11px',
    },
}))

export default function Header({
    sale,
    saleHasChanged,
    mode,
    stores,
    submitting,
    onShopChange,
    onStatusChange,
    onValueChange,
    onSaveClick,
    onSaleCanceled,
    onSubmitCancelConfirmation,
    saleStatusMap,
}) {
    const classes = useStyles()
    const isAdd = mode === SALE_FORM_MODES.add
    const saleListPath = getPath('sales')
    const showStatusSelect = sale.can_edit_status && Object.keys(saleStatusMap).length > 1
    const showCancelButton =
        !isAdd && ['unpaid', 'pending', 'waiting_for_stock'].indexOf(sale.status) > -1
    const showCancelConfirmation = !isAdd && sale.status === 'in_cancel'
    const enableSaveButton = isAdd || saleHasChanged

    return (
        <React.Fragment>
            <Button component={Link} to={saleListPath} color="secondary">
                <ArrowBackIcon />
                {gettext('Orders')}
            </Button>

            {showCancelConfirmation && (
                <Paper className={classes.cancelConfirmSection}>
                    <SaleCancelConfirmation
                        saleId={sale.id}
                        onSubmitConfirmation={onSubmitCancelConfirmation}
                    />
                </Paper>
            )}

            <Paper className={classNames(classes.actionBar, classes.section)}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {sale.sale_id && (
                        <Typography
                            variant="subtitle1"
                            component="span"
                            className={classes.sectionTitle}
                        >{`${gettext('Sale')} ${sale.sale_id}`}</Typography>
                    )}
                    {isAdd && (
                        <span style={{ display: 'flex', paddingLeft: 10 }}>
                            <ShopSelect
                                data={stores}
                                dataIsStores={true}
                                onChange={onShopChange}
                                shopId={sale?.shop?.id}
                            />
                        </span>
                    )}

                    {!isAdd && sale?.shop?.channel && (
                        <Typography
                            variant="body2"
                            component="span"
                            style={{ display: 'flex', paddingLeft: 10, alignItems: 'center' }}
                        >
                            <ChannelIcons channels={[sale.shop.channel]} />
                            <span className={classes.bodyText} style={{ marginLeft: 2 }}>
                                {sale.shop.shop_name}
                            </span>
                        </Typography>
                    )}
                </span>

                <span style={{ display: 'flex', flexGrow: 1, paddingLeft: 8 }}>
                    {sale.status && !showStatusSelect && (
                        <Chip
                            label={sale.status_display_name}
                            className={classes.status}
                            color="secondary"
                            size="small"
                            variant="outlined"
                        />
                    )}
                    {showStatusSelect && (
                        <SaleStatusSelector
                            statuses={saleStatusMap}
                            value={sale.status}
                            onChange={onStatusChange}
                        />
                    )}
                </span>

                <div className={classes.actions}>
                    {!isAdd && (
                        <Button
                            color="secondary"
                            href={`/sales/${sale.id}/invoice`}
                            target="_blank"
                        >
                            {gettext('Invoice')}
                        </Button>
                    )}

                    {submitting && (
                        <CircularProgress
                            className={classes.loadingIcon}
                            color="secondary"
                            size={16}
                        />
                    )}

                    {showCancelButton && (
                        <CancelButton
                            saleId={sale.id}
                            onSaleCanceled={onSaleCanceled}
                            btnProps={{
                                className: classes.button,
                                disabled: submitting,
                            }}
                        />
                    )}

                    {isAdd && (
                        <Button
                            component={Link}
                            to={saleListPath}
                            className={classes.button}
                            disabled={submitting}
                        >
                            {gettext('Discard')}
                        </Button>
                    )}

                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={onSaveClick}
                        className={classes.button}
                        style={{ marginLeft: 8 }}
                        disabled={!enableSaveButton || submitting}
                    >
                        {gettext('Save')}
                    </Button>
                </div>
            </Paper>
        </React.Fragment>
    )
}

function SaleStatusSelector({ statuses, value, onChange, placeholder = gettext('Sale status') }) {
    const theme = useTheme()

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: 30,
            minWidth: 220,
            borderColor: state.isFocused ? theme.palette.primary.main : 'hsl(0,0%,80%)',
            boxShadow: state.isFocused ? theme.palette.primary.main : 'none',
            '&:hover': {
                borderColor: theme.palette.primary.main,
            },
            color: theme.palette.grey[700],
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: 4,
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: 4,
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0px 6px',
            color: theme.palette.grey[700],
        }),
        menu: (provided) => ({
            ...provided,
            color: theme.palette.grey[700],
        }),
        input: (provided) => ({
            ...provided,
            margin: 0,
            padding: 0,
        }),
        placeholder: (provided) => ({
            ...provided,
            paddingTop: 1,
        }),
    }

    const statusOptions = Object.entries(statuses).map(([status, text]) => ({
        label: text,
        value: status,
    }))

    return (
        <Select
            styles={selectStyles}
            onChange={(selectedOption) => onChange(selectedOption.value)}
            placeholder={placeholder}
            isSearchable={false}
            isClearable={false}
            options={statusOptions}
            value={statusOptions.find((item) => item.value === value)}
        />
    )
}
