import React from 'react'



import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import grey from '@material-ui/core/colors/grey'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { getPath } from '../../oakra/components/base-app'

import AddChannelStepper from './add-channel-stepper'

const styles = (theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    stepper: {
        borderBottom: '1px solid ' + grey[200],
        padding: 20,
    },
    channelPicker: {
        marginTop: 50,
        width: '75%',
    },
    selectChannelMessage: {
        marginTop: 30,
        textAlign: 'center',
    },
    channels: {
        display: 'flex',
        marginBottom: 50,
        marginTop: 30,
        justifyContent: 'center',
    },
    channelCard: {
        width: 200,
        height: 100,
        marginRight: 20,
    },
    channelCardActionArea: {
        height: '100%',
        display: 'flex',
    },
    channelIcon: {
        width: '80%',
    },
})

class ChannelPicker extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            channels: [],
        }

        this.handleSelectChannel = this.handleSelectChannel.bind(this)
    }

    componentDidMount() {
        const { channelsAPIURL } = this.props

        fetch(channelsAPIURL)
            .then((response) => response.json())
            .then((channels) => {
                this.setState({ channels })
            })
    }

    handleSelectChannel(channelID) {
        return () => {
            const path = getPath('addChannelSelectStore').replace('channel_id', channelID)
            window.location.href = path
        }
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.container}>
                <Paper className={classes.channelPicker}>
                    <div className={classes.stepper}>
                        <AddChannelStepper activeStep={0} />
                    </div>
                    <Typography className={classes.selectChannelMessage} variant="body1">
                        {gettext('Select the channel you want to integrate:')}
                    </Typography>
                    <div className={classes.channels}>
                        {this.state.channels.map((channel) => (
                            <Card key={channel.id} className={classes.channelCard}>
                                <CardActionArea
                                    className={classes.channelCardActionArea}
                                    onClick={this.handleSelectChannel(channel.id)}
                                >
                                    <img
                                        className={classes.channelIcon}
                                        src={channel.channel_icon_url}
                                    />
                                </CardActionArea>
                            </Card>
                        ))}
                    </div>
                </Paper>
            </div>
        )
    }
}

ChannelPicker = withStyles(styles)(ChannelPicker)

export default ChannelPicker
