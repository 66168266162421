import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import type { Attribute, ChannelAttributeValueName } from './types'

import { useProductFormContext } from '~/products/hooks/useProductForm'
import type { AttributeOption } from '~/products/types'
import { guards } from '~/products/utils'

type Props = {
    name: ChannelAttributeValueName
    attribute: Attribute
}

export default function AttributeMultiOption({ name, attribute }: Props) {
    const { control } = useProductFormContext()
    const { field } = useController({
        control,
        name,
        defaultValue: [],
        rules: {
            required:
                attribute.required && interpolate(gettext('%s is required'), [attribute.name]),
        },
    })

    const value = useMemo(() => {
        if (guards.isOptionArray(field.value)) {
            return field.value.map((option) => option.value ?? option.label)
        }

        if (guards.isStringArray(field.value)) {
            return field.value
        }

        return []
    }, [field.value])

    return (
        <TextField
            {...field}
            fullWidth
            select
            value={value}
            size="small"
            margin="dense"
            SelectProps={{
                multiple: true,
                renderValue: (selected) => {
                    if (!Array.isArray(selected)) {
                        return null
                    }

                    return (
                        <Stack direction="row" gap={1} flexWrap="wrap">
                            {selected.map((selectedValue: string | AttributeOption) => (
                                <Chip
                                    key={
                                        typeof selectedValue === 'string'
                                            ? selectedValue
                                            : selectedValue.value
                                    }
                                    label={
                                        typeof selectedValue === 'string'
                                            ? selectedValue
                                            : selectedValue.label
                                    }
                                    size="small"
                                    onMouseDown={(e) => {
                                        // Stop the select from focusing when deleting chip when not focused
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                    onDelete={() => {
                                        field.onChange(
                                            value.filter((v) =>
                                                typeof selectedValue === 'string'
                                                    ? v !== selectedValue
                                                    : v !== selectedValue.value
                                            )
                                        )
                                    }}
                                />
                            ))}
                        </Stack>
                    )
                },
            }}
            label={attribute.name}
            required={attribute.required}
            helperText={attribute.tip}
            onChange={(e) => {
                const value: unknown = e.target.value
                if (Array.isArray(value)) {
                    field.onChange(
                        value.map((value: string | AttributeOption) => {
                            return typeof value === 'string' ? value : value.value
                        })
                    )
                }
            }}
        >
            {attribute.options.map((option) => (
                <MenuItem key={option.value} disabled={option.inactive} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    )
}
