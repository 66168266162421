// Sale CSV Export Selector
import React, { useState, useRef } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'

import { post } from '../../tools/request'

import { useAppSelector } from '~/store'

const styles = (theme) => ({
    dialogActions: {
        paddingRight: 24,
        paddingLeft: 24,
        paddingBottom: 15,
        marginTop: 0,
    },
    radioText: {
        cursor: 'pointer',
    },
    formatImage: {
        maxHeight: 20,
    },
})

let SaleCSVExportSelector = ({
    open,
    formats,
    onClose,
    queryParamsString,
    classes,
    staticRoot,
    salesFilter,
}) => {
    const { exportDocumentsAPIURL } = useAppSelector((state) => state.initial.endpoints)
    const [selectedFormat, setSelectedFormat] = useState('standard')
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [displayScreen, setDisplayScreen] = useState('export')
    const [loading, setLoading] = useState(false)
    const [createdExportDocument, setCreatedExportDocument] = useState({})

    const containerRef = useRef()

    const handleToggle = (i) => () => {
        setSelectedFormat(formats[i].id)
        setSelectedIndex(i)
    }

    const submitSalesExport = async () => {
        setLoading(true)
        const res = await post(exportDocumentsAPIURL, {
            document_type: 'sales_export',
            status: 'waiting',
            name: 'Sale Export',
            params: {
                filter: salesFilter,
                format: selectedFormat,
            },
        })
        setCreatedExportDocument(res)
        setLoading(false)
        setDisplayScreen('submitted')
    }

    const handleClose = () => {
        onClose()
        setDisplayScreen('export')
        setCreatedExportDocument({})
    }

    const handleViewExports = () => {
        window.open('/home/sales/exports', '_blank')
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth ref={containerRef}>
            <React.Fragment>
                <DialogTitle>{gettext('Export')}</DialogTitle>
                <DialogContent>
                    {displayScreen === 'export' && (
                        <div>
                            <Typography variant="body2">{gettext('Select export type')}</Typography>
                            <List dense>
                                {formats.map(({ id, name, image }, i) => (
                                    <ListItem key={i} onClick={handleToggle(i)}>
                                        <ListItemIcon>
                                            <Radio
                                                name={'choices'}
                                                value={id}
                                                inputProps={{ 'aria-labelledby': `label-${name}` }}
                                                checked={selectedIndex == i}
                                            />
                                        </ListItemIcon>
                                        <img
                                            id={`label-${name}`}
                                            src={`${staticRoot}web/img/${image}`}
                                            alt={name}
                                            title={name}
                                            className={classNames(classes.formatImage, id)}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    )}
                    {displayScreen === 'submitted' && (
                        <Typography variant="body2" style={{ marginBottom: 24 }}>
                            {interpolate(gettext('File %s is generating'), [
                                createdExportDocument.name,
                            ])}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    {displayScreen === 'export' && (
                        <>
                            <Button color="secondary" onClick={handleClose}>
                                {gettext('Cancel')}
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={loading}
                                onClick={submitSalesExport}
                            >
                                {gettext('Export')}
                            </Button>
                        </>
                    )}
                    {displayScreen === 'submitted' && (
                        <>
                            <Button color="secondary" onClick={handleClose}>
                                {gettext('Close')}
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleViewExports}
                            >
                                {gettext('View exports')}
                            </Button>
                        </>
                    )}
                </DialogActions>
            </React.Fragment>
        </Dialog>
    )
}

export default withStyles(styles)(SaleCSVExportSelector)
