import { connect } from 'react-redux'

import StorePicker from '../components/store-picker'

const mapStateToProps = (state) => ({
    stores: state.initial.stores,
    language: state.initial.language,
    userID: state.initial.user.id,
})

export default connect(mapStateToProps)(StorePicker)
