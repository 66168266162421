import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import Select from 'react-select'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'
import Paper from '@material-ui/core/Paper'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DescriptionIcon from '@material-ui/icons/Description'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'

import classNames from 'classnames'
import Cookie from 'js-cookie'

import { copyObject } from '../../tools/utils'

// Bulk update

const useBulkUpdateStyles = makeStyles((theme) => ({
    storeSelectTitle: {
        fontWeight: 500,
        marginBottom: 5,
    },
    storeSelect: {
        marginBottom: 15,
    },
    downloadTemplate: {
        marginBottom: 15,
    },
    dropzone: {
        height: 100,
        backgroundColor: grey[100],
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        overflow: 'hidden',
    },
    dropzoneActive: {
        backgroundColor: grey[400],
    },
    uploadMessage: {
        display: 'flex',
        alignItems: 'center',
    },
    fileIcon: {
        color: grey[500],
        marginRight: 10,
    },
    confirmationSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: 10,
        marginTop: 15,
    },
    uploadButtonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    uploadProgress: {
        color: theme.palette.secondary.main,
        marginRight: 10,
    },
    uploadSummary: {
        border: `1px solid ${grey[500]}`,
        borderRadius: 5,
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    uploadSummaryTitle: {
        fontWeight: 500,
        marginBottom: 10,
    },
    uploadError: {
        color: theme.palette.error.main,
        textAlign: 'left',
        marginTop: 10,
    },
}))

const titleCase = (s) => {
    return s
        .toLowerCase()
        .replace(/^_*(.)|_+(.)/g, (_, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()))
}

const BulkUpdateManager = (props) => {
    const { storeOptions, uploadPricesURL, exportPricesURL } = props
    const classes = useBulkUpdateStyles()

    const [uploadSummary, setUploadSummary] = useState(null)
    const [selectedStore, setSelectedStore] = useState(null)
    const [fileToUpload, setFileToUpload] = useState(null)
    const [uploading, setUploading] = useState(false)

    const handleDownloadTemplate = () => {
        window.location.href = exportPricesURL.replace('store_pk', selectedStore.id)
    }

    const handleSelectStore = (storeOption) => {
        const selectedStore = storeOptions.find((store) => store.id === storeOption.value)
        setSelectedStore(selectedStore)
    }

    const onDrop = (files) => {
        setFileToUpload(files[0])
    }

    const handleUpload = () => {
        setUploading(true)
        setUploadSummary(null)
    }

    const handleClickBack = () => {
        setUploadSummary(null)
    }

    useEffect(() => {
        if (uploading) {
            window.analytics.track('Upload price sheet', {
                page: window.location.href,
                feature: 'price',
                type: 'bulk update',
                trigger: 'button click',
                store_id: selectedStore.id,
            })

            const url = uploadPricesURL.replace('store_pk', selectedStore.id)
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': fileToUpload.type,
                    'X-CSRFToken': Cookie.get('csrftoken'),
                },
                body: fileToUpload,
            }).then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        setUploading(false)
                        setUploadSummary(data)
                        setFileToUpload(null)
                        console.log('upload success', data)
                    })
                } else {
                    response.json().then((data) => {
                        setUploading(false)
                        setUploadSummary(data)
                        console.log('upload error', data)
                    })
                }
            })
        }
    }, [uploading])

    let hasUploadErrors = false
    if (uploadSummary && uploadSummary.errors) {
        hasUploadErrors = ['price_errors', 'platform_data_errors', 'file_errors'].some(
            (field) =>
                uploadSummary.errors[field] && Object.keys(uploadSummary.errors[field]).length > 0
        )
        if (hasUploadErrors) {
            console.log(uploadSummary)
        }
    }

    return (
        <div>
            {!uploadSummary && (
                <React.Fragment>
                    <Typography variant="body2">
                        {gettext('To update prices, fill in the update sheet and upload below')}
                    </Typography>

                    <Typography className={classes.storeSelectTitle} variant="body2">
                        {gettext('Select store')}
                    </Typography>

                    <Select
                        className={classes.storeSelect}
                        value={selectedStore}
                        options={storeOptions}
                        onChange={handleSelectStore}
                    />

                    <Button
                        className={classes.downloadTemplate}
                        variant="outlined"
                        startIcon={<GetAppIcon />}
                        size="small"
                        onClick={handleDownloadTemplate}
                        disabled={!selectedStore}
                    >
                        {gettext('Download update sheet')}
                    </Button>

                    <Dropzone
                        onDropAccepted={onDrop}
                        maxSize={1000000}
                        accept="text/csv,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/x-csv," // comma at end implies empty string; file type may be empty string on Windows
                    >
                        {({ getRootProps, getInputProps, isDragActive }) => (
                            <div
                                className={classNames(classes.dropzone, {
                                    [classes.dropzoneActive]: isDragActive,
                                })}
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div className={classes.uploadMessage}>
                                    {isDragActive ? (
                                        <Typography className={classes.dropMessage} variant="body2">
                                            {gettext('Drop file here')}
                                        </Typography>
                                    ) : (
                                        <React.Fragment>
                                            <DescriptionIcon className={classes.fileIcon} />
                                            <Typography variant="body2">
                                                {gettext('Drop file here or click to select')}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>

                    {fileToUpload && (
                        <div className={classes.confirmationSection}>
                            <Typography>{fileToUpload.name}</Typography>
                            <div className={classes.uploadButtonContainer}>
                                <CircularProgress
                                    className={classes.uploadProgress}
                                    size={20}
                                    style={{ opacity: uploading ? 1 : 0 }}
                                />
                                <Button
                                    startIcon={<PublishIcon />}
                                    color="primary"
                                    variant="outlined"
                                    onClick={handleUpload}
                                    disabled={uploading || !selectedStore}
                                >
                                    {gettext('Upload')}
                                </Button>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}

            {uploadSummary && (
                <div className={classes.uploadSummary}>
                    <Typography className={classes.uploadSummaryTitle}>
                        {gettext('Update summary')}
                    </Typography>

                    {uploadSummary?.updated?.updated_sku_count > 0 && (
                        <Typography>
                            {interpolate(gettext('Updated price for %s stock units'), [
                                uploadSummary.updated.updated_sku_count,
                            ])}
                        </Typography>
                    )}

                    {uploadSummary?.updated?.updated_sku_count === 0 && (
                        <Typography>{gettext('No prices changed')}</Typography>
                    )}

                    {hasUploadErrors && (
                        <Typography className={classes.uploadError}>
                            {gettext(
                                'There are some errors when uploading file, please check and try again.'
                            )}
                            <br />
                            <br />
                            {Object.entries(uploadSummary.errors).map(
                                ([errorGroupName, errorGroup]) => {
                                    return Object.entries(errorGroup).map(
                                        ([error, errorList], i) => {
                                            return (
                                                <div key={i}>
                                                    {titleCase(errorGroupName)} - {titleCase(error)}
                                                    :
                                                    <ul>
                                                        {errorList.map((obj, j) => {
                                                            return (
                                                                <li key={j}>
                                                                    {JSON.stringify(
                                                                        obj,
                                                                        null,
                                                                        '\t'
                                                                    )}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            )}
                        </Typography>
                    )}

                    <Button color="secondary" onClick={handleClickBack}>
                        {gettext('Back')}
                    </Button>
                </div>
            )}
        </div>
    )
}

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 20,
    },

    inputSection: {
        padding: 20,
        width: '50%',
        minWidth: 700,
    },
})

class PriceManager extends React.PureComponent {
    constructor(props) {
        super(props)

        const storeOptions = copyObject(props.stores)
        storeOptions.forEach((store) => {
            store.value = store.id
            store.label = store.name
        })

        this.state = {
            storeOptions,
        }
    }

    render() {
        const { classes, uploadPricesURL, exportPricesURL } = this.props

        return (
            <div className={classes.container}>
                <Paper className={classes.inputSection}>
                    <div>
                        <BulkUpdateManager
                            storeOptions={this.state.storeOptions}
                            uploadPricesURL={uploadPricesURL}
                            exportPricesURL={exportPricesURL}
                        />
                    </div>
                </Paper>
            </div>
        )
    }
}

export default withStyles(styles)(PriceManager)
