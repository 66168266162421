import { Link as RouterLink } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HomeIcon from '@material-ui/icons/Home'
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball';
import grey from '@material-ui/core/colors/grey'

import { getPath } from '~/tools/utils'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 20,
    },

    container: {
        padding: "100px 50px",
        width: '60%',
        minHeight: 160,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },

    message: {
        marginTop: 8,
        marginBottom: 24,
    },

}))


export default function AccessDeniedPage(props) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Paper className={classes.container}>
                <SportsVolleyballIcon style={{color: grey[400], fontSize: 100}}/>
                <Typography variant="subtitle1" className={classes.message}>
                    {gettext('You do not have permission to view this page currently')}
                </Typography>

                <Button
                    color="primary"
                    variant="contained"
                    component={RouterLink}
                    to={getPath('home')}
                    startIcon={<HomeIcon />}
                >
                    {gettext('Home')}
                </Button>

            </Paper>
        </div>
    )
}
