import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import SwapCallsIcon from '@material-ui/icons/SwapCalls'
import WarningIcon from '@material-ui/icons/Warning'

import Cookie from 'js-cookie'
import moment from 'moment'

import { getPath } from '../../oakra/components/base-app'
import { copyObject } from '../../tools/utils'

const styles = (theme) => ({
    shopsContainer: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    loadingSpinner: {
        marginTop: 30,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    statusText: {
        marginLeft: 7,
        color: grey[500],
        margin: 0,
    },
    resetAuthLink: {
        marginLeft: 5,
    },
    hasProductsForReview: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 2,
    },
    hasProductImportIssues: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 2,
    },
    warningIcon: {
        color: orange[500],
        width: 18,
    },
    hasProductsForReviewLink: {
        color: orange[500],
        marginLeft: 7,
        textDecoration: 'none',
        '&:hover': {
            color: orange[700],
        },
    },
    hasProductImportIssuesLink: {
        color: orange[500],
        marginLeft: 7,
        textDecoration: 'none',
        '&:hover': {
            color: orange[700],
        },
    },
    table: {
        minWidth: 700,
        width: '100%',
        tableLayout: 'fixed',
        marginBottom: theme.spacing(3),
    },
    channelIcon: {
        width: 75,
        verticalAlign: 'middle',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    addButton: {
        marginTop: 20,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 250,
    },
    storeSection: {
        marginTop: 20,
        borderBottom: '1px solid #e0e0e0',
        padding: 20,
    },
    storeHeader: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #e0e0e0',
        paddingBottom: 15,
        paddingLeft: 15,
    },
    syncModeFormControl: {
        marginLeft: 20,
    },
    syncModeSelect: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '2px 35px 2px 15px',
        outline: 'none',
    },
    syncModeText: {
        fontSize: '0.8rem',
    },
    hasRequiredMergesContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 15,
    },
    hasRequiredMergesLink: {
        color: orange[500],
        marginLeft: 7,
        textDecoration: 'none',
        '&:hover': {
            color: orange[700],
        },
    },
    noShops: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 50,
    },
    noShopsText: {
        fontSize: 20,
        color: grey[500],
        marginBottom: 10,
    },
    noShopsIcon: {
        marginBottom: 10,
    },
    syncStatus: {},
    syncStatusSelect: {
        display: 'flex',
        alignItems: 'center',
        padding: '2px 35px 2px 15px',
        cursor: 'pointer',
        fontSize: '0.8rem',
        outline: 'none',
    },
    syncStatusIcon: {
        minWidth: 25,
    },
    syncStatusText: {
        fontSize: '0.9rem',
        display: 'inline-flex',
    },
    pauseIcon: {
        width: 16,
        height: 16,
    },
})

const statusIndicatorColors = {
    valid: '#41C49C',
    invalid: '#F25073',
}

const SYNC_MODES = {
    NOT_SYNCING: 'not_syncing',
    RECEIVING_DATA: 'receiving',
    MANAGING: 'managing',
}

function App(props) {
    return <Integrations {...props}></Integrations>
}

let StatusIndicator = (props) => {
    const { classes, status, name, channelAuthLinkURL, channelID, storeID, appType } = props

    const [authLink, setAuthLink] = useState(null)

    if (status === 'invalid') {
        fetch(`${channelAuthLinkURL.replace('channel_id', channelID)}?store_id=${storeID}&app_type=${appType}`)
            .then((response) => response.json())
            .then((data) => {
                setAuthLink(data.auth_link)
            })
    }

    return (
        <div className={classes.statusContainer}>
            <div
                style={{
                    backgroundColor: statusIndicatorColors[status],
                    width: 16,
                    height: 16,
                    borderRadius: 8,
                    minWidth: 16,
                }}
            ></div>
            <Typography className={classes.statusText} variant="body2">
                {name}
            </Typography>
            {status === 'invalid' && authLink && (
                <Link className={classes.resetAuthLink} href={authLink}>
                    {gettext('Reset')}
                </Link>
            )}
        </div>
    )
}

StatusIndicator = withStyles(styles)(StatusIndicator)

class Integrations extends React.Component {
    constructor(props) {
        super(props)

        moment.locale(props.language)

        this.state = {
            loading: true,
        }

        this.handleAddIntegration = this.handleAddIntegration.bind(this)
        this.handleToggleStatus = this.handleToggleStatus.bind(this)
    }

    handleAddIntegration() {
        window.location.href = getPath('addChannelSelectChannel')
    }

    handleToggleStatus(storeID, shopID, field) {
        const { stores, shopAPIURL, updateStore } = this.props

        return (event) => {
            window.analytics.track('Toggle shop sync status', {
                page: window.location.href,
                feature: 'setting',
                trigger: 'toggle click',
                store_id: storeID,
                shop_id: shopID,
                sync_type: field,
                sync_value: event.target.checked,
            })

            const storesCopy = copyObject(stores)
            const store = storesCopy.find((store) => store.id == storeID)

            const shop = store.shops.find((shop) => shop.id == shopID)
            shop[field] = event.target.checked

            updateStore(store)

            const data = { [field]: event.target.checked }

            fetch(shopAPIURL.replace('shop_pk', shopID), {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRFToken': Cookie.get('csrftoken'),
                },
                body: JSON.stringify(data),
            })
        }
    }

    render() {
        const { classes, stores, channelAuthLinkURL } = this.props
        let numShops = 0

        stores.forEach((store) => {
            store.shops.forEach((shop) => {
                numShops += 1
            })
        })

        if (numShops > 0) {
            return (
                <React.Fragment>
                    {stores.map((store) => (
                        <Paper className={classes.storeSection} key={store.id}>
                            <div className={classes.storeHeader}>
                                <Typography>{store.name}</Typography>

                                {store.has_required_merges && (
                                    <div className={classes.hasRequiredMergesContainer}>
                                        <WarningIcon className={classes.warningIcon} />
                                        <Link
                                            href={getPath('mergeRecommendations').replace(
                                                'store_id',
                                                store.id
                                            )}
                                            className={classes.hasRequiredMergesLink}
                                        >
                                            {gettext(
                                                'You must first merge certain products before using the system'
                                            )}
                                        </Link>
                                    </div>
                                )}
                            </div>

                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{gettext('Platform')}</TableCell>
                                        <TableCell>{gettext('Shop name')}</TableCell>
                                        <TableCell>{gettext('Status')}</TableCell>
                                        <TableCell>{gettext('Last updated')}</TableCell>
                                        <TableCell align="center">
                                            {gettext('Sync sales')}
                                        </TableCell>
                                        <TableCell align="center">
                                            {gettext('Adjust stock for sales')}
                                        </TableCell>
                                        <TableCell align="center">
                                            {gettext('Sync stock to shop')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {store.shops.map((shop) => {
                                        return (
                                            <TableRow key={shop.id}>
                                                <TableCell>
                                                    <img
                                                        className={classes.channelIcon}
                                                        src={shop.channel.channel_icon_url}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {shop.shop_name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <React.Fragment>
                                                        {shop.credential_statuses.map(c => (
                                                            <StatusIndicator
                                                                key={shop.id}
                                                                status={c.status}
                                                                name={c.name}
                                                                channelID={shop.channel.id}
                                                                storeID={store.id}
                                                                channelAuthLinkURL={channelAuthLinkURL}
                                                                appType={c.credential_type.split('_')[0]}
                                                            />
                                                        ))}
                                                        
                                                        {shop.has_product_import_issues && (
                                                            <div
                                                                className={
                                                                    classes.hasProductImportIssues
                                                                }
                                                            >
                                                                <WarningIcon
                                                                    className={classes.warningIcon}
                                                                />
                                                                <Link
                                                                    href={getPath(
                                                                        'productImportIssues'
                                                                    ).replace('shop_id', shop.id)}
                                                                    className={
                                                                        classes.hasProductImportIssuesLink
                                                                    }
                                                                >
                                                                    {gettext(
                                                                        'There were issues importing your products'
                                                                    )}
                                                                </Link>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {moment(shop.date_synced).format('lll')}
                                                    </Typography>
                                                </TableCell>
                                                {[
                                                    'should_sync_sales_from_shop',
                                                    'should_adjust_stock_for_sales_from_shop',
                                                    'should_sync_stock_to_shop',
                                                ].map((sync_field) => (
                                                    <TableCell key={sync_field} align="center">
                                                        {shop.is_managed_programatically && (
                                                            <Switch
                                                                checked={shop[sync_field]}
                                                                onChange={this.handleToggleStatus(
                                                                    store.id,
                                                                    shop.id,
                                                                    sync_field
                                                                )}
                                                                color="secondary"
                                                                key={sync_field}
                                                            />
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    ))}
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.addButton}
                        onClick={this.handleAddIntegration}
                    >
                        <AddIcon className={classes.leftIcon} />
                        {gettext('Add integration')}
                    </Button>
                </React.Fragment>
            )
        } else {
            return (
                <Paper className={classes.shopsContainer}>
                    <div className={classes.noShops}>
                        <Typography className={classes.noShopsText} variant="h5">
                            {gettext('To get started, link your platforms to Oakra')}
                        </Typography>
                        <SwapCallsIcon
                            className={classes.noShopsIcon}
                            color="disabled"
                            style={{ fontSize: 50 }}
                        ></SwapCallsIcon>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.addButton}
                            onClick={this.handleAddIntegration}
                        >
                            <AddIcon className={classes.leftIcon} />
                            {gettext('Add integration')}
                        </Button>
                    </div>
                </Paper>
            )
        }
    }
}

export default withStyles(styles)(Integrations)
