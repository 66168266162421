import { type MouseEvent, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'

import { captureException } from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'

import { ProductPayload, useSaveProductMutation } from '../api/saveProduct'
import AttributesProvider from '../containers/attributes-provider'
import { useProductForm } from '../hooks/useProductForm'
import { useProductPage } from '../hooks/useProductPage'
import {
    createProductStore,
    ProductStoreProvider,
    useProductPageActions,
    useProductPageDialog,
    useProductPageTab,
} from '../hooks/useProductPageStore'
import { toProductPayload } from '../utils/transformers/toProductPayload'

import DeleteProductDialog from './dialog-product-delete'
import SaveInactiveProductDialog from './dialog-product-save-inactive'
import ErrorPointer from './error-pointer'
import ProductActions from './product-actions'
import ProductChannels from './product-channels'
import ProductDimensions from './product-dimensions/product-dimensions'
import BasicInfo from './product-info/basic-info'
import ProductChannelProducts from './product-info/channel-products'
import FulfillmentOptions from './product-info/fulfillment-options'
import PackageInfo from './product-info/package-info'
import ProductBundles from './product-info/product-bundles'
import ProductMedia from './product-info/product-media'
import ProductPricing from './product-info/product-pricing'
import StockRecords from './product-info/stock-records'
import ProductStockUnits from './product-stock-units/product-stock-units'

import { queryKeys } from '~/api'
import { shouldBulkEditPackageInfo } from '~/products/utils/packageInfo'
import { getPath } from '~/tools/utils'
import { ApiError } from '~/utils/error'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *:last-child': {
            marginBottom: theme.spacing(20),
        },
    },
}))

export default function Product() {
    const store = createProductStore()

    return (
        <ProductStoreProvider store={store}>
            <AttributesProvider>
                <ProductInfo />
            </AttributesProvider>
        </ProductStoreProvider>
    )
}

function ProductInfo() {
    const classes = useStyles()
    const { isNewProduct, productInfo } = useProductPage()
    const history = useHistory()
    const queryClient = useQueryClient()
    const { mutate, isLoading: isSavingProduct } = useSaveProductMutation()
    const [bulkEditPackageInfo, setBulkEditPackageInfo] = useState(true)
    useEffect(() => {
        if (!isNewProduct) {
            setBulkEditPackageInfo(shouldBulkEditPackageInfo(productInfo.product.stock_units))
        }
    }, [setBulkEditPackageInfo, isNewProduct, productInfo])

    const methods = useProductForm()
    const { enqueueSnackbar } = useSnackbar()

    const [showErrorSummary, setShowErrorSummary] = useState(false)

    function saveProduct(payload: ProductPayload, createAnother: boolean) {
        return mutate(payload, {
            onSuccess: ({ id }) => {
                if (!isNewProduct) {
                    void Promise.all([
                        queryClient.invalidateQueries({
                            queryKey: queryKeys.products.stockRecords(id).queryKey,
                        }),
                        queryClient.invalidateQueries({
                            queryKey: queryKeys.products.detail(id).queryKey,
                        }),
                    ])
                    return
                }

                if (createAnother) {
                    methods.reset()
                    methods.clearErrors()
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    return
                }

                const productPath = getPath('product')
                    .replace('store_id', productInfo.store.id.toString())
                    .replace('product_id', id.toString())
                history.replace(productPath)
            },
            onSettled: (_, error) => {
                if (!error) {
                    enqueueSnackbar(gettext('Product saved successfully'), {
                        variant: 'success',
                        SnackbarProps: { id: 'product-detail' },
                    })
                    return
                }

                enqueueSnackbar(
                    <div>
                        {error instanceof ApiError ? (
                            <>
                                <Typography>
                                    {error.message ?? gettext('Internal server error')}
                                </Typography>
                                <Typography>
                                    {gettext('Request ID')}: {error.requestId}
                                </Typography>
                            </>
                        ) : (
                            <Typography>{gettext('Internal server error')}</Typography>
                        )}
                    </div>,
                    { variant: 'error', SnackbarProps: { id: 'product-detail' } }
                )
            },
        })
    }

    function handleFormSubmit(e: MouseEvent, createAnother = false) {
        return methods.handleSubmit(
            (data) => {
                const payload = toProductPayload({ formData: data, isNewProduct })
                const result = ProductPayload.safeParse(payload)
                if (!result.success) {
                    if (import.meta.env.DEV) {
                        console.error(result.error)
                    } else {
                        captureException(result.error.issues)
                    }

                    if (data.active_channel_stock_unit_discounts?.length) {
                        payload.active_channel_stock_unit_discounts =
                            data.active_channel_stock_unit_discounts.filter(
                                (item) => item.id && item.price
                            )
                    }

                    enqueueSnackbar(gettext('Failed to parse product information'), {
                        variant: 'error',
                    })
                    return
                }

                window.analytics.track('Save product', {
                    page: window.location.href,
                    feature: 'product',
                    type: isNewProduct ? 'create' : 'update',
                    trigger: 'button click',
                    product_id: result.data.id,
                })

                saveProduct(result.data, createAnother)
            },
            (err) => {
                setShowErrorSummary(true)
                console.log('errors', err)
                console.log('methods.getValues(): ', methods.getValues())
            }
        )(e)
    }

    const tab = useProductPageTab()
    const dialog = useProductPageDialog()
    const { openDialog, changeTab, closeDialog } = useProductPageActions()

    return (
        <FormProvider {...methods}>
            <Box
                position="relative"
                mt={2.5}
                mb={5}
                display="flex"
                flexDirection="column"
                gridRowGap={20}
                className={classes.root}
            >
                <ErrorPointer />
                {!isNewProduct && productInfo.product.has_merged_channel_products && (
                    <Box component={Paper} p={2.5} display="flex" alignItems="center">
                        <Box component={InfoIcon} fontSize={56} color="grey.500" />
                        <Box p={2.5}>
                            <Typography color="textSecondary" variant="body2">
                                {gettext(
                                    'This product was merged from different platform products. Saving will result in a unified listing based on the information on this page. This may differ from the current product information on each platform (name, description, images, etc.).'
                                )}
                            </Typography>
                        </Box>
                    </Box>
                )}
                <BasicInfo isNewProduct={isNewProduct} tab={tab} onChangeTab={changeTab} />
                <ProductChannelProducts />
                <ProductMedia />
                <PackageInfo bulkEdit={bulkEditPackageInfo} setBulkEdit={setBulkEditPackageInfo} />
                {(isNewProduct || productInfo.product.dimensions.length > 0) && (
                    <ProductDimensions isNewProduct={isNewProduct} />
                )}
                <ProductStockUnits />
                <ProductBundles />
                <StockRecords />
                <FulfillmentOptions />
                <ProductPricing isNewProduct={isNewProduct} />
                <ProductChannels
                    isNewProduct={isNewProduct}
                    categoryPickerOpen={dialog === 'category-picker'}
                    onCategoryPickerOpen={() => openDialog('category-picker')}
                    onCategoryPickerClose={closeDialog}
                />
            </Box>

            <ProductActions
                showErrorSummary={showErrorSummary}
                isSaving={isSavingProduct || methods.formState.isValidating}
                onSubmit={handleFormSubmit}
                onErrorSummaryChange={setShowErrorSummary}
                onDeleteProduct={() => openDialog('delete-confirmation')}
            />

            {!isNewProduct && (
                <>
                    <DeleteProductDialog
                        open={dialog === 'delete-confirmation'}
                        productId={productInfo.product.id}
                        onCancel={closeDialog}
                    />

                    {productInfo.product.status !== 'active' && (
                        <SaveInactiveProductDialog
                            open={dialog === 'save-inactive'}
                            onClose={closeDialog}
                            onConfirm={(e) => {
                                closeDialog()
                                // Wait for the dialog to close before submitting
                                requestAnimationFrame(() => {
                                    return handleFormSubmit(e)
                                })
                            }}
                        />
                    )}
                </>
            )}
        </FormProvider>
    )
}
