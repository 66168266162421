import { connect } from 'react-redux'

import StockAdjustmentGroups from '../components/groups'

const mapStateToProps = (state) => ({
    stockAdjustmentGroupsAPIURL: state.initial.endpoints.stockAdjustmentGroupsAPIURL,
    stockAdjustmentGroupAPIURL: state.initial.endpoints.stockAdjustmentGroupAPIURL,
    stockAdjustmentGroupCreateAPIURL: state.initial.endpoints.stockAdjustmentGroupCreateAPIURL,
    stockAdjustmentsUpdateAPIURL: state.initial.endpoints.stockAdjustmentsUpdateAPIURL,
    stockAdjustmentsAPIURL: state.initial.endpoints.stockAdjustmentsAPIURL,

    stores: state.stores,
    userID: state.initial.user.id,
    language: state.initial.language,
})

export default connect(mapStateToProps)(StockAdjustmentGroups)
