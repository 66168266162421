import { z } from 'zod'

export const BundleItem = z.object({
    id: z.number(),
    num_of_units: z.number(),
    member_bundle_item: z.object({
        id: z.number(),
        sku: z.string(),
        barcode: z.string().nullish(),
    }),
})
export type BundleItem = z.infer<typeof BundleItem>

export const StockItem = z.object({
    id: z.number(),
    barcode: z.string().nullish(),
    sku: z.string(),
    is_bundle: z.boolean(),
    bundle_items: BundleItem.array(),
    package_height: z.number().nullish(),
    package_length: z.number().nullish(),
    package_weight: z.number().nullish(),
    package_width: z.number().nullish(),
})
export type StockItem = z.infer<typeof StockItem>
