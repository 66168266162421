import { type ChangeEvent, useRef } from 'react'
import { useController } from 'react-hook-form'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { filesize } from 'filesize'
import { useSnackbar } from 'notistack'

import type { Attribute, ChannelAttributeValueName } from './types'

import { api } from '~/api'
import { useUploadToS3 } from '~/common/hooks'
import { MAX_IMAGE_UPLOAD_SIZE } from '~/consts'
import { useProductFormContext } from '~/products/hooks/useProductForm'
import { useAppSelector } from '~/store'

type Props = {
    name: ChannelAttributeValueName
    attribute: Attribute
}

export default function AttributeSizeChart({ name, attribute }: Props) {
    const staticFilesDomain = useAppSelector((state) => state.initial.staticFilesDomain)
    const { control, getValues } = useProductFormContext()
    const { field, fieldState } = useController({
        control,
        name,
        defaultValue: '',
        rules: {
            required:
                attribute.required && interpolate(gettext('%s is required'), [attribute.name]),
            validate: (value) => {
                if (!value && attribute.required) {
                    return interpolate(gettext('%s is required'), [attribute.name])
                }

                if (typeof value !== 'string' || !isValidUrl(value)) {
                    return gettext('Invalid URL')
                }

                return true
            },
        },
    })
    const uploadToS3 = useUploadToS3()
    const getPresignedUrl = api.stores.getPresignedUrl.useMutation()
    const { enqueueSnackbar } = useSnackbar()

    const fileInputRef = useRef<HTMLInputElement>(null)

    function handleUpload(e: ChangeEvent<HTMLInputElement>) {
        if (!e.target.files?.length) {
            return
        }

        const files = Array.from(e.target.files)
        if (files.some((file) => file.size >= MAX_IMAGE_UPLOAD_SIZE)) {
            enqueueSnackbar(
                interpolate(gettext('File size is too large. Max size is %s'), [
                    filesize(MAX_IMAGE_UPLOAD_SIZE, { base: 2 }),
                ]),
                { variant: 'error' }
            )
            return
        }

        const file = files[0]
        if (!file) {
            return
        }

        return getPresignedUrl.mutate(
            {
                body: [{ filename: file.name, content_type: file.type }],
                params: { storeId: getValues().store.id },
            },
            {
                onSuccess: (data) => {
                    if (data.body.length !== 1) {
                        return
                    }

                    const { filename, presigned_url } = data.body[0]

                    uploadToS3.mutate(
                        { file, presignedUrl: presigned_url },
                        {
                            onSuccess: () => {
                                field.onChange(`${staticFilesDomain}${filename}`)
                                field.onBlur()
                            },
                            onError: (error) => {
                                enqueueSnackbar(
                                    error.message ??
                                        gettext('Failed to upload image. Please try again.'),
                                    { variant: 'error' }
                                )
                            },
                        }
                    )
                },
                onError: (error) => {
                    enqueueSnackbar(
                        error.body instanceof Error
                            ? error.body.message
                            : gettext('Failed to get presigned URL. Please try again.'),
                        { variant: 'error' }
                    )
                },
            }
        )
    }
    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <TextField
                {...field}
                fullWidth
                type="url"
                size="small"
                margin="dense"
                disabled={getPresignedUrl.isLoading || uploadToS3.isLoading}
                label={attribute.name}
                required={attribute.required}
                helperText={fieldState.error?.message ?? attribute.tip}
                error={!!fieldState.error}
            />
            <input
                hidden
                ref={fileInputRef}
                type="file"
                accept="image/jpeg,image/png"
                onChange={handleUpload}
            />
            <IconButton
                disabled={getPresignedUrl.isLoading || uploadToS3.isLoading}
                size="small"
                onClick={() => fileInputRef.current?.click()}
            >
                <CloudUploadIcon />
            </IconButton>
        </Stack>
    )
}

function isValidUrl(url: string) {
    try {
        new URL(url)
        return true
    } catch {
        return false
    }
}
