import { useRef, useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import Select from 'react-select'

import grey from '@material-ui/core/colors/grey'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { range as _range } from 'lodash-es'
import moment from 'moment'
import AsyncSelect from 'react-select/async'
import {
    VictoryChart,
    VictoryAxis,
    VictoryLine,
    VictoryVoronoiContainer,
    VictoryTooltip,
    VictoryLabel,
} from 'victory'

import { abbreviateNumber, numberWithCommas, getTickValues } from '../../tools/analytics'

import ChartTheme from './small-chart-theme'

import 'moment/locale/en-gb'
import 'moment/locale/th'

const CHART_HEIGHT = 150
const CONTENT_PADDING = 20
const PLACEHOLDER_SPEED = 0.5
const PLACEHOLDER_INTERVAL = 0.5
const STAT_HEIGHT = '1.2rem'
const CHART_TOP_MARGIN = 10
const DESIRED_NUMBER_OF_TICKS = 5
const CHANGE_FONT_SIZE = '1rem'

const summaryStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        marginRight: 15,
        padding: 20,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: '0.9rem',
        fontWeight: 600,
    },
    statPlaceholder: {
        marginTop: 5,
    },
    stats: {
        marginTop: 5,
        display: 'flex',
        justifyContent: 'space-between',
    },
    stat: {
        fontSize: STAT_HEIGHT,
    },
    change: {
        fontSize: CHANGE_FONT_SIZE,
        color: grey[500],
    },
    chartPlaceholder: {
        marginTop: CHART_TOP_MARGIN,
    },
}))

const Tooltip = ({ x, y }) => {
    const theme = useTheme()

    return (
        <g>
            <circle
                cx={x}
                cy={y}
                r="4"
                stroke={theme.palette.secondary.main}
                strokeWidth={1.5}
                fill="white"
            />
        </g>
    )
}

const SummaryChartCard = ({
    title,
    statTotal,
    change,
    data,
    previousPeriodData,
    daysInPeriod,
    language,
    reportLink,
}) => {
    moment.locale(language)
    const theme = useTheme()

    const containerEl = useRef(null)
    const classes = summaryStyles()
    const [chartWidth, setChartWidth] = useState(null)

    useEffect(() => {
        if (containerEl.current) {
            setChartWidth(containerEl.current.offsetWidth - CONTENT_PADDING * 2)
        }
    }, [containerEl.current])

    const YValues = data.map((d) => d.y).concat(previousPeriodData.map((d) => d.y))
    const maxYValue = Math.max(...YValues)
    const RangeMax = maxYValue * 1.1

    const XValues = data.map((d) => d.x)
    const domainMin = XValues[0]
    const [domainMax] = XValues.slice(-1)

    const tickValues = getTickValues(
        data.map((d) => d.x),
        DESIRED_NUMBER_OF_TICKS
    )

    return (
        <Paper className={classes.container} ref={containerEl}>
            <div className={classes.header}>
                <Typography className={classes.title}>{title}</Typography>
                <Link href={reportLink} color="secondary">
                    {gettext('Report')}
                </Link>
            </div>

            {data.length == 0 && (
                <ContentLoader
                    className={classes.statPlaceholder}
                    width={chartWidth / 2}
                    height={STAT_HEIGHT}
                    speed={PLACEHOLDER_SPEED}
                    interval={PLACEHOLDER_INTERVAL}
                >
                    <rect x="0" y="0" rx="4" ry="4" width={chartWidth / 2} height={STAT_HEIGHT} />
                </ContentLoader>
            )}
            {data.length > 0 && (
                <div className={classes.stats}>
                    <Typography className={classes.stat}>{statTotal}</Typography>
                    <Typography className={classes.change}>{change}</Typography>
                </div>
            )}
            {data.length > 0 && (
                <VictoryChart
                    className={classes.chart}
                    height={CHART_HEIGHT}
                    width={chartWidth}
                    theme={ChartTheme}
                    containerComponent={<VictoryVoronoiContainer responsive={false} />}
                    domain={{ x: [domainMin, domainMax], y: [0, RangeMax] }}
                >
                    <VictoryLine
                        data={data}
                        interpolation="natural"
                        style={{ data: { stroke: theme.palette.secondary.main } }}
                        labelComponent={
                            <VictoryTooltip
                                pointerLength={0}
                                flyoutComponent={<Tooltip />}
                                labelComponent={
                                    <VictoryLabel
                                        dy={-10}
                                        backgroundStyle={{
                                            fill: 'white',
                                            stroke: theme.palette.secondary.main,
                                            rx: 2,
                                            ry: 2,
                                        }}
                                        backgroundPadding={6}
                                    />
                                }
                                text={(d) => {
                                    return `${moment(d.datum.x).format(
                                        'D MMM'
                                    )} - ${abbreviateNumber(d.datum.y, 1)}`
                                }}
                            />
                        }
                    />
                    <VictoryLine
                        data={previousPeriodData}
                        interpolation="natural"
                        style={{
                            data: {
                                stroke: grey[500],
                                opacity: 0.5,
                            },
                        }}
                        labelComponent={
                            <VictoryTooltip
                                pointerLength={0}
                                flyoutComponent={<Tooltip />}
                                labelComponent={
                                    <VictoryLabel
                                        dy={-10}
                                        backgroundStyle={{
                                            fill: 'white',
                                            stroke: grey[500],
                                            rx: 2,
                                            ry: 2,
                                        }}
                                        backgroundPadding={6}
                                    />
                                }
                                text={(d) => {
                                    return `${moment(d.datum.x)
                                        .subtract(daysInPeriod, 'days')
                                        .format('D MMM')} - ${abbreviateNumber(d.datum.y, 1)}`
                                }}
                            />
                        }
                    />
                    <VictoryAxis
                        tickValues={tickValues}
                        tickFormat={(tick) => moment(tick).format('D MMM')}
                        scale={{ x: 'time' }}
                        style={{ grid: { stroke: 'none' } }}
                    />
                    <VictoryAxis tickFormat={(t) => abbreviateNumber(t, 1)} dependentAxis={true} />
                </VictoryChart>
            )}
            {data.length == 0 && (
                <div className={classes.progressContainer}>
                    <ContentLoader
                        className={classes.chartPlaceholder}
                        width={chartWidth}
                        height={CHART_HEIGHT - CHART_TOP_MARGIN}
                        speed={PLACEHOLDER_SPEED}
                        interval={PLACEHOLDER_INTERVAL}
                    >
                        <rect
                            x="0"
                            y="0"
                            rx="4"
                            ry="4"
                            width={chartWidth}
                            height={CHART_HEIGHT - CHART_TOP_MARGIN}
                        />
                    </ContentLoader>
                </div>
            )}
        </Paper>
    )
}

const statsTableStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        marginRight: 15,
        padding: 20,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: '0.9rem',
        fontWeight: 600,
        marginBottom: 10,
    },
    headerRow: {
        display: 'flex',
        paddingBottom: 3,
        marginBottom: 7,
        borderBottom: `1px solid ${grey[300]}`,
    },
    labelTitleCell: {
        flex: '1 0 auto',
        fontWeight: 600,
        fontSize: '0.75rem',
        color: grey[700],
    },
    valueTitleCell: {
        flex: '1 0 auto',
        fontWeight: 500,
        fontSize: '0.75rem',
        textAlign: 'right',
        color: grey[700],
    },
    tableRow: {
        display: 'flex',
        marginBottom: 5,
    },
    labelCell: {
        display: 'flex',
        alignItems: 'center',
        flex: '1 0 auto',
    },
    labelText: {
        fontSize: '0.9rem',
        marginLeft: 5,
        maxWidth: 210,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    icon: {
        height: 15,
        width: 15,
    },
    valueCell: {
        flex: '1 0 auto',
        textAlign: 'right',
    },
    placeholder: {},
}))

const TEXT_PLACEHOLDER_HEIGHT = 20
const TEXT_PLACEHOLDER_LABEL_MARGIN = 5
const TEXT_PLACEHOLDER_MARGIN = 5

const StatsTableCard = ({ cardTitle, labelTitle, valueTitle, data, reportLink }) => {
    const classes = statsTableStyles()

    const containerEl = useRef(null)
    const [contentWidth, setContentWidth] = useState(null)

    useEffect(() => {
        if (containerEl.current) {
            setContentWidth(containerEl.current.offsetWidth - CONTENT_PADDING * 2)
        }
    }, [containerEl.current])

    return (
        <Paper className={classes.container} ref={containerEl}>
            <div className={classes.header}>
                <Typography className={classes.title}>{cardTitle}</Typography>
                <Link href={reportLink} color="secondary">
                    {gettext('Report')}
                </Link>
            </div>

            {data.length > 0 && (
                <>
                    <div className={classes.headerRow}>
                        <div className={classes.labelTitleCell}>{labelTitle}</div>
                        <div className={classes.valueTitleCell}>{valueTitle}</div>
                    </div>

                    {data.map((d, i) => (
                        <div key={i} className={classes.tableRow}>
                            <div component="th" scope="row" className={classes.labelCell}>
                                {d.iconURL && <img className={classes.icon} src={d.iconURL} />}
                                <Typography className={classes.labelText}>{d.label}</Typography>
                            </div>
                            <div className={classes.valueCell}>
                                {numberWithCommas(Math.round(d.value))}
                            </div>
                        </div>
                    ))}
                </>
            )}

            {data.length == 0 && (
                <div className={classes.progressContainer}>
                    <ContentLoader
                        className={classes.placeholder}
                        width={contentWidth}
                        height={6 * (TEXT_PLACEHOLDER_HEIGHT + TEXT_PLACEHOLDER_MARGIN)}
                        speed={PLACEHOLDER_SPEED}
                        interval={PLACEHOLDER_INTERVAL}
                    >
                        <rect
                            x="0"
                            y="0"
                            rx="4"
                            ry="4"
                            width={75}
                            height={TEXT_PLACEHOLDER_HEIGHT}
                        />
                        {_range(5).map((x) => (
                            <rect
                                x="0"
                                y={
                                    TEXT_PLACEHOLDER_LABEL_MARGIN +
                                    (x + 1) * (TEXT_PLACEHOLDER_HEIGHT + TEXT_PLACEHOLDER_MARGIN)
                                }
                                rx="4"
                                ry="4"
                                width={contentWidth}
                                height={TEXT_PLACEHOLDER_HEIGHT}
                                key={x}
                            />
                        ))}
                    </ContentLoader>
                </div>
            )}
        </Paper>
    )
}

const ReportSelector = ({
    options,
    handleChange,
    isSearchable,
    isClearable,
    isDisabled,
    value,
    clearValue,
    width,
}) => {
    const theme = useTheme()

    return (
        <Select
            options={options}
            onChange={handleChange}
            value={value}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isDisabled={isDisabled}
            placeholder={gettext('Select')}
            clearValue={clearValue}
            styles={{
                container: (provided, state) => ({
                    ...provided,
                    fontSize: 15,
                }),
                control: (provided, state) => ({
                    ...provided,
                    cursor: 'pointer',
                    minHeight: 36,
                    width: width || 150,
                    borderColor: state.isFocused ? theme.palette.secondary.main : 'hsl(0,0%,80%)',
                    boxShadow: state.isFocused ? theme.palette.secondary.main : 'none',
                    '&:hover': {
                        borderColor: theme.palette.secondary.main,
                    },
                }),
                option: (provided, state) => () => ({
                    ...provided,
                    cursor: 'pointer',
                    backgroundColor: state.isSelected ? theme.palette.secondary.main : null,
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    padding: 4,
                }),
                clearIndicator: (provided, state) => ({
                    ...provided,
                    padding: 4,
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    padding: '0px 6px',
                }),
                input: (provided, state) => ({
                    ...provided,
                    margin: 0,
                    padding: 0,
                }),
                placeholder: (provided, state) => ({
                    ...provided,
                    paddingTop: 1,
                }),
            }}
        />
    )
}

const ReportSelectorAsync = ({ options, loadOptions, handleChange, value, width }) => {
    const theme = useTheme()

    return (
        <AsyncSelect
            cacheOptions
            options={options}
            onChange={handleChange}
            value={value}
            loadOptions={loadOptions}
            placeholder={gettext('Search')}
            styles={{
                container: (provided, state) => ({
                    ...provided,
                    fontSize: 15,
                }),
                control: (provided, state) => ({
                    ...provided,
                    cursor: 'pointer',
                    minHeight: 36,
                    width: width || 150,
                    borderColor: state.isFocused ? theme.palette.secondary.main : 'hsl(0,0%,80%)',
                    boxShadow: state.isFocused ? theme.palette.secondary.main : 'none',
                    '&:hover': {
                        borderColor: theme.palette.secondary.main,
                    },
                }),
                option: (provided, state) => () => ({
                    ...provided,
                    cursor: 'pointer',
                    backgroundColor: state.isSelected ? theme.palette.secondary.main : null,
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    padding: 4,
                }),
                clearIndicator: (provided, state) => ({
                    ...provided,
                    padding: 4,
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    padding: '0px 6px',
                }),
                input: (provided, state) => ({
                    ...provided,
                    margin: 0,
                    padding: 0,
                }),
                placeholder: (provided, state) => ({
                    ...provided,
                    paddingTop: 1,
                }),
            }}
        />
    )
}

export { SummaryChartCard, StatsTableCard, ReportSelector, ReportSelectorAsync }
