import { connect } from 'react-redux'

import Dashboard from '../components/dashboard'

const mapStateToProps = (state) => ({
    stores: state.stores,
    userID: state.initial.user.id,
    accountOwnerID: state.initial.accountOwner.id,
    language: state.initial.language,
    staticRoot: state.initial.staticRoot,
    analyticsTimeSeriesChartAPIURL: state.initial.endpoints.analyticsTimeSeriesChartAPIURL,
    shopsRevenueSummaryAPIURL: state.initial.endpoints.shopsRevenueSummaryAPIURL,
    channelsRevenueSummaryAPIURL: state.initial.endpoints.channelsRevenueSummaryAPIURL,
    productsRevenueSummaryAPIURL: state.initial.endpoints.productsRevenueSummaryAPIURL,
})

export default connect(mapStateToProps)(Dashboard)
