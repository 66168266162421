import { cloneDeep } from 'lodash-es'

import { PATHS } from '../constants'

export function copyObject(obj) {
    return cloneDeep(obj)
}

export function debounce(fn, time) {
    let timeout

    return function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this
        const args = arguments

        return new Promise((resolve) => {
            const functionCall = () => {
                resolve(fn.apply(context, args))
            }

            clearTimeout(timeout)
            timeout = setTimeout(functionCall, time)
        })
    }
}

/**
 *
 * @param {{translations: Record<string, unknown>}} obj
 * @param {string} language
 * @param {string} fieldName
 * @returns {string}
 */
export function getTranslation(obj, language, fieldName) {
    // return empty string if no object or translations
    if (!obj || !('translations' in obj)) {
        return ''
    }

    // return translation if exists
    if (language in obj.translations && obj.translations[language][fieldName]) {
        return obj.translations[language][fieldName]
    }

    const translation = Object.values(obj.translations)
        .filter((languageFields) => !!languageFields[fieldName])
        .at(0)

    // return first translation that exists or empty string
    return translation ? translation[fieldName] : ''
}

export function translationsIncludeTerm(translations, term) {
    let foundTerm = false

    Object.entries(translations).forEach(([language, languageFields]) => {
        Object.entries(languageFields).forEach(([fieldName, fieldValue]) => {
            if (fieldValue && fieldValue.toLowerCase().includes(term.toLowerCase())) {
                foundTerm = true
            }
        })
    })

    return foundTerm
}

export function capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const range = (start, end, increment) => {
    // if the end is not defined...
    const isEndDef = typeof end !== 'undefined'
    // ...the first argument should be the end of the range...
    end = isEndDef ? end : start
    // ...and 0 should be the start
    start = isEndDef ? start : 0

    // if the increment is not defined, we could need a +1 or -1
    // depending on whether we are going up or down
    if (typeof increment === 'undefined') {
        increment = Math.sign(end - start)
    }

    // calculating the length of the array, which has always to be positive
    const length = Math.abs((end - start) / (increment || 1))

    // In order to return the right result, we need to create a new array
    // with the calculated length and fill it with the items starting from
    // the start value + the value of increment.
    const { result } = Array.from({ length }).reduce(
        ({ result, current }) => ({
            // append the current value to the result array
            result: [...result, current],
            // adding the increment to the current item
            // to be used in the next iteration
            current: current + increment,
        }),
        { current: start, result: [] }
    )

    return result
}

export function isValidBarcodeCharacter(keyCode) {
    return /^[a-z0-9\s-_]$/i.test(event.key)
}

export function stripHTMLTags(html) {
    const div = document.createElement('div')
    div.innerHTML = html
    const text = div.textContent || div.innerText || ''

    return text
}

export function truncate(text, n) {
    return text.length > n ? text.substr(0, n - 1) + ' . . .' : text
}

export const getUniqueChannelsFromShops = (shops) => {
    const uniqueChannelIDs = [...new Set(shops.map((shop) => shop.channel.id))]
    const channels = copyObject(shops.map((shop) => shop.channel))

    return uniqueChannelIDs.map((channelID) => channels.find((channel) => channel.id === channelID))
}

export const getUniqueChannelsFromChannelProducts = (channelProducts) => {
    // Map each channel product shop channel to channel ID
    const uniqueChannels = new Map(
        channelProducts.map((cp) => [cp.shop.channel.id, copyObject(cp.shop.channel)])
    )

    return [...uniqueChannels.values()]
}

export const getUniqueShopsFromChannelProducts = (channelProducts) => {
    // Map each channel product shop to shop ID
    const uniqueShops = new Map(channelProducts.map((cp) => [cp.shop.id, copyObject(cp.shop)]))

    return [...uniqueShops.values()]
}

export const channelProductHasError = (channelProduct, channelProductErrors) => {
    if (!channelProductErrors) return false

    let hasErrors = false

    Object.entries(channelProductErrors.translations).forEach(([language, languageErrors]) => {
        if (Object.keys(languageErrors).length > 0) {
            hasErrors = true
        }
    })

    if (channelProductErrors.images) {
        hasErrors = true
    }

    return hasErrors
}

export const getStockUnitLabel = (stockUnit) => stockUnit?.sku ?? ''

export const arraysEqual = (a, b) => {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false

    const aCopy = copyObject(a)
    const bCopy = copyObject(b)

    aCopy.sort()
    bCopy.sort()

    for (var i = 0; i < aCopy.length; ++i) {
        if (aCopy[i] !== bCopy[i]) return false
    }
    return true
}

export const objectIsEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const getAttr = (obj, attr) => {
    if (!obj) return undefined

    if (typeof attr === 'function') return attr(obj)

    const i = attr.indexOf('.')
    if (i === -1) return obj[attr]

    return getAttr(obj[attr.substr(0, i)], attr.substr(i + 1))
}

/**
 * @param {string} name
 * @returns {string}
 */
export function getPath(name) {
    const path = PATHS[name]
    return path.replace(/:/g, '')
}
