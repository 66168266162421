import { useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'

import classNames from 'classnames'

import { formatAmount, useCommonSaleStyles } from '../sale'

import { getTranslation } from '~/tools/utils'

// Sale Items
export default function SaleItems({
    sale,
    mode,
    language,
    searchQuery,
    onSearchFieldChange,
    onStockUnitQuantityChange,
    onDiscountChange,
    onUnitPriceChange,
}) {
    const classes = useCommonSaleStyles()
    const hasItems = !!sale.items_count
    const hasShop = !!sale.shop

    // disable editing items, user have to cancel this sale and create other one
    const allowItemEditing =
        sale.can_edit_items &&
        (mode === 'add' || sale.status === 'unpaid' || sale.status === 'waiting_for_stock')

    return (
        <Paper className={classNames(classes.basicInfo, classes.section)}>
            <Typography variant="subtitle1" className={classes.sectionTitle}>
                {gettext('Items')}
            </Typography>
            {allowItemEditing && (
                <Tooltip title={hasShop ? '' : gettext('Please select a shop first')}>
                    <TextField
                        disabled={!hasShop}
                        style={{ marginTop: 5 }}
                        onInput={(e) => {
                            onSearchFieldChange(e.target.value)
                        }}
                        value={searchQuery}
                        placeholder={gettext('To add items, enter product name or SKU')}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="disabled" />
                                </InputAdornment>
                            ),
                            style: { height: 30, fontSize: '0.9rem' },
                        }}
                    />
                </Tooltip>
            )}
            {hasItems && (
                <SaleItemsList
                    sale={sale}
                    language={language}
                    onUnitPriceChange={onUnitPriceChange}
                    onDiscountChange={onDiscountChange}
                    onStockUnitQuantityChange={allowItemEditing && onStockUnitQuantityChange}
                />
            )}
        </Paper>
    )
}

function SaleItemsList({
    sale,
    language,
    onStockUnitQuantityChange,
    onDiscountChange,
    onUnitPriceChange,
}) {
    const items = sale.sale_line_items || []

    return (
        <Box mt={3} color="grey.700">
            {items.map((item, i) => (
                <SaleItem
                    key={i}
                    sale={sale}
                    item={item}
                    language={language}
                    onUnitPriceChange={onUnitPriceChange}
                    onStockUnitQuantityChange={onStockUnitQuantityChange}
                    onDiscountChange={onDiscountChange}
                    isEditable={!!onStockUnitQuantityChange}
                    currency={sale.currency}
                />
            ))}
        </Box>
    )
}

// Sale Item
const useSaleItemStyles = makeStyles((theme) => ({
    saleItemImage: {
        width: 40,
    },
    amounts: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
    },
    name: {
        fontSize: '0.9rem',
    },
    skuInfo: {
        border: '1px solid',
        color: theme.palette.grey[500],
        padding: '0 6px',
        borderRadius: 5,
        marginRight: 5,
        fontSize: '0.8rem',
    },
    deleteButton: {
        marginLeft: 5,
    },
    skuName: {
        color: theme.palette.grey[500],
        marginRight: 5,
        fontSize: '0.8rem',
    },
    itemCanceledStatus: {
        color: theme.palette.error.main,
        border: '1px solid',
        padding: '0 6px',
        borderRadius: 5,
        marginRight: 5,
        fontSize: '0.8rem',
    },
    label: {
        color: theme.palette.grey[500],
        fontSize: '0.8rem',
        marginBottom: -3,
    },
    textField: {
        marginTop: 5,
    },
    input: {
        textAlign: 'center',
        width: 60,
        fontSize: '0.9rem',
        padding: 7,
    },
    inputLabelMargin: {
        transform: 'translate(9px, 10px) scale(0.8)',
    },
    inputLabelShrink: {
        transform: 'translate(14px, -6px) scale(0.75)',
        color: theme.palette.grey[500],
    },
}))

function SaleItem({
    sale,
    item,
    language,
    onUnitPriceChange,
    onStockUnitQuantityChange,
    onDiscountChange,
    isEditable,
    currency,
}) {
    const classes = useSaleItemStyles()

    const name = item?.stock_unit?.product
            ? getTranslation(item.stock_unit.product, language, 'name')
            : item.product_name,
        imageURL = item.image || item?.stock_unit?.product?.cover_image_url,
        pricePerItem = formatAmount(item.unit_price || item.price_paid, currency),
        sku = item?.stock_unit?.sku || item.sku,
        sku_name = item.stock_unit_name || item?.stock_unit?.name

    const [saleItem, setSaleItem] = useState(item)
    const [displayAmount, setDisplayAmount] = useState(item.num_sold)
    const [discount, setDiscount] = useState(item.item_level_discount && Number(item.item_level_discount))
    const [unitPrice, setUnitPrice] = useState(item.unit_price && Number(item.unit_price))

    const handleDeleteClick = () => {
        onStockUnitQuantityChange(item.stock_unit, -item.num_sold)
    }

    const updateNumSold = (e) => {
        const txtVal = e.target.value
        const num = +txtVal
        const delta = num - saleItem.num_sold

        setDisplayAmount(txtVal)

        if (txtVal.trim() !== '') onStockUnitQuantityChange(item.stock_unit, delta)
    }

    const updateDiscount = (e) => {
        const value = e.target.value
        setDiscount(value)
        onDiscountChange(item.stock_unit, value)
    }

    const updateUnitPrice = (e) => {
        const value = e.target.value
        setUnitPrice(value)
        onUnitPriceChange(item.stock_unit, value)
    }

    useEffect(() => {
        setSaleItem(item)
        setDisplayAmount(item.num_sold)
        setDiscount(item.item_level_discount && Number(item.item_level_discount))
        setUnitPrice(item.unit_price && Number(item.unit_price))
    }, [item])

    const total = (Number(unitPrice) || 0) * Number(saleItem.num_sold) - (Number(discount) || 0)
    const isItemCanceled = sale.status !== 'canceled' && item.status === 'canceled'

    return (
        <Box sx={{ display: 'flex', mb: 2 }}>
            <div>
                <img className={classes.saleItemImage} src={imageURL} height={40} />
            </div>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: 1.5,
                    flexGrow: 1,
                    minWidth: 0,
                }}
            >
                <Typography className={classes.name}>{name}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 0.5,
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <Typography className={classes.skuInfo}>{sku}</Typography>
                        <Typography className={classes.skuName}>{sku_name}</Typography>
                        {isItemCanceled && (
                            <Typography className={classes.itemCanceledStatus}>Canceled</Typography>
                        )}
                    </Box>
                    {isEditable ? (
                        <Box sx={{ display: 'flex' }}>
                            <div className={classes.amounts}>
                                <TextField
                                    className={classes.textField}
                                    size="small"
                                    margin="dense"
                                    type="number"
                                    label={gettext('Price')}
                                    variant="outlined"
                                    value={unitPrice || ''}
                                    style={{ marginTop: 5 }}
                                    onChange={updateUnitPrice}
                                    InputLabelProps={{
                                        margin: 'dense',
                                        shrink: true,
                                        classes: {
                                            marginDense: classes.inputLabelMargin,
                                            shrink: classes.inputLabelShrink,
                                        },
                                    }}
                                    inputProps={{ className: classes.input }}
                                />

                                <span style={{ marginLeft: -8, marginRight: -8 }}>x</span>

                                <TextField
                                    className={classes.textField}
                                    size="small"
                                    margin="dense"
                                    type="number"
                                    label={gettext('Quantity')}
                                    variant="outlined"
                                    value={displayAmount || ''}
                                    style={{ marginTop: 5 }}
                                    onChange={updateNumSold}
                                    InputLabelProps={{
                                        margin: 'dense',
                                        shrink: true,
                                        classes: {
                                            marginDense: classes.inputLabelMargin,
                                            shrink: classes.inputLabelShrink,
                                        },
                                    }}
                                    inputProps={{ className: classes.input }}
                                />

                                <TextField
                                    className={classes.textField}
                                    size="small"
                                    margin="dense"
                                    type="number"
                                    label={gettext('Discount')}
                                    variant="outlined"
                                    value={discount || ''}
                                    style={{ marginTop: 5 }}
                                    onChange={updateDiscount}
                                    InputLabelProps={{
                                        margin: 'dense',
                                        shrink: true,
                                        classes: {
                                            marginDense: classes.inputLabelMargin,
                                            shrink: classes.inputLabelShrink,
                                        },
                                    }}
                                    inputProps={{ className: classes.input }}
                                />

                                <div style={{ marginTop: -14 }}>
                                    <div className={classes.label}>{gettext('Total')}</div>
                                    {formatAmount(total, currency)}
                                </div>
                            </div>

                            <IconButton
                                className={classes.deleteButton}
                                size="small"
                                onClick={handleDeleteClick}
                                title={gettext('Delete sale item')}
                            >
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex' }}>
                            {discount ? (
                                <div className={classes.amounts}>
                                    <div>
                                        <div className={classes.label}>{gettext('Price')}</div>
                                        {pricePerItem} x {saleItem.num_sold}
                                    </div>

                                    <div>
                                        <div className={classes.label}>{gettext('Discount')}</div>
                                        {formatAmount(discount, currency)}
                                    </div>

                                    <div>
                                        <div className={classes.label}>{gettext('Total')}</div>
                                        {formatAmount(total, currency)}
                                    </div>
                                </div>
                            ) : (
                                <div className={classes.amounts}>
                                    {pricePerItem} x {saleItem.num_sold}
                                </div>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export const SaleLineItem = SaleItem
