import React from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import classNames from 'classnames'
import Cookie from 'js-cookie'

import { getPath } from './base-app'

const styles = (theme) => ({
    loginPanel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 48px)',
    },
    loginCard: {
        paddingLeft: 40,
        paddingRight: 40,
        width: 500,
        display: 'flex',
        flexDirection: 'column',
    },
    loginTitle: {
        borderBottom: '1px solid ' + grey[200],
        marginLeft: -40,
        marginRight: -40,
        paddingLeft: 40,
        paddingTop: 20,
        paddingBottom: 12,
    },
    logo: {
        width: 120,
    },
    field: {
        marginBottom: 15,
    },
    email: {
        marginTop: 40,
    },
    password: {},
    errorMessage: {},
    submitContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        justifyContent: 'flex-end',
        borderTop: '1px solid ' + grey[200],
        marginLeft: -40,
        marginRight: -40,
        paddingTop: 20,
        paddingRight: 40,
        paddingBottom: 20,
    },
    loginProgress: {
        marginRight: 15,
    },
    submitButton: {
        width: 100,
        color: 'white',
    },
    confirmationCodeSection: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    confirmationCodeRow: {
        display: 'flex',
    },
    confirmationCode: {
        marginLeft: 10,
    },
    confirmationCodeError: {},
    resendButton: {
        alignSelf: 'flex-end',
    },
})

function UnstyledResendButton(props) {
    const { classes, secondsRemaining, onClick } = props

    let buttonText

    if (secondsRemaining > 0) {
        buttonText = `Resend (${secondsRemaining})`
    } else {
        buttonText = gettext('Resend')
    }

    return (
        <Button className={classes.resendButton} disabled={secondsRemaining > 0} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

const ResendButton = withStyles(styles)(UnstyledResendButton)

class Login extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            emailError: '',
            password: '',
            passwordError: '',
            showPassword: false,
            errorMessage: '',
            confirmationCode: '',
            confirmationCodeError: '',
            loggingIn: false,
            displayConfirmationCode: false,
            resendSecondsRemaining: 0,
        }

        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.startResendCountdown = this.startResendCountdown.bind(this)
        this.handleResendConfirmationCode = this.handleResendConfirmationCode.bind(this)

        this.emailFieldRef = React.createRef()
        this.passwordFieldRef = React.createRef()
    }

    startResendCountdown(numberOfSeconds) {
        this.setState({ resendSecondsRemaining: 60 }, () => {
            this.resendTimer = setInterval(() => {
                if (this.state.resendSecondsRemaining > 0) {
                    this.setState({ resendSecondsRemaining: this.state.resendSecondsRemaining - 1 })
                } else {
                    clearInterval(this.resendTimer)
                }
            }, 1000)
        })
    }

    handleSubmit(e) {
        const { loginAPIURL, changeLoginState } = this.props

        e.preventDefault()

        let credentials = {
            email: this.state.email,
            password: this.state.password,
        }

        if (this.state.displayConfirmationCode) {
            if (this.state.confirmationCode) {
                credentials.confirmation_code = this.state.confirmationCode
            } else {
                this.setState({
                    confirmationCodeError: gettext('Please enter the confirmation code.'),
                })
                return
            }
        }

        this.setState(
            {
                loggingIn: true,
                emailError: '',
                passwordError: '',
                errorMessage: '',
                confirmationCodeError: '',
            },
            () => {
                fetch(loginAPIURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'X-CSRFToken': Cookie.get('csrftoken'),
                    },
                    body: JSON.stringify(credentials),
                })
                    .then((response) => {
                        const data = response.json().then((data) => {
                            if (response.ok) {
                                changeLoginState(true)
                                const params = new Proxy(
                                    new URLSearchParams(window.location.search),
                                    {
                                        get: (searchParams, prop) => searchParams.get(prop),
                                    }
                                )
                                window.location.href = params?.redirect_path || getPath('home')
                            } else {
                                let newState = {}

                                if (
                                    'confirmation_code_required' in data &&
                                    data['confirmation_code_required']
                                ) {
                                    newState = { ...newState, displayConfirmationCode: true }

                                    if (!this.state.resendSecondsRemaining) {
                                        this.startResendCountdown(60)
                                    }
                                }

                                let errorFieldRef = false

                                if ('non_field_errors' in data) {
                                    newState = {
                                        ...newState,
                                        errorMessage: data.non_field_errors[0],
                                    }
                                }

                                if ('email' in data) {
                                    newState = { ...newState, emailError: data.email[0] }
                                    errorFieldRef = this.emailFieldRef
                                }

                                if ('password' in data) {
                                    newState = { ...newState, passwordError: data.password[0] }
                                    if (!errorFieldRef) errorFieldRef = this.passwordFieldRef
                                }

                                this.setState(newState)
                                ;(errorFieldRef || this.emailFieldRef).current.select()
                            }
                        })

                        return response
                    })
                    .finally(() => {
                        this.setState({ loggingIn: false })
                    })
            }
        )
        return false
    }

    handleFieldChange(field) {
        return (event) => {
            this.setState({ [field]: event.target.value })
        }
    }

    handleClickShowPassword() {
        this.setState({ showPassword: !this.state.showPassword })
    }

    handleResendConfirmationCode(user) {
        const { loginConfirmationCodeAPIURL } = this.props
        const url = loginConfirmationCodeAPIURL + '?email=' + this.state.email

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).then((response) => {
            if (!this.state.resendSecondsRemaining) {
                this.startResendCountdown(60)
            }
        })
    }

    render() {
        const { classes, staticRoot } = this.props

        return (
            <form className={classes.loginPanel} onSubmit={this.handleSubmit}>
                <Paper className={classes.loginCard}>
                    <div className={classes.loginTitle}>
                        <img className={classes.logo} src={staticRoot + 'landing/oakra.png'} />
                    </div>
                    <TextField
                        className={classNames(classes.field, classes.email)}
                        variant="outlined"
                        label={gettext('Email')}
                        value={this.state.email}
                        onChange={this.handleFieldChange('email')}
                        error={Boolean(this.state.emailError)}
                        helperText={this.state.emailError}
                        autoFocus
                        inputRef={this.emailFieldRef}
                    />
                    <TextField
                        className={classNames(classes.field, classes.password)}
                        variant="outlined"
                        type={this.state.showPassword ? 'text' : 'password'}
                        label={gettext('Password')}
                        value={this.state.password}
                        onChange={this.handleFieldChange('password')}
                        error={Boolean(this.state.passwordError)}
                        helperText={this.state.passwordError}
                        inputRef={this.passwordFieldRef}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment variant="filled" position="end">
                                    <IconButton
                                        aria-label="Toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                    >
                                        {this.state.showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {this.state.displayConfirmationCode && (
                        <div className={classes.confirmationCodeSection}>
                            <div className={classes.confirmationCodeRow}>
                                <Typography variant="body2">
                                    {gettext(
                                        'One more step. We sent a confirmation code to the email you entered. Please enter that code.'
                                    )}
                                </Typography>
                                <TextField
                                    className={classNames(classes.field, classes.confirmationCode)}
                                    variant="outlined"
                                    value={this.state.confirmationCode}
                                    onChange={this.handleFieldChange('confirmationCode')}
                                    InputProps={{ style: { height: '35px' } }}
                                />
                            </div>
                            <Typography
                                className={classes.confirmationCodeError}
                                variant="body2"
                                color="error"
                            >
                                {this.state.confirmationCodeError}
                            </Typography>
                            <ResendButton
                                secondsRemaining={this.state.resendSecondsRemaining}
                                onClick={this.handleResendConfirmationCode}
                            />
                        </div>
                    )}
                    {this.state.errorMessage && (
                        <Typography className={classes.errorMessage} color="error" variant="body2">
                            {this.state.errorMessage}
                        </Typography>
                    )}
                    <div className={classes.submitContainer}>
                        {this.state.loggingIn && (
                            <CircularProgress className={classes.loginProgress} size={25} />
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.submitButton}
                            disabled={this.state.loggingIn}
                            type="submit"
                        >
                            {gettext('Log in')}
                        </Button>
                    </div>
                </Paper>
            </form>
        )
    }
}

export default withStyles(styles)(Login)
