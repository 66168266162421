import { type MouseEvent, useRef, useMemo, useCallback } from 'react'
import { useFormState, useWatch } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'

import { useProductFormContext } from '../hooks/useProductForm'
import { useProductPage } from '../hooks/useProductPage'
import { useProductPageActions } from '../hooks/useProductPageStore'

import ErrorSummary from './errors-summary'

import { type ProductDetailResponse, queryKeys, api, useApiQueryClient } from '~/api'
import { type Product } from '~/common/schemas/product'
import { getPath } from '~/tools/utils'

type Props = {
    showErrorSummary: boolean
    isSaving: boolean
    onSubmit: (e: MouseEvent, createAnother?: boolean) => void
    onErrorSummaryChange: (state: boolean) => void
    onDeleteProduct: () => void
}

export default function ProductActions({
    isSaving,
    showErrorSummary,
    onSubmit,
    onErrorSummaryChange,
    onDeleteProduct,
}: Props) {
    const { isNewProduct, productInfo } = useProductPage()
    const { openDialog } = useProductPageActions()
    const { control } = useProductFormContext()
    const { errors } = useFormState({ control })
    const formHasErrors = Object.keys(errors).length > 0

    const productImages = useWatch({ control, name: 'images' })
    const allProductImagesUploaded = useMemo(
        () => checkImagesUploaded(productImages),
        [productImages]
    )

    const stockUnits = useWatch({ control, name: 'stock_units' })
    const allStockUnitImagesUploaded = useMemo(() => {
        return stockUnits.every(({ images }) => checkImagesUploaded(images))
    }, [stockUnits])

    const channelProducts = useWatch({ control, name: 'channel_products' })
    const allChannelStockUnitImagesUploaded = useMemo(() => {
        return channelProducts
            .filter((cp) => cp.has_channel_specific_media)
            .every(({ channel_stock_units }) => {
                return channel_stock_units.every(({ images }) => checkImagesUploaded(images))
            })
    }, [channelProducts])

    const allChannelProductImagesUploaded = useMemo(() => {
        return channelProducts
            .filter((cp) => cp.has_channel_specific_media)
            .every(({ images }) => checkImagesUploaded(images))
    }, [channelProducts])

    const allImagesUploaded =
        allProductImagesUploaded &&
        allStockUnitImagesUploaded &&
        allChannelProductImagesUploaded &&
        allChannelStockUnitImagesUploaded

    const canSave = useMemo(() => {
        return allImagesUploaded && !isSaving
    }, [allImagesUploaded, isSaving])

    const closeErrorSummary = useCallback(() => onErrorSummaryChange(false), [onErrorSummaryChange])
    const openErrorSummary = useCallback(() => onErrorSummaryChange(true), [onErrorSummaryChange])

    const ref = useRef<HTMLDivElement>(null)

    return (
        <Box
            ref={ref}
            sx={{
                mx: -2.5,
                px: 2.5,
                py: 1.5,
                position: 'fixed',
                bottom: 0,
                display: 'flex',
                bgcolor: 'background.paper',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: 'calc(100% - 200px)',
                borderTop: '1px solid',
                borderColor: 'grey.200',
                zIndex: 'appBar',
            }}
        >
            <Button
                color="primary"
                component={RouterLink}
                to={getPath('products')}
                startIcon={<ArrowBackIcon />}
            >
                {gettext('Back to products')}
            </Button>

            {!isNewProduct && <ProductStatus product={productInfo.product} />}

            <Grid container spacing={1} sx={{ width: 'auto' }}>
                {formHasErrors && ref.current && (
                    <Grid item>
                        <ErrorSummary
                            open={showErrorSummary}
                            anchor={ref.current}
                            onOpen={openErrorSummary}
                            onClose={closeErrorSummary}
                        />
                    </Grid>
                )}

                {isNewProduct ? (
                    <Grid item>
                        <Button
                            color="primary"
                            variant="outlined"
                            disabled={!canSave}
                            onClick={(e) => onSubmit(e, true)}
                        >
                            {isSaving ? (
                                <CircularProgress size={24} />
                            ) : (
                                gettext('Save & create another')
                            )}
                        </Button>
                    </Grid>
                ) : (
                    <Grid item>
                        <Button startIcon={<DeleteIcon />} color="error" onClick={onDeleteProduct}>
                            {gettext('Delete product')}
                        </Button>
                    </Grid>
                )}

                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        sx={{ minWidth: 128 }}
                        disabled={!canSave}
                        onClick={(e) => {
                            if (isNewProduct) {
                                onSubmit(e)
                                return
                            }

                            if (productInfo.product.status !== 'active') {
                                openDialog('save-inactive')
                                return
                            }

                            onSubmit(e)
                        }}
                    >
                        {isSaving ? <CircularProgress size={24} /> : gettext('Save Product')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

function checkImagesUploaded(images: Array<{ status?: string | null }>) {
    return images.every((image) => image.status === 'uploaded')
}

function ProductStatus({ product }: { product: Product }) {
    const { enqueueSnackbar } = useSnackbar()
    const apiQueryClient = useApiQueryClient()

    function contextHasData(context: unknown): context is {
        previousProductData: ProductDetailResponse
    } {
        return typeof context === 'object' && context !== null && 'previousProductData' in context
    }

    const { mutate } = api.products.updateStatus.useMutation({
        onMutate: ({ body }) => {
            if (!product) {
                return
            }

            const previousProductData = apiQueryClient.products.detail.getQueryData(
                queryKeys.products.detail(product.id).queryKey
            )

            apiQueryClient.products.detail.setQueryData(
                queryKeys.products.detail(product.id).queryKey,
                (data) => {
                    if (!data) {
                        return
                    }

                    return {
                        ...data,
                        body: {
                            ...data.body,
                            ...body,
                        },
                    }
                }
            )

            return { previousProductData }
        },
        onError: (_error, _vars, context) => {
            if (!product || !contextHasData(context)) {
                return
            }

            enqueueSnackbar(gettext('Failed to update product status'), { variant: 'error' })

            apiQueryClient.products.detail.setQueryData(
                queryKeys.products.detail(product.id).queryKey,
                context.previousProductData
            )
        },
        onSuccess: () => {
            enqueueSnackbar(gettext('Product status updated'), {
                variant: 'success',
                SnackbarProps: { id: 'product-detail' },
            })
        },
    })

    return (
        <Stack direction="row" alignItems="center" gap={0.75}>
            <Typography variant="subtitle2" color="textSecondary">
                {gettext('Product Status')}
            </Typography>
            <Switch
                checked={product.status === 'active'}
                onChange={(_, checked) => {
                    const status = checked ? 'active' : 'inactive'
                    mutate({
                        body: { status },
                        params: { id: product.id },
                    })
                }}
            />
            <Typography variant="subtitle2" style={{ textTransform: 'capitalize' }}>
                {product.status === 'active' ? gettext('Active') : gettext('Inactive')}
            </Typography>
        </Stack>
    )
}
