import { memo, useMemo, useRef } from 'react'
import { type FieldValues, useController, type UseControllerProps } from 'react-hook-form'
import ReactQuill, { Quill } from 'react-quill'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'
import { type Quill as TQuill } from 'quill'
// eslint-disable-next-line import/default
import QuillImageDropAndPaste, { type ImageData } from 'quill-image-drop-and-paste'

import { stripHTMLTags } from '~/tools/utils'

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

export type Props<T extends FieldValues> = UseControllerProps<T> & {
    readonly?: boolean
    maxLength?: number
    showMaxLength?: boolean
    onImageUpload?: (files: File[], quill: TQuill) => Promise<void>
}

const useStyles = makeStyles((theme) => ({
    textEditor: {
        '& > .ql-toolbar': {
            borderTopLeftRadius: theme.spacing(0.5),
            borderTopRightRadius: theme.spacing(0.5),
        },

        '& > .ql-container': {
            borderBottomLeftRadius: theme.spacing(0.5),
            borderBottomRightRadius: theme.spacing(0.5),
        },
    },
    textEditorError: {
        '& > .ql-toolbar': {
            borderTopColor: theme.palette.error.main,
            borderRightColor: theme.palette.error.main,
            borderLeftColor: theme.palette.error.main,
        },

        '& > .ql-container': {
            borderColor: theme.palette.error.main,
        },
    },
    textInfo: {
        '& > p, span': {
            marginTop: theme.spacing(1),
        },
    },
    textError: {
        display: 'inline-block',
        paddingLeft: theme.spacing(1),
    },
}))

const FormRichText = <T extends FieldValues>({
    name,
    control,
    maxLength,
    showMaxLength,
    readonly,
    rules,
    defaultValue,
    onImageUpload,
}: Props<T>) => {
    const classes = useStyles()
    const ref = useRef<ReactQuill | null>(null)
    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules, defaultValue })
    const shouldShowError = !!error

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['link', onImageUpload && 'image'],
                    ['clean'],
                ],
                handlers: {
                    image: () => {
                        const input = document.createElement('input')
                        input.setAttribute('type', 'file')
                        input.setAttribute('accept', 'image/jpeg,image/png')
                        input.setAttribute('multiple', '')
                        input.click()

                        input.onchange = async () => {
                            if (!input.files || !ref.current) {
                                return
                            }

                            const files = Array.from(input.files)

                            return onImageUpload?.(files, ref.current.getEditor())
                        }
                    },
                },
            },
            clipboard: {
                matchVisual: false,
            },
            imageDropAndPaste: {
                handler: async (_: string, __: string, imageData: ImageData) => {
                    if (!onImageUpload || !ref.current) {
                        return
                    }
                    const file = imageData.toFile('tmp_image.png')
                    if (!file) {
                        return
                    }

                    await onImageUpload([file], ref.current.getEditor())
                },
            },
        }),
        [onImageUpload]
    )

    return (
        <div>
            <ReactQuill
                ref={(instance) => {
                    field.ref({
                        focus: () => instance?.getEditor(),
                    })
                    ref.current = instance
                }}
                readOnly={readonly}
                value={field.value ?? ''}
                className={classNames(
                    classes.textEditor,
                    shouldShowError && classes.textEditorError
                )}
                modules={modules}
                onChange={(content) => {
                    field.onChange(content)
                }}
            />

            <div className={classes.textInfo}>
                {shouldShowError && (
                    <Typography className={classes.textError} variant="caption" color="error">
                        {error.message}
                    </Typography>
                )}
                {showMaxLength && (
                    <Typography variant="body2" color="textSecondary">
                        {stripHTMLTags(field.value).length}/{maxLength ?? '-'}
                    </Typography>
                )}
            </div>
        </div>
    )
}

export default memo(FormRichText) as typeof FormRichText
