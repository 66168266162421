import moment from 'moment'

export const getTotalRevenue = (timeseriesData) => {
    return timeseriesData.map((d) => parseFloat(d.revenue)).reduce((a, b) => a + b, 0)
}

export const getTotalUnitsSold = (timeseriesData) => {
    return timeseriesData.map((d) => d.units_sold).reduce((a, b) => a + b, 0)
}

export const getTotalNumSales = (timeseriesData) => {
    return timeseriesData.map((d) => d.num_sales).reduce((a, b) => a + b, 0)
}

export const getOverallAOV = (timeseriesData) => {
    const totalNumSales = getTotalNumSales(timeseriesData)
    const aov = totalNumSales > 0 ? getTotalRevenue(timeseriesData) / totalNumSales : 0
    return Math.round(aov)
}

export const abbreviateNumber = (number, decPlaces) => {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces)

    // Enumerate number abbreviations
    var abbrev = ['k', 'm', 'b', 't']

    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10, (i + 1) * 3)

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            number = Math.round((number * decPlaces) / size) / decPlaces

            // Handle special case where we round up to the next abbreviation
            if (number == 1000 && i < abbrev.length - 1) {
                number = 1
                i++
            }

            // Add the letter for the abbreviation
            number += abbrev[i]

            // We are done... stop
            break
        }
    }

    return number
}

export const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getDataWithAOV = (data) => {
    data.forEach((d) => {
        const aov = d.num_sales > 0 ? parseFloat(d.revenue) / d.num_sales : 0
        const roundedAOV = Math.round(aov * 100) / 100

        d.aov = roundedAOV
    })

    return data
}

export const getTickValues = (xValues, targetNumberOfTicks) => {
    let numTicksRatio = xValues.length / targetNumberOfTicks

    if (numTicksRatio < 1) {
        numTicksRatio = 1
    } else {
        numTicksRatio = Math.round(numTicksRatio)
    }

    return xValues.filter((xValue, i) => i % numTicksRatio === 0)
}

export const getChangePercent = (value, previousValue) => {
    if (previousValue > 0) {
        const changePercent = value / previousValue - 1
        if (changePercent <= 0) {
            return `${Math.round(changePercent * 100)}%`
        } else {
            return `+${Math.round(changePercent * 100)}%`
        }
    } else {
        if (value > 0) {
            return '-%'
        } else {
            return '0%'
        }
    }
}

export const daysInRange = (startDate, endDate) => {
    return endDate.diff(startDate, 'days') + 1
}

export const getCurrentPeriodData = (startDate, endDate, data) => {
    return data.filter((d) => {
        const date = moment(d.date)
        return date.isSameOrAfter(startDate) && date.isSameOrBefore(endDate)
    })
}

export const getPreviousPeriodData = (startDate, endDate, data, makeDatesCurrentPeriod = false) => {
    const days = daysInRange(startDate, endDate)

    const previousStartDate = startDate.clone().subtract(days, 'days')
    const previousEndDate = endDate.clone().subtract(days, 'days')

    let filteredData = data.filter((d) => {
        const date = moment(d.date)
        return date.isSameOrAfter(previousStartDate) && date.isSameOrBefore(previousEndDate)
    })

    if (makeDatesCurrentPeriod) {
        filteredData.forEach((d) => {
            d.date = moment(d.date).add(days, 'days').toISOString(true)
        })
    }

    return filteredData
}
