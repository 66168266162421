import { grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'

export * from './theme'

export const useTableStyles = makeStyles(() => ({
    tableContainer: {
        marginTop: 25,
    },
    table: {},
    row: {
        marginBottom: 8,
    },
    tableCell: {
        padding: '0 15px',
    },
}))

export const useFormStyles = makeStyles(() => ({
    checkbox: {
        color: grey[500],
        padding: 2,
    },
    textField: {
        width: '100%',
    },
}))
