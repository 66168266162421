import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import PropTypes from 'prop-types'

import PermissionsSelectLevel from '../permissions-select-level'

import { useStyles } from './styles'

export default function PermissionLevel({ value = '', onChange }) {
    const classes = useStyles()
    return (
        <TableRow>
            <TableCell colSpan={2} className={classes.tableCell}>
                <div className={classes.tableCellContainer}>
                    {/* <FormControl> */}
                    {/* <InputLabel htmlFor="permissionLevel">Select Permission Level</InputLabel> */}
                    <Typography variant="subtitle2" style={{ marginRight: 15 }}>
                        {gettext('Select Permission Level')}
                    </Typography>
                    <PermissionsSelectLevel
                        id="permissionLevel"
                        value={value}
                        // defaultValue={permissionLevel}
                        onChange={onChange}
                    />
                    {/* }</FormControl> */}
                </div>
            </TableCell>
        </TableRow>
    )
}

PermissionLevel.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}
