import { connect } from 'react-redux'

import { updateStore } from '../../oakra/actions/stores'
import Integrations from '../components/integrations'

const mapStateToProps = (state) => ({
    shopsForUserAPIURL: state.initial.endpoints.shopsForUserAPIURL,
    shopAPIURL: state.initial.endpoints.shopAPIURL,
    storeAPIURL: state.initial.endpoints.storeAPIURL,
    channelAuthLinkURL: state.initial.endpoints.channelAuthLinkURL,
    language: state.initial.language,
    userID: state.initial.user.id,
    stores: state.stores,
})

const matchDispatchToProps = (dispatch) => ({
    updateStore: (store) => {
        dispatch(updateStore(store))
    },
})

export default connect(mapStateToProps, matchDispatchToProps)(Integrations)
