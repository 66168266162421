import React from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'

import { get } from '../../tools/request'

import AddressManager from './address-manager'

const styles = (theme) => ({
    dialogContent: {
        minHeight: 350,
    },
    dialogActions: {
        paddingRight: 24,
        paddingLeft: 24,
        paddingBottom: 15,
        marginTop: 0,
    },
    loadingIndicator: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 200,
    },
})

class AddressManagerDialog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: null,
            loading: false,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        const { addressesAPIURL, customerId } = this.props

        this.setState({ loading: true })

        get(addressesAPIURL, { customer_id: customerId }).then((res) => {
            if (res.ok) {
                this.setState({ data: res.results })
            }
            this.setState({ loading: false })
        })
    }

    handleChange = () => {
        this.fetchData()
    }

    render() {
        const { classes, dialogTitle, onClose, ...props } = this.props
        const { loading, data } = this.state

        return (
            <Dialog open maxWidth="md" fullWidth className={classes.dialog} onClose={onClose}>
                <DialogTitle>{dialogTitle || gettext('Select address')}</DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {loading && !data ? (
                        <div className={classes.loadingIndicator}>
                            <CircularProgress size={25} />
                        </div>
                    ) : (
                        <AddressManager {...props} data={data} onChange={this.handleChange} />
                    )}
                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                    <Button variant="contained" color="secondary" onClick={onClose}>
                        {gettext('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(AddressManagerDialog)
