import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useDebounce } from '@react-hook/debounce'

import type { Attribute, ChannelAttributeValueName } from './types'

import { api, queryKeys } from '~/api'
import { useLanguage } from '~/common/hooks'
import { useProductFormContext } from '~/products/hooks/useProductForm'
import type { AttributeOption, AttributeValue } from '~/products/types'
import { guards } from '~/products/utils'

type Props = {
    name: ChannelAttributeValueName
    attribute: Attribute
    categoryId: number
    shouldFetch: boolean
}

export default function AttributeAsync({ name, attribute, categoryId, shouldFetch }: Props) {
    const filter = createFilterOptions<AttributeOption>()
    const language = useLanguage()
    const { control, setValue } = useProductFormContext()
    const { field, fieldState } = useController({
        control,
        name,
        shouldUnregister: true,
        rules: {
            validate: (value) => {
                if (!value && attribute.required) {
                    return interpolate(gettext(`${attribute.name} is required`), [attribute.name])
                }

                return true
            },
        },
    })

    const [search, setSearch] = useDebounce('', 500)
    const { data, isLoading } = api.channels.attributeOptions.useQuery(
        queryKeys.channels.attributeOptions({
            attributePk: attribute.id,
            categoryId,
            language,
            search,
        }).queryKey,
        {
            params: { language, attributePk: attribute.id },
            query: { search, category_id: categoryId },
        },
        {
            enabled: !!search && shouldFetch,
            select: (data) => ({
                ...data,
                body: [...data.body].sort((a, b) => a.label.localeCompare(b.label)),
            }),
        }
    )

    const isMultiple = useMemo(
        () => attribute.attribute_type === 'multi_option',
        [attribute.attribute_type]
    )

    const value = useMemo(() => {
        const productAttributeValue = field.value as AttributeValue
        if (!productAttributeValue || guards.isLogisticsProviderArray(productAttributeValue)) {
            return isMultiple ? [] : null
        }

        if (typeof productAttributeValue === 'string') {
            return {
                label: productAttributeValue,
                value: productAttributeValue,
                inactive: false,
            }
        }

        if (guards.isStringArray(productAttributeValue)) {
            return productAttributeValue.map((value) => ({
                label: value,
                value: value,
                inactive: false,
            })) as AttributeOption[]
        }

        return productAttributeValue
    }, [isMultiple, field.value])

    return (
        <Autocomplete
            fullWidth
            size="small"
            value={value}
            options={data?.body ?? attribute.options}
            loading={shouldFetch && search ? isLoading : false}
            getOptionDisabled={(option) => option.inactive}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            multiple={isMultiple}
            filterOptions={(options, params) => {
                const filtered = filter(options, params)

                let existingValue = new Set<string>()
                if (guards.isStringArray(field.value)) {
                    existingValue = new Set(field.value)
                } else if (guards.isOptionArray(field.value)) {
                    existingValue = new Set(field.value.map((v) => v.value))
                } else if (guards.isOption(field.value)) {
                    existingValue.add(field.value.value)
                }

                if (params.inputValue === '') {
                    return attribute.options.map((option) => ({
                        ...option,
                        inactive: existingValue.has(option.value),
                    }))
                }

                if (attribute.can_create_option && filtered.length === 0) {
                    filtered.push({
                        value: params.inputValue,
                        label: `Add "${params.inputValue}"`,
                        inactive: existingValue.has(params.inputValue),
                    })
                }

                return filtered.map((option) => ({
                    ...option,
                    inactive: existingValue.has(option.value),
                }))
            }}
            onInputChange={(_, __, reason) => {
                if (reason !== 'clear') {
                    return
                }

                const value = isMultiple ? ([] as string[]) : ''
                setValue(name, value)
            }}
            onChange={(_, value) => {
                if (!value) {
                    return
                }

                if (guards.isOption(value) && value.label.startsWith('Add')) {
                    return field.onChange({
                        label: value.value,
                        value: value.value,
                        inactive: false,
                    })
                }

                field.onChange(value)
            }}
            renderInput={(params) => (
                <TextField
                    {...field}
                    {...params}
                    required={attribute.required}
                    label={attribute.name}
                    margin="dense"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message ?? attribute.tip}
                    onChange={(e) => setSearch(e.target.value)}
                />
            )}
        />
    )
}
