/**
 * Converts a string to title case.
 * @param {string} str - The input string.
 * @returns {string} - The string converted to title case.
 */
export const toTitleCase = (str) => {
    if (!str) return str
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

export const sortArrayByField = (arr, field, alg) => {
    let compareFn
    if (!alg) {
        compareFn = sortByFieldDefault(field)
    } else {
        compareFn = alg(field)
    }

    arr.sort(compareFn)
    return arr
}

export const sortByFieldDefault = (field) => (a, b) => {
    return a[field] - b[field]
}

export const sortByFieldTextAsc = (field) => (a, b) => {
    if (a[field] > b[field]) {
        return 1
    }

    return b[field] > a[field] ? -1 : 0
}

export const sortByFieldTextDesc = (field) => (a, b) => {
    if (a[field] < b[field]) {
        return 1
    }

    return b[field] < a[field] ? -1 : 0
}

export const getFieldError = (errors = [], fieldName = '') => {
    const match = errors.find((err) => !!err[fieldName])
    return match ? match[fieldName] : undefined
}

export const buildErrorProps = (errors = [], fieldName = '') => ({
    error: !!getFieldError(errors, fieldName),
    helperText: getFieldError(errors, fieldName) || undefined,
})
