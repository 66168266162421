import { type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table'

import { useShopeeLogistics } from '../../hooks/useShopeeLogistics'
import { ShopeeLogisticsProviders } from '../shopee-attributes'

import type { ShopeeLogisticsProvider } from '~/common/schemas/logistics-shopee'
import { useProductFormContext, type ProductFormSchema } from '~/products/hooks/useProductForm'
import { guards } from '~/products/utils'
import { getProviderLimits } from '~/products/utils/getProviderLimits'
import { arraysEqual } from '~/tools/utils'

type ChannelProduct = ProductFormSchema['channel_products'][0]

type Props = {
    name: `channel_attribute_values.${string}.product.${string}.value`
    shopeeProducts: ChannelProduct[]
    categoryId: number
    shopIds: number[]
    countryCode: string | null
}

export default function ShopeeLogistics({ name, shopeeProducts, shopIds, countryCode }: Props) {
    const { setValue } = useProductFormContext()
    const {
        shopeeLogistics: logisticsProviders,
        fetchShopeeLogisticsProviders,
        fetchShopeeLogisticsValues,
        updateShopeeLogistics,
        syncShopeeLogisticsShops,
    } = useShopeeLogistics()

    const [editingProvidersForChannelProductId, setEditingProvidersForChannelProductId] = useState<
        string | number
    >(-1)

    const editingProvidersForChannelProduct = useMemo(() => {
        return shopeeProducts.find((cp) => cp.id === editingProvidersForChannelProductId) ?? null
    }, [editingProvidersForChannelProductId, shopeeProducts])

    const [channelProductProvidersOpen, setChannelProductProvidersOpen] = useState(false)

    useEffect(() => {
        async function fetchShopeeLogistics() {
            await fetchShopeeLogisticsProviders(shopIds)
            if (!shopeeProducts) {
                return
            }

            const alreadyCreatedShopeeProductIds = shopeeProducts.reduce((acc, cp) => {
                if (typeof cp.id == 'number') {
                    acc.push(cp.id)
                }

                return acc
            }, [] as number[])
            if (!alreadyCreatedShopeeProductIds.length) {
                return
            }

            await fetchShopeeLogisticsValues(alreadyCreatedShopeeProductIds)
        }

        void fetchShopeeLogistics()
    }, [fetchShopeeLogisticsProviders, fetchShopeeLogisticsValues, shopIds, shopeeProducts])

    const previousShopIds = useRef(shopeeProducts.map((cp) => cp.shop.id))
    useEffect(() => {
        const shopIDs = shopeeProducts.map((cp) => cp.shop.id)

        if (!arraysEqual(previousShopIds.current, shopIDs)) {
            previousShopIds.current = shopIDs
            void syncShopeeLogisticsShops(shopeeProducts, logisticsProviders)
        }
    }, [logisticsProviders, shopeeProducts, syncShopeeLogisticsShops])

    const handleEditChannelProductProviders = useCallback((channelProductId: string | number) => {
        setChannelProductProvidersOpen(true)
        setEditingProvidersForChannelProductId(channelProductId)
    }, [])

    const saveProviders = useCallback(
        (shopId: number) => {
            return (providersForShop: ShopeeLogisticsProvider[]) => {
                const updatedProviders = providersForShop.filter((p) => p.enabled)

                updateShopeeLogistics({
                    ...logisticsProviders,
                    [shopId]: providersForShop,
                })

                setValue(`${name}.shop-${shopId}.value`, updatedProviders, {
                    shouldValidate: true,
                })
            }
        },
        [logisticsProviders, updateShopeeLogistics, setValue, name]
    )

    return (
        <>
            <LogisticsTable
                logisticsProviders={logisticsProviders}
                shopeeProducts={shopeeProducts}
                onEditClick={handleEditChannelProductProviders}
            />

            {editingProvidersForChannelProduct && (
                <Dialog
                    open={channelProductProvidersOpen}
                    TransitionProps={{
                        onExited: () => setEditingProvidersForChannelProductId(-1),
                    }}
                    onClose={() => setChannelProductProvidersOpen(false)}
                >
                    <DialogTitle>{editingProvidersForChannelProduct.shop.shop_name}</DialogTitle>

                    <DialogContent>
                        <Typography gutterBottom variant="subtitle2">
                            {gettext('Logistics providers')}
                        </Typography>

                        <ShopeeLogisticsProviders
                            providersForShop={
                                logisticsProviders[editingProvidersForChannelProduct.shop.id] ?? []
                            }
                            countryCode={countryCode}
                            onSave={saveProviders(editingProvidersForChannelProduct.shop.id)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            onClick={() => setChannelProductProvidersOpen(false)}
                        >
                            {gettext('Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

const columnHelper = createColumnHelper<ChannelProduct>()

type LogisticsTableProps = {
    shopeeProducts: ChannelProduct[]
    logisticsProviders: Record<number, ShopeeLogisticsProvider[]>
    onEditClick: (channelProductId: string | number) => void
}

function LogisticsTable({ shopeeProducts, logisticsProviders, onEditClick }: LogisticsTableProps) {
    const uniqueShopeeChannelProducts = useMemo(() => {
        const existingShops = new Set<number>()

        return shopeeProducts.filter((cp) => {
            if (existingShops.has(cp.shop.id)) {
                return false
            }

            existingShops.add(cp.shop.id)
            return true
        })
    }, [shopeeProducts])

    const columns = useMemo(
        () => [
            columnHelper.accessor('shop.shop_name', {
                header: gettext('Shop'),
                cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
            }),
            columnHelper.accessor('shop.channel.id', {
                id: 'enabled-logistics',
                header: gettext('Enabled logistics'),
                cell: ({ getValue, row }) => (
                    <LogisticsTableCell
                        channelId={getValue()}
                        logisticsProviders={logisticsProviders[row.original.shop.id]}
                    />
                ),
            }),
            columnHelper.display({
                id: 'edit-logistics',
                header: gettext('Edit'),
                cell: ({ row }) => (
                    <IconButton color="secondary" onClick={() => onEditClick(row.original.id)}>
                        <EditIcon />
                    </IconButton>
                ),
            }),
        ],
        [logisticsProviders, onEditClick]
    )

    const table = useReactTable({
        columns,
        data: uniqueShopeeChannelProducts,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <TableContainer sx={{ mt: 1, mb: 2 }}>
            <Table size="small">
                <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <TableCell
                                    key={header.id}
                                    align={
                                        header.id.includes('edit-logistics') ? 'center' : 'inherit'
                                    }
                                >
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map((row) => (
                        <LogisticsTableRow
                            key={row.id}
                            channelId={row.original.shop.channel.id}
                            shopId={row.original.shop.id}
                        >
                            {row.getVisibleCells().map((cell) => (
                                <TableCell
                                    key={cell.id}
                                    align={
                                        cell.id.includes('edit-logistics') ? 'center' : 'inherit'
                                    }
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                            ))}
                        </LogisticsTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

type LogisticsTableRowProps = {
    channelId: number
    shopId: number
    children: ReactNode
}

function LogisticsTableRow({ channelId, shopId, children }: LogisticsTableRowProps) {
    function isSellerOwnFleet(value: ShopeeLogisticsProvider[]): boolean {
        return value.length === 1 && value[0].fee_type === 'CUSTOM_PRICE'
    }

    const { register, getValues, resetField } = useProductFormContext()
    const { ref, name } = register(
        `channel_attribute_values.${channelId}.product.logistics.value.shop-${shopId}.value`,
        {
            validate: (value, form) => {
                if (!value || !guards.isLogisticsProviderArray(value)) {
                    return gettext('No logistics providers selected. You must select at least one.')
                }

                const enabledProviders = value.filter((lp) => lp.enabled)
                if (enabledProviders.length === 0) {
                    return gettext('No logistics providers selected. You must select at least one.')
                }

                if (isSellerOwnFleet(enabledProviders)) {
                    return true
                }

                const productWeight = form.package_weight
                const limits = getProviderLimits(value)
                if (typeof productWeight === 'number' && productWeight > limits.weight.maxValue) {
                    return gettext(
                        'The product weight exceeds the maximum weight allowed by the logistics provider.'
                    )
                }

                return true
            },
        }
    )

    useEffect(() => {
        const name =
            `channel_attribute_values.${channelId}.product.logistics.value.shop-${shopId}` as const
        const existingValue = getValues(name)
        if (!existingValue.channel || !existingValue.value) {
            resetField(name, { defaultValue: { channel: 'shopee', value: [] } })
        }
    }, [channelId, shopId, getValues, resetField])

    return (
        <TableRow data-form-name={name} ref={ref}>
            {children}
        </TableRow>
    )
}

function LogisticsTableCell({
    channelId,
    logisticsProviders = [],
}: {
    channelId: number
    logisticsProviders?: ShopeeLogisticsProvider[]
}) {
    const { formState } = useProductFormContext()
    const channelAttributeValuesErrors =
        formState.errors.channel_attribute_values?.[channelId]?.product?.logistics?.value

    const enabledLogisticsProviders = useMemo(
        () => logisticsProviders.filter((lp) => lp.enabled),
        [logisticsProviders]
    )

    if (enabledLogisticsProviders.length === 0) {
        return (
            <Typography variant="body2">
                {gettext('No logistics providers selected. You must select at least one.')}
            </Typography>
        )
    }

    return (
        <>
            <Typography gutterBottom>
                {enabledLogisticsProviders.map((lp) => lp.logistic_name).join(', ')}
            </Typography>
            {channelAttributeValuesErrors && (
                <Typography color="error" variant="subtitle2">
                    {channelAttributeValuesErrors.message}
                </Typography>
            )}
        </>
    )
}
