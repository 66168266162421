import React from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'

import classNames from 'classnames'

import { DebouncedTextField } from '../../../oakra/components/inputs'
import { patch } from '../../../tools/request'

import BulkUpdateDialog from './bulk-update-dialog'
import BaseTable from './table'

export default class ConfigTable extends BaseTable {
    state = { editingRow: {}, showBulkUpdateDialog: false }

    columns = [
        {
            id: 'should_calculate_low_stock',
            align: 'center',
            label: gettext('Low stock'),
            sort: false,
            width: 100,
        },
        {
            id: 'days_till_empty_threshold',
            align: 'center',
            label: gettext('Days till empty threshold'),
            sort: false,
            width: 100,
        },

        {
            id: 'should_calculate_slow_selling',
            align: 'center',
            label: gettext('Slow selling'),
            sort: false,
            width: 100,
        },
        {
            id: 'units_sold_during_period_threshold',
            align: 'center',
            label: gettext('Slow selling threshold'),
            sort: false,
            width: 300,
        },
        { id: 'action', align: 'center', label: '', sort: false, width: 100 },
    ]

    filterOptions = [
        {
            field: 'Low stock',
            lookup: 'stock_item__should_calculate_low_stock',
            select_multiple: false,
            values: [
                { value: 'true', label: 'Enabled' },
                { value: 'false', label: 'Disabled' },
            ],
        },
        {
            field: 'Slow selling',
            lookup: 'stock_item__should_calculate_slow_selling',
            select_multiple: false,
            values: [
                { value: 'true', label: 'Enabled' },
                { value: 'false', label: 'Disabled' },
            ],
        },
    ]

    renderToolbarRight = () => {
        const { selected, onAction } = this.props
        const { showBulkUpdateDialog } = this.state

        return selected.length > 0 ? (
            <React.Fragment>
                <Button
                    color="secondary"
                    onClick={() => this.setState({ showBulkUpdateDialog: true })}
                >
                    {gettext('Bulk update')}
                </Button>

                <BulkUpdateDialog
                    open={showBulkUpdateDialog}
                    onSubmit={this.handleSubmitBulkUpdate}
                    onClose={() => this.setState({ showBulkUpdateDialog: false })}
                />
            </React.Fragment>
        ) : null
    }

    handleSubmitBulkUpdate = (data) => {
        this.props.onAction('bulk_update', data)
        this.setState({ showBulkUpdateDialog: false })
    }

    handleEditClick = (row) => {
        const stock_item = row.stock_item

        this.setState({
            editingRow: {
                id: stock_item.id,
                should_calculate_low_stock: stock_item.should_calculate_low_stock,
                days_till_empty_threshold: stock_item.days_till_empty_threshold,

                should_calculate_slow_selling: stock_item.should_calculate_slow_selling,
                units_sold_during_period_threshold: stock_item.units_sold_during_period_threshold,
                slow_selling_period_size: stock_item.slow_selling_period_size,
            },
        })
    }

    handleSaveClick = () => {
        const { id, ...updateArgs } = this.state.editingRow
        const {
            endpoints: { stockItemAPIURL },
            onDataUpdated,
        } = this.props
        patch(stockItemAPIURL.replace('stock_item_pk', id), updateArgs).then((res) => {
            onDataUpdated?.(res)
            this.setState({ editingRow: {} })
        })
    }

    handleCancelClick = () => {
        this.setState({ editingRow: {} })
    }

    handleInlineChange = (values) => {
        this.setState(({ editingRow }) => ({ editingRow: { ...editingRow, ...values } }))
    }

    renderCells = (row) => {
        const { classes } = this.props
        const { editingRow } = this.state

        const stock_item = row.stock_item
        const editing = editingRow.id === stock_item.id

        if (editing) {
            return (
                <React.Fragment>
                    <TableCell className={classNames(classes.tableCell, classes.centerAlign)}>
                        <Checkbox
                            checked={editingRow.should_calculate_low_stock}
                            onChange={(e) =>
                                this.handleInlineChange({
                                    should_calculate_low_stock: e.target.checked,
                                })
                            }
                        />
                    </TableCell>

                    <TableCell className={classNames(classes.tableCell, classes.centerAlign)}>
                        <DebouncedTextField
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={editingRow.days_till_empty_threshold}
                            onChange={(e) =>
                                this.handleInlineChange({
                                    days_till_empty_threshold: e.target.value,
                                })
                            }
                            inputProps={{ className: classes.numberInput }}
                        />
                    </TableCell>

                    <TableCell className={classNames(classes.tableCell, classes.centerAlign)}>
                        <Checkbox
                            checked={editingRow.should_calculate_slow_selling}
                            onChange={(e) =>
                                this.handleInlineChange({
                                    should_calculate_slow_selling: e.target.checked,
                                })
                            }
                        />
                    </TableCell>

                    <TableCell className={classNames(classes.tableCell, classes.centerAlign)}>
                        <div>
                            <Box display="inline">
                                <DebouncedTextField
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    value={editingRow.units_sold_during_period_threshold}
                                    onChange={(e) =>
                                        this.handleInlineChange({
                                            units_sold_during_period_threshold: e.target.value,
                                        })
                                    }
                                    inputProps={{
                                        className: classes.numberInput,
                                        style: { width: 50 },
                                    }}
                                />
                            </Box>
                            <Box display="inline" className={classes.inlineText}>
                                {gettext('units in')}
                            </Box>

                            <Box display="inline">
                                <DebouncedTextField
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    value={editingRow.slow_selling_period_size}
                                    onChange={(e) =>
                                        this.handleInlineChange({
                                            slow_selling_period_size: e.target.value,
                                        })
                                    }
                                    inputProps={{
                                        className: classes.numberInput,
                                        style: { width: 50 },
                                    }}
                                    display="inline"
                                />
                            </Box>
                            <Box display="inline" className={classes.inlineText}>
                                {gettext('days')}
                            </Box>
                        </div>
                    </TableCell>

                    <TableCell className={classNames(classes.tableCell, classes.centerAlign)}>
                        <IconButton
                            color="primary"
                            size="small"
                            aria-label="save"
                            onClick={() => this.handleSaveClick(row)}
                        >
                            <SaveIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            size="small"
                            aria-label="cancel"
                            className={classes.textPrimary}
                            onClick={() => this.handleCancelClick(row)}
                        >
                            <CancelIcon fontSize="small" />
                        </IconButton>
                    </TableCell>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <TableCell className={classNames(classes.tableCell, classes.centerAlign)}>
                    <Checkbox checked={stock_item.should_calculate_low_stock} disabled />
                </TableCell>

                <TableCell className={classNames(classes.tableCell, classes.centerAlign)}>
                    <Typography noWrap={true} variant="body2">
                        {stock_item.days_till_empty_threshold}
                    </Typography>
                </TableCell>

                <TableCell className={classNames(classes.tableCell, classes.centerAlign)}>
                    <Checkbox checked={stock_item.should_calculate_slow_selling} disabled />
                </TableCell>

                <TableCell className={classNames(classes.tableCell, classes.centerAlign)}>
                    <Typography noWrap={true} variant="body2">
                        {stock_item.units_sold_during_period_threshold} {gettext('units in')}{' '}
                        {stock_item.slow_selling_period_size} {gettext('days')}
                    </Typography>
                </TableCell>

                <TableCell className={classNames(classes.tableCell, classes.centerAlign)}>
                    <IconButton
                        color="inherit"
                        className={classes.textPrimary}
                        size="small"
                        aria-label="edit"
                        onClick={() => this.handleEditClick(row)}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                </TableCell>
            </React.Fragment>
        )
    }
}
