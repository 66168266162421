import { useEffect } from 'react'
import { useWatch } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { MediaType } from '~/common/schemas/media'
import { MAX_SKU_IMAGES } from '~/constants'
import MediaShowcase from '~/products/components/media-showcase'
import { type ProductFormSchema, useProductFormContext } from '~/products/hooks/useProductForm'
import { getMediaIDSet, getMediaNameSet } from '~/products/utils/updateChannelProductMedia'

export default function ProductStockUnitImages({ index }: { index: number }) {
    const name = `stock_units.${index}` as const
    const imageName = `${name}.images` as const

    const { setValue, getValues, control } = useProductFormContext()
    const sku = useWatch({ control, name: `${name}.sku`, exact: true })
    const images = useWatch({ control, name: imageName, exact: true })

    /**
     * Update stock unit id for each images when images change and stock unit id is available
     * Update channel stock unit images for all channel product when stock unit images change
     */
    useEffect(() => {
        if (!areImagesReady(images)) {
            return
        }

        const channelProducts = getValues('channel_products')
        const id = getValues(`${name}.id`)
        if (typeof id === 'number') {
            images.forEach((_, index) => {
                setValue(`${imageName}.${index}.stock_unit`, id)
            })
        }

        setValue(
            'channel_products',
            updateChannelStockUnitImages(channelProducts, images, index, id)
        )
    }, [getValues, imageName, images, index, name, setValue])

    return (
        <Box mb={1}>
            <Box mt={1}>
                <Typography component="div" variant="body2">
                    {sku ? (
                        <>
                            <Typography variant="subtitle2" display="inline">
                                SKU
                            </Typography>
                            : {sku}
                        </>
                    ) : (
                        interpolate(gettext('Stock unit %s'), [index + 1])
                    )}
                </Typography>
            </Box>
            <MediaShowcase
                mediaPath="grouped_media"
                fieldPath={imageName}
                mediaType={MediaType.IMAGE}
                maxMediaObjs={MAX_SKU_IMAGES}
            />
        </Box>
    )
}

function areImagesReady(images: Array<{ status?: string | null }>) {
    if (!images.length) {
        return false
    }

    return images.every((image) => image.status !== 'ready')
}

function updateChannelStockUnitImages(
    channelProducts: ProductFormSchema['channel_products'],
    stockUnitImages: ProductFormSchema['stock_units'][0]['images'],
    stockUnitIndex: number,
    stockUnitId: string | number
) {
    const imageIds = getMediaIDSet(stockUnitImages)
    const imageNames = getMediaNameSet(stockUnitImages)

    // TODO: Improve this, currently very efficient
    return channelProducts.map((cp) => {
        /**
         * if channel product has specific images, return as is
         * These images are handled in the channel product edit component
         */
        if (cp.has_channel_specific_media) {
            return cp
        }

        const updatedChannelStockUnits = cp.channel_stock_units.map((csu, index) => {
            if (index !== stockUnitIndex) {
                return csu
            }

            // Remove deleted images
            const images = csu.images.filter((image) => {
                return image.stock_unit_image?.id
                    ? imageIds.has(image.stock_unit_image.id)
                    : imageNames.has(image.stock_unit_image?.name ?? '')
            })

            // Update or add images
            stockUnitImages.forEach((stockUnitImage) => {
                let image

                if (stockUnitImage.id) {
                    image = images.find(
                        (channelStockUnitImage) =>
                            channelStockUnitImage.stock_unit_image?.id === stockUnitImage.id
                    )
                } else {
                    image = images.find(
                        (channelStockUnitImage) =>
                            channelStockUnitImage.stock_unit_image?.name === stockUnitImage.name
                    )
                }

                if (image) {
                    image.display_order = stockUnitImage.display_order
                } else {
                    const channelStockUnitImage = {
                        stock_unit_image: stockUnitImage,
                        display_order: stockUnitImage.display_order,
                        stock_unit: typeof stockUnitId === 'number' ? stockUnitId : undefined,
                    }

                    images.push(channelStockUnitImage)
                }
            })

            return { ...csu, images: [...images].sort((a, b) => a.display_order - b.display_order) }
        })

        return { ...cp, channel_stock_units: updatedChannelStockUnits }
    })
}
