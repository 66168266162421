import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'

const steps = [gettext('Select channel'), gettext('Select store'), gettext('Authorize channel')]

export default function AddChannelStepper({ activeStep }) {
    return (
        <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
                return (
                    <Step key={index}>
                        <StepLabel>{step}</StepLabel>
                    </Step>
                )
            })}
        </Stepper>
    )
}
