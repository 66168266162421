import React from 'react'

import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import moment from 'moment'
import queryString from 'query-string'

import { get, post } from '../../../tools/request'

import ConfigTable from './config-table'
import LowStockTable from './low-stock-table'
import SlowSellingTable from './slow-selling-table'
import { styles } from './style'
import TabBar from './summary'

class StockReport extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selected: [],
            rowsPerPage: 25,
            dataSource: [],
            count: 0,
            loading: true,
        }
    }

    componentDidMount() {
        moment.locale(this.props.language)

        this.fetchData()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            this.fetchData(queryString.parse(nextProps.location.search))
        }
    }

    fetchData = (params) => {
        const { location, endpoints } = this.props
        const { view = 'has_low_stock', ...query } = params || queryString.parse(location.search)
        if (view !== 'setting') query[view] = true

        this.setState({ loading: true })

        return get(endpoints.stockItemReportsForUserAPIURL, query).then((data) => {
            const newState = {
                count: data.count,
                next: data.next,
                previous: data.previous,
                dataSource: data.results,
                loading: false,
            }

            this.setState(newState)
        })
    }

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState(() => ({
                selected: this.state.dataSource.map((p) => p.stock_item.id),
            }))
        } else {
            this.setState({ selected: [] })
        }
    }

    handleSort = (value) => {
        this.handleFilterChange({ order_by: value })
    }

    handleChecked = (event, checked, stockItemID) => {
        const { selected } = this.state
        let newSelected = [...selected]

        if (checked) {
            if (!selected.includes(stockItemID)) {
                newSelected.push(stockItemID)
            }
        } else {
            newSelected = newSelected.filter((id) => id !== stockItemID)
        }

        this.setState({ selected: newSelected })
    }

    handleChangePage = (event, page) => {
        this.handleFilterChange({ page: page + 1 })
    }

    handleActiveSwitch = () => {}

    handleFilterChange = (values, reset) => {
        const { history, location } = this.props

        if (!('page' in values)) {
            values.page = undefined
        }

        const search = reset ? values : { ...queryString.parse(location.search), ...values }
        history.push({
            pathname: location.pathname,
            search: queryString.stringify(search),
        })
    }

    handleSummaryClick = (action) => {
        this.handleFilterChange({ view: action }, true)
    }

    handleDataUpdated = (newData) => {
        this.setState(({ dataSource }) => {
            const newDataSource = dataSource.map((o) => {
                const { id, ...args } = newData
                if (o.stock_item.id === id) {
                    return { ...o, stock_item: { ...o.stock_item, ...args } }
                }
                return o
            })
            return { dataSource: newDataSource }
        })
    }

    handleAction = (action, args = {}) => {
        const { endpoints } = this.props
        const { selected } = this.state

        switch (action) {
            case 'export':
                window.location.href = queryString.stringifyUrl({
                    url: endpoints.stockItemReportsExportForUserAPIURL,
                    query: this.getQuery(),
                })
                return

            case 'disable_low_stock':
            case 'disable_slow_selling':
            case 'bulk_update':
                const postData = {
                    data: {
                        [action === 'disable_low_stock'
                            ? 'should_calculate_low_stock'
                            : 'should_calculate_slow_selling']: false,
                    },
                    pks: selected,
                    apply_to: 'input_pks',
                    ...args,
                }

                this.setState({ loading: true })

                return post(
                    queryString.stringifyUrl({
                        url: endpoints.stockItemReportBulkUpdateAPIURL,
                        query: this.getQuery(),
                    }),
                    postData
                ).then((res) => {
                    this.fetchData()
                    this.setState({ selected: [] })
                    return res
                })
                break
        }
    }

    getQuery = () => {
        const { view = 'has_low_stock', ...query } = queryString.parse(location.search)
        if (view !== 'setting') query[view] = true
        return query
    }

    render() {
        const { classes, language, stores, staticRoot, location, endpoints } = this.props
        const { count, selected, rowsPerPage, loading, dataSource } = this.state
        const params = queryString.parse(location.search)
        const page = (params.page || 1) - 1

        const orderBy = params.order_by || ''
        const view = params.view || 'has_low_stock'

        const tableProps = {
            classes,
            language,
            stores,
            staticRoot,
            orderBy,
            dataSource,
            loading,
            filterValues: params,
            selected,
            endpoints,
            pagination: { count, rowsPerPage, page, onPageChange: this.handleChangePage },
            onFilterChange: this.handleFilterChange,
            onSelectAllClick: this.handleSelectAllClick,
            onRequestSort: this.handleSort,
            onRowChecked: this.handleChecked,
            onDataUpdated: this.handleDataUpdated,
            onAction: this.handleAction,
        }

        return (
            <React.Fragment>
                <Paper className={classes.root}>
                    <TabBar
                        endpointUrl={endpoints.stockItemReportsSummaryForUserAPIURL}
                        onClick={this.handleSummaryClick}
                        value={view}
                    />
                    {view === 'has_low_stock' && <LowStockTable {...tableProps} />}
                    {view === 'is_slow_selling' && <SlowSellingTable {...tableProps} />}
                    {view === 'setting' && <ConfigTable {...tableProps} />}
                </Paper>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(StockReport)
