import { useState, useEffect, useMemo, useReducer, useCallback } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { UserPermissions } from './user-permissions'
import UserSelectGroups from './user-select-groups'

import { api, queryKeys } from '~/api'
import { useAppSelector } from '~/store'

const initialUserDetail = {
    id: undefined,
    account_owner: undefined,
    is_account_owner: undefined,
    groups: [],
    first_name: '',
    last_name: '',
    username: '',
    email: '',
}

function userDetailReducer(state, action) {
    switch (action.type) {
        case 'update':
            return { ...state, [action.name]: action.value }
        case 'reset':
            return { ...initialUserDetail, ...(action?.user ?? {}) }
        default:
            return state
    }
}

export default function UserDetailDialog({ isCreateUser = true, user, open = false, onClose }) {
    const classes = useStyles()
    const accountOwnerId = useAppSelector((state) => state.initial.accountOwner?.id)
    const { enqueueSnackbar } = useSnackbar()
    const queryClient = useQueryClient()

    const [activeTab, setActiveTab] = useState('user')
    const [createdUser, setCreatedUser] = useState(null)
    const [sendCredentials, setSendCredentials] = useState(false)

    const [userDetails, dispatch] = useReducer(userDetailReducer, initialUserDetail)
    useEffect(() => {
        dispatch({ type: 'reset', user })
    }, [user])

    const {
        data: permissionData,
        isLoading,
        error,
    } = api.users.permissions.useQuery(
        queryKeys.users.permissions(userDetails.id).queryKey,
        { params: { id: user?.id ?? 100 } },
        { enabled: !!user && activeTab === 'permissions' }
    )

    const [selectedPermissions, setSelectedPermissions] = useState([])
    useEffect(() => {
        if (permissionData) {
            setSelectedPermissions(permissionData.body)
        }
    }, [permissionData])

    const { createUser, updateUser, updateUserPermissions, isLoading: saving } = useUpdateUser()

    const permissionsModified = useMemo(() => {
        // If there is no permission data, we assume it is a new user and return true
        if (!permissionData) {
            return true
        }

        const currentPermissions = permissionData.body.map(({ codename }) => codename)
        const newPermissions = selectedPermissions.map(({ codename }) => codename)

        return (
            currentPermissions.length !== newPermissions.length ||
            currentPermissions.some((codename) => !newPermissions.includes(codename))
        )
    }, [permissionData, selectedPermissions])

    function updateUserDetail(e) {
        const { name, value } = e.target
        dispatch({ type: 'update', name, value })
    }

    function handleCloseDialog(user) {
        setCreatedUser(null)
        setSelectedPermissions([])
        dispatch({ type: 'reset' })
        onClose?.(user)
    }

    function handleCreateUser(payload) {
        return createUser(
            { body: payload },
            {
                onSuccess: (data) => {
                    setCreatedUser(data.body)
                    queryClient.invalidateQueries(
                        queryKeys.users.accountUserListing(accountOwnerId).queryKey
                    )

                    if (!selectedPermissions.length) {
                        enqueueSnackbar(gettext('User successfully created'), {
                            variant: 'success',
                        })
                        return
                    }

                    updateUserPermissions(
                        {
                            params: { id: data.body.user.id },
                            body: selectedPermissions,
                        },
                        {
                            onSuccess: () => {
                                enqueueSnackbar(gettext('User successfully created'), {
                                    variant: 'success',
                                })
                                queryClient.invalidateQueries(
                                    queryKeys.users.permissions(userDetails.id).queryKey
                                )
                            },
                        }
                    )
                },
                onError: (error) => {
                    if (error.status !== 400) {
                        enqueueSnackbar(gettext('Something went wrong'), { variant: 'error' })
                        return
                    }

                    enqueueSnackbar(
                        <List dense>
                            {Object.entries(error.body.error).map(([key, value]) => (
                                <ListItem key={key}>{value[0]}</ListItem>
                            ))}
                        </List>,
                        { variant: 'error' }
                    )
                },
            }
        )
    }

    function handleUpdateUser(payload) {
        return updateUser(
            { body: payload, params: { id: user.id } },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries(
                        queryKeys.users.accountUserListing(accountOwnerId).queryKey
                    )

                    if (!permissionsModified) {
                        handleCloseDialog(data.body)
                        enqueueSnackbar(gettext('User successfully updated'), {
                            variant: 'success',
                        })
                        return
                    }

                    updateUserPermissions(
                        { body: selectedPermissions, params: { id: data.body.id } },
                        {
                            onSuccess: () => {
                                queryClient.invalidateQueries(
                                    queryKeys.users.permissions(userDetails.id).queryKey
                                )
                                handleCloseDialog(data.body)
                                enqueueSnackbar(gettext('User successfully updated'), {
                                    variant: 'success',
                                })
                            },
                        }
                    )
                },
            }
        )
    }

    const renderPermissionsTab = useCallback(() => {
        if (isCreateUser) {
            return (
                <UserPermissions
                    showAdminToggle
                    permissionsMode="account"
                    currentPermissions={selectedPermissions}
                    onUpdatePermissions={setSelectedPermissions}
                />
            )
        }

        if (isLoading) {
            return (
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress size={26} color="secondary" />
                </Box>
            )
        }

        if (error) {
            return <Typography color="error">{gettext('Something went wrong')}</Typography>
        }

        return (
            <UserPermissions
                showAdminToggle
                permissionsMode="account"
                targetId={userDetails.id}
                currentPermissions={selectedPermissions}
                onUpdatePermissions={setSelectedPermissions}
            />
        )
    }, [selectedPermissions, error, isCreateUser, isLoading, userDetails?.id])

    const dialogTitle = useMemo(() => {
        if (createdUser) {
            return (
                <Box display="flex" alignItems="center">
                    <Box display="inline-block" mr={2}>
                        {gettext('User successfully created')}
                    </Box>
                    <CheckCircleIcon color="primary" />
                </Box>
            )
        }

        return isCreateUser ? gettext('Create user') : gettext('Edit user')
    }, [createdUser, isCreateUser])

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth="md"
            PaperProps={{ className: classes.dialog }}
            onClose={handleCloseDialog}
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                {createdUser ? (
                    <UserNewInfo
                        username={createdUser.user.username}
                        password={createdUser.password}
                    />
                ) : (
                    <form
                        id="user-form"
                        onSubmit={(e) => {
                            e.preventDefault()
                            const payload = {
                                ...userDetails,
                                account_owner: accountOwnerId,
                            }

                            return isCreateUser
                                ? handleCreateUser(payload)
                                : handleUpdateUser(payload)
                        }}
                    >
                        <Tabs
                            value={activeTab}
                            aria-label=""
                            onChange={(_event, value) => {
                                setActiveTab(value)
                            }}
                        >
                            <Tab
                                label="User Details"
                                value="user"
                                selected={activeTab === 'user'}
                            />
                            <Tab
                                label="Permissions"
                                value="permissions"
                                selected={activeTab === 'permissions'}
                            />
                        </Tabs>
                        {activeTab === 'user' && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mt: 2,
                                    gridGap: '0.5rem',
                                }}
                            >
                                <TextField
                                    required
                                    label={gettext('First name')}
                                    placeholder={gettext('First name')}
                                    color="secondary"
                                    margin="dense"
                                    name="first_name"
                                    value={userDetails?.first_name ?? ''}
                                    onChange={updateUserDetail}
                                />
                                <TextField
                                    required
                                    label={gettext('Last name')}
                                    placeholder={gettext('Last name')}
                                    color="secondary"
                                    margin="dense"
                                    name="last_name"
                                    value={userDetails?.last_name ?? ''}
                                    onChange={updateUserDetail}
                                />
                                <TextField
                                    required
                                    label={gettext('Username')}
                                    placeholder={gettext('Username')}
                                    color="secondary"
                                    margin="dense"
                                    name="username"
                                    value={userDetails?.username ?? ''}
                                    onChange={updateUserDetail}
                                    inputProps={{ minLength: 6 }}
                                />
                                <TextField
                                    required
                                    type="email"
                                    label={gettext('Email')}
                                    placeholder={gettext('Email')}
                                    color="secondary"
                                    margin="dense"
                                    name="email"
                                    value={userDetails?.email ?? ''}
                                    onChange={updateUserDetail}
                                />
                                {isCreateUser && (
                                    <Box
                                        color="grey.500"
                                        component={FormControlLabel}
                                        label={gettext('Send login credentials')}
                                        control={
                                            <Checkbox
                                                checked={sendCredentials}
                                                color="secondary"
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                onChange={() => {
                                                    setSendCredentials(!sendCredentials)
                                                }}
                                            />
                                        }
                                    />
                                )}
                                <UserSelectGroups
                                    groups={userDetails.groups}
                                    onGroupsChange={(groups) =>
                                        dispatch({ type: 'update', name: 'groups', value: groups })
                                    }
                                />
                            </Box>
                        )}
                        {activeTab === 'permissions' && renderPermissionsTab()}
                    </form>
                )}
            </DialogContent>
            <DialogActions>
                {createdUser ? (
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => handleCloseDialog(createdUser)}
                    >
                        {gettext('Close')}
                    </Button>
                ) : (
                    <>
                        <Button color="secondary" onClick={() => handleCloseDialog()}>
                            {gettext('Cancel')}
                        </Button>
                        <Button
                            type="submit"
                            form="user-form"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                if (activeTab !== 'user') {
                                    setActiveTab('user')
                                }
                            }}
                        >
                            {saving && (
                                <Box
                                    component={CircularProgress}
                                    mr={1}
                                    size={14}
                                    color="secondary"
                                />
                            )}
                            {isCreateUser ? gettext('Create user') : gettext('Save')}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    )
}

UserDetailDialog.propTypes = {
    isCreateUser: PropTypes.bool,
    user: PropTypes.shape({
        id: PropTypes.number,
        username: PropTypes.string,
        email: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        groups: PropTypes.arrayOf(PropTypes.number),
    }),
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

const useStyles = makeStyles(() => ({
    dialog: {
        height: '100vh',
        overflowY: 'auto',
    },
}))

function UserNewInfo({ username, password }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gridRowGap: '2rem', mt: 2.5 }}>
            <Box bgcolor="grey.100" sx={{ borderRadius: 5, py: 2.5, px: 3.5 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gridGap: '1rem' }}>
                    <InfoField label={gettext('Username')} value={username} />
                    <InfoField label={gettext('Password')} value={password} />
                </Box>
            </Box>
            <Typography color="textSecondary" variant="body2">
                {gettext(
                    'Please save this information. The user’s password will not be available for viewing again.'
                )}
            </Typography>
        </Box>
    )
}
UserNewInfo.propTypes = {
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
}

function InfoField({ label, value }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gridGap: '2rem' }}>
            <Typography color="textSecondary" variant="body2">
                {label}
            </Typography>
            <Typography component="span">{value}</Typography>
        </Box>
    )
}
InfoField.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
}

function useUpdateUser() {
    const {
        mutate: createUser,
        isLoading: isCreatingUser,
        error: createUserError,
    } = api.users.create.useMutation()
    const {
        mutate: updateUser,
        isLoading: isUpdatingUser,
        error: updateUserError,
    } = api.users.update.useMutation()
    const {
        mutate: updateUserPermissions,
        isLoading: isUpdatingPermissions,
        error: updateUserPermissionsError,
    } = api.users.updatePermissions.useMutation()

    const isLoading = isCreatingUser || isUpdatingUser || isUpdatingPermissions

    const errors = {
        userError: createUserError || updateUserError,
        permissionsError: updateUserPermissionsError,
    }

    return {
        createUser,
        updateUser,
        updateUserPermissions,
        isLoading,
        errors,
    }
}
