import { memo } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import { useDeleteProductMutation } from '../api/deleteProduct'

const useStyles = makeStyles((theme) => ({
    deleteConfirmationButton: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
    },
}))

type Props = {
    productId: number
    open: boolean
    onCancel: () => void
}

function DeleteProductDialog({ productId, open, onCancel }: Props) {
    const { mutate, isLoading } = useDeleteProductMutation()
    const classes = useStyles()
    const history = useHistory()

    return (
        <Dialog fullWidth open={open} maxWidth="xs">
            <DialogTitle>{gettext('Delete confirmation')}</DialogTitle>
            <DialogContent>
                {gettext(
                    'Are you sure you want to delete this product? This item will be removed from all linked channels.'
                )}
            </DialogContent>

            <DialogActions>
                {isLoading && <CircularProgress size={22} color="secondary" />}
                <Button color="secondary" disabled={isLoading} onClick={onCancel}>
                    {gettext('Cancel')}
                </Button>

                <Button
                    className={classes.deleteConfirmationButton}
                    variant="outlined"
                    disabled={isLoading}
                    onClick={() => {
                        window.analytics.track('Delete product', {
                            page: window.location.href,
                            feature: 'product',
                            type: 'delete',
                            trigger: 'button click',
                            product_id: productId,
                        })

                        mutate(productId, {
                            onSuccess: () => {
                                history.push('/home/products/catalog')
                            },
                        })
                    }}
                >
                    {gettext('Delete')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(DeleteProductDialog)
