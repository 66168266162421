import { connect } from 'react-redux'

import { changeLoginState } from '../actions/login'
import Login from '../components/login'

const mapStateToProps = (state) => ({
    loginAPIURL: state.initial.endpoints.loginAPIURL,
    loginConfirmationCodeAPIURL: state.initial.endpoints.loginConfirmationCodeAPIURL,
    staticRoot: state.initial.staticRoot,
})

const matchDispatchToProps = (dispatch) => ({
    changeLoginState: (loginState) => {
        return dispatch(changeLoginState(loginState))
    },
})

export default connect(mapStateToProps, matchDispatchToProps)(Login)
