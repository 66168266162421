import { connect } from 'react-redux'

import PickPackBatches from '../components/pick-pack-batches'

const mapStateToProps = (state) => ({
    stores: state.stores,
    accountOwnerID: state.initial.accountOwner.id,
    language: state.initial.language,
    staticRoot: state.initial.staticRoot,
    pickPackBatchesAPIURL: state.initial.endpoints.pickPackBatchesAPIURL,
})

export default connect(mapStateToProps)(PickPackBatches)
