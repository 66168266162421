import { type ChangeEvent, memo, useCallback, useEffect, useState, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { type TableComponents, TableVirtuoso } from 'react-virtuoso'

import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'

import ProductSection from './product-section'

import { FormField } from '~/common/components'
import { MAX_VIRTUALIZED_HEIGHT } from '~/constants'
import { DebouncedTextField } from '~/oakra/components/inputs'
import { useProductFormContext } from '~/products/hooks/useProductForm'
import { useCommonStyles } from '~/products/styles'
import { getAttr } from '~/tools/utils'

const useStyles = makeStyles((theme) => ({
    packageInfoSection: {
        marginTop: 16,
    },
    packageField: {
        width: 160,
    },
    skuColumn: {
        width: 100,
        textAlign: 'left',
    },
    textSize: {
        fontSize: '1rem',
    },
    toggle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
    },
    headerRow: {
        backgroundColor: theme.palette.background.paper,
    },
    errorText: {
        maxWidth: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
    },
    headerSection: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
    },
}))

const fields = [
    { name: 'package_width', displayName: gettext('Package width'), label: 'Width (cm)' },
    { name: 'package_length', displayName: gettext('Package length'), label: 'Length (cm)' },
    { name: 'package_height', displayName: gettext('Package height'), label: 'Height (cm)' },
    { name: 'package_weight', displayName: gettext('Package weight'), label: 'Weight (kg)' },
] as const

const components: TableComponents = {
    Table: (props) => (
        <table
            {...props}
            style={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
            }}
        />
    ),
}

type Props = {
    bulkEdit: boolean
    setBulkEdit: (state: boolean) => void
}

// Package info is always stored at the stock item level.
//
// For new products we give users the option to bulk edit package info
// on all stock items with one set of fields, if more than one stock unit
// is present.
//
// For existing products, bulk editing is the default on product load
// if package info for all stock items is the same

export default memo(function PackageInfo({ bulkEdit, setBulkEdit }: Props) {
    const commonClasses = useCommonStyles()
    const classes = useStyles()
    const { setValue, getValues, control } = useProductFormContext()

    const stockUnits = useWatch({ control, name: 'stock_units' })
    const hasSingleSku = useMemo(() => (stockUnits?.length || 0) === 1, [stockUnits])

    const [containerHeight, setContainerHeight] = useState(0)

    useEffect(() => {
        if (bulkEdit) {
            return
        }
        // Select product package information for channel like Shopee
        const stockItemWithDimensions =
            stockUnits
                ?.filter(
                    (su) =>
                        su.stock_item?.package_height &&
                        su.stock_item.package_width &&
                        su.stock_item.package_length &&
                        su.stock_item.package_weight
                )
                ?.map((su) => su.stock_item) ?? []

        if (stockItemWithDimensions.length > 0) {
            let currentStockItem = stockItemWithDimensions[0]
            const currentScore =
                currentStockItem.package_height *
                currentStockItem.package_width *
                currentStockItem.package_length

            stockItemWithDimensions.forEach((si) => {
                const stockItemScore = si.package_height * si.package_width * si.package_length
                if (
                    stockItemScore > currentScore ||
                    (stockItemScore === currentScore &&
                        si.package_weight > currentStockItem.package_weight)
                ) {
                    currentStockItem = si
                }
            })

            fields.forEach((field) => {
                setValue(field.name, getAttr(currentStockItem, field.name))
            })
        }
    }, [bulkEdit, setValue, stockUnits])

    const handleToggle = useCallback(() => {
        const isBulkEdit = !bulkEdit
        setBulkEdit(isBulkEdit)
        fields.forEach((field) => {
            setValue(field.name, null)
        })
        // TODO: Why is this needed?
        setValue('product_level_package_info', bulkEdit)
    }, [setValue, bulkEdit, setBulkEdit])

    function handleSetFieldForAllFunc(
        fieldName: 'package_width' | 'package_length' | 'package_height' | 'package_weight'
    ) {
        return (e: ChangeEvent<HTMLInputElement>) => {
            const value = parseFloat(e.target.value)
            if (isNaN(value)) {
                return
            }
            const stockUnits = getValues('stock_units')
            stockUnits.forEach((_, index) => {
                const path = `stock_units.${index}.stock_item.${fieldName}` as const
                setValue(path, value)
            })
        }
    }

    return (
        <ProductSection>
            <div className={commonClasses.sectionTitle}>
                <div className={classes.headerSection}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle1">
                            {gettext('Package information')}
                        </Typography>
                    </div>
                    {!hasSingleSku && (
                        <div className={classes.toggle}>
                            <div>{gettext('Show all skus')}</div>
                            <Switch checked={!bulkEdit} onChange={handleToggle} />
                        </div>
                    )}
                </div>
            </div>

            <TableVirtuoso
                data={bulkEdit ? [] : stockUnits}
                components={components}
                style={{
                    height: containerHeight + 1,
                    maxHeight: MAX_VIRTUALIZED_HEIGHT,
                    overflowY: bulkEdit ? 'hidden' : 'auto',
                    marginTop: 16,
                }}
                totalListHeightChanged={setContainerHeight}
                fixedHeaderContent={() => (
                    <>
                        <tr className={classes.headerRow}>
                            {!bulkEdit && (
                                <th className={classes.skuColumn}>
                                    <div>{gettext('Set all')}</div>
                                </th>
                            )}
                            {fields.map(({ name, label, displayName }) => {
                                return (
                                    <th key={name}>
                                        {bulkEdit ? (
                                            <FormField
                                                key={name}
                                                control={control}
                                                name={name}
                                                type="number"
                                                className={classNames(
                                                    classes.packageField,
                                                    commonClasses.textField
                                                )}
                                                variant="outlined"
                                                margin="dense"
                                                placeholder={gettext('Set all')}
                                                label={gettext(label)}
                                                inputProps={{ min: 0, step: 0.1 }}
                                                rules={{
                                                    min: {
                                                        value: 0.001,
                                                        message: gettext(
                                                            `${displayName} is required`
                                                        ),
                                                    },
                                                }}
                                                onChange={handleSetFieldForAllFunc(name)}
                                            />
                                        ) : (
                                            <>
                                                <DebouncedTextField
                                                    key={name}
                                                    className={classNames(
                                                        classes.packageField,
                                                        commonClasses.textField
                                                    )}
                                                    placeholder={gettext('Set all')}
                                                    label={gettext(label)}
                                                    onChange={handleSetFieldForAllFunc(name)}
                                                    margin="dense"
                                                    variant="outlined"
                                                    type="number"
                                                />
                                            </>
                                        )}
                                    </th>
                                )
                            })}
                        </tr>
                    </>
                )}
                itemContent={(index) => (
                    <>
                        <td>
                            <div className={classes.skuColumn}>
                                {stockUnits[index].sku ||
                                    interpolate(gettext('SKU %s'), [index + 1])}
                            </div>
                        </td>
                        {fields.map(({ name, label }) => {
                            const path = `stock_units.${index}.stock_item.${name}` as const
                            return (
                                <td key={path}>
                                    <FormField
                                        key={path}
                                        control={control}
                                        name={path}
                                        type="number"
                                        className={classNames(
                                            classes.packageField,
                                            commonClasses.textField
                                        )}
                                        label={gettext(label)}
                                        margin="dense"
                                        variant="outlined"
                                        inputProps={{ min: 0 }}
                                        showErrorText={false}
                                    />
                                </td>
                            )
                        })}
                    </>
                )}
            />
        </ProductSection>
    )
})
