import { useEffect, useState } from 'react'

const DEFAULT_DELAY = 500

/**
 * Hook that returns a debounced value.
 * @param value Value to be debounced
 * @param delay Delay in milliseconds, default is 500
 * @returns Debounced value after delay
 */
export default function useDebounce<T>(value: T, delay = DEFAULT_DELAY): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay)

        return () => clearTimeout(timer)
    }, [value, delay])

    return debouncedValue
}
