// Payment method selector

import Select, { components } from 'react-select'

import grey from '@material-ui/core/colors/grey'
import { withTheme } from '@material-ui/core/styles'

const paymentMethodSelectorStyles = {
    maxWidth: 16,
    paddingRight: 3,
}

const PaymentMethodOption = (props) => (
    <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
                style={{
                    width: 20,
                    height: 20,
                    marginRight: 7,
                }}
                src={[props.data.value.iconURL]}
            />
            <span>{props.data.label}</span>
        </div>
    </components.Option>
)
const customSingleValue = (props) => {
    const sel = props.getValue()[0]
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
                style={{
                    width: 20,
                    height: 20,
                    marginRight: 7,
                }}
                src={[sel.value.iconURL]}
            />
            <span>{sel.label}</span>
        </div>
    )
}

let PaymentMethodSelector = ({
    paymentMethodDetails,
    paymentMethodType,
    onChange,
    isDisabled,
    theme,
}) => {
    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: 30,
            marginTop: 15,
            minWidth: '100%',
            borderColor: state.isFocused ? theme.palette.primary.main : 'hsl(0,0%,80%)',
            boxShadow: state.isFocused ? theme.palette.primary.main : 'none',
            '&:hover': {
                borderColor: theme.palette.primary.main,
            },
            color: grey[700],
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: 4,
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            padding: 4,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: '0px 6px',
            color: grey[700],
        }),
        menu: (provided, state) => ({
            ...provided,
            color: grey[700],
        }),
        input: (provided, state) => ({
            ...provided,
            margin: 0,
            padding: 0,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            paddingTop: 1,
        }),
    }

    const makeOptValueForType = (data) => (type) => ({
        label: data.names[type],
        value: { type, iconURL: data.icons[type] },
    })

    const makePaymentOptions = (data) => data.types.map(makeOptValueForType(data))

    const options = makePaymentOptions(paymentMethodDetails)
    const selectedValue = options.find((opt) => opt.value.type == paymentMethodType)

    return (
        <Select
            styles={selectStyles}
            value={selectedValue}
            onChange={(selectedOption) => onChange(selectedOption.value.type)}
            placeholder={gettext('Select payment method')}
            isSearchable={false}
            isClearable={false}
            isDisabled={isDisabled}
            options={options}
            components={{
                Option: PaymentMethodOption,
                SingleValue: customSingleValue,
            }}
        />
    )
}

PaymentMethodSelector = withTheme(PaymentMethodSelector)

export default PaymentMethodSelector
