import { type UIEvent, useMemo, useState, useCallback, type ReactNode } from 'react'
import DataGrid from 'react-data-grid'
import { useLocation } from 'react-router-dom'

import HelpIcon from '@mui/icons-material/Help'
import LaunchIcon from '@mui/icons-material/Launch'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import { api, queryKeys, getApiErrorMessage } from '~/api'
import StockItemFilter from '~/stock/stock-item-filter'
import { useAppSelector } from '~/store'
import { isAtBottom } from '~/utils/isAtBottom'

type SelectedStock = {
    channelStockItemId: number
    isBundle: boolean
}

export default function StockByShopPage() {
    const [showSyncStatus, setShowSyncStatus] = useState(false)
    const [selectedStock, setSelectedStock] = useState<SelectedStock | null>(null)
    const [isOpen, setIsOpen] = useState(false)

    const handleBreakdownClick = useCallback((stock: SelectedStock) => {
        setIsOpen(true)
        setSelectedStock(stock)
    }, [])

    return (
        <Paper
            sx={{
                mt: 3,
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                minHeight: '85dvh',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <StockItemFilter debounceTime={500} />
                <FormControlLabel
                    control={<Switch />}
                    checked={showSyncStatus}
                    label={gettext('Display sync status')}
                    onChange={(_, checked) => setShowSyncStatus(checked)}
                />
            </Box>
            <TableContainer
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 6,
                    '& > div': { height: '100%' },
                }}
            >
                <StockByShopDataTable onBreakdownClick={handleBreakdownClick} />
            </TableContainer>

            {selectedStock && (
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={isOpen}
                    TransitionProps={{ onExited: () => setSelectedStock(null) }}
                    onClose={() => setIsOpen(false)}
                >
                    <DialogTitle>{gettext('Channel stock item breakdown')}</DialogTitle>
                    <DialogContent>
                        <TableContainer
                            sx={{ border: '1px solid', borderColor: 'grey.200', borderRadius: 2 }}
                        >
                            <StockBreakdownTable
                                channelStockItemId={selectedStock.channelStockItemId}
                                isBundle={selectedStock.isBundle}
                            />
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" onClick={() => setIsOpen(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Paper>
    )
}

const useStyles = makeStyles(() => ({
    row: {
        '&:hover svg': {
            transition: 'opacity 100ms ease-in-out',
            opacity: 1,
        },
    },
}))

function StockByShopDataTable({
    onBreakdownClick,
}: {
    onBreakdownClick: (stock: SelectedStock) => void
}) {
    const fetchLimit = 50
    const location = useLocation()
    const classes = useStyles()
    const stores = useAppSelector((state) => state.stores)

    const { status, data, error, hasNextPage, fetchStatus, fetchNextPage } =
        api.stock.stockItemsWithStock.useInfiniteQuery(
            queryKeys.stock.stockByShop(location.search).queryKey,
            ({ pageParam = 0 }) => {
                const params = new URLSearchParams(location.search)
                return {
                    query: {
                        offset: pageParam * fetchLimit,
                        limit: fetchLimit,
                        search: params.get('search') ?? undefined,
                        is_bundle: params.get('is_bundle') ?? undefined,
                    },
                }
            },
            {
                getNextPageParam: (lastPage) => {
                    if (!lastPage.body.next) {
                        return
                    }

                    const url = new URL(lastPage.body.next)
                    const offsetParam = url.searchParams.get('offset') ?? ''
                    const offset = parseInt(offsetParam)
                    if (isNaN(offset)) {
                        return
                    }

                    return Math.ceil(offset / fetchLimit)
                },
            }
        )

    const rows = useMemo(() => {
        return data?.pages.flatMap((page) => page.body.results)
    }, [data])
    type Row = NonNullable<typeof rows>[number]

    function handleScroll(event: UIEvent<HTMLDivElement>) {
        const shouldFetchNextPage =
            status === 'success' && fetchStatus === 'idle' && hasNextPage && isAtBottom(event)

        if (!shouldFetchNextPage) {
            return
        }

        void fetchNextPage()
    }

    const shopAccessors = useMemo(() => {
        const shops = stores.flatMap((store) => store.shops)
        return shops.map(({ id, shop_name }, index) => {
            return {
                key: `shop_${id}_${index}`,
                name: shop_name,
                disabled: true,
                formatter: ({ row }: { row: Row }) => {
                    const stock = row.stocks.find((s) => s.shop_id === id)
                    if (!stock) {
                        return '-'
                    }

                    return (
                        <Stack direction="row" justifyContent="space-between">
                            <span>{stock.calculated_stock}</span>
                            <IconButton
                                size="small"
                                onClick={() =>
                                    onBreakdownClick({
                                        channelStockItemId: stock.channel_stock_item_id,
                                        isBundle: row.is_bundle,
                                    })
                                }
                            >
                                <LaunchIcon sx={{ opacity: 0 }} fontSize="small" />
                            </IconButton>
                        </Stack>
                    )
                },
            }
        })
    }, [stores, onBreakdownClick])

    if (status === 'loading') {
        return (
            <Stack spacing={2}>
                {Array.from({ length: 50 }).map((_, index) => (
                    <Stack key={index} gap={3} direction="row">
                        {Array.from({ length: shopAccessors.length + 2 }).map((_, colIndex) => (
                            <Skeleton key={colIndex} variant="text" width="100%" />
                        ))}
                    </Stack>
                ))}
            </Stack>
        )
    }

    if (status === 'error') {
        return (
            <Typography color="error" align="center">
                {getApiErrorMessage(error.body)}
            </Typography>
        )
    }

    return (
        <DataGrid
            className="rdg-light"
            rowKeyGetter={(row: Row) => row.sku}
            columns={[
                { key: 'sku', name: 'SKU', frozen: true, minWidth: 200 },
                { key: 'name', name: gettext('Name'), minWidth: 200 },
                ...shopAccessors,
            ]}
            rows={rows}
            rowClass={() => classes.row}
            onScroll={handleScroll}
        />
    )
}

function StockBreakdownTable({
    channelStockItemId,
    isBundle,
}: {
    channelStockItemId: number
    isBundle: boolean
}) {
    const stores = useAppSelector((state) => state.stores)
    const shops = stores.flatMap((store) => store.shops)
    const { data, error, status } = api.stock.channelStockItemBreakdown.useQuery(
        queryKeys.stock.breakdown(channelStockItemId).queryKey,
        { params: { id: channelStockItemId } }
    )

    function formatCampaignType(type: string) {
        switch (type) {
            case 'flash_sale':
                return 'Flash Sale'
            default:
                return type
        }
    }

    if (status === 'loading') {
        return (
            <Stack spacing={2}>
                {Array.from({ length: 6 }).map((_, index) => (
                    <Stack key={index} gap={2} direction="row">
                        <Skeleton variant="text" width={100} />
                        <Skeleton variant="text" width={200} />
                    </Stack>
                ))}
            </Stack>
        )
    }

    if (status === 'error') {
        return <Typography color="error">{getApiErrorMessage(error.body)}</Typography>
    }

    const {
        active_channel_stock_item_campaign_locks,
        active_channel_stock_adjustment,
        actual_stock,
        bundle_stock_items,
        stock_adjustment,
        stock_buffer,
        calculated_stock,
    } = data.body

    const tooltipTitle = `Display = ${
        bundle_stock_items.length > 0 ? '(Lowest actual stock quantity/units)' : 'Actual stock'
    } ${active_channel_stock_adjustment ? '+ Stock adjustment' : ''} ${
        active_channel_stock_item_campaign_locks.length > 0 ? '- Reserved stocks' : ''
    } - Stock buffer, minimum 0`

    return (
        <Table size="small">
            <TableBody>
                {isBundle ? (
                    bundle_stock_items.map(({ stock_item: item, quantity }, index, arr) => (
                        <TableRow
                            key={item.id}
                            sx={{
                                '& > td': {
                                    borderBottomColor:
                                        arr.length - 1 === index ? 'grey.400' : undefined,
                                },
                            }}
                        >
                            <TableCell variant="head" scope="col">
                                {gettext('Actual stock')}
                                <CaptionText>
                                    {interpolate(gettext('%s - %s units'), [
                                        item.sku,
                                        item.num_of_units,
                                    ])}
                                </CaptionText>
                            </TableCell>
                            <TableCell>{quantity}</TableCell>
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell variant="head" scope="col">
                            {gettext('Actual stock')}
                        </TableCell>
                        <TableCell>{actual_stock}</TableCell>
                    </TableRow>
                )}
                {active_channel_stock_item_campaign_locks.map((lock) => (
                    <TableRow key={lock.channel_stock_item}>
                        <TableCell variant="head" scope="col">
                            {gettext('Reserved stock')}
                            <CaptionText>
                                {lock.channel_name}
                                {' - '}
                                {lock.shop_name}
                                {' - '}
                                {formatCampaignType(lock.channel_campaign.campaign_type)}
                            </CaptionText>
                        </TableCell>
                        <TableCell>-{Math.abs(lock.units_allocated)}</TableCell>
                    </TableRow>
                ))}
                {active_channel_stock_adjustment && (
                    <TableRow>
                        <TableCell variant="head" scope="col">
                            {gettext('Adjustment')}
                            <CaptionText>
                                {active_channel_stock_adjustment.adjustment_group.name}
                            </CaptionText>
                        </TableCell>
                        <TableCell>
                            {stock_adjustment > 0 ? `+${stock_adjustment}` : stock_adjustment}
                        </TableCell>
                    </TableRow>
                )}
                <TableRow
                    sx={{
                        '& > td': {
                            borderBottomColor: 'grey.400',
                        },
                    }}
                >
                    <TableCell variant="head" scope="col">
                        {gettext('Buffer')}
                    </TableCell>
                    <TableCell>{stock_buffer}</TableCell>
                </TableRow>
                <TableRow sx={{ '& > td': { borderBottom: 'none' } }}>
                    <TableCell variant="head" scope="col">
                        {gettext('Display')}
                    </TableCell>
                    <TableCell>
                        <Stack direction="row" gap={1}>
                            {calculated_stock}
                            <Tooltip title={tooltipTitle}>
                                <HelpIcon fontSize="small" color="action" />
                            </Tooltip>
                        </Stack>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

function CaptionText({ children }: { children: ReactNode }) {
    return (
        <Typography variant="caption" sx={{ display: 'block' }}>
            {children}
        </Typography>
    )
}
