import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { api, queryKeys } from '~/api'
import { useAppSelector } from '~/store'
import { sortArrayByField, sortByFieldTextAsc } from '~/utils'

type Props = {
    groups: number[]
    helperText?: string
    onGroupsChange: (groups: number[]) => void
}

export default function UserSelectGroups({ groups, helperText, onGroupsChange }: Props) {
    const accountOwnerId = useAppSelector((state) => state.initial.accountOwner.id)

    const { isLoading, data, error } = api.users.accountGroupListing.useQuery(
        queryKeys.users.groups(accountOwnerId).queryKey,
        { params: { id: accountOwnerId } },
        {
            select: (data) => ({
                ...data,
                body: sortArrayByField(data.body, 'name', sortByFieldTextAsc),
            }),
        }
    )

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={26} color="secondary" />
            </Box>
        )
    }

    if (error) {
        return <Typography color="error">{gettext('Something went wrong')}</Typography>
    }

    if (data.body.length === 0) {
        return (
            <Box
                color="grey.500"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gridGap: 10,
                }}
            >
                <AccountCircleIcon />
                <Typography>{gettext('No groups found')}</Typography>
            </Box>
        )
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="user-select-groups">{gettext('Groups')}</InputLabel>
            <Select
                multiple
                value={groups}
                labelId="user-select-groups"
                onChange={(e) => onGroupsChange(e.target.value as number[])}
            >
                {data.body.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                        {group.name}
                    </MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText error>{helperText}</FormHelperText>}
        </FormControl>
    )
}
