import { useState } from 'react'
import Dropzone from 'react-dropzone'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import PublishIcon from '@material-ui/icons/Publish'

import { type DragEndEvent } from '@dnd-kit/core/dist/types/events'
import classNames from 'classnames'

import SnackbarAlert from '~/oakra/components/snackbar-alert'

const useStyles = makeStyles((theme) => ({
    dropzoneActive: {
        backgroundColor: theme.palette.grey[400],
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
    },
    defaultButton: {
        backgroundColor: theme.palette.grey[100],
    },
}))

export type FileUploadProps = {
    onDrop: (event: DragEndEvent) => void
    accept: string
    className?: string
    maxSize: number
}

function bytesToMB(bytes: number): string {
    const megabytes = bytes / (1024 * 1024)
    return megabytes.toFixed(2)
}

function FileUploadButton({ accept, onDrop, className, maxSize }: FileUploadProps) {
    const classes = useStyles()
    const [showSnackBar, setShowSnackBar] = useState(false)
    const [uploadErrorMessage, setUploadErrorMessage] = useState('')

    const onDropRejected = (rejectedFiles: File[]) => {
        rejectedFiles.forEach((file) => {
            if (file.size > maxSize) {
                setUploadErrorMessage(
                    interpolate(gettext('File must be smaller than %s MB'), [bytesToMB(maxSize)])
                )
            } else if (file.size === 0) {
                setUploadErrorMessage(gettext('File cannot be empty'))
            } else if (!accept.includes(file.type)) {
                setUploadErrorMessage(
                    interpolate(gettext('%s files are not supported'), [file.type])
                )
            }
        })
        setShowSnackBar(true)
    }

    return (
        <>
            <Dropzone
                onDrop={onDrop}
                maxSize={maxSize}
                accept={accept}
                onDropRejected={onDropRejected}
            >
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                        className={classNames({
                            [classes.dropzoneActive]: isDragActive,
                        })}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <Button
                            className={classNames(classes.defaultButton, className)}
                            startIcon={<PublishIcon />}
                        >
                            {gettext('Upload')}
                        </Button>
                    </div>
                )}
            </Dropzone>

            <SnackbarAlert
                autoHideDuration={5000}
                open={showSnackBar}
                severity="error"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => {
                    setShowSnackBar(false)
                }}
            >
                {uploadErrorMessage}
            </SnackbarAlert>
        </>
    )
}

export default FileUploadButton
