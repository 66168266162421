import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ReorderIcon from '@material-ui/icons/Reorder'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { type NormalizedDimension } from './schemas'

const useDraggableStyles = makeStyles((theme) => ({
    dimensionName: {
        fontWeight: 500,
        marginBottom: theme.spacing(1),
    },
    outlinedChips: {
        borderRadius: 4,
    },
    dragContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    chipContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
    },
}))

type ProductDimensionDraggableProps = {
    dimension: NormalizedDimension
    index: number
    isNewProduct: boolean
    isOnlyDimension: boolean
    canReorder: boolean
    onEdit: (dimension: NormalizedDimension) => void
    onRemove: (index: number) => void
}

export default function ProductDimensionDraggable({
    dimension,
    index,
    isNewProduct,
    isOnlyDimension,
    canReorder,
    onEdit,
    onRemove,
}: ProductDimensionDraggableProps) {
    const { setNodeRef, setActivatorNodeRef, attributes, listeners, transition, transform } =
        useSortable({ id: dimension.id })

    const classes = useDraggableStyles()

    return (
        <div
            ref={setNodeRef}
            style={{
                transition,
                transform: CSS.Transform.toString(transform),
            }}
            {...attributes}
        >
            <div className={classes.dragContainer}>
                <div>
                    <Typography variant="body1" className={classes.dimensionName}>
                        {dimension.name}
                    </Typography>

                    <div className={classes.chipContainer}>
                        {dimension.options.map(({ index: optionIndex, name: optionName }) => (
                            <Chip
                                variant="outlined"
                                key={optionIndex}
                                label={optionName}
                                classes={{ outlined: classes.outlinedChips }}
                            />
                        ))}
                    </div>
                </div>

                <div>
                    {isNewProduct && (
                        <Tooltip title="Remove dimension">
                            <IconButton
                                aria-label="remove dimension"
                                onClick={() => onRemove(index)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title="Edit dimension">
                        <IconButton aria-label="edit dimension" onClick={() => onEdit(dimension)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>

                    {isNewProduct && !isOnlyDimension && (
                        <Tooltip title="Reorder dimensions">
                            <IconButton
                                innerRef={setActivatorNodeRef}
                                aria-label="reorder dimensions"
                                disabled={canReorder}
                                {...listeners}
                            >
                                <ReorderIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </div>
            <Divider />
        </div>
    )
}
