import {
    initContract,
    type ClientInferResponseBody,
    type ClientInferResponses,
    type AppRoute,
} from '@ts-rest/core'
import { initQueryClient, useTsRestQueryClient } from '@ts-rest/react-query'
import Cookies from 'js-cookie'
import { z } from 'zod'

import type { FieldInfo, InitShippingInfo, SalesFilter } from '.'

import { UserLocation } from '~/common/schemas/location'
import { ShopeeLogisticsProvider } from '~/common/schemas/logistics-shopee'
import { ChannelStockUnitDiscount, Product, ProductDiscount } from '~/common/schemas/product'
import type { Shop } from '~/common/schemas/shop'
import { StockItem } from '~/common/schemas/stock-item'
import {
    StockRecord,
    StockItemWithStockRecords,
    StockRecordAdjustments,
} from '~/common/schemas/stock-record'

const permissionSchema = z.object({
    id: z.number(),
    name: z.string(),
    codename: z.string(),
    permission_type: z.enum(['api', 'ui']),
    object_pk: z.coerce.number().nullish(),
    content_type: z
        .object({
            id: z.number(),
            app_label: z.string(),
            model: z.string(),
        })
        .nullish(),
})

export const api = initQueryClient(createApiContract(), {
    credentials: 'include',
    baseUrl: `${import.meta.env.VITE_API_URL || window.location.origin}/endpoints`,
    baseHeaders: {
        'X-CSRFToken': Cookies.get('csrftoken') ?? '',
    },
})

function createApiContract() {
    const c = initContract()

    return c.router(
        {
            users: createUserContract(),
            products: createProductContract(),
            sales: createSaleContract(),
            groups: createGroupContract(),
            channels: createChannelContract(),
            integrations: createIntegrationsContract(),
            stock: createStockContract(),
            stores: createStoreContract(),
        },
        { strictStatusCodes: true }
    )
}
export type ApiContract = ReturnType<typeof createApiContract>

function createUserContract() {
    const basicUserSchema = z.object({
        id: z.number(),
        account_owner: z.number(),
        username: z.string(),
        first_name: z.string(),
        last_name: z.string(),
        email: z.string(),
        groups: z.array(z.number()),
        is_account_owner: z.boolean(),
    })

    const c = initContract()

    return c.router({
        accountUserListing: {
            method: 'GET',
            path: '/users/:id/users/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Get users managed by the current account owner',
            responses: {
                200: z.array(basicUserSchema),
                403: makeGenericError(),
            },
        },
        accountGroupListing: {
            method: 'GET',
            path: '/users/:id/groups/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Get groups managed by the current account owner',
            responses: {
                200: z.array(
                    z.object({
                        id: z.number(),
                        is_active: z.boolean(),
                        is_admin: z.boolean(),
                        members: z.array(
                            basicUserSchema.pick({ id: true, email: true }).extend({
                                first_name: z.string().nullable(),
                                last_name: z.string().nullable(),
                                username: z.string().nullable(),
                                is_demo: z.boolean(),
                            })
                        ),
                        name: z.string(),
                        owner: z.number(),
                        permissions: z.array(z.number()),
                    })
                ),
            },
        },
        locations: {
            method: 'GET',
            path: '/users/:id/locations/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Get user fulfillment locations',
            responses: {
                200: UserLocation.array(),
            },
        },
        create: {
            method: 'POST',
            path: '/users/create/',
            summary: 'Create user',
            body: basicUserSchema.omit({ id: true, is_account_owner: true }),
            responses: {
                201: z.object({
                    password: z.string(),
                    user: basicUserSchema,
                }),
                400: makeGenericError(z.record(z.array(z.string()))),
            },
        },
        update: {
            method: 'PUT',
            path: '/users/:id/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Update user',
            body: basicUserSchema,
            responses: {
                200: basicUserSchema,
                400: makeGenericError(z.record(z.array(z.string()))),
            },
        },
        delete: {
            method: 'DELETE',
            path: '/users/:id/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Delete user',
            body: z.never(),
            responses: {
                204: z.never(),
            },
        },
        permissions: {
            method: 'GET',
            path: '/users/:id/permissions/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Get user permissions',
            responses: {
                200: z.array(permissionSchema),
            },
        },
        updatePermissions: {
            method: 'POST',
            path: '/users/:id/permissions/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Update user permissions',
            body: z.array(permissionSchema),
            responses: {
                200: z.array(permissionSchema),
            },
        },
    })
}

function createProductContract() {
    const c = initContract()

    return c.router({
        detail: {
            method: 'GET',
            path: '/products/:id/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Get product detail',
            validateResponseOnClient: true,
            responses: {
                200: Product,
            },
        },
        stockRecords: {
            method: 'GET',
            path: '/products/:id/stock-records/',
            pathParams: z.object({ id: z.number() }),
            query: z.object({ include_bundle_items: z.boolean().optional() }),
            summary: 'Get stock records of a product',
            responses: {
                200: z.array(StockRecord),
            },
        },
        discounts: {
            method: 'GET',
            path: '/products/:id/product-discounts/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Get product discounts',
            responses: {
                200: z.array(ProductDiscount),
            },
        },
        activeChannelStockUnitDiscounts: {
            method: 'GET',
            path: '/products/:id/active-channel-stock-unit-discounts/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Get active channel stock unit discounts',
            responses: {
                200: z.array(ChannelStockUnitDiscount),
            },
        },
        /*
         * TODO: Add create-update-delete methods
         * NOTE: Do not use yet, still in development
         * */
        create: {
            method: 'POST',
            path: '/products/',
            summary: 'Create a product',
            body: Product,
            responses: {
                201: z.object({ id: z.number() }),
            },
        },
        updateStatus: {
            method: 'PATCH',
            path: '/products/:id/status/',
            summary: 'Update product status',
            pathParams: z.object({ id: z.number() }),
            body: z.object({
                status: z.enum(['active', 'inactive']),
            }),
            responses: {
                200: z.object({ id: z.number() }),
            },
        },
    })
}

function createSaleContract() {
    const c = initContract()

    return c.router({
        initShippingInfoFields: {
            method: 'POST',
            path: '/sales/init-shipping-info/fields/',
            summary: 'Get init shipping info fields',
            body: c.type<{ sales_filter: Pick<SalesFilter, 'included_sale_pks'> }>(),
            responses: {
                200: c.type<
                    Array<{
                        shop: Shop | null
                        field_info: FieldInfo
                        init_shipping_info: InitShippingInfo
                    }>
                >(),
            },
        },
        ids: {
            method: 'GET',
            path: '/users/:userId/sales/pks/',
            summary: 'Get sale IDs',
            pathParams: z.object({ userId: z.number() }),
            query: c.type<
                Partial<{
                    date_created_after: string
                    date_created_before: string
                    status: string
                    shop__channel: number
                    shop: number
                }>
            >(),
            responses: {
                200: z.array(z.number()),
            },
        },
        filterValues: {
            method: 'GET',
            path: '/users/:userId/filter-values/sales/',
            pathParams: z.object({ userId: z.number() }),
            summary: 'Get sales filter values',
            responses: {
                200: c.type<
                    Array<{
                        field: string
                        lookup: string
                        select_multiple: boolean
                        values: Array<{ value: string; label: string }>
                    }>
                >(),
            },
        },
        initShippingInfo: {
            method: 'POST',
            path: '/sales/init-shipping-info/',
            summary: 'Init shipping info',
            body: c.type<{
                // TODO: Update this type
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                init_shipping_info: Record<number, Record<string, any>>
                sales_filter: Partial<SalesFilter>
            }>(),
            responses: {
                201: c.type<{
                    status: string
                    pick_pack_batch_id: number | null
                    errors: Array<{
                        message: string
                        fulfillment_pk: number
                        sale_pk: number
                        sale_id: number
                    }>
                }>(),
            },
        },
    })
}

function createGroupContract() {
    const c = initContract()

    return c.router({
        detail: {
            method: 'GET',
            path: '/groups/:id/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Get group detail',
            responses: {
                200: z.object({
                    id: z.number(),
                    name: z.string(),
                    is_active: z.boolean(),
                    is_admin: z.boolean(),
                    owner: z.number(),
                    permissions: z.array(z.number()),
                    members: z.array(
                        z.object({
                            id: z.number(),
                            email: z.string(),
                            username: z.string().nullable(),
                            first_name: z.string().nullable(),
                            last_name: z.string().nullable(),
                        })
                    ),
                }),
            },
        },
        permissions: {
            method: 'GET',
            path: '/groups/:id/permissions/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Get group permissions',
            responses: {
                200: z.array(permissionSchema),
            },
        },
        addUsers: {
            method: 'POST',
            path: '/groups/:id/users/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Add users to group',
            body: z.object({ users: z.array(z.number()) }),
            responses: {
                200: z.object({ id: z.number() }),
            },
        },
        removeUsers: {
            method: 'DELETE',
            path: '/groups/:id/users/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Remove users from group',
            body: z.object({ users: z.array(z.number()) }),
            responses: {
                204: z.never(),
            },
        },
    })
}

function createIntegrationsContract() {
    const c = initContract()
    const SettingResponse = z
        .object({
            ouCode: z.string(),
            source: z.string(),
            branch_code: z.string(),
            pos_api_url: z.string(),
            master_api_url: z.string(),
            sale_person_code: z.string(),
            sync_sales_to_system: z.boolean(),
            retail_price_list_code: z.string(),
            retail_price_list_keyword: z.string(),
            marketplace_price_list_code: z.string(),
            sync_stock_from_system_status: z.string(),
            marketplace_price_list_keyword: z.string(),
            sync_stock_from_system_message: z.union([z.string(), z.null()]),
            update_price_from_system_to_channels: z.boolean(),
            sync_stock_from_system_last_updated_time: z.string(),
            price_lists: z.array(
                z.object({
                    pricelistCode: z.string(),
                    pricelistDesc: z.string(),
                    startDate: z.string(),
                    endDate: z.nullable(z.string()),
                    shopCondition: z.string(),
                    custCondition: z.string(),
                    upddateBy: z.string(),
                    upddateDate: z.string(),
                })
            ),
        })
        .passthrough()

    return {
        jaymart: c.router({
            settings: {
                method: 'GET',
                path: '/integrations/jaymart/settings/',
                summary: 'Get Jaymart settings',
                responses: {
                    200: SettingResponse,
                },
            },
            updateSetting: {
                method: 'POST',
                path: '/integrations/jaymart/settings/',
                summary: 'Update Jaymart settings',
                body: z.object({
                    settings: z.record(
                        z.enum([
                            'retail_price_list_code',
                            'marketplace_price_list_code',
                            'branch_code',
                        ]),
                        z.string()
                    ),
                }),
                responses: {
                    200: SettingResponse,
                },
            },
            syncStock: {
                method: 'PUT',
                path: '/integrations/jaymart/sync-stock/',
                summary: 'Sync Jaymart stock',
                body: z.never(),
                responses: {
                    200: SettingResponse,
                },
            },
        }),
    }
}

function createStockContract() {
    const c = initContract()

    return c.router({
        stockRecords: {
            method: 'GET',
            path: '/stock/stock-records/',
            query: z.object({
                search: z.string().optional(),
                is_bundle: z.boolean().optional(),
            }),
            summary: 'Get stock records',
            responses: {
                200: z.array(StockItemWithStockRecords),
            },
        },
        stockRecordAdjustments: {
            method: 'GET',
            path: '/stock/stock-records/:id/adjustments',
            pathParams: z.object({ id: z.number() }),
            query: z.object({
                search: z.string().optional(),
                user_id: z.number().optional(),
                adjustment_type: z.string().optional(),
                date_updated_before: z.string().optional(),
                date_updated_after: z.string().optional(),
                order_by: z.string().optional(),
            }),
            summary: 'Get stock record adjustments',
            responses: {
                200: z.array(StockRecordAdjustments),
            },
        },
        stockRecordAdjustmentFilterValues: {
            method: 'GET',
            path: '/stock/stock-records/:id/adjustments/filter-values',
            pathParams: z.object({ id: z.number() }),
            summary: 'Get stock record adjustments',
            responses: {
                200: z.array(
                    z.object({
                        field: z.string(),
                        lookup: z.string(),
                        select_multiple: z.boolean(),
                        values: z.array(
                            z.object({
                                label: z.string(),
                                value: z.string(),
                            })
                        ),
                    })
                ),
            },
        },
        stockItem: {
            method: 'GET',
            path: '/stock/stock-items/:id/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Get a stock item detail, included bundle items',
            responses: {
                200: StockItem,
            },
        },
        updateStockItem: {
            method: 'PATCH',
            path: '/stock/stock-items/:id/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Update stock item',
            body: z.object({ users: z.array(z.number()) }),
            responses: {
                200: z.object({ id: z.number() }),
            },
        },
        stockItemsWithStock: {
            method: 'GET',
            path: '/channels/stock/stock-items/',
            query: z
                .object({
                    search: z.string(),
                    is_active: z.boolean(),
                    limit: z.number(),
                    offset: z.number(),
                    is_bundle: z.string(),
                })
                .partial(),
            responses: {
                200: makePaginatedResponse(
                    z.object({
                        id: z.number(),
                        sku: z.string(),
                        name: z.string(),
                        is_bundle: z.boolean(),
                        stocks: z.array(
                            z.object({
                                channel_stock_item_id: z.number(),
                                shop_id: z.number(),
                                actual_stock: z.number(),
                                calculated_stock: z.number(),
                            })
                        ),
                    })
                ),
            },
        },
        channelStockItemBreakdown: {
            method: 'GET',
            path: '/channels/stock/channel-stock-items/:id/',
            pathParams: c.type<{ id: number }>(),
            responses: {
                200: c.type<{
                    id: number
                    shop_id: number
                    stock_item_id: number
                    actual_stock: number
                    stock_buffer: number
                    stock_adjustment: number
                    reserved_stock: number
                    calculated_stock: number
                    active_channel_stock_adjustment: {
                        adjustment_group: {
                            id: number
                            name: string
                            store: number
                            status: string
                            is_active: boolean
                            start_date: string
                            end_date: string
                        }
                    } | null
                    active_channel_stock_item_campaign_locks: Array<{
                        units_allocated: number
                        units_remaining: number
                        channel_stock_item: number
                        shop_location: number | null
                        shop_name: string
                        channel_name: string
                        channel_campaign: {
                            channel_campaign_id: string
                            campaign_type: string
                            start_date: string
                            end_date: string
                        }
                    }>
                    stock_records: Array<{
                        id: number
                        quantity: number
                        quantity_allocated: number
                        depletion_velocity: number
                        location: number
                        stock_item: number
                    }>
                    bundle_stock_items: Array<{
                        quantity: number
                        stock_item: {
                            id: number
                            sku: string
                            name: string
                            num_of_units: number | null
                        }
                    }>
                }>(),
            },
        },
    })
}

function createChannelContract() {
    const channelSchema = z.object({
        id: z.number(),
        channel_icon_url: z.string(),
        channel_square_icon_url: z.string(),
        name: z.string(),
        country_code: z.string().nullable(),
        selectable: z.boolean(),
        has_categories: z.boolean(),
        has_category_attributes: z.boolean(),
        categories_are_shop_level: z.boolean(),
        has_channel_manager: z.boolean(),
        has_integrated_shipping: z.boolean(),
        allow_duplicate_skus: z.boolean(),
    })
    const categorySchema = z.object({
        id: z.number(),
        translations: z.record(z.object({ name: z.string() })),
        category_id: z.number(),
        channel: z.number(),
        is_leaf: z.boolean(),
        depth: z.number(),
        updating: z.boolean(),
        supports_multiple_stock_units: z.boolean(),
    })
    const attributeSchema = z.object({
        id: z.number(),
        name: z.string(),
        tip: z.string().nullable(),
        attribute_id: z.string(),
        attribute_type: z.enum([
            'text',
            'integer',
            'float',
            'richtext',
            'date',
            'datetime',
            'option',
            'multi_option',
            'image',
            'json',
        ]),
        options: z.array(
            z.object({
                label: z.string(),
                value: z.string(),
                inactive: z.boolean(),
            })
        ),
        units: z.array(z.object({ id: z.number(), name: z.string() })),
        required: z.boolean(),
        can_create_option: z.boolean(),
        should_fetch_options_async: z.boolean(),
        sku_attribute: z.boolean(),
        unique_for_stock_unit: z.boolean(),
        recommended: z.boolean(),
        is_shop_specific: z.boolean(),
    })
    const attributeOptionValueSchema = z.object({
        value: z.nullable(z.string()),
        label: z.string(),
        inactive: z.boolean(),
    })

    const c = initContract()

    return c.router({
        list: {
            method: 'GET',
            path: '/channels/',
            summary: 'List all channels',
            responses: {
                200: z.array(channelSchema),
            },
        },
        detail: {
            method: 'GET',
            path: '/channels/:id/',
            pathParams: z.object({ id: z.number() }),
            summary: 'Get channel detail by id',
            responses: {
                200: channelSchema,
            },
        },
        categoryDetail: {
            method: 'GET',
            path: '/channels/:channelId/category/:categoryId',
            pathParams: z.object({
                channelId: z.number(),
                categoryId: z.string(), // String is required because 0 is falsy
            }),
            summary: 'Get category detail in a channel',
            responses: {
                200: categorySchema,
            },
        },
        categoryAncestors: {
            method: 'GET',
            path: '/channels/:channelId/category/:categoryId/ancestors/',
            pathParams: z.object({
                channelId: z.number(),
                categoryId: z.number(), // Number can be used here since it should never be 0
            }),
            summary: 'Get ancestor categories of a category in a channel',
            responses: {
                200: z.array(categorySchema.extend({ children: z.array(categorySchema) })),
            },
        },
        categoryChildren: {
            method: 'GET',
            path: '/channels/:channelId/category/:categoryId/children/',
            pathParams: c.type<{
                channelId: number
                categoryId: string
            }>(),
            query: c.type<{
                shop_id?: string
            }>(),
            summary: 'Get category children in a channel',
            responses: {
                200: c.type<{
                    count: number
                    next: string | null
                    previous: string | null
                    results: Array<z.infer<typeof categorySchema>>
                }>(),
            },
        },
        categoryAttributes: {
            method: 'GET',
            path: '/channels/:channelId/category/:categoryId/attributes/:language/',
            pathParams: z.object({
                channelId: z.number(),
                categoryId: z.string(), // String is required because the API considers 0 as falsy
                language: z.string(),
            }),
            query: z.object({
                shop_id: z.number(),
            }),
            summary: 'Get category attributes in a channel',
            responses: {
                200: z.array(attributeSchema),
                403: makeGenericError(),
            },
        },
        attributeOptions: {
            method: 'GET',
            path: '/channels/products/attributes/:attributePk/options/:language/',
            pathParams: c.type<{
                attributePk: number
                language: string
            }>(),
            query: c.type<{
                category_id: number
                search: string
            }>(),
            summary: 'Get category attributes in a channel',
            responses: {
                200: c.type<Array<{ label: string; value: string; inactive: boolean }>>(),
            },
        },
        attributeValues: {
            method: 'GET',
            path: '/channels/products/:channelProductId/attribute/values/',
            pathParams: z.object({
                channelProductId: z.number(),
            }),
            query: c.type<{ language: string }>(),
            summary:
                'Get existing product attribute values and stock unit attribute values of a channel product',
            responses: {
                200: z.object({
                    products: z.array(
                        z.object({
                            id: z.number(),
                            attribute: z.object({
                                attribute_id: z.string(),
                                id: z.number(),
                            }),
                            channel_product: z.number(),
                            unit: z.object({ id: z.number(), name: z.string() }).nullable(),
                            value: z.union([
                                z.string().describe('Value'),
                                attributeOptionValueSchema,
                                attributeOptionValueSchema.array(),
                                ShopeeLogisticsProvider.array(),
                            ]),
                        })
                    ),
                    stock_units: z.array(
                        z.object({
                            attribute: z.object({
                                attribute_id: z.string(),
                                id: z.number(),
                            }),
                            channel_stock_unit: z.number(),
                            id: z.number(),
                            unit: z.number().nullable(),
                            value: z.union([
                                z.string().describe('Value'),
                                attributeOptionValueSchema,
                            ]),
                        })
                    ),
                }),
            },
        },
    })
}

function createStoreContract() {
    const c = initContract()

    return c.router({
        getPresignedUrl: {
            method: 'POST',
            path: '/stores/:storeId/upload/signature/',
            pathParams: c.type<{ storeId: number }>(),
            summary: 'Get presigned url for upload',
            body: c.type<Array<{ filename: string; content_type: string }>>(),
            responses: {
                200: c.type<
                    Array<{ presigned_url: string; original_filename: string; filename: string }>
                >(),
            },
        },
    })
}

/**
 * Creates a Zod object schema with an `error` field and an optional `request_id` field.
 *
 * @param [error] - The Zod schema for the `error` field. Defaults to `z.string()`.
 *
 * @returns A Zod object schema with the specified `error` field and an optional `request_id` field.
 */
function makeGenericError<T extends z.ZodTypeAny = z.ZodString>(
    error = z.string() as unknown as T
) {
    return z.object({
        error,
        request_id: z.string().uuid().optional(),
    })
}

function makePaginatedResponse<T extends z.ZodTypeAny>(schema: T) {
    return z.object({
        count: z.number(),
        next: z.string().nullable(),
        previous: z.string().nullable(),
        results: z.array(schema),
    })
}

export type InitShippingInfoFieldsResponse = ClientInferResponseBody<
    ApiContract['sales']['initShippingInfoFields']
>
export type ProductDetailResponse = ClientInferResponses<ApiContract['products']['detail']>
export type ApiResponses = ClientInferResponses<ApiContract>

export type InferResponseBody<
    Route extends AppRoute,
    Status extends number = 200,
> = ClientInferResponseBody<Route, Status>

export function useApiQueryClient() {
    return useTsRestQueryClient(api)
}
