import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import type { Attribute } from './types'

import { useProductFormContext } from '~/products/hooks/useProductForm'

type Props = {
    attribute: Attribute
    channelId: number
}

export default function AttributeUnit({ attribute, channelId }: Props) {
    const { control } = useProductFormContext()
    const { field } = useController({
        control,
        name: `channel_attribute_values.${channelId}.product.${attribute.attribute_id}.unit`,
    })

    const unitMap = useMemo(
        () => new Map(attribute.units.map((unit) => [unit.id, unit])),
        [attribute.units]
    )

    return (
        <TextField
            {...field}
            value={field.value?.id ?? ''}
            select
            fullWidth
            margin="dense"
            variant="outlined"
            size="small"
            sx={{ width: '25%', minWidth: 100 }}
            required={attribute.required}
            label={gettext('Unit')}
            helperText={attribute.tip}
            onChange={(e) => {
                const value = parseInt(e.target.value)
                const unit = unitMap.get(value)
                if (!unit) {
                    return
                }

                field.onChange(unit)
            }}
        >
            {attribute.units.map((unit) => (
                <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                </MenuItem>
            ))}
        </TextField>
    )
}
