import { Fragment } from 'react'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import EditIcon from '@material-ui/icons/Edit'
import Skeleton from '@material-ui/lab/Skeleton'

import ProductSection from './product-info/product-section'

export default function ProductSkeleton() {
    return (
        <Box mt={3} display="flex" flexDirection="column" gridRowGap={20}>
            <ProductSection id="basic-info-skeleton">
                <Box display="flex" gridColumnGap={16}>
                    {[...Array(3)].map((_, index) => (
                        <Skeleton key={index} height={48} width={100} variant="text" />
                    ))}
                </Box>

                <Box clone mt={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Skeleton height={48} variant="rect" />
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton height={100} variant="rect" />
                        </Grid>
                    </Grid>
                </Box>
            </ProductSection>

            <ProductSection id="channel-products-skeleton">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Skeleton height={32} width={64} variant="text" />
                                </TableCell>

                                {[...Array(4)].map((_, index) => (
                                    <TableCell key={index}>
                                        <Skeleton height={32} variant="text" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...Array(5)].map((_, index) => (
                                <TableRow key={index}>
                                    <TableCell width={32} align="center">
                                        <Skeleton
                                            height={32}
                                            width={32}
                                            variant="circle"
                                            style={{ display: 'inline-block' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton height={32} variant="text" />
                                    </TableCell>
                                    <TableCell width={250} align="center">
                                        <Switch disabled />
                                    </TableCell>
                                    <TableCell width={250} align="center">
                                        <IconButton disabled>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell width={250} align="center">
                                        <Switch disabled />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ProductSection>

            <ProductSection id="media-skeleton">
                <Grid container spacing={2}>
                    {[1, 2].map((item) => (
                        <Fragment key={item}>
                            <Grid item xs={12}>
                                <Skeleton height={32} width={200} variant="text" />
                            </Grid>
                            <Grid item>
                                <Box display="flex" gridColumnGap={16}>
                                    {[...Array(5)].map((_, index) => (
                                        <Skeleton
                                            key={index}
                                            height={100}
                                            width={100}
                                            variant="rect"
                                        />
                                    ))}
                                </Box>
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
            </ProductSection>

            <ProductSection id="dimesion-skeleton">
                <Grid container spacing={2}>
                    {[1, 2].map((item) => (
                        <Grid key={item} item xs={12}>
                            <Skeleton height={48} width={400} variant="text" />

                            <Box clone mt={1}>
                                <Grid container spacing={1}>
                                    {[...Array(5)].map((_, index) => (
                                        <Grid key={index} item xs={12}>
                                            <Skeleton height={48} width={400} variant="rect" />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </ProductSection>
        </Box>
    )
}
