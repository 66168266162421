import { useState, useMemo, useEffect } from 'react'

import Button from '@material-ui/core/Button'
import { grey } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import HistoryIcon from '@material-ui/icons/History'

import { useInfiniteQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import moment from 'moment'

import TableSkeleton from './table-skeleton'

import { api, queryKeys } from '~/api'
import DateSelectButton from '~/oakra/components/date-select-button'
import FilterSelect from '~/oakra/components/filter-select'
import { debounce } from '~/tools/utils'

const useStyles = makeStyles({
    placeholder: {
        height: 200,
        backgroundColor: grey[100],
        borderRadius: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    placeholderIcon: {
        color: grey[500],
        fontSize: 50
    },
    placeholderText: {
        color: grey[700]
    },
    tableContainer: {
        maxHeight: 300,
    },
    table: {
        maxWidth: '100%',
        tableLayout: 'fixed',
    },
    tableHeader: {
        borderTop: '1px solid ' + grey[200],
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell: {
        padding: '9px 12px',
        wordWrap: 'break-word',
        fontSize: '0.85rem',
    },
    tableHeadCell: {
        padding: '12px',
        fontSize: '0.85rem',
    },
    tableText: {
        fontSize: '0.85rem',
    },
    datetime: {
        color: grey[600],
    },
    source: {
        color: grey[600],
        '&a': {
            color: grey[600],
        }
    },
})

const AdjustmentHistory = ({ stockRecord, onClose, open }) => {
    const classes = useStyles()
    const [isDataLoaded, setIsDataLoaded] = useState(false)

    const { data: filterValuesResult, isLoading: isLoadingFilterFields } = api.stock.stockRecordAdjustmentFilterValues.useQuery(
        queryKeys.stock.stockRecordAdjustmentFilterValues(stockRecord.id).queryKey,
        {
            params: { id: stockRecord.id },
        },
    )
    const filterFields = filterValuesResult?.body || []

    const [filterValues, setFilterValues] = useState({
        date_updated_after: moment().subtract(13, 'days'),
        date_updated_before: moment()
    })

    const {
        data: adjustmentsResult,
        isFetching: isLoadingAdjustments,
        fetchNextPage,
    } = useStockRecordAdjustmentsInfiniteQuery(stockRecord.id, {
        ...filterValues,
        date_updated_after: filterValues.date_updated_after?.format('YYYY-MM-DD'),
        date_updated_before: filterValues.date_updated_before?.format('YYYY-MM-DD'),
        order_by: "-date_updated"
    })

    const adjustmentsData = useMemo(
        () => adjustmentsResult?.pages.flatMap((page) => page.results),
        [adjustmentsResult?.pages]
    )

    const handleFilterChange = (values) => {
        setFilterValues(values)
    }

    useEffect(() => {
        if (!isDataLoaded && !isLoadingAdjustments && !isLoadingFilterFields) {
            setIsDataLoaded(true)
        }
    }, [isLoadingAdjustments, isLoadingFilterFields, isDataLoaded])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="event-dialog-title"
            maxWidth={800}
        >
            <DialogTitle id="event-dialog-title">{gettext('History')}</DialogTitle>
            <DialogContent>
                <div style={{ width: 800 }}>
                    {(isDataLoaded && adjustmentsData) &&
                        <>
                            <div style={{ marginBottom: 16 }}>
                                <StockRecordAdjustmentFilter
                                    filterValues={filterValues}
                                    filterFields={filterFields}
                                    onFilterChange={handleFilterChange}
                                />
                            </div>


                            {adjustmentsData.length > 0 ?
                                <AdjustmentHistoryTable
                                    data={adjustmentsData || []}
                                    onFetchNextPage={() => !isLoadingAdjustments && fetchNextPage()}
                                />:
                                <div className={classes.placeholder}>
                                    <HistoryIcon className={classes.placeholderIcon}/>
                                    <Typography className={classes.placeholderText}>
                                        {gettext("No adjustments during selected time period")}
                                    </Typography>
                                </div>
                            }

                        </>

                    }
                    {!isDataLoaded && <TableSkeleton rowsNum={4} colsNum={3} height={20} />}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    {gettext('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function isAtBottom({ currentTarget }) {
    return currentTarget.scrollTop + 10 >= currentTarget.scrollHeight - currentTarget.clientHeight
}

const AdjustmentHistoryTable = ({ data, onFetchNextPage }) => {
    const classes = useStyles()
    function handleScroll(event) {
        if (isAtBottom(event)) {
            onFetchNextPage()
        }
    }

    return (
        <TableContainer
            className={classes.tableContainer}
            onScroll={handleScroll}
        >
            <Table
                stickyHeader
                className={classes.table}
            >
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{
                                width: 200,
                            }}
                            className={classes.tableHeadCell}
                        >
                            {gettext('Event')}
                        </TableCell>
                        <TableCell
                            style={{
                                width: 100,
                            }}
                            className={classes.tableHeadCell}
                        >
                            {gettext('Date created')}
                        </TableCell>
                        <TableCell
                            style={{
                                width: 100,
                            }}
                            className={classes.tableHeadCell}
                        >
                            {gettext('Quantity change')}
                        </TableCell>
                        <TableCell
                            style={{
                                width: 100,
                            }}
                            className={classes.tableHeadCell}
                        >
                            {gettext('Quantity')}
                        </TableCell>
                        <TableCell
                            style={{
                                width: 200,
                            }}
                            className={classes.tableHeadCell}
                        >
                            {gettext('Source')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => {
                        return (
                            <TableRow key={row.id} hover>
                                <TableCell className={classes.tableCell}>
                                    <Typography className={classes.tableText} variant="body2">
                                        {row.adjustment_type_name}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography
                                        className={classNames(classes.tableText, classes.datetime)}
                                        variant="body2"
                                    >
                                        {moment(row.date_updated).format('D MMM HH:mm')}
                                    </Typography>
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                    <Typography
                                        className={classNames(classes.tableText, classes.number)}
                                        variant="body2"
                                    >
                                        {row.change_in_quantity}
                                    </Typography>
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                    <Typography
                                        className={classNames(classes.tableText, classes.number)}
                                        variant="body2"
                                    >
                                        {row.beginning_quantity + row.change_in_quantity}
                                    </Typography>
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                    <Typography
                                        className={classNames(classes.tableText, classes.source)}
                                        variant="body2"
                                    >
                                        {row.content_object_info?.type === 'user' && row.content_object_info.label}
                                        {row.content_object_info?.type === 'sale' && <a className={classes.source} href={`/home/sale/${row.content_object_info.id}`} target='_blank' rel="noreferrer">{gettext('Order')} #{row.content_object_info.label}</a>}
                                        {row.content_object_info?.type === 'app_key' && row.content_object_info.label}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default AdjustmentHistory


const useFilterStyles = makeStyles({
    toolbarItems: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: 0,
        padding: 0,
        minHeight: 32,
    },
    searchBar: {
        margin: '3px 7px',
    },
})

const StockRecordAdjustmentFilter = ({ filterValues, filterFields, onFilterChange }) => {
    const classes = useFilterStyles()

    const handleFilterChange = debounce((values) => {
        onFilterChange({ ...filterValues, ...values })
    }, 500)

    const handleDateChange = (startDate, endDate) => {
        onFilterChange({
            ...filterValues,
            date_updated_after: startDate,
            date_updated_before: endDate
        })
    }

    return (
        <Toolbar className={classes.toolbarItems}>
            <DateSelectButton
                startDate={filterValues.date_updated_after}
                endDate={filterValues.date_updated_before}
                handleDateChange={handleDateChange}
                initialButtonText={gettext('Past 2 weeks')}
            />

            <TextField
                id="search"
                type="search"
                className={classes.searchBar}
                margin="normal"
                onChange={(e) => handleFilterChange({ search: e.target.value })}
                variant="outlined"
                placeholder={gettext('Order id, fulfillment id')}
                InputProps={{ style: { height: 30, fontSize: 13, width: 200 } }}
                inputProps={{ style: { paddingLeft: 9 } }}
                defaultValue={filterValues.search}
            />

            {filterFields.map(field => (
                <FilterSelect
                    key={field.lookup}
                    field={field}
                    value={field.values.find(
                        (o) => o.value == filterValues[field.lookup]
                    )}
                    handleFilterChange={(field) => option => handleFilterChange({ [field.lookup]: option?.value })}
                />
            ))}
        </Toolbar>
    )
}

const FETCH_LIMIT = 25

function useStockRecordAdjustmentsInfiniteQuery(stockRecordId, params) {
    return useInfiniteQuery({
        enabled: true,
        queryKey: queryKeys.stock.stockRecordAdjustments(stockRecordId, params).queryKey,
        queryFn: ({ pageParam = 0 }) => {
            return api.stock.stockRecordAdjustments.query({
                query: {
                    ...params,
                    limit: FETCH_LIMIT,
                    offset: FETCH_LIMIT * pageParam,
                },
                params: { id: stockRecordId },
            }).then(res => res.body)
        },
        getNextPageParam: (lastPage) => {
            if (!lastPage.next) {
                return
            }

            const url = new URL(lastPage.next)
            const offset = parseInt(url.searchParams.get('offset') ?? '')
            if (isNaN(offset)) {
                return
            }

            return Math.ceil(offset / FETCH_LIMIT)
        },
    })
}
