export const ADD_STORE = 'add_store'

export function addStore(store) {
    return { type: ADD_STORE, store: store }
}

export const DELETE_STORE = 'delete_store'

export function deleteStore(storeID) {
    return { type: DELETE_STORE, storeID: storeID }
}

export const UPDATE_STORE = 'update_store'

export function updateStore(store) {
    return { type: UPDATE_STORE, store: store }
}
