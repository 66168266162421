import { useMemo } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import type { ProductFormSchema } from '~/products/hooks/useProductForm'

const useStyles = makeStyles({
    selectShopsShopName: {
        display: 'flex',
        alignItems: 'center',
    },
    selectShopsChannelIcon: {
        width: 22,
        marginRight: 10,
    },
})

type Props = {
    shops: ProductFormSchema['notConfiguredShops']
    onSelectShop: (shopId: number) => void
    onSelectAll: () => void
}

const SelectShopsTable = ({ shops, onSelectShop, onSelectAll }: Props) => {
    const classes = useStyles()

    const isSomeSelected = useMemo(() => shops.some((shop) => shop.selected), [shops])
    const isAllSelected = useMemo(() => shops.every((shop) => shop.selected), [shops])

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={isSomeSelected && !isAllSelected}
                                checked={isAllSelected}
                                onChange={onSelectAll}
                            />
                        </TableCell>

                        <TableCell align="left">{gettext('Shop')}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {shops.map((shop) => (
                        <TableRow
                            hover
                            key={shop.id}
                            selected={shop.selected}
                            onClick={() => onSelectShop(shop.id)}
                        >
                            <TableCell padding="checkbox">
                                <Checkbox checked={shop.selected} />
                            </TableCell>
                            <TableCell
                                className={classes.selectShopsShopName}
                                component="th"
                                scope="row"
                            >
                                <img
                                    className={classes.selectShopsChannelIcon}
                                    src={shop.channel.channel_square_icon_url}
                                />
                                <Typography>{shop.shop_name}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SelectShopsTable
