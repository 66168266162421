import { z } from 'zod'

import { Dimension } from '~/products/api/saveProduct'

export const NormalizedDimension = Dimension.pick({ index: true }).extend({
    id: z.union([z.string().uuid(), z.number()]),
    name: z.string().min(1, 'Dimension name is required'),
    options: z.array(
        Dimension.shape.options.element.pick({ index: true }).extend({
            id: z.union([z.string().uuid(), z.number()]),
            name: z.string().min(1, 'Option name is required'),
        })
    ),
})
export type NormalizedDimension = z.infer<typeof NormalizedDimension>
