import { useContext } from 'react'
import { useWatch } from 'react-hook-form'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { AttributesContext } from '../attributes-provider'

import SelectShopsTable from './select-shops-table'

import type { Channel } from '~/common/schemas/shop'
import { type ProductFormSchema, useProductFormContext } from '~/products/hooks/useProductForm'
import { getUniqueChannelsFromShops, getUniqueShopsFromChannelProducts } from '~/tools/utils'
import { getUUID } from '~/utils/uuid'

const useStyles = makeStyles((theme) => ({
    addShopsButton: {
        marginTop: 10,
    },
    addShopsContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 30,
    },
    addShopsPromptText: {
        color: theme.palette.grey[500],
        marginBottom: 15,
        marginTop: 10,
    },
    addShopsPaper: {
        width: 125,
        padding: 10,
        marginBottom: 20,
        cursor: 'pointer',
    },
    addShopsIcon: {
        width: '100%',
    },
}))

type Props = {
    open: boolean
    onClose: () => void
}

export default function DialogAddShops({ open, onClose }: Props) {
    const classes = useStyles()
    const { registerChannelProducts } = useContext(AttributesContext)
    const { control, getValues, setValue } = useProductFormContext()
    const [notConfiguredShops, store] = useWatch({ control, name: ['notConfiguredShops', 'store'] })

    function handleAddShopsSelectShop(shopId: number) {
        const shopIndex = notConfiguredShops.findIndex((shop) => shop.id === shopId)
        if (shopIndex === -1) {
            return
        }

        setValue(
            `notConfiguredShops.${shopIndex}.selected`,
            !notConfiguredShops[shopIndex].selected
        )
    }

    function handleAddShopsSelectAll() {
        const updatedShops = notConfiguredShops.map((shop) => ({
            ...shop,
            selected: !shop.selected,
        }))
        setValue('notConfiguredShops', updatedShops)
    }

    function handleAddShops() {
        const channelProducts = updateChannelProducts()

        updateNotConfiguredShops(channelProducts, store.shops)
        updateChannels()
        registerChannelProducts(channelProducts)
        onClose()
    }

    function updateChannelProducts() {
        const id = getValues('id')
        const channelProducts = getValues('channel_products')
        const stockUnits = getValues('stock_units')
        const images = getValues('images')
        const channels = getValues('channels')

        // TODO: Why is this check necessary?
        // const existingChannelProduct = channelProducts.find((cp) => !!cp.id)
        // if (!existingChannelProduct) {
        //     return channelProducts
        // }
        const channelCategoryMap = channels.reduce((map, channel) => {
            return map.set(channel.id, channel.category)
        }, new Map<number, ProductFormSchema['channels'][0]['category']>())

        const channelProductImages = images
            .map((image) => ({
                ...image,
                product_image: image,
                display_order: image.display_order,
            }))
            .sort(
                (a, b) => a.display_order - b.display_order
            ) satisfies ProductFormSchema['channel_products'][0]['images']

        const channelStockUnits = stockUnits.map((su) => ({
            sku: su.sku,
            price: '',
            images: su.images
                .map((image) => ({
                    stock_unit_image: image,
                    display_order: image.display_order,
                }))
                .sort((a, b) => a.display_order - b.display_order),
            placeholder: false,
            stock_unit: su,
            channel_stock_item: null,
            fulfillment_locations: [],
        })) satisfies ProductFormSchema['channel_products'][0]['channel_stock_units']

        const newChannelProducts = notConfiguredShops
            .filter((shop) => shop.selected)
            .map((shop) => ({
                id: getUUID(),
                product: typeof id === 'number' ? id : undefined,
                shop,
                name: null,
                display_constant_stock: false,
                channel_stock_units: [...channelStockUnits],
                images: [...channelProductImages],
                shouldCreateChannelProduct: true,
                has_channel_specific_media: false,
                status: 'active',
                translations: {
                    [shop.local_language]: {
                        name: '',
                        description: '',
                    },
                    ...store.languages.reduce((obj, l) => {
                        if (l !== shop.local_language) {
                            obj[l] = {
                                name: '',
                                description: '',
                            }
                        }

                        return obj
                    }, {} as Record<string, { name: string; description: string }>),
                },
                category: channelCategoryMap.get(shop.channel.id) || null,
                category_id: channelCategoryMap.get(shop.channel.id)?.category_id || null,
            })) satisfies ProductFormSchema['channel_products']

        const updatedChannelProducts = [...channelProducts, ...newChannelProducts]

        setValue('channel_products', updatedChannelProducts)

        return updatedChannelProducts
    }

    function updateNotConfiguredShops(
        channelProducts: ProductFormSchema['channel_products'],
        shops: NonNullable<typeof store>['shops']
    ) {
        const shopsForProduct = getUniqueShopsFromChannelProducts(channelProducts)
        const shopIDsForProduct = new Set(shopsForProduct.map((shop) => shop.id))
        const notConfiguredShops = shops
            .filter((shop) => !shopIDsForProduct.has(shop.id) || shop.channel.allow_duplicate_skus)
            .map((shop) => ({ ...shop, selected: false }))

        setValue('notConfiguredShops', notConfiguredShops)
    }

    function updateChannels() {
        const configuredChannelIds = new Set(getValues('channels').map((channel) => channel.id))
        const selectedShops = notConfiguredShops.filter((shop) => shop.selected)
        const newShopChannels = getUniqueChannelsFromShops(selectedShops)
        const channelsToAdd = newShopChannels.reduce((channels, channel) => {
            channel.category = null

            if (!configuredChannelIds.has(channel.id)) {
                channels.push(channel)
            }

            return channels
        }, [] as Channel[])

        setValue('channels', [...getValues('channels'), ...channelsToAdd])
    }

    return (
        <Dialog fullWidth open={open} maxWidth="xs">
            <DialogContent className={classes.addShopsContent}>
                <Typography className={classes.addShopsPromptText}>
                    {gettext('Select shops to add')}
                </Typography>
                <SelectShopsTable
                    shops={notConfiguredShops}
                    onSelectShop={handleAddShopsSelectShop}
                    onSelectAll={handleAddShopsSelectAll}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{gettext('Cancel')}</Button>
                <Button color="secondary" onClick={handleAddShops}>
                    {gettext('Add shops')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
