import { grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import PropTypes from 'prop-types'

import DialogDeleteConfirm from '../../../components/DialogDeleteConfirm'
import { deleteGroupById } from '../../../services/groups.service'

const useStyles = makeStyles(() => ({
    summary: {
        marginTop: 30,
        marginBottom: 30,
        padding: 15,
        border: `1px solid ${grey[400]}`,
        borderRadius: 5,
    },
    info: {
        fontSize: '0.9rem',
        color: grey[700],
    },
}))

export default function GroupDeleteConfirm({ open, group, onClose }) {
    const classes = useStyles()

    async function handleConfirm() {
        await deleteGroupById(group.id)
        onClose()
    }

    return (
        <DialogDeleteConfirm open={open} onConfirm={handleConfirm} onClose={onClose}>
            <Typography>{gettext('Are you sure you want to delete this group?')}</Typography>
            <div className={classes.summary}>
                <Typography className={classes.info}>{group?.name}</Typography>
            </div>
        </DialogDeleteConfirm>
    )
}

GroupDeleteConfirm.propTypes = {
    open: PropTypes.bool.isRequired,
    group: PropTypes.object,
    onClose: PropTypes.func.isRequired,
}
