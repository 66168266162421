import { useCallback, useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import type {
    AutocompleteChangeReason,
    AutocompleteInputChangeReason,
} from '@material-ui/lab/Autocomplete'

import { useQuery } from '@tanstack/react-query'

import BundleItemStockItems from './bundle-item-stock-items'

import useDebounce from '~/common/hooks/useDebounce'
import type { Product } from '~/common/schemas/product'
import { type StockItem } from '~/common/schemas/stock-item'
import { type ProductFormSchema } from '~/products/hooks/useProductForm'
import { useProductPage } from '~/products/hooks/useProductPage'
import { useAppSelector } from '~/store'
import { getTranslation } from '~/tools/utils'

const useStyles = makeStyles((theme) => ({
    paper: {
        minHeight: 400,
    },
    selectSKUPrompt: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}))

type BundleItem = ProductFormSchema['stock_units'][0]['stock_item']['bundle_items'][0]

type Props = {
    open: boolean
    onAdd: (bundledItem: BundleItem) => void
    onClose: () => void
}

export default function BundleItemSelector({ open, onAdd, onClose }: Props) {
    const classes = useStyles()

    const [productId, setProductId] = useState(-1)
    const [searchInput, setSearchInput] = useState('')

    const { data: options = [], isFetching } = useSearchProductsQuery(searchInput)

    const closeDialog = useCallback(() => {
        setSearchInput('')
        setProductId(-1)
        onClose()
    }, [onClose])

    const handleSelectStockUnit = useCallback(
        (stockItem: StockItem) => {
            onAdd({ member_bundle_item: stockItem, num_of_units: 1 })
            closeDialog()
        },
        [closeDialog, onAdd]
    )

    const handleInputChange = (value: string, reason: AutocompleteInputChangeReason) => {
        switch (reason) {
            case 'input':
                setSearchInput(value)
                break
            case 'clear':
                setSearchInput('')
                setProductId(-1)
                break
            case 'reset':
                break
            default:
                break
        }
    }

    const handleChange = (value: (typeof options)[0] | null, reason: AutocompleteChangeReason) => {
        if (reason === 'select-option' && typeof value === 'object' && value) {
            setProductId(value.value)
        }
    }

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            classes={{ paper: classes.paper }}
            onClose={closeDialog}
        >
            <DialogTitle>{gettext('Add bundle item')}</DialogTitle>
            <DialogContent>
                <Autocomplete
                    loading={isFetching}
                    noOptionsText={gettext('No options')}
                    options={options}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={gettext('Search by product name or SKU')}
                            variant="outlined"
                        />
                    )}
                    inputValue={searchInput}
                    filterOptions={(x) => x}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onInputChange={(_, value, reason) => handleInputChange(value, reason)}
                    onChange={(_, value, reason) => handleChange(value, reason)}
                />

                {productId > -1 && (
                    <>
                        <Typography className={classes.selectSKUPrompt} variant="subtitle2">
                            {gettext('Select an SKU to add to this bundle')}
                        </Typography>

                        <BundleItemStockItems
                            productId={productId}
                            onSelectStockUnit={handleSelectStockUnit}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={closeDialog}>
                    {gettext('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function useSearchProductsQuery(input: string) {
    const language = useAppSelector((state) => state.initial.language)
    const productsForUserAPIURL = useAppSelector(
        (state) => state.initial.endpoints.productsForUserAPIURL
    )
    const user = useAppSelector((state) => state.initial.user)
    const { isNewProduct, productInfo } = useProductPage()
    const searchInputDebounced = useDebounce(input)

    return useQuery({
        enabled: !!searchInputDebounced && !!user,
        queryKey: ['products-search', { productsForUserAPIURL, searchInputDebounced }],
        queryFn: async () => {
            const url =
                productsForUserAPIURL.replace('user_pk', user?.id.toString() ?? '') +
                '?search=' +
                searchInputDebounced

            const res = await fetch(url)
            return await res.json()
        },
        select: (data: { results: Product[] }) => {
            return data.results
                .filter(({ id }) => isNewProduct || id !== productInfo.product.id)
                .map((item) => ({ value: item.id, label: getTranslation(item, language, 'name') }))
        },
    })
}
