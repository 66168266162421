import { z } from 'zod'

export const UserLocation = z.object({
    id: z.number(),
    location_id: z.string(),
    location_type: z.string(),
    name: z.string(),
    available_for_fulfillment: z.boolean(),
})

export type UserLocation = z.infer<typeof UserLocation>
