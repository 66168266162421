import { useEffect, useMemo } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

import { z } from 'zod'

import { toProductFormSchema } from '../utils/transformers/toProductFormSchema'

import { useProductPage } from './useProductPage'

import { Dimension } from '~/common/schemas/dimension'
import { UserLocation } from '~/common/schemas/location'
import { ShopeeLogisticsProvider } from '~/common/schemas/logistics-shopee'
import { Media } from '~/common/schemas/media'
import {
    ChannelProduct,
    ChannelStockUnit,
    ChannelStockUnitDiscount,
    Product,
    ProductDiscount,
    ProductVideo,
    ChannelProductVideo,
} from '~/common/schemas/product'
import { Shop } from '~/common/schemas/shop'
import { BundleItem, StockItem } from '~/common/schemas/stock-item'
import { StockRecord } from '~/common/schemas/stock-record'
import { StockUnit } from '~/common/schemas/stock-unit'
import { Store } from '~/common/schemas/store'
import { getUniqueChannelsFromShops } from '~/tools/utils'
import { getUUID } from '~/utils/uuid'

export const LogisticsAttributeValue = z.record(
    z
        .string()
        .refine((value): value is `shop-${number}` => {
            const key = value.split('-')
            const shopId = parseInt(key[1])
            return key.length === 2 && key[0] === 'shop' && !isNaN(shopId)
        })
        .transform((value, ctx) => {
            const key = value.split('-')
            const shopId = parseInt(key[1])
            if (key.length !== 2 || key[0] !== 'shop' || isNaN(shopId)) {
                ctx.addIssue({
                    code: 'custom',
                    message: 'Invalid shop ID',
                    path: [],
                })
                return z.NEVER
            }

            return shopId
        })
        .describe('Shop ID'),
    z.discriminatedUnion('channel', [
        z.object({
            channel: z.literal('shopee'),
            value: z.array(ShopeeLogisticsProvider.partial()),
        }),
        z.object({
            channel: z.literal('tiktok'),
            value: z.array(z.string()),
        }),
    ])
)

export type LogisticsAttributeValue = z.infer<typeof LogisticsAttributeValue>

export const StockUnitFormSchema = StockUnit.partial({ product: true }).extend({
    id: z.string().or(z.number()),
    fulfillment_locations: UserLocation.array(),
    stock_records: StockRecord.partial({ id: true, stock_item: true }).array(),
    dimension_options: StockUnit.shape.dimension_options.element
        .extend({ id: z.string().or(z.number()) })
        .array(),
    images: StockUnit.shape.images.element.partial({ stock_unit: true, id: true }).array(),
    stock_item: StockItem.partial({ id: true, stock_unit: true }).extend({
        bundle_items: BundleItem.partial({ id: true }).array(),
    }),
})
export type StockUnitFormSchema = z.infer<typeof StockUnitFormSchema>

export const ProductAttributeValue = z.object({
    value: z.union([
        z.string(),
        z.array(z.string()),
        z.object({
            label: z.string(),
            value: z.nullable(z.string()),
            inactive: z.boolean(),
        }),
        z.array(
            z.object({
                label: z.string(),
                value: z.nullable(z.string()),
                inactive: z.boolean(),
            })
        ),
        LogisticsAttributeValue,
    ]),
    unit: z
        .object({
            id: z.number(),
            name: z.string(),
        })
        .nullish(),
})
export type ProductAttributeValue = z.infer<typeof ProductAttributeValue>

const StockUnitAttributeValue = z
    .record(
        z.union([
            z.string(),
            z.object({ label: z.string(), value: z.string(), inactive: z.boolean() }),
        ])
    )
    .describe('Stock unit attribute value by attribute ID')

export const ProductFormSchema = Product.omit({
    cover_image_url: true,
    date_created: true,
    has_merged_channel_products: true,
    status: true,
}).extend({
    channel_attribute_values: z
        .record(
            z.object({
                product: z
                    .record(ProductAttributeValue)
                    .describe('Product attribute values by attribute ID'),
                stockUnit: z
                    .record(StockUnitAttributeValue)
                    .optional()
                    .describe('Stock unit attribute values by stock unit ID'),
            })
        )
        .describe('Channel attribute values by channel ID'),
    id: z.string().or(z.number()),
    dimensions: Dimension.extend({
        id: z.union([z.string(), z.number()]),
        options: Dimension.shape.options.element
            .extend({
                id: z.union([z.string(), z.number()]),
            })
            .array(),
    }).array(),
    grouped_media: Media.array().nullish(),
    images: Product.shape.images.element
        .partial({ id: true, product: true })
        .array()
        .min(1, gettext('At least one image is required')),
    videos: ProductVideo.array().nullish(),
    stock_units: StockUnitFormSchema.array()
        .min(1, 'At least one stock unit is required')
        .superRefine((stockUnits, ctx) => {
            const indexesBySku = stockUnits.reduce((acc, { sku }, index) => {
                if (!sku) {
                    return acc
                }

                const currentIndexesBySku = acc.get(sku) ?? []

                return acc.set(sku, [...currentIndexesBySku, index])
            }, new Map<string, number[]>())

            indexesBySku.forEach((indexesWithSameSku) => {
                if (indexesWithSameSku.length > 1) {
                    indexesWithSameSku.forEach((index) => {
                        ctx.addIssue({
                            path: [index, 'sku'],
                            code: 'custom',
                            message: gettext('Duplicate SKU'),
                        })
                    })
                }
            })
        }),
    channel_products: ChannelProduct.partial({ product: true, status: true })
        .extend({
            id: z.string().or(z.number()),
            shouldCreateChannelProduct: z.boolean().default(true),
            channel_stock_units: ChannelStockUnit.partial({
                id: true,
                status: true,
                channel_product: true,
            })
                .extend({
                    stock_unit: StockUnitFormSchema.extend({ sku: z.string() }).optional(),
                    placeholder: z.boolean(),
                    images: ChannelStockUnit.shape.images.element
                        .partial({ channel_stock_unit: true, id: true })
                        .array(),
                })
                .array(),
            grouped_media: Media.array().nullish(),
            images: ChannelProduct.shape.images.element
                .partial({ id: true, channel_product: true })
                .array()
                .min(1, gettext('At least one image is required')),
            videos: ChannelProductVideo.array().nullish(),
        })
        .array(),
    notConfiguredShops: Shop.extend({ selected: z.boolean().default(false) }).array(),
    channels: Shop.shape.channel
        .extend({
            category: Product.shape.channel_products.element.shape.category.optional(),
        })
        .array(),
    store: Store.extend({
        shops: Shop.omit({ store: true }).array(),
        languages: z.string().array(),
    }),
    product_discounts: ProductDiscount.array(),
    active_channel_stock_unit_discounts: ChannelStockUnitDiscount.array(),
})
export type ProductFormSchema = z.input<typeof ProductFormSchema>

export function useProductFormContext() {
    return useFormContext<ProductFormSchema>()
}

export function useProductForm(shouldBulkEditPackageInfo = false) {
    const { isNewProduct, productInfo } = useProductPage()
    const { store, locations: fulfillmentLocations } = productInfo

    const values = useMemo(() => {
        if (isNewProduct) {
            return
        }

        return toProductFormSchema({
            store,
            fulfillmentLocations,
            product: productInfo.product,
            stockRecords: productInfo.stockRecords,
            discounts: productInfo.productDiscounts,
            activeChannelStockUnitDiscounts: productInfo.activeChannelStockUnitDiscounts,
            existingChannelAttributeValues: productInfo.channelAttributeValues,
        })
    }, [isNewProduct, productInfo, store, fulfillmentLocations])

    const methods = useForm<ProductFormSchema>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: {
            id: getUUID(),
            store,
            channel_attribute_values: {},
            channel_products: getInitialChannelProducts(store),
            dimensions: [],
            images: [],
            package_height: 0,
            package_length: 0,
            package_weight: 0,
            package_width: 0,
            product_discounts: [],
            active_channel_stock_unit_discounts: [],
            stock_units: [
                {
                    id: getUUID(),
                    dimension_options: [],
                    images: [],
                    sku: '',
                    fulfillment_locations: fulfillmentLocations,
                    stock_records: fulfillmentLocations.map((location) => ({
                        location,
                        quantity: 0,
                    })),
                    stock_item: {
                        barcode: null,
                        bundle_items: [],
                        is_bundle: false,
                        sku: '',
                    },
                },
            ],
            stock_units_editing_is_enabled: true,
            translations: store.languages.reduce(
                (acc, language) => ({
                    ...acc,
                    [language]: { name: '', description: '', short_description: '' },
                }),
                {}
            ),
            notConfiguredShops: [],
            channels: getUniqueChannelsFromShops(store.shops),
        },
        values,
        shouldFocusError: false,
    })

    useEffect(() => {
        const channelProducts = values?.channel_products
        if (!channelProducts) {
            return
        }

        channelProducts.forEach((cp, index) => {
            if (!cp.error_message) {
                return
            }

            methods.setError(`channel_products.${index}.error_message`, {
                type: 'manual',
                message: cp.error_message,
            })
        })
    }, [methods, values])

    return methods
}

function getInitialChannelProducts(store: ProductFormSchema['store']) {
    return store.shops.map((shop) => {
        const translations: ChannelProduct['translations'] = {}
        store.languages.forEach((language) => {
            translations[language] = {
                name: '',
                description: '',
                short_description: '',
            }
        })

        return {
            id: getUUID(),
            shop,
            categoryId: 0,
            display_constant_stock: false,
            channel_stock_units: [
                {
                    placeholder: false,
                    price: '',
                    images: [],
                    sku: '',
                    channel_stock_item: { fulfillment_stock_record_options: [] },
                },
            ],
            images: [],
            shouldCreateChannelProduct: true,
            has_channel_specific_media: false,
            translations,
        }
    }) satisfies ProductFormSchema['channel_products']
}
