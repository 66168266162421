import { useState, useEffect, useCallback } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search'

import { useTableStyles } from '../../../theme'
import { sortArrayByField, sortByFieldTextAsc } from '../../../utils'

import GroupDeleteConfirm from './group-delete-confirm'
import GroupDetail from './group-detail'

import { api, queryKeys } from '~/api'
import { useAppSelector } from '~/store'

export default function Groups() {
    const accountOwnerId = useAppSelector((state) => state.initial.accountOwner.id)

    const tableClasses = useTableStyles()
    const [searchTerm, setSearchTerm] = useState('')
    const [groupDetailsOpen, setGroupDetailsOpen] = useState(false)
    const [isCreateGroup, setIsCreateGroup] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
    const { data, isLoading, error } = api.users.accountGroupListing.useQuery(
        queryKeys.users.groups(accountOwnerId).queryKey,
        { params: { id: accountOwnerId } },
        {
            select: (data) => ({
                ...data,
                body: sortArrayByField(data.body, 'name', sortByFieldTextAsc),
            }),
        }
    )

    useEffect(() => {
        if (!deleteConfirmationOpen) {
            setSelectedGroup(null)
        }
    }, [deleteConfirmationOpen])

    useEffect(() => {
        if (!groupDetailsOpen) {
            setSelectedGroup(null)
        }
    }, [groupDetailsOpen])

    const renderGroups = useCallback(() => {
        if (isLoading) {
            return (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 2,
                    }}
                >
                    <CircularProgress size={30} color="secondary" />
                </Box>
            )
        }

        if (error) {
            return <Typography color="error">{gettext('Something went wrong')}</Typography>
        }

        const filteredGroups = filterGroups(data.body, searchTerm)
        if (filteredGroups.length === 0) {
            return (
                <Box
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}
                >
                    <AccountCircleIcon color="disabled" />
                    <Typography color="textSecondary">{gettext('No groups found')}</Typography>
                </Box>
            )
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{gettext('Group name')}</TableCell>
                            <TableCell width={50} align="center">
                                {gettext('Edit')}
                            </TableCell>
                            <TableCell width={50} align="center">
                                {gettext('Delete')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredGroups.map((group) => (
                            <TableRow key={group.id}>
                                <TableCell className={tableClasses.tableCell}>
                                    {group.name}
                                </TableCell>
                                <TableCell className={tableClasses.tableCell} align="center">
                                    <IconButton
                                        aria-label="edit"
                                        onClick={() => {
                                            setIsCreateGroup(false)
                                            setSelectedGroup({ ...group })
                                            setGroupDetailsOpen(true)
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell className={tableClasses.tableCell} align="center">
                                    {group && !group.is_admin && (
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                setSelectedGroup({ ...group })
                                                setDeleteConfirmationOpen(true)
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }, [data?.body, error, isLoading, searchTerm, tableClasses.tableCell])

    return (
        <Box sx={{ p: 4, display: 'grid', gridGap: '1rem' }}>
            <Typography variant="h5">{gettext('Groups')}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    gridGap: '1rem',
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <TextField
                    fullWidth
                    value={searchTerm}
                    variant="outlined"
                    size="small"
                    placeholder={gettext('Search')}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="disabled" />
                            </InputAdornment>
                        ),
                        endAdornment: searchTerm ? (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={() => setSearchTerm('')}>
                                    <CancelIcon color="disabled" />
                                </IconButton>
                            </InputAdornment>
                        ) : null,
                    }}
                />

                <Box clone sx={{ flexShrink: 0 }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setIsCreateGroup(true)
                            setGroupDetailsOpen(true)
                        }}
                    >
                        {gettext('Create group')}
                    </Button>
                </Box>
            </Box>

            {renderGroups()}

            <GroupDetail
                open={groupDetailsOpen}
                isCreateGroup={isCreateGroup}
                accountOwnerId={accountOwnerId}
                groupId={selectedGroup?.id}
                onClose={async (newGroup) => {
                    if (newGroup) {
                        // TODO: Refetch group
                    }
                    setGroupDetailsOpen(false)
                    setSelectedGroup(null)
                }}
            />

            <GroupDeleteConfirm
                accountOwnerId={accountOwnerId}
                open={deleteConfirmationOpen}
                group={selectedGroup}
                onClose={() => {
                    setDeleteConfirmationOpen(false)
                    // TODO: Refetch groups
                }}
            />
        </Box>
    )
}

function filterGroups(groups, searchTerm) {
    return searchTerm
        ? groups.filter((group) => group.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : groups
}
