import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
    palette: {
        primary: {
            main: '#41C49C',
        },
        secondary: {
            main: '#4B84C3',
        },
        error: {
            main: '#F25073',
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: ['Roboto', 'Lato', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        subtitle1: {
            fontWeight: 400,
            fontSize: '1rem',
        },
        body2: {
            fontSize: '0.9rem',
        },
    },
    overrides: {
        MuiTableRow: {
            head: {
                height: 10,
            },
        },
        MuiTableCell: {
            root: {
                padding: '10px 16px 10px 16px',
            },
            head: {
                lineHeight: '0.9rem',
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '0.8em',
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 40,
            },
        },
    },
})
