import { makeStyles } from '@material-ui/core/styles'

export const useCommonStyles = makeStyles((theme) => ({
    section: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 20,
        paddingRight: 20,
    },
    sectionTitle: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid ' + theme.palette.grey[200],
        marginLeft: -20,
        marginRight: -20,
        paddingLeft: 20,
        paddingBottom: 10,
    },
    productImageHelperText: {
        marginTop: 20,
    },
    productImageErrorText: {
        marginTop: 10,
        marginLeft: 5,
        color: theme.palette.error.main,
    },
    categoryPicker: {},
    stockUnits: {},
    stockUnit: {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid ' + theme.palette.grey[200],
        marginLeft: -20,
        marginRight: -20,
        padding: 20,
    },
    channelIcon: {
        width: 25,
        marginRight: 10,
    },
    attributes: {
        marginTop: 20,
    },
    textField: {
        marginRight: 10,
    },
    stockUnitsAttributes: {},
    stockUnitAttributes: {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        paddingTop: 15,
    },
    stockUnitAttributesTitle: {
        marginBottom: 10,
    },
    logisticsContainer: {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        paddingTop: 10,
        paddingBottom: 10,
    },
    logisticsTitle: {
        margin: '10px 15px',
    },
    channelLink: {
        marginLeft: 15,
        color: theme.palette.grey[500],
        cursor: 'pointer',
        fontSize: '0.9rem',
    },
    constantStockSection: {
        display: 'flex',
        alignItems: 'center',
    },
    displayConstantStockInfoIcon: {
        fontSize: '1.5rem',
        marginRight: 5,
        color: theme.palette.grey[500],
    },
    imageTogglerContainer: {
        marginTop: 30,
    },
    imageTogglerText: {
        fontSize: '0.75rem',
        color: theme.palette.grey[600],
    },
    discountSelect: {
        width: 300,
        marginRight: 15,
        marginBottom: 20,
    },
    channelStockUnitDiscount: {
        display: 'flex',
        alignItems: 'center',
    },
    stockUnitCoverageButton: {
        marginTop: 10,
        marginRight: 10,
    },
    hasErrorsMessage: {
        color: theme.palette.error.main,
        marginRight: 15,
    },
    saveButton: {
        color: 'white',
    },
}))
