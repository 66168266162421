import { connect } from 'react-redux'

import ProductImportIssues from '../components/product-import-issues'

const mapStateToProps = (state) => ({
    productImportIssuesAPIURL: state.initial.endpoints.productImportIssuesAPIURL,
    deleteChannelProductByIDAPIURL: state.initial.endpoints.deleteChannelProductByIDAPIURL,
    shopAPIURL: state.initial.endpoints.shopAPIURL,
    language: state.initial.language,
    userID: state.initial.user.id,
    staticRoot: state.initial.staticRoot,
})

const matchDispatchToProps = (dispatch) => ({
    updateStore: (store) => {
        dispatch(updateStore(store))
    },
})

export default connect(mapStateToProps, matchDispatchToProps)(ProductImportIssues)
