import { connect } from 'react-redux'

import ProductSelector from '../components/product-selector'

const mapStateToProps = (state) => ({
    productsForStoreSummaryAPIURL: state.initial.endpoints.productsForStoreSummaryAPIURL,
    discountsForStoreAPIURL: state.initial.endpoints.discountsForStoreAPIURL,
    productDiscountsAPIURL: state.initial.endpoints.productDiscountsAPIURL,
    staticRoot: state.initial.staticRoot,
    stores: state.stores,
    userID: state.initial.user.id,
    language: state.initial.language,
})

export default connect(mapStateToProps)(ProductSelector)
