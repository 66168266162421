import React from 'react'
import Select from 'react-select'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { withStyles, withTheme } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'

import classNames from 'classnames'
import { Howl } from 'howler'
import Cookie from 'js-cookie'

import {
    copyObject,
    isValidBarcodeCharacter,
    getTranslation,
    range,
    truncate,
} from '../../tools/utils'

const styles = (theme) => ({
    dialog: {
        height: 'calc(100% - 75px)',
    },
    loadingDialogContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    packedSaleContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    packedSaleContainer: {},
    allAtOnceContainer: {
        width: '100%',
    },
    fulfillmentLineItems: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    fulfillmentLineItem: {
        display: 'flex',
        width: 'calc(50% - 15px)',
        padding: 15,
        marginRight: 15,
        marginBottom: 15,
        border: '1px solid lightgray',
        borderRadius: 5,
    },
    fulfillmentLineItemHighlighted: {
        borderColor: theme.palette.primary.main,
    },
    coverImageAllAtOnce: {
        width: '8vw',
        minWidth: '8vw',
        marginRight: 20,
    },
    allAtOnceRightSection: {},
    productNameAllAtOnce: {
        fontSize: '0.9rem',
    },
    stockUnitSectionAllAtOnce: {
        display: 'flex',
        marginTop: 10,
    },
    stockUnitSubSectionAllAtOnce: {
        marginRight: 20,
    },
    stockUnitSubSectionTitleAllAtOnce: {
        color: grey[500],
        fontSize: '0.9rem',
    },
    stockUnitSubSectionTextAllAtOnce: {
        fontSize: '0.9rem',
    },
    progressSectionAllAtOnce: {
        marginTop: 10,
    },
    sliProgressMeter: {
        display: 'flex',
        flex: '1 0 auto',
    },
    sliProgressMeterSegment: {
        backgroundColor: grey[300],
        marginRight: 3,
        height: 5,
        flex: '1 0 auto',
    },
    sliProgressMeterSegmentActive: {
        backgroundColor: theme.palette.primary.main,
    },
    sliUnitsNeeded: {
        fontSize: '0.9rem',
        marginTop: 3,
    },
    dialogContent: {
        display: 'flex',
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid lightgray',
    },
    allAtOnceError: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 20,
    },
    allAtOnceErrorIcon: {},
    allAtOnceErrorText: {
        marginLeft: 3,
        fontSize: '1rem',
        color: theme.palette.error.main,
    },
    titleSpacer: {
        flexGrow: 1,
    },
    startWithBarcodeText: {
        color: grey[500],
    },
    startWithBarcodeSwitch: {
        marginRight: 10,
    },
    showOrdersText: {
        color: grey[500],
        marginRight: 10,
    },
    modeSelect: {
        width: 150,
    },
    stockUnitInfo: {
        display: 'flex',
        padding: '10px 30px 30px 30px',
        alignItems: 'flex-start',
        width: '100%',
    },
    coverImage: {
        width: '25vw',
        minWidth: '25vw',
        marginRight: 50,
    },
    rightPanel: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    progressForOrder: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
        paddingBottom: 20,
        borderBottom: '1px solid lightgray',
    },
    progressTextContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 5,
    },
    itemsRemaining: {
        fontSize: '1.3rem',
        marginRight: 10,
    },
    itemsRemainingLabel: {
        fontSize: '1rem',
        color: grey[500],
    },
    progressMeter: {
        display: 'flex',
        flex: '1 0 auto',
    },
    progressMeterSegment: {
        backgroundColor: grey[300],
        marginRight: 3,
        height: 10,
        flex: '1 0 auto',
    },
    progressMeterSegmentActive: {
        backgroundColor: theme.palette.primary.main,
    },
    productName: {},
    stockUnitSection: {
        display: 'flex',
        marginTop: 40,
    },
    stockUnitSubSection: {
        marginRight: 50,
    },
    stockUnitSubSectionTitle: {
        color: grey[500],
        fontSize: '1.3rem',
    },
    stockUnitSubSectionText: {},
    row: {
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: 40,
    },
    unitsRequired: {},
    unitsRequiredTitle: {
        color: grey[500],
        fontSize: '1.3rem',
    },
    unitsRequiredText: {
        color: grey[400],
    },
    unitsStillNeeded: {
        marginRight: 40,
    },
    unitsStillNeededTitle: {
        color: grey[500],
        fontSize: '1.3rem',
    },
    prompt: {
        color: grey[500],
        marginTop: 20,
        fontSize: '1.2rem',
    },
    scanIcon: {
        fontSize: 50,
        marginRight: 10,
    },
    confirmIcon: {
        marginTop: 5,
        fontSize: 60,
    },
    scannedMessage: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 40,
    },
    scanErrorText: {
        color: theme.palette.error.main,
        fontSize: '1.3rem',
    },
    confirmedText: {
        color: theme.palette.primary.main,
    },
    actionButton: {},
    nextButton: {
        color: 'white',
    },
    verticalSpacer: {
        flex: '1 1 auto',
    },
    itemControls: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    previousItemButton: {
        marginRight: 10,
    },
    nextItemButton: {},
    dialogActions: {
        borderTop: '1px solid lightgray',
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 15,
    },
    soundSwitchContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
    },
    soundSwitchText: {
        color: grey[600],
    },
    spacer: {
        flex: '1 1 auto',
    },
    counterText: {
        color: grey[600],
        marginRight: 10,
    },
    allConfirmed: {
        display: 'flex',
        alignItems: 'center',
    },
    allConfirmedIcon: {
        fontSize: 60,
        marginRight: 15,
    },
    allConfirmedIconText: {
        color: grey[600],
    },
    saleInformation: {
        marginLeft: 75,
        marginTop: 10,
    },
    channelIcon: {
        height: 30,
        marginBottom: 10,
    },
    saleInfoSaleID: {
        color: grey[600],
        fontSize: '1.3rem',
        marginBottom: 10,
    },
    address: {},
    addressText: {
        color: grey[600],
        fontSize: '1.3rem',
    },
    nextSaleButton: {
        marginLeft: 75,
        marginTop: 15,
        color: 'white',
    },
    startWithBarcodeContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    scanBarcodeIcon: {
        width: 100,
    },
    scanBarcodeText: {
        fontSize: '2rem',
        color: grey[500],
    },
    scanBarcodeSubtext: {
        color: grey[600],
    },
    startWithBarcodeError: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 15,
    },
    startWithBarcodeErrorIcon: {},
    startWithBarcodeErrorText: {
        marginLeft: 3,
        fontSize: '1rem',
        color: theme.palette.error.main,
    },
    documentsSection: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 75,
        marginTop: 50,
    },
    documentOptionsGroup: {
        display: 'flex',
        flexDirection: 'row',
    },
})

const ORDER_DISPLAY_MODES = {
    ONE_BY_ONE: 'one_by_one',
    ALL_AT_ONCE: 'all_at_once',
}

const ORDER_DISPLAY_OPTIONS = [
    { label: gettext('One by one'), value: ORDER_DISPLAY_MODES.ONE_BY_ONE },
    { label: gettext('All at once'), value: ORDER_DISPLAY_MODES.ALL_AT_ONCE },
]

const PAGES = {
    LOADING: 'loading',
    FULFILLMENT_COMPLETE: 'fulfillment_complete',
    START_WITH_BARCODE: 'start_with_barcode',
    PROCESS_ORDER: 'process_order',
    ALL_FULFILLMENTS_COMPLETE: 'all_fulfillments_complete',
}

function OrderProgress(props) {
    const { classes, fulfillment } = props

    return (
        <div className={classes.progressForOrder}>
            <div className={classes.progressTextContainer}>
                <Typography
                    variant="h5"
                    className={classNames(classes.progressText, classes.itemsRemaining)}
                >
                    {interpolate(pgettext('Items in order', '%s of %s'), [
                        fulfillment.fulfillment_line_items.reduce(
                            (total, fli) => total + fli.numPacked,
                            0
                        ),
                        fulfillment.fulfillment_line_items.reduce(
                            (total, fli) => total + fli.quantity,
                            0
                        ),
                    ])}
                </Typography>
                <Typography
                    variant="h5"
                    className={classNames(classes.progressText, classes.itemsRemainingLabel)}
                >
                    {gettext('items packed in this order')}
                </Typography>
            </div>
            <div className={classes.progressMeter}>
                {range(
                    0,
                    fulfillment.fulfillment_line_items.reduce(
                        (total, fli) => total + fli.quantity,
                        0
                    )
                ).map((fli, index) => {
                    const itemsPackedForOrder = fulfillment.fulfillment_line_items.reduce(
                        (total, fli) => total + fli.numPacked,
                        0
                    )

                    return (
                        <div
                            className={classNames(classes.progressMeterSegment, {
                                [classes.progressMeterSegmentActive]: index < itemsPackedForOrder,
                            })}
                            key={index}
                        ></div>
                    )
                })}
            </div>
        </div>
    )
}

const extractSaleLineItemInfo = (saleLineItem, language) => ({
    image: saleLineItem.stock_unit?.product?.cover_image_url || saleLineItem.image,
    productName: saleLineItem.stock_unit?.product
        ? getTranslation(saleLineItem.stock_unit.product, language, 'name')
        : saleLineItem.product_name,
    sku: saleLineItem.stock_unit?.sku || saleLineItem.sku,
    skuName: saleLineItem.stock_unit?.sku_name || saleLineItem.stock_unit_name || gettext('None'),
    barcode: saleLineItem.stock_unit?.barcode || gettext('Not set'),
})

class OrderProcessor extends React.PureComponent {
    constructor(props) {
        super(props)

        const orderDisplayMode =
            localStorage.getItem('orderDisplayMode') || ORDER_DISPLAY_MODES.ONE_BY_ONE
        const orderDisplayOption = ORDER_DISPLAY_OPTIONS.find(
            (option) => option.value === orderDisplayMode
        )

        const startWithBarcode = localStorage.getItem('startWithBarcode') === 'true' ? true : false

        const getShippingLabel = localStorage.getItem('getShippingLabel') === 'true' ? true : false
        const getPackList = localStorage.getItem('getPackList') === 'true' ? true : false
        const getTaxInvoice = localStorage.getItem('getTaxInvoice') === 'true' ? true : false

        this.state = {
            page: PAGES.LOADING,
            fulfillments: [],
            salesByID: null,
            totalSalesCount: 0,
            next: null,
            currentFulfillmentIndex: 0,
            currentItemIndex: 0,
            showOneByOneError: false,
            showAllAtOnceError: false,
            showStartWithBarcodeError: false,
            soundOn: true,
            orderDisplayOption: orderDisplayOption,
            startWithBarcode: startWithBarcode,
            justScannedFulfillmentLineItemID: '',

            getShippingLabel: getShippingLabel,
            getPackList: getPackList,
            getTaxInvoice: getTaxInvoice,
        }

        this.fetchFulfillmentsData = this.fetchFulfillmentsData.bind(this)
        this.fetchSalesData = this.fetchSalesData.bind(this)

        this.makeFulfillmentPacked = this.makeFulfillmentPacked.bind(this)

        this.keyDownListener = this.keyDownListener.bind(this)

        this.onEnter = this.onEnter.bind(this)
        this.onExit = this.onExit.bind(this)
        this.isValidOneByOneScan = this.isValidOneByOneScan.bind(this)
        this.isValidAllAtOnceScan = this.isValidAllAtOnceScan.bind(this)
        this.isValidScanToStart = this.isValidScanToStart.bind(this)
        this.nextOneByOne = this.nextOneByOne.bind(this)
        this.nextAllAtOnce = this.nextAllAtOnce.bind(this)

        this.handleNextOrderClick = this.handleNextOrderClick.bind(this)
        this.handleConfirmOrderClick = this.handleConfirmOrderClick.bind(this)
        this.handlePreviousOrderClick = this.handlePreviousOrderClick.bind(this)

        this.handleToggleSound = this.handleToggleSound.bind(this)
        this.handleNextFulfillment = this.handleNextFulfillment.bind(this)

        this.handleNextItemClick = this.handleNextItemClick.bind(this)
        this.handleConfirmItemClick = this.handleConfirmItemClick.bind(this)
        this.handlePreviousItemClick = this.handlePreviousItemClick.bind(this)

        this.handleOrderDisplayModeChange = this.handleOrderDisplayModeChange.bind(this)
        this.handleStartWithBarcodeChange = this.handleStartWithBarcodeChange.bind(this)

        this.hasPartiallyProcessedOrders = this.hasPartiallyProcessedOrders.bind(this)
        this.canChangeStartWithBarcode = this.canChangeStartWithBarcode.bind(this)

        this.scannedBarcodeOneByOne = this.scannedBarcodeOneByOne.bind(this)
        this.scannedBarcodeAllAtOnce = this.scannedBarcodeAllAtOnce.bind(this)
        this.scannedBarcodeToStart = this.scannedBarcodeToStart.bind(this)

        this.handleCheckDocumentOption = this.handleCheckDocumentOption.bind(this)
        this.getDocumentsURL = this.getDocumentsURL.bind(this)

        this.buffer = ''
    }

    componentDidMount() {
        const { staticRoot } = this.props

        this.scanSuccessSound = new Howl({ src: staticRoot + 'web/audio/Scanner - success.mp3' })
        this.scanErrorSound = new Howl({ src: staticRoot + 'web/audio/Scanner - error.mp3' })
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.pickPackBatch && Boolean(this.props.pickPackBatch)) {
            this.fetchFulfillmentsData()
        }
    }

    fetchFulfillmentsData() {
        const { pickPackBatch, pickPackBatchFulfillmentsAPIURL } = this.props

        let url

        if (this.state.next) {
            url = this.state.next
        } else {
            url = pickPackBatchFulfillmentsAPIURL.replace('pick_pack_batch_pk', pickPackBatch.id)
        }

        return fetch(url).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const fulfillments = copyObject(this.state.fulfillments)

                    data.results.forEach((fulfillment) => {
                        fulfillment.fulfillment_line_items.forEach((fli) => {
                            fli.numPacked = 0
                            fli.confirmed = false
                        })
                    })

                    const newFulfillments = fulfillments.concat(data.results)

                    const newState = {
                        totalSalesCount: data.count,
                        next: data.next,
                        fulfillments: newFulfillments,
                        page: this.state.startWithBarcode
                            ? PAGES.START_WITH_BARCODE
                            : PAGES.PROCESS_ORDER,
                    }

                    const saleIDs = newFulfillments.map((fulfillment) => fulfillment.sale)

                    this.fetchSalesData(saleIDs, () => {
                        this.setState(newState)
                    })
                })
            }
        })
    }

    fetchSalesData(saleIDs, callback) {
        const { salesFromPKsAPIURL } = this.props
        const queryString = saleIDs.map((saleID) => `pk=${saleID}`).join('&')
        const url = `${salesFromPKsAPIURL}?${queryString}`

        return fetch(url).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const salesByID = {}

                    data.results.forEach((sale) => {
                        salesByID[sale.id] = sale
                    })

                    this.setState({ salesByID }, callback)
                })
            }
        })
    }

    makeFulfillmentPacked(fulfillmentID) {
        const { fulfillmentAPIURL } = this.props
        const data = { status: 'waiting_for_3pl' }

        const fulfillments = copyObject(this.state.fulfillments)
        const fulfillment = fulfillments.find((f) => f.id === fulfillmentID)
        fulfillment.status = status

        this.setState({ fulfillments }, () => {
            fetch(fulfillmentAPIURL.replace('fulfillment_pk', fulfillmentID), {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRFToken': Cookie.get('csrftoken'),
                },
                body: JSON.stringify(data),
            }).then((response) => {
                if (!response.ok) {
                    console.error('Error making fulfillment packed')
                }
            })
        })
    }

    nextOneByOne() {
        const fulfillments = copyObject(this.state.fulfillments)
        const currentFulfillment = fulfillments[this.state.currentFulfillmentIndex]
        const currentItem = currentFulfillment.fulfillment_line_items[this.state.currentItemIndex]

        currentItem.numPacked += 1

        if (currentItem.quantity - currentItem.numPacked === 0) {
            currentItem.confirmed = true

            const allItemsInFulfillmentPacked = currentFulfillment.fulfillment_line_items.every(
                (fli) => fli.confirmed
            )

            if (allItemsInFulfillmentPacked) {
                currentFulfillment.confirmed = true
                this.makeFulfillmentPacked(currentFulfillment.id)
            }

            this.setState({ fulfillments, showOneByOneError: false }, () => {
                setTimeout(() => {
                    if (allItemsInFulfillmentPacked) {
                        this.setState({
                            page: PAGES.FULFILLMENT_COMPLETE,
                        })
                    } else {
                        this.setState({
                            currentItemIndex: this.state.currentItemIndex + 1,
                        })
                    }
                }, 1000)
            })
        } else if (currentItem.quantity - currentItem.numPacked > 0) {
            this.setState({ fulfillments, showOneByOneError: false })
        }
    }

    nextAllAtOnce(scannedValue) {
        const fulfillments = copyObject(this.state.fulfillments)
        const currentFulfillment = fulfillments[this.state.currentFulfillmentIndex]
        const scannedItem = currentFulfillment.fulfillment_line_items.find(
            (fli) => fli.stock_record && fli.stock_record.stock_item.barcode === scannedValue
        )

        scannedItem.numPacked += 1

        if (scannedItem.quantity - scannedItem.numPacked === 0) {
            scannedItem.confirmed = true

            const allItemsInFulfillmentPacked = currentFulfillment.fulfillment_line_items.every(
                (fli) => fli.confirmed
            )

            if (allItemsInFulfillmentPacked) {
                currentFulfillment.confirmed = true
                this.makeFulfillmentPacked(currentFulfillment.id)
            }

            this.setState(
                {
                    fulfillments,
                    showAllAtOnceError: false,
                    justScannedFulfillmentLineItemID: scannedItem.id,
                },
                () => {
                    setTimeout(() => {
                        if (allItemsInFulfillmentPacked) {
                            this.setState({
                                page: PAGES.FULFILLMENT_COMPLETE,
                            })
                        }
                    }, 1000)
                }
            )
        } else if (scannedItem.quantity - scannedItem.numPacked > 0) {
            this.setState({
                fulfillments,
                showAllAtOnceError: false,
                justScannedFulfillmentLineItemID: scannedItem.id,
            })
        }
    }

    handleOrderDisplayModeChange(value) {
        this.setState({ orderDisplayOption: value }, () => {
            localStorage.setItem('orderDisplayMode', value.value)
        })
    }

    canChangeStartWithBarcode() {
        const allowedPage = [PAGES.START_WITH_BARCODE, PAGES.PROCESS_ORDER].includes(
            this.state.page
        )
        return allowedPage && !this.hasPartiallyProcessedOrders()
    }

    handleStartWithBarcodeChange(event) {
        const startWithBarcode = event.target.checked
        const startWithBarcodeString = startWithBarcode ? 'true' : 'false'
        localStorage.setItem('startWithBarcode', startWithBarcodeString)

        const newState = {
            startWithBarcode: startWithBarcode,
        }

        if (startWithBarcode) {
            newState.page = PAGES.START_WITH_BARCODE
        } else {
            newState.page = PAGES.PROCESS_ORDER
        }

        this.setState(newState)
    }

    handleNextOrderClick(event) {
        event.currentTarget.blur()

        const previousSaleIndex = this.state.currentFulfillmentIndex + 1

        this.setState({
            currentFulfillmentIndex: previousSaleIndex,
            currentItemIndex: 0,
            showOneByOneError: false,
        })
    }

    handleConfirmOrderClick(event) {
        event.currentTarget.blur()

        const fulfillments = copyObject(this.state.fulfillments)
        const currentFulfillment = fulfillments[this.state.currentFulfillmentIndex]

        let nextOrderWaitTime

        currentFulfillment.fulfillment_line_items.forEach((fli) => {
            fli.numPacked = fli.quantity
            fli.confirmed = true
        })

        if (currentFulfillment.confirmed) {
            nextOrderWaitTime = 0
        } else {
            currentFulfillment.confirmed = true
            nextOrderWaitTime = 1000
            this.makeFulfillmentPacked(currentFulfillment.id)
        }

        this.setState({ fulfillments, showOneByOneError: false }, () => {
            setTimeout(() => {
                this.setState({
                    page: PAGES.FULFILLMENT_COMPLETE,
                })
            }, nextOrderWaitTime)
        })
    }

    handlePreviousOrderClick(event) {
        event.currentTarget.blur()

        const previousFulfillmentIndex = this.state.currentFulfillmentIndex - 1

        this.setState({
            showOneByOneError: false,
            currentFulfillmentIndex: previousFulfillmentIndex,
        })
    }

    scannedBarcodeOneByOne() {
        const currentFulfillment = this.state.fulfillments[this.state.currentFulfillmentIndex]
        const currentItem = currentFulfillment.fulfillment_line_items[this.state.currentItemIndex]

        if (currentItem.confirmed) {
            this.scanErrorSound.play()
            this.buffer = ''
            return
        }

        if (this.isValidOneByOneScan(this.buffer)) {
            if (this.state.soundOn) {
                this.scanSuccessSound.play()
            }

            this.nextOneByOne()
        } else {
            if (this.state.soundOn) {
                this.scanErrorSound.play()
            }

            this.setState({ showOneByOneError: true })
        }
    }

    scannedBarcodeAllAtOnce() {
        if (this.isValidAllAtOnceScan(this.buffer)) {
            if (this.state.soundOn) {
                this.scanSuccessSound.play()
            }

            this.nextAllAtOnce(this.buffer)
        } else {
            if (this.state.soundOn) {
                this.scanErrorSound.play()
            }

            this.setState({ showAllAtOnceError: true })
        }
    }

    scannedBarcodeToStart() {
        if (this.isValidScanToStart(this.buffer)) {
            if (this.state.soundOn) {
                this.scanSuccessSound.play()
            }

            const fulfillments = copyObject(this.state.fulfillments)
            const fulfillmentWithItem = fulfillments.find(
                (fulfillment) =>
                    !fulfillment.confirmed &&
                    fulfillment.fulfillment_line_items.some(
                        (fli) =>
                            fli.stock_record && fli.stock_record.stock_item.barcode === this.buffer
                    )
            )

            const scannedItem = fulfillmentWithItem.fulfillment_line_items.find(
                (fli) => fli.stock_record && fli.stock_record.stock_item.barcode === this.buffer
            )
            const fulfillmentItemsWithoutScannedItem =
                fulfillmentWithItem.fulfillment_line_items.filter(
                    (fli) => fli.id !== scannedItem.id
                )
            const reorderedItems = [scannedItem].concat(fulfillmentItemsWithoutScannedItem)

            fulfillmentWithItem.fulfillment_line_items = reorderedItems

            const confirmedFulfillments = fulfillments.filter(
                (fulfillment) => fulfillment.confirmed
            )
            const otherFulfillments = fulfillments.filter(
                (fulfillment) => !fulfillment.confirmed && fulfillment.id !== fulfillmentWithItem.id
            )

            const reorderedFulfillments = confirmedFulfillments
                .concat([fulfillmentWithItem])
                .concat(otherFulfillments)
            const fulfillmentWithItemIndex = reorderedFulfillments.findIndex(
                (fulfillment) => fulfillment.id === fulfillmentWithItem.id
            )

            this.setState(
                {
                    fulfillments: reorderedFulfillments,
                    currentFulfillmentIndex: fulfillmentWithItemIndex,
                    currentItemIndex: 0,
                    page: PAGES.PROCESS_ORDER,
                    showStartWithBarcodeError: false,
                },
                () => {
                    if (this.state.orderDisplayOption.value === ORDER_DISPLAY_MODES.ONE_BY_ONE) {
                        this.nextOneByOne()
                    } else if (
                        this.state.orderDisplayOption.value === ORDER_DISPLAY_MODES.ALL_AT_ONCE
                    ) {
                        this.nextAllAtOnce(this.buffer)
                    }
                }
            )
        } else {
            if (this.state.soundOn) {
                this.scanErrorSound.play()
            }

            this.setState({
                showStartWithBarcodeError: true,
            })
        }
    }

    keyDownListener(event) {
        if (isValidBarcodeCharacter(event.key)) {
            this.buffer += event.key
        }

        if (event.key === 'Enter') {
            if (this.state.page === PAGES.PROCESS_ORDER) {
                if (this.state.orderDisplayOption.value === ORDER_DISPLAY_MODES.ONE_BY_ONE) {
                    this.scannedBarcodeOneByOne()
                } else if (
                    this.state.orderDisplayOption.value === ORDER_DISPLAY_MODES.ALL_AT_ONCE
                ) {
                    this.scannedBarcodeAllAtOnce()
                }
            } else if (this.state.page === PAGES.START_WITH_BARCODE) {
                this.scannedBarcodeToStart()
            }

            this.buffer = ''
        }
    }

    onEnter() {
        document.addEventListener('keydown', this.keyDownListener)
    }

    onExit() {
        document.removeEventListener('keydown', this.keyDownListener)
        this.buffer = ''

        if (this.state.page === PAGES.ALL_FULFILLMENTS_COMPLETE) {
            const resetFulfillments = copyObject(this.state.fulfillments)
            resetFulfillments.forEach((fulfillment) => {
                fulfillment.confirmed = false
                fulfillment.fulfillment_line_items.forEach((fli) => {
                    fli.confirmed = false
                    fli.numPacked = 0
                })
            })

            this.setState({
                page: this.startWithBarcode ? PAGES.START_WITH_BARCODE : PAGES.PROCESS_ORDER,
                fulfillments: resetFulfillments,
                currentFulfillmentIndex: 0,
                currentItemIndex: 0,
            })
        }
    }

    isValidOneByOneScan(scannedValue) {
        const currentFulfillment = this.state.fulfillments[this.state.currentFulfillmentIndex]
        const currentItem = currentFulfillment.fulfillment_line_items[this.state.currentItemIndex]

        return (
            scannedValue ===
            (currentItem.stock_record && currentItem.stock_record.stock_item.barcode)
        )
    }

    isValidAllAtOnceScan(scannedValue) {
        const currentFulfillment = this.state.fulfillments[this.state.currentFulfillmentIndex]
        let foundItem = false

        currentFulfillment.fulfillment_line_items.forEach((fli) => {
            if (fli.stock_record && fli.stock_record.stock_item.barcode === scannedValue) {
                foundItem = true
            }
        })

        return foundItem
    }

    isValidScanToStart(scannedValue) {
        return this.state.fulfillments.some(
            (fulfillment) =>
                !fulfillment.confirmed &&
                fulfillment.fulfillment_line_items.some(
                    (fli) =>
                        fli.stock_record && fli.stock_record.stock_item.barcode === scannedValue
                )
        )
    }

    handleToggleSound(event) {
        this.setState({ soundOn: event.target.checked })
    }

    handleNextFulfillment(event) {
        const { handleClose } = this.props

        event.currentTarget.blur()
        const allSalesPacked = this.state.fulfillments.every((fulfillment) => fulfillment.confirmed)

        if (allSalesPacked) {
            this.setState(
                {
                    page: PAGES.ALL_FULFILLMENTS_COMPLETE,
                },
                () => {
                    setTimeout(() => {
                        handleClose()
                    }, 3000)
                }
            )
        } else {
            const nextFulfillmentIndex = this.state.currentFulfillmentIndex + 1

            if (nextFulfillmentIndex >= this.state.fulfillments.length - 2 && this.state.next) {
                this.fetchFulfillmentsData()
            }

            this.setState({
                currentFulfillmentIndex: nextFulfillmentIndex,
                currentItemIndex: 0,
                page: this.state.startWithBarcode ? PAGES.START_WITH_BARCODE : PAGES.PROCESS_ORDER,
            })
        }
    }

    handleNextItemClick(event) {
        event.currentTarget.blur()

        this.setState({
            currentItemIndex: this.state.currentItemIndex + 1,
            showOneByOneError: false,
        })
    }

    handleConfirmItemClick(event) {
        event.currentTarget.blur()

        const fulfillments = copyObject(this.state.fulfillments)
        const currentFulfillment = fulfillments[this.state.currentFulfillmentIndex]
        const currentItem = currentFulfillment.fulfillment_line_items[this.state.currentItemIndex]

        let nextItemWaitTime

        currentItem.numPacked = currentItem.quantity

        if (currentItem.confirmed) {
            nextItemWaitTime = 0
        } else {
            currentItem.confirmed = true
            nextItemWaitTime = 1000
        }

        const allItemsInFulfillmentPacked = currentFulfillment.fulfillment_line_items.every(
            (fli) => fli.confirmed
        )

        if (allItemsInFulfillmentPacked) {
            currentFulfillment.confirmed = true
            this.makeFulfillmentPacked(currentFulfillment.id)
        }

        this.setState({ fulfillments, showOneByOneError: false }, () => {
            setTimeout(() => {
                if (allItemsInFulfillmentPacked) {
                    this.setState({
                        page: PAGES.FULFILLMENT_COMPLETE,
                    })
                } else {
                    this.setState({
                        currentItemIndex: this.state.currentItemIndex + 1,
                    })
                }
            }, nextItemWaitTime)
        })
    }

    handlePreviousItemClick(event) {
        event.currentTarget.blur()

        this.setState({
            showOneByOneError: false,
            currentItemIndex: this.state.currentItemIndex - 1,
        })
    }

    hasPartiallyProcessedOrders() {
        return this.state.fulfillments.some((fulfillment) => {
            return fulfillment.fulfillment_line_items.some(
                (fli) =>
                    (fli.numPacked > 0 && !fli.confirmed) ||
                    (fli.confirmed && !fulfillment.confirmed)
            )
        })
    }

    handleCheckDocumentOption(e) {
        const option = e.target.value

        this.setState({ [option]: !this.state[option] })
    }

    getDocumentsURL() {
        const { fulfillmentDocumentsURL } = this.props

        const params = {
            should_get_shipping_label: this.state.getShippingLabel,
            should_get_pack_list: this.state.getPackList,
            should_get_tax_invoice: this.state.getTaxInvoice,
        }

        const queryString = Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')

        const fulfillment = this.state.fulfillments[this.state.currentFulfillmentIndex]

        return fulfillmentDocumentsURL.replace('fulfillment_pk', fulfillment.id) + '?' + queryString
    }

    render() {
        const { classes, theme, language, open, handleClose, staticRoot } = this.props

        let fulfillment, sale, fulfillmentLineItem, saleLineItem, sliInfo
        if (this.state.fulfillments.length > 0 && this.state.salesByID) {
            fulfillment = this.state.fulfillments[this.state.currentFulfillmentIndex]
            sale = this.state.salesByID[fulfillment.sale]
            fulfillmentLineItem =
                fulfillment.fulfillment_line_items.length > 0
                    ? fulfillment.fulfillment_line_items[this.state.currentItemIndex]
                    : null
            saleLineItem = sale.sale_line_items.find(
                (sli) => sli.id === fulfillmentLineItem.sale_line_item
            )
            sliInfo = extractSaleLineItemInfo(saleLineItem, language)
        } else {
            fulfillment = null
            sale = null
            fulfillmentLineItem = null
            saleLineItem = null
        }

        return (
            <Dialog
                classes={{ paper: classes.dialog }}
                open={open}
                maxWidth="xl"
                fullWidth={true}
                TransitionProps={{ onEnter: this.onEnter(), onExit: this.onExit }}
            >
                <DialogTitle className={classes.dialogTitle} disableTypography={true}>
                    <Typography variant="h6">
                        {sale ? interpolate(gettext('Order %s'), [sale.sale_id]) : gettext('Order')}
                    </Typography>
                    {this.state.showAllAtOnceError && (
                        <div className={classes.allAtOnceError}>
                            <WarningIcon className={classes.allAtOnceErrorIcon} color="error" />
                            <Typography className={classes.allAtOnceErrorText} variant="h5">
                                {gettext('That item was not found in this order')}
                            </Typography>
                        </div>
                    )}
                    <div className={classes.titleSpacer} />
                    <Typography className={classes.startWithBarcodeText} variant="body2">
                        {gettext('Start with barcode')}
                    </Typography>
                    <Switch
                        className={classes.startWithBarcodeSwitch}
                        checked={this.state.startWithBarcode}
                        onChange={this.handleStartWithBarcodeChange}
                        disabled={!this.canChangeStartWithBarcode()}
                    />
                    <Typography className={classes.showOrdersText} variant="body2">
                        {gettext('Show orders')}
                    </Typography>
                    <Select
                        className={classes.modeSelect}
                        isClearable={false}
                        options={ORDER_DISPLAY_OPTIONS}
                        value={this.state.orderDisplayOption}
                        onChange={this.handleOrderDisplayModeChange}
                        isDisabled={this.hasPartiallyProcessedOrders()}
                        theme={(reactSelectTheme) => ({
                            ...reactSelectTheme,
                            colors: {
                                ...reactSelectTheme.colors,
                                primary: theme.palette.secondary.main,
                            },
                        })}
                    />
                </DialogTitle>
                {this.state.page === PAGES.LOADING && (
                    <DialogContent className={classes.loadingDialogContent}>
                        <CircularProgress
                            className={classes.initialLoadingSpinner}
                            variant="indeterminate"
                            size={60}
                            color="secondary"
                        />
                    </DialogContent>
                )}

                {this.state.page === PAGES.ALL_FULFILLMENTS_COMPLETE && (
                    <DialogContent className={classes.loadingDialogContent}>
                        <div className={classes.allConfirmed}>
                            <CheckCircleIcon className={classes.allConfirmedIcon} color="primary" />
                            <Typography variant="h5" className={classes.allConfirmedIconText}>
                                {gettext('Packed all orders in pick list')}
                            </Typography>
                        </div>
                    </DialogContent>
                )}

                {this.state.page === PAGES.FULFILLMENT_COMPLETE && (
                    <DialogContent className={classes.packedSaleContent}>
                        <div className={classes.packedSaleContainer}>
                            <div className={classes.allConfirmed}>
                                <CheckCircleIcon
                                    className={classes.allConfirmedIcon}
                                    color="primary"
                                />
                                <div className={classes.allConfirmedTextContainer}></div>
                                <Typography variant="h5" className={classes.allConfirmedIconText}>
                                    {gettext('Packed all items in this order')}
                                </Typography>
                            </div>
                            {Boolean(sale) && (
                                <div className={classes.saleInformation}>
                                    <img
                                        className={classes.channelIcon}
                                        src={sale.shop.channel.channel_icon_url}
                                    />
                                    <Typography variant="h5" className={classes.saleInfoSaleID}>
                                        {interpolate(gettext('Order %s'), [sale.sale_id])}
                                    </Typography>
                                    <div className={classes.address}>
                                        <Typography variant="h5" className={classes.addressText}>
                                            {sale.address1}
                                        </Typography>
                                        <Typography variant="h5" className={classes.addressText}>
                                            {sale.address2}
                                        </Typography>
                                        <Typography variant="h5" className={classes.addressText}>
                                            {sale.address3}
                                        </Typography>
                                        <Typography variant="h5" className={classes.addressText}>
                                            {sale.address4}
                                        </Typography>
                                        <Typography variant="h5" className={classes.addressText}>
                                            {sale.address5}
                                        </Typography>
                                        <Typography variant="h5" className={classes.addressText}>
                                            {sale.city}
                                        </Typography>
                                        <Typography variant="h5" className={classes.addressText}>
                                            {sale.province}
                                        </Typography>
                                        <Typography variant="h5" className={classes.addressText}>
                                            {sale.post_code}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                            <Button
                                className={classes.nextSaleButton}
                                color="primary"
                                variant="contained"
                                onClick={this.handleNextFulfillment}
                                size="large"
                                disableFocusRipple
                                disableRipple
                            >
                                {gettext('Continue')}
                            </Button>
                            <div className={classes.documentsSection}>
                                <FormControl
                                    component="fieldset"
                                    className={classes.documentOptionsFormControl}
                                >
                                    <FormGroup className={classes.documentOptionsGroup}>
                                        <FormControlLabel
                                            classes={{ label: classes.documentOptionLabel }}
                                            control={
                                                <Checkbox
                                                    checked={this.state.getShippingLabel}
                                                    onChange={this.handleCheckDocumentOption}
                                                    value="getShippingLabel"
                                                    disabled={
                                                        ![
                                                            'waiting_for_shipping_confirmation',
                                                            'waiting_for_picking',
                                                            'picking',
                                                            'packing',
                                                            'waiting_for_3pl_pickup',
                                                            'waiting_for_3pl_dropoff',
                                                        ].includes(fulfillment.status)
                                                    }
                                                />
                                            }
                                            label={gettext('Shipping labels')}
                                        />
                                        <FormControlLabel
                                            classes={{ label: classes.documentOptionLabel }}
                                            control={
                                                <Checkbox
                                                    checked={this.state.getPackList}
                                                    onChange={this.handleCheckDocumentOption}
                                                    value="getPackList"
                                                />
                                            }
                                            label={gettext('Pack list')}
                                        />
                                        <FormControlLabel
                                            classes={{ label: classes.documentOptionLabel }}
                                            control={
                                                <Checkbox
                                                    checked={this.state.getTaxInvoice}
                                                    onChange={this.handleCheckDocumentOption}
                                                    value="getTaxInvoice"
                                                />
                                            }
                                            label={gettext('Tax invoice')}
                                        />
                                    </FormGroup>
                                </FormControl>
                                <Button
                                    className={classes.getDocumentsButton}
                                    color="primary"
                                    variant="outlined"
                                    href={this.getDocumentsURL()}
                                    target="_blank"
                                    size="large"
                                    disabled={
                                        !this.state.getShippingLabel &&
                                        !this.state.getPackList &&
                                        !this.state.getTaxInvoice
                                    }
                                    disableFocusRipple
                                    disableRipple
                                >
                                    {gettext('Print documents')}
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                )}

                {this.state.page === PAGES.PROCESS_ORDER &&
                    this.state.orderDisplayOption.value === ORDER_DISPLAY_MODES.ONE_BY_ONE && (
                        <DialogContent className={classes.dialogContent}>
                            {fulfillmentLineItem && (
                                <div className={classes.stockUnitInfo}>
                                    <img className={classes.coverImage} src={sliInfo.image} />
                                    <div className={classes.rightPanel}>
                                        <OrderProgress
                                            classes={classes}
                                            fulfillment={fulfillment}
                                        />
                                        <Typography variant="h5" className={classes.productName}>
                                            {sliInfo.productName}
                                        </Typography>
                                        <div className={classes.stockUnitSection}>
                                            <div className={classes.stockUnitSubSection}>
                                                <Typography
                                                    className={classes.stockUnitSubSectionTitle}
                                                    variant="h5"
                                                >
                                                    {gettext('SKU')}
                                                </Typography>
                                                <Typography
                                                    className={classes.stockUnitSubSectionText}
                                                    variant="h5"
                                                >
                                                    {sliInfo.sku}
                                                </Typography>
                                            </div>
                                            <div className={classes.stockUnitSubSection}>
                                                <Typography
                                                    className={classes.stockUnitSubSectionTitle}
                                                    variant="h5"
                                                >
                                                    {gettext('SKU name')}
                                                </Typography>
                                                <Typography
                                                    className={classes.stockUnitSubSectionText}
                                                    variant="h5"
                                                >
                                                    {sliInfo.skuName}
                                                </Typography>
                                            </div>
                                            <div className={classes.stockUnitSubSection}>
                                                <Typography
                                                    className={classes.stockUnitSubSectionTitle}
                                                    variant="h5"
                                                >
                                                    {gettext('Barcode')}
                                                </Typography>
                                                <Typography
                                                    className={classes.stockUnitSubSectionText}
                                                    variant="h5"
                                                >
                                                    {fulfillmentLineItem.stock_record?.stock_item
                                                        ?.barcode || gettext('Not set')}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={classes.row}>
                                            <div className={classes.unitsStillNeeded}>
                                                <Typography
                                                    className={classes.unitsStillNeededTitle}
                                                    variant="h5"
                                                >
                                                    {gettext('Units still needed')}
                                                </Typography>
                                                {fulfillmentLineItem.confirmed ? (
                                                    <CheckCircleIcon
                                                        className={classes.confirmIcon}
                                                        color="primary"
                                                    />
                                                ) : (
                                                    <Typography
                                                        className={classes.unitsStillNeededText}
                                                        variant="h3"
                                                    >
                                                        {fulfillmentLineItem.quantity -
                                                            fulfillmentLineItem.numPacked}
                                                    </Typography>
                                                )}
                                            </div>

                                            <div className={classes.unitsRequired}>
                                                <Typography
                                                    className={classes.unitsRequiredTitle}
                                                    variant="h5"
                                                >
                                                    {gettext('Units required')}
                                                </Typography>
                                                <Typography
                                                    className={classes.unitsRequiredText}
                                                    variant="h3"
                                                >
                                                    {fulfillmentLineItem.quantity}
                                                </Typography>
                                            </div>

                                            {this.state.showOneByOneError && (
                                                <div className={classes.scannedMessage}>
                                                    <WarningIcon
                                                        className={classes.scanIcon}
                                                        color="error"
                                                    />
                                                    <Typography
                                                        className={classes.scanErrorText}
                                                        variant="h5"
                                                    >
                                                        {gettext(
                                                            'Does not match barcode for this product'
                                                        )}
                                                    </Typography>
                                                </div>
                                            )}
                                        </div>
                                        {!fulfillmentLineItem.confirmed && (
                                            <Typography variant="h5" className={classes.prompt}>
                                                {gettext(
                                                    'Scan product or press Confirm Item and Continue'
                                                )}
                                            </Typography>
                                        )}
                                        <div className={classes.verticalSpacer} />
                                        <div className={classes.itemControls}>
                                            <Button
                                                className={classes.previousItemButton}
                                                color="secondary"
                                                variant="outlined"
                                                disabled={this.state.currentItemIndex === 0}
                                                onClick={this.handlePreviousItemClick}
                                                disableFocusRipple
                                                disableRipple
                                            >
                                                {gettext('Previous item')}
                                            </Button>
                                            {fulfillmentLineItem.confirmed ? (
                                                <Button
                                                    className={classes.nextItemButton}
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={this.handleNextItemClick}
                                                    disabled={
                                                        this.state.currentItemIndex >=
                                                        fulfillment.fulfillment_line_items.length -
                                                            1
                                                    }
                                                    disableFocusRipple
                                                    disableRipple
                                                >
                                                    {gettext('Next item')}
                                                </Button>
                                            ) : (
                                                <Button
                                                    className={classes.nextItemButton}
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={this.handleConfirmItemClick}
                                                    disableFocusRipple
                                                    disableRipple
                                                >
                                                    {gettext('Confirm item and continue')}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </DialogContent>
                    )}

                {this.state.page === PAGES.PROCESS_ORDER &&
                    this.state.orderDisplayOption.value === ORDER_DISPLAY_MODES.ALL_AT_ONCE && (
                        <DialogContent className={classes.dialogContent}>
                            <div className={classes.allAtOnceContainer}>
                                <OrderProgress classes={classes} fulfillment={fulfillment} />
                                <div className={classes.fulfillmentLineItems}>
                                    {fulfillment.fulfillment_line_items.map((fli) => {
                                        const saleLineItem = sale.sale_line_items.find(
                                            (sli) => sli.id === fli.sale_line_item
                                        )
                                        const sliInfo = extractSaleLineItemInfo(
                                            saleLineItem,
                                            language
                                        )

                                        return (
                                            <div
                                                className={classNames(classes.fulfillmentLineItem, {
                                                    [classes.fulfillmentLineItemHighlighted]:
                                                        this.state
                                                            .justScannedFulfillmentLineItemID ===
                                                        fli.id,
                                                })}
                                                key={fli.id}
                                            >
                                                <img
                                                    className={classes.coverImageAllAtOnce}
                                                    src={
                                                        saleLineItem?.stock_unit?.product
                                                            ?.cover_image_url || saleLineItem.image
                                                    }
                                                />
                                                <div className={classes.allAtOnceRightSection}>
                                                    <Typography
                                                        className={classes.productNameAllAtOnce}
                                                    >
                                                        {truncate(sliInfo.productName, 100)}
                                                    </Typography>
                                                    <div
                                                        className={
                                                            classes.stockUnitSectionAllAtOnce
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.stockUnitSubSectionAllAtOnce
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.stockUnitSubSectionTitleAllAtOnce
                                                                }
                                                                variant="h5"
                                                            >
                                                                {gettext('SKU')}
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.stockUnitSubSectionTextAllAtOnce
                                                                }
                                                                variant="h5"
                                                            >
                                                                {sliInfo.sku}
                                                            </Typography>
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.stockUnitSubSectionAllAtOnce
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.stockUnitSubSectionTitleAllAtOnce
                                                                }
                                                                variant="h5"
                                                            >
                                                                {gettext('SKU name')}
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.stockUnitSubSectionTextAllAtOnce
                                                                }
                                                                variant="h5"
                                                            >
                                                                {sliInfo.skuName}
                                                            </Typography>
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.stockUnitSubSectionAllAtOnce
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.stockUnitSubSectionTitleAllAtOnce
                                                                }
                                                                variant="h5"
                                                            >
                                                                {gettext('Barcode')}
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.stockUnitSubSectionTextAllAtOnce
                                                                }
                                                                variant="h5"
                                                            >
                                                                {sliInfo.barcode}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={classes.progressSectionAllAtOnce}
                                                    >
                                                        <div className={classes.sliProgressMeter}>
                                                            {range(0, fli.quantity).map((index) => (
                                                                <div
                                                                    className={classNames(
                                                                        classes.sliProgressMeterSegment,
                                                                        {
                                                                            [classes.sliProgressMeterSegmentActive]:
                                                                                index <
                                                                                fli.numPacked,
                                                                        }
                                                                    )}
                                                                    key={index}
                                                                />
                                                            ))}
                                                        </div>
                                                        <Typography
                                                            className={classes.sliUnitsNeeded}
                                                        >
                                                            {interpolate(gettext('%s of %s'), [
                                                                fli.numPacked,
                                                                fli.quantity,
                                                            ])}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </DialogContent>
                    )}

                {this.state.page === PAGES.START_WITH_BARCODE && (
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.startWithBarcodeContainer}>
                            <img
                                className={classes.scanBarcodeIcon}
                                src={staticRoot + 'web/img/barcode.svg'}
                            />
                            <Typography className={classes.scanBarcodeText}>
                                {gettext('Scan a barcode')}
                            </Typography>
                            <Typography className={classes.scanBarcodeSubtext}>
                                {gettext(
                                    'Scan any item to begin packing an order that contains the item'
                                )}
                            </Typography>
                            {this.state.showStartWithBarcodeError && (
                                <div className={classes.startWithBarcodeError}>
                                    <WarningIcon
                                        className={classes.startWithBarcodeErrorIcon}
                                        color="error"
                                    />
                                    <Typography
                                        className={classes.startWithBarcodeErrorText}
                                        variant="h5"
                                    >
                                        {gettext('That item was not found in any order')}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </DialogContent>
                )}

                <DialogActions className={classes.dialogActions}>
                    <Button
                        className={classes.actionButton}
                        color="secondary"
                        onClick={handleClose}
                        size="large"
                        disableFocusRipple
                        disableRipple
                    >
                        {gettext('Close')}
                    </Button>
                    <div className={classes.soundSwitchContainer}>
                        <Switch
                            checked={this.state.soundOn}
                            onChange={this.handleToggleSound}
                            color="primary"
                        />
                        <Typography variant="subtitle1" className={classes.soundSwitchText}>
                            {gettext('Sound')}
                        </Typography>
                    </div>
                    <div className={classes.spacer} />
                    <Typography variant="subtitle1" className={classes.counterText}>
                        {interpolate(gettext('%s of %s orders'), [
                            this.state.currentFulfillmentIndex + 1,
                            this.state.fulfillments.length,
                        ])}
                    </Typography>
                    <Button
                        className={classes.actionButton}
                        color="primary"
                        variant="outlined"
                        onClick={this.handlePreviousOrderClick}
                        disabled={
                            [
                                PAGES.LOADING,
                                PAGES.FULFILLMENT_COMPLETE,
                                PAGES.ALL_FULFILLMENTS_COMPLETE,
                            ].includes(this.state.page) || this.state.currentFulfillmentIndex === 0
                        }
                        size="large"
                        disableFocusRipple
                        disableRipple
                    >
                        {gettext('Previous order')}
                    </Button>
                    {Boolean(fulfillment) && Boolean(fulfillment.confirmed) ? (
                        <Button
                            className={classNames(classes.actionButton, classes.nextButton)}
                            color="primary"
                            variant="contained"
                            onClick={this.handleNextOrderClick}
                            disabled={[
                                PAGES.LOADING,
                                PAGES.FULFILLMENT_COMPLETE,
                                PAGES.ALL_FULFILLMENTS_COMPLETE,
                            ].includes(this.state.page)}
                            size="large"
                            disableFocusRipple
                            disableRipple
                        >
                            {gettext('Next order')}
                        </Button>
                    ) : (
                        <Button
                            className={classNames(classes.actionButton, classes.nextButton)}
                            color="primary"
                            variant="contained"
                            onClick={this.handleConfirmOrderClick}
                            disabled={[
                                PAGES.LOADING,
                                PAGES.FULFILLMENT_COMPLETE,
                                PAGES.ALL_FULFILLMENTS_COMPLETE,
                            ].includes(this.state.page)}
                            size="large"
                            disableFocusRipple
                            disableRipple
                        >
                            {gettext('Confirm order and continue')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        )
    }
}

export default withTheme(withStyles(styles)(OrderProcessor))
