import { useCallback, useMemo } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'

import {
    DndContext,
    KeyboardSensor,
    PointerSensor,
    closestCenter,
    useSensor,
    useSensors,
    type DragOverEvent,
} from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { FormField } from '../../../common/components'

import ProductDimensionOption from './product-dimension-option'

import { NormalizedDimension } from '~/products/components/product-dimensions/schemas'
import { useProductFormContext } from '~/products/hooks/useProductForm'
import { getUUID } from '~/utils/uuid'

type Props = {
    isNewProduct: boolean
    index?: number
    defaultValues?: {
        id: string | number
        name: string
        index: number
        options: { id: string | number; name: string }[]
    }
    existingDimensions: { name: string }[]
    onSubmit: (data: NormalizedDimension) => void
    onRemove: (index: number) => void
}

export default function ProductDimensionOptionsForm({
    isNewProduct,
    index = -1,
    defaultValues = {
        id: getUUID(),
        name: '',
        index: 0,
        options: [{ id: getUUID(), name: '' }],
    },
    existingDimensions,
    onSubmit,
    onRemove,
}: Props) {
    const { control: productFormControl } = useProductFormContext()
    const channelProducts = useWatch({
        control: productFormControl,
        name: 'channel_products',
        exact: true,
    })

    const { control, setFocus, watch, handleSubmit } = useForm({ mode: 'onBlur', defaultValues })
    const { fields, append, remove, replace } = useFieldArray({
        control: control,
        keyName: 'optionId',
        name: `options`,
    })
    const options = watch('options')

    const handleAddDimensionOption = useCallback(
        () => append({ id: getUUID(), name: '' }, { shouldFocus: false }),
        [append]
    )

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const items = useMemo(() => fields.map((field) => field.optionId), [fields])

    function handleDragEnd({ active, over }: DragOverEvent) {
        if (!over || active.id === over.id) {
            return
        }

        const [oldIndex, newIndex] = [
            fields.findIndex((f) => f.optionId === active.id),
            fields.findIndex((f) => f.optionId === over.id),
        ]

        // Disallow dragging to add new option input field
        if (newIndex === fields.length - 1) {
            return
        }

        replace(arrayMove(fields, oldIndex, newIndex))
    }

    return (
        <form
            onSubmit={handleSubmit((data) => {
                const options = data.options
                    .filter((option) => option.name.trim().length > 0)
                    .map(({ id, name }, index) => ({ id, name, index }))

                onSubmit(NormalizedDimension.parse({ ...data, options }))
            })}
        >
            <Box ml={4.5}>
                <Typography>{gettext('Dimension')}</Typography>

                <Box display="flex" alignItems="center">
                    <FormField
                        control={control}
                        name="name"
                        disabled={!isNewProduct}
                        rules={{
                            required: gettext('Dimension name is required'),
                            validate: {
                                unique: (value) => {
                                    if (typeof value !== 'string') {
                                        return
                                    }

                                    return existingDimensions.some(
                                        ({ name }) =>
                                            name.trim().toLowerCase() === value.trim().toLowerCase()
                                    )
                                        ? gettext('Dimension name already exists')
                                        : undefined
                                },
                            },
                        }}
                    />

                    {isNewProduct && index >= 0 && (
                        <Box ml={1}>
                            <Tooltip title="Remove dimension">
                                <IconButton
                                    aria-label="remove product dimension"
                                    tabIndex={-1}
                                    size="small"
                                    onClick={() => onRemove(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                </Box>

                <Box mt={2}>
                    <Typography>{gettext('Options')}</Typography>
                </Box>

                <DndContext
                    modifiers={[restrictToWindowEdges, restrictToVerticalAxis]}
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <Box component="ul" ml={-4.5} p={0} style={{ listStyle: 'none' }}>
                        <SortableContext items={items} strategy={verticalListSortingStrategy}>
                            {fields.map(({ optionId }, index) => (
                                <ProductDimensionOption
                                    key={optionId}
                                    id={optionId}
                                    control={control}
                                    channelProducts={channelProducts}
                                    options={options}
                                    currentIndex={index}
                                    isFirst={index === 0}
                                    isLast={index === fields.length - 1}
                                    onRemove={remove}
                                    onAppend={handleAddDimensionOption}
                                    onFocus={setFocus}
                                />
                            ))}
                        </SortableContext>
                    </Box>
                </DndContext>

                <Box mt={1}>
                    <Button type="submit">{gettext('Done')}</Button>
                </Box>
            </Box>
        </form>
    )
}
