import { createAxiosInstance } from '../utils/apiClient'

const groupApi = createAxiosInstance('groups')

/**
 *
 * @param {number} accountOwnerId Account owner ID to create group
 * @param {string} groupName
 * @returns
 */
export const createGroup = async (accountOwnerId, groupName) => {
    const { data: newGroup } = await groupApi.post(`/create/`, {
        owner: accountOwnerId,
        name: groupName,
    })

    return newGroup
}

/**
 *
 * @param {number} groupId Group ID to update
 * @param {Record<string, any>} data Data to update
 * @returns
 */
export const updateGroupById = async (groupId, data) => {
    const { data: updatedGroup } = await groupApi.patch(`/${groupId}/`, data)

    return updatedGroup
}

/**
 *
 * @param {number} groupId Group ID to delete
 * @returns
 */
export const deleteGroupById = async (groupId) => {
    const { data } = await groupApi.delete(`/${groupId}`)

    return data
}

/**
 *
 * @param {number} groupId Group ID to get permissions from
 * @param {Array<Record<string, any>>} permissionCodeNames Permissions to update
 * @returns
 */
export const updateGroupPermissions = async (groupId, permissionCodeNames) => {
    const { data } = await groupApi.post(`/${groupId}/permissions/`, [...permissionCodeNames])

    return data
}
