import * as fb from './facebook'
import * as jd from './jd'
import * as lazada from './lazada'
import * as magneto from './magneto'
import * as shopee from './shopee'
import * as shopify from './shopify'
import * as tiktok from './tiktok'
import * as wooCommerce from './woo-commerce'

type FieldInfo = {
    name: {
        minLength: number
        maxLength: number
    }
    description: {
        minLength: number
        maxLength: number
    }
    maxImages?: number
    maxImagesLevel?: string
    short_description?: {
        maxLength: number
    }
    optionName?: {
        maxLength: number
    }
}

export const LANGUAGE_LABEL = {
    th: 'ไทย',
    en: 'English',
    vi: 'Tiếng Việt',
} as const

export const CHANNEL_ID = {
    Lazada: 1,
    LazadaVN: 12,
    Shopee: 2,
    JDCentral: 4,
    Shopify: 5,
    Magneto: 6,
    WooCommerce: 7,
    Facebook: 9,
    TikTok: 14,
} as const
export type ChannelId = typeof CHANNEL_ID
export type ChannelIdValue = ChannelId[keyof ChannelId]

export const CURRENCY_SYMBOL = {
    THB: '฿',
    VND: 'đ',
} as const

export const COUNTRY_CURRENCY = {
    th: 'THB',
    vn: 'VND',
} as const

export const channelProductFieldInfo: Readonly<Record<ChannelIdValue, FieldInfo>> = {
    [CHANNEL_ID.Lazada]: lazada.FIELD_INFO,
    [CHANNEL_ID.LazadaVN]: lazada.FIELD_INFO_VN,
    [CHANNEL_ID.Shopee]: shopee.FIELD_INFO,
    [CHANNEL_ID.Shopify]: shopify.FIELD_INFO,
    [CHANNEL_ID.Magneto]: magneto.FIELD_INFO,
    [CHANNEL_ID.JDCentral]: jd.FIELD_INFO,
    [CHANNEL_ID.WooCommerce]: wooCommerce.FIELD_INFO,
    [CHANNEL_ID.Facebook]: fb.FIELD_INFO,
    [CHANNEL_ID.TikTok]: tiktok.FIELD_INFO,
}

export const MAX_SKU_LENGTH = 100
export const MAX_VIRTUAIZED_HEIGHT = 420
export const DEFAULT_MAX_PACKAGE_DIMENSION = 200

export const DEFAULT_DIMENSION_OPTION_MAX_LENGTH = 255

// IMPORTANT! WE USE HARDCODED PERMISSIONS, IF BACKEND PERMISSIONS CHANGE YOU'LL NEED TO UPDATE THE CODES IN permissionCodes
export const permissionCodes = {
    modules: ['module.analytics', 'module.products', 'module.sales'],
    account: [
        'account.users.view',
        'account.users.edit',
        'account.stores.view',
        'account.stores.edit',
        'account.shops.view',
        'account.shops.edit',
        'account.products.view',
        'account.products.edit',
        'account.sales.view',
        'account.sales.edit',
        'account.analytics.view',
        'account.analytics.edit',
        'account.pickpack.view',
        'account.pickpack.edit',
    ],
    store: [
        'store.shops.view',
        'store.shops.edit',
        'store.products.view',
        'store.products.edit',
        'store.sales.view',
        'store.sales.edit',
    ],
    location: ['location.pickpack.view', 'location.pickpack.edit'],
    shop: ['shop.sales.view', 'shop.sales.edit'],
}

export const MAX_PRODUCT_IMAGES = 8
export const MAX_PRODUCT_VIDEOS = 8
export const MAX_SKU_IMAGES = 5
export const MAX_VIRTUALIZED_HEIGHT = 420

export const MAX_IMAGE_UPLOAD_SIZE = 1024 * 1024 * 10
export const MAX_VIDEO_UPLOAD_SIZE = 1024 * 1024 * 20
export const ACCEPT_IMAGE_TYPE = 'image/jpeg, image/png'
export const ACCEPT_VIDEO_TYPE = 'video/mp4'

export const CHANNELS_WITH_SHORT_DESCRIPTION = [
    CHANNEL_ID.Lazada,
    CHANNEL_ID.WooCommerce,
    CHANNEL_ID.Magneto,
] as const

export const DEFAULT_MAX_SHORT_DESCRIPTION_LENGTH = 200

export { shopee, lazada }
