import { isLogisticsProviderArray, isOption, isOptionArray, isStringArray } from '../guards'

import type { ShopeeLogisticsProvider } from '~/common/schemas/logistics-shopee'
import type { ProductPayloadInput } from '~/products/api/saveProduct'
import {
    LogisticsAttributeValue,
    type ProductAttributeValue,
    type ProductFormSchema,
} from '~/products/hooks/useProductForm'

export function toProductPayload({
    formData,
    isNewProduct,
}: {
    formData: ProductFormSchema
    isNewProduct: boolean
}): ProductPayloadInput {
    const stockUnits = toPayloadStockUnits(formData.stock_units)
    const channelAttributeValues = toPayloadChannelAttributeValues(
        formData.channel_attribute_values,
        formData.stock_units
    )

    const channelProducts = toPayloadChannelProducts(
        formData.channel_products,
        formData.stock_units,
        isNewProduct
    )

    const dimensions = toPayloadDimensions(formData.dimensions)

    return {
        ...formData,
        channel_attribute_values: channelAttributeValues,
        channel_products: channelProducts,
        stock_units: stockUnits,
        dimensions: dimensions,
    }
}

function toPayloadChannelStockUnits(
    channelStockUnits: ProductFormSchema['channel_products'][number]['channel_stock_units'],
    stockUnits: ProductFormSchema['stock_units']
) {
    return channelStockUnits
        .map((channelStockUnit, index) => {
            const stockUnit = stockUnits[index]

            return {
                ...channelStockUnit,
                placeholder: channelStockUnit.placeholder ?? false,
                price: channelStockUnit.price,
                sku: stockUnit.sku,
                // stock_unit: stockUnit.id, Stock unit looked up by SKU on backend so not included here
                fulfillment_locations: stockUnit.stock_item.is_bundle
                    ? []
                    : stockUnit.fulfillment_locations.map((location) => location.location_id),
                images:
                    channelStockUnit.images?.map((image, index) => ({
                        ...image,
                        display_order: image.display_order ?? index + 1,
                        stock_unit_image: {
                            ...image.stock_unit_image,
                            display_order: image.stock_unit_image?.display_order ?? index + 1,
                            stock_unit: typeof stockUnit.id === 'number' ? stockUnit.id : undefined,
                        },
                    })) ?? [],
            }
        })
        .filter(
            (csu) => !csu.placeholder
        ) satisfies ProductPayloadInput['channel_products'][number]['channel_stock_units']
}

function toPayloadChannelProducts(
    channelProducts: ProductFormSchema['channel_products'],
    stockUnits: ProductFormSchema['stock_units'],
    isNewProduct = false
): ProductPayloadInput['channel_products'] {
    return channelProducts
        .map((cp) => {
            return {
                ...cp,
                category_id: cp.shop.channel.has_categories
                    ? cp.category?.category_id ?? null
                    : null,
                shop: cp.shop.id,
                channel_stock_units: toPayloadChannelStockUnits(cp.channel_stock_units, stockUnits),
            }
        })
        .filter((cp) => {
            return isNewProduct ? cp.shouldCreateChannelProduct : true
        })
}

function toPayloadStockUnits(
    stockUnits: ProductFormSchema['stock_units']
): ProductPayloadInput['stock_units'] {
    return stockUnits.map(({ fulfillment_locations: _, ...su }) => ({
        ...su,
        id: typeof su.id === 'number' ? su.id : undefined,
        stock_records: su.stock_records.map((sr) => ({
            location_id: sr.location.location_id,
            quantity: sr.quantity,
            stock_item: sr.stock_item,
        })),
        dimension_options: su.dimension_options.map((option) => ({
            ...option,
            id: typeof option.id === 'number' ? option.id : undefined,
        })),
        stock_item: {
            ...su.stock_item,
            sku: su.sku,
        },
    }))
}

function toPayloadDimensions(
    dimensions: ProductFormSchema['dimensions']
): ProductPayloadInput['dimensions'] {
    return dimensions.map((dimension) => ({
        ...dimension,
        id: typeof dimension.id === 'number' ? dimension.id : undefined,
        options: dimension.options.map((option) => ({
            ...option,
            id: typeof option.id === 'number' ? option.id : undefined,
        })),
    }))
}

function toPayloadChannelAttributeValues(
    channelAttributeValues: ProductFormSchema['channel_attribute_values'],
    stockUnits: Array<{ id: string | number; sku: string }>
): ProductPayloadInput['channel_attribute_values'] {
    const result: ProductPayloadInput['channel_attribute_values'] = {}
    const skuByStockUnitId = new Map(stockUnits.map((su) => [su.id.toString(), su.sku]))

    Object.entries(channelAttributeValues).forEach(([channelId, attributeType]) => {
        const productAttributeValues = Object.entries(attributeType.product)
            .filter(([_, { value }]) => !!value || (Array.isArray(value) && value.length > 0))
            .map(([attributeId, attributeDetails]) => {
                return attributeId === 'logistics'
                    ? toLogisticsAttributeValue(
                          LogisticsAttributeValue.parse(attributeDetails.value)
                      )
                    : toChannelAttributeValue(attributeId, attributeDetails)
            })

        const stockUnits = Object.entries(attributeType.stockUnit ?? {}).reduce(
            (acc, [stockUnitId, attributeDetails]) => {
                const sku = skuByStockUnitId.get(stockUnitId)
                const attributeValues = Object.entries(attributeDetails).reduce(
                    (arr, [attributeId, attributeValue]) => {
                        if (attributeValue) {
                            arr.push({
                                attribute_id: attributeId,
                                value:
                                    typeof attributeValue === 'string'
                                        ? attributeValue
                                        : attributeValue.value,
                            })
                        }

                        return arr
                    },
                    [] as ProductPayloadInput['channel_attribute_values'][string]['stock_units'][number]['stock_unit_attribute_values']
                )

                if (sku && attributeValues.length > 0) {
                    acc.push({ sku, stock_unit_attribute_values: attributeValues })
                }

                return acc
            },
            [] as ProductPayloadInput['channel_attribute_values'][string]['stock_units']
        )

        result[channelId] = {
            product_attribute_values: productAttributeValues,
            stock_units: stockUnits,
        }
    })

    return result
}

function toLogisticsAttributeValue(record: LogisticsAttributeValue) {
    const channel = Object.values(record)[0].channel

    switch (channel) {
        case 'shopee':
            return {
                type: 'shopee_logistics' as const,
                attribute_id: 'logistics' as const,
                value: Object.entries(record).reduce(
                    (acc, [shopId, logistics]) => {
                        if (isLogisticsProviderArray(logistics.value)) {
                            acc[shopId] = logistics.value
                        }
                        return acc
                    },
                    {} as Record<string, Array<Partial<ShopeeLogisticsProvider>>>
                ),
            }

        case 'tiktok':
            return {
                type: 'tiktok_delivery_option' as const,
                attribute_id: 'logistics' as const,
                value: Object.entries(record).reduce(
                    (acc, [shopId, deliverOptions]) => {
                        if (isStringArray(deliverOptions.value)) {
                            acc[shopId] = deliverOptions.value
                        }
                        return acc
                    },
                    {} as Record<string, string[]>
                ),
            }

        default: {
            const _exhaustiveCheck: never = channel
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            throw new Error(`Unhandled channel: ${_exhaustiveCheck}`)
        }
    }
}

function toChannelAttributeValue(
    attributeId: string,
    productAttributeValue: ProductAttributeValue
) {
    let value: string | string[] = Array.isArray(productAttributeValue.value) ? [] : ''

    if (
        typeof productAttributeValue.value === 'string' ||
        isStringArray(productAttributeValue.value)
    ) {
        value = productAttributeValue.value
    } else if (isOptionArray(productAttributeValue.value)) {
        value = productAttributeValue.value
            .filter((option) => !option.inactive)
            .map((option) => option.value ?? option.label)
    } else if (isOption(productAttributeValue.value)) {
        value = productAttributeValue.value.value ?? productAttributeValue.value.label
    }

    return {
        type: 'common' as const,
        attribute_id: attributeId,
        value,
        unit: productAttributeValue.unit,
    }
}
