import { useMemo, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

import useIntersectionObserver from '@react-hook/intersection-observer'
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    type RowSelectionState,
    useReactTable,
    type OnChangeFn,
} from '@tanstack/react-table'
import classNames from 'classnames'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

import ChannelIcons from './channel-icons'

import { useLanguage } from '~/common/hooks'
import { type Product } from '~/common/schemas/product'
import { useAppSelector } from '~/store'
import { getPath, getTranslation } from '~/tools/utils'

dayjs.extend(LocalizedFormat)

const useStyles = makeStyles((theme) => ({
    table: {
        tableLayout: 'fixed',
        width: '100%',
    },
    tableHeader: {
        borderTop: '1px solid ' + theme.palette.grey[200],
    },
    tableCell: {
        padding: '0 12px',

        '&.MuiTableCell-stickyHeader': {
            backgroundColor: theme.palette.common.white,
        },
    },
    productLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    productImg: {
        aspectRatio: '1/1',
        marginTop: 5,
        marginBottom: 5,
        borderRadius: 5,
    },
    noProductMessage: {
        textAlign: 'center',
        padding: 75,
        color: theme.palette.grey[400],
    },
    errorMessage: {
        padding: theme.spacing(2),
    },
    mergeProductLink: {
        display: 'flex',
        alignItems: 'center',
        color: 'inherit',
        textDecoration: 'none',
    },
    mergeIcon: {
        transform: 'rotate(90deg)',
    },
}))

const skeletonRows = Array.from({ length: 25 }) as Product[]
const helper = createColumnHelper<Product>()

type Props = {
    status: 'loading' | 'success' | 'error'
    rowSelection: RowSelectionState
    isFetchingMore: boolean
    canFetchMore?: boolean
    products?: Product[]
    stickyHeader?: boolean
    onRowSelectionChange: OnChangeFn<RowSelectionState>
    onStatusUpdate: (productId: Product['id'], status: Product['status']) => void
    onFetchMore: () => void
}

export default function ProductsTable({
    status,
    products,
    rowSelection,
    canFetchMore,
    isFetchingMore,
    stickyHeader,
    onRowSelectionChange,
    onStatusUpdate,
    onFetchMore,
}: Props) {
    const classes = useStyles()
    const language = useLanguage()
    const staticRoot = useAppSelector((state) => state.initial.staticRoot)

    const ref = useRef(null)
    const { isIntersecting } = useIntersectionObserver(ref)
    useEffect(() => {
        if (isIntersecting) {
            onFetchMore()
        }
    }, [isIntersecting, onFetchMore])

    const isFetchingAny = status === 'loading' || isFetchingMore
    const columns = useMemo(
        () => [
            helper.display({
                id: 'select',
                header: ({ table }) => (
                    <Checkbox
                        disabled={status === 'loading' || products?.length === 0}
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        disabled={!row.original}
                        checked={row.getIsSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
                size: 40,
            }),
            helper.accessor('cover_image_url', {
                header: '',
                cell: ({ getValue, row }) => {
                    const product = row.original
                    if (product) {
                        return (
                            <img
                                src={getValue()}
                                alt={getTranslation(product, language, 'name')}
                                className={classes.productImg}
                                height={50}
                            />
                        )
                    }

                    return isFetchingAny ? <Skeleton height={70} width={50} /> : null
                },
                size: 50,
            }),
            helper.accessor('translations', {
                header: gettext('Name'),
                cell: ({ row }) => {
                    const product = row.original
                    if (product) {
                        return (
                            <Link
                                to={getPath('product')
                                    .replace('product_id', product.id)
                                    .replace('store_id', product.store.id)}
                                className={classes.productLink}
                            >
                                <Typography noWrap variant="body2">
                                    {getTranslation(product, language, 'name')}
                                </Typography>
                            </Link>
                        )
                    }

                    return isFetchingAny ? <Skeleton height={36} width={400} /> : null
                },
                size: 600,
            }),
            helper.accessor('date_created', {
                header: gettext('Created'),
                cell: ({ getValue, row }) => {
                    const product = row.original
                    if (product) {
                        return (
                            <Typography noWrap variant="body2">
                                {dayjs(getValue()).format('lll')}
                            </Typography>
                        )
                    }

                    return isFetchingAny ? <Skeleton height={36} width={150} /> : null
                },
                size: 150,
            }),
            helper.accessor('channel_products', {
                header: gettext('Channels'),
                cell: ({ row }) => {
                    const channelProducts = row.original?.channel_products
                    if (channelProducts) {
                        return (
                            <ChannelIcons
                                channels={channelProducts.map((cp) => cp.shop.channel)}
                                hasErrors={channelProducts.some((cp) => !!cp.error_message)}
                            />
                        )
                    }

                    return isFetchingAny
                        ? Array.from({ length: 3 }).map((_, i) => (
                              <Skeleton
                                  key={i}
                                  variant="circle"
                                  height={20}
                                  width={20}
                                  style={{ display: 'inline-block', marginRight: 5 }}
                              />
                          ))
                        : null
                },
                size: 100,
            }),
            helper.accessor('status', {
                header: gettext('Active'),
                cell: ({ getValue, row }) => {
                    const product = row.original
                    if (product) {
                        return (
                            <Switch
                                checked={getValue() === 'active'}
                                disabled={getValue() === 'deleted'}
                                onChange={(_, checked) => {
                                    onStatusUpdate(product.id, checked ? 'active' : 'inactive')
                                }}
                            />
                        )
                    }

                    return isFetchingAny ? <Switch disabled checked /> : null
                },
                size: 60,
            }),
        ],
        [
            classes.productImg,
            classes.productLink,
            isFetchingAny,
            language,
            onStatusUpdate,
            products?.length,
            status,
        ]
    )

    const data = useMemo(() => {
        if (status === 'loading') {
            return skeletonRows
        }

        if (!products) {
            return []
        }

        if (isFetchingMore) {
            return [...products, ...skeletonRows]
        }

        return products
    }, [isFetchingMore, products, status])

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        state: { rowSelection },
        onRowSelectionChange,
    })

    return (
        <Table stickyHeader={stickyHeader} className={classes.table}>
            <TableHead className={classes.tableHeader}>
                {table.getHeaderGroups().map((group) => (
                    <TableRow key={group.id}>
                        {group.headers.map((header) => (
                            <TableCell
                                key={header.id}
                                className={classes.tableCell}
                                style={{ width: header.getSize() }}
                            >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>

            <TableBody>
                {status === 'error' && (
                    <TableRow>
                        <TableCell colSpan={columns.length} className={classes.tableCell}>
                            <Typography
                                align="center"
                                color="error"
                                variant="subtitle1"
                                className={classes.errorMessage}
                            >
                                {gettext('Failed to load products')}
                            </Typography>
                        </TableCell>
                    </TableRow>
                )}
                {products?.length === 0 ? (
                    <TableRow>
                        <TableCell
                            colSpan={columns.length}
                            className={classNames(classes.noProductMessage, classes.tableCell)}
                        >
                            <img src={staticRoot + 'web/img/product-gray.png'} />
                            <Typography variant="subtitle1">
                                {gettext('No products to display')}
                            </Typography>
                        </TableCell>
                    </TableRow>
                ) : (
                    table.getRowModel().rows.map((row) => (
                        <TableRow hover key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id} className={classes.tableCell}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))
                )}
            </TableBody>

            {/* Manually set display here to bind ref */}
            <TableFooter
                innerRef={ref}
                style={{ display: products?.length ? 'table-footer-group' : 'none' }}
            >
                <TableRow>
                    <TableCell align="center" colSpan={table.getVisibleFlatColumns().length}>
                        {canFetchMore ? (
                            <Button size="small" disabled={isFetchingAny} onClick={onFetchMore}>
                                Fetch more
                            </Button>
                        ) : (
                            <Typography variant="button">{gettext('End of list')}</Typography>
                        )}
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    )
}
