import React from 'react'

import 'moment/locale/en-gb'
import 'moment/locale/th'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { grey, red } from '@material-ui/core/colors'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import HistoryIcon from '@material-ui/icons/History'
import RefreshIcon from '@material-ui/icons/Refresh'

import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'

import DateSelectButton from '../../oakra/components/date-select-button'
import FilterSelect from '../../oakra/components/filter-select'
import { get } from '../../tools/request'
import { debounce } from '../../tools/utils'

import EventHistory from './history'
import DocumentStatusIcon from './status-icon'

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    toolbar: {
        position: 'fixed',
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
    },
    tableHeader: {
        borderTop: '1px solid ' + grey[200],
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell: {
        padding: '6px 12px',
        wordWrap: 'break-word',
        fontSize: '0.85rem',
    },
    tableHeadCell: {
        padding: '12px',
        fontSize: '0.85rem',
    },
    iconCell: {
        textAlign: 'center',
        color: grey[500],
    },
    storeSelector: {
        width: '150px',
    },
    searchBar: {
        width: 400,
    },
    generateLabelDialog: {
        textAlign: 'center',
    },
    generateLabels: {
        alignSelf: 'flex-end',
    },
    generateLabelProgress: {},
    noExportDocumentsMessage: {
        textAlign: 'center',
        padding: 75,
    },
    noExportDocumentsText: {
        color: grey[400],
    },
    exportDocumentRow: {},
    imgPlaceholder: {
        height: 40,
    },
    tableText: {
        fontSize: '0.85rem',
    },
    exportDocumentLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    exportDocumentImage: {
        width: 40,
    },
    exportDocumentTotal: {
        textAlign: 'center',
    },
    exportDocumentAmount: {},
    numItems: {
        color: grey[600],
    },
    shippingColumn: {
        textAlign: 'center',
    },
    locationText: {},
    status: {
        textAlign: 'center',
    },
    datetime: {
        color: grey[600],
    },
    statusDisplayName: {},
    channel: {
        textAlign: 'center',
    },
    channelIcon: {
        alignSelf: 'flex-start',
        width: 25,
    },
    shipByDateColumn: {
        textAlign: 'center',
    },
    shipByDate: {
        color: grey[600],
        border: `1px solid ${grey[300]}`,
        borderRadius: '4px',
        fontSize: 10,
        padding: 3,
    },
    shipByDateOverdue: {
        border: `1px solid ${red[100]}`,
        color: red[400],
    },
    pagingInfo: {
        height: 48,
        padding: '14px 32px',
        textAlign: 'right',
    },
    tableContainer: {
        maxHeight: 'calc(100vh - 170px)',
    },
})

const columns = [
    {
        id: 'name',
        label: gettext('Report name'),
        sort: false,
        centerAlign: false,
        minWidth: 250,
    },
    {
        id: 'date_created',
        label: gettext('Date created'),
        sort: false,
        centerAlign: false,
        width: 200,
    },
    {
        id: 'document_type',
        label: gettext('Document type'),
        sort: false,
        centerAlign: false,
        width: 150,
    },
    {
        id: 'status',
        label: gettext('Status'),
        sort: false,
        centerAlign: false,
        width: 150,
    },
    {
        id: 'download',
        label: gettext('Download'),
        sort: false,
        centerAlign: true,
        width: 100,
    },
    {
        id: 'history',
        label: gettext('History'),
        sort: false,
        centerAlign: true,
        width: 100,
    },
]

class ExportDocumentTableHeadWithoutStyle extends React.PureComponent {
    render() {
        const { classes } = this.props

        return (
            <TableHead className={classes.tableHeader}>
                <TableRow>
                    {columns.map((column) => {
                        return (
                            <TableCell
                                key={column.id}
                                padding={column.disablePadding ? 'none' : 'default'}
                                style={{
                                    textAlign: column.centerAlign && 'center',
                                    width: column.width ? column.width : 'auto',
                                    minWidth: column.minWidth ? column.minWidth : 0,
                                }}
                                className={classes.tableHeadCell}
                            >
                                {column.label}
                            </TableCell>
                        )
                    }, this)}
                </TableRow>
            </TableHead>
        )
    }
}

const ExportDocumentTableHead = withStyles(styles)(ExportDocumentTableHeadWithoutStyle)

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 67,
        zIndex: 3,
        paddingLeft: 12,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },
    title: {
        flex: '0 1 auto',
        flexWrap: 'wrap',
    },
    toolbarItems: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 10,
    },
    toolbarTextField: {},
    orderSearch: {
        width: 150,
        marginTop: 2.5,
        marginBottom: 2.5,
        marginRight: 7,
        marginLeft: 7,
    },
    productSearch: {
        width: 150,
        marginTop: 2.5,
        marginBottom: 2.5,
        marginRight: 7,
    },
    filters: {
        marginTop: 10,
        marginBottom: 10,
        flexWrap: 'wrap',
    },
    clearFilter: {
        fontSize: 13,
        marginLeft: 7,
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'flex',
        flex: '0 0 auto',
    },
    selectActionButton: {
        alignSelf: 'center',
        flexShrink: 0,
    },
    topPagination: {
        marginLeft: 15,
    },
    paginationActions: {
        marginLeft: 5,
    },
    paginationToolbar: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    numTotal: {
        fontSize: 12,
        marginLeft: 4,
        paddingTop: 9,
        color: grey[600],
    },
    lastPollTime: {
        fontSize: 12,
        marginRight: 8,
        paddingTop: 4,
        color: grey[600],
    },
})

const DocumentTypeText = {
    tax_invoice: gettext('Tax invoice'),
    sales_export: gettext('Sale export'),
}

const DocumentStatusText = {
    waiting: gettext('Waiting'),
    generating: gettext('Generating'),
    ready: gettext('Ready'),
    failed: gettext('Failed'),
}

class ExportDocumentTableToolbarWithoutStyle extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectActionAnchorEl: null,
            dateButtonText: gettext('Past 2 weeks'),
        }

        this.handleDateChange = this.handleDateChange.bind(this)
    }

    handleDateChange(dateStart, dateEnd, buttonText) {
        this.props.handleDateTimeChange(dateStart, dateEnd)
        this.setState({ dateButtonText: buttonText })
    }

    render() {
        const {
            numTotal,
            classes,
            handleFilterChange,
            handleClearFilters,
            filterOptions,
            filterValues,
            pollingState,
            lastPollTime,
            handleRefresh,
        } = this.props

        return (
            <Toolbar className={classNames(classes.root)}>
                <div className={classes.title}>
                    <div className={classes.toolbarItems}>
                        <div style={{ marginRight: 7 }}>
                            <DateSelectButton
                                startDate={filterValues.date_created_after}
                                endDate={filterValues.date_created_before}
                                handleDateChange={this.handleDateChange}
                                initialButtonText={this.state.dateButtonText}
                            />
                        </div>
                        {filterOptions.map((field) => (
                            <FilterSelect
                                key={field.lookup}
                                field={field}
                                value={filterValues[field.lookup]}
                                handleFilterChange={handleFilterChange}
                            />
                        ))}
                        <Button className={classes.clearFilter} onClick={handleClearFilters}>
                            {gettext('Clear filters')}
                        </Button>
                        {numTotal > 0 && (
                            <span className={classes.numTotal}>
                                {interpolate(gettext('%s exports'), [numTotal])}
                            </span>
                        )}
                    </div>
                </div>
                {pollingState === 'stop' && (
                    <div className={classes.toolbarItems}>
                        {lastPollTime && (
                            <span className={classes.lastPollTime}>
                                {interpolate(gettext('Last updated at %s'), [
                                    moment(lastPollTime).format('HH:mm'),
                                ])}
                            </span>
                        )}

                        <a style={{ cursor: 'pointer' }} onClick={handleRefresh}>
                            <RefreshIcon />
                        </a>
                    </div>
                )}
            </Toolbar>
        )
    }
}

ExportDocumentTableToolbarWithoutStyle.propTypes = {
    classes: PropTypes.object.isRequired,
}

const ExportDocumentTableToolbar = withStyles(toolbarStyles)(ExportDocumentTableToolbarWithoutStyle)

class ExportDocuments extends React.PureComponent {
    constructor(props) {
        super(props)

        moment.locale(props.language)

        this.state = {
            rowsPerPage: 25,
            exportDocuments: [],
            count: 0,
            page: 0,
            maxPageFetched: 0,
            loading: true,
            shippingInfoManagerOpen: false,
            filterOptions: [
                {
                    field: gettext('Document type'),
                    lookup: 'document_type',
                    values: Object.entries(DocumentTypeText).map(([value, label]) => ({
                        value,
                        label,
                    })),
                    select_multiple: false,
                },
                {
                    field: gettext('Status'),
                    lookup: 'status',
                    values: Object.entries(DocumentStatusText).map(([value, label]) => ({
                        value,
                        label,
                    })),
                    select_multiple: false,
                },
            ],
            filterValues: {
                date_created_after: moment().subtract(13, 'days'),
                date_created_before: moment(),
            },
            pollingState: null, // null | polling | stop
            lastPollTime: null,
            firstPollTime: null,
        }

        this.tableContainerRef = React.createRef()

        this.filtersToQueryString = this.filtersToQueryString.bind(this)
        this.fetchExportDocumentData = this.fetchExportDocumentData.bind(this)
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.clearFilters = this.clearFilters.bind(this)
        this.handleDateTimeChange = this.handleDateTimeChange.bind(this)
        this.fetchExportDocumentDataDebounced = debounce(this.fetchExportDocumentData, 500)
        this.handleTableScroll = debounce(this.handleTableScroll, 100)
    }

    componentDidMount() {
        this.fetchExportDocumentData()
        setInterval(this.checkAndPollForDocuments, 2 * 1000)
    }

    filtersToQueryString(filters = false) {
        let filtersToUse = filters || this.state.filterValues

        filtersToUse = filtersToUse.id_value ? { id_value: filtersToUse.id_value } : filtersToUse

        return Object.entries(filtersToUse)
            .filter(([, option]) => Boolean(option))
            .map(([fieldLookup, option]) => {
                if (moment.isMoment(option)) {
                    return `${fieldLookup}=${encodeURIComponent(option.format('YYYY-MM-DD'))}`
                } else {
                    return `${fieldLookup}=${encodeURIComponent(option.value || option)}`
                }
            })
            .join('&')
    }

    fetchExportDocumentData(page = 0, filtersToUse = false) {
        let url = this.props.exportDocumentsAPIURL
        if (page > this.state.page) {
            url = this.state.next
        }
        if (!url.includes('?')) {
            const queryString = this.filtersToQueryString(filtersToUse)
            if (queryString) {
                url += '?' + queryString
            }
        }
        this.setState({
            loading: true,
        })

        return get(url).then((response) => {
            const newState = {
                loading: false,
            }
            if (response.ok) {
                newState.count = response.count
                newState.next = response.next
                newState.previous = response.previous
                newState.page = page

                if (page === 0) {
                    // reset state
                    newState.maxPageFetched = 0
                    newState.exportDocuments = response.results
                } else if (page > 0) {
                    newState.exportDocuments = this.state.exportDocuments.concat(response.results)
                }
            }
            this.setState(newState)
        })
    }

    pollForDocuments = (documentPKs) => {
        this.setState({ loading: true })

        get(this.props.exportDocumentsAPIURL, {
            document_pks: documentPKs,
        }).then((response) => {
            const newState = {
                loading: false,
            }
            if (response.ok) {
                const documentsByPK = {}
                response.results.forEach((doc) => {
                    documentsByPK[doc.id] = doc
                })
                newState.exportDocuments = this.state.exportDocuments.map(
                    (doc) => documentsByPK[doc.id] || doc
                )
                newState.lastPollTime = moment()
                if (!this.state.firstPollTime) {
                    newState.firstPollTime = moment()
                }
            }
            this.setState(newState)
        })
    }

    checkAndPollForDocuments = (forcePoll = false) => {
        const { exportDocuments, firstPollTime, pollingState } = this.state
        if (pollingState === 'stop' && !forcePoll) {
            return
        }

        if (firstPollTime && moment() - firstPollTime > 5 * 1000) {
            if (pollingState === 'polling') {
                this.setState({ pollingState: 'stop' })
            }
            if (!forcePoll) {
                return
            }
        }

        const documentPKsToPoll = exportDocuments
            .filter((doc) => doc.status === 'generating' || doc.status === 'waiting')
            .map((doc) => doc.id)

        if (documentPKsToPoll.length > 0) {
            if (pollingState === null) {
                this.setState({ pollingState: 'polling' })
            }
            this.pollForDocuments(documentPKsToPoll)
        }
    }

    handleSort(event, property) {
        let orderBy

        if (this.state.orderBy.startsWith('-') && this.state.orderBy.replace('-', '') == property) {
            orderBy = property
        } else {
            orderBy = '-' + property
        }

        this.setState(
            {
                orderBy,
                loading: true,
            },
            () => {
                this.fetchExportDocumentData()
            }
        )
    }

    handleChangePage(page) {
        const needsFetch = page > this.state.maxPageFetched

        if (needsFetch) {
            this.setState(
                {
                    maxPageFetched: page,
                    loading: true,
                },
                () => {
                    this.fetchExportDocumentData(page)
                }
            )
        } else {
            this.setState({
                page: page,
            })
        }
    }

    loadNextPage = () => {
        const { page, next } = this.state
        if (next) {
            const nextPage = page + 1
            this.handleChangePage(nextPage)
        }
    }

    handleFilterChange(field) {
        return (option) => {
            if (this.tableContainerRef.current) {
                // reset scroll to top
                this.tableContainerRef.current.scrollTop = 0
            }
            const filterValues = { ...this.state.filterValues }
            if (option) {
                filterValues[field.lookup] = option
            } else {
                delete filterValues[field.lookup]
            }

            this.setState(
                {
                    filterValues,
                    page: 0,
                },
                () => {
                    this.fetchExportDocumentData()
                }
            )
        }
    }

    clearFilters(refresh = true) {
        const filterValues = { ...this.state.filterValues }
        const doNotClearFields = ['date_created_after', 'date_created_before']
        const textFields = []

        Object.keys(filterValues).forEach((fieldLookup) => {
            if (!doNotClearFields.includes(fieldLookup))
                filterValues[fieldLookup] = textFields.includes(fieldLookup) ? '' : null
        })

        this.setState(
            {
                filterValues,
                page: 0,
            },
            () => {
                refresh && this.fetchExportDocumentData()
            }
        )
    }

    handleDateTimeChange(startDate, endDate) {
        const filterValues = { ...this.state.filterValues }
        filterValues['date_created_after'] = startDate
        filterValues['date_created_before'] = endDate
        this.setState({ filterValues, page: 0 }, () => {
            this.fetchExportDocumentData()
        })
    }

    handleTableScroll = () => {
        const tableContainerRef = this.tableContainerRef
        if (tableContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current
            const scrollPercent = (2 * clientHeight - scrollHeight + scrollTop) / clientHeight
            if (scrollPercent >= 0.6 && !this.state.loading) {
                // triggered after scroll over 60% of the table
                this.loadNextPage()
            }
        }
    }

    openHistoryModal = (document) => {
        this.setState({
            selectedRow: document,
            eventHistoryModalOpen: true,
        })
    }

    closeHistoryModal = () => {
        this.setState({
            eventHistoryModalOpen: false,
        })
    }

    render() {
        const { classes, staticRoot } = this.props
        const {
            loading,
            rowsPerPage,
            count,
            exportDocuments,
            eventHistoryModalOpen,
            selectedRow,
            pollingState,
            lastPollTime,
            filterOptions,
            filterValues,
        } = this.state
        const hasMultiplePages = count > rowsPerPage

        const isLastPage = !this.state.next

        return (
            <Paper className={classes.root}>
                <ExportDocumentTableToolbar
                    className={classes.toolbar}
                    numTotal={count}
                    loading={loading}
                    filterOptions={filterOptions}
                    filterValues={filterValues}
                    pollingState={pollingState}
                    lastPollTime={lastPollTime}
                    handleFilterChange={this.handleFilterChange}
                    handleClearFilters={this.clearFilters}
                    handleDateTimeChange={this.handleDateTimeChange}
                    handleRefresh={() => this.checkAndPollForDocuments(true)}
                />
                {eventHistoryModalOpen && (
                    <EventHistory
                        document={selectedRow}
                        open={eventHistoryModalOpen}
                        onClose={this.closeHistoryModal}
                    />
                )}
                <TableContainer
                    className={classes.tableContainer}
                    onScroll={this.handleTableScroll}
                    ref={this.tableContainerRef}
                >
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <ExportDocumentTableHead className={classes.tableHead} />
                        <TableBody>
                            {!loading && exportDocuments.length === 0 && (
                                <TableRow>
                                    <TableCell
                                        colSpan={6}
                                        className={classNames(
                                            classes.noExportDocumentsMessage,
                                            classes.tableCell
                                        )}
                                    >
                                        <img
                                            className={classes.noExportDocumentsIcon}
                                            src={staticRoot + 'web/img/order.png'}
                                        />
                                        <Typography
                                            className={classes.noExportDocumentsText}
                                            variant="subtitle1"
                                        >
                                            {gettext('No documents to display')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}

                            {exportDocuments.map((doc) => {
                                return (
                                    <TableRow
                                        className={classes.exportDocumentRow}
                                        hover
                                        tabIndex={-1}
                                        key={doc.id}
                                    >
                                        <TableCell className={classes.tableCell}>
                                            <Typography
                                                className={classes.tableText}
                                                variant="body2"
                                            >
                                                {doc.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography
                                                className={classNames(
                                                    classes.tableText,
                                                    classes.datetime
                                                )}
                                                variant="body2"
                                            >
                                                {moment(doc.date_created).format('D MMM HH:mm')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography
                                                className={classes.tableText}
                                                variant="body2"
                                            >
                                                {DocumentTypeText[doc.document_type]}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography
                                                className={classes.tableText}
                                                variant="body2"
                                            >
                                                {DocumentStatusText[doc.status]}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={classNames(
                                                classes.tableCell,
                                                classes.iconCell
                                            )}
                                        >
                                            <DocumentStatusIcon document={doc} />
                                        </TableCell>

                                        <TableCell
                                            className={classNames(
                                                classes.tableCell,
                                                classes.iconCell
                                            )}
                                        >
                                            <a
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.openHistoryModal(doc)}
                                            >
                                                <HistoryIcon />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}

                            {hasMultiplePages && (
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <div style={{ textAlign: 'center' }}>
                                            {isLastPage ? (
                                                <span>{gettext('All data loaded.')}</span>
                                            ) : (
                                                <CircularProgress size={28} />
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        )
    }
}

export default withStyles(styles)(ExportDocuments)
