import Cookie from 'js-cookie'
import queryString from 'query-string'

export const request = (url, method, data) => {
    let promise
    if (method === 'GET') {
        if (data) url = queryString.stringifyUrl({ url, query: data })
        promise = fetch(url)
    } else {
        promise = fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRFToken': Cookie.get('csrftoken'),
            },
            body: JSON.stringify(data),
        })
    }

    return promise.then((response) => {
        return response
            .json()
            .then((data) => {
                if (data instanceof Array) {
                    return { data, ok: response.ok }
                }
                return { ...data, ok: response.ok }
            })
            .catch(() => ({ ok: response.ok }))
    })
}

export const get = (url, data) => {
    return request(url, 'GET', data)
}

export const post = (url, data) => {
    return request(url, 'POST', data)
}

export const put = (url, data) => {
    return request(url, 'PUT', data)
}

export const patch = (url, data) => {
    return request(url, 'PATCH', data)
}

export const remove = (url, data) => {
    return request(url, 'DELETE', data)
}
