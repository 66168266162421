import { type ProductFormSchema, StockUnitFormSchema } from '~/products/hooks/useProductForm'

export const makeDimensionOptions = (
    optionIds: (string | number)[],
    dimensions: ProductFormSchema['dimensions']
) => {
    const optionsSchema = StockUnitFormSchema.shape.dimension_options

    return optionsSchema.min(1, 'Option is required').parse(
        optionIds.reduce((acc, optionId, dimensionIndex) => {
            const dimension = dimensions.at(dimensionIndex)
            const option = dimension?.options.find((option) => option.id === optionId)
            if (!option) {
                return acc
            }

            acc.push({
                id: option.id,
                index: option.index,
                product_dimension: {
                    index: dimensionIndex,
                },
            })

            return acc
        }, [] as StockUnitFormSchema['dimension_options'])
    )
}
