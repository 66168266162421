import Switch from '@material-ui/core/Switch'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import PropTypes from 'prop-types'

import { useStyles } from './styles'

export default function ModulePermission({ name, description, permissions, checked, onChange }) {
    const classes = useStyles()

    return (
        <TableRow>
            <TableCell className={classes.tableCell}>
                <div className={classes.tableCellContainer}>
                    <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                        {gettext(name)}
                    </Typography>
                    <Typography variant="subtitle2" style={{ marginRight: 15 }}>
                        {gettext(description)}
                    </Typography>
                </div>
            </TableCell>
            <TableCell>
                <div style={{ display: 'flex', alignItems: 'end', flexDirection: 'column' }}>
                    <Switch checked={checked} onChange={(_, checked) => onChange(permissions, checked)} />
                </div>
            </TableCell>
        </TableRow>
    )
}

ModulePermission.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}
