import React from 'react'

import  Paper  from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'


import { get } from '../../../tools/request'
import { commonStyles } from '../sale'

import Note from './note'

const saleNotesStyles = (theme) => ({
    ...commonStyles,
    saleNoteList: {
        listStyle: 'none',
        padding: 0,
    },
})

class Notes extends React.Component {
    state = {
        notes: [],
        loading: true,
    }

    componentDidMount() {
        this.fetchNotes()
    }

    fetchNotes = () => {
        const { saleNotesAPIURL, saleId } = this.props

        this.setState({ loading: true })

        get(saleNotesAPIURL.replace('sale_pk', saleId)).then((res) => {
            if (res.ok) {
                this.setState({ notes: res.data })
            }
            this.setState({ loading: false })
        })
    }

    handleNoteChange = () => {
        this.fetchNotes()
    }

    render() {
        const { classes, saleId, storeId, staticFilesDomain, saleNoteAPIURL, saleNotesAPIURL } =
            this.props
        const { notes } = this.state

        const saleNoteProps = {
            storeId,
            staticFilesDomain,
            saleNotesAPIURL: saleNotesAPIURL.replace('sale_pk', saleId),
            saleNoteAPIURL: saleNoteAPIURL.replace('sale_pk', saleId),
            onChange: this.handleNoteChange,
        }

        return (
            <Paper className={classNames(classes.basicInfo, classes.section)}>
                <Typography variant="subtitle1" className={classes.sectionTitle}>
                    {gettext('Notes')}
                </Typography>

                <div className={classNames(classes.bodyText, classes.saleNoteList)}>
                    {notes.map((note) => (
                        <Note mode="view" key={note.id} currentNote={note} {...saleNoteProps} />
                    ))}
                </div>

                <Note mode="placeholder" currentNote={{ sale: saleId }} {...saleNoteProps} />
            </Paper>
        )
    }
}

export default withStyles(saleNotesStyles)(Notes)
