import { useState, useRef } from 'react'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import grey from '@material-ui/core/colors/grey'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { MuiPickersUtilsProvider, Calendar } from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'
import moment from 'moment'

const dateSelectStyles = makeStyles((theme) => ({
    dateSelectButton: {
        backgroundColor: 'white',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
    },
    shortcuts: {
        display: 'flex',
        marginBottom: 15,
    },
    shortcut: {
        fontSize: '0.9rem',
        fontWeight: 500,
        color: grey[600],
        cursor: 'pointer',
        marginRight: 10,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    calendars: {
        display: 'flex',
    },
    startDate: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto',
        overflow: 'hidden',
    },
    startDateText: {
        border: `1px solid ${grey[400]}`,
        textAlign: 'center',
        padding: '0.3rem',
        borderRadius: '0.3rem',
        fontWeight: 400,
    },
    spacer: {
        borderLeft: `1px solid ${grey[400]}`,
        margin: '0 20px',
    },
    endDate: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto',
        overflow: 'hidden',
    },
    endDateText: {
        border: `1px solid ${grey[400]}`,
        textAlign: 'center',
        padding: '0.3rem',
        borderRadius: '0.3rem',
        fontWeight: 400,
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))

const PERIOD_TO_DAYS = {
    past_week: 7,
    past_2_weeks: 14,
    past_month: 30,
}

const PERIOD_LABEL = {
    yesterday: gettext('Yesterday'),
    past_week: gettext('Past week'),
    past_2_weeks: gettext('Past 2 weeks'),
    past_month: gettext('Past month'),
}

const DateSelectButton = ({
    startDate,
    endDate,
    handleDateChange,
    container,
    initialButtonText,
    disabled,
}) => {
    const [showCalendar, setShowCalendar] = useState(false)
    const [buttonText, setButtonText] = useState(initialButtonText || gettext('Past week'))
    const [clickAwayActive, setClickAwayActive] = useState(false)
    const buttonEl = useRef(null)

    const classes = dateSelectStyles()

    const handleClickButton = () => {
        if (!showCalendar) setTimeout(() => setClickAwayActive(true), 200)
        setShowCalendar(true)
    }

    const handleClickShortcut = (period) => (e) => {
        if (period == 'yesterday') {
            const yesterday = moment().subtract(1, 'days')
            handleDateChange(yesterday, yesterday, PERIOD_LABEL[period])
        } else {
            const today = moment()
            const days = PERIOD_TO_DAYS[period]
            const startDate = moment().subtract(days, 'days')
            handleDateChange(startDate, today, PERIOD_LABEL[period])
        }
        setButtonText(PERIOD_LABEL[period])
        handleClickCloseCalendar()
    }

    const handleClickCloseCalendar = (close = true) => {
        if (showCalendar && close) {
            setShowCalendar(false)
            setClickAwayActive(false)
        }
    }

    return (
        <>
            <Button
                className={classes.dateSelectButton}
                variant="outlined"
                startIcon={<DateRangeIcon />}
                ref={buttonEl}
                onClick={handleClickButton}
                disabled={disabled}
            >
                {buttonText}
            </Button>
            <ClickAwayListener onClickAway={() => handleClickCloseCalendar(clickAwayActive)}>
                <Popper
                    open={showCalendar}
                    anchorEl={buttonEl.current}
                    placement="bottom-start"
                    style={{ zIndex: 2000 }}
                    {...(container && { container: container.current })}
                >
                    <Paper className={classes.container}>
                        <div className={classes.shortcuts}>
                            <Typography
                                className={classes.shortcut}
                                onClick={handleClickShortcut('yesterday')}
                            >
                                {gettext('Yesterday')}
                            </Typography>
                            <Typography
                                className={classes.shortcut}
                                onClick={handleClickShortcut('past_week')}
                            >
                                {gettext('Past week')}
                            </Typography>
                            <Typography
                                className={classes.shortcut}
                                onClick={handleClickShortcut('past_2_weeks')}
                            >
                                {gettext('Past 2 weeks')}
                            </Typography>
                            <Typography
                                className={classes.shortcut}
                                onClick={handleClickShortcut('past_month')}
                            >
                                {gettext('Past month')}
                            </Typography>
                        </div>
                        <div className={classes.calendars}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <div className={classes.startDate}>
                                    <Typography className={classes.startDateText}>
                                        {startDate.format('LL')}
                                    </Typography>
                                    <Calendar
                                        date={startDate}
                                        onChange={(date, isFinish) => {
                                            const startDateText = date.format('ll')
                                            const endDateText = endDate.format('ll')
                                            const btnText = `${startDateText} - ${endDateText}`
                                            setButtonText(btnText)
                                            handleDateChange(date, endDate, btnText)
                                        }}
                                        maxDate={endDate}
                                        disableFuture
                                    />
                                </div>
                                <div className={classes.spacer} />
                                <div className={classes.endDate}>
                                    <Typography className={classes.endDateText}>
                                        {endDate.format('LL')}
                                    </Typography>
                                    <Calendar
                                        date={endDate}
                                        onChange={(date, isFinish) => {
                                            const startDateText = startDate.format('ll')
                                            const endDateText = date.format('ll')
                                            const btnText = `${startDateText} - ${endDateText}`
                                            setButtonText(btnText)
                                            handleDateChange(startDate, date, btnText)
                                        }}
                                        minDate={startDate}
                                        disableFuture
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={classes.footer}>
                            <Button
                                className={classes.closeButton}
                                color="secondary"
                                variant="contained"
                                size="small"
                                onClick={handleClickCloseCalendar}
                            >
                                {gettext('Close')}
                            </Button>
                        </div>
                    </Paper>
                </Popper>
            </ClickAwayListener>
        </>
    )
}

export default DateSelectButton
