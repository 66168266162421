import { memo, useCallback, useEffect } from 'react'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import type { default as Quill } from 'quill'

import { useProductFormContext } from '../hooks/useProductForm'

import BasicProductInfoItem from './basic-product-info-item'

import { languageLabels } from '~/constants'
import { useAppSelector } from '~/store'
import { getPresignedUrls, uploadImageToS3 } from '~/utils/image'

type Language = keyof typeof languageLabels

type FieldProps =
    | {
          field: 'product'
          channelProductIndex?: undefined
      }
    | {
          field: 'channel_product'
          channelProductIndex: number
      }

type ShortDescriptionProps =
    | {
          hasShortDescription: true
          shortDescriptionMaxLength?: number
      }
    | {
          hasShortDescription: false
          shortDescriptionMaxLength?: undefined
      }

type BaseBasicProductInfoProps = {
    tab: string
    languages: string[]
    nameMaxLength: number
    descriptionMaxLength: number
    onChangeTab: (newTab: string) => void
}

export type BasicProductInfoProps = BaseBasicProductInfoProps & FieldProps & ShortDescriptionProps

const isValidLanguage = (language: string): language is Language => language in languageLabels

const MAX_IMAGE_SIZE = 5 * 1024 * 1024

function BasicProductInfo({
    tab,
    languages,
    field,
    channelProductIndex,
    descriptionMaxLength,
    nameMaxLength,
    hasShortDescription,
    shortDescriptionMaxLength,
    onChangeTab,
}: BasicProductInfoProps) {
    const { getValues } = useProductFormContext()
    const staticFilesDomain = useAppSelector((state) => state.initial.staticFilesDomain)
    const uploadImageSignatureAPIURL = useAppSelector(
        (state) => state.initial.endpoints.uploadImageSignatureAPIURL
    )

    const defaultLanguage = languages.length > 1 ? languages[0] : null
    useEffect(() => {
        if (defaultLanguage) {
            onChangeTab(defaultLanguage)
        }
    }, [defaultLanguage, onChangeTab])

    const handleImageChange = useCallback(
        async (files: File[], quill: Quill) => {
            const store = getValues('store')
            const imageFiles = files.filter(
                (f) => f.type.startsWith('image/') && f.size < MAX_IMAGE_SIZE
            )

            const infoForFiles = imageFiles.map(({ name, type }) => ({
                filename: name,
                content_type: type,
            }))

            const uploadURLs = await getPresignedUrls(
                uploadImageSignatureAPIURL.replace('store_id', store.id.toString()),
                infoForFiles
            )
            await Promise.all(
                uploadURLs.map((url) => {
                    const image = imageFiles.find((f) => f.name === url.original_filename)
                    if (!image) {
                        throw new Error('Image not found')
                    }

                    return uploadImageToS3({
                        presignedUrl: url.presigned_url,
                        file: image,
                    })
                })
            )

            const range = quill.getSelection(true)
            uploadURLs.forEach((url, index) => {
                const imageUrl = staticFilesDomain + url.filename
                quill.insertEmbed(range.index + index, 'image', imageUrl)
            })
        },
        [getValues, staticFilesDomain, uploadImageSignatureAPIURL]
    )

    return (
        <div>
            <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(_, value: string) => onChangeTab(value)}
            >
                {languages.map((language) => (
                    <Tab
                        key={language}
                        label={
                            isValidLanguage(language) ? languageLabels[language] : languageLabels.en
                        }
                        value={language}
                    />
                ))}
            </Tabs>

            {languages.map((language) => (
                <BasicProductInfoItem
                    key={language}
                    language={language}
                    required={languages.length === 0 || language !== 'en'}
                    tab={tab}
                    field={field}
                    channelProductIndex={channelProductIndex}
                    nameMaxLength={nameMaxLength}
                    descriptionMaxLength={descriptionMaxLength}
                    hasShortDescription={hasShortDescription}
                    shortDescriptionMaxLength={shortDescriptionMaxLength}
                    onImageChange={handleImageChange}
                />
            ))}
        </div>
    )
}

export default memo(BasicProductInfo)
