import { memo, useEffect } from 'react'
import { useWatch } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import DeleteIcon from '@material-ui/icons/Delete'

import { type ProductFormSchema, useProductFormContext } from '../../hooks/useProductForm'

import { FormField } from '~/common/components'
import useDebounce from '~/common/hooks/useDebounce'
import { useDimensionHelpers } from '~/products/hooks/useDimensionHelpers'

const useStyles = makeStyles((theme) => ({
    deleteButton: {
        marginLeft: theme.spacing(1),
    },
}))

type Props = {
    id: string | number
    index: number
    hasOnlyOne: boolean
    canEdit: boolean
    options: ProductFormSchema['stock_units'][0]['dimension_options']
    onDelete?: (index: number) => void
}

function StockUnitRow({ id, index, hasOnlyOne, canEdit, options, onDelete }: Props) {
    const name = `stock_units.${index}` as const
    const classes = useStyles()
    const { getDimensionOptionName } = useDimensionHelpers()

    const { setValue, getValues, control } = useProductFormContext()
    const sku = useWatch({ control, name: `${name}.sku`, exact: true })
    const debouncedSku = useDebounce(sku, 1000)

    useEffect(() => {
        getValues('channel_products').forEach((cp, cpIndex) => {
            cp.channel_stock_units.forEach((csu, csuIndex) => {
                if (csuIndex !== index || csu.sku === debouncedSku) {
                    return
                }

                setValue(
                    `channel_products.${cpIndex}.channel_stock_units.${csuIndex}.sku`,
                    debouncedSku
                )
            })
        })
    }, [debouncedSku, getValues, index, setValue])

    return (
        <>
            {options.map((option) => (
                <TableCell key={`${option.index}-${option.product_dimension.index}`}>
                    {getDimensionOptionName(option.product_dimension.index, option.id)}
                </TableCell>
            ))}

            <TableCell padding="none" colSpan={2}>
                <Box display="flex" alignItems="center">
                    <FormField
                        fullWidth
                        control={control}
                        disabled={!canEdit}
                        name={`stock_units.${index}.sku` as const}
                        rules={{
                            validate: (value) => {
                                if (!value) {
                                    return gettext('SKU is required')
                                }

                                const stockUnits = getValues('stock_units')
                                const duplicatedIndex = stockUnits.findLastIndex(
                                    (su) => su.id !== id && su.sku === value
                                )
                                if (duplicatedIndex > -1) {
                                    return gettext(
                                        `SKU is already used in stock unit #${duplicatedIndex + 1}`
                                    )
                                }

                                return true
                            },
                        }}
                    />
                    {!hasOnlyOne && (
                        <IconButton
                            className={classes.deleteButton}
                            tabIndex={-1}
                            size="small"
                            onClick={() => onDelete?.(index)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Box>
            </TableCell>
        </>
    )
}

export default memo(StockUnitRow)
