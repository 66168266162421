import { connect } from 'react-redux'

import Discount from '../components/discount'

const mapStateToProps = (state) => ({
    discountAPIURL: state.initial.endpoints.discountAPIURL,
    discountCreateAPIURL: state.initial.endpoints.discountCreateAPIURL,
    productDiscountsAPIURL: state.initial.endpoints.productDiscountsAPIURL,
    simplifiedProductDiscountsAPIURL: state.initial.endpoints.simplifiedProductDiscountsAPIURL,
    updateProductDiscountsAPIURL: state.initial.endpoints.updateProductDiscountsAPIURL,
    stores: state.initial.stores,
    language: state.initial.language,
    userID: state.initial.user.id,
    staticRoot: state.initial.staticRoot,
})

export default connect(mapStateToProps)(Discount)
