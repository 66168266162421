import { connect } from 'react-redux'

import ImageManager from '../components/image-manager'

const mapStateToProps = (state) => ({
    staticFilesDomain: state.initial.staticFilesDomain,
    uploadImageSignatureAPIURL: state.initial.endpoints.uploadImageSignatureAPIURL,
})

export default connect(mapStateToProps)(ImageManager)
