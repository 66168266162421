import { forwardRef, memo } from 'react'

import Paper from '@material-ui/core/Paper'
import type { PaperProps } from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

import clsx from 'classnames'

const useStyles = makeStyles({
    section: {
        position: 'relative',
        padding: '15px 20px',
    },
})

const ProductSection = forwardRef(function ProductSection(
    { children, className, ...rest }: PaperProps,
    ref
) {
    const classes = useStyles()

    return (
        <Paper innerRef={ref} className={clsx(classes.section, className)} {...rest}>
            {children}
        </Paper>
    )
})

export default memo(ProductSection)
