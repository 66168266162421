import { useRef, useMemo, useEffect, useCallback } from 'react'
import { useWatch } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import ReorderIcon from '@material-ui/icons/Reorder'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import PropTypes from 'prop-types'

import { FormField } from '../../../common/components'
import { DEFAULT_DIMENSION_OPTION_MAX_LENGTH } from '../../../constants'
import { getFieldInfoByShop } from '../../utils/getFieldLengths'

export default function ProductDimensionOption({
    id,
    channelProducts,
    options,
    control,
    currentIndex,
    isFirst,
    isLast,
    onRemove,
    onAppend,
    onFocus,
}) {
    const {
        isDragging,
        attributes,
        listeners,
        transform,
        transition,
        setActivatorNodeRef,
        setNodeRef,
    } = useSortable({ id, attributes: { tabIndex: -1 } })

    const name = `options.${currentIndex}.name`
    const value = useWatch({ name, control })
    const hasRun = useRef(false)
    const shouldDisplayDeleteOption = !isFirst && !isLast

    const maxOptionNameLength = useMemo(
        () =>
            channelProducts.length > 0
                ? Math.max(
                      ...channelProducts.map((cp) => {
                          const fieldInfo = getFieldInfoByShop(cp.shop)
                          const maxLength =
                              fieldInfo?.optionName?.maxLength ||
                              DEFAULT_DIMENSION_OPTION_MAX_LENGTH
                          return maxLength
                      })
                  )
                : DEFAULT_DIMENSION_OPTION_MAX_LENGTH,
        [channelProducts]
    )

    useEffect(() => {
        if (value && isLast && !hasRun.current) {
            hasRun.current = true
            onAppend()
        }
    }, [hasRun, value, isLast, onAppend])

    const handleBackspace = useCallback(
        (e) => {
            if (e.key !== 'Backspace' || value) {
                return
            }

            e.preventDefault()
            if (!isLast) {
                onRemove(currentIndex)
            }

            onFocus(`options.${currentIndex - 1}.name`)
        },
        [currentIndex, isLast, onFocus, onRemove, value]
    )

    const validateUnique = async (optionValue) => {
        if (!optionValue) {
            return
        }

        const lowercaseValue = optionValue.trim().toLowerCase()
        const optionNames = options
            .filter((_, index) => index !== currentIndex)
            .map((option) => option.name.trim().toLowerCase())

        return !optionNames.includes(lowercaseValue) || gettext('Option already exists')
    }

    const existingOptionNames = useMemo(
        () =>
            options
                .filter((_, index) => index !== currentIndex)
                .map((_, index) => `options${index}.name`),
        [currentIndex, options]
    )

    return (
        <li
            ref={setNodeRef}
            style={{
                opacity: isDragging ? 0.4 : undefined,
                transition,
                transform: CSS.Transform.toString(transform),
            }}
        >
            <Box display="flex" alignItems="center" gridColumnGap={4}>
                <Box visibility={isLast ? 'hidden' : 'visible'}>
                    <IconButton
                        {...listeners}
                        {...attributes}
                        innerRef={setActivatorNodeRef}
                        aria-label="reorder product dimension option"
                        size="small"
                        tabIndex={-1}
                    >
                        <ReorderIcon />
                    </IconButton>
                </Box>
                <FormField
                    control={control}
                    name={name}
                    placeholder={isFirst ? gettext('Add an option') : gettext('Add another option')}
                    rules={{
                        required: isFirst && gettext('Dimension option is required'),
                        maxLength: {
                            value: maxOptionNameLength,
                            message: interpolate(
                                gettext('Option name should be %s characters or less'),
                                [maxOptionNameLength]
                            ),
                        },
                        validate: validateUnique,
                        deps: existingOptionNames,
                    }}
                    onKeyDown={handleBackspace}
                />
                {shouldDisplayDeleteOption && (
                    <Box ml={1}>
                        <Tooltip title="Remove option">
                            <IconButton
                                aria-label="remove product dimension option"
                                tabIndex={-1}
                                size="small"
                                onClick={() => onRemove(currentIndex)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </Box>
        </li>
    )
}

ProductDimensionOption.propTypes = {
    control: PropTypes.object.isRequired,
    channelProducts: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    currentIndex: PropTypes.number.isRequired,
    onAppend: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
}
