import { useMemo } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'

import { useCommonStyles } from '../../styles'

import { FormField } from '~/common/components'
import { useProductFormContext } from '~/products/hooks/useProductForm'
import { useProductPage } from '~/products/hooks/useProductPage'

const useStyles = makeStyles((theme) => ({
    regularColumn: {
        whiteSpace: 'nowrap',
        padding: '5px 10px',
    },
    stockInput: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}))

type Props = {
    index: number
}

export default function StockRecordRow({ index }: Props) {
    const classes = useStyles()
    const commonClasses = useCommonStyles()
    const { productInfo } = useProductPage()
    const { locations } = productInfo

    const name = `stock_units.${index}` as const
    const { control } = useProductFormContext()
    const isBundle = useWatch({ control, name: `${name}.stock_item.is_bundle`, exact: true })
    const sku = useWatch({ control, name: `${name}.sku`, exact: true })

    const { fields: stockRecords } = useFieldArray({
        control,
        name: `${name}.stock_records`,
        keyName: 'fieldId',
    })

    const inputProps = useMemo(
        () => ({ className: classes.stockInput, min: 0 }),
        [classes.stockInput]
    )

    return (
        <>
            <TableCell className={classes.regularColumn}>
                <Typography>{sku || interpolate(gettext('SKU %s'), [index + 1])}</Typography>
            </TableCell>

            {isBundle ? (
                <TableCell colSpan={locations.length}>
                    <Typography color="textSecondary">
                        {gettext('Cannot edit stock for this stock unit')}
                    </Typography>
                </TableCell>
            ) : (
                locations.map((location) => {
                    const stockRecordIndex = stockRecords.findIndex(
                        (record) => record.location.id === location.id
                    )

                    return (
                        <TableCell key={location.id} className={classes.regularColumn}>
                            <FormField
                                control={control}
                                name={`${name}.stock_records.${stockRecordIndex}.quantity`}
                                className={commonClasses.textField}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                inputProps={inputProps}
                            />
                        </TableCell>
                    )
                })
            )}
        </>
    )
}
