import { useMutation } from '@tanstack/react-query'
import Cookies from 'js-cookie'

import { useAppSelector } from '~/store'

type StockRecord = {
    id?: number
    location: {
        id: number
    }
    stock_item: {
        id: number
        sku: string
    }
    quantity: number
}

export function updateStockRecords(url: string, records: StockRecord[]) {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRFToken': Cookies.get('csrftoken') ?? '',
        },
        body: JSON.stringify(records),
    })
}

export function useUpdateStockRecordsMutation() {
    const updateStockRecordsAPIURL = useAppSelector(
        (state) => state.initial.endpoints.updateStockRecordsAPIURL
    )

    return useMutation({
        mutationFn: (records: StockRecord[]) =>
            updateStockRecords(updateStockRecordsAPIURL, records),
    })
}
