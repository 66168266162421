import { z } from 'zod'

export const MediaStatus = {
    READY_TO_UPLOAD: 'ready_to_upload',
    UPLOADING: 'uploading',
    UPLOADED: 'uploaded',
} as const

export const MediaType = {
    IMAGE: 'image',
    VIDEO: 'video',
    UPLOAD: 'upload-button', // It's internal type that is only used on FE side
} as const

export const Media = z.object({
    id: z.number().optional(),
    name: z.string().nullish(),
    file: z.string().nullish(),
    preview: z.string().nullish(),
    status: z.nativeEnum(MediaStatus).nullish(),
    media_type: z.nativeEnum(MediaType).nullish(),
    content_type: z.string().nullish(),
})

export type Media = z.infer<typeof Media>
export type MediaType = NonNullable<Media['media_type']>

export const AssignedMedia = z.object({
    id: z.number().optional(),
    media_id: z.number().nullish(),
    display_order: z.number(),
    url: z.string().nullish(),
    preview: z.string().nullish(),
    name: z.string().nullish(),
    status: z.nativeEnum(MediaStatus).nullish(),
    media_type: z.nativeEnum(MediaType).nullish(),
    channel_url: z.string().nullish(),
    content_type: z.string().nullish(),
})
export type AssignedMedia = z.infer<typeof AssignedMedia>

export const ExistingAssignedMedia = AssignedMedia.extend({
    id: z.number(),
})
