import { memo, useEffect, useRef } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

import { type Category } from './category-picker'

import { useAppSelector } from '~/store'
import { getTranslation } from '~/tools/utils'

const useStyles = makeStyles({
    spinnerContainer: {
        flex: '1 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '150px 0',
    },
    categoryList: {
        width: 250,
        flexShrink: 0,
        overflowY: 'auto',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
    categoryItem: {
        paddingLeft: '20px',
        cursor: 'pointer',
    },
})

export default memo(function CategoryPickerColumn({
    category,
    isLoading,
    isSelected,
    onSelectCategory,
}: {
    category: Category
    isLoading: boolean
    isSelected: (category: Category) => boolean
    onSelectCategory: (category: Category) => void
}) {
    const classes = useStyles()

    if (isLoading) {
        return (
            <div className={classes.spinnerContainer}>
                <CircularProgress />
            </div>
        )
    }

    if (!category.children) {
        return null
    }

    return (
        <List className={classes.categoryList}>
            {category.children.map((childCategory) => {
                return (
                    <CategoryPickerItem
                        key={childCategory.category_id}
                        category={childCategory}
                        selected={isSelected(childCategory)}
                        onSelectCategory={onSelectCategory}
                    />
                )
            })}
        </List>
    )
})

const CategoryPickerItem = memo(function CategoryPickerItem({
    category,
    selected,
    onSelectCategory,
}: {
    category: Category
    selected: boolean
    onSelectCategory: (category: Category) => void
}) {
    const language = useAppSelector(({ initial }) => initial.language)
    const classes = useStyles()
    const ref = useRef<HTMLLIElement>(null)

    useEffect(() => {
        if (selected && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
    }, [selected])

    return (
        <ListItem
            innerRef={ref}
            selected={selected}
            className={classes.categoryItem}
            onClick={() => onSelectCategory(category)}
        >
            <ListItemText primary={getTranslation(category, language, 'name')} />

            {!category.is_leaf && (
                <ListItemIcon>
                    <KeyboardArrowRightIcon />
                </ListItemIcon>
            )}
        </ListItem>
    )
})
