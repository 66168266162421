import grey from '@material-ui/core/colors/grey'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'
import moment from 'moment'

const useStyles = makeStyles({
    header: {
        minWidth: 300,
    },
    shopName: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    channelIconImg: {
        height: 20,
        width: 20,
        marginRight: 5,
    },
    greyText: {
        color: grey[500],
    },
    priceRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    shopDiscountHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
    },
    shopDiscountContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    border: {
        border: '1px solid ' + grey[400],
        borderRadius: 5,
        padding: '18px 24px',
        marginBottom: 10,
        width: 450,
    },
})

type ChannelProductDiscountSummary = {
    shop: {
        id: number
        shop_name: string
        channel: {
            id: string
            channel_square_icon_url: string
        }
    }
    data: {
        sku: string
        discount_price: number | string
    }[]
    has_more: boolean
}

type ProductDiscountSummary = {
    id: number
    name: string
    start_date?: string
    end_date?: string
    channel_product_discounts: ChannelProductDiscountSummary[]
}

type DiscountSummaryProps = {
    discount: ProductDiscountSummary
}

const DiscountSummary = ({ discount }: DiscountSummaryProps) => {
    const classes = useStyles()

    const startDate = discount.start_date ? moment(discount.start_date).format('D MMM') : null,
        endDate = discount.end_date ? moment(discount.end_date).format('D MMM') : null

    const channelProductDiscounts = discount.channel_product_discounts
    return (
        <>
            <div className={classes.border}>
                <div className={classes.header}>
                    <Typography variant="h6">{discount.name}</Typography>
                    {startDate && endDate && (
                        <Typography variant="subtitle1" className={classes.greyText}>
                            {startDate} - {endDate}
                        </Typography>
                    )}
                </div>

                <div>
                    {channelProductDiscounts.map((cpd) => (
                        <div key={cpd.shop.id}>
                            <div className={classes.shopDiscountHeader}>
                                <img
                                    className={classes.channelIconImg}
                                    src={cpd.shop.channel.channel_square_icon_url}
                                />
                                <Typography variant="body1">{cpd.shop.shop_name}</Typography>
                            </div>
                            <div className={classes.shopDiscountContent}>
                                <div className={classes.channelIconImg}></div>
                                <div style={{ width: '100%' }}>
                                    {cpd.data.map((discount) => (
                                        <div
                                            key={discount.sku}
                                            className={classNames(
                                                classes.greyText,
                                                classes.priceRow
                                            )}
                                        >
                                            <Typography variant="body2">{discount.sku}</Typography>
                                            <Typography variant="body2">
                                                {discount.discount_price}
                                            </Typography>
                                        </div>
                                    ))}
                                    {cpd.has_more && <div className={classes.greyText}>...</div>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

type DiscountSummaryListProps = {
    discounts: ProductDiscountSummary[]
}
const DiscountSummaryList = ({ discounts }: DiscountSummaryListProps) => {
    return (
        <>
            {discounts.map((discount, index) => (
                <DiscountSummary key={index} discount={discount} />
            ))}
        </>
    )
}

export default DiscountSummaryList
