// Sale Summary
import React from 'react'

import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'

import classNames from 'classnames'

import { currencySymbols } from '../../../constants'
import { commonStyles, formatAmount } from '../sale'

import PaymentMethodSelector from './payment-method-selector'

const summaryStyles = (theme) => ({
    ...commonStyles,
    summaryContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    noItemPlaceholder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
})

const saleHasItems = (sale) => sale.items_count > 0

let Summary = ({
    classes,
    sale,
    paymentMethodDetails,
    onPaymentMethodChange,
    onSaleSummaryChange,
}) => {
    const hasItems = saleHasItems(sale)

    return (
        <Paper
            style={{ height: '100%' }}
            className={classNames(classes.basicInfo, classes.section, classes.summaryContainer)}
        >
            <div className={classes.actionBar}>
                <Typography variant="subtitle1" className={classes.sectionTitle}>
                    {gettext('Summary')}
                </Typography>
            </div>

            {hasItems ? (
                <EditableSaleReceipt
                    sale={sale}
                    editing={sale.can_edit_items}
                    onSaleSummaryChange={onSaleSummaryChange}
                />
            ) : (
                <div className={classNames(classes.bodyText, classes.noItemPlaceholder)}>
                    {gettext('No items selected yet')}
                </div>
            )}

            <PaymentMethodSelector
                paymentMethodDetails={paymentMethodDetails}
                paymentMethodType={sale.payment_method_type}
                isDisabled={!sale.can_edit_items}
                onChange={onPaymentMethodChange}
            />
        </Paper>
    )
}

Summary = withStyles(summaryStyles)(Summary)

export default Summary

// Sale Receipt
const saleReceiptsStyles = (theme) => ({
    ...commonStyles,
    saleReceipt: {
        marginTop: 10,
    },
    receiptRow: {
        display: 'flex',
        '& .spacer': {
            flexGrow: 1,
            height: 15,
        },
        '& .dots': {
            borderBottom: '1px dotted #ccc',
            margin: '0 5px',
            flexGrow: 1,
            height: 15,
        },
    },
    totalRow: {
        borderTop: '1px solid #999',
        marginTop: 14,
        paddingTop: 4,
    },
    editableRow: {
        marginTop: 12,
        marginBottom: 12,
    },
    inputField: {
        width: 83,
        marginTop: -4,
        fontSize: '0.9rem',

        '& input': {
            textAlign: 'right',
            fontSize: '0.9rem',
            padding: '7px 2px',
        },
    },
})

const ReceiptRow = ({ classes, label, value, currency, ...props }) => {
    return (
        !!value && (
            <div className={classes.receiptRow} {...props}>
                <span>{label}</span>
                <span className="dots spacer">&nbsp;</span>
                <span>{formatAmount(value, currency)}</span>
            </div>
        )
    )
}

const EditableReceiptRow = ({ classes, label, value, name, onChange }) => {
    return (
        <div className={classNames(classes.receiptRow, classes.editableRow)}>
            <span>{label}</span>
            <span className="dots spacer">&nbsp;</span>
            <span>
                <TextField
                    size="small"
                    margin="dense"
                    type="number"
                    variant="outlined"
                    name={name}
                    value={value}
                    onChange={onChange}
                    className={classes.inputField}
                />
            </span>
        </div>
    )
}

class EditableSaleReceiptWithoutStyle extends React.Component {
    constructor(props) {
        super(props)

        const { sale } = props
        let discount = sale.discount
        let shipping_fee = sale.shipping_fee
        let cod_fee = sale.cod_fee

        if (sale.discounts?.length) {
            discount = sale.discounts.reduce((total, item) => total + Number(item.amount), 0)
        }

        if (sale.shipping_fees?.length) {
            shipping_fee = sale.shipping_fees.reduce((total, item) => total + Number(item.total), 0)
        }

        if (sale.fees?.length) {
            cod_fee = sale.fees.reduce(
                (total, item) => (item.fee_type === 'cod' ? total + Number(item.total) : total),
                0
            )
        }

        this.state = {
            data: {
                discount_percent: '',
                discount: discount && Number(discount),
                shipping_fee: shipping_fee && Number(shipping_fee),
                cod_fee: cod_fee && Number(cod_fee),
            },
            visible: {
                discount: !!Number(discount),
                cod_fee: !!Number(cod_fee),
            },
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target
        const updataData = { [name]: value ? Number(value) : null }
        if (name === 'discount') {
            updataData.discount_percent = ''
        } else if (name === 'discount_percent') {
            updataData.discount = null
        }

        this.setState(({ data }) => ({ data: { ...data, ...updataData } }), this.triggerChange)
    }

    showField = (field) => {
        this.setState(({ visible }) => ({ visible: { ...visible, [field]: true } }))
    }

    getCalculatedValues = () => {
        let { discount, discount_percent, shipping_fee, cod_fee } = this.state.data
        const { sale, editing } = this.props

        // Exclude sale_line_item in calculating sub_total when the item is canceled but order is not canceled
        const sub_total = sale.sale_line_items
            .filter(
                (sale_line_item) =>
                    !(sale.status !== 'canceled' && sale_line_item.status === 'canceled')
            )
            .reduce((total, item) => {
                return (
                    total +
                    Number(item.unit_price) * Number(item.num_sold) -
                    Number(item.item_level_discount || 0)
                )
            }, 0)

        if (discount_percent) {
            discount = (discount_percent * sub_total) / 100
        }

        cod_fee = cod_fee || 0
        discount = discount || 0
        const vat = sale.total_taxes ? Number(sale.total_taxes) : 0
        const total = editing ? sub_total + vat + shipping_fee + cod_fee - discount : sale.total
        const total_without_vat = total - vat

        return {
            discount,
            discount_percent,
            shipping_fee,
            cod_fee,
            sub_total,
            vat,
            total,
            total_without_vat,
        }
    }

    getDiscountBreakdownDetail = () => {
        const { sale } = this.props
        let total_price_discounts = 0
        let total_channel_voucher_discounts = 0
        let total_channel_coin_discounts = 0
        let total_channel_shipping_fee_discounts = 0
        let total_seller_voucher_discounts = 0
        let total_seller_shipping_fee_discounts = 0
        let total_channel_discount_price = 0
        let total_other_discounts = 0

        sale?.discounts?.forEach((d) => {
            switch (d.discount_type) {
                case 'discount_price': {
                    total_price_discounts += Number(d.amount)
                    break
                }
                case 'channel_voucher': {
                    total_channel_voucher_discounts += Number(d.amount)
                    break
                }
                case 'channel_coin': {
                    total_channel_coin_discounts += Number(d.amount)
                    break
                }
                case 'channel_shipping_fee': {
                    total_channel_shipping_fee_discounts += Number(d.amount)
                    break
                }
                case 'channel_seller_voucher': {
                    total_seller_voucher_discounts += Number(d.amount)
                    break
                }
                case 'channel_seller_shipping_fee': {
                    total_seller_shipping_fee_discounts += Number(d.amount)
                    break
                }
                case 'total_channel_discount_price': {
                    total_channel_discount_price += Number(d.amount)
                    break
                }
                default: {
                    total_other_discounts += Number(d.amount)
                }
            }
        })

        return {
            total_price_discounts,
            total_channel_voucher_discounts,
            total_channel_coin_discounts,
            total_channel_shipping_fee_discounts,
            total_seller_voucher_discounts,
            total_seller_shipping_fee_discounts,
            total_channel_discount_price,
            total_other_discounts,
        }
    }

    triggerChange = () => {
        this.props.onSaleSummaryChange(this.getCalculatedValues())
    }

    render() {
        const { classes, sale, editing } = this.props
        const { currency, items_count, payment_method_type } = sale
        const {
            sub_total,
            discount,
            discount_percent,
            shipping_fee,
            cod_fee,
            total,
            vat,
            total_without_vat,
        } = this.getCalculatedValues()

        const {
            total_price_discounts,
            total_channel_voucher_discounts,
            total_channel_coin_discounts,
            total_channel_shipping_fee_discounts,
            total_seller_voucher_discounts,
            total_seller_shipping_fee_discounts,
            total_channel_discount_price,
            total_other_discounts,
        } = this.getDiscountBreakdownDetail()

        const discountFields = [
            {
                value: total_price_discounts,
                label: gettext('Price discounts'),
            },
            {
                value: total_channel_voucher_discounts,
                label: gettext('Channel Vouchers'),
            },
            {
                value: total_channel_coin_discounts,
                label: gettext('Coin discounts'),
            },
            {
                value: total_seller_voucher_discounts,
                label: gettext('Seller Vouchers'),
            },
            {
                value: total_channel_shipping_fee_discounts,
                label: gettext('Channel Shipping Fee'),
            },
            {
                value: total_seller_shipping_fee_discounts,
                label: gettext('Seller Shipping Fee'),
            },
            {
                value: total_channel_discount_price,
                label: gettext('Channel price discount'),
            },
            {
                value: total_other_discounts,
                label: gettext('Other discounts'),
            },
        ]
        const { visible } = this.state

        return (
            <div className={classNames(classes.saleReceipt, classes.bodyText)}>
                <ReceiptRow
                    classes={classes}
                    currency={currency}
                    label={`${items_count} ${ngettext('item', 'items', items_count)}`}
                    value={sub_total}
                />

                {editing ? (
                    <React.Fragment>
                        <EditableReceiptRow
                            classes={classes}
                            label={gettext('Shipping fee')}
                            name="shipping_fee"
                            value={shipping_fee || ''}
                            onChange={this.handleChange}
                        />

                        {visible.discount && (
                            <div className={classNames(classes.receiptRow, classes.editableRow)}>
                                <span>{gettext('Discount')}</span>
                                <span className="dots spacer">&nbsp;</span>
                                <span>
                                    <OutlinedInput
                                        size="small"
                                        margin="dense"
                                        type="number"
                                        name="discount_percent"
                                        variant="outlined"
                                        endAdornment={
                                            <InputAdornment position="end">%</InputAdornment>
                                        }
                                        value={discount_percent || ''}
                                        onChange={this.handleChange}
                                        className={classes.inputField}
                                        style={{ paddingRight: 5, width: 70 }}
                                    />

                                    <OutlinedInput
                                        size="small"
                                        margin="dense"
                                        type="number"
                                        name="discount"
                                        variant="outlined"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                {currencySymbols[currency]}
                                            </InputAdornment>
                                        }
                                        value={discount || ''}
                                        onChange={this.handleChange}
                                        className={classes.inputField}
                                        style={{ marginLeft: 2, paddingLeft: 5 }}
                                    />
                                </span>
                            </div>
                        )}

                        {(visible.cod_fee || payment_method_type === 'cod') && (
                            <EditableReceiptRow
                                classes={classes}
                                label={gettext('COD fee')}
                                name="cod_fee"
                                value={cod_fee}
                                onChange={this.handleChange}
                            />
                        )}

                        <div style={{ textAlign: 'right' }}>
                            {!visible.discount && (
                                <Button
                                    size="small"
                                    color="secondary"
                                    onClick={() => this.showField('discount')}
                                >
                                    <AddIcon style={{ fontSize: '1.2rem' }} />
                                    {gettext('Discount')}
                                </Button>
                            )}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <ReceiptRow
                            classes={classes}
                            currency={currency}
                            label={gettext('Discount')}
                            value={-discount}
                        />
                        <div style={{ paddingLeft: 20 }}>
                            {!!discount &&
                                discountFields
                                    ?.filter((field) => field.value && field.value > 0)
                                    ?.map((field, index) => (
                                        <ReceiptRow
                                            key={index}
                                            classes={classes}
                                            currency={currency}
                                            label={field.label}
                                            value={-field.value}
                                        />
                                    ))}
                        </div>
                        <ReceiptRow
                            classes={classes}
                            currency={currency}
                            label={gettext('Shipping fee')}
                            value={shipping_fee}
                        />
                        <ReceiptRow
                            classes={classes}
                            currency={currency}
                            label={gettext('COD fee')}
                            value={cod_fee}
                        />
                    </React.Fragment>
                )}
                <ReceiptRow
                    classes={classes}
                    currency={currency}
                    label={gettext('Total')}
                    value={total}
                    className={classNames(classes.receiptRow, classes.totalRow)}
                />
                {vat > 0 && (
                    <div>
                        <ReceiptRow
                            classes={classes}
                            currency={currency}
                            label={gettext('VAT')}
                            value={vat}
                            className={classNames(classes.receiptRow)}
                        />
                        <ReceiptRow
                            classes={classes}
                            currency={currency}
                            label={gettext('Total without VAT')}
                            value={total_without_vat}
                            className={classNames(classes.receiptRow)}
                        />
                    </div>
                )}
            </div>
        )
    }
}

const EditableSaleReceipt = withStyles(saleReceiptsStyles)(EditableSaleReceiptWithoutStyle)
