import { useMemo } from 'react'
import { useFieldArray, useFormState, useWatch } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'

import { type UserLocation } from '~/common/schemas/location'
import { useProductFormContext } from '~/products/hooks/useProductForm'
import { useProductPage } from '~/products/hooks/useProductPage'

const useStyles = makeStyles((theme) => ({
    regularColumn: {
        whiteSpace: 'nowrap',
        padding: '5px 10px',
        borderBottom: 'none',
    },
    cell: {
        transition: 'transform 0.10s ease-in-out',
        height: 30,
        cursor: 'pointer',
        borderBottom: 'none',

        '&:hover': {
            transform: 'scale3d(1.03, 1.03, 1)',
            boxShadow: '0 2px 2px rgba(0,0,0,0.35)',
        },

        '&:only-of-type': {
            cursor: 'not-allowed',
        },
    },
    cellInactive: {
        backgroundColor: theme.palette.grey[400],
    },
    cellSelected: {
        backgroundColor: theme.palette.primary.main,
    },
}))

type Props = {
    index: number
}

export default function FulfillmentRow({ index }: Props) {
    const name = `stock_units.${index}` as const
    const classes = useStyles()
    const { productInfo } = useProductPage()
    const { control, register, trigger } = useProductFormContext()
    const [sku, isBundle] = useWatch({
        control,
        name: [`${name}.sku`, `${name}.stock_item.is_bundle`],
    })

    const {
        fields: fulfillmentLocations,
        remove,
        append,
    } = useFieldArray({
        control,
        name: `${name}.fulfillment_locations`,
        keyName: 'fieldId',
        rules: {
            validate: {
                incompleteFulfillment: (value, form) => {
                    const stockItem = form.stock_units[index]?.stock_item
                    return (
                        stockItem?.is_bundle ||
                        value.length > 0 ||
                        gettext('You must select at least one fulfillment location')
                    )
                },
            },
        },
    })

    const { ref } = register(`${name}.fulfillment_locations`)

    const { errors } = useFormState({
        control,
        name: `stock_units.${index}.fulfillment_locations`,
        exact: true,
    })

    const fulfillmentLocationsIdSet = useMemo(
        () => new Set(fulfillmentLocations.map((location) => location.id)),
        [fulfillmentLocations]
    )

    const fulfillmentLocationMessage =
        errors.stock_units?.[index]?.fulfillment_locations?.root?.message

    const { locations } = productInfo
    const hasOnlyOneLocation = locations.length === 1

    function handleToggleLocation(selected: boolean, location: UserLocation) {
        if (hasOnlyOneLocation) {
            return
        }

        if (selected) {
            const existingLocationIndex = fulfillmentLocations.findIndex(
                (fl) => fl.location_id === location.location_id
            )
            remove(existingLocationIndex)
        } else {
            append(location)
        }

        void trigger(name)
    }

    return (
        <>
            <TableCell className={classes.regularColumn}>
                <Tooltip title={fulfillmentLocationMessage ?? ''}>
                    <Typography
                        ref={ref}
                        color={fulfillmentLocationMessage ? 'error' : 'inherit'}
                        style={{
                            position: 'relative',
                            cursor: fulfillmentLocationMessage ? 'help' : 'inherit',
                            fontWeight: fulfillmentLocationMessage ? 600 : 'inherit',
                        }}
                    >
                        {sku || interpolate(gettext('SKU %s'), [index + 1])}
                    </Typography>
                </Tooltip>
            </TableCell>
            {isBundle ? (
                <TableCell colSpan={locations.length}>
                    <Typography color="textSecondary">
                        {gettext('Cannot select fulfillment locations for bundles')}
                    </Typography>
                </TableCell>
            ) : (
                locations.map((location) => {
                    const isSelected =
                        fulfillmentLocationsIdSet.has(location.id) &&
                        location.available_for_fulfillment

                    return (
                        <Tooltip
                            key={location.id}
                            title={
                                hasOnlyOneLocation
                                    ? gettext('SKU must have at least one location')
                                    : ''
                            }
                        >
                            <TableCell
                                className={classNames(
                                    classes.cell,
                                    isSelected ? classes.cellSelected : classes.cellInactive
                                )}
                                onClick={() => handleToggleLocation(isSelected, location)}
                            />
                        </Tooltip>
                    )
                })
            )}
        </>
    )
}
