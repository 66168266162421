import { useMutation } from '@tanstack/react-query'
import Cookies from 'js-cookie'

import { useAppSelector } from '~/store'

type StockItem = {
    id: number
    name: string
}

export function updateStockItems(url: string, stockItems: StockItem[]) {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRFToken': Cookies.get('csrftoken') ?? '',
        },
        body: JSON.stringify(stockItems),
    })
}

export function useUpdateStockItemsMutation() {
    const bulkUpdateStockItemsAPIURL = useAppSelector(
        (state) => state.initial.endpoints.bulkUpdateStockItemsAPIURL
    )

    return useMutation({
        mutationFn: (records: StockItem[]) =>
            updateStockItems(bulkUpdateStockItemsAPIURL, records),
    })
}
