// Sale Customer Editor

import { useState, useRef, useEffect, useCallback } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import SearchIcon from '@material-ui/icons/Search'

import { getTranslation } from '../../../tools/utils'

const stockUnitsWithProductToProductsWithStockUnits = (stockUnits) =>
    Object.values(
        stockUnits.reduce((products, { product, ...stockUnit }) => {
            const stockUnitToAdd = { ...stockUnit, product: { ...product } }
            if (products[product.id]) {
                products[product.id]['stockUnits'].push(stockUnitToAdd)
            } else {
                products[product.id] = { ...product, stockUnits: [stockUnitToAdd] }
            }
            return products
        }, {})
    )

const styles = (theme) => ({
    searchingIndicator: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 250,
    },
    dialogContent: {
        minHeight: 350,
    },
    dialogActions: {
        paddingRight: 24,
        paddingLeft: 24,
        paddingBottom: 15,
        marginTop: 0,
    },
    productList: {
        maxHeight: 300,
        overflow: 'scroll',
        listStyle: 'none',
        padding: 0,
    },
    productListItem: {
        marginBottom: 24,
    },
    productDetail: {
        display: 'flex',
    },
    productImage: {
        width: 40,
        marginRight: 10,
    },
    productName: {
        color: theme.palette.grey[700],
    },
    stockItemButton: {
        textTransform: 'none',
        width: '100%',
        fontWeight: 'normal',
    },
})

function useRefWithCallback(onMount) {
    const nodeRef = useRef(null)

    const setRef = useCallback(
        (node) => {
            nodeRef.current = node

            if (nodeRef.current) {
                onMount(nodeRef.current)
            }
        },
        [onMount]
    )

    return setRef
}

const SaleItemSelector = ({
    handleSelected,
    handleClose,
    open,
    classes,
    language,
    sale,
    stockUnitsForShopAPIURL,
    stockUnitsForStoreAPIURL,
    searchQuery,
    disabledStockUnits,
    disabledStockUnitText,
    onSearchFieldChange,
}) => {
    // const [visible, setVisible] = useState(open)
    const [foundStockItems, setFoundStockItems] = useState([])
    const [isSearching, setIsSearching] = useState(false)

    const returnSelectedItem = (item) => {
        handleSelected({ ...item })
    }

    const getSearchURL = useCallback(
        (searchText) =>
            stockUnitsForStoreAPIURL.replace('store_pk', sale.shop.store_id) +
            '?search=' +
            searchText, // search SKUs from current store
        [stockUnitsForStoreAPIURL, sale?.shop?.store_id]
    )

    const searchStockUnits = useCallback(
        (text) => {
            if (text !== '') {
                setIsSearching(true)
                fetch(getSearchURL(text))
                    .then((response) => response.json())
                    .then((response) => {
                        const results = stockUnitsWithProductToProductsWithStockUnits(
                            response.results
                        )
                        setFoundStockItems(results)
                    })
                    .finally(() => {
                        setIsSearching(false)
                    })
            } else {
                setFoundStockItems([])
            }
        },
        [getSearchURL]
    )

    const searchInputHandler = (e) => {
        onSearchFieldChange(e.target.value)
    }

    const inputRef = useRefWithCallback((input) => {
        input.focus()

        const end = input.value.length
        input.setSelectionRange(end, end)
    })

    useEffect(() => {
        if (open) {
            setFoundStockItems([])
        }
    }, [open])

    useEffect(() => {
        let timeoutId

        const delayedSearch = () => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(() => searchStockUnits(searchQuery), 1000)
        }

        if (searchQuery) {
            delayedSearch()
        }

        return () => clearTimeout(timeoutId)
    }, [searchStockUnits, searchQuery])

    return (
        <Dialog
            className={classes.dialog}
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>{gettext('Select Items')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            onInput={searchInputHandler}
                            placeholder={gettext('Enter product name or SKU to search')}
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            fullWidth
                            inputRef={inputRef}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {isSearching ? (
                            <div className={classes.searchingIndicator}>
                                <CircularProgress size={25} />
                            </div>
                        ) : foundStockItems.length > 0 ? (
                            <div className={classes.productList}>
                                {foundStockItems.map((p, i) => (
                                    <div key={i} className={classes.productListItem}>
                                        <StockUnitProductGroup
                                            classes={classes}
                                            language={language}
                                            product={p}
                                            onStockUnitClick={returnSelectedItem}
                                            disabledStockUnits={disabledStockUnits}
                                            disabledStockUnitText={
                                                disabledStockUnitText ||
                                                gettext('This stock unit is already in the sale')
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <span>{gettext('No results')}</span>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button color="secondary" onClick={handleClose}>
                    {gettext('Cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(styles)(SaleItemSelector)

const StockUnitProductGroup = ({
    classes,
    product,
    language,
    onStockUnitClick,
    disabledStockUnits,
    disabledStockUnitText,
}) => {
    const productName = getTranslation(product, language, 'name')

    return (
        <Grid item container xs={12} spacing={1}>
            <Grid className={classes.productDetail} item xs={12}>
                <img className={classes.productImage} src={product.cover_image_url} height={40} />
                <span className={classes.productName}>{productName}</span>
            </Grid>
            {product.stockUnits.map((su, i) => {
                const isDisabled = disabledStockUnits.includes(su.id)
                return (
                    <Grid item xs={12} sm={6} key={i}>
                        <Tooltip
                            arrow
                            enterDelay={700}
                            title={isDisabled ? disabledStockUnitText : ''}
                        >
                            <span>
                                <Button
                                    className={classes.stockItemButton}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => onStockUnitClick(su)}
                                    disabled={isDisabled}
                                    size="small"
                                >
                                    {su.sku}
                                    {su.name && ` - ${su.name}`}
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                )
            })}
        </Grid>
    )
}
