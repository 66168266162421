import { grey } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import SearchIcon from '@material-ui/icons/Search'

import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
    searchIcon: {
        color: grey[500],
    },
    clearSearchIcon: {
        color: grey[500],
    },
    searchField: {
        flex: '1 0 auto',
    },
}))

export default function FormSearchField({ value, onChange, onClear }) {
    const classes = useStyles()

    return (
        <TextField
            className={classes.searchField}
            placeholder={gettext('Search')}
            variant="outlined"
            margin="dense"
            size="small"
            value={value}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={onClear}>
                            <CancelIcon className={classes.clearSearchIcon} />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            onChange={onChange}
        />
    )
}

FormSearchField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
}
