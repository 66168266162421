import { type ReactNode, createContext, useContext } from 'react'

import type { InferResponseBody, ApiContract } from '~/api'
import type { UserLocation } from '~/common/schemas/location'
import type { ChannelStockUnitDiscount, Product, ProductDiscount } from '~/common/schemas/product'
import type { StockRecord } from '~/common/schemas/stock-record'
import type { StoreWithShops } from '~/store'

type ProductInfo = {
    product: Product
    store: StoreWithShops
    locations: UserLocation[]
    stockRecords: StockRecord[]
    productDiscounts: ProductDiscount[]
    activeChannelStockUnitDiscounts: ChannelStockUnitDiscount[]
    channelAttributeValues: Record<
        number,
        InferResponseBody<ApiContract['channels']['attributeValues']>
    >
}

type ProductPageContext =
    | { isNewProduct: true; productInfo: Pick<ProductInfo, 'store' | 'locations'> }
    | { isNewProduct: false; productInfo: ProductInfo }

export const ProductPageContext = createContext<ProductPageContext | null>(null)

type BaseProps = {
    store: StoreWithShops
    locations: UserLocation[]
    children: ReactNode
    isNewProduct: boolean
}

type NewProps = BaseProps & {
    isNewProduct: true
}

type UpdateProps = BaseProps & {
    isNewProduct: false
    product: Product
    stockRecords: StockRecord[]
    productDiscounts: ProductDiscount[]
    activeChannelStockUnitDiscounts: ChannelStockUnitDiscount[]
    channelAttributeValues: Record<
        number,
        InferResponseBody<ApiContract['channels']['attributeValues']>
    >
}

export function ProductPageProvider(props: NewProps | UpdateProps) {
    if (props.isNewProduct) {
        return (
            <ProductPageContext.Provider
                value={{
                    isNewProduct: props.isNewProduct,
                    productInfo: {
                        store: props.store,
                        locations: props.locations,
                    },
                }}
            >
                {props.children}
            </ProductPageContext.Provider>
        )
    }

    const { isNewProduct, ...productInfo } = props
    return (
        <ProductPageContext.Provider value={{ isNewProduct, productInfo }}>
            {props.children}
        </ProductPageContext.Provider>
    )
}
export function useProductPage() {
    const context = useContext(ProductPageContext)
    if (!context) {
        throw new Error('useProductPage must be used within a ProductPageProvider')
    }

    return context
}
