import React from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import classNames from 'classnames'
import Cookie from 'js-cookie'

const styles = (theme) => ({
    signUpPanel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 48px)',
    },
    signUpCard: {
        paddingLeft: 40,
        paddingRight: 40,
        width: 500,
        display: 'flex',
        flexDirection: 'column',
    },
    signUpTitle: {
        borderBottom: '1px solid ' + grey[200],
        marginLeft: -40,
        marginRight: -40,
        paddingLeft: 40,
        paddingTop: 20,
        paddingBottom: 12,
    },
    logo: {
        width: 120,
    },
    signupMessage: {
        marginTop: 40,
    },
    passwordMessage: {
        marginTop: 20,
        marginBottom: 20,
    },
    field: {
        marginBottom: 15,
    },
    email: {
        marginTop: 20,
        marginBottom: 10,
    },
    password: {},
    passwordError: {},
    submitContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        justifyContent: 'flex-end',
        borderTop: '1px solid ' + grey[200],
        marginLeft: -40,
        marginRight: -40,
        paddingTop: 20,
        paddingRight: 40,
        paddingBottom: 20,
    },
    signUpProgress: {
        marginRight: 15,
    },
    submitButton: {
        width: 100,
        color: 'white',
    },
    checkIcon: {
        margin: '30px auto',
        color: theme.palette.primary.main,
        fontSize: 70,
    },
    checkEmailMessage: {
        margin: '0 auto',
    },
})

class SignUp extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            emailError: '',
            password: '',
            confirmPassword: '',
            passwordError: '',
            errorMessage: '',
            signingUp: false,
            userEmailNotConfirmed: false,
            confirmationEmailSent: false,
            confirmationEmailResent: false,
        }

        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.passwordsMatch = this.passwordsMatch.bind(this)
        this.handleResendConfirmationEmail = this.handleResendConfirmationEmail.bind(this)
    }

    handleSubmit() {
        const { signUpAPIURL } = this.props

        if (!this.passwordsMatch()) {
            return
        }

        const credentials = {
            email: this.state.email,
            password: this.state.password,
        }

        this.setState(
            { signingUp: true, emailError: '', passwordError: '', errorMessage: '' },
            () => {
                fetch(signUpAPIURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'X-CSRFToken': Cookie.get('csrftoken'),
                    },
                    body: JSON.stringify(credentials),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.id) {
                            this.setState({ confirmationEmailSent: true })
                        } else {
                            let newState = {}
                            if ('non_field_errors' in data) {
                                newState = { errorMessage: data.non_field_errors[0] }
                            }

                            if ('email' in data) {
                                newState = { ...newState, emailError: data.email.message }

                                if (data.email.code == 'user_email_not_confirmed') {
                                    newState.userEmailNotConfirmed = true
                                }
                            }

                            if ('password' in data) {
                                newState = { ...newState, passwordError: data.password[0] }
                            }

                            this.setState(newState)
                        }
                    })

                    .finally(() => {
                        this.setState({ signingUp: false })
                    })
            }
        )
    }

    handleFieldChange(field) {
        return (event) => {
            this.setState({ [field]: event.target.value })
        }
    }

    handleClickShowPassword() {
        this.setState({ showPassword: !this.state.showPassword })
    }

    passwordsMatch() {
        if (this.state.password != this.state.confirmPassword) {
            this.setState({ passwordError: gettext('Passwords do not match.') })
            return false
        } else {
            return true
        }
    }

    handleResendConfirmationEmail() {
        const { sendConfirmationEmailAPIURL } = this.props

        fetch(sendConfirmationEmailAPIURL + '?email=' + this.state.email, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRFToken': Cookie.get('csrftoken'),
            },
        }).then((response) => {
            this.setState({ confirmationEmailResent: true })
        })
    }

    render() {
        const { classes, staticRoot } = this.props

        return (
            <div className={classes.signUpPanel}>
                <Paper className={classes.signUpCard}>
                    <div className={classes.signUpTitle}>
                        <img className={classes.logo} src={staticRoot + 'landing/oakra.png'} />
                    </div>
                    {this.state.confirmationEmailSent && (
                        <React.Fragment>
                            <CheckCircleIcon className={classes.checkIcon} />
                            <Typography className={classes.checkEmailMessage}>
                                {gettext(
                                    'Check your email for a link to continue the signup process.'
                                )}
                            </Typography>
                        </React.Fragment>
                    )}
                    {!this.state.confirmationEmailSent && (
                        <React.Fragment>
                            <Typography className={classes.signupMessage} variant="body2">
                                {gettext(
                                    'Getting started with Oakra is easy. Start by entering your email address.'
                                )}
                            </Typography>
                            <TextField
                                className={classNames(classes.field, classes.email)}
                                variant="outlined"
                                label={gettext('Email')}
                                value={this.state.email}
                                onChange={this.handleFieldChange('email')}
                                error={Boolean(this.state.emailError)}
                                helperText={this.state.emailError}
                            />
                            {this.state.userEmailNotConfirmed && (
                                <div className={classes.resendConfirmationSection}>
                                    <Button
                                        className={classes.resendButton}
                                        disabled={this.state.confirmationEmailResent}
                                        onClick={this.handleResendConfirmationEmail}
                                    >
                                        {gettext('Resend confirmation email')}
                                    </Button>
                                </div>
                            )}
                            {!this.state.userEmailNotConfirmed && (
                                <React.Fragment>
                                    <Typography className={classes.passwordMessage} variant="body2">
                                        {gettext(
                                            'Now enter a password at least 8 characters long, with letters, numbers, and at least one symbol ()[]{}|~!@#$%^&*_-+=;:,<>./?'
                                        )}
                                    </Typography>
                                    <TextField
                                        className={classNames(classes.field, classes.password)}
                                        variant="outlined"
                                        label={gettext('Password')}
                                        type="password"
                                        value={this.state.password}
                                        onChange={this.handleFieldChange('password')}
                                        error={Boolean(this.state.passwordError)}
                                    />
                                    <TextField
                                        className={classNames(classes.field, classes.password)}
                                        variant="outlined"
                                        label={gettext('Confirm password')}
                                        type="password"
                                        value={this.state.confirmPassword}
                                        onChange={this.handleFieldChange('confirmPassword')}
                                        error={Boolean(this.state.passwordError)}
                                    />
                                    {this.state.passwordError && (
                                        <Typography
                                            variant="body2"
                                            className={classes.passwordError}
                                            color="error"
                                        >
                                            {this.state.passwordError}
                                        </Typography>
                                    )}
                                    {this.state.errorMessage && (
                                        <Typography
                                            variant="body2"
                                            className={classes.errorMessage}
                                            color="error"
                                        >
                                            {this.state.errorMessage}
                                        </Typography>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}

                    <div className={classes.submitContainer}>
                        {this.state.loggingIn && (
                            <CircularProgress className={classes.signUpProgress} size={25} />
                        )}
                        {!this.state.userEmailNotConfirmed && !this.state.confirmationEmailSent && (
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.submitButton}
                                disabled={this.state.loggingIn}
                                onClick={this.handleSubmit}
                            >
                                {gettext('Sign up')}
                            </Button>
                        )}
                    </div>
                </Paper>
            </div>
        )
    }
}

export default withStyles(styles)(SignUp)
