import { connect } from 'react-redux'

import Sales from '../components/sales'

const mapStateToProps = (state) => {
    const getAccounts = () => {}

    return {
        salesAPIURL: state.initial.endpoints.salesAPIURL,
        salesPKsAPIURL: state.initial.endpoints.salesPKsAPIURL,
        exportSalesURL: state.initial.endpoints.exportSalesURL,
        salesFilterValuesAPIURL: state.initial.endpoints.salesFilterValuesAPIURL,
        generateOrderDocumentURL: state.initial.endpoints.generateOrderDocumentURL,
        saleDocumentURL: state.initial.endpoints.saleDocumentURL,
        saleStatuses: state.initial.saleStatuses,
        stores: state.stores,
        accountOwnerID: state.initial.accountOwner.id,
        language: state.initial.language,
        staticRoot: state.initial.staticRoot,
        saleCSVFormats: state.initial.saleCSVFormats,
    }
}

export default connect(mapStateToProps)(Sales)
