import { useState } from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import LinearProgress from '@material-ui/core/LinearProgress'
import Modal from '@material-ui/core/Modal'
import { makeStyles, type Theme } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DeleteIcon from '@material-ui/icons/Delete'

import classNames from 'classnames'

import { type Media, MediaStatus, MediaType } from '~/common/schemas/media'
import { useAppSelector } from '~/store'

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        minHeight: 600,
    },
    mediaWrapper: {
        cursor: 'pointer',
        position: 'relative',
        display: 'inline-block',
    },
    mediaContainer: {
        border: '1px solid ' + theme.palette.grey[400],
        height: 150,
        width: 150,
    },
    selectedOverlay: {
        border: '4px solid ' + theme.palette.secondary.main,
        background: 'rgba(255, 255, 255, 0.3)',
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: 4,
    },
    hoverOverlay: {
        // border: '4px solid ' + theme.palette.secondary.main,
        background: 'rgba(255, 255, 255, 0.3)',
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: 4,
        opacity: 0,
        '&:hover': {
            opacity: 1,
        },
    },
    checkbox: {
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1),
        zIndex: 5,
    },
    expandIcon: {
        position: 'absolute',
        top: theme.spacing(1.5),
        right: theme.spacing(0.25),
        zIndex: 10,
    },
    progress: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalTopBar: {
        borderBottom: '1px solid ' + theme.palette.grey[400],
        background: 'white',
        height: 50,
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    deleteIconInModal: {
        right: theme.spacing(1),
        position: 'absolute',
    },
    deleteIconOnMedia: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        zIndex: 10,
    },
}))

export type MediaPreviewProps = {
    media: Media
    hideSelect?: boolean
    isExpandable?: boolean
    clickToExpand?: boolean
    onSelect?: (media: Media) => void
    onUnSelect?: (media: Media) => void
    onDelete?: (media: Media) => void
    className?: any
}

function ExpandIcon({ className, ...props }) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                transform: 'rotate(45deg)',
                width: 'fit-content',
            }}
            className={className}
            {...props}
        >
            <ArrowBackIcon style={{ width: '0.8em' }} />
            <ArrowForwardIcon style={{ width: '0.8em' }} />
        </div>
    )
}

function MediaPreview({
    media,
    hideSelect,
    onSelect,
    onUnSelect,
    onDelete,
    isExpandable,
    clickToExpand,
    className,
}: MediaPreviewProps) {
    const classes = useStyles()
    const staticFilesDomain = useAppSelector((state) => state.initial.staticFilesDomain)
    const [showModal, setShowModal] = useState(false)
    const [isSelected, setIsSelected] = useState(false)
    const handleExpandImage = (e: { stopPropagation: () => void }) => {
        setShowModal(true)
        e.stopPropagation()
    }
    const toggleSelectImage = () => {
        if (media.status === MediaStatus.UPLOADED) {
            if (isSelected) {
                onUnSelect?.(media)
            } else {
                onSelect?.(media)
            }
            setIsSelected(!isSelected)
        }
    }
    const expandImageOnClick = clickToExpand && isExpandable
    const showExpandIconOnMedia = isExpandable && !clickToExpand
    const showDeleteIconOnMedia = !showExpandIconOnMedia
    const showDeleteIconOnImageModal = showExpandIconOnMedia

    let handleImageClick
    if (expandImageOnClick) {
        handleImageClick = handleExpandImage
    } else if (!hideSelect) {
        handleImageClick = toggleSelectImage
    }

    return (
        <div>
            <div className={classes.mediaWrapper} onClick={handleImageClick}>
                {media.status === MediaStatus.UPLOADED && (
                    <div className={isSelected ? classes.selectedOverlay : classes.hoverOverlay}>
                        {!isSelected && (
                            <>
                                {showExpandIconOnMedia && (
                                    <ExpandIcon
                                        className={classes.expandIcon}
                                        onClick={(e: { stopPropagation: () => void }) => {
                                            setShowModal(true)
                                            e.stopPropagation()
                                        }}
                                    />
                                )}
                                {showDeleteIconOnMedia && (
                                    <DeleteIcon
                                        className={classes.deleteIconOnMedia}
                                        onClick={() => {
                                            onDelete?.(media)
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}
                <div className={classNames(classes.mediaContainer, className)}>
                    {(media.media_type === MediaType.IMAGE && (
                        <img
                            alt={`Media ${media.name}`}
                            style={{ width: '100%', height: '100%' }}
                            src={media.preview || staticFilesDomain + media.file}
                        />
                    )) ||
                        (media.media_type === MediaType.VIDEO && (
                            <video controls={false} style={{ width: '100%', height: '100%' }}>
                                <source
                                    src={media.preview || staticFilesDomain + media.file}
                                    type="video/mp4"
                                />
                            </video>
                        ))}
                </div>
                {media.status === MediaStatus.UPLOADING && (
                    <LinearProgress className={classes.progress} color="secondary" />
                )}
            </div>
            {isExpandable && (
                <Modal
                    open={showModal}
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500 }}
                    onClose={() => setShowModal(false)}
                >
                    <Fade in={showModal}>
                        <div className={classes.paper}>
                            {showDeleteIconOnImageModal && (
                                <div className={classes.modalTopBar}>
                                    <Button
                                        variant="text"
                                        className={classes.deleteIconInModal}
                                        onClick={() => {
                                            onDelete?.(media)
                                            setShowModal(false)
                                        }}
                                    >
                                        <DeleteIcon
                                            style={{
                                                width: 24,
                                                height: 24,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        />
                                    </Button>
                                </div>
                            )}
                            {(media.media_type === MediaType.IMAGE && (
                                <img
                                    alt={`Media modal ${media.name}`}
                                    src={media.preview || staticFilesDomain + media.file}
                                    style={{ maxWidth: '90vw', maxHeight: '90vh' }}
                                />
                            )) ||
                                (media.media_type === MediaType.VIDEO && (
                                    <video controls style={{ maxWidth: '90vw', maxHeight: '90vh' }}>
                                        <source
                                            src={media.preview || staticFilesDomain + media.file}
                                            type="video/mp4"
                                        />
                                    </video>
                                ))}
                        </div>
                    </Fade>
                </Modal>
            )}
        </div>
    )
}

export default MediaPreview
