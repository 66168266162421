import { forwardRef, type HTMLAttributes, memo, useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { makeStyles, type Theme } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import BlockIcon from '@material-ui/icons/Block'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import { useProductFormContext } from '~/products/hooks/useProductForm'
import { useProductPage } from '~/products/hooks/useProductPage'

const useStyles = makeStyles((theme) => ({
    channelProductsTable: {
        tableLayout: 'fixed',
    },
    channelColumn: {
        width: 75,
    },
    shopColumn: {
        width: 500,
    },
    channelIcon: {
        width: 25,
        marginRight: 10,
    },
    linkNotAvailableIcon: {
        color: theme.palette.grey[500],
        height: 20,
        marginTop: 5,
    },
    editChannelProductGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    tooltip: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        fontSize: '0.9rem',
        boxShadow: theme.shadows[1],
        maxWidth: 250,
    },
    skeleton: {
        '&.MuiSkeleton-circle': {
            width: 36,
            height: 36,
        },
    },
}))

type Props = {
    onCreateToggle: (index: number, checked: boolean) => void
    onEditClick: (index: number) => void
    onDeleteClick: (index: number) => void
}

export default function ChannelProductTable({ onCreateToggle, onEditClick, onDeleteClick }: Props) {
    const classes = useStyles()
    const { isNewProduct } = useProductPage()
    const { control } = useProductFormContext()
    const channelProducts = useWatch({ control, name: 'channel_products' })

    const visibleColumns = useMemo(
        () =>
            [
                { label: 'Channel', classes: { root: classes.channelColumn }, skeleton: 'circle' },
                { label: 'Shop', classes: { root: classes.shopColumn } },
                { label: 'Link', hidden: isNewProduct },
                { label: 'Status', hidden: isNewProduct, skeleton: 'circle' },
                { label: 'Display constant stock' },
                { label: 'Edit' },
                { label: 'Create product', hidden: !isNewProduct },
                { label: 'Delete', hidden: isNewProduct },
                { label: 'Active', hidden: isNewProduct },
            ].filter(({ hidden }) => !hidden),
        [classes.channelColumn, classes.shopColumn, isNewProduct]
    )

    return (
        <Table className={classes.channelProductsTable}>
            <TableHead>
                <TableRow>
                    {visibleColumns.map((cell) => (
                        <TableCell key={cell.label} classes={cell.classes}>
                            {gettext(cell.label)}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {channelProducts.map((cp, index) => (
                    <ChannelProductRow
                        key={`${cp.id}-${index}`}
                        index={index}
                        canDelete={channelProducts.length > 1}
                        onCreateToggle={onCreateToggle}
                        onDeleteClick={onDeleteClick}
                        onEditClick={onEditClick}
                    />
                ))}
            </TableBody>
        </Table>
    )
}

type ChannelProductRowProps = {
    index: number
    canDelete: boolean
    onCreateToggle: (index: number, checked: boolean) => void
    onDeleteClick: (index: number) => void
    onEditClick: (index: number) => void
}

const ChannelProductRow = memo(function ChannelProductRow({
    index,
    canDelete,
    onCreateToggle,
    onDeleteClick,
    onEditClick,
}: ChannelProductRowProps) {
    const classes = useStyles()
    const { isNewProduct, productInfo } = useProductPage()
    const { setValue, control, register } = useProductFormContext()
    const channelProduct = useWatch({ control, name: `channel_products.${index}` })
    const { ref } = register(`channel_products.${index}.error_message`)
    const hasError = !!channelProduct.error_message

    return (
        <TableRow>
            <TableCell align="center">
                <img
                    alt={channelProduct.shop.channel.name}
                    className={classes.channelIcon}
                    src={channelProduct.shop.channel.channel_square_icon_url}
                />
            </TableCell>
            <TableCell>{channelProduct.shop.shop_name}</TableCell>
            {!isNewProduct && (
                <>
                    <TableCell>
                        {channelProduct.url ? (
                            <Link href={channelProduct.url} target="_blank">
                                {gettext('Link')}
                            </Link>
                        ) : (
                            <BlockIcon className={classes.linkNotAvailableIcon} />
                        )}
                    </TableCell>
                    <TableCell>
                        <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title={channelProduct.error_message ?? ''}
                        >
                            <ChannelProductStatusIndicator ref={ref} hasError={hasError} />
                        </Tooltip>
                    </TableCell>
                </>
            )}
            <TableCell>
                <Switch
                    checked={channelProduct.display_constant_stock}
                    onChange={(_, checked) => {
                        setValue(`channel_products.${index}.display_constant_stock`, checked)
                    }}
                />
            </TableCell>
            <TableCell>
                <div className={classes.editChannelProductGroup}>
                    <IconButton onClick={() => onEditClick(index)}>
                        <EditIcon />
                    </IconButton>
                    {hasError && (
                        <Typography color="error" variant="subtitle2">
                            {gettext('Errors')}
                        </Typography>
                    )}
                </div>
            </TableCell>
            {isNewProduct ? (
                <TableCell>
                    <Switch
                        checked={channelProduct.shouldCreateChannelProduct}
                        onChange={(_, checked) => onCreateToggle(index, checked)}
                    />
                </TableCell>
            ) : (
                <>
                    <TableCell>
                        <Tooltip
                            title={
                                canDelete ? '' : gettext('At least 1 channel product is required')
                            }
                        >
                            <span>
                                <IconButton
                                    disabled={!canDelete}
                                    color="secondary"
                                    onClick={() => onDeleteClick(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </TableCell>
                    <TableCell>
                        <Tooltip
                            title={
                                !isNewProduct && productInfo.product.status === 'active'
                                    ? ''
                                    : gettext('Product must be active to change this setting')
                            }
                        >
                            <span>
                                <Switch
                                    checked={channelProduct.status === 'active'}
                                    disabled={
                                        (!isNewProduct &&
                                            productInfo.product.status !== 'active') ||
                                        (channelProduct?.status !== 'active' &&
                                            channelProduct?.status !== 'inactive')
                                    }
                                    onChange={() => {
                                        setValue(
                                            `channel_products.${index}.status`,
                                            channelProduct.status === 'active'
                                                ? 'inactive'
                                                : 'active'
                                        )
                                    }}
                                />
                            </span>
                        </Tooltip>
                    </TableCell>
                </>
            )}
        </TableRow>
    )
})

const useIndicatorStyles = makeStyles<Theme, { hasError?: boolean }>((theme) => ({
    root: {
        position: 'relative',
        display: 'inline-block',
        width: theme.spacing(2),
        height: theme.spacing(2),
        borderRadius: '50%',
        backgroundColor: ({ hasError }) =>
            hasError ? theme.palette.error.main : theme.palette.primary.main,
    },
}))

const ChannelProductStatusIndicator = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLDivElement> & { hasError?: boolean }
>(function ChannelProductStatusIndicator({ hasError, ...restProps }, ref) {
    const classes = useIndicatorStyles({ hasError })

    return <div {...restProps} ref={ref} className={classes.root} />
})
