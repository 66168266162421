import { memo, useCallback, useState } from 'react'

import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

import ProductDimensionOptionsForm from './product-dimension-options-form'
import { type NormalizedDimension } from './schemas'

import { getUUID } from '~/utils/uuid'

type Props = {
    existingDimensions: NormalizedDimension[]
    nextDimensionIndex: number
    onDimensionFormOpen: () => void
    onDimensionAdd: (dimension: NormalizedDimension) => void
}

function ProductDimensionsFooter({
    existingDimensions,
    nextDimensionIndex,
    onDimensionFormOpen,
    onDimensionAdd,
}: Props) {
    const [isAdding, setIsAdding] = useState(false)

    const handleFormOpen = useCallback(() => {
        setIsAdding(true)
        onDimensionFormOpen()
    }, [onDimensionFormOpen])

    const handleFormSubmit = useCallback(
        (data: NormalizedDimension) => {
            setIsAdding(false)
            onDimensionAdd(data)
        },
        [onDimensionAdd]
    )

    const handleFormRemove = useCallback(() => setIsAdding(false), [])

    return isAdding ? (
        <ProductDimensionOptionsForm
            isNewProduct
            existingDimensions={existingDimensions}
            defaultValues={{
                id: getUUID(),
                index: nextDimensionIndex,
                name: '',
                options: [{ id: getUUID(), name: '' }],
            }}
            onSubmit={handleFormSubmit}
            onRemove={handleFormRemove}
        />
    ) : (
        <Button startIcon={<AddIcon />} onClick={handleFormOpen}>
            {gettext(nextDimensionIndex ? 'Add another dimension' : 'Add options')}
        </Button>
    )
}

export default memo(ProductDimensionsFooter)
