import { useCallback, useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { grey } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'
import moment from 'moment'

import { get } from '../../tools/request'

import { useAppSelector } from '~/store'

const useStyles = makeStyles({
    tableContainer: {
        maxHeight: 300,
    },
    table: {
        maxWidth: '100%',
        tableLayout: 'fixed',
    },
    tableHeader: {
        borderTop: '1px solid ' + grey[200],
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell: {
        padding: '9px 12px',
        wordWrap: 'break-word',
        fontSize: '0.85rem',
    },
    tableHeadCell: {
        padding: '12px',
        fontSize: '0.85rem',
    },
    tableText: {
        fontSize: '0.85rem',
    },
    datetime: {
        color: grey[600],
    },
    user: {
        color: grey[600],
    },
})

const EventHistory = ({ document, onClose, open }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const { exportDocumentEventsAPIURL } = useAppSelector((state) => state.initial.endpoints)

    const fetchData = useCallback(async () => {
        setLoading(true)
        const res = await get(exportDocumentEventsAPIURL.replace('document_pk', document.id))

        setData(res.data)
        setLoading(false)
    }, [exportDocumentEventsAPIURL, document])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="event-dialog-title">
            <DialogTitle id="event-dialog-title">{gettext('History')}</DialogTitle>
            <DialogContent>
                <div style={{ width: 500 }}>
                    {loading ? (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress size={28} />
                        </div>
                    ) : (
                        <EventHistoryTable data={data} />
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    {gettext('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const EventHistoryTable = ({ data }) => {
    const classes = useStyles()
    return (
        <TableContainer className={classes.tableContainer}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{
                                width: 150,
                            }}
                            className={classes.tableHeadCell}
                        >
                            {gettext('Event')}
                        </TableCell>
                        <TableCell
                            style={{
                                width: 150,
                            }}
                            className={classes.tableHeadCell}
                        >
                            {gettext('Date created')}
                        </TableCell>
                        <TableCell
                            style={{
                                minWidth: 200,
                            }}
                            className={classes.tableHeadCell}
                        >
                            {gettext('User')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => {
                        const user = row.user || {}
                        let userText = user.email
                        const names = [user.first_name, user.last_name].filter(Boolean)
                        if (names.length > 0) {
                            userText += ` (${names.join(' ')})`
                        }
                        return (
                            <TableRow key={row.id} hover>
                                <TableCell className={classes.tableCell}>
                                    <Typography className={classes.tableText} variant="body2">
                                        {row.event_type_name}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography
                                        className={classNames(classes.tableText, classes.datetime)}
                                        variant="body2"
                                    >
                                        {moment(row.datetime).format('D MMM HH:mm')}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography
                                        className={classNames(classes.tableText, classes.user)}
                                        variant="body2"
                                    >
                                        {userText}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default EventHistory
