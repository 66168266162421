import Cookies from 'js-cookie'
import ky from 'ky'

import { type PaginatedParameters, type PaginatedResponse } from '.'

import { type Product } from '~/common/schemas/product'

export type FilterValue = {
    value: string | number
    label: string
}

export type Filter = {
    field: string
    lookup: string
    select_multiple: boolean
    can_select_exclusive?: boolean
    values: FilterValue[]
}

class ProductApi {
    constructor(
        private api = ky.create({
            prefixUrl: `${window.location.origin}/endpoints`,
            credentials: 'include',
            headers: { 'X-CSRFToken': Cookies.get('csrftoken') ?? undefined },
        })
    ) {}

    async getProductListing(
        userId: number,
        params: Record<string, number | string[]> & PaginatedParameters = {}
    ) {
        const searchParams = new URLSearchParams()

        Object.entries(params).forEach(([key, value]) => {
            if (!value) {
                return
            }

            if (Array.isArray(value)) {
                value.forEach((v) => {
                    searchParams.append(key, v)
                })

                return
            }

            searchParams.append(key, value.toString())
        })

        if (!params.order_by) {
            searchParams.set('order_by', '-date_created')
        }

        return this.api
            .get(`users/${userId}/products/`, { searchParams })
            .json<PaginatedResponse<Product>>()
    }

    async getProductFilters(userId: number) {
        return this.api.get(`users/${userId}/filter-values/products/`).json<Filter[]>()
    }

    async updateProductStatus(productId: number, status: string) {
        return this.api
            .patch(`products/${productId}/status/`, { json: { id: productId, status } })
            .json<{ id: number }>()
    }

    async deleteProduct(productId: number) {
        return this.api.delete(`products/${productId}/`).json()
    }
}

export const productApi = new ProductApi()
