import React from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import grey from '@material-ui/core/colors/grey'
import Dialog from '@material-ui/core/Dialog'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'

import { debounce, getTranslation, copyObject } from '../../tools/utils'

// import GroupSummaryList from './group-summary'

const styles = () => ({
    topBar: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid ' + grey[200],
        padding: '10px 20px',
        flex: '0 0 auto',
    },
    progress: {
        width: '100%',
        height: 4,
    },
    title: {
        marginRight: 20,
        marginTop: 8,
    },
    searchBar: {
        width: 325,
        height: 38,
    },
    table: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
    },
    tableHeader: {
        borderBottom: '1px solid ' + grey[200],
    },
    tableHeaderNameCell: {
        paddingTop: 14,
        paddingLeft: 8,
    },
    tableRow: {
        display: 'flex',
        '&:hover': {
            backgroundColor: grey[200],
        },
        '&:last-child': {
            borderBottom: 'none',
        },
        paddingTop: 5,
        paddingBottom: 5,
        outline: 'none',
        borderBottom: '1px solid ' + grey[200],
        cursor: 'pointer',
    },
    tableRowSpan: {
        width: '100%',
    },
    tableCell: {},
    tableWrapper: {
        overflowX: 'auto',
    },
    tableBody: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
    },
    noStockItemsMessage: {
        width: '100%',
        textAlign: 'center',
        marginTop: 50,
    },
    noStockItemsIcon: {
        width: 75,
    },
    noStockItemsText: {
        color: grey[600],
    },
    checkboxColumnCell: {
        paddingLeft: 10,
        width: 70,
        flex: '0 0 auto',
    },
    skuColumnCell: {
        width: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    nameColumnCell: {
        paddingRight: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    nameColumnName: {
        flexGrow: 1,
        paddingLeft: 8,
    },
    samePeriodGroupInfo: {
        width: '25%',
        textAlign: 'right',
    },
    samePeriodGroupsList: {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        color: grey[500],
    },
    bottomBar: {
        borderTop: '1px solid ' + grey[200],
        alignItems: 'center',
        justifyContent: 'flex-end',
        display: 'flex',
        padding: '10px 20px',
        flex: '0 0 auto',
    },
    button: {
        marginLeft: 15,
    },
    addButton: {
        color: 'white',
    },
    spacer: {
        flex: '1 1 auto',
    },
    copyFromContent: {
        textAlign: 'center',
    },
    copyFromList: {
        maxHeight: 310,
    },
    notAvailable: {
        color: grey[400],
    },
    notAvailableImage: {
        opacity: 0.4,
    },
    hasGroupBlock: {
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        textTransform: 'none',
    },
})

class StyledStockItemsTableHead extends React.PureComponent {
    render() {
        const { onSelectAllClick, numSelected, rowCount, classes, excludedStockItemsCount } =
            this.props

        return (
            <div className={classes.tableHeader}>
                <div className={classes.tableRow}>
                    <div
                        className={classNames(classes.tableCell, classes.checkboxColumnCell)}
                        width={70}
                    >
                        <Checkbox
                            indeterminate={
                                numSelected > 0 && numSelected < rowCount - excludedStockItemsCount
                            }
                            checked={
                                rowCount !== 0 && numSelected === rowCount - excludedStockItemsCount
                            }
                            onChange={onSelectAllClick}
                        />
                    </div>
                    <div
                        className={classNames(
                            classes.tableCell,
                            classes.skuColumnCell,
                            classes.tableHeaderNameCell
                        )}
                        width={100}
                    >
                        <Typography variant="body2">{gettext('SKU')}</Typography>
                    </div>
                    <div
                        className={classNames(
                            classes.tableCell,
                            classes.nameColumnCell,
                            classes.tableHeaderNameCell
                        )}
                    >
                        <Typography variant="body2">{gettext('Name')}</Typography>
                    </div>
                </div>
            </div>
        )
    }
}

StyledStockItemsTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const StockItemsTableHead = withStyles(styles)(StyledStockItemsTableHead)

class StockItemSelector extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            orderBy: 'sku',
            selected: [],
            rowsPerPage: 25,
            products: [],
            count: 0,
            page: 0,
            maxPageFetched: 0,
            loading: false,
            showCopyFromDialog: false,
            copyFromGroups: [],
            loadingCopyFromStockItems: false,
            displayStockItemGroups: [],
            showStockItemGroupsDialog: false,
        }

        this.fetchStockItemData = this.fetchStockItemData.bind(this)
        this.fetchGroupsData = this.fetchGroupsData.bind(this)
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this)
        this.handleChecked = this.handleChecked.bind(this)
        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.isSelected = this.isSelected.bind(this)
        this.searchHandler = this.searchHandler.bind(this)
        // this.handleOpenCopyFromDialog = this.handleOpenCopyFromDialog.bind(this)
        // this.handleCancelCopyFromDialog = this.handleCancelCopyFromDialog.bind(this)
        // this.handleCopyFromGroup = this.handleCopyFromGroup.bind(this)

        this.fetchStockItemDataDebounced = debounce(this.fetchStockItemData, 500)

        this.copyFromScrollRef = React.createRef()
    }

    componentDidMount() {
        const { stockItemsAPIURL, startDate, endDate } = this.props

        if (startDate && endDate) {
            this.fetchStockItemData(stockItemsAPIURL, true)
        }

        this.fetchGroupsData()
    }

    componentDidUpdate(prevProps) {
        const { stockItemsAPIURL, startDate, endDate } = this.props

        if (prevProps.startDate && prevProps.endDate && startDate && endDate) {
            if (
                (!moment(startDate).isSame(moment(prevProps.startDate)) ||
                    !moment(endDate).isSame(moment(prevProps.endDate))) &&
                !this.state.loading
            ) {
                this.fetchStockItemData(stockItemsAPIURL, true)
            }
        }
    }

    async fetchStockItemData(url, replace, page = null, searchTerm = null) {
        const { storeID, startDate, endDate } = this.props

        if (!url.includes('order_by')) {
            url += '?order_by=' + this.state.orderBy
        }

        if (searchTerm) {
            url += '&search=' + searchTerm
        }

        url += '&status=active'

        // url += '&group_exclusion_start_date=' + encodeURIComponent(startDate)
        // url += '&group_exclusion_end_date=' + encodeURIComponent(endDate)

        this.setState({ loading: true })

        const response = await fetch(url.replace('store_pk', storeID))
        const data = await response.json()
        let products
        if (replace) {
            products = data.results
        } else {
            products = this.state.products.concat(data.results)
        }
        const newState = {
            count: data.count,
            next: data.next,
            previous: data.previous,
            products: products,
            loading: false,
        }
        if (page === 0) {
            newState['page'] = 0
            newState['maxPageFetched'] = 0
        } else if (page > 0) {
            newState['page'] = page
        }
        this.setState(newState)
    }

    fetchGroupsData() {
        const { stockAdjustmentGroupsAPIURL, storeID } = this.props

        if (this.state.copyFromLoading) return

        this.setState({ copyFromLoading: true }, async () => {
            let url = `${stockAdjustmentGroupsAPIURL}?store=${storeID}&order_by=-start_date`

            const response = await fetch(url)
            const data = await response.json()
            const groups = copyObject(this.state.copyFromGroups).concat(data)
            const newState = {
                copyFromGroups: groups,
            }
            this.setState(newState)
        })
    }

    handleSelectAllClick(_, checked) {
        if (checked) {
            const newSelectedStockItems = copyObject(this.state.products).filter(
                (p) => !this.props.excludedStockItemIDs.includes(p.id)
            )

            this.setState({ selected: newSelectedStockItems })
        } else {
            this.setState({ selected: [] })
        }
    }

    handleChecked(_, checked, product) {
        const selectedStockItemIDs = this.state.selected.map((p) => p.id)
        let newSelected = copyObject(this.state.selected)

        if (checked) {
            if (!selectedStockItemIDs.includes(product.id)) {
                newSelected.push(product)
            }
        } else {
            newSelected = newSelected.filter((p) => p.id !== product.id)
        }

        this.setState({ selected: newSelected })
    }

    handleSelectRow(product) {
        const selectedStockItemIDs = this.state.selected.map((p) => p.id)
        let newSelected = copyObject(this.state.selected)

        if (selectedStockItemIDs.includes(product.id)) {
            newSelected = newSelected.filter((p) => p.id !== product.id)
        } else {
            newSelected.push(product)
        }

        this.setState({ selected: newSelected })
    }

    handleChangePage(_, page) {
        let pageChangeURL

        if (page > this.state.page) {
            pageChangeURL = this.state.next
        } else {
            pageChangeURL = this.state.previous
        }

        const needsFetch = page > this.state.maxPageFetched

        if (needsFetch) {
            this.setState(
                {
                    maxPageFetched: page,
                    loading: true,
                },
                () => {
                    this.fetchStockItemData(pageChangeURL, false, page)
                }
            )
        } else {
            this.setState({
                page: page,
            })
        }
    }

    isSelected(productID) {
        const productIDs = this.state.selected.map((p) => p.id)
        return productIDs.includes(productID)
    }

    searchHandler(event) {
        const { stockItemsAPIURL } = this.props

        const searchTerm = event.target.value

        this.fetchStockItemDataDebounced(stockItemsAPIURL, true, 0, searchTerm)
    }

    // handleOpenCopyFromDialog() {
    //     this.setState({ showCopyFromDialog: true })
    // }

    // handleCancelCopyFromDialog() {
    //     this.setState({ showCopyFromDialog: false })
    // }

    // handleCopyFromGroup(groupID = null) {
    //     const { stockAdjustmentsUpdateAPIURL, handleSelectedStockItems } = this.props
    //     return () => {
    //         if (groupID) {
    //             this.setState({ loadingCopyFromStockItems: true }, () => {
    //                 fetch(stockAdjustmentsUpdateAPIURL.replace('group_id', groupID))
    //                     .then((response) => response.json())
    //                     .then((productGroups) => {
    //                         const products = productGroups.map((pd) => pd.product)

    //                         this.setState(
    //                             {
    //                                 loadingCopyFromStockItems: false,
    //                                 showCopyFromDialog: false,
    //                             },
    //                             () => {
    //                                 handleSelectedStockItems(products)
    //                             }
    //                         )
    //                     })
    //             })
    //         } else {
    //             this.setState(
    //                 {
    //                     showCopyFromDialog: false,
    //                 },
    //                 () => {
    //                     handleSelectedStockItems([])
    //                 }
    //             )
    //         }
    //     }
    // }

    // handleOpenStockItemGroups = (product) => {
    //     return (event) => {
    //         this.setState({
    //             displayStockItemGroups: product.same_period_groups,
    //             showStockItemGroupsDialog: true,
    //         })
    //         event.stopPropagation()
    //     }
    // }

    // handleCloseStockItemGroups = () => {
    //     this.setState({ showStockItemGroupsDialog: false })
    // }

    // onStockItemGroupDialogExited = () => {
    //     this.setState({ displayStockItemGroups: [] })
    // }

    render() {
        const {
            classes,
            display,
            handleSelectedStockItems,
            excludedStockItemIDs,
            language,
            staticRoot,
        } = this.props
        const { rowsPerPage, page } = this.state

        const emptyRows =
            rowsPerPage - Math.min(rowsPerPage, this.state.products.length - page * rowsPerPage)

        const displayedStockItems = this.state.products.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        )
        return (
            <Dialog
                open={display}
                fullWidth={true}
                maxWidth="md"
                onClose={() => {
                    handleSelectedStockItems([])
                    this.setState({ selected: [] })
                }}
            >
                <div className={classes.topBar}>
                    <Typography className={classes.title} variant="subtitle1">
                        {gettext('Add stock items to group')}
                    </Typography>

                    <TextField
                        autoFocus
                        id="search"
                        type="search"
                        className={classes.searchBar}
                        margin="normal"
                        onChange={this.searchHandler}
                        variant="outlined"
                        placeholder={gettext('Search items (name, SKU, etc)')}
                        InputProps={{ style: { height: '38px' } }}
                    />
                    <div className={classes.spacer} />

                    <TablePagination
                        className={classes.topPagination}
                        component="div"
                        count={this.state.count}
                        rowsPerPage={25}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': gettext('Previous'),
                        }}
                        nextIconButtonProps={{
                            'aria-label': gettext('Next'),
                        }}
                        onPageChange={this.handleChangePage}
                        rowsPerPageOptions={[25]}
                    />
                </div>
                {this.state.loading && (
                    <LinearProgress className={classes.progress} color="secondary" />
                )}
                <div className={classes.table} aria-labelledby="tableTitle">
                    <StockItemsTableHead
                        className={classes.tableHead}
                        numSelected={this.state.selected.length}
                        orderBy={this.state.orderBy}
                        onSelectAllClick={this.handleSelectAllClick}
                        rowCount={this.state.products.length}
                        excludedStockItemsCount={excludedStockItemIDs.length}
                    />
                    <div className={classes.tableBody}>
                        {!this.state.loading && this.state.products.length === 0 && (
                            <div className={classes.noStockItemsMessage}>
                                <img
                                    className={classes.noStockItemsIcon}
                                    src={staticRoot + 'web/img/product-gray.png'}
                                />
                                <Typography
                                    className={classes.noStockItemsText}
                                    variant="subtitle1"
                                >
                                    {gettext('No items found')}
                                </Typography>
                            </div>
                        )}
                        {displayedStockItems.map((product) => {
                            const isSelected = this.isSelected(product.id)
                            const notAvailable = excludedStockItemIDs.includes(product.id)
                            return (
                                <div
                                    className={classes.tableRow}
                                    role="checkbox"
                                    aria-checked={isSelected}
                                    tabIndex={-1}
                                    key={product.id}
                                    selected={isSelected}
                                    onClick={() => {
                                        if (!notAvailable) {
                                            this.handleSelectRow(product)
                                        }
                                    }}
                                    title={
                                        notAvailable
                                            ? gettext('StockItem is already in this group')
                                            : ''
                                    }
                                >
                                    <div
                                        className={classNames(
                                            classes.tableCell,
                                            classes.checkboxColumnCell
                                        )}
                                    >
                                        <Checkbox
                                            disabled={notAvailable}
                                            checked={isSelected}
                                            onChange={(event, checked) =>
                                                this.handleChecked(
                                                    event,
                                                    checked,
                                                    copyObject(product)
                                                )
                                            }
                                        />
                                    </div>
                                    <div
                                        className={classNames(
                                            classes.tableCell,
                                            classes.skuColumnCell
                                        )}
                                    >
                                        <Typography
                                            className={classNames([
                                                notAvailable ? classes.notAvailable : '',
                                            ])}
                                            variant="body1"
                                        >
                                            {product.sku}
                                        </Typography>
                                    </div>
                                    <div
                                        scope="row"
                                        className={classNames(
                                            classes.tableCell,
                                            classes.nameColumnCell
                                        )}
                                    >
                                        <Typography
                                            className={classNames([
                                                notAvailable ? classes.notAvailable : '',
                                            ])}
                                            variant="body1"
                                        >
                                            {product.name ||
                                                getTranslation(product, language, 'name')}
                                        </Typography>
                                        {/* {isInSamePeriodGroup && (
                                            <div>
                                                <Button
                                                    className={classNames([
                                                        classes.hasGroupBlock,
                                                    ])}
                                                    onClick={this.handleOpenStockItemGroups(
                                                        product
                                                    )}
                                                >
                                                    <InfoIcon style={{ paddingBottom: 4 }} />

                                                    <Typography
                                                        variant="body2"
                                                        style={{
                                                            paddingLeft: 5,
                                                            color: grey[500],
                                                        }}
                                                    >
                                                        {gettext(
                                                            'Has groups in selected date range'
                                                        )}
                                                    </Typography>
                                                </Button>
                                            </div>
                                        )} */}
                                    </div>
                                </div>
                            )
                        })}
                        {emptyRows > 0 && (
                            <div
                                className={classes.tableRowSpan}
                                style={{ height: 49 * emptyRows }}
                            ></div>
                        )}
                    </div>
                </div>
                <div className={classes.bottomBar}>
                    <Typography>
                        {interpolate(gettext('%s selected'), [this.state.selected.length])}
                    </Typography>
                    <Button
                        className={classes.button}
                        color="primary"
                        onClick={() => {
                            handleSelectedStockItems([])
                            this.setState({ selected: [] })
                        }}
                    >
                        {gettext('Cancel')}
                    </Button>
                    <Button
                        className={classNames(classes.button, classes.addButton)}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleSelectedStockItems(copyObject(this.state.selected))
                            this.setState({ selected: [] })
                        }}
                    >
                        {gettext('Add items')}
                    </Button>
                </div>
            </Dialog>
        )
    }
}

export default withStyles(styles)(StockItemSelector)
