import { useMutation } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import { z } from 'zod'

import { ShopeeLogisticsProvider } from '~/common/schemas/logistics-shopee'
import { Media, AssignedMedia } from '~/common/schemas/media'
import {
    ChannelProduct,
    ChannelStockUnit,
    ChannelStockUnitDiscount,
} from '~/common/schemas/product'
import { BundleItem, StockItem } from '~/common/schemas/stock-item'
import { StockRecord } from '~/common/schemas/stock-record'
import { StockUnit } from '~/common/schemas/stock-unit'
import { Store } from '~/common/schemas/store'
import { makeTranslationsSchema, Translations } from '~/common/schemas/translations'
import { useAppSelector } from '~/store'
import { handleFetchError } from '~/utils/error'

const Id = z
    .union([z.number(), z.string()])
    .transform((id) => (typeof id === 'number' ? id : undefined))

//#region Children schemas
export const Dimension = z.object({
    id: z.number().optional(),
    index: z.number().nonnegative('Dimension index must be non-negative'),
    options: z
        .array(
            z.object({
                id: z.number().optional(),
                index: z.number().nonnegative('Option index must be non-negative'),
                translations: makeTranslationsSchema(
                    z.object({ option: z.string().min(1, 'Option name is required') })
                ),
            })
        )
        .min(1, 'At least one option is required'),
    translations: makeTranslationsSchema(
        z.object({
            name: z.string().min(1, 'Dimension name is required'),
        })
    ),
})
export type Dimension = z.infer<typeof Dimension>
//#endregion

export const ProductPayload = z.object({
    channel_attribute_values: z.record(
        z.string().refine((data) => !isNaN(parseInt(data)), 'Invalid channel ID'),
        z.object({
            stock_units: z.array(
                z.object({
                    sku: z.string(),
                    stock_unit_attribute_values: z.array(
                        z.object({
                            attribute_id: z.string(),
                            value: z.string(),
                        })
                    ),
                })
            ),
            product_attribute_values: z.array(
                z.discriminatedUnion('type', [
                    z.object({
                        type: z.literal('shopee_logistics'),
                        attribute_id: z.literal('logistics'),
                        value: z.record(z.coerce.number(), z.array(ShopeeLogisticsProvider.partial())),
                    }),
                    z.object({
                        type: z.literal('tiktok_delivery_option'),
                        attribute_id: z.literal('logistics'),
                        value: z.record(z.coerce.number(), z.array(z.string())),
                    }),
                    z.object({
                        type: z.literal('common'),
                        attribute_id: z.string(),
                        value: z.union([z.string(), z.array(z.string())]),
                        unit: z.object({ id: z.number(), name: z.string() }).nullish(),
                    }),
                ])
            ),
        })
    ),
    channel_products: ChannelProduct.omit({ category: true })
        .partial({ product: true, status: true })
        .extend({
            id: Id,
            category_id: z.number().nullable(),
            grouped_media: Media.pick({
                id: true,
                file: true,
                media_type: true,
                name: true,
            })
                .array()
                .optional()
                .nullable(),
            images: ChannelProduct.shape.images.element
                .pick({
                    id: true,
                    display_order: true,
                    media_id: true,
                    channel_url: true,
                    channel_product: true,
                    product_image: true,
                })
                .extend({ id: Id })
                .partial()
                .array(),
            videos: AssignedMedia.pick({
                id: true,
                display_order: true,
                media_id: true,
                channel_url: true,
            })
                .extend({
                    channel_product: z.number().optional(),
                    product_video: AssignedMedia.extend({
                        product: z.number(),
                    })
                        .optional()
                        .nullish(),
                })
                .array()
                .optional()
                .nullable(),
            shop: z.number(),
            shouldCreateChannelProduct: z.boolean().optional(),
            channel_stock_units: ChannelStockUnit.partial({
                channel_product: true,
                id: true,
                status: true,
            })
                .omit({ channel_stock_item: true, stock_unit: true })
                .extend({
                    images: ChannelStockUnit.shape.images.element
                        .pick({
                            id: true,
                            display_order: true,
                            media_id: true,
                            channel_url: true,
                            channel_stock_unit: true,
                            stock_unit_image: true,
                        })
                        .partial({ channel_stock_unit: true, id: true })
                        .array(),
                    fulfillment_locations: z.string().array(),
                    price: z.string().transform((value) => parseFloat(value) || 0),
                    placeholder: z.boolean(),
                })
                .array(),
        })
        .array(),
    dimensions: Dimension.array(),
    id: Id,
    grouped_media: Media.pick({
        id: true,
        file: true,
        media_type: true,
        name: true,
    })
        .array()
        .optional()
        .nullable(),
    images: AssignedMedia.pick({ id: true, display_order: true, media_id: true, channel_url: true })
        .extend({
            product: z.number().optional(),
        })
        .array()
        .min(1),
    videos: AssignedMedia.pick({
        id: true,
        display_order: true,
        media_id: true,
        channel_url: true,
    })
        .partial({ id: true })
        .array()
        .optional()
        .nullable(),
    shopee_logistics: z.unknown().optional(),
    stock_units: StockUnit.partial({ id: true, product: true })
        .extend({
            dimension_options: StockUnit.shape.dimension_options.element.omit({ id: true }).array(),
            images: StockUnit.shape.images.element
                .pick({
                    id: true,
                    display_order: true,
                    media_id: true,
                    channel_url: true,
                    stock_unit: true,
                })
                .partial({ id: true, stock_unit: true })
                .array(),
            stock_records: StockRecord.pick({ quantity: true, stock_item: true })
                .partial({ stock_item: true })
                .extend({ location_id: z.union([z.number(), z.string()]) })
                .array(),
            stock_item: StockItem.partial({ id: true, stock_unit: true }).extend({
                bundle_items: BundleItem.partial({ id: true }).array(),
                package_height: z.number().nonnegative().nullish(),
                package_length: z.number().nonnegative().nullish(),
                package_weight: z.number().nonnegative().nullish(),
                package_width: z.number().nonnegative().nullish(),
            }),
        })
        .array()
        .min(1),
    stock_units_editing_is_enabled: z.boolean(),
    store: Store.transform((store) => store.id),
    translations: Translations,
    active_channel_stock_unit_discounts: ChannelStockUnitDiscount.array().nullish(),
})
export type ProductPayload = z.infer<typeof ProductPayload>
export type ProductPayloadInput = z.input<typeof ProductPayload>

async function saveProduct(apiUrl: string, method: 'POST' | 'PUT', payload: ProductPayload) {
    const res = await fetch(apiUrl, {
        method,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRFToken': Cookies.get('csrftoken') ?? '',
        },
        body: JSON.stringify(payload),
    })

    if (!res.ok) {
        return handleFetchError(res)
    }

    return z.object({ id: z.number() }).promise().parse(res.json())
}

export function useSaveProductMutation() {
    const productCreateAPIURL = useAppSelector(
        (state) => state.initial.endpoints.productCreateAPIURL
    )
    const productAPIURL = useAppSelector((state) => state.initial.endpoints.productAPIURL)

    return useMutation({
        mutationFn: async (data: ProductPayload) => {
            const apiUrl = data.id
                ? productAPIURL.replace('product_pk', data.id.toString())
                : productCreateAPIURL
            const method = data.id ? 'PUT' : 'POST'

            return await saveProduct(apiUrl, method, data)
        },
    })
}
