import { Redirect } from 'react-router-dom'


import { useUser } from '~/common/hooks'
import { getPath } from '~/tools/utils'

export default function HomePage(props) {
    const { permissions } = useUser()
    let redirectPath = ''
    if (permissions.some(p => p.codename === 'module.sales')) {
        redirectPath = getPath('sales')
    } else if (permissions.some(p => p.codename === 'module.products')) {
        redirectPath = getPath('products')
    } else if (permissions.some(p => p.codename === 'module.analytics')) {
        redirectPath = getPath('dashboardOverview')
    } else {
        redirectPath = getPath('accessDenied')
    }

    return (
        <Redirect
            to={{
                pathname: redirectPath,
                state: { from: props.location }
            }}
        />
    )
}
