import { type ProductFormSchema, type StockUnitFormSchema } from '../hooks/useProductForm'

import { type UserLocation } from '~/common/schemas/location'
import { shouldBulkEditPackageInfo } from '~/products/utils/packageInfo'
import { getUUID } from '~/utils/uuid'

export function createStockUnit(
    dimensionOptions: Array<{
        id: string | number
        index: number
        product_dimension: { index: number }
    }>,
    locations: UserLocation[],
    product: ProductFormSchema
) {
    const productID = typeof product?.id === 'number' ? product.id : undefined
    const bulkEdit = product ? shouldBulkEditPackageInfo(product.stock_units) : false
    return {
        id: getUUID(),
        product: productID,
        sku: '',
        images: [],
        dimension_options: dimensionOptions,
        is_bundle: false,
        fulfillment_locations: [...locations],
        stock_item: {
            sku: '',
            barcode: null,
            bundle_items: [],
            is_bundle: false,
            package_height: bulkEdit ? product.package_height : null,
            package_weight: bulkEdit ? product.package_weight : null,
            package_length: bulkEdit ? product.package_length : null,
            package_width: bulkEdit ? product.package_width : null,
        },
        stock_records: locations.map((location) => ({
            location,
            quantity: 0,
        })),
    }
}

/**
 * Computes a sorting key for a given set of dimension options.
 * The sorting key is a string where each part represents a dimension-option combination.
 * The parts are joined by underscores.
 *
 * @param dimensionOptions - The dimension options for which to compute the sorting key.
 * @param multiplier - The multiplier to apply to the product dimension index. Defaults to `1000`
 * @returns The computed key.
 */
function computeStockUnitDimensionOptionsKey(
    dimensionOptions: StockUnitFormSchema['dimension_options'],
    multiplier = 1000
) {
    return dimensionOptions
        .map(({ product_dimension, index }) => {
            // Use the product dimension index as the major sorting factor
            // and the individual option index as the minor sorting factor
            return product_dimension.index * multiplier + index
        })
        .join('_')
}

/**
 * Sorts an array of stock units based on their dimension options.
 * Each stock unit has an array of dimension options, which includes a product dimension and an index.
 * The function first computes a sorting key for each stock unit and then sorts them accordingly.
 * NOTE: The array will be modified in-place.
 *
 * @param stockUnits - The array of stock units to sort.
 * @returns The sorted array of stock units.
 */
export function sortStockUnitsByDimensionOptions(
    stockUnits: StockUnitFormSchema[]
): StockUnitFormSchema[] {
    const sortKeyById = stockUnits.reduce(
        (acc, su) => {
            acc[su.id] = computeStockUnitDimensionOptionsKey(su.dimension_options)

            return acc
        },
        {} as Record<string | number, string>
    )

    return stockUnits.sort((a, b) => {
        const aSortKey = sortKeyById[a.id]
        const bSortKey = sortKeyById[b.id]

        return aSortKey.localeCompare(bSortKey)
    })
}

export function computeStockUnitItemKey(index: number, su: StockUnitFormSchema) {
    return `item-${index}-${su.id}`
}
