import { connect } from 'react-redux'

import { changeLoginState } from '../actions/login'
import App from '../components/app'

const mapStateToProps = (state) => ({
    currentLanguage: state.initial.language,
    setLanguageURL: state.initial.endpoints.setLanguageURL,
    staticRoot: state.initial.staticRoot,
    loginURL: state.initial.pageURLs.loginURL,
    logoutAPIURL: state.initial.endpoints.logoutAPIURL,
    loggedIn: state.login.loggedIn,
    redirectPath: state.initial.redirectPath,
})

const matchDispatchToProps = (dispatch) => ({
    changeLoginState: (loginState) => {
        dispatch(changeLoginState(loginState))
    },
})

export default connect(mapStateToProps, matchDispatchToProps)(App)
