import { ProductFormSchema } from '../../hooks/useProductForm'

import { NormalizedDimension } from './schemas'

const DimensionPayload = ProductFormSchema.shape.dimensions.element

export const convertNormalizedDimensionsToPayload = (
    dimensions: NormalizedDimension[],
    languages: string[]
) => {
    // Since we are using the same name for all languages
    // we need to add other languages with the same value to the dimensions
    return DimensionPayload.array().parse(
        dimensions.map(({ name, options, ...rest }) => ({
            options: options.map(({ name: optionName, ...restOption }) => ({
                translations: languages.reduce((acc, language) => {
                    acc[language] = { option: optionName }
                    return acc
                }, {} as Record<string, { option: string }>),
                ...restOption,
            })),
            translations: languages.reduce((acc, language) => {
                acc[language] = { name }
                return acc
            }, {} as Record<string, { name: string }>),
            ...rest,
        }))
    )
}

export const convertDimensionsToNormalized = (dimensions: ProductFormSchema['dimensions']) => {
    return NormalizedDimension.array().parse(
        dimensions.map(({ options, translations, ...rest }) => ({
            name: translations.th?.name || translations.en?.name,
            options: options.map(({ translations: optionTranslations, ...restOption }) => ({
                name: optionTranslations.th.option || optionTranslations.en.option,
                ...restOption,
            })),
            ...rest,
        }))
    )
}
