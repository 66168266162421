import { useState } from 'react'
import { useWatch } from 'react-hook-form'
import { TableVirtuoso, type TableComponents } from 'react-virtuoso'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import { type StockUnitFormSchema, useProductFormContext } from '../../hooks/useProductForm'

import ProductSection from './product-section'
import StockRecordsRow from './stock-records-row'

import { MAX_VIRTUALIZED_HEIGHT } from '~/constants'
import { useProductPage } from '~/products/hooks/useProductPage'
import { useCommonStyles } from '~/products/styles'
import { computeStockUnitItemKey } from '~/products/utils/stock-unit'

const useStyles = makeStyles((theme) => ({
    table: {
        minHeight: 100,
        maxHeight: MAX_VIRTUALIZED_HEIGHT,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    regularColumn: {
        whiteSpace: 'nowrap',
        padding: '5px 10px',
    },
    headerRow: {
        backgroundColor: theme.palette.background.paper,
    },
}))

const components: TableComponents<StockUnitFormSchema> = {
    Scroller: TableContainer,
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableBody,
    TableHead,
    TableRow,
}

export default function StockRecords() {
    const commonClasses = useCommonStyles()
    const classes = useStyles()
    const [height, setHeight] = useState(0)

    const { control } = useProductFormContext()
    const stockUnits = useWatch({ control, name: 'stock_units', exact: true })

    const { productInfo } = useProductPage()
    const { locations } = productInfo

    return (
        <ProductSection>
            <div className={commonClasses.sectionTitle}>
                <Typography variant="subtitle1">{gettext('Stock')}</Typography>
            </div>

            <TableVirtuoso
                components={components}
                className={classes.table}
                style={{ height }}
                data={stockUnits}
                totalListHeightChanged={setHeight}
                fixedHeaderContent={() => (
                    <TableRow className={classes.headerRow}>
                        <TableCell>&nbsp;</TableCell>
                        {locations.map((location) => (
                            <TableCell key={location.id} className={classes.regularColumn}>
                                <Typography>{location.name}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                )}
                itemContent={(index) => <StockRecordsRow index={index} />}
                computeItemKey={computeStockUnitItemKey}
            />
        </ProductSection>
    )
}
