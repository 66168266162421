import { connect } from 'react-redux'

import OrderProcessor from '../components/order-processor'

const mapStateToProps = (state) => ({
    stores: state.initial.stores,
    language: state.initial.language,
    userID: state.initial.user.id,
    pickPackBatchFulfillmentsAPIURL: state.initial.endpoints.pickPackBatchFulfillmentsAPIURL,
    salesFromPKsAPIURL: state.initial.endpoints.salesFromPKsAPIURL,
    fulfillmentAPIURL: state.initial.endpoints.fulfillmentAPIURL,
    fulfillmentDocumentsURL: state.initial.endpoints.fulfillmentDocumentsURL,

    staticRoot: state.initial.staticRoot,
})

export default connect(mapStateToProps)(OrderProcessor)
