import { memo, useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { api, queryKeys } from '~/api'
import { type StockItem } from '~/common/schemas/stock-item'
import { useProductFormContext } from '~/products/hooks/useProductForm'

type Props = {
    productId: number
    onSelectStockUnit: (stockItem: StockItem) => void
}

const useStyles = makeStyles({
    sku: {
        marginRight: 10,
        flex: '1 0 auto',
    },
    errorText: {
        fontSize: '0.9rem',
        marginRight: 10,
    },
})

export default memo(function BundleItemStockItems({ productId, onSelectStockUnit }: Props) {
    const classes = useStyles()
    const {
        data: product,
        isLoading,
        error,
    } = api.products.detail.useQuery(queryKeys.products.detail(productId).queryKey, {
        params: { id: productId },
    })
    const { control } = useProductFormContext()
    const channelProducts = useWatch({ control, name: 'channel_products' })
    // Require bundled items available on all bundle item's shops
    const missingShops = useMemo(() => {
        if (product?.status !== 200) {
            return []
        }

        const channelProductShopsMap = new Map(
            product.body.channel_products.map(({ shop }) => [shop.id, shop])
        )

        const missingShops = channelProducts
            .filter(({ shop, shouldCreateChannelProduct }) => {
                if (shouldCreateChannelProduct === undefined || shouldCreateChannelProduct) {
                    return !channelProductShopsMap.has(shop.id)
                }
                return false
            })
            .map(({ shop }) => shop)

        const missingShopIdSet = new Set()
        return missingShops.filter((shop) => {
            if (!missingShopIdSet.has(shop.id)) {
                missingShopIdSet.add(shop.id)
                return true
            }
            return false
        })
    }, [channelProducts, product])

    const hasMissingShops = useMemo(() => missingShops.length > 0, [missingShops])
    const missingShopNames = useMemo(
        () => missingShops.map((shop) => shop.shop_name),
        [missingShops]
    )

    if (productId < 0) {
        return null
    }

    if (isLoading) {
        return <Typography>{gettext('Loading...')}</Typography>
    }

    if (error) {
        return <Typography color="error">{gettext('Error loading product')}</Typography>
    }

    return (
        <List>
            {product.body.stock_units.map(({ id, stock_item: stockItem, sku }) => (
                <ListItem
                    key={id}
                    button
                    disabled={hasMissingShops || stockItem.is_bundle}
                    onClick={() => onSelectStockUnit(stockItem)}
                >
                    <ListItemText className={classes.sku}>{sku}</ListItemText>
                    {stockItem.is_bundle && (
                        <Typography className={classes.errorText}>
                            {gettext('Cannot select bundle SKU as item')}
                        </Typography>
                    )}
                    {hasMissingShops && (
                        <Typography className={classes.errorText}>
                            {interpolate(gettext('Product missing shops %s'), [
                                missingShopNames.join(', '),
                            ])}
                        </Typography>
                    )}
                </ListItem>
            ))}
        </List>
    )
})
