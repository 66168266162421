import { connect } from 'react-redux'

import {
    SummaryChartCard as SummaryChartCardComponent,
    StatsTableCard as StatsTableCardComponent,
} from '../components/dashboard-components'

const mapStateToProps = (state) => ({
    language: state.initial.language,
    analyticsTimeSeriesAPI: state.initial.endpoints.analyticsTimeSeriesAPI,
    userID: state.initial.user.id,
})

const SummaryChartCard = connect(mapStateToProps)(SummaryChartCardComponent)
const StatsTableCard = connect(mapStateToProps)(StatsTableCardComponent)

export { SummaryChartCard, StatsTableCard }
