import React from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import Cookie from 'js-cookie'

import { languageLabels } from '../../constants'

class LanguageButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            anchorEl: null,
        }

        this.languageInputRef = React.createRef()
        this.handleMenu = this.handleMenu.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleSelectLanguage = this.handleSelectLanguage.bind(this)
    }

    handleMenu(event) {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleSelectLanguage(language) {
        return (event) => {
            this.languageInputRef.current.value = language
            this.languageInputRef.current.form.submit()
        }
    }

    handleClose() {
        this.setState({
            anchorEl: null,
        })
    }

    render() {
        const { currentLanguage, setLanguageURL } = this.props
        const otherLanguages = Object.keys(languageLabels).filter((l) => l != currentLanguage)
        const open = Boolean(this.state.anchorEl)
        const csrftoken = Cookie.get('csrftoken')

        return (
            <React.Fragment>
                <Button
                    aria-owns={open ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                >
                    {languageLabels[currentLanguage]}
                </Button>
                <Menu
                    id="menu-appbar"
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={this.handleSelectLanguage(currentLanguage)}>
                        {languageLabels[currentLanguage]}
                    </MenuItem>
                    {otherLanguages.map((l) => (
                        <MenuItem onClick={this.handleSelectLanguage(l)} key={l}>
                            {languageLabels[l]}
                        </MenuItem>
                    ))}
                </Menu>
                <form action={setLanguageURL} method="post">
                    <input type="hidden" name="language" ref={this.languageInputRef} />
                    <input type="hidden" name="next" value={window.location.href} />
                    <input type="hidden" name="csrfmiddlewaretoken" value={csrftoken} />
                </form>
            </React.Fragment>
        )
    }
}

export default LanguageButton
