/* eslint-disable no-restricted-imports */
/* eslint-disable no-class-assign */
import React from 'react'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import grey from '@material-ui/core/colors/grey'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import AddChannelStepper from './add-channel-stepper'

import { ChannelsWithChat, AppType } from '~/constants'


const styles = (theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    stepper: {
        borderBottom: '1px solid ' + grey[200],
        padding: 20,
    },
    channelInstructions: {
        marginTop: 50,
        width: '75%',
    },
    channels: {
        display: 'flex',
        marginBottom: 50,
        marginTop: 30,
        justifyContent: 'center',
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 30,
    },
    channelCard: {
        width: 250,
        marginRight: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 20,
    },
    channelIcon: {
        width: '80%',
    },
    cardActions: {
        width: '100%',
        borderTop: '1px solid ' + grey[200],
    },
})

class ChannelInstructions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}

        this.handleClickAuthorize = this.handleClickAuthorize.bind(this)
    }

    componentDidMount() {
        const { channelAPIURL, channelAuthLinkURL } = this.props
        const { channel_id, store_id } = this.props.match.params

        fetch(channelAPIURL.replace('channel_id', channel_id))
            .then((response) => response.json())
            .then((channel) => {
                this.setState({ channel }, () => {
                    fetch(`${channelAuthLinkURL.replace('channel_id', channel_id)}?store_id=${store_id}&app_type=${AppType.ERP}`)
                        .then((response) => response.json())
                        .then((data) => {
                            this.setState({ authLinkERP: data.auth_link })
                        })
                    if (ChannelsWithChat.includes(channel.name)) {
                        fetch(`${channelAuthLinkURL.replace('channel_id', channel_id)}?store_id=${store_id}&app_type=${AppType.CHAT}`)
                            .then((response) => response.json())
                            .then((data) => {
                                this.setState({ authLinkChat: data.auth_link })
                            })
                    }
                })
            })
    }

    handleClickAuthorize(link) {
        window.location.href = link
    }

    render() {
        const { classes, user } = this.props
        const instructionMessage =
            'To link %(channelName)s to your Oakra account, click on the link below to go to the %(channelName)s website. %(channelName)s will ask you to give Oakra permission to help manage your account.'
        const instructionMessageFormat = ngettext(instructionMessage, instructionMessage, 1)
        const { authLinkERP, authLinkChat } = this.state

        return (
            <div className={classes.container}>
                <Paper className={classes.channelInstructions}>
                    <div className={classes.stepper}>
                        <AddChannelStepper activeStep={2} />
                    </div>
                    <div className={classes.cardContainer}>
                        {this.state.channel && (
                            <Card
                                key={this.state.channel.id}
                                className={classes.channelCard}
                                raised={false}
                            >
                                <img
                                    className={classes.channelIcon}
                                    src={this.state.channel.channel_icon_url}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {gettext('Authorize') +
                                            ' ' +
                                            this.state.channel.name.replace(/\b\w/g, (l) =>
                                                l.toUpperCase()
                                            )}
                                    </Typography>
                                    <Typography component="p">
                                        {interpolate(
                                            instructionMessageFormat,
                                            {
                                                channelName: this.state.channel.name.replace(
                                                    /\b\w/g,
                                                    (l) => l.toUpperCase()
                                                ),
                                            },
                                            true
                                        )}
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <Button
                                        size="small"
                                        color="primary"
                                        disabled={
                                            authLinkERP && !user.is_demo ? false : true
                                        }
                                        onClick={() => this.handleClickAuthorize(authLinkERP)}
                                    >
                                        {gettext('Authorize ERP')}
                                    </Button>
                                    {authLinkChat &&
                                        <Button
                                            size="small"
                                            color="primary"
                                            disabled={user.is_demo}
                                            onClick={() => this.handleClickAuthorize(authLinkChat)}
                                        >
                                            {gettext('Authorize Chat')}
                                        </Button>
                                    }
                                </CardActions>
                            </Card>
                        )}
                    </div>
                </Paper>
            </div>
        )
    }
}

ChannelInstructions = withStyles(styles)(ChannelInstructions)

export default ChannelInstructions
