import { useState } from 'react'

import InputAdornment from '@material-ui/core/InputAdornment'
import { useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'

import SearchDialog from '../containers/search-dialog'

const SearchInput = ({ onSelect, onCustomerCreate }) => {
    const theme = useTheme()
    const [searchValue, setSearchValue] = useState('')
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value)
    }

    const handleDialogClose = (item) => {
        setSearchValue('')
        onSelect && onSelect(item)
    }

    return (
        <div>
            <TextField
                style={{ marginTop: 5 }}
                onInput={handleSearchChange}
                value={searchValue}
                placeholder={gettext('Search for customer address')}
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon style={{ color: theme.palette.grey[500] }} />
                        </InputAdornment>
                    ),
                    style: { height: 30, fontSize: '0.9rem' },
                }}
            />

            {!!searchValue && (
                <SearchDialog
                    initialSearchValue={searchValue}
                    createCustomer
                    onCustomerCreate={onCustomerCreate}
                    onClose={handleDialogClose}
                />
            )}
        </div>
    )
}

export default SearchInput
