import { memo, useMemo } from 'react'
import { useFormState, useWatch } from 'react-hook-form'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'

import BasicProductInfo from '../basic-product-info'

import { MediaType } from '~/common/schemas/media'
import { MAX_PRODUCT_IMAGES, MAX_PRODUCT_VIDEOS, MAX_SKU_IMAGES } from '~/constants'
import { CHANNELS_WITH_SHORT_DESCRIPTION } from '~/consts'
import MediaShowcase from '~/products/components/media-showcase'
import { useProductFormContext } from '~/products/hooks/useProductForm'
import { useProductPage } from '~/products/hooks/useProductPage'
import { useProductPageActions, useProductPageTab } from '~/products/hooks/useProductPageStore'
import { useCommonStyles } from '~/products/styles/index'
import { getFieldInfoByShop } from '~/products/utils/getFieldLengths'

type ChannelStockUnitImagesProps = {
    cpIndex: number
    csuIndex: number
}

const ChannelStockUnitImages = memo(function ChannelStockUnitImages({
    cpIndex,
    csuIndex,
}: ChannelStockUnitImagesProps) {
    const { getValues } = useProductFormContext()

    const sku =
        getValues(`stock_units.${csuIndex}.sku`) ||
        interpolate(gettext('Stock unit %s'), [csuIndex + 1])

    return (
        <Box mb={1.25}>
            <Typography variant="subtitle2">{sku}</Typography>
            <MediaShowcase
                mediaPath={`channel_products.${cpIndex}.grouped_media`}
                fieldPath={`channel_products.${cpIndex}.channel_stock_units.${csuIndex}.images`}
                mediaType={MediaType.IMAGE}
                maxMediaObjs={MAX_SKU_IMAGES}
            />
        </Box>
    )
})

type Props = {
    open: boolean
    channelProductIndex: number
    onToggleSpecificMedia: (checked: boolean) => void
}

export default memo(function DialogChannelProductEdit({
    open,
    channelProductIndex,
    onToggleSpecificMedia,
}: Props) {
    const name = `channel_products.${channelProductIndex}` as const
    const commonClasses = useCommonStyles()
    const { isNewProduct } = useProductPage()
    const { control, register } = useProductFormContext()
    const channelProduct = useWatch({ control, name })

    const fieldInfo = useMemo(() => {
        return getFieldInfoByShop(channelProduct.shop)
    }, [channelProduct])

    const languages = useMemo(() => {
        const base = ['en']

        if (channelProduct.shop.local_language) {
            base.unshift(channelProduct.shop.local_language)
        }

        return base
    }, [channelProduct])

    const tab = useProductPageTab()
    const { changeTab, resetSelectedChannelProductIndex, closeDialog } = useProductPageActions()
    const mediaPath = `${name}.grouped_media`
    const imagesFieldPath = `${name}.images` as const
    const { ref: ImageErrorRef } = register(imagesFieldPath)
    const videosFieldPath = `${name}.videos` as const
    const { errors } = useFormState({ control, name: [videosFieldPath, imagesFieldPath] })

    const imageErrors = errors.channel_products?.[channelProductIndex]?.images
    const videoErrors = errors.channel_products?.[channelProductIndex]?.videos

    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            TransitionProps={{ onExited: resetSelectedChannelProductIndex }}
            onClose={closeDialog}
        >
            <DialogTitle>
                <Stack direction="row" alignItems="center" gap={1.25}>
                    <Typography>{gettext('Edit channel specific info')}</Typography>
                    <img
                        width={25}
                        alt={channelProduct.shop.shop_name}
                        src={channelProduct.shop.channel.channel_square_icon_url}
                    />
                    <Typography>{channelProduct.shop.shop_name}</Typography>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <BasicProductInfo
                    tab={tab}
                    languages={languages}
                    field="channel_product"
                    channelProductIndex={channelProductIndex}
                    nameMaxLength={fieldInfo.name.maxLength}
                    descriptionMaxLength={fieldInfo.description.maxLength}
                    hasShortDescription={CHANNELS_WITH_SHORT_DESCRIPTION.includes(
                        channelProduct.shop.channel.id as never
                    )}
                    onChangeTab={changeTab}
                />

                {!isNewProduct && (
                    <>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={channelProduct.has_channel_specific_media}
                                    color="secondary"
                                    onChange={(_, checked) => onToggleSpecificMedia(checked)}
                                />
                            }
                            label={gettext('Separate media for this channel')}
                        />
                        {channelProduct.has_channel_specific_media && (
                            <Box mt={1.25}>
                                <Box mt={2}>
                                    <Typography variant="subtitle2">
                                        {gettext('Product videos')}
                                    </Typography>
                                    {videoErrors && (
                                        <Typography
                                            className={commonClasses.productImageErrorText}
                                            variant="caption"
                                        >
                                            {videoErrors.message}
                                        </Typography>
                                    )}

                                    <MediaShowcase
                                        mediaPath={mediaPath}
                                        fieldPath={videosFieldPath}
                                        mediaType={MediaType.VIDEO}
                                        maxMediaObjs={MAX_PRODUCT_VIDEOS}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <div ref={ImageErrorRef}>
                                        <Typography variant="subtitle2">
                                            {gettext('Product images')}
                                        </Typography>

                                        {imageErrors && (
                                            <Typography
                                                className={commonClasses.productImageErrorText}
                                                variant="caption"
                                            >
                                                {imageErrors.message}
                                            </Typography>
                                        )}
                                    </div>
                                    <MediaShowcase
                                        mediaPath={mediaPath}
                                        fieldPath={imagesFieldPath}
                                        mediaType={MediaType.IMAGE}
                                        maxMediaObjs={MAX_PRODUCT_IMAGES}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        p: 2,
                                        mt: 2.5,
                                        border: '1px solid',
                                        borderColor: 'grey.400',
                                    }}
                                >
                                    {channelProduct.channel_stock_units.map((csu, csuIndex) => (
                                        <ChannelStockUnitImages
                                            key={csu.id ?? csuIndex}
                                            cpIndex={channelProductIndex}
                                            csuIndex={csuIndex}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )}
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <Button color="secondary" onClick={closeDialog}>
                    {gettext('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
})
