import SearchFilter from '~/components/search-filter'

type Props = {
    /**
     * The amount of time (in milliseconds) that the component will wait after the user stops typing before it triggers a search.
     *
     * If not provided, the `SearchFilter` component will use its default debounce time (200ms).
     */
    debounceTime?: number
}

/**
 * `StockItemFilter` is a React component for filtering stock items.
 * It uses the `SearchFilter` component to provide a search interface for the user.
 *
 * @example
 * <StockItemFilter debounceTime={500} />
 *
 * @returns The rendered `SearchFilter` component with specific filter values for stock items.
 */
export default function StockItemFilter({ debounceTime }: Props) {
    return (
        <SearchFilter
            debounceTime={debounceTime}
            searchPlaceholder={gettext('Search by SKU, item name')}
            filterValues={[
                {
                    field: 'Bundle',
                    lookup: 'is_bundle',
                    select_multiple: false,
                    can_select_exclusive: false,
                    values: [
                        { label: 'Is bundle', value: 'True' },
                        { label: 'Is not bundle', value: 'False' },
                    ],
                },
            ]}
        />
    )
}
