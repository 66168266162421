import React from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles'

const imageListStyles = (theme) => ({
    imageList: {
        listStyle: 'none',
        marginTop: 10,
        marginBottom: 10,
        padding: 0,
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        '& li': {
            display: 'inline-block',
            marginRight: 10,
            marginBottom: 10,

            '& img': {
                height: 140,
                cursor: 'pointer',
            },
        },
        '& li:last-child': {
            marginRight: 0,
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
    },
})

class ImageList extends React.Component {
    state = {
        enlargeImage: null,
        showEnlargeImageModal: false,
    }

    handleImageClick = (item) => {
        this.setState({
            enlargeImage: this.props.staticFilesDomain + item.image,
            showEnlargeImageModal: true,
        })
    }

    render() {
        const { classes, images, staticFilesDomain } = this.props
        const { enlargeImage, showEnlargeImageModal } = this.state

        return (
            <div>
                <ul className={classes.imageList}>
                    {images.map((img, i) => (
                        <li key={i}>
                            <img
                                src={staticFilesDomain + img.image}
                                onClick={() => this.handleImageClick(img)}
                            />
                        </li>
                    ))}
                </ul>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={showEnlargeImageModal}
                    onClose={() => this.setState({ showEnlargeImageModal: false })}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={showEnlargeImageModal}>
                        <div className={classes.paper}>
                            {enlargeImage && (
                                <img
                                    src={enlargeImage}
                                    style={{ maxWidth: '90vw', maxHeight: '90vh' }}
                                />
                            )}
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}

export default withStyles(imageListStyles)(ImageList)
