import React from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CancelIcon from '@material-ui/icons/Cancel'
import InfoIcon from '@material-ui/icons/Info'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'
import classNames from 'classnames'
import Cookie from 'js-cookie'
import moment from 'moment'
import PropTypes from 'prop-types'

import { getPath } from '../../oakra/components/base-app'
import { debounce, getTranslation, copyObject } from '../../tools/utils'
import StockItemSelector from '../containers/stock-item-selector'

import 'moment/locale/en-gb'
import 'moment/locale/th'

const styles = (theme) => ({
    section: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 20,
        marginBottom: 20,
    },
    sectionTitle: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid ' + grey[200],
        marginLeft: -20,
        marginRight: -20,
        paddingLeft: 20,
        paddingBottom: 10,
    },
    titleMessage: {
        marginLeft: 20,
        color: grey[500],
    },
    textField: {
        marginRight: 25,
    },
    nameField: {
        width: 250,
    },
    adjustmentGroupFields: {
        display: 'flex',
        alignItems: 'baseline',
        padding: '20px 10px',
    },
    clearIcon: {
        fontSize: 20,
    },
    orText: {
        marginRight: 20,
        color: grey[500],
    },
    toolbar: {
        position: 'fixed',
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
    },
    tableHeader: {
        borderTop: '1px solid ' + grey[200],
    },
    tableCell: {
        padding: '5px 12px',
    },
    tableCellSmallText: {
        padding: '5px 12px',
        fontSize: 12,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    checkboxColumnHeader: {
        width: 70,
    },
    checkboxRowCell: {
        padding: '0 12px',
    },
    channelIcon: {
        width: 50,
        marginBottom: 5,
        display: 'block',
    },
    bottomPagination: {
        marginBottom: 50,
    },
    bottomBar: {
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        backgroundColor: 'white',
        marginLeft: -20,
        marginRight: -20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid ' + grey[200],
        zIndex: 999,
        justifyContent: 'space-between',
        width: 'calc(100% - 200px)',
    },
    saveActions: {
        display: 'flex',
        alignItems: 'center',
    },
    savedMessage: {
        color: grey[500],
        marginRight: 15,
    },
    hasErrorsMessage: {
        color: theme.palette.error.main,
        marginRight: 15,
    },
    saveProgress: {
        marginRight: 15,
    },
    saveButton: {
        color: 'white',
    },
    noDataMessage: {
        textAlign: 'center',
        paddingTop: 75,
        paddingBottom: 75,
        paddingLeft: 24,
    },
    noDataText: {
        color: grey[400],
    },
    tableAddButton: {
        marginTop: 20,
    },
    channelIconImg: {
        display: 'inline',
        paddingRight: 4,
        height: 20,
        marginRight: 2,
    },
    channelIconText: {
        fontSize: 12,
        fontWeight: 500,
    },
    channelTitleCell: {
        padding: '14px 10px 10px',
    },
    channelTitleBordered: {
        borderLeft: '1px solid #eee',
    },
    channelTitleBlock: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    truncatedDescription: {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        'word-break': 'none',
        overflow: 'hidden',
        hyphens: 'auto',
    },
    hintMessage: {
        padding: '0 0 10px 10px',
        color: grey[500],
    },
})

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        minHeight: 67,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    searchBar: {
        width: 350,
        marginLeft: 20,
        marginTop: 0,
        marginBottom: 0,
    },
    bulkField: {
        marginRight: 0,
        marginLeft: 15,
        marginTop: 0,
        marginBottom: 0,
        width: 150,
    },
    spacer: {
        flex: '1 1 auto',
    },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },
    topPagination: {},
    title: {
        flex: '0 0 auto',
    },
    toolbarItems: {
        display: 'flex',
        alignItems: 'baseline',
    },
    actions: {
        display: 'flex',
        color: theme.palette.text.secondary,
    },
    addButton: {
        height: 38,
        alignSelf: 'center',
        width: 175,
    },
})

const columns = [
    { id: 'sku', align: 'left', label: 'SKU', sort: false, width: 130 },
    { id: 'name', align: 'left', label: gettext('Name'), sort: false, width: 350 },
    { id: 'quantity', align: 'left', label: gettext('Set quantity'), sort: true, width: 130 },
]

const sortByShop = (channelStockAdjustments) => {
    return channelStockAdjustments.sort((a, b) => {
        return a.shopID > b.shopID ? 1 : b.shopID > a.shopID ? -1 : 0
    })
}

const sortShopsByID = (shops) => {
    return shops.sort((shopA, shopB) => {
        return shopA.id > shopB.id ? 1 : shopB.id > shopA.id ? -1 : 0
    })
}

class StockAdjustmentTableHeadWithoutStyle extends React.PureComponent {
    render() {
        const { onSelectAllClick, updateQuantityForShop, numSelected, rowCount, shops, classes } =
            this.props

        return (
            <TableHead className={classes.tableHeader}>
                <TableRow>
                    {[{ width: 70 }, ...columns].map(({ width }, index) => (
                        <TableCell {...(width && { width })} key={index}></TableCell>
                    ))}
                    {shops.map((shop) => (
                        <TableCell
                            className={classNames(
                                classes.channelTitleBordered,
                                classes.channelTitleCell
                            )}
                            width={130}
                            align="center"
                            key={shop.id}
                        >
                            <div className={classes.channelTitleBlock}>
                                <img
                                    className={classes.channelIconImg}
                                    src={shop.channel.channel_square_icon_url}
                                />
                                <Typography className={classes.channelIconText}>
                                    {shop.shop_name}
                                </Typography>
                            </div>
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    <TableCell className={classes.tableCell}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount !== 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {columns.map((column) => {
                        return (
                            <TableCell
                                className={classes.tableCellSmallText}
                                key={column.id}
                                align={column.align}
                                width={column.width}
                            >
                                {column.label}
                            </TableCell>
                        )
                    }, this)}
                    {shops.map((shop) => (
                        <React.Fragment key={shop.id}>
                            <TableCell
                                className={classNames(
                                    classes.tableCellSmallText,
                                    classes.channelTitleBordered
                                )}
                                align="center"
                                width={130}
                            >
                                <TextField
                                    variant="outlined"
                                    onChange={updateQuantityForShop(shop)}
                                    placeholder="Set quantity"
                                    inputProps={{
                                        style: {
                                            textAlign: 'right',
                                            fontSize: '0.9rem',
                                        },
                                    }}
                                    InputProps={{
                                        style: { height: '38px' },
                                    }}
                                    type="number"
                                />
                            </TableCell>
                        </React.Fragment>
                    ))}
                </TableRow>
            </TableHead>
        )
    }
}

StockAdjustmentTableHeadWithoutStyle.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const StockAdjustmentTableHead = withStyles(styles)(StockAdjustmentTableHeadWithoutStyle)

class StockAdjustmentsTableToolbarWithoutStyle extends React.PureComponent {
    render() {
        const {
            store,
            channel,
            numSelected,
            handleChangePage,
            handleAddStockItems,
            handleRemoveStockItems,
            searchHandler,
            page,
            count,
            classes,
            updateQuantityForAll,
            disabledBulkEdit,
        } = this.props

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                {this.props.loading && (
                    <LinearProgress className={classes.progress} color="secondary" />
                )}
                <div className={classes.title}>
                    {numSelected > 0 ? (
                        <Typography color="inherit" variant="subtitle1">
                            {interpolate(pgettext('Number selected', '%s selected'), [numSelected])}
                        </Typography>
                    ) : (
                        <Typography variant="subtitle1" className={classes.sectionTitle}>
                            {gettext('Stock items')}
                        </Typography>
                    )}
                </div>
                <TextField
                    id="search"
                    type="search"
                    className={classes.searchBar}
                    margin="normal"
                    onChange={searchHandler}
                    variant="outlined"
                    placeholder={gettext('Search stock items')}
                    InputProps={{ style: { height: '38px' } }}
                />
                <TextField
                    className={classes.bulkField}
                    variant="outlined"
                    onChange={updateQuantityForAll}
                    placeholder={gettext('Adjust all ')}
                    margin="normal"
                    type="number"
                    disabled={disabledBulkEdit}
                    inputProps={{
                        style: { textAlign: 'right' },
                    }}
                    InputProps={{
                        style: { height: '38px' },
                    }}
                />
                <Tooltip
                    title={gettext('Adjust all will apply the value to all items in this group.')}
                    placement="right"
                >
                    <IconButton>
                        <InfoIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    {numSelected > 0 ? (
                        <React.Fragment>
                            <Button color="secondary" onClick={handleRemoveStockItems}>
                                {gettext('Remove from adjustment group')}
                            </Button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.addButton}
                                onClick={handleAddStockItems}
                                disabled={!store || Boolean(channel)}
                            >
                                <AddIcon />
                                {gettext('Add items')}
                            </Button>
                            <TablePagination
                                className={classes.topPagination}
                                component="div"
                                count={count}
                                rowsPerPage={25}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': gettext('Previous'),
                                }}
                                nextIconButtonProps={{
                                    'aria-label': gettext('Next'),
                                }}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[25]}
                            />
                        </React.Fragment>
                    )}
                </div>
            </Toolbar>
        )
    }
}

StockAdjustmentsTableToolbarWithoutStyle.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
}

const StockAdjustmentsTableToolbar = withStyles(toolbarStyles)(
    StockAdjustmentsTableToolbarWithoutStyle
)

class StockAdjustmentGroup extends React.PureComponent {
    constructor(props) {
        super(props)

        const storeID = this.props.match.params.store_id

        this.state = {
            adjustmentGroup: {
                name: '',
                start_date: null,
                end_date: null,
                // num_products: '',
                store: storeID ? parseInt(storeID, 10) : null,
                // channel: null,
                status: 'upcoming',
                is_active: true,
            },
            errors: {
                name: '',
                start_date: '',
                end_date: '',
            },
            shops: [],
            stockAdjustmentData: [],
            searchResults: null,
            selected: [],
            rowsPerPage: 25,
            page: 0,
            loading: true,
            newAdjustmentGroup: props.location.pathname.includes('create'),
            displayStockItemSelector: false,
            saving: false,
        }

        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleDateTimeChange = this.handleDateTimeChange.bind(this)
        this.clearDatePicker = this.clearDatePicker.bind(this)
        this.fetchAdjustmentGroup = this.fetchAdjustmentGroup.bind(this)
        this.fetchStockItems = this.fetchStockItems.bind(this)
        this.handleAddStockItems = this.handleAddStockItems.bind(this)
        this.handleRemoveStockItems = this.handleRemoveStockItems.bind(this)
        this.handleChecked = this.handleChecked.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this)
        this.handleSelectedStockItems = this.handleSelectedStockItems.bind(this)
        this.updateQuantityForRow = this.updateQuantityForRow.bind(this)
        this.updateQuantityForShop = this.updateQuantityForShop.bind(this)
        this.handleAdjustmentQuantityChanged = this.handleAdjustmentQuantityChanged.bind(this)
        this.updateQuantityForAll = this.updateQuantityForAll.bind(this)
        this.isSelected = this.isSelected.bind(this)
        this.searchHandler = this.searchHandler.bind(this)
        this.searchStockItems = this.searchStockItems.bind(this)

        this.saveHandler = this.saveHandler.bind(this)
        this.saveAdjustmentGroup = this.saveAdjustmentGroup.bind(this)
        this.saveStockItems = this.saveStockItems.bind(this)

        this.searchStockItemsDebounced = debounce(this.searchStockItems, 500)
    }

    componentDidMount() {
        if (this.state.newAdjustmentGroup) {
            this.setState({ loading: false })
        } else {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.fetchAdjustmentGroup(() => {})

            this.fetchStockItems(() => {
                this.setState({ loading: false })
            })
        }
    }

    fetchAdjustmentGroup(callback) {
        const { stock_adjustment_group_pk } = this.props.match.params
        const { stockAdjustmentGroupAPIURL } = this.props

        fetch(
            stockAdjustmentGroupAPIURL.replace(
                'stock_adjustment_group_pk',
                stock_adjustment_group_pk
            )
        )
            .then((response) => response.json())
            .then((adjustmentGroup) => {
                this.setState({ adjustmentGroup }, callback)
            })
    }

    getShopsById = () => {
        const shopsById = {}
        this.props.stores.forEach((store) => {
            store.shops?.forEach((shop) => {
                shopsById[shop.id] = shop
            })
        })
        return shopsById
    }

    fetchStockItems(callback) {
        const { stock_adjustment_group_pk } = this.props.match.params
        const { stockAdjustmentsAPIURL, stores } = this.props
        const shopsById = this.getShopsById()

        fetch(
            stockAdjustmentsAPIURL.replace('stock_adjustment_group_pk', stock_adjustment_group_pk)
        )
            .then((response) => response.json())
            .then((channelStockAdjustments) => {
                const shopIDs = new Set()
                const shops = []

                const adjustmentsGroupByStockItems = {}

                channelStockAdjustments.forEach((csa) => {
                    if (!shopIDs.has(csa.shop)) {
                        shopIDs.add(csa.shop)
                        shops.push(shopsById[csa.shop])
                    }
                    let item = adjustmentsGroupByStockItems[csa.stock_item.id]
                    if (!item) {
                        item = {
                            stock_item: csa.stock_item,
                            channel_stock_adjustments: [],
                            quantity: '',
                        }
                        adjustmentsGroupByStockItems[csa.stock_item.id] = item
                    }
                    item.channel_stock_adjustments.push({
                        quantity: csa.quantity,
                        shopID: csa.shop,
                        errors: {},
                    })
                })

                Object.values(adjustmentsGroupByStockItems).forEach((row) => {
                    const shopIDs = new Set(row.channel_stock_adjustments.map((csa) => csa.shopID))
                    shops.forEach((shop) => {
                        if (!shopIDs.has(shop.id)) {
                            row.channel_stock_adjustments.push({
                                shopID: shop.id,
                                quantity: '',
                                errors: {},
                            })
                        }
                    })
                    sortByShop(row.channel_stock_adjustments)
                })

                const sortedShops = sortShopsByID(shops)
                this.setState(
                    {
                        stockAdjustmentData: Object.values(adjustmentsGroupByStockItems),
                        shops: sortedShops,
                        loading: false,
                    },
                    callback
                )
            })
    }

    handleNameChange(event) {
        const adjustmentGroup = copyObject(this.state.adjustmentGroup)
        const name = event.target.value
        adjustmentGroup.name = name

        const errors = copyObject(this.state.errors)

        if (name) {
            errors.name = ''
        } else {
            errors.name = gettext('Cannot be empty')
        }

        this.setState({ adjustmentGroup, errors })
    }

    handleDateTimeChange(field) {
        return (date) => {
            const errors = copyObject(this.state.errors)

            const startDate = this.state.adjustmentGroup.start_date
            const endDate = this.state.adjustmentGroup.end_date
            const minStartDate = moment().add(2, 'hours')

            const adjustmentGroup = copyObject(this.state.adjustmentGroup)

            if (
                (field == 'start_date' && date.isAfter(endDate)) ||
                (field == 'end_date' && date.isBefore(startDate))
            ) {
                errors.start_date = gettext('Cannot be after end date')
                errors.end_date = gettext('Cannot be before start date')
            } else {
                errors.start_date = ''
                errors.end_date = ''
                adjustmentGroup[field] = date.format()
            }

            // automatically set end_date to start date if we're setting start_date and end_date is empty
            if (field == 'start_date' && adjustmentGroup.end_date == null) {
                adjustmentGroup.end_date = adjustmentGroup.start_date
            }

            this.setState({ adjustmentGroup, errors })
        }
    }

    handleChecked(event, checked, row) {
        const selectedStockItemIDs = this.state.selected.map((pd) => pd.stock_item.id)
        let newSelected = copyObject(this.state.selected)

        if (checked) {
            if (!selectedStockItemIDs.includes(row.stock_item.id)) {
                newSelected.push(row)
            }
        } else {
            newSelected = newSelected.filter((pd) => pd.stock_item.id != row.stock_item.id)
        }

        this.setState({ selected: newSelected })
    }

    handleChangePage(event, page) {
        this.setState({
            page: page,
        })
    }

    handleSelectAllClick(event, checked) {
        if (checked) {
            this.setState((state) => ({
                selected: copyObject(this.state.searchResults || this.state.stockAdjustmentData),
            }))
            return
        } else {
            this.setState({ selected: [] })
        }
    }

    handleAddStockItems() {
        this.setState({ displayStockItemSelector: true })
    }

    handleRemoveStockItems() {
        const selectedStockItemIDs = this.state.selected.map((pd) => pd.stock_item.id)
        const newState = {
            selected: [],
            stockAdjustmentData: copyObject(this.state.stockAdjustmentData).filter(
                (pd) => !selectedStockItemIDs.includes(pd.stock_item.id)
            ),
        }

        if (this.state.searchResults) {
            newState.searchResults = copyObject(this.state.searchResults).filter(
                (pd) => !selectedStockItemIDs.includes(pd.stock_item.id)
            )
        }

        this.setState(newState)
    }

    clearDatePicker(field) {
        const adjustmentGroup = copyObject(this.state.adjustmentGroup)

        return (e) => {
            e.stopPropagation()
            adjustmentGroup[field] = null
            this.setState({ adjustmentGroup })
        }
    }

    handleSelectedStockItems(stockItems) {
        const shopsById = this.getShopsById()
        const shopIDs = new Set(this.state.shops.map((s) => s.id))
        let shops = copyObject(this.state.shops)

        stockItems.forEach((p) => {
            p.channel_stock_items.forEach((cp) => {
                const shopID = cp.shop_id
                if (!shopIDs.has(shopID) && shopsById[shopID]) {
                    shopIDs.add(shopID)
                    shops.push(shopsById[shopID])
                }
            })
        })
        shops = sortShopsByID(shops)

        const newStockAdjustments = this.makePlaceholderChannelStockAdjustments(stockItems, shops)

        let stockAdjustmentData = copyObject(this.state.stockAdjustmentData)
        stockAdjustmentData = stockAdjustmentData.concat(newStockAdjustments)

        this.setState({
            shops,
            stockAdjustmentData,
            displayStockItemSelector: false,
        })
    }

    makePlaceholderChannelStockAdjustments(stockItems, shops) {
        return stockItems.map((su) => {
            const channelStockAdjustments = []

            shops.forEach((shop) => {
                channelStockAdjustments.push({
                    shopID: shop.id,
                    errors: {},
                })
            })

            sortByShop(channelStockAdjustments)

            return {
                stock_item: su,
                channel_stock_adjustments: channelStockAdjustments,
                errors: {},
            }
        })
    }

    updateQuantityForAll(event) {
        let value = event.target.value
        const stockAdjustmentData = copyObject(this.state.stockAdjustmentData)
        this.updateStockAdjustmentWithNewQuantity(stockAdjustmentData, value)
        this.setState({
            stockAdjustmentData,
        })
    }

    updateStockAdjustmentWithNewQuantity = (data, quantity, stockItemID, shopID) => {
        data.forEach((row) => {
            if (stockItemID && row.stock_item.id !== stockItemID) {
                return
            }

            row.channel_stock_adjustments.forEach((csa) => {
                if (shopID && csa.shopID !== shopID) {
                    return
                }
                csa.quantity = quantity
                csa.errors = {}
            })
        })
    }

    updateQuantityForRow(row) {
        return (event) => {
            const value = event.target.value
            console.log('updateQuantityForRow', row, value)
            const stockItemID = row.stock_item.id

            const stockAdjustmentData = copyObject(this.state.stockAdjustmentData)
            const searchResults =
                this.state.searchResults === null ? null : copyObject(this.state.searchResults)

            this.updateStockAdjustmentWithNewQuantity(stockAdjustmentData, value, stockItemID)
            if (searchResults) {
                this.updateStockAdjustmentWithNewQuantity(searchResults, value, stockItemID)
            }
            this.setState({
                searchResults: searchResults,
                stockAdjustmentData: stockAdjustmentData,
            })
        }
    }

    updateQuantityForShop(shop) {
        return (event) => {
            const value = event.target.value
            console.log('updateQuantityForShop', shop, value)
            const shopID = shop.id

            const stockAdjustmentData = copyObject(this.state.stockAdjustmentData)
            const searchResults =
                this.state.searchResults === null ? null : copyObject(this.state.searchResults)

            this.updateStockAdjustmentWithNewQuantity(stockAdjustmentData, value, null, shopID)
            if (searchResults) {
                this.updateStockAdjustmentWithNewQuantity(searchResults, value, null, shopID)
            }
            this.setState({
                searchResults: searchResults,
                stockAdjustmentData: stockAdjustmentData,
            })
        }
    }

    handleAdjustmentQuantityChanged(editingRow, shopID) {
        const stockItemID = editingRow.stock_item.id
        return (event) => {
            console.log('handleAdjustmentQuantityChanged', editingRow, shopID, event.target.value)
            if (event.target.value.includes('-')) {
                return
            }
            const stockAdjustmentData = copyObject(this.state.stockAdjustmentData)
            const searchResults =
                this.state.searchResults === null ? null : copyObject(this.state.searchResults)

            const newQuantity = event.target.value === '' ? '' : parseFloat(event.target.value)

            this.updateStockAdjustmentWithNewQuantity(
                stockAdjustmentData,
                newQuantity,
                stockItemID,
                shopID
            )

            if (searchResults) {
                this.updateStockAdjustmentWithNewQuantity(
                    searchResults,
                    newQuantity,
                    stockItemID,
                    shopID
                )
            }
            this.setState({
                searchResults: searchResults,
                stockAdjustmentData: stockAdjustmentData,
            })
        }
    }

    isSelected(stockItemID) {
        const selectedStockItemIDs = this.state.selected.map((pd) => pd.stock_item.id)
        return selectedStockItemIDs.includes(stockItemID)
    }

    searchStockItems(searchTerm) {
        if (!searchTerm) {
            this.setState({ searchResults: null, page: 0 })
            return
        }

        const stockAdjustmentData = copyObject(this.state.stockAdjustmentData)

        const filteredStockItems = stockAdjustmentData.filter((pd) => {
            if (
                pd.stock_item.sku &&
                pd.stock_item.sku.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
                return true
            }

            if (
                pd.stock_item.name &&
                pd.stock_item.name.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
                return true
            }
            return false
        })

        this.setState({
            searchResults: filteredStockItems,
            page: 0,
        })
    }

    searchHandler(event) {
        const searchTerm = event.target.value

        this.searchStockItemsDebounced(searchTerm)
    }

    stockItemHasErrors = () => {
        const adjustmentGroupWithError = this.state.stockAdjustmentData.filter((row) => {
            const csuds = row.channel_stock_adjustments.filter((csa) => {
                return csa?.errors && Object.keys(csa.errors).length > 0
            })
            return csuds?.length > 0
        })

        return adjustmentGroupWithError?.length > 0
    }

    adjustmentGroupComplete = () => {
        const {
            name: name_error,
            start_date: start_date_error,
            end_date: end_date_error,
        } = this.state.errors
        const { name, start_date, end_date } = this.state.adjustmentGroup

        return (
            (!name_error || !start_date_error || !end_date_error) &&
            !!name &&
            !!start_date &&
            !!end_date
        )
    }

    saveAdjustmentGroup() {
        const { stockAdjustmentGroupCreateAPIURL } = this.props

        return fetch(stockAdjustmentGroupCreateAPIURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRFToken': Cookie.get('csrftoken'),
            },
            body: JSON.stringify(this.state.adjustmentGroup),
        })
    }

    saveStockItems() {
        const { stockAdjustmentsUpdateAPIURL } = this.props
        const data = []
        this.state.stockAdjustmentData.map((r) => {
            r.channel_stock_adjustments.map((csa) =>
                data.push({
                    stock_item_id: r.stock_item.id,
                    shop_id: csa.shopID,
                    quantity: csa.quantity || null,
                })
            )
        })

        return fetch(
            stockAdjustmentsUpdateAPIURL.replace(
                'stock_adjustment_group_pk',
                this.state.adjustmentGroup.id
            ),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRFToken': Cookie.get('csrftoken'),
                },
                body: JSON.stringify(data),
            }
        )
    }

    saveHandler() {
        window.analytics.track('Save stock group adjustment', {
            page: window.location.href,
            feature: 'stock',
            sub_feature: 'adjustment',
            type: this.state.newAdjustmentGroup ? 'create' : 'update',
            trigger: 'button click',
        })

        this.setState({ saving: true }, () => {
            if (this.state.newAdjustmentGroup) {
                this.saveAdjustmentGroup()
                    .then((response) => response.json())
                    .then((adjustmentGroup) => {
                        this.setState({ adjustmentGroup: adjustmentGroup }, () => {
                            this.saveStockItems().then(() => {
                                window.location.href = getPath('stockAdjustmentGroups')
                            })
                        })
                    })
            } else {
                this.saveStockItems().then(() => {
                    window.location.href = getPath('stockAdjustmentGroups')
                })
            }
        })
    }

    render() {
        const { classes, language, staticRoot, stores } = this.props
        const { selected, rowsPerPage, page } = this.state

        const storeName = this.state.adjustmentGroup.store
            ? this.props.stores.find((store) => store.id == this.state.adjustmentGroup.store).name
            : ''

        let displayedRows =
            this.state.searchResults === null
                ? this.state.stockAdjustmentData
                : this.state.searchResults
        displayedRows = displayedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

        moment.locale(language)

        let title
        if (this.state.newAdjustmentGroup) {
            title = gettext('New Stock Adjustment Group (%s)')
        } else {
            title = gettext('Stock Adjustment Group (%s)')
        }
        title = interpolate(title, [storeName])

        return (
            <React.Fragment>
                <Paper className={classes.section}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>
                        {title}
                        <Tooltip
                            title={gettext(
                                'Name and dates cannot be changed after group is saved.'
                            )}
                            placement="right"
                        >
                            <IconButton>
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                    <div className={classes.adjustmentGroupFields}>
                        <TextField
                            className={classNames(classes.nameField, classes.textField)}
                            label={gettext('Name')}
                            autoFocus
                            margin="dense"
                            name="name"
                            type="text"
                            variant="outlined"
                            value={this.state.adjustmentGroup.name}
                            onChange={this.handleNameChange}
                            error={Boolean(this.state.errors.name)}
                            helperText={this.state.errors.name}
                            disabled={!this.state.newAdjustmentGroup}
                        />

                        <React.Fragment>
                            <MuiPickersUtilsProvider
                                className={classNames(classes.textField, classes.dateField)}
                                utils={MomentUtils}
                                locale={language}
                                value={this.state.adjustmentGroup.start_date}
                                moment={moment}
                            >
                                <DateTimePicker
                                    value={this.state.adjustmentGroup.start_date}
                                    label={gettext('Start date')}
                                    margin="dense"
                                    className={classNames(classes.textField, classes.dateField)}
                                    onChange={this.handleDateTimeChange('start_date')}
                                    format="lll"
                                    disabled={!this.state.newAdjustmentGroup}
                                    error={Boolean(this.state.errors.start_date)}
                                    helperText={this.state.errors.start_date}
                                    disablePast={true}
                                    maxDate={
                                        this.state.adjustmentGroup.end_date
                                            ? this.state.adjustmentGroup.end_date
                                            : moment().add(5, 'y')
                                    }
                                    inputVariant="outlined"
                                    InputProps={{
                                        endAdornment: this.state.newAdjustmentGroup && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={this.clearDatePicker('start_date')}
                                                >
                                                    <CancelIcon className={classes.clearIcon} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider
                                className={classNames(classes.textField, classes.dateField)}
                                utils={MomentUtils}
                                locale={language}
                                value={this.state.adjustmentGroup.end_date}
                                moment={moment}
                            >
                                <DateTimePicker
                                    value={this.state.adjustmentGroup.end_date}
                                    label={gettext('End date')}
                                    margin="dense"
                                    className={classNames(classes.textField, classes.dateField)}
                                    onChange={this.handleDateTimeChange('end_date')}
                                    format="lll"
                                    disabled={!this.state.newAdjustmentGroup}
                                    error={Boolean(this.state.errors.end_date)}
                                    helperText={this.state.errors.end_date}
                                    disablePast={true}
                                    maxDate={moment().add(5, 'y')}
                                    inputVariant="outlined"
                                    InputProps={{
                                        endAdornment: this.state.newAdjustmentGroup && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={this.clearDatePicker('end_date')}
                                                >
                                                    <CancelIcon className={classes.clearIcon} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </React.Fragment>
                    </div>
                    <div className={classes.hintMessage}>
                        {gettext(
                            'Stock adjustment quantities will be added to the calculated stock for each shop.'
                        )}
                    </div>
                </Paper>
                {this.adjustmentGroupComplete() && (
                    <Paper>
                        <StockAdjustmentsTableToolbar
                            className={classes.toolbar}
                            store={this.state.adjustmentGroup.store}
                            channel={this.state.adjustmentGroup.channel}
                            numSelected={selected.length}
                            handleChangePage={this.handleChangePage}
                            handleRemoveStockItems={this.handleRemoveStockItems}
                            handleAddStockItems={this.handleAddStockItems}
                            updateQuantityForAll={this.updateQuantityForAll}
                            disabledBulkEdit={
                                this.state.searchResults !== null ||
                                Boolean(this.state.adjustmentGroup.channel)
                            }
                            count={
                                this.state.searchResults !== null
                                    ? this.state.searchResults.length
                                    : this.state.stockAdjustmentData.length
                            }
                            page={this.state.page}
                            loading={this.state.loading}
                            searchHandler={this.searchHandler}
                        />
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <StockAdjustmentTableHead
                                    className={classes.tableHead}
                                    numSelected={selected.length}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    updateQuantityForShop={this.updateQuantityForShop}
                                    rowCount={displayedRows.length}
                                    shops={this.state.shops}
                                />
                                <TableBody>
                                    {!this.state.loading &&
                                        (this.state.searchResults === null
                                            ? this.state.stockAdjustmentData.length == 0
                                            : this.state.searchResults.length == 0) && (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={4}
                                                    className={classNames(
                                                        classes.noDataMessage,
                                                        classes.tableCell
                                                    )}
                                                >
                                                    <img
                                                        className={classes.noDataIcon}
                                                        src={
                                                            staticRoot + 'web/img/product-gray.png'
                                                        }
                                                    />
                                                    <Typography
                                                        className={classes.noDataText}
                                                        variant="subtitle1"
                                                    >
                                                        {gettext('No products found')}
                                                    </Typography>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        className={classes.tableAddButton}
                                                        onClick={this.handleAddStockItems}
                                                        disabled={!this.state.adjustmentGroup.store}
                                                    >
                                                        <AddIcon />
                                                        {gettext('Add products')}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    {displayedRows.map((row) => {
                                        const isSelected = this.isSelected(row.stock_item.id)
                                        const stockItemDesc =
                                            getTranslation(row.stock_item, language, 'name') ||
                                            row.stock_item.name

                                        return (
                                            <React.Fragment key={row.id}>
                                                <TableRow
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isSelected}
                                                >
                                                    <TableCell
                                                        padding="checkbox"
                                                        className={classes.tableCell}
                                                    >
                                                        <Checkbox
                                                            checked={isSelected}
                                                            onChange={(event, checked) =>
                                                                this.handleChecked(
                                                                    event,
                                                                    checked,
                                                                    row
                                                                )
                                                            }
                                                        />
                                                    </TableCell>

                                                    <TableCell className={classes.tableCell}>
                                                        <Typography
                                                            className={classes.truncatedDescription}
                                                            variant="body2"
                                                            title={stockItemDesc}
                                                        >
                                                            {row.stock_item.sku}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell className={classes.tableCell}>
                                                        <Typography
                                                            className={classes.truncatedDescription}
                                                            variant="body2"
                                                            title={stockItemDesc}
                                                        >
                                                            {stockItemDesc}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell
                                                        className={classes.tableCell}
                                                        align="center"
                                                    >
                                                        <TextField
                                                            variant="outlined"
                                                            onChange={this.updateQuantityForRow(
                                                                row
                                                            )}
                                                            inputProps={{
                                                                style: {
                                                                    textAlign: 'right',
                                                                    fontSize: '0.9rem',
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: { height: '38px' },
                                                            }}
                                                            type="number"
                                                        />
                                                    </TableCell>
                                                    {row.channel_stock_adjustments.map(
                                                        (csa, index) => (
                                                            <React.Fragment key={index}>
                                                                <TableCell
                                                                    className={classes.tableCell}
                                                                    align="center"
                                                                    width={130}
                                                                >
                                                                    <TextField
                                                                        value={
                                                                            csa.quantity
                                                                                ? Math.round(
                                                                                      csa.quantity
                                                                                  )
                                                                                : ''
                                                                        }
                                                                        onChange={this.handleAdjustmentQuantityChanged(
                                                                            row,
                                                                            csa.shopID
                                                                        )}
                                                                        error={Boolean(
                                                                            csa.errors.quantity
                                                                        )}
                                                                        helperText={
                                                                            csa.errors.quantity
                                                                        }
                                                                        disabled={!csa}
                                                                        inputProps={{
                                                                            style: {
                                                                                textAlign: 'right',
                                                                                fontSize: '0.9rem',
                                                                            },
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                </TableRow>
                                            </React.Fragment>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            className={classes.bottomPagination}
                            component="div"
                            count={this.state.stockAdjustmentData.length}
                            rowsPerPage={rowsPerPage}
                            page={this.state.page}
                            backIconButtonProps={{
                                'aria-label': gettext('Previous'),
                            }}
                            nextIconButtonProps={{
                                'aria-label': gettext('Next'),
                            }}
                            onPageChange={this.handleChangePage}
                            rowsPerPageOptions={[25]}
                        />
                    </Paper>
                )}
                <div className={classes.bottomBar}>
                    <Button component={Link} to={getPath('stockAdjustmentGroups')} color="primary">
                        <ArrowBackIcon />
                        {gettext('Back to groups')}
                    </Button>
                    <div className={classes.saveActions}>
                        {this.state.hasErrors && (
                            <Typography className={classes.hasErrorsMessage}>
                                {gettext('Errors')}
                            </Typography>
                        )}
                        {this.state.saved && !this.state.hasErrors && (
                            <Typography className={classes.savedMessage}>
                                {gettext('Saved')}
                            </Typography>
                        )}
                        {this.state.saving && (
                            <CircularProgress className={classes.saveProgress} size={25} />
                        )}
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.saveButton}
                            onClick={this.saveHandler}
                            disabled={
                                !this.adjustmentGroupComplete() ||
                                this.stockItemHasErrors() ||
                                this.state.saving
                            }
                        >
                            {gettext('Save Group')}
                        </Button>
                    </div>
                </div>
                {this.state.adjustmentGroup.store && (
                    <StockItemSelector
                        adjustmentGroupID={this.state.adjustmentGroup.id}
                        startDate={this.state.adjustmentGroup.start_date}
                        endDate={this.state.adjustmentGroup.end_date}
                        display={this.state.displayStockItemSelector}
                        stockAdjustmentData={this.state.stockAdjustmentData}
                        storeID={this.state.adjustmentGroup.store}
                        handleSelectedStockItems={this.handleSelectedStockItems}
                        excludedStockItemIDs={this.state.stockAdjustmentData.map(
                            (row) => row.stock_item.id
                        )}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(StockAdjustmentGroup)
