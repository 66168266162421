import { Fragment, useCallback, useMemo } from 'react'

import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'

import { SortableContext } from '@dnd-kit/sortable'

import ProductDimensionDraggable from './product-dimension-draggable'
import ProductDimensionOptionsForm from './product-dimension-options-form'
import type { NormalizedDimension } from './schemas'

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDivider-root': {
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(3),
        },
    },
}))

type Props = {
    isNewProduct: boolean
    normalizedDimensions: NormalizedDimension[]
    existingDimensions: NormalizedDimension[]
    selectedDimension?: NormalizedDimension
    onEdit: (dimension: NormalizedDimension) => void
    onChange: (dimensions: NormalizedDimension[]) => void
    onRemove: (dimensionIndex: number) => void
}

export default function ProductDimensionDroppable({
    isNewProduct,
    normalizedDimensions,
    selectedDimension,
    existingDimensions,
    onEdit,
    onChange,
    onRemove,
}: Props) {
    const classes = useStyles()

    const updateDimensions = useCallback(
        (updatedDimension: NormalizedDimension) => {
            const newDimensions = [...normalizedDimensions]

            const dimensionIndex = newDimensions.findIndex(
                ({ index }) => index === updatedDimension.index
            )
            if (dimensionIndex === -1) {
                return
            }

            newDimensions[dimensionIndex] = updatedDimension

            onChange(newDimensions)
        },
        [normalizedDimensions, onChange]
    )

    const items = useMemo(() => normalizedDimensions.map((d) => d.id), [normalizedDimensions])

    return (
        <div className={classes.container}>
            <SortableContext items={items}>
                {normalizedDimensions.map((dimension) =>
                    selectedDimension?.name === dimension.name &&
                    selectedDimension?.index === dimension.index ? (
                        <Fragment key={dimension.name}>
                            <ProductDimensionOptionsForm
                                isNewProduct={isNewProduct}
                                index={dimension.index}
                                defaultValues={selectedDimension}
                                existingDimensions={existingDimensions}
                                onSubmit={updateDimensions}
                                onRemove={onRemove}
                            />
                            <Divider />
                        </Fragment>
                    ) : (
                        <ProductDimensionDraggable
                            key={dimension.name}
                            index={dimension.index}
                            dimension={dimension}
                            canReorder={!!selectedDimension}
                            isOnlyDimension={normalizedDimensions.length === 1}
                            isNewProduct={isNewProduct}
                            onEdit={onEdit}
                            onRemove={onRemove}
                        />
                    )
                )}
            </SortableContext>
        </div>
    )
}
