import { z } from 'zod'

import { UserLocation } from './location'

const StockItem = z.object({
    barcode: z.nullable(z.string()),
    should_calculate_low_stock: z.boolean(),
    days_till_empty_threshold: z.number(),
    low_stock_period_size: z.number(),
    should_calculate_slow_selling: z.boolean(),
    units_sold_during_period_threshold: z.number(),
    slow_selling_period_size: z.number().optional(),
    sku: z.string(),
    name: z.number().optional(),
    id: z.number().optional(),
})

export const StockRecord = z.object({
    id: z.number(),
    location: UserLocation,
    stock_item: StockItem,
    quantity: z.number(),
})
export type StockRecord = z.infer<typeof StockRecord>


const StockRecordSimple = z.object({
    id: z.number(),
    location_id: z.number(),
    quantity: z.number(),
})

export const StockItemWithStockRecords = z.object({
    sku: z.string().optional(),
    name: z.number().optional(),
    id: z.number().optional(),
    stock_records: z.array(StockRecordSimple),
})
export type StockItemWithStockRecords = z.infer<typeof StockItemWithStockRecords>

const ContentObjectInfo = z.object({
    id: z.number().optional(),
    label: z.string().optional(),
    type: z.string().optional(),
})

export const StockRecordAdjustments = z.object({
    id: z.number().optional(),
    adjustment_type_name: z.string().optional(),
    beginning_quantity: z.number().optional(),
    change_in_quantity: z.number().optional(),
    date_updated: z.string().optional(),
    content_object_info: ContentObjectInfo
})
export type StockRecordAdjustments = z.infer<typeof StockRecordAdjustments>