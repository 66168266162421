export const productKeys = {
    all: ['products'] as const,
    listing: (userId: number, filters: Record<string, string[]>) => [
        ...productKeys.all,
        'listing',
        { userId, filters },
    ],
    filters: (userId: number) => [...productKeys.all, 'filters', { userId }] as const,
    checkSkus: (storeId: number) => [...productKeys.all, 'check-skus', { storeId }] as const,
    detail: (productId: number) => [...productKeys.all, 'detail', { productId }] as const,
    stockRecords: (productId: number) =>
        [...productKeys.all, 'stock-records', { productId }] as const,
    productDiscounts: (productId: number) =>
        [...productKeys.all, 'product-discounts', { productId }] as const,
    activeChannelStockUnitDiscounts: (productId: number) =>
        [...productKeys.all, 'active-channel-stock-unit-discounts', { productId }] as const,
    userLocations: (userId: number) => [...productKeys.all, 'user-locations', { userId }] as const,
}

export const categoryKeys = {
    all: ['categories'] as const,
    ancestors: (categoryId: number, channelId: number) =>
        [...categoryKeys.all, 'ancestors', { categoryId, channelId }] as const,
    children: (categoryId: number, channelId: number) =>
        [...categoryKeys.all, 'children', { categoryId, channelId }] as const,
}
