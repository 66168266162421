import React from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import { post, put } from '../../tools/request'

const styles = (theme) => ({
    dialog: {},
    dialogContent: {
        minHeight: 300,
    },
    dialogActions: {
        paddingRight: 24,
        paddingLeft: 24,
        paddingBottom: 15,
        marginTop: 0,
    },
})

class AddressEditor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            inputData: { ...props.inputData },
            submitting: false,
        }
    }

    handleValueChange = (event) => {
        const { name, value, checked } = event.target
        this.setState(({ inputData }) => ({
            inputData: {
                ...inputData,
                [name]: name.startsWith('is_default') ? checked : value,
            },
        }))
    }

    handleClose = () => {
        const { onClose } = this.props
        this.setState({ inputData: {} })
        onClose && onClose()
    }

    handleSave = (e) => {
        e.preventDefault()

        const { onClose, addressesAPIURL, addressAPIURL } = this.props
        const { id, ...data } = this.state.inputData

        this.setState({ submitting: true })
        const promise = id
            ? put(addressAPIURL.replace('address_pk', id), data)
            : post(addressesAPIURL, data)

        promise.then((res) => {
            if (res.ok) {
                this.setState({ submitting: false, inputData: {} })
                onClose && onClose({ address: res })
            }
        })
    }

    render() {
        const { inputData, submitting } = this.state
        const { classes } = this.props

        const inputProps = {
            onChange: this.handleValueChange,
            variant: 'standard',
            fullWidth: true,
        }

        const isCreate = !inputData.id

        return (
            <Dialog className={classes.dialog} open maxWidth="sm" fullWidth>
                <form onSubmit={this.handleSave}>
                    <DialogTitle>
                        {isCreate ? gettext('Create address') : gettext('Update address')}
                    </DialogTitle>

                    <DialogContent className={classes.dialogContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    {...inputProps}
                                    autoFocus
                                    label={gettext('Address line 1')}
                                    name="address1"
                                    value={inputData.address1}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    {...inputProps}
                                    label={gettext('Address line 2')}
                                    name="address2"
                                    value={inputData.address2}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...inputProps}
                                    label={gettext('District')}
                                    name="district"
                                    value={inputData.district}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...inputProps}
                                    label={gettext('City')}
                                    name="city"
                                    value={inputData.city}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...inputProps}
                                    label={gettext('Province')}
                                    name="province"
                                    value={inputData.province}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...inputProps}
                                    label={gettext('Postal code')}
                                    name="post_code"
                                    value={inputData.post_code}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...inputProps}
                                    label={gettext('Country')}
                                    name="country"
                                    value={inputData.country}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...inputProps}
                                    label={gettext('Contact name')}
                                    name="contact_name"
                                    value={inputData.contact_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...inputProps}
                                    label={gettext('Phone')}
                                    name="phone"
                                    value={inputData.phone}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...inputProps}
                                    label={gettext('Tax ID')}
                                    name="tax_id_number"
                                    value={inputData.tax_id_number}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <div>
                                    <Checkbox
                                        {...inputProps}
                                        checked={inputData.is_default_shipping_address}
                                        name="is_default_shipping_address"
                                        color="secondary"
                                    />
                                    {gettext('Default shipping address')}
                                </div>
                                <div>
                                    <Checkbox
                                        {...inputProps}
                                        checked={inputData.is_default_billing_address}
                                        name="is_default_billing_address"
                                        color="secondary"
                                    />
                                    {gettext('Default billing address')}
                                </div>
                                <div>
                                    <Checkbox
                                        {...inputProps}
                                        checked={inputData.is_default_invoice_address}
                                        name="is_default_invoice_address"
                                        color="secondary"
                                    />
                                    {gettext('Default invoice address')}
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button color="secondary" disabled={submitting} onClick={this.handleClose}>
                            {gettext('Cancel')}
                        </Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={submitting}
                        >
                            {gettext('Save')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

AddressEditor = withStyles(styles)(AddressEditor)

export default AddressEditor
