import { z } from 'zod'

import { Dimension } from './dimension'
import { UserLocation } from './location'
import { ExistingAssignedMedia, Media, AssignedMedia } from './media'
import { Shop } from './shop'
import { StockUnit } from './stock-unit'
import { Store } from './store'
import { makeTranslationsSchema, Translations } from './translations'

const ChannelStockItem = z.object({
    id: z.number().optional(),
    stock_item: z.number().optional(),
    fulfillment_stock_record_options: z
        .object({
            id: z.number(),
            location: UserLocation,
            stock_item: z.object({
                barcode: z.string().nullable(),
                days_till_empty_threshold: z.number(),
                low_stock_period_size: z.number(),
                should_calculate_low_stock: z.boolean(),
                should_calculate_slow_selling: z.boolean(),
                slow_selling_period_size: z.number(),
                stock_unit: z.number().optional(),
                units_sold_during_period_threshold: z.number(),
            }),
            quantity: z.number(),
        })
        .array(),
})

export const ChannelStockUnit = z.object({
    channel_product: z.number(),
    channel_stock_item: ChannelStockItem.nullish(),
    channel_stock_unit_id: z.string().nullish(),
    id: z.number(),
    images: AssignedMedia.extend({
        stock_unit_image: AssignedMedia.extend({ stock_unit: z.number().optional() }).nullish(),
        channel_stock_unit: z.number().nullish(),
    }).array(),
    price: z.string().refine((price) => !price || /^\d+(\.\d{1,2})?$/.test(price), {
        message: 'Max 2 decimal places',
    }),
    price_currency: z.string().nullish(),
    sku: z.string().nullable(),
    status: z.enum(['active', 'inactive', 'deleted', 'rejected']),
    stock_unit: z.object({
        id: z.number(),
        product: z.number(),
        sku: z.string(),
        stock_unit_id: z.string().nullable(),
        num_in_stock: z.number(),
        status: z.string(),
    }),
    url: z.string().nullish(),
})
export type ChannelStockUnit = z.infer<typeof ChannelStockUnit>

export const ChannelCategory = z.object({
    category_id: z.number(),
    channel: z.number(),
    depth: z.number(),
    id: z.number(),
    is_leaf: z.boolean(),
    supports_multiple_stock_units: z.boolean(),
    translations: makeTranslationsSchema(
        z.object({
            name: z.string(),
        })
    ),
    updating: z.boolean(),
})
export type ChannelCategory = z.infer<typeof ChannelCategory>

export const ChannelProductVideo = AssignedMedia.extend({
    channel_product: z.number().optional(),
    product_video: AssignedMedia.extend({
        product: z.number(),
    }).nullish(),
})

export const ChannelProduct = z.object({
    category: ChannelCategory.nullish(),
    channel_stock_units: ChannelStockUnit.array(),
    display_constant_stock: z.boolean(),
    error_message: z.string().nullish(),
    has_channel_specific_media: z.boolean(),
    id: z.union([z.number(), z.string()]),
    grouped_media: Media.array().nullish(),
    images: AssignedMedia.extend({
        channel_product: z.number(),
        product_image: AssignedMedia.extend({
            product: z.number().optional(),
        }).nullish(),
    }).array(),
    videos: ChannelProductVideo.array().nullish(),
    is_promotion_period: z.boolean().optional(),
    product: z.number().optional(),
    shop: Shop,
    status: z.enum([
        'active',
        'inactive',
        'error',
        'pending',
        'rejected',
        'deleted',
        'ready_to_list',
        'need_more_info',
    ]),
    translations: Translations,
    url: z.string().nullish(),
})
export type ChannelProduct = z.infer<typeof ChannelProduct>

export const ProductDiscount = z.object({
    id: z.number(),
    stock_unit_discounts: z
        .object({
            stock_unit: z.any(),
            channel_stock_unit_discounts: z
                .object({
                    id: z.number(),
                    channel_stock_unit: z.object({
                        id: z.number(),
                        channel_product: z.object({
                            id: z.number(),
                            shop: z.object({
                                channel: z.object({
                                    id: z.number(),
                                }),
                            }),
                        }),
                    }),
                    price: z.string().or(z.number()),
                })
                .array(),
        })
        .array(),
    discount: z.object({
        id: z.number(),
        start_date: z.string().nullable(),
        end_date: z.string().nullable(),
        name: z.string(),
    }),
})

export type ProductDiscount = z.infer<typeof ProductDiscount>

export const ChannelStockUnitDiscount = z.object({
    id: z.number(),
    discount_id: z.number(),
    channel_stock_unit_id: z.number(),
    channel_product_id: z.number(),
    stock_unit_id: z.number(),
    shop_id: z.number(),
    price: z.string().or(z.number()),
})
export type ChannelStockUnitDiscount = z.infer<typeof ChannelStockUnitDiscount>

export const ProductVideo = AssignedMedia.extend({ product: z.number().optional() })
export const Product = z.object({
    channel_products: ChannelProduct.array(),
    cover_image_url: z.string(),
    date_created: z.string(),
    dimensions: Dimension.array(),
    has_merged_channel_products: z.boolean(),
    id: z.number(),
    grouped_media: Media.array().nullish(),
    images: ExistingAssignedMedia.extend({ product: z.number() }).array(),
    videos: ProductVideo.array().nullish(),
    package_height: z.number().nullish(),
    package_length: z.number().nullish(),
    package_weight: z.number().nullish(),
    package_width: z.number().nullish(),
    status: z.enum(['draft', 'active', 'inactive', 'deleted']),
    stock_units: StockUnit.array(),
    stock_units_editing_is_enabled: z.boolean(),
    store: Store,
    translations: Translations,
})
export type Product = z.infer<typeof Product>
