import Typography from '@material-ui/core/Typography'

const TextView = ({ content, ...props }) => {
    if (!content) return null

    const lines = content.split('\n')
    return lines.map((line, i) => {
        return (
            <Typography variant="body2" key={i} {...props}>
                {line}
            </Typography>
        )
    })
}

export default TextView
