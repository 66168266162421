import { connect } from 'react-redux'

import { AttributesProvider } from '../components/attributes-provider'

const mapStateToProps = (state) => ({
    categoryAttributesAPIURL: state.initial.endpoints.categoryAttributesAPIURL,
    channelProductAttributesAPIURL: state.initial.endpoints.channelProductAttributesAPIURL,
    channelStockUnitAttributesAPIURL: state.initial.endpoints.channelStockUnitAttributesAPIURL,

    shopeeLogisticsProvidersAPIURL: state.initial.endpoints.shopeeLogisticsProvidersAPIURL,
    shopeeLogisticsValuesAPIURL: state.initial.endpoints.shopeeLogisticsValuesAPIURL,

    language: state.initial.language,
})

export default connect(mapStateToProps, null, null, { withRef: true })(AttributesProvider)
