import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'

import moment from 'moment'

import { post, put, remove } from '../../../tools/request'
import { commonStyles } from '../sale'

import ImageList from './image-list'
import ImageManager from './image-manager'
import TextView from './text-view'

const saleNoteStyles = (theme) => ({
    ...commonStyles,
    noteItem: {
        margin: '16px 0',

        '&:hover': {
            '& div[aria-role=action-buttons]': {
                display: 'block',
            },
        },
    },

    noteDate: {
        color: theme.palette.grey[500],
    },

    noteText: {
        overflow: 'hidden',
        clear: 'both',
    },

    noteInput: {
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
        padding: '18.5px 14px',
        fontSize: 'inherit',
        fontFamily: 'inherit',
        '&:focus': {
            borderColor: theme.palette.primary.main,
            outline: 'none !important',
        },
    },

    noteButtons: {
        float: 'right',
        marginTop: -6,
        display: 'none',
    },
})

class Note extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            currentNote: { ...props.currentNote },
            mode: props.mode || 'placeholder', // 'placeholder', 'create', 'update', 'view'
            enlargeImage: null,
            showEnlargeImageModal: false,
            showDeleteConfirm: false,
        }
    }

    handleEdit = () => {
        this.setState({ mode: 'update' })
    }

    handleCancel = () => {
        const { mode } = this.state
        const { currentNote } = this.props
        this.setState({
            mode: mode === 'create' ? 'placeholder' : 'view',
            currentNote: { ...currentNote },
        })
    }

    handleInputFocus = () => {
        if (this.state.mode === 'placeholder') {
            this.setState({ mode: 'create' })
        }
    }

    handleSave = () => {
        const { saleNotesAPIURL, saleNoteAPIURL, onChange } = this.props
        const { currentNote, mode } = this.state

        if (mode === 'create') {
            post(saleNotesAPIURL, currentNote).then((res) => {
                if (res.ok) {
                    onChange({ created: res })
                    this.setState({
                        mode: 'placeholder',
                        currentNote: { ...currentNote, text: '' },
                    })
                }
            })
        } else {
            put(saleNoteAPIURL.replace('sale_note_pk', currentNote.id), currentNote).then((res) => {
                if (res.ok) {
                    onChange({ created: res })
                    this.setState({ mode: 'view' })
                }
            })
        }
    }

    handleDelete = () => {
        const { saleNoteAPIURL, onChange } = this.props
        const { currentNote } = this.state

        remove(saleNoteAPIURL.replace('sale_note_pk', currentNote.id)).then((res) => {
            if (res.ok) {
                onChange({ deleted: currentNote })
                this.setState({ showDeleteConfirm: false })
            }
        })
    }

    handleChange = (e) => {
        const value = e?.target?.value
        this.setState(({ currentNote }) => ({ currentNote: { ...currentNote, text: value } }))
    }

    handleImageChange = (images, callback) => {
        this.setState(({ currentNote }) => {
            return {
                currentNote: {
                    ...currentNote,
                    images: images,
                },
            }
        })
        callback && callback()
    }

    render() {
        const { classes, storeId, staticFilesDomain } = this.props
        const { mode, currentNote, showDeleteConfirm } = this.state
        const images = currentNote.images || []
        const disableSaveButton = !currentNote.text && images.length === 0

        return (
            <div className={classes.noteItem}>
                {mode === 'view' && (
                    <div className={classes.noteButtons} aria-role="action-buttons">
                        <IconButton
                            aria-label="update"
                            size="small"
                            style={{ marginLeft: 2 }}
                            onClick={this.handleEdit}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>

                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => this.setState({ showDeleteConfirm: true })}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </div>
                )}

                {currentNote.date_created && (
                    <Typography className={classes.noteDate} variant="caption">
                        {moment(currentNote.date_created).format('D MMM YYYY HH:mm')}
                    </Typography>
                )}

                {currentNote.text && mode === 'view' && (
                    <div className={classes.noteText}>
                        <TextView content={currentNote.text} style={{ margin: 0, padding: 0 }} />
                    </div>
                )}

                {mode !== 'view' && (
                    <TextareaAutosize
                        className={classes.noteInput}
                        placeholder={gettext('Add a note')}
                        value={mode === 'placeholder' ? '' : currentNote.text}
                        onFocus={this.handleInputFocus}
                        onChange={this.handleChange}
                        rowsMax={5}
                        rowsMin={5}
                    />
                )}

                {currentNote.images?.length > 0 && mode === 'view' && (
                    <ImageList images={currentNote.images} staticFilesDomain={staticFilesDomain} />
                )}

                {(mode === 'create' || mode === 'update') && (
                    <React.Fragment>
                        <div style={{ marginBottom: 10 }}>
                            <ImageManager
                                storeID={storeId}
                                images={images}
                                imagesChangedHandler={this.handleImageChange}
                            />
                        </div>

                        <div>
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                style={{ marginRight: 4 }}
                                onClick={this.handleSave}
                                disabled={disableSaveButton}
                            >
                                {gettext('Save')}
                            </Button>
                            <Button size="small" onClick={this.handleCancel}>
                                {gettext('Cancel')}
                            </Button>
                        </div>
                    </React.Fragment>
                )}

                <Dialog
                    open={showDeleteConfirm}
                    onClose={() => this.setState({ showDeleteConfirm: false })}
                    aria-labelledby="dialog-title"
                >
                    <DialogTitle id="dialog-title">{gettext('Confirmation')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {gettext('Do you want to delete this note?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            autoFocus
                            onClick={() => this.setState({ showDeleteConfirm: false })}
                        >
                            {gettext('Cancel')}
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={this.handleDelete}
                        >
                            {gettext('Delete')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(saleNoteStyles)(Note)
