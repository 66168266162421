import { combineReducers } from 'redux'

import initial from './initial'
import login from './login'
import stores from './stores'

export default combineReducers({
    initial,
    login,
    stores,
})
