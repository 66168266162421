import { useState, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TableContainer from '@material-ui/core/TableContainer'

import { captureException } from '@sentry/react'
import {
    useInfiniteQuery,
    useMutation,
    useQueryClient,
    type InfiniteData,
} from '@tanstack/react-query'
import { useSnackbar } from 'notistack'

import { productKeys } from '../api/query-keys-factory'
import { useProductFilter } from '../hooks/useProductFilter'

import ProductsTable from './products-table'
import ProductsToolbar from './products-toolbar'

import { type PaginatedResponse } from '~/api'
import { type Filter, productApi, type FilterValue } from '~/api/product-api'
import useUser from '~/common/hooks/useUser'
import { type Product } from '~/common/schemas/product'
import { useAppSelector } from '~/store'
import { getPath } from '~/tools/utils'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflowY: 'hidden',
        marginTop: theme.spacing(3),
        position: 'relative',

        '--products-toolbar-max-height': `${theme.spacing(12)}px`,

        [theme.breakpoints.up('xl')]: {
            '--products-toolbar-max-height': `${theme.spacing(8)}px`,
        },
    },
    createProductLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    tableContainer: {
        height: `calc(100% - var(--products-toolbar-max-height))`,
        overflowY: 'auto',
    },
}))

export default function Products() {
    const classes = useStyles()
    const stores = useAppSelector((app) => app.stores)
    const { enqueueSnackbar } = useSnackbar()
    const { data: filterData, isLoading: filterLoading } = useProductFilter()

    const { filterValues, filterParams, updateFilterValues, toggleExclusiveFilter } =
        useProductFilterValues(filterData)

    const {
        data: productData,
        status: productDataStatus,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    } = useProductListingInfinite(filterParams)

    const products = useMemo(
        () => productData?.pages.flatMap((page) => page.results),
        [productData?.pages]
    )

    const [dialog, setDialog] = useState('')
    function closeDialog() {
        setDialog('')
    }

    const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
    const selectedIds = useMemo(() => {
        return products ? Object.keys(rowSelection).map((key) => products?.[parseInt(key)].id) : []
    }, [products, rowSelection])

    const { mutate: updateProductStatus, mutateAsync: updateProductStatusAsync } =
        useUpdateProductStatus(filterParams)

    function handleActiveSwitch(productId: number, status: Product['status']) {
        const product = products?.find((product) => product.id === productId)
        if (product?.status === status) {
            return
        }

        updateProductStatus(
            { id: productId, status },
            {
                onSuccess: () => {
                    enqueueSnackbar(gettext('Product status updated'), { variant: 'success' })
                    window.analytics.track('Change product status', {
                        page: window.location.href,
                        feature: 'product',
                        type: 'update',
                        trigger: 'toggle click',
                        product_id: productId,
                        status_value: status,
                    })
                },
                onError: (error) => {
                    captureException(error)
                    enqueueSnackbar(gettext('Failed to update product status'), {
                        variant: 'error',
                    })
                },
            }
        )
    }

    async function handleBatchStatusUpdate(status: 'active' | 'inactive') {
        if (!selectedIds.length) {
            return
        }

        try {
            await Promise.all(selectedIds.map((id) => updateProductStatusAsync({ id, status })))

            window.analytics.track('Change product status', {
                page: window.location.href,
                feature: 'product',
                type: 'bulk update',
                trigger: 'button click',
                product_ids: selectedIds,
                status_value: status,
            })

            enqueueSnackbar(gettext('Batch product status updated'), { variant: 'success' })
            setRowSelection({})
        } catch (error) {
            captureException(error)
            enqueueSnackbar(gettext('Failed to update some product status'), { variant: 'error' })
        }
    }

    const { mutateAsync: deleteProductAsync, isLoading: isDeletingProduct } =
        useDeleteProduct(filterParams)

    async function handleDeleteProducts() {
        if (!selectedIds.length) {
            return
        }

        try {
            await Promise.all(selectedIds.map((id) => deleteProductAsync(id)))

            window.analytics.track('Delete product', {
                page: window.location.href,
                feature: 'product',
                type: 'bulk delete',
                trigger: 'button click',
                product_ids: selectedIds,
            })

            setRowSelection({})
            setDialog('')
        } catch (error) {
            captureException(error)
            enqueueSnackbar(gettext('Failed to delete some products'), { variant: 'error' })
        }
    }

    function handleFilterChange(field: Filter) {
        return (option: FilterValue | null) => updateFilterValues(field.lookup, option)
    }

    function handleFilterSelectExclusive(field: Filter) {
        return () => toggleExclusiveFilter(field.lookup)
    }

    function handleSearchChange(value: string) {
        updateFilterValues('search', { label: 'search', value })
    }

    return (
        <>
            <Box component={Paper} className={classes.root}>
                <ProductsToolbar
                    loading={filterLoading}
                    numSelected={Object.keys(rowSelection).length}
                    filterValues={filterValues}
                    filterOptions={filterData}
                    onMakeActive={() => handleBatchStatusUpdate('active')}
                    onMakeInactive={() => handleBatchStatusUpdate('inactive')}
                    onDelete={() => setDialog('delete')}
                    onNewProductClick={() => setDialog('new-product')}
                    onFilterChange={handleFilterChange}
                    onSelectExclusive={handleFilterSelectExclusive}
                    onSearchChange={handleSearchChange}
                />

                <TableContainer className={classes.tableContainer}>
                    <ProductsTable
                        stickyHeader
                        status={productDataStatus}
                        products={products}
                        rowSelection={rowSelection}
                        canFetchMore={hasNextPage}
                        isFetchingMore={isFetchingNextPage}
                        onRowSelectionChange={setRowSelection}
                        onStatusUpdate={handleActiveSwitch}
                        onFetchMore={fetchNextPage}
                    />
                </TableContainer>
            </Box>
            <Dialog
                open={dialog === 'delete'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={closeDialog}
            >
                <DialogTitle id="alert-dialog-title">
                    {gettext('Delete these products?')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {gettext('Products will be removed from all linked channels.')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isDeletingProduct} color="primary" onClick={closeDialog}>
                        {gettext('Cancel')}
                    </Button>
                    <Button
                        autoFocus
                        disabled={isDeletingProduct}
                        color="primary"
                        onClick={handleDeleteProducts}
                    >
                        {gettext('Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={dialog === 'new-product'}
                onClose={closeDialog}
                maxWidth="xs"
                aria-labelledby="select-store"
            >
                <DialogTitle id="select-store">{gettext('Select store')}</DialogTitle>
                <div>
                    <List>
                        {stores.map((store) => (
                            <Link
                                key={store.id}
                                to={getPath('createProduct').replace('store_id', store.id)}
                                className={classes.createProductLink}
                            >
                                <ListItem button>
                                    <ListItemText primary={store.name} />
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                </div>
            </Dialog>
        </>
    )
}

function useProductFilterValues(data?: Filter[]) {
    const [filterValues, setFilterValues] = useState(() => {
        return new Map<string, FilterValue | FilterValue[] | null>().set('status', {
            label: 'Active',
            value: 'active',
        })
    })

    useEffect(() => {
        if (!data) {
            return
        }

        setFilterValues(
            data.reduce((acc, { lookup }) => {
                acc.set(lookup, lookup === 'status' ? { label: 'Active', value: 'active' } : null)

                return acc
            }, new Map() as typeof filterValues)
        )
    }, [data])

    function toggleExclusiveFilter(lookup: string) {
        const key = `${lookup}__exclusive`
        setFilterValues(
            (prev) =>
                new Map(prev.set(key, prev.get(key) ? null : { label: 'Exclusive', value: 'True' }))
        )
    }

    function updateFilterValues(lookup: string, option: FilterValue | null) {
        setFilterValues((prev) => new Map(prev.set(lookup, option)))
    }

    const filterParams = useMemo(() => {
        return [...filterValues.entries()].reduce((acc, [key, filter]) => {
            if (!filter) {
                return acc
            }

            if (Array.isArray(filter)) {
                acc[key] = filter.map(({ value }) => value.toString())
                return acc
            }

            if (filter.value) {
                acc[key] = [filter.value.toString()]
            }

            return acc
        }, {} as Record<string, string[]>)
    }, [filterValues])

    return {
        filterValues: Object.fromEntries(filterValues),
        filterParams,
        toggleExclusiveFilter,
        updateFilterValues,
    }
}

function useUpdateProductStatus(params: Record<string, string[]>) {
    const user = useUser()
    const queryClient = useQueryClient()
    const queryKey = productKeys.listing(user.id, params)

    return useMutation({
        mutationFn: ({ id, status }: { id: Product['id']; status: Product['status'] }) => {
            return productApi.updateProductStatus(id, status)
        },
        onMutate: async ({ id, status }) => {
            const previousProducts = queryClient.getQueryData(queryKey)

            await queryClient.cancelQueries(queryKey)

            queryClient.setQueryData<InfiniteData<PaginatedResponse<Product>>>(queryKey, (old) => {
                if (!old) {
                    return
                }

                return {
                    ...old,
                    pages: old.pages.map((page) => ({
                        ...page,
                        results: page.results.map((product) => ({
                            ...product,
                            status: product.id === id ? status : product.status,
                        })),
                    })),
                }
            })

            return { previousProducts }
        },
        onError: (_, __, context) => {
            queryClient.setQueryData(queryKey, context?.previousProducts)
        },
    })
}

function useDeleteProduct(params: Record<string, string[]>) {
    const user = useUser()
    const queryClient = useQueryClient()
    const queryKey = productKeys.listing(user.id, params)

    return useMutation({
        mutationFn: (id: Product['id']) => productApi.deleteProduct(id),
        onSuccess: () => queryClient.invalidateQueries(queryKey),
    })
}

const FETCH_LIMIT = 25

function useProductListingInfinite(params: Record<string, string[]>) {
    const user = useUser()
    return useInfiniteQuery({
        enabled: true,
        queryKey: productKeys.listing(user.id, params),
        queryFn: ({ pageParam = 0 }) => {
            return productApi.getProductListing(user.id, {
                ...params,
                limit: FETCH_LIMIT,
                offset: FETCH_LIMIT * pageParam,
            })
        },
        getNextPageParam: (lastPage) => {
            if (!lastPage.next) {
                return
            }

            const url = new URL(lastPage.next)
            const offset = parseInt(url.searchParams.get('offset') ?? '')
            if (isNaN(offset)) {
                return
            }

            return Math.ceil(offset / FETCH_LIMIT)
        },
    })
}
