import { connect } from 'react-redux'

import PickListProcessor from '../components/pick-list-processor'

const mapStateToProps = (state) => ({
    stores: state.initial.stores,
    language: state.initial.language,
    userID: state.initial.user.id,
    pickPackBatchItemsAPIURL: state.initial.endpoints.pickPackBatchItemsAPIURL,
    staticRoot: state.initial.staticRoot,
})

export default connect(mapStateToProps)(PickListProcessor)
