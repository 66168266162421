import { connect } from 'react-redux'

import Notes from '../components/sale/notes'

const mapStateToProps = (state) => ({
    saleNotesAPIURL: state.initial.endpoints.saleNotesAPIURL,
    saleNoteAPIURL: state.initial.endpoints.saleNoteAPIURL,
    staticFilesDomain: state.initial.staticFilesDomain,
})

export default connect(mapStateToProps)(Notes)
