import { connect } from 'react-redux'

import ExportDocuments from '../components/exports'

const mapStateToProps = (state) => {
    return {
        exportDocumentsAPIURL: state.initial.endpoints.exportDocumentsAPIURL,
        // documentEventsAPIURL: 'endpoints/documents/1/events',
        stores: state.stores,
        userID: state.initial.user.id,
        language: state.initial.language,
        staticRoot: state.initial.staticRoot,
    }
}

export default connect(mapStateToProps)(ExportDocuments)
