import { connect } from 'react-redux'

import Settings from '../components/settings'

const mapStateToProps = (state) => ({
    language: state.initial.language,
    userID: state.initial.user.id,
})

export default connect(mapStateToProps)(Settings)
