import { useCallback } from 'react'
import { useWatch } from 'react-hook-form'

import AddIcon from '@mui/icons-material/Add'
import ListIcon from '@mui/icons-material/List'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import DialogStockUnitCoverage from '../dialog-stock-unit-coverage'

import ChannelProductTable from './channel-products-table'
import DialogAddShops from './dialog-add-shops'
import DialogChannelProductDelete from './dialog-channel-product-delete'
import DialogChannelProductEdit from './dialog-channel-product-edit'
import ProductSection from './product-section'

import { useProductFormContext } from '~/products/hooks/useProductForm'
import { useProductPage } from '~/products/hooks/useProductPage'
import {
    type ChannelProductDialog,
    useProductPageActions,
    useProductPageDialog,
    useProductPageSelectedChannelProductIndex,
} from '~/products/hooks/useProductPageStore'
import { updateChannels } from '~/products/utils/updateChannels'
import { updateNotConfiguredShops } from '~/products/utils/updateNotConfiguredShops'

export default (function ProductChannelProducts() {
    const { isNewProduct } = useProductPage()
    const { setValue, getValues } = useProductFormContext()
    const store = getValues('store')

    const selectedChannelProductIndex = useProductPageSelectedChannelProductIndex()
    const dialog = useProductPageDialog()
    const { closeDialog, resetSelectedChannelProductIndex, openChannelProductDialog } =
        useProductPageActions()

    const handleOpenChannelProductDialog = useCallback(
        (type?: ChannelProductDialog) => (index: number) => openChannelProductDialog(index, type),
        [openChannelProductDialog]
    )

    const handleDeleted = useCallback(() => {
        if (selectedChannelProductIndex === -1) {
            return
        }

        const selectedChannelProduct = getValues(`channel_products.${selectedChannelProductIndex}`)
        const channelProducts = getValues('channel_products')
        const updatedChannelProducts = channelProducts.filter(
            ({ id }) => id !== selectedChannelProduct.id
        )
        const channels = updateChannels(updatedChannelProducts)
        const notConfiguredShops = updateNotConfiguredShops(
            updatedChannelProducts,
            channels,
            store.shops,
            getValues('stock_units').length > 1
        )

        setValue('channels', channels)
        setValue('notConfiguredShops', notConfiguredShops)
        setValue('channel_products', updatedChannelProducts)
        closeDialog()
        resetSelectedChannelProductIndex()
    }, [
        store.shops,
        getValues,
        setValue,
        selectedChannelProductIndex,
        closeDialog,
        resetSelectedChannelProductIndex,
    ])

    const toggleHasChannelSpecificMedia = useCallback(
        (checked: boolean) => {
            const index = selectedChannelProductIndex
            if (index === -1) {
                return
            }

            const selectedChannelProduct = getValues(`channel_products.${index}`)
            const images = checked ? [] : getValues('images')
            const videos = checked ? [] : getValues('videos')

            setValue(`channel_products.${index}.has_channel_specific_media`, checked)
            setValue(`channel_products.${index}.images`, images)
            setValue(`channel_products.${index}.videos`, videos)
            setValue(
                `channel_products.${index}.channel_stock_units`,
                selectedChannelProduct.channel_stock_units.map((csu, csuIndex) => {
                    const stockUnitImages = getValues(`stock_units.${csuIndex}.images`)

                    return {
                        ...csu,
                        images: checked
                            ? []
                            : stockUnitImages.map((image) => ({
                                  stock_unit_image: image,
                                  display_order: image.display_order,
                              })),
                    }
                })
            )
        },
        [selectedChannelProductIndex, setValue, getValues]
    )

    const toggleShouldCreateChannelProduct = useCallback(
        (cpIndex: number, value: boolean) => {
            const updatedChannelProducts = getValues('channel_products').map((cp, index) => {
                if (index !== cpIndex) {
                    return cp
                }

                return {
                    ...cp,
                    shouldCreateChannelProduct: value,
                }
            })

            setValue('channel_products', updatedChannelProducts)
        },
        [getValues, setValue]
    )

    return (
        <>
            <ProductSection>
                <Stack gap={2}>
                    <ChannelProductTable
                        onCreateToggle={toggleShouldCreateChannelProduct}
                        onEditClick={handleOpenChannelProductDialog()}
                        onDeleteClick={handleOpenChannelProductDialog('channel-products-delete')}
                    />

                    <ChannelProductTableFooter />
                </Stack>
            </ProductSection>

            {selectedChannelProductIndex > -1 && (
                <>
                    <DialogChannelProductDelete
                        open={dialog === 'channel-products-delete'}
                        channelProductIndex={selectedChannelProductIndex}
                        onDeleted={handleDeleted}
                    />
                    <DialogChannelProductEdit
                        open={dialog === 'channel-products'}
                        channelProductIndex={selectedChannelProductIndex}
                        onToggleSpecificMedia={toggleHasChannelSpecificMedia}
                    />
                </>
            )}

            <DialogAddShops open={dialog === 'add-shops'} onClose={closeDialog} />
            <DialogStockUnitCoverage
                open={dialog === 'stock-units-coverage'}
                onClose={closeDialog}
            />
        </>
    )
})

function ChannelProductTableFooter() {
    const { isNewProduct } = useProductPage()
    const { getValues, control } = useProductFormContext()
    const channelProducts = useWatch({ control, name: 'channel_products' })
    const hasNotConfiguredShops = getValues('notConfiguredShops').length > 0

    const { openDialog } = useProductPageActions()

    const hasPlaceholderChannelStockUnit = channelProducts.some((cp) =>
        cp.channel_stock_units.some((csu) => csu.placeholder)
    )

    return (
        <Stack direction="row" gap={1.5}>
            {hasPlaceholderChannelStockUnit && (
                <Button
                    color="inherit"
                    startIcon={<ListIcon />}
                    onClick={() => openDialog('stock-units-coverage')}
                >
                    {gettext('Stock units coverage')}
                </Button>
            )}
            {!isNewProduct && hasNotConfiguredShops && (
                <Button
                    color="inherit"
                    startIcon={<AddIcon />}
                    onClick={() => openDialog('add-shops')}
                >
                    {gettext('Add shops')}
                </Button>
            )}
        </Stack>
    )
}
