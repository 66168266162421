import { useEffect, useMemo, useCallback } from 'react'

import grey from '@material-ui/core/colors/grey'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'

import moment from 'moment'

import { copyObject, objectIsEmpty } from '../../tools/utils'

import 'moment/locale/en-gb'
import 'moment/locale/th'

const useStyles = makeStyles(() => ({
    shippingInfoComponent: {
        width: '100%',
        marginBottom: 20,
    },
    shopeeShippingProviderList: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },
    shippingProviderLogo: {
        maxWidth: 80,
        maxHeight: 30,
        marginRight: 10,
    },
    shippingInfoTitleText: {
        color: grey[500],
        marginLeft: 5,
    },
    selectedShippingProviderList: {
        padding: '0 10px',
        '& li': {
            padding: '10px 0',
            listStyleType: 'none',
        }
    }
}))

function SelectedShippingProviders({ shippingProviders, allSalesHaveShippingProvider, channelName="" }) {
    const classes = useStyles()
    return (
        <div>
            <Typography className={classes.shippingInfoTitleText}>
                {allSalesHaveShippingProvider ?
                    interpolate(gettext('All %s orders already have the following shipping providers'), [channelName])
                    :
                    interpolate(gettext('Some %s orders already have the following shipping providers'), [channelName])
                }
            </Typography>
            <ul className={classes.selectedShippingProviderList}>
                {shippingProviders.map((sp) => (
                    <li key={sp.id}>
                        <img
                            src={sp.logo_url}
                            style={{ maxWidth: 80, maxHeight: 30 }}
                        />
                    </li>
                ))}
            </ul>
        </div>
    )
}

function ShippingInitInfoSelectField(props) {
    const { field, label, value, options, optionLabelField, optionValueField, handleSelectChange } =
        props
    const classes = useStyles()

    return (
        <FormControl variant="outlined" className={classes.shippingInfoComponent}>
            <InputLabel htmlFor={field}>{label}</InputLabel>
            <Select
                value={value && (typeof value === 'object' ? value[optionValueField] : value)}
                onChange={handleSelectChange}
                input={<OutlinedInput labelWidth={150} name={field} id={field} />}
            >
                {options.map((o) => {
                    let optionLabel
                    // This field is for Shopee
                    if (optionLabelField === 'date') {
                        optionLabel = moment(o[optionLabelField] * 1000).format('ddd D MMM')
                        if (o?.time_text) {
                            optionLabel = optionLabel + ` ${o.time_text}`
                        }
                    } else {
                        optionLabel = o[optionLabelField]
                    }
                    return (
                        <MenuItem value={o[optionValueField]} key={o[field] || o.id}>
                            {optionLabel}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

function ShippingInitInfoTextField(props) {
    const { label, key, value, handleTextChange } = props
    const classes = useStyles()

    return (
        <TextField
            key={key}
            label={label}
            className={classes.shippingInfoComponent}
            value={value}
            onChange={handleTextChange}
            margin="normal"
            variant="outlined"
        />
    )
}

function InitShippingInfoManager(props) {
    const { initShippingInfoFields, fieldInfo, initShippingInfo, handleInfoChange } = props
    const allSalesHaveShippingProvider = initShippingInfoFields?.all_sales_have_shipping_provider
    const selectedShippingProviders = initShippingInfoFields?.selected_shipping_providers || []

    const field = 'shipment_provider'
    const { label, label_field: optionLabelField, value_field: optionValueField } = fieldInfo[field]
    const values = initShippingInfoFields[field]

    const handleSelectChange = (field, optionValueField) => (event) => {
        const newInitShippingInfo = copyObject(initShippingInfo)
        const value = event.target.value
        const valueObj = initShippingInfoFields[field].find(
            (option) => option[optionValueField] === value
        )
        newInitShippingInfo[field] = valueObj

        handleInfoChange(newInitShippingInfo)
    }

    useEffect(() => {
        const newInitShippingInfo = copyObject(initShippingInfo)
        const valueObj = initShippingInfoFields[field][0]
        newInitShippingInfo[field] = valueObj

        handleInfoChange(newInitShippingInfo)
    }, [])

    return (
        <div>
            {!allSalesHaveShippingProvider && !objectIsEmpty(initShippingInfo) && (
                <ShippingInitInfoSelectField
                    field={field}
                    key={field}
                    label={label}
                    value={initShippingInfo[field]}
                    options={values}
                    optionLabelField={optionLabelField}
                    optionValueField={optionValueField}
                    handleSelectChange={handleSelectChange(field, optionValueField)}
                />
            )}
            {selectedShippingProviders.length > 0 && (
                <SelectedShippingProviders
                    allSalesHaveShippingProvider={allSalesHaveShippingProvider}
                    shippingProviders={selectedShippingProviders}
                />
            )}
        </div>
    )
}

const lazadaHoursTillRTSOptions = () => {
    const now = moment()

    return [
        {
            value: 0,
            label: gettext('Now'),
        },
        {
            value: 1,
            label: now.add(1, 'hour').format('HH:mm'),
        },
        {
            value: 2,
            label: now.add(1, 'hour').format('HH:mm'),
        },
        {
            value: 3,
            label: now.add(1, 'hour').format('HH:mm'),
        },
    ]
}

const defaultLazadaHoursTillRTS = 0 // default to Now

function LazadaInitShippingInfoManager(props) {
    const { initShippingInfoFields, fieldInfo, initShippingInfo, handleInfoChange } = props

    const handleSelectChange = (field, optionValueField) => (event) => {
        const newInitShippingInfo = copyObject(initShippingInfo)
        const value = event.target.value
        if (field === 'hours_till_rts') {
            newInitShippingInfo[field] = value
        } else {
            const valueObj = initShippingInfoFields[field].find(
                (option) => option[optionValueField] === value
            )
            newInitShippingInfo[field] = valueObj
        }

        handleInfoChange(newInitShippingInfo)
    }

    const field = 'shipment_provider'
    const { label, label_field: optionLabelField, value_field: optionValueField } = fieldInfo[field]
    const values = initShippingInfoFields[field]
    const allSalesHaveShippingProvider = initShippingInfoFields?.all_sales_have_shipping_provider
    const selectedShippingProviders = initShippingInfoFields?.selected_shipping_providers || []

    useEffect(() => {
        const newInitShippingInfo = copyObject(initShippingInfo)
        const valueObj = initShippingInfoFields[field][0]
        newInitShippingInfo[field] = valueObj
        newInitShippingInfo.hours_till_rts = defaultLazadaHoursTillRTS

        handleInfoChange(newInitShippingInfo)
    }, [])

    return (
        <div>
            <ShippingInitInfoSelectField
                field="hours_till_rts"
                key="hours_till_rts"
                label={gettext('Ready to ship time')}
                value={
                    initShippingInfo.hours_till_rts === undefined
                        ? defaultLazadaHoursTillRTS
                        : initShippingInfo.hours_till_rts
                }
                options={lazadaHoursTillRTSOptions()}
                optionLabelField="label"
                optionValueField="value"
                handleSelectChange={handleSelectChange('hours_till_rts', 'value')}
            />

            {!allSalesHaveShippingProvider && !objectIsEmpty(initShippingInfo) && (
                <ShippingInitInfoSelectField
                    field={field}
                    key={field}
                    label={label}
                    value={initShippingInfo[field]}
                    options={values}
                    optionLabelField={optionLabelField}
                    optionValueField={optionValueField}
                    handleSelectChange={handleSelectChange(field, optionValueField)}
                />
            )}

            {selectedShippingProviders.length > 0 && (
                <SelectedShippingProviders
                    allSalesHaveShippingProvider={allSalesHaveShippingProvider}
                    shippingProviders={selectedShippingProviders}
                    channelName='Lazada'
                />
            )}
        </div>
    )
}

function ShopeeInitShippingInfoManager(props) {
    const { initShippingInfoFields, fieldInfo, initShippingInfo, handleInfoChange } = props
    const handoffTypeField = 'handoff_type'
    const {
        label: handoffTypeLabel,
        label_field: handoffTypeLabelField,
        value_field: handoffTypeValueField,
    } = fieldInfo[handoffTypeField]
    const classes = useStyles()

    const handleSelectChange = useCallback(
        (field, optionValueField) => (event) => {
            const newInitShippingInfo = copyObject(initShippingInfo)
            const handoffTypeValue = initShippingInfo[handoffTypeField][handoffTypeValueField]

            const value = event.target.value
            const valueObj = initShippingInfoFields[handoffTypeValue][field].find(
                (option) => option[optionValueField] === value
            )
            newInitShippingInfo[field] = valueObj

            handleInfoChange(newInitShippingInfo)
        },
        [handleInfoChange, handoffTypeValueField, initShippingInfo, initShippingInfoFields]
    )

    const handleTextChange = useCallback(
        (field) => (event) => {
            const newInitShippingInfo = copyObject(initShippingInfo)
            const value = event.target.value
            newInitShippingInfo[field] = value

            handleInfoChange(newInitShippingInfo)
        },
        [handleInfoChange, initShippingInfo]
    )
    const components = useMemo(() => {
        const components = []
        const pickupTimesField = 'pickup_time_id_by_shipping_provider'

        if (!objectIsEmpty(initShippingInfo)) {
            // Handoff Type
            const handoffTypeValue = initShippingInfo[handoffTypeField][handoffTypeValueField]
            const handoffTypeOptions = initShippingInfoFields[handoffTypeField]
            components.push(
                <ShippingInitInfoSelectField
                    field={handoffTypeField}
                    key={handoffTypeField}
                    label={handoffTypeLabel}
                    value={initShippingInfo[handoffTypeField]}
                    options={handoffTypeOptions}
                    optionLabelField={handoffTypeLabelField}
                    optionValueField={handoffTypeValueField}
                    handleSelectChange={(event) => {
                        const newInitShippingInfo = {}
                        const value = event.target.value
                        const handoffTypeObj = initShippingInfoFields[handoffTypeField].find(
                            (option) => option[handoffTypeValueField] === value
                        )
                        newInitShippingInfo[handoffTypeField] = handoffTypeObj

                        const handoffTypeValue = handoffTypeObj[handoffTypeValueField]

                        Object.entries(initShippingInfoFields[handoffTypeValue]).forEach(
                            ([field, values]) => {
                                newInitShippingInfo[field] = values[0]
                            }
                        )

                        if (handoffTypeValue === 'pickup') {
                            // newInitShippingInfo = {
                            //    address_id: {
                            //    address_id: 12312,
                            //    time_slot_info_list : [
                            //      {
                            //          time_slot_list: [
                            //              {'date': 1684573200, 'pickup_time_id': '1684573200'},
                            //              {'date': 1684659600, 'pickup_time_id': '1684659600'}
                            //          ],
                            //          shipping_providers": [
                            //              {
                            //                  "id": shipping_provider.id,
                            //                  "name": shipping_provider.name,
                            //                  "logo_url": shipping_provider.logo_url,
                            //              }
                            //          ]
                            //      },
                            //    ]
                            //    pickup_time_id_by_shipping_provider: {
                            //      shipping_provider.id: {'date': 1684573200, 'pickup_time_id': '1684573200'}
                            //    }
                            //   ...
                            // }
                            const pickupTimeIdsInfo = {}
                            newInitShippingInfo['address_id']['time_slot_info_list'].forEach(
                                (timeslot) => {
                                    timeslot?.shipping_providers.forEach((shippingProvider) => {
                                        pickupTimeIdsInfo[shippingProvider.id] =
                                            timeslot['time_slot_list'][0]
                                    })
                                }
                            )
                            newInitShippingInfo[pickupTimesField] = pickupTimeIdsInfo
                        }

                        handleInfoChange(newInitShippingInfo)
                    }}
                />
            )

            // Fields for handoff type
            const initShippingInfoFieldsForHandoffType =
                initShippingInfoFields[handoffTypeValue] ?? {}

            Object.entries(initShippingInfoFieldsForHandoffType).forEach(([field, values]) => {
                if (field === 'sales_requiring_tracking_number') {
                    return
                }

                const {
                    label,
                    label_field: optionLabelField,
                    value_field: optionValueField,
                    input_type: type,
                } = fieldInfo[field]

                if (type === 'select') {
                    components.push(
                        <ShippingInitInfoSelectField
                            field={field}
                            key={field}
                            label={label}
                            value={initShippingInfo[field]}
                            options={values}
                            optionLabelField={optionLabelField}
                            optionValueField={optionValueField}
                            handleSelectChange={handleSelectChange(field, optionValueField)}
                        />
                    )
                } else {
                    components.push(
                        <ShippingInitInfoTextField
                            field={field}
                            key={field}
                            label={label}
                            value={initShippingInfo[field]}
                            handleTextChange={handleTextChange(field)}
                        />
                    )
                }
            })

            // Pickup timeslot
            if (handoffTypeValue === 'pickup') {
                const address = initShippingInfo['address_id']

                const {
                    label: pickupTimesLabel,
                    label_field: pickupTimesLabelField,
                    value_field: pickupTimesValueField,
                } = fieldInfo[pickupTimesField]

                components.push(
                    <div key={address.address_id}>
                        {address?.time_slot_info_list.map((time_slot, index) => {
                            const shippingProviders = time_slot.shipping_providers
                            return (
                                <div key={index}>
                                    <div className={classes.shopeeShippingProviderList}>
                                        {shippingProviders.map((provider) => (
                                            <img
                                                key={provider.id}
                                                src={provider.logo_url}
                                                alt={provider.name}
                                                className={classes.shippingProviderLogo}
                                            />
                                        ))}
                                    </div>
                                    <ShippingInitInfoSelectField
                                        field={pickupTimesField}
                                        key={shippingProviders[0].id}
                                        label={pickupTimesLabel}
                                        value={
                                            initShippingInfo[pickupTimesField][
                                            shippingProviders[0].id
                                            ]
                                        }
                                        options={
                                            address['time_slot_info_list'][index]['time_slot_list']
                                        }
                                        optionLabelField={pickupTimesLabelField}
                                        optionValueField={pickupTimesValueField}
                                        handleSelectChange={(event) => {
                                            const newInitShippingInfo = copyObject(initShippingInfo)

                                            const value = event.target.value
                                            const valueObj = address['time_slot_info_list'][index][
                                                'time_slot_list'
                                            ].find(
                                                (option) => option[pickupTimesValueField] === value
                                            )
                                            shippingProviders.forEach((shippingProvider) => {
                                                newInitShippingInfo[pickupTimesField][
                                                    shippingProvider.id
                                                ] = valueObj
                                            })

                                            handleInfoChange(newInitShippingInfo)
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                )
            }

            if ('sales_requiring_tracking_number' in initShippingInfoFieldsForHandoffType) {
                const field = 'sales_requiring_tracking_number'

                const handleTrackingNumberChange = (saleID) => (event) => {
                    const newInitShippingInfo = copyObject(initShippingInfo)
                    const value = event.target.value

                    if (!newInitShippingInfo[field]) {
                        newInitShippingInfo[field] = {}
                    }

                    newInitShippingInfo[field][saleID] = value

                    handleInfoChange(newInitShippingInfo)
                }

                initShippingInfoFieldsForHandoffType[field].map((saleID) => {
                    const component = (
                        <ShippingInitInfoTextField
                            field={'tracking_number'}
                            key={`tracking_number_${saleID}`}
                            label={`${gettext('Order')} ${saleID}`}
                            value={initShippingInfo[field][saleID]}
                            handleTextChange={handleTrackingNumberChange(saleID)}
                        />
                    )
                    components.push(component)
                })
            }
        }

        return components
    }, [
        fieldInfo,
        handleInfoChange,
        handleSelectChange,
        handleTextChange,
        handoffTypeLabel,
        handoffTypeLabelField,
        handoffTypeValueField,
        initShippingInfo,
        initShippingInfoFields,
        classes,
    ])

    useEffect(() => {
        const newInitShippingInfo = {}
        const handoffTypeObj = initShippingInfoFields[handoffTypeField][0]
        const handoffTypeValue = handoffTypeObj[handoffTypeValueField]

        newInitShippingInfo[handoffTypeField] = handoffTypeObj

        Object.entries(initShippingInfoFields[handoffTypeValue]).forEach(([field, values]) => {
            newInitShippingInfo[field] = values[0]
        })
        if (handoffTypeValue === 'pickup') {
            const pickupTimeIdsInfo = {}
            newInitShippingInfo['address_id']['time_slot_info_list'].forEach((timeslot) => {
                timeslot?.shipping_providers.forEach((shippingProvider) => {
                    pickupTimeIdsInfo[shippingProvider.id] = timeslot['time_slot_list'][0]
                })
            })
            newInitShippingInfo.pickup_time_id_by_shipping_provider = pickupTimeIdsInfo
        }
        handleInfoChange(newInitShippingInfo)
    }, [])

    const unavailableHandoffTypes = initShippingInfoFields.unavailable_handoff_type || []

    return (
        <div>
            {unavailableHandoffTypes.map((item) => (
                <Alert
                    style={{ marginBottom: 24 }}
                    severity="warning"
                    icon={false}
                    key={item['value']}
                >
                    {interpolate(
                        gettext(
                            '%s is available for some selected orders but not for those shipped by %s (orders %s)'
                        ),
                        [
                            item['label'],
                            item['shipping_providers'].join(', '),
                            item['sale_ids'].join(', '),
                        ]
                    )}
                </Alert>
            ))}
            {components}
        </div>
    )
}

const JDCentralInitShippingInfoManager = (props) => {
    const { initShippingInfoFields, fieldInfo, initShippingInfo, handleInfoChange } = props
    const field = 'pickup_address'
    const { label, label_field: optionLabelField, value_field: optionValueField } = fieldInfo[field]
    const values = initShippingInfoFields[field]

    const handleSelectChange = (field, optionValueField) => (event) => {
        const newInitShippingInfo = copyObject(initShippingInfo)
        const value = event.target.value
        const valueObj = initShippingInfoFields[field].find(
            (option) => option[optionValueField] === value
        )
        newInitShippingInfo[field] = valueObj

        handleInfoChange(newInitShippingInfo)
    }

    useEffect(() => {
        const newInitShippingInfo = copyObject(initShippingInfo)
        const valueObj = initShippingInfoFields[field][0]
        newInitShippingInfo[field] = valueObj

        handleInfoChange(newInitShippingInfo)
    }, [])

    return (
        <div>
            {!objectIsEmpty(initShippingInfo) && (
                <ShippingInitInfoSelectField
                    field={field}
                    key={field}
                    label={label}
                    value={initShippingInfo[field]}
                    options={values}
                    optionLabelField={optionLabelField}
                    optionValueField={optionValueField}
                    handleSelectChange={handleSelectChange(field, optionValueField)}
                />
            )}
        </div>
    )
}

function TikTokInitShippingInfoManager(props) {
    const { initShippingInfoFields, fieldInfo, initShippingInfo, handleInfoChange } = props

    const handoffTypeField = 'pick_up_type'
    const {
        label: handoffTypeLabel,
        label_field: handoffTypeLabelField,
        value_field: handoffTypeValueField,
    } = fieldInfo[handoffTypeField]

    const components = useMemo(() => {
        const components = []

        if (!objectIsEmpty(initShippingInfo)) {
            // Handoff Type

            const handoffTypeValue = initShippingInfo[handoffTypeField][handoffTypeValueField]
            const handoffTypeOptions = initShippingInfoFields[handoffTypeField]
            const pickupTimesField = 'pick_up'

            components.push(
                <ShippingInitInfoSelectField
                    field={handoffTypeField}
                    key={handoffTypeField}
                    label={handoffTypeLabel}
                    value={initShippingInfo[handoffTypeField]}
                    options={handoffTypeOptions}
                    optionLabelField={handoffTypeLabelField}
                    optionValueField={handoffTypeValueField}
                    handleSelectChange={(event) => {
                        const newInitShippingInfo = {}
                        const value = event.target.value
                        const handoffTypeObj = initShippingInfoFields[handoffTypeField].find(
                            (option) => option[handoffTypeValueField] === value
                        )
                        newInitShippingInfo[handoffTypeField] = handoffTypeObj

                        const handoffTypeValue = handoffTypeObj[handoffTypeValueField]
                        if (initShippingInfoFields[handoffTypeValue]) {
                            Object.entries(initShippingInfoFields[handoffTypeValue]).forEach(
                                ([field, values]) => {
                                    newInitShippingInfo[field] = values[0]
                                }
                            )
                        }

                        if (handoffTypeValue === 1) {
                            newInitShippingInfo[pickupTimesField] =
                                initShippingInfoFields.pick_up_time_list?.length > 0
                                    ? initShippingInfoFields.pick_up_time_list[0]
                                    : null
                        }

                        handleInfoChange(newInitShippingInfo)
                    }}
                />
            )

            // Pickup timeslot
            if (handoffTypeValue === 1) {
                const pickupTimesList = initShippingInfoFields.pick_up_time_list
                if (!pickupTimesList.length) {
                    return components
                }

                const {
                    label: pickupTimesLabel,
                    label_field: pickupTimesLabelField,
                    value_field: pickupTimesValueField,
                } = fieldInfo[pickupTimesField]

                components.push(
                    <ShippingInitInfoSelectField
                        field={pickupTimesField}
                        key={pickupTimesField}
                        label={pickupTimesLabel}
                        value={initShippingInfo[pickupTimesField]}
                        options={pickupTimesList}
                        optionLabelField={pickupTimesLabelField}
                        optionValueField={pickupTimesValueField}
                        handleSelectChange={(event) => {
                            const newInitShippingInfo = copyObject(initShippingInfo)

                            const value = event.target.value
                            const valueObj = pickupTimesList.find(
                                (option) => option[pickupTimesValueField] === value
                            )
                            newInitShippingInfo[pickupTimesField] = valueObj

                            handleInfoChange(newInitShippingInfo)
                        }}
                    />
                )
            }
        }

        return components
    }, [
        fieldInfo,
        handleInfoChange,
        handoffTypeLabel,
        handoffTypeLabelField,
        handoffTypeValueField,
        initShippingInfo,
        initShippingInfoFields,
    ])

    useEffect(() => {
        const newInitShippingInfo = {}
        newInitShippingInfo[handoffTypeField] = initShippingInfoFields[handoffTypeField][0]
        handleInfoChange(newInitShippingInfo)
    }, [])

    const unavailableHandoffTypes = initShippingInfoFields.unavailable_handoff_type || []

    return (
        <div>
            {unavailableHandoffTypes.map((item) => (
                <Alert
                    style={{ marginBottom: 24 }}
                    severity="warning"
                    icon={false}
                    key={item['value']}
                >
                    {interpolate(
                        gettext(
                            '%s is available for some selected orders but not for those shipped by %s (orders %s)'
                        ),
                        [
                            item['label'],
                            item['shipping_providers'].join(', '),
                            item['sale_ids'].join(', '),
                        ]
                    )}
                </Alert>
            ))}
            {components}
        </div>
    )
}

function NocNocInitShippingInfoManager(props) {
    const { fieldInfo, initShippingInfo, handleInfoChange } = props

    const handleNumericTextChange = (field) => (event) => {
        const newInitShippingInfo = copyObject(initShippingInfo)
        const value = event.target.value.replace(/\D/g, '')
        newInitShippingInfo[field] = value

        handleInfoChange(newInitShippingInfo)
    }

    const field = 'number_of_packages'
    const { label } = fieldInfo[field]

    return (
        <div>
            <ShippingInitInfoTextField
                field={field}
                key={field}
                label={label}
                value={initShippingInfo[field]}
                handleTextChange={handleNumericTextChange(field)}
            />
        </div>
    )
}

const managerLookup = {
    Shopee: ShopeeInitShippingInfoManager,
    Lazada: LazadaInitShippingInfoManager,
    TikTok: TikTokInitShippingInfoManager,
    'JD Central': JDCentralInitShippingInfoManager,
    NocNoc: NocNocInitShippingInfoManager,
}

const getChannelShippingInfoManager = (channelName) => {
    return managerLookup[channelName] || InitShippingInfoManager
}

export { getChannelShippingInfoManager }
