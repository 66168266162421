import React from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import grey from '@material-ui/core/colors/grey'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import classNames from 'classnames'
import Cookie from 'js-cookie'
import moment from 'moment'
import PropTypes from 'prop-types'

import { getPath } from '../../oakra/components/base-app'
import { patch } from '../../tools/request'
import { stableSort, getSorting } from '../../tools/table-tools'
import { copyObject } from '../../tools/utils'

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        minHeight: 67,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    spacer: {
        flex: '1 1 auto',
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'flex',
    },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },
    topPagination: {
        width: 200,
    },
    title: {
        flex: '0 0 auto',
        marginTop: 8,
    },
    toolbarItems: {
        display: 'flex',
        alignItems: 'baseline',
    },
    storeSelector: {
        width: 200,
    },
    statusSelector: {
        marginLeft: 20,
    },
    storeSelectorSelect: {
        height: 38,
    },
    statusSelectorSelect: {
        height: 38,
    },
    addButton: {
        height: 38,
        alignSelf: 'center',
        width: 175,
    },
})

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        position: 'relative',
    },
    toolbar: {
        position: 'fixed',
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
    },
    tableHeader: {
        borderTop: '1px solid ' + grey[200],
    },
    tableCell: {
        padding: '0 12px',
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    checkboxColumnHeader: {
        width: 70,
    },
    checkboxRowCell: {
        padding: '0 12px',
    },
    discountLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    createDiscountLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    noDiscountsMessage: {
        textAlign: 'center',
        padding: 75,
        paddingRight: 51,
    },
    noDiscountsIcon: {
        width: 100,
        height: 100,
        color: grey[300],
    },
    noDiscountsText: {
        color: grey[400],
    },
    tableAddButton: {
        marginTop: 20,
    },
})

const shouldDisableDelete = (discountStartTime) => {
    if (!discountStartTime) return false

    const startTime = moment(discountStartTime)
    const now = moment()

    const duration = moment.duration(now.diff(startTime)).asMinutes()
    return duration < 60 && duration > 0
}

class ShopSelector extends React.PureComponent {
    render() {
        const { classes } = this.props
        return (
            <FormControl className={classes.storeSelector} variant="outlined">
                <InputLabel>{gettext('Store')}</InputLabel>
                <Select
                    className={classes.storeSelectorSelect}
                    value={this.props.selectedShopID}
                    onChange={this.props.storeChangeHandler}
                    input={<OutlinedInput labelWidth={100} />}
                >
                    <MenuItem value="all" key="all">
                        {pgettext('all stores', 'All')}
                    </MenuItem>
                    {this.props.stores.map((store) => (
                        <MenuItem value={store.id} key={store.id}>
                            {store.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }
}

ShopSelector = withStyles(toolbarStyles)(ShopSelector)

const columns = [
    { id: 'name', align: 'left', label: gettext('Name'), sort: true, width: 250 },
    {
        id: 'store__name',
        align: 'left',
        label: gettext('Store'),
        sort: true,
        visible: (props) => props.showStoreName,
    },
    { id: 'start_date', align: 'left', label: gettext('Start date'), sort: true },
    { id: 'end_date', align: 'left', label: gettext('End date'), sort: true },
    {
        id: 'status',
        align: 'left',
        label: gettext('Status'),
        sort: true,
        visible: (props) => props.showStatus,
    },
    { id: 'num_products', align: 'left', label: gettext('# of products'), sort: true },
    { id: 'active', align: 'center', label: gettext('Active'), sort: false, width: 100 },
    { id: 'more_button', align: 'left', label: '', sort: false, width: 80 },
]

const DiscountActionsButton = ({ onCopyDiscount }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleAction = () => {
        onCopyDiscount()
        handleClose()
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="three-dots-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="three-dots-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleAction}>{gettext('Copy discount')}</MenuItem>
            </Menu>
        </div>
    )
}

class DiscountsTableHead extends React.PureComponent {
    createSortHandler(property) {
        return (event) => {
            this.props.onRequestSort(event, property)
        }
    }

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, classes } = this.props

        return (
            <TableHead className={classes.tableHeader}>
                <TableRow>
                    <TableCell className={classes.tableCell} style={{ width: 70 }}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount !== 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {columns.map((column) => {
                        const colVisible = column.visible
                            ? typeof (column.visible == 'function')
                                ? column.visible(this.props)
                                : column.visible
                            : true
                        return (
                            colVisible && (
                                <TableCell
                                    hidden={
                                        column.visible
                                            ? typeof (column.visible == 'function')
                                                ? !column.visible(this.props)
                                                : !!column.visible
                                            : false
                                    }
                                    className={classes.tableCell}
                                    key={column.id}
                                    align={column.align}
                                    sortDirection={
                                        orderBy.replace('-', '') === column.id &&
                                        (orderBy.startsWith('-') ? 'desc' : 'asc')
                                    }
                                    width={column.width}
                                >
                                    {column.sort ? (
                                        <TableSortLabel
                                            active={orderBy.replace('-', '') === column.id}
                                            direction={orderBy.startsWith('-') ? 'desc' : 'asc'}
                                            onClick={this.createSortHandler(column.id)}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    ) : (
                                        column.label
                                    )}
                                </TableCell>
                            )
                        )
                    }, this)}
                </TableRow>
            </TableHead>
        )
    }
}

DiscountsTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

DiscountsTableHead = withStyles(styles)(DiscountsTableHead)

class StatusSelector extends React.PureComponent {
    render() {
        const { classes, selectedStatus, handleStatusSelectorChange } = this.props
        const allStatuses = ['expired', 'current', 'upcoming']

        return (
            <FormControl className={classes.statusSelector} variant="outlined">
                <InputLabel>{gettext('Status')}</InputLabel>
                <Select
                    className={classes.statusSelectorSelect}
                    value={selectedStatus}
                    onChange={handleStatusSelectorChange}
                    input={<OutlinedInput labelWidth={100} />}
                >
                    <MenuItem value="all" key="all">
                        All
                    </MenuItem>
                    {allStatuses.map((status) => (
                        <MenuItem key={status} value={status}>
                            {gettext(status).charAt(0).toUpperCase() + gettext(status).slice(1)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }
}

StatusSelector = withStyles(toolbarStyles)(StatusSelector)

class DiscountsTableToolbar extends React.PureComponent {
    render() {
        const {
            numSelected,
            selectedShopID,
            handleShopChange,
            handleStatusSelectorChange,
            selectedStatus,
            handleChangePage,
            handleDelete,
            handleNewDiscountClick,
            stores,
            page,
            count,
            classes,
            loading,
        } = this.props

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                {this.props.loading && (
                    <LinearProgress className={classes.progress} color="secondary" />
                )}
                <div className={classes.title}>
                    {numSelected > 0 ? (
                        <Typography color="inherit" variant="subtitle1">
                            {interpolate(pgettext('Number selected', '%s selected'), [numSelected])}
                        </Typography>
                    ) : (
                        <div className={classes.toolbarItems}>
                            <ShopSelector
                                selectedShopID={selectedShopID}
                                stores={stores}
                                storeChangeHandler={handleShopChange}
                            />
                            <StatusSelector
                                selectedStatus={selectedStatus}
                                handleStatusSelectorChange={handleStatusSelectorChange}
                            />
                        </div>
                    )}
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    {numSelected > 0 ? (
                        <React.Fragment>
                            <Button color="secondary" onClick={handleDelete}>
                                {ngettext('Delete discount', 'Delete discounts', numSelected)}
                            </Button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {stores.length == 1 ? (
                                <Button
                                    component={Link}
                                    to={getPath('createDiscount').replace('store_id', stores[0].id)}
                                    target="_blank"
                                    variant="outlined"
                                    color="primary"
                                    className={classes.addButton}
                                >
                                    <AddIcon />
                                    {gettext('New discount')}
                                </Button>
                            ) : (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.addButton}
                                    onClick={handleNewDiscountClick}
                                >
                                    <AddIcon />
                                    {gettext('New discount')}
                                </Button>
                            )}
                            <TablePagination
                                className={classes.topPagination}
                                component="div"
                                count={count}
                                rowsPerPage={25}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': gettext('Previous'),
                                }}
                                nextIconButtonProps={{
                                    'aria-label': gettext('Next'),
                                }}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[25]}
                            />
                        </React.Fragment>
                    )}
                </div>
            </Toolbar>
        )
    }
}

DiscountsTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
}

DiscountsTableToolbar = withStyles(toolbarStyles)(DiscountsTableToolbar)

class Discounts extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            orderBy: '-start_date',
            selected: [],
            rowsPerPage: 25,
            selectedShopID: 'all',
            discounts: [],
            count: 0,
            page: 0,
            maxPageFetched: 0,
            deleteConfirmOpen: false,
            newProductSelectShopOpen: false,
            loading: true,
            selectedStatus: 'all',
        }

        this.fetchDiscountsData = this.fetchDiscountsData.bind(this)
        this.getDataURL = this.getDataURL.bind(this)
        this.handleShopChange = this.handleShopChange.bind(this)
        this.handleStatusSelectorChange = this.handleStatusSelectorChange.bind(this)
        this.handleSort = this.handleSort.bind(this)
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleChecked = this.handleChecked.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleNewDiscountClick = this.handleNewDiscountClick.bind(this)
        this.handleNewDiscountSelectShopClose = this.handleNewDiscountSelectShopClose.bind(this)
        this.isSelected = this.isSelected.bind(this)
    }

    componentDidMount() {
        this.fetchDiscountsData(this.getDataURL(), false)
    }

    addStoresInfoToDiscountsData(data) {
        const { stores } = this.props,
            storesById = stores.reduce((allById, store) => {
                allById[store.id] = store
                return allById
            }, {})
        return data.map((discount) => ({ ...discount, storeName: storesById[discount.store].name }))
    }

    fetchDiscountsData(url, replace, page = null, searchTerm = null) {
        if (!url.includes('order_by')) {
            url += '?order_by=' + this.state.orderBy
        }

        if (this.state.selectedStatus != 'all') {
            url += '&status=' + this.state.selectedStatus
        }

        return fetch(url.replace('store_pk', this.state.selectedShopID))
            .then((response) => response.json())
            .then((data) => {
                let discounts

                data = this.addStoresInfoToDiscountsData(data)

                if (replace) {
                    discounts = data
                } else {
                    discounts = copyObject(this.state.discounts).concat(data)
                }

                const newState = {
                    count: discounts.length,
                    discounts: discounts,
                    loading: false,
                }

                if (page === 0) {
                    newState['page'] = 0
                    newState['maxPageFetched'] = 0
                } else if (page > 0) {
                    newState['page'] = page
                }

                this.setState(newState, () => {})
            })
    }

    getDataURL() {
        const { accountOwnerID, discountsForStoreAPIURL, discountsForUserAPIURL } = this.props

        if (this.state.selectedShopID == 'all') {
            return discountsForUserAPIURL.replace('user_pk', accountOwnerID)
        } else {
            return discountsForStoreAPIURL.replace('store_pk', this.state.selectedShopID)
        }
    }

    handleShopChange(e) {
        this.setState(
            {
                selectedShopID: e.target.value,
                loading: true,
            },
            () => {
                this.fetchDiscountsData(this.getDataURL(), true, 0)
            }
        )
    }

    handleStatusSelectorChange(e) {
        this.setState(
            {
                selectedStatus: e.target.value,
                loading: true,
            },
            () => {
                this.fetchDiscountsData(this.getDataURL(), true, 0)
            }
        )
    }

    handleSort(event, property) {
        let orderBy

        if (this.state.orderBy.startsWith('-') && this.state.orderBy.replace('-', '') == property) {
            orderBy = property
        } else {
            orderBy = '-' + property
        }

        this.setState(
            {
                orderBy,
            },
            () => {
                this.fetchDiscountsData(this.getDataURL(), true, 0)
            }
        )
    }

    handleSelectAllClick(event, checked) {
        if (checked) {
            this.setState((state) => ({ selected: this.state.discounts.map((d) => d.id) }))
            return
        } else {
            this.setState({ selected: [] })
        }
    }

    handleClick(event, id) {}

    handleChecked(event, checked, productID) {
        const { selected } = this.state
        let newSelected = [...selected]

        if (checked) {
            if (!selected.includes(productID)) {
                newSelected.push(productID)
            }
        } else {
            newSelected = newSelected.filter((id) => id != productID)
        }

        this.setState({ selected: newSelected })
    }

    handleChangePage(event, page) {
        let pageChangeURL = this.getDataURL()
        const needsFetch = page > this.state.maxPageFetched

        this.setState({
            page: page,
        })
    }

    handleDeleteConfirmClose(confirm) {
        const { discountAPIURL } = this.props
        let discounts = copyObject(this.state.discounts)
        const selected = copyObject(this.state.selected)

        return (event) => {
            if (confirm) {
                discounts = discounts.filter((d) => !selected.includes(d.id))

                let stateToSet = {
                    selected: [],
                    count: discounts.length,
                    discounts: discounts,
                    deleteConfirmOpen: false,
                }

                if (
                    this.state.page > 0 &&
                    (this.state.page + 1) * this.state.rowsPerPage > discounts.length
                ) {
                    stateToSet['page'] = this.state.page - 1
                }

                this.setState(stateToSet, () => {
                    selected.forEach((discountID) => {
                        fetch(discountAPIURL.replace('discount_id', discountID), {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                                'X-CSRFToken': Cookie.get('csrftoken'),
                            },
                        })
                            .then((response) => response.json())
                            .catch((error) => {})
                    })
                })
            } else {
                this.setState({ deleteConfirmOpen: false })
            }
        }
    }

    handleDelete() {
        this.setState({
            deleteConfirmOpen: true,
        })
    }

    handleNewDiscountClick() {
        this.setState({
            newProductSelectShopOpen: true,
        })
    }

    handleNewDiscountSelectShopClose() {
        this.setState({
            newProductSelectShopOpen: false,
        })
    }

    handleActiveSwitch = (discountId, checked) => {
        console.log('handleActiveSwitch', discountId, checked)
        const { discountAPIURL } = this.props
        const payload = {
            is_active: checked,
        }
        let discounts = this.state.discounts.map((discount) => {
            if (discount.id === discountId) {
                return { ...discount, is_active: checked }
            }
            return { ...discount }
        })

        this.setState(
            {
                discounts,
            },
            () => {
                patch(discountAPIURL.replace('discount_id', discountId), payload)
                    .then((response) => {
                        console.log('update discount', response)
                    })
                    .catch((error) => {
                        console.log('update discount error', error)
                    })
            }
        )
    }

    isSelected(discountID) {
        return this.state.selected.includes(discountID)
    }

    render() {
        const { classes, language, stores } = this.props
        const { order, orderBy, selected, rowsPerPage, page } = this.state

        const displayedDiscounts = stableSort(this.state.discounts, getSorting(orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        )

        moment.locale(language)

        return (
            <React.Fragment>
                <Paper className={classes.root}>
                    <DiscountsTableToolbar
                        className={classes.toolbar}
                        numSelected={selected.length}
                        selectedShopID={this.state.selectedShopID}
                        stores={this.props.stores}
                        handleShopChange={this.handleShopChange}
                        handleStatusSelectorChange={this.handleStatusSelectorChange}
                        selectedStatus={this.state.selectedStatus}
                        handleChangePage={this.handleChangePage}
                        handleDelete={this.handleDelete}
                        handleNewDiscountClick={this.handleNewDiscountClick}
                        count={this.state.count}
                        page={this.state.page}
                        loading={this.state.loading}
                    />
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <DiscountsTableHead
                                className={classes.tableHead}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={this.handleSelectAllClick}
                                onRequestSort={this.handleSort}
                                rowCount={this.state.discounts.length}
                                showStoreName={this.state.selectedShopID == 'all'}
                                showStatus={this.state.selectedStatus == 'all'}
                            />
                            <TableBody>
                                {!this.state.loading && this.state.discounts.length == 0 && (
                                    <TableRow>
                                        <TableCell
                                            colSpan={5}
                                            className={classNames(
                                                classes.noDiscountsMessage,
                                                classes.tableCell
                                            )}
                                        >
                                            <LocalOfferIcon className={classes.noDiscountsIcon} />
                                            <Typography
                                                className={classes.noDiscountsText}
                                                variant="subtitle1"
                                            >
                                                {gettext('No discounts to display')}
                                            </Typography>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                className={classes.tableAddButton}
                                                onClick={this.handleNewDiscountClick}
                                            >
                                                <AddIcon />
                                                {gettext('New discount')}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {displayedDiscounts.map((discount) => {
                                    const isSelected = this.isSelected(discount.id)
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) =>
                                                this.handleClick(event, discount.id)
                                            }
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={discount.id}
                                            selected={isSelected}
                                        >
                                            <TableCell
                                                padding="checkbox"
                                                className={classes.tableCell}
                                            >
                                                <Checkbox
                                                    checked={isSelected}
                                                    onChange={(event, checked) =>
                                                        this.handleChecked(
                                                            event,
                                                            checked,
                                                            discount.id
                                                        )
                                                    }
                                                    disabled={shouldDisableDelete(
                                                        discount.start_date
                                                    )}
                                                />
                                            </TableCell>

                                            <TableCell
                                                scope="row"
                                                padding="none"
                                                className={classes.tableCell}
                                            >
                                                <Link
                                                    to={getPath('discount').replace(
                                                        'discount_id',
                                                        discount.id
                                                    )}
                                                    className={classes.discountLink}
                                                >
                                                    <Typography noWrap={true} variant="body2">
                                                        {discount.name}
                                                    </Typography>
                                                </Link>
                                            </TableCell>
                                            {this.state.selectedShopID == 'all' && (
                                                <TableCell
                                                    scope="row"
                                                    padding="none"
                                                    className={classes.tableCell}
                                                >
                                                    <Link
                                                        to={getPath('discount').replace(
                                                            'discount_id',
                                                            discount.id
                                                        )}
                                                        className={classes.discountLink}
                                                    >
                                                        <Typography noWrap={true} variant="body2">
                                                            {discount.storeName}
                                                        </Typography>
                                                    </Link>
                                                </TableCell>
                                            )}
                                            <TableCell className={classes.tableCell}>
                                                <Link
                                                    to={getPath('discount').replace(
                                                        'discount_id',
                                                        discount.id
                                                    )}
                                                    className={classes.discountLink}
                                                >
                                                    <Typography noWrap={true} variant="body2">
                                                        {discount.start_date
                                                            ? moment(discount.start_date).format(
                                                                  'lll'
                                                              )
                                                            : '-'}
                                                    </Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Link
                                                    to={getPath('discount').replace(
                                                        'discount_id',
                                                        discount.id
                                                    )}
                                                    className={classes.discountLink}
                                                >
                                                    <Typography noWrap={true} variant="body2">
                                                        {discount.end_date
                                                            ? moment(discount.end_date).format(
                                                                  'lll'
                                                              )
                                                            : '-'}
                                                    </Typography>
                                                </Link>
                                            </TableCell>
                                            {this.state.selectedStatus == 'all' && (
                                                <TableCell
                                                    scope="row"
                                                    padding="none"
                                                    className={classes.tableCell}
                                                >
                                                    <Link
                                                        to={getPath('discount').replace(
                                                            'discount_id',
                                                            discount.id
                                                        )}
                                                        className={classes.discountLink}
                                                    >
                                                        <Typography noWrap={true} variant="body2">
                                                            {gettext(discount.status)
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                                gettext(discount.status).slice(1)}
                                                        </Typography>
                                                    </Link>
                                                </TableCell>
                                            )}
                                            <TableCell className={classes.tableCell}>
                                                <Link
                                                    to={getPath('discount').replace(
                                                        'discount_id',
                                                        discount.id
                                                    )}
                                                    className={classes.discountLink}
                                                >
                                                    <Typography noWrap={true} variant="body2">
                                                        {discount.num_products}
                                                    </Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Switch
                                                    checked={discount.is_active}
                                                    onChange={(e, checked) =>
                                                        this.handleActiveSwitch(
                                                            discount.id,
                                                            checked
                                                        )
                                                    }
                                                    disabled={discount.status === 'expired'}
                                                />
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <DiscountActionsButton
                                                    onCopyDiscount={() => {
                                                        this.props.history.push(
                                                            `${getPath('createDiscount').replace(
                                                                'store_id',
                                                                discount.store
                                                            )}/?copy_discount_id=${discount.id}`
                                                        )
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        component="div"
                        count={this.state.count}
                        rowsPerPage={rowsPerPage}
                        page={this.state.page}
                        backIconButtonProps={{
                            'aria-label': gettext('Previous'),
                        }}
                        nextIconButtonProps={{
                            'aria-label': gettext('Next'),
                        }}
                        onPageChange={this.handleChangePage}
                        rowsPerPageOptions={[25]}
                    />
                </Paper>

                <Dialog
                    open={this.state.deleteConfirmOpen}
                    onClose={this.handleDeleteConfirmClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {ngettext(
                            'Delete this discount?',
                            'Delete these discounts?',
                            this.state.selected.length
                        )}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {ngettext(
                                'It will be removed from all linked platforms.',
                                'They will be removed from all linked platforms.',
                                this.state.selected.length
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteConfirmClose(false)} color="primary">
                            {gettext('Cancel')}
                        </Button>
                        <Button
                            onClick={this.handleDeleteConfirmClose(true)}
                            color="primary"
                            autoFocus
                        >
                            {gettext('Confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.newProductSelectShopOpen}
                    onClose={this.handleNewDiscountSelectShopClose}
                    fullWidth={true}
                    maxWidth="xs"
                    aria-labelledby="select-store"
                >
                    <DialogTitle id="select-store">{gettext('Select store')}</DialogTitle>
                    <div>
                        <List>
                            {stores.map((store) => (
                                <Link
                                    key={store.id}
                                    to={getPath('createDiscount').replace('store_id', store.id)}
                                    target="_blank"
                                    className={classes.createDiscountLink}
                                    onClick={this.handleNewDiscountSelectShopClose}
                                >
                                    <ListItem button>
                                        <ListItemText primary={store.name} />
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}

Discounts.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Discounts)
