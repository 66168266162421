// Shop selector
import Select, { components } from 'react-select'

import grey from '@material-ui/core/colors/grey'
import { withTheme, withStyles } from '@material-ui/core/styles'


import ChannelIcons from '../../../products/components/channel-icons'


const shopSelectorStyles = (theme) => ({})

const { Option, Control } = components
const ShopOption = (props) => (
    <Option {...props}>
        <div style={{ display: 'flex', columnGap: 5 }}>
            <ChannelIcons channels={[props.data.value.channel]} />
            <span>{props.data.label}</span>
        </div>
    </Option>
)
const customSingleValue = (props) => {
    const sel = props.getValue()[0]
    return (
        <div style={{ display: 'flex', columnGap: 5 }}>
            <ChannelIcons channels={[sel.value.channel]} />
            <span>{sel.label}</span>
        </div>
    )
}
const fnLabelSort = (a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0)

let ShopSelector = ({
    placeholder = gettext('Select shop'),
    data,
    dataIsStores,
    onChange,
    shopId,
    theme,
}) => {
    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: 30,
            minWidth: 300,
            borderColor: state.isFocused ? theme.palette.primary.main : 'hsl(0,0%,80%)',
            boxShadow: state.isFocused ? theme.palette.primary.main : 'none',
            '&:hover': {
                borderColor: theme.palette.primary.main,
            },
            color: grey[700],
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: 4,
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            padding: 4,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: '0px 6px',
            color: grey[700],
        }),
        menu: (provided, state) => ({
            ...provided,
            color: grey[700],
        }),
        input: (provided, state) => ({
            ...provided,
            margin: 0,
            padding: 0,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            paddingTop: 1,
        }),
    }

    const filterUseableShops = (shops) => shops.filter((shop) => shop.can_create_sale_manually)

    const makeShopOptions = (shops) => shops.map((shop) => ({ label: shop.shop_name, value: shop }))

    let shopOptions = []
    if (dataIsStores) {
        shopOptions = data
            .map((store) => ({
                label: `${store.name}`,
                options: makeShopOptions(filterUseableShops(store.shops)).sort(fnLabelSort),
            }))
            .sort(fnLabelSort)
    } else {
        shopOptions = makeShopOptions(filterUseableShops(data)).sort(fnLabelSort)
    }

    const findOption = (opts, shopId) => {
        if (opts.length == 0) return false
        const toSearch = opts[0]?.label
            ? opts.reduce((all, { options }) => all.concat(options), [])
            : opts
        return toSearch.find(({ value: shop }) => shop.id === shopId)
    }

    return (
        <Select
            styles={selectStyles}
            onChange={(selectedOption) => onChange(selectedOption.value)}
            placeholder={placeholder}
            isSearchable={false}
            isClearable={false}
            value={findOption(shopOptions, shopId)}
            options={shopOptions}
            components={{ Option: ShopOption, SingleValue: customSingleValue }}
        />
    )
}

ShopSelector = withTheme(withStyles(shopSelectorStyles)(ShopSelector))

export default ShopSelector
