import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'

import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
    row: {
        marginBottom: 25,
    },
}))

export default function NonFieldErrors({ errors = [] }) {
    const classes = useStyles()

    return (
        <div className={classes.row} style={{ marginTop: '2rem' }}>
            {errors.map(({ non_field_errors }) => {
                if (!Array.isArray(non_field_errors) || !non_field_errors.length) {
                    return null
                }

                return non_field_errors.map((err) => (
                    <Alert severity="error" key={JSON.stringify(err)}>
                        {err}
                    </Alert>
                ))
            })}
        </div>
    )
}

NonFieldErrors.propTypes = {
    errors: PropTypes.arrayOf(
        PropTypes.shape({
            non_field_errors: PropTypes.arrayOf(PropTypes.string),
        })
    ),
}
