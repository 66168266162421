import { useMemo } from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import AttributeAsync from './attribute/attribute-async'
import AttributeMultiOption from './attribute/attribute-multi-option'
import AttributeOption from './attribute/attribute-option'
import AttributeSizeChart from './attribute/attribute-size-chart'
import AttributeUnit from './attribute/attribute-unit'
import type {
    Attribute,
    ProductAttributeValueName,
    StockUnitAttributeValueName,
} from './attribute/types'
import ShopeeLogistics from './shopee/shopee-logistics'

import { api, queryKeys } from '~/api'
import { FormField } from '~/common/components'
import { useLanguage } from '~/common/hooks'
import { ChannelId } from '~/constants'
import { useProductFormContext } from '~/products/hooks/useProductForm'

type Props = {
    shopId: number
    channelId: number
    categoryId: number
}

export function ChannelAttributes({ shopId, channelId, categoryId }: Props) {
    const language = useLanguage()
    const { watch } = useProductFormContext()
    const stockUnits = watch('stock_units')
    const {
        data: categoryAttributesData,
        isLoading,
        error,
    } = api.channels.categoryAttributes.useQuery(
        queryKeys.channels.categoryAttributes({ channelId, categoryId }).queryKey,
        {
            query: {
                shop_id: shopId,
            },
            params: {
                channelId,
                categoryId: categoryId.toString(),
                language,
            },
        },
        {
            enabled: categoryId > 0,
            select: (data) => {
                return {
                    ...data,
                    body: data.body
                        .map((attr) => ({
                            ...attr,
                            options: attr.options.sort((a, b) => a.label.localeCompare(b.label)),
                        }))
                        .sort((attributeA, attributeB) => {
                            // Place 'json' attribute_type at the bottom
                            if (
                                attributeA.attribute_type === 'json' &&
                                attributeB.attribute_type !== 'json'
                            ) {
                                return 1
                            } else if (
                                attributeA.attribute_type !== 'json' &&
                                attributeB.attribute_type === 'json'
                            ) {
                                return -1
                            }

                            return 0
                        }),
                }
            },
        }
    )

    if (isLoading) {
        return (
            <>
                {Array.from({ length: 10 }).map((_, i) => (
                    <Skeleton key={i} height={48} />
                ))}
            </>
        )
    }

    if (error) {
        const errorMessage =
            error.status === 403
                ? error.body.error
                : gettext(
                      `Unable to fetch attributes for category ${categoryId} in channel ${channelId}`
                  )

        return (
            <Typography color="error" sx={{ gridColumn: '1/-1' }}>
                {errorMessage}
            </Typography>
        )
    }

    const categoryAttributes = categoryAttributesData.body
    const stockUnitAttributes = categoryAttributes.filter((attr) => attr.sku_attribute)
    const productAttributes = categoryAttributes.filter((attr) => !attr.sku_attribute)

    return (
        <>
            <AttributeContainer sx={{ mt: 2 }}>
                {productAttributes.map((attribute) => (
                    <Box
                        key={attribute.attribute_id}
                        sx={{
                            display: 'flex',
                            gridGap: '0.25rem',
                            gridColumn: attribute.attribute_type === 'json' ? '1 / -1' : undefined,
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <ChannelAttribute
                                type="product"
                                name={`channel_attribute_values.${channelId}.product.${attribute.attribute_id}.value`}
                                attribute={attribute}
                                categoryId={categoryId}
                                channelId={channelId}
                            />
                        </Box>

                        {attribute.units.length > 0 && (
                            <AttributeUnit attribute={attribute} channelId={channelId} />
                        )}
                    </Box>
                ))}
            </AttributeContainer>
            {stockUnitAttributes.length > 0 && (
                <Stack rowGap={2}>
                    {stockUnits.map(({ sku, id }, index) => (
                        <Stack key={id}>
                            <Typography variant="subtitle2">
                                {interpolate(gettext('Stock unit %s'), [index + 1])}{' '}
                                {sku && `(${sku})`}
                            </Typography>

                            <AttributeContainer>
                                {stockUnitAttributes.map((attribute) => (
                                    <ChannelAttribute
                                        key={attribute.attribute_id}
                                        name={`channel_attribute_values.${channelId}.stockUnit.${id.toString()}.${
                                            attribute.attribute_id
                                        }`}
                                        type="stockUnit"
                                        attribute={attribute}
                                        categoryId={categoryId}
                                        channelId={channelId}
                                    />
                                ))}
                            </AttributeContainer>
                        </Stack>
                    ))}
                </Stack>
            )}
        </>
    )
}

type ChannelProductAttributeProps = {
    type: 'product'
    name: ProductAttributeValueName
    attribute: Attribute
    categoryId: number
    channelId: number
}

type ChannelStockUnitAttributeProps = {
    type: 'stockUnit'
    name: StockUnitAttributeValueName
    attribute: Attribute
    categoryId: number
    channelId: number
}

function ChannelAttribute({
    type,
    name,
    attribute,
    categoryId,
    channelId,
}: ChannelProductAttributeProps | ChannelStockUnitAttributeProps) {
    const { control, getValues } = useProductFormContext()
    const channel = getValues(`channels`).find((c) => c.id === channelId)
    if (!channel) {
        throw new Error(`Channel ${channelId} not found`)
    }

    const shopeeProducts = useMemo(
        () => getValues(`channel_products`).filter((cp) => cp.shop.channel.id === ChannelId.Shopee),
        [getValues]
    )

    const shopIds = useMemo(() => {
        const set = new Set(shopeeProducts.map((cp) => cp.shop.id))
        return Array.from(set)
    }, [shopeeProducts])

    if (attribute.should_fetch_options_async || attribute.can_create_option) {
        return (
            <AttributeAsync
                shouldFetch={type === 'product'}
                name={name}
                attribute={attribute}
                categoryId={categoryId}
            />
        )
    }

    if (attribute.attribute_id === 'logistics' && type === 'product') {
        /**
         * TODO: Look up channel ID here to get shopee/tiktok channel
         */
        return (
            <>
                <Divider />
                <Box sx={{ p: 2 }}>
                    <Typography gutterBottom>{gettext('Logistics')}</Typography>
                    <ShopeeLogistics
                        name={name}
                        countryCode={channel.country_code}
                        categoryId={categoryId}
                        shopeeProducts={shopeeProducts}
                        shopIds={shopIds}
                    />
                </Box>
            </>
        )
    }

    if (attribute.attribute_id === 'size_chart') {
        return <AttributeSizeChart name={name} attribute={attribute} />
    }

    switch (attribute.attribute_type) {
        case 'text':
            return (
                <FormField
                    fullWidth
                    label={attribute.name}
                    required={attribute.required}
                    control={control}
                    name={name}
                    rules={{
                        required:
                            attribute.required &&
                            interpolate(gettext('%s is required'), [attribute.name]),
                    }}
                />
            )
        case 'richtext':
            return (
                <FormField
                    multiline
                    fullWidth
                    control={control}
                    name={name}
                    required={attribute.required}
                    label={attribute.name}
                    rules={{
                        required:
                            attribute.required &&
                            interpolate(gettext('%s is required'), [attribute.name]),
                    }}
                />
            )
        case 'integer':
        case 'float':
            return (
                <FormField
                    fullWidth
                    type="number"
                    label={attribute.name}
                    required={attribute.required}
                    helperText={attribute.tip}
                    control={control}
                    name={name}
                    rules={{
                        required:
                            attribute.required &&
                            interpolate(gettext('%s is required'), [attribute.name]),
                    }}
                />
            )
        case 'option':
            return <AttributeOption name={name} attribute={attribute} />
        case 'multi_option':
            return <AttributeMultiOption name={name} attribute={attribute} />
        default:
            return (
                <TextField
                    fullWidth
                    disabled
                    variant="outlined"
                    margin="dense"
                    size="small"
                    required={attribute.required}
                    label={attribute.name}
                    helperText={gettext('Attribute type not supported')}
                />
            )
    }
}

const AttributeContainer = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gridAutoRows: 'max-content',
    gridGap: theme.spacing(1),
}))
