import { connect } from 'react-redux'

import StockItemSelector from '../components/stock-item-selector'

const mapStateToProps = (state) => ({
    stockItemsAPIURL: state.initial.endpoints.stockItemsAPIURL,
    stockAdjustmentGroupsAPIURL: state.initial.endpoints.stockAdjustmentGroupsAPIURL,
    stockAdjustmentsUpdateAPIURL: state.initial.endpoints.stockAdjustmentsUpdateAPIURL,
    staticRoot: state.initial.staticRoot,
    stores: state.stores,
    userID: state.initial.user.id,
    language: state.initial.language,
})

export default connect(mapStateToProps)(StockItemSelector)
