import { useMemo, useState, useCallback, memo, useContext } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { DndContext, type DragEndEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { isEqual } from 'lodash-es'

import { type ProductFormSchema, useProductFormContext } from '../../hooks/useProductForm'
import { useCommonStyles } from '../../styles'
import { AttributesContext } from '../attributes-provider'

import ProductDimensionDroppable from './product-dimension-droppable'
import ProductDimensionsFooter from './product-dimensions-footer'
import type { NormalizedDimension } from './schemas'
import { convertDimensionsToNormalized, convertNormalizedDimensionsToPayload } from './utils'

import { useProductPage } from '~/products/hooks/useProductPage'
import { getRequiredLanguages } from '~/products/utils/getRequiredLanguages'
import { createStockUnit, sortStockUnitsByDimensionOptions } from '~/products/utils/stock-unit'
import { getAllPossibleDimensionsOptions } from '~/utils/getAllPossibleDimensionsOptions'
import { makeDimensionOptions } from '~/utils/makeDimensionOptions'

export default memo(function ProductDimensions({ isNewProduct }: { isNewProduct: boolean }) {
    const commonClasses = useCommonStyles()

    const { normalizedDimensions, addDimension, replaceDimensions, removeDimension } =
        useDimensionsFields(isNewProduct)

    const { selectedDimension, existingDimensions, selectDimensionId } =
        useSelectedDimension(normalizedDimensions)

    const {
        updateStockUnitsDimensionsOrder,
        updateStockUnitsOptions,
        replaceStockUnits,
        syncChannelStockUnits,
    } = useStockUnits()

    const handleDragEnd = useCallback(
        ({ active, over }: DragEndEvent) => {
            if (active.id === over?.id) {
                return
            }

            const newIndex = normalizedDimensions.findIndex(({ id }) => id === active.id)
            const oldIndex = normalizedDimensions.findIndex(({ id }) => id === over?.id)

            replaceDimensions(arrayMove(normalizedDimensions, oldIndex, newIndex))
            updateStockUnitsDimensionsOrder()
        },
        [normalizedDimensions, replaceDimensions, updateStockUnitsDimensionsOrder]
    )

    const handleDimensionEdit = useCallback(
        (dimension?: NormalizedDimension) => {
            selectDimensionId(dimension?.id ?? null)
        },
        [selectDimensionId]
    )

    const handleDimensionsChange = useCallback(
        (updatedDimensions: NormalizedDimension[]) => {
            replaceDimensions(updatedDimensions)
            handleDimensionEdit()

            const currentOptions = getAllPossibleDimensionsOptions(normalizedDimensions)
            const upcomingOptions = getAllPossibleDimensionsOptions(updatedDimensions)
            if (isEqual(currentOptions, upcomingOptions)) {
                return
            }

            updateStockUnitsOptions(currentOptions, upcomingOptions)
        },
        [handleDimensionEdit, normalizedDimensions, replaceDimensions, updateStockUnitsOptions]
    )

    const handleDimensionAdd = useCallback(
        (dimension: NormalizedDimension) => {
            addDimension(dimension)
            replaceStockUnits()
            syncChannelStockUnits()
            handleDimensionEdit()
        },
        [addDimension, handleDimensionEdit, syncChannelStockUnits, replaceStockUnits]
    )

    const handleDimensionRemove = useCallback(
        (index: number) => {
            removeDimension(index)
            replaceStockUnits()
            syncChannelStockUnits()
        },
        [removeDimension, syncChannelStockUnits, replaceStockUnits]
    )

    return (
        <Paper className={commonClasses.section}>
            <Typography variant="subtitle1" className={commonClasses.sectionTitle}>
                {gettext('Options')}
            </Typography>

            <Box my={2} maxWidth="50%">
                {normalizedDimensions.length > 0 && (
                    <DndContext onDragEnd={handleDragEnd}>
                        <ProductDimensionDroppable
                            isNewProduct={isNewProduct}
                            selectedDimension={selectedDimension}
                            existingDimensions={existingDimensions}
                            normalizedDimensions={normalizedDimensions}
                            onEdit={handleDimensionEdit}
                            onChange={handleDimensionsChange}
                            onRemove={handleDimensionRemove}
                        />
                    </DndContext>
                )}
            </Box>

            {isNewProduct && (
                <ProductDimensionsFooter
                    existingDimensions={existingDimensions}
                    nextDimensionIndex={normalizedDimensions.length}
                    onDimensionAdd={handleDimensionAdd}
                    onDimensionFormOpen={handleDimensionEdit}
                />
            )}
        </Paper>
    )
})

function useDimensionsFields(isNewProduct: boolean) {
    const { control, getValues } = useProductFormContext()
    const { fields, replace, append, remove } = useFieldArray({
        control,
        name: 'dimensions',
        keyName: 'dimensionId',
    })

    const normalizedDimensions = useMemo(() => convertDimensionsToNormalized(fields), [fields])

    const replaceDimensions = useCallback(
        (updatedDimensions: NormalizedDimension[]) => {
            const orderedDimensions = updatedDimensions.map((dimension, index) => ({
                ...dimension,
                index,
            }))
            const languages = getRequiredLanguages(getValues('channel_products'), isNewProduct)
            const converted = convertNormalizedDimensionsToPayload(orderedDimensions, languages)

            replace(converted)
        },
        [getValues, isNewProduct, replace]
    )

    const addDimension = useCallback(
        (dimension: NormalizedDimension) => {
            const languages = getRequiredLanguages(getValues('channel_products'), isNewProduct)
            const index = fields.length
            const [newDimension] = convertNormalizedDimensionsToPayload(
                [{ ...dimension, index }],
                languages
            )

            append(newDimension)
        },
        [append, fields.length, getValues, isNewProduct]
    )

    const removeDimension = useCallback(
        (dimensionIndex: number) => {
            remove(dimensionIndex)
        },
        [remove]
    )

    return {
        normalizedDimensions,
        addDimension,
        replaceDimensions,
        removeDimension,
    }
}

function useSelectedDimension(dimensions: NormalizedDimension[]) {
    const { control } = useProductFormContext()
    const formDimensions = useWatch({ control, name: 'dimensions' })
    const [selectedDimensionId, setSelectedDimensionId] = useState<string | number | null>(null)

    const selectedDimension = useMemo(
        () => dimensions.find(({ id }) => id === selectedDimensionId),
        [dimensions, selectedDimensionId]
    )

    const selectDimensionId = useCallback((id: typeof selectedDimensionId) => {
        setSelectedDimensionId(id)
    }, [])

    const existingDimensions = useMemo(() => {
        return dimensions.filter(
            (_, index) => index !== (selectedDimension?.index ?? formDimensions.length)
        )
    }, [formDimensions.length, dimensions, selectedDimension?.index])

    return { selectedDimension, existingDimensions, selectDimensionId }
}

function useStockUnits() {
    const { productInfo } = useProductPage()
    const { locations } = productInfo

    const { getValues, setValue } = useProductFormContext()

    const {
        replaceStockUnits: replaceChannelAttributesStockUnits,
        deleteStockUnits: deleteChannelAttributesStockUnits,
    } = useContext(AttributesContext)

    const createStockUnitsByOptions = useCallback(
        (options: Array<string | number | Array<string | number>>) => {
            const dimensions = getValues('dimensions')
            const product = getValues()
            return options.map((optionIds, index) => {
                const dimensionOptions = Array.isArray(optionIds)
                    ? makeDimensionOptions(optionIds, dimensions)
                    : [{ index, id: optionIds, product_dimension: { index: 0 } }]

                return createStockUnit(dimensionOptions, locations, product)
            })
        },
        [locations, getValues]
    )

    const syncChannelStockUnits = useCallback(() => {
        const stockUnits = getValues('stock_units')
        const channelProducts = getValues('channel_products')

        const updatedChannelProducts = channelProducts.map((cp) => {
            const channelStockUnitById = new Map(
                cp.channel_stock_units.filter(Boolean).map((csu) => [csu.stock_unit?.id ?? -1, csu])
            )

            const channelStockUnits = stockUnits.map(
                (su) =>
                    channelStockUnitById.get(su.id) ?? {
                        price: '0',
                        images: [],
                        placeholder: false,
                        stock_unit: su,
                        channel_stock_item: { fulfillment_stock_record_options: [] },
                        channel_stock_unit_id: null,
                        fulfillment_locations: su.fulfillment_locations.map(
                            ({ location_id }) => location_id
                        ),
                        sku: su.sku,
                    }
            ) satisfies ProductFormSchema['channel_products'][number]['channel_stock_units']

            return {
                ...cp,
                channel_stock_units: channelStockUnits,
            }
        })

        setValue('channel_products', updatedChannelProducts)
    }, [getValues, setValue])

    const replaceStockUnits = useCallback(() => {
        const currentDimensions = getValues('dimensions')
        const product = getValues()
        const allPossibleOptions = getAllPossibleDimensionsOptions(currentDimensions)
        const stockUnitsByOptions = createStockUnitsByOptions(allPossibleOptions)
        const stockUnits =
            stockUnitsByOptions.length > 0
                ? stockUnitsByOptions
                : [createStockUnit([], locations, product)]

        setValue('stock_units', stockUnits)
        syncChannelStockUnits()
        replaceChannelAttributesStockUnits(stockUnits)
    }, [
        getValues,
        createStockUnitsByOptions,
        setValue,
        syncChannelStockUnits,
        replaceChannelAttributesStockUnits,
        locations,
    ])

    const updateStockUnitOptionsOrder = useCallback(
        (stockUnits: ProductFormSchema['stock_units']) => {
            const dimensions = getValues('dimensions')
            return sortStockUnitsByDimensionOptions(
                stockUnits.map((su) => ({
                    ...su,
                    dimension_options: su.dimension_options.map((options) => {
                        const dimension = dimensions.at(options.product_dimension.index)
                        const dimensionOption = dimension?.options.find(
                            ({ id }) => id === options.id
                        )

                        return dimensionOption
                            ? { ...options, index: dimensionOption.index }
                            : options
                    }),
                }))
            )
        },
        [getValues]
    )

    const updateStockUnitsDimensionsOrder = useCallback(() => {
        const dimensions = getValues('dimensions')
        const stockUnits = [...getValues('stock_units')]

        dimensions.forEach(({ options, index }) => {
            const optionIdSet = new Set(options.map(({ id }) => id))
            stockUnits.forEach(({ dimension_options }) => {
                dimension_options.forEach(({ id, product_dimension }) => {
                    if (optionIdSet.has(id)) {
                        product_dimension.index = index
                    }
                })
                dimension_options.sort(
                    (a, b) => a.product_dimension.index - b.product_dimension.index
                )
            })
        })

        setValue('stock_units', sortStockUnitsByDimensionOptions(stockUnits))
        replaceChannelAttributesStockUnits(stockUnits)
        syncChannelStockUnits()
    }, [getValues, replaceChannelAttributesStockUnits, setValue, syncChannelStockUnits])

    const removeStockUnits = useCallback(
        (options: Array<Array<string | number>>, stockUnits: ProductFormSchema['stock_units']) => {
            const stockUnitOptions = stockUnits.map((su) => {
                return su.dimension_options.map(({ id }) => id)
            })

            const indexesToRemove = new Set(
                options.map((options) => {
                    return stockUnitOptions.findIndex((t) =>
                        Array.isArray(t) ? isEqual(t, options) : t === options[0]
                    )
                })
            )

            return indexesToRemove
        },
        []
    )

    const updateStockUnitsOptions = useCallback(
        (
            currentOptions: Array<Array<string | number>>,
            upcomingOptions: Array<Array<string | number>>
        ) => {
            const upcomingOptionsMap = new Map(
                upcomingOptions.map((options, index) => [options.join('_'), index])
            )

            const optionsToRemove: Array<Array<string | number>> = []
            let shouldReorder = false

            currentOptions.forEach((options, index) => {
                const key = options.join('_')
                const upcomingIndex = upcomingOptionsMap.get(key)

                if (upcomingIndex === undefined) {
                    optionsToRemove.push(options)
                    return
                }

                /**
                 * Remove existing options from upcoming options
                 * This will leave only new options
                 */
                upcomingOptionsMap.delete(key)

                if (upcomingIndex !== index) {
                    shouldReorder = true
                    return
                }

                if (upcomingIndex === index) {
                    return
                }
            })

            const optionsToAdd = upcomingOptions.filter((options) =>
                upcomingOptionsMap.has(options.join('_'))
            )

            let stockUnits = getValues('stock_units')
            if (optionsToRemove.length) {
                const indexesToRemove = removeStockUnits(optionsToRemove, stockUnits)

                deleteChannelAttributesStockUnits([...indexesToRemove])
                stockUnits = stockUnits.filter((_, index) => !indexesToRemove.has(index))
            }

            if (shouldReorder) {
                stockUnits = updateStockUnitOptionsOrder(stockUnits)
            }

            if (optionsToAdd.length) {
                const newStockUnits = createStockUnitsByOptions(optionsToAdd)
                stockUnits = updateStockUnitOptionsOrder([...stockUnits, ...newStockUnits])
            }

            setValue('stock_units', stockUnits)
            replaceChannelAttributesStockUnits(stockUnits)
            syncChannelStockUnits()
        },
        [
            getValues,
            setValue,
            replaceChannelAttributesStockUnits,
            syncChannelStockUnits,
            removeStockUnits,
            deleteChannelAttributesStockUnits,
            updateStockUnitOptionsOrder,
            createStockUnitsByOptions,
        ]
    )

    return {
        createStockUnitsByOptions,
        replaceStockUnits,
        syncChannelStockUnits,
        updateStockUnitsDimensionsOrder,
        updateStockUnitsOptions,
    }
}
