import { useRouteMatch } from 'react-router-dom'

import { api, queryKeys } from '~/api'
import { useUser } from '~/common/hooks'
import Product from '~/products/components/product'
import ProductSkeleton from '~/products/components/product-skeleton'
import { ProductPageProvider } from '~/products/hooks/useProductPage'
import { useAppSelector } from '~/store'

export default function ProductCreatePage() {
    const { params } = useRouteMatch<{ store_id: string }>()
    const store = useAppSelector(
        ({ initial: { stores } }) =>
            stores.find((store) => store.id === parseInt(params.store_id)) ?? stores[0]
    )

    const user = useUser()
    const { data, isLoading, error } = api.users.locations.useQuery(
        queryKeys.users.locations(user.id).queryKey,
        { params: { id: user.id } }
    )

    if (isLoading) {
        return <ProductSkeleton />
    }

    if (error) {
        return <div>Error</div>
    }

    return (
        <ProductPageProvider isNewProduct store={store} locations={data.body}>
            <Product />
        </ProductPageProvider>
    )
}
