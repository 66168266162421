import { useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { grey } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import ViewModuleIcon from '@material-ui/icons/ViewModule';

import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'


import TableSkeleton from './table-skeleton'

import { api, queryKeys } from '~/api'

const useStyles = makeStyles(theme => ({
    tableContainer: {
        maxHeight: 300,
    },
    table: {
        maxWidth: '100%',
        tableLayout: 'fixed',
        marginBottom: 15,
    },
    tableHeader: {
        borderTop: '1px solid ' + grey[200],
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell: {
        padding: '9px 12px',
        wordWrap: 'break-word',
        fontSize: '0.85rem',
    },
    tableHeadCell: {
        padding: '12px',
        fontSize: '0.85rem',
    },
    tableText: {
        fontSize: '0.85rem',
    },
    bundleSkuField: {
        width: '100%',
    },
    bundleUnitsField: {
        width: '100%',
    },
    clearBundleButton: {
        color: theme.palette.error.main,
    },
    placeholder: {
        background: grey[100],
        height: 110,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: "column",
        marginBottom: 10
    },
    placeholderIcon: {
        fontSize: 48,
        color: grey[500]
    },
    placeholderText: {
        color: grey[700]
    }
}))

const BundleItem = ({ stockItem, onClose, open }) => {
    const classes = useStyles()
    const [data, setData] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const queryClient = useQueryClient()

    const { data: stockItemDetail, isLoading } = api.stock.stockItem.useQuery(
        queryKeys.stock.stockItem(stockItem.id).queryKey,
        {
            params: { id: stockItem.id },
        },
    )
    const currentStockItem = stockItemDetail?.body

    useEffect(() => {
        if (currentStockItem) {
            setData(currentStockItem.bundle_items.map(item => ({
                sku: item.member_bundle_item.sku,
                num_of_units: item.num_of_units
            })))
        }
    }, [currentStockItem])

    const addBundleMember = () => {
        setData([
            ...data,
            {
                sku: '',
                num_of_units: 1
            }
        ])
    }

    const removeBundleMember = (index) => {
        data.splice(index, 1)
        setData([...data])
    }

    const updateBundleMember = (itemIndex, values) => {
        setData(data.map((item, index) => index === itemIndex ? { ...item, ...values } : item))
    }

    const { mutate: updateStockItem, isLoading: isSaving } = api.stock.updateStockItem.useMutation({
        onSuccess: async (data) => {
            await queryClient.invalidateQueries(queryKeys.stock.stockItem(stockItem.id).queryKey)
            enqueueSnackbar(gettext('Updated success'), { variant: 'success' })
            onClose(data)
        },
        onError: () => {
            enqueueSnackbar(gettext('Update error'), { variant: 'error' })
        }
    })

    const handleSave = () => {
        const payload = {
            is_bundle: data.length > 0,
            bundle_items: data.filter(item => item.sku && item.num_of_units).map(item => ({
                member_bundle_item: { sku: item.sku },
                num_of_units: item.num_of_units
            }))
        }
        updateStockItem({
            body: payload,
            params: { id: currentStockItem.id },
        })
    }

    const handleClearBundle = () => {
        const payload = {
            is_bundle: false,
            bundle_items: []
        }
        updateStockItem({
            body: payload,
            params: { id: currentStockItem.id },
        })
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose()}
            aria-labelledby="event-dialog-title"
            maxWidth={800}
        >
            <DialogTitle id="event-dialog-title">{gettext('Bundle')}</DialogTitle>
            <DialogContent>
                <div style={{ width: 500 }}>
                    {isLoading ? (
                        <TableSkeleton rowsNum={3} columns={[gettext('SKU'), gettext('Units')]} />
                    ) : (
                        <>
                            {data?.length > 0 ?
                                <BundleItemTable
                                    data={data || []}
                                    onBundleMemberRemove={removeBundleMember}
                                    onBundleMemberChange={updateBundleMember}
                                />
                                :
                                <div className={classes.placeholder}>
                                    <ViewModuleIcon className={classes.placeholderIcon} />
                                    <div className={classes.placeholderText}>
                                        {gettext('No bundle items')}
                                    </div>
                                </div>
                            }
                            <Button startIcon={<AddIcon />} onClick={addBundleMember}>
                                {gettext('Add bundled item')}
                            </Button>
                        </>

                    )}
                </div>
            </DialogContent>
            <DialogActions>
                {isSaving && <CircularProgress color="inherit" size={24} style={{ marginRight: 4 }} />}

                {currentStockItem?.is_bundle &&
                    <Button
                        className={classes.clearBundleButton}
                        onClick={handleClearBundle}
                        style={{ float: 'left' }}
                        disabled={isSaving}
                    >
                        {gettext('Clear bundle')}
                    </Button>
                }

                <Button
                    onClick={() => onClose()}
                    color="secondary"
                    disabled={isSaving}
                >
                    {gettext('Close')}
                </Button>

                <Button
                    onClick={handleSave}
                    color="secondary"
                    variant="contained"
                    disabled={isSaving}
                >
                    {gettext('Save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const BundleItemTable = ({ data, onBundleMemberRemove, onBundleMemberChange }) => {
    const classes = useStyles()
    return (
        <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{
                                width: 300,
                            }}
                            className={classes.tableHeadCell}
                        >
                            {gettext('SKU')}
                        </TableCell>
                        <TableCell
                            style={{
                                width: 150,
                            }}
                            className={classes.tableHeadCell}

                        >
                            {gettext('Units')}
                        </TableCell>
                        <TableCell
                            style={{
                                width: 100,
                            }}
                            className={classes.tableHeadCell}
                        />
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data.map(
                        (item, itemIndex) => (
                            <TableRow key={item.id || itemIndex}>
                                <TableCell className={classes.tableCell}>
                                    <TextField
                                        type="text"
                                        className={classes.bundleSkuField}
                                        value={item.sku}
                                        InputProps={{
                                            className: classes.bundleSkuField,
                                        }}
                                        onChange={e => onBundleMemberChange(itemIndex, { sku: e.target.value })}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <TextField
                                        value={item.num_of_units}
                                        type="number"
                                        inputProps={{ min: 1 }}
                                        className={classes.bundleUnitsField}
                                        InputProps={{
                                            className: classes.bundleUnitsField,
                                        }}
                                        onChange={e => onBundleMemberChange(itemIndex, { num_of_units: e.target.value })}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <IconButton
                                        color="secondary"
                                        onClick={() => onBundleMemberRemove(itemIndex)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default BundleItem
