import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

export default function UsersNotFound() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 4,
                gridGap: 10,
            }}
        >
            <AccountCircleIcon color="disabled" />
            <Typography color="textSecondary">{gettext('No users found')}</Typography>
        </Box>
    )
}
