import React from 'react'

import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'

import classNames from 'classnames'

import BaseTable from './table'

export default class SlowSellingTable extends BaseTable {
    columns = [
        {
            id: 'units_sold_during_period',
            align: 'center',
            label: gettext('Sold units'),
            sort: true,
            width: 200,
        },
    ]

    renderToolbarRight = () => {
        const { classes, selected, onAction } = this.props

        return selected.length > 0 ? (
            <Button color="secondary" onClick={() => onAction('disable_slow_selling')}>
                {gettext('Disable slow selling alert')}
            </Button>
        ) : (
            <Button
                variant="outlined"
                color="primary"
                className={classes.toolbarActionButton}
                onClick={() => onAction('export')}
            >
                <GetAppIcon /> {gettext('Export')}
            </Button>
        )
    }

    renderCells = (row) => {
        const { classes } = this.props

        const stock_item = row.stock_item

        return (
            <React.Fragment>
                <TableCell className={classNames(classes.tableCell, classes.centerAlign)}>
                    <Tooltip
                        title={`Alert threshold: ${
                            stock_item.units_sold_during_period_threshold
                        } ${gettext('units in')} ${stock_item.slow_selling_period_size} ${gettext(
                            'days'
                        )}`}
                    >
                        <Typography noWrap={true} variant="body2">
                            {row.units_sold_during_period} {gettext('units in')}{' '}
                            {stock_item.slow_selling_period_size} {gettext('days')}
                        </Typography>
                    </Tooltip>
                </TableCell>
            </React.Fragment>
        )
    }
}
