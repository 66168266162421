import CircularProgress from '@material-ui/core/CircularProgress'
import { grey } from '@material-ui/core/colors'
import GetAppIcon from '@material-ui/icons/GetApp'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import { post } from '../../tools/request'

import { useAppSelector } from '~/store'

const DocumentStatusIcon = ({ document }) => {
    const { status } = document
    if (status === 'generating' || status === 'waiting') {
        return <CircularProgress size={21} color={grey[500]} />
    }

    if (status === 'failed') {
        return <HighlightOffIcon />
    }

    if (status === 'ready') {
        return <DownloadFileIcon document={document} />
    }
    return null
}

const DownloadFileIcon = ({ document }) => {
    const { exportDocumentEventsAPIURL } = useAppSelector((state) => state.initial.endpoints)
    const createDownloadEvent = async () => {
        const res = await post(exportDocumentEventsAPIURL.replace('document_pk', document.id), {
            event_type: 'downloaded',
        })
        console.log('create download event', res)
    }

    const handleClick = () => {
        createDownloadEvent()
        window.open(document.file, 'blank')
    }
    return (
        <a style={{ cursor: 'pointer' }} onClick={handleClick}>
            <GetAppIcon />
        </a>
    )
}

export default DocumentStatusIcon
