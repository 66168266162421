import { useState, useRef, useEffect, useMemo, useCallback } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'

import { get } from '../../tools/request'
import CustomerEditor from '../containers/customer-editor'
import CustomerSummary from '../containers/customer-summary'

const useCustomerButtonStyles = makeStyles(() => ({
    panel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}))

const CreateCustomerButton = ({ onCreate }) => {
    const classes = useCustomerButtonStyles()
    const [showDialog, setShowDialog] = useState(false)

    const handleClose = (data) => {
        setShowDialog(false)
        if (data) {
            onCreate(data.customer)
        }
    }
    return (
        <div className={classes.panel}>
            <Button color="secondary" onClick={() => setShowDialog(true)}>
                {gettext('New customer')}
            </Button>

            {showDialog && <CustomerEditor onClose={handleClose} />}
        </div>
    )
}

const useSearchDialogStyles = makeStyles(() => ({
    searchingIndicator: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 250,
    },
    dialogContent: {
        minHeight: 350,
    },
    dialogActions: {
        paddingRight: 24,
        paddingLeft: 24,
        paddingBottom: 15,
        marginTop: 0,
        alignItems: 'space-between',
    },
}))

const SearchDialog = ({
    onClose,
    initialSearchValue,
    customersAPIURL,
    createCustomer,
    onCustomerCreate,
}) => {
    const [searchValue, setSearchValue] = useState(initialSearchValue || '')
    const [searchResult, setSearchResult] = useState([])
    const [isSearching, setIsSearching] = useState(false)
    const inputRef = useRef()

    useEffect(() => {
        setTimeout(() => {
            inputRef.current.focus()
        }, 10)
    }, [])

    const searchCustomer = useCallback(
        (searchValue) => {
            setIsSearching(true)

            get(customersAPIURL, {
                search: searchValue,
                expand: 'default_shipping_address,default_billing_address,default_invoice_address',
            }).then((res) => {
                if (res.ok) {
                    setSearchResult(res.results)
                }
                setIsSearching(false)
            })
        },
        [customersAPIURL]
    )

    useEffect(() => {
        let timeoutId

        const delayedSearch = () => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(() => searchCustomer(searchValue), 1000)
        }

        if (searchValue) {
            delayedSearch()
        }

        return () => clearTimeout(timeoutId)
    }, [searchCustomer, searchValue])

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value)
    }

    const handleClose = () => {
        onClose && onClose()
    }

    const handleSelect = (item) => {
        onClose && onClose(item)
    }

    const handleChange = (customer) => {
        const newSearchResult =
            searchResult?.map((item) =>
                item.id === customer.id ? { ...item, ...customer } : item
            ) || []
        setSearchResult(newSearchResult)
    }

    const classes = useSearchDialogStyles()

    return (
        <Dialog open fullWidth maxWidth="sm" className={classes.dialog} onClose={handleClose}>
            <DialogTitle>{gettext('Search for customer')}</DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={searchValue}
                            inputRef={inputRef}
                            placeholder={gettext('Search for customer')}
                            onInput={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {isSearching ? (
                            <div className={classes.searchingIndicator}>
                                <CircularProgress size={25} />
                            </div>
                        ) : (
                            <div>
                                {searchResult.length === 0 && !!searchValue && (
                                    <span>{gettext('No results')}</span>
                                )}

                                {searchResult.length > 0 && (
                                    <CustomerAddressList
                                        data={searchResult}
                                        onSelect={handleSelect}
                                        onChange={handleChange}
                                    />
                                )}
                            </div>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                {createCustomer ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <CreateCustomerButton onCreate={onCustomerCreate} />
                        <Button color="secondary" onClick={handleClose}>
                            {gettext('Cancel')}
                        </Button>
                    </div>
                ) : (
                    <Button color="secondary" onClick={handleClose}>
                        {gettext('Cancel')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default SearchDialog

const CustomerAddressList = ({ data, onSelect, onChange }) => {
    const [selectedItem, setSelectedItem] = useState(null)
    const handleSelect = (action, item) => {
        if (action === 'select') {
            onSelect && onSelect(item)
        } else if (action === 'edit') {
            setSelectedItem(item)
        }
    }
    const handleClose = (customer) => {
        setSelectedItem(null)

        if (customer) {
            onChange && onChange(customer)
        }
    }

    return (
        <div>
            {data.map((item) => (
                <CustomerAddressItem key={item.id} data={item} onSelect={handleSelect} />
            ))}

            {selectedItem && <CustomerSummary customerId={selectedItem.id} onClose={handleClose} />}
        </div>
    )
}

const useItemStyle = makeStyles((theme) => ({
    item: {
        padding: '8px 0',
        cursor: 'pointer',
        display: 'flex',
    },
    content: {
        width: '100%',
    },
    customer: {},
    address: {
        fontSize: '1em',
        color: theme.palette.grey[500],
    },
    actionBar: {
        display: 'flex',

        '& button': {
            display: 'inline',
            fontSize: '0.9em',
            height: 32,
        },
    },
}))

const CustomerAddressItem = ({ data, onSelect }) => {
    const classes = useItemStyle()

    const customerTexts = useMemo(() => [data.name, data.phone, data.email].filter(Boolean), [data])
    const addressTexts = useMemo(() => {
        const addr = data.default_shipping_address
        return (
            addr &&
            [
                addr.contact_name !== data.name && addr.contact_name,
                addr.phone !== data.phone && addr.phone,
                addr.address1,
                addr.address2,
                addr.city,
                addr.province,
                addr.country,
                addr.post_code,
            ].filter(Boolean)
        )
    }, [data])

    return (
        <div className={classes.item}>
            <div className={classes.content}>
                <Typography className={classes.customer}>{customerTexts.join(', ')}</Typography>

                {addressTexts && (
                    <Typography className={classes.address}>{addressTexts.join(', ')}</Typography>
                )}
            </div>

            <div className={classes.actionBar}>
                <Button color="secondary" onClick={() => onSelect('select', data)}>
                    {gettext('Select')}
                </Button>

                <Button color="secondary" onClick={() => onSelect('edit', data)}>
                    {gettext('Edit')}
                </Button>
            </div>
        </div>
    )
}
