import { useMutation } from '@tanstack/react-query'
import ky, { type HTTPError } from 'ky'

export function useUploadToS3() {
    return useMutation<void, HTTPError, { file: File; presignedUrl: string }>({
        mutationFn: async ({ file, presignedUrl }) => {
            await ky.put(presignedUrl, {
                body: file,
                headers: {
                    'Content-Type': file.type,
                },
            })
        },
    })
}
