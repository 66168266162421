import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'

const userKeys = createQueryKeys('users', {
    accountUserListing: (accountOwnerId: number) => [{ accountOwnerId }],
    groups: (accountOwnerId: number) => [{ accountOwnerId }],
    locations: (userId: number) => [{ userId }],
    permissions: (userId: number) => [{ userId }],
})

const productKeys = createQueryKeys('products', {
    detail: (id: number) => [{ id }],
    stockRecords: (productId: number) => [{ productId }],
    discounts: (productId: number) => [{ productId }],
    activeChannelStockUnitDiscounts: (productId: number) => [{ productId }],
})

const saleKeys = createQueryKeys('sales', {
    ids: (userId: number, filter: Record<string, string>) => [{ userId, filter }],
    filterValues: (userId: number) => [{ userId }],
    fields: (saleId: number) => [{ saleId }],
})

const groupKeys = createQueryKeys('groups', {
    detail: (id: number) => [{ id }],
    permissions: (id: number) => [{ id }],
})

const channelKeys = createQueryKeys('channels', {
    ancestors: ({ channelId, categoryId }: { channelId: number; categoryId: number }) =>
        ['ancestors', { channelId, categoryId }] as const,
    children: ({ channelId, categoryId }: { channelId: number; categoryId: number }) =>
        ['children', { channelId, categoryId }] as const,
    categoryAttributes: ({ channelId, categoryId }: { channelId: number; categoryId: number }) =>
        ['categoryAttributes', { channelId, categoryId }] as const,
    attributeValues: ({ channelProductId }: { channelProductId: number }) =>
        ['attributes', { channelProductId }] as const,
    attributeOptions: (params: {
        attributePk: number
        categoryId: number
        language: string
        search: string
    }) => ['attributeOptions', { ...params }] as const,
})

const stockKeys = createQueryKeys('stock', {
    stockRecords: (query) => [{ ...query }],
    stockRecordAdjustments: (stockRecordId: number, query) => [{ stockRecordId, ...query }],
    stockRecordAdjustmentFilterValues: (id: number) => [{ id }],
    stockItem: (id: number) => [{ id }],
    stockByShop: (params: string) => [{ params }],
    breakdown: (channelStockItemId: number) => [{ channelStockItemId }],
})

export const queryKeys = mergeQueryKeys(
    userKeys,
    productKeys,
    saleKeys,
    groupKeys,
    channelKeys,
    stockKeys
)
