import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Timeline from '@material-ui/lab/Timeline'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'

import classNames from 'classnames'
import moment from 'moment'

import { get } from '../../../tools/request'
import { commonStyles } from '../sale'

import FulfillmentDialog from './fulfillment-dialog'

const fulfillmentSyles = (theme) => ({
    timeline: {
        transform: 'rotate(-90deg)',
        transformOrigin: '44px 30px',
        width: '200px',
        height: '100px',
        display: 'inline-grid',
    },

    timelineContentEven: {
        display: 'inline-block',
        transform: 'rotate(90deg)',
        textAlign: 'center',
        width: '120px',
        height: '60px',
        transformOrigin: '40px -12px',
        color: theme.palette.grey[700],
        fontSize: '0.6rem',
    },

    timelineContentOdd: {
        display: 'inline-block',
        transform: 'rotate(90deg)',
        textAlign: 'center',
        width: '120px',
        height: '60px',
        transformOrigin: '5px -46px',
        color: theme.palette.grey[700],
        fontSize: '0.6rem',
    },

    info: {
        display: 'flex',
        marginBottom: 15,
    },

    infoLabel: {
        color: theme.palette.grey[700],
        fontSize: '0.7rem',
    },

    infoValue: {
        fontSize: '0.8rem',
    },

    infoCell: {
        float: 'left',
        width: '130px',
        height: '50px',
    },

    status: {
        fontSize: '0.6rem',
    },

    timestamp: {
        fontSize: '0.6rem',
    },

    button: {
        height: 32,
        zIndex: 1,
        marginRight: 4,
    },
})

let Fulfillment = ({ classes, statusesMap, baseWidth, data, canInitiateShipping, onAction }) => {
    const {
        tracking_number,
        fulfillment_id,
        shipping_provider,
        fulfillment_events,
        is_shipping_label_available,
    } = data

    const timelineItemCount = Math.max(fulfillment_events.length - 1, 1)
    const timelineWidth = ((baseWidth - 100) * (timelineItemCount + 1)) / timelineItemCount

    return (
        <div style={{ marginTop: 10 }}>
            <div className={classes.info}>
                {shipping_provider && (
                    <div className={classes.infoCell}>
                        <img
                            src={shipping_provider.logo_url}
                            style={{ maxWidth: 105, maxHeight: 50 }}
                        />
                    </div>
                )}

                {fulfillment_id && (
                    <div className={classes.infoCell}>
                        <Typography variant="body2" className={classes.infoLabel}>
                            {gettext('Fulfillment ID')}
                        </Typography>
                        <Typography variant="body2" className={classes.infoValue}>
                            {fulfillment_id}
                        </Typography>
                    </div>
                )}

                {tracking_number ? (
                    <React.Fragment>
                        <div className={classes.infoCell}>
                            <Typography variant="body2" className={classes.infoLabel}>
                                {gettext('Tracking number')}
                            </Typography>
                            <Typography variant="body2" className={classes.infoValue}>
                                {tracking_number}
                            </Typography>
                        </div>

                        <Button
                            color="secondary"
                            size="small"
                            className={classes.button}
                            onClick={() => onAction('view', data)}
                        >
                            {gettext('Details')}
                        </Button>

                        <Button
                            disabled={!is_shipping_label_available}
                            color="secondary"
                            size="small"
                            className={classes.button}
                            onClick={() => onAction('label', data)}
                        >
                            {gettext('View label')}
                        </Button>
                    </React.Fragment>
                ) : (
                    canInitiateShipping && (
                        <Button
                            color="secondary"
                            size="small"
                            className={classes.button}
                            onClick={() => onAction('edit', data)}
                        >
                            {gettext('Initiate Shipping')}
                        </Button>
                    )
                )}
            </div>

            {fulfillment_events && (
                <div style={{ height: 100 }}>
                    <Timeline className={classes.timeline} style={{ height: timelineWidth }}>
                        {fulfillment_events.map((event, i) => (
                            <TimelineItem key={i}>
                                <TimelineSeparator>
                                    <TimelineDot variant="outlined" color="primary" />
                                    {i === 0 || i < fulfillment_events.length - 1 ? (
                                        <TimelineConnector />
                                    ) : undefined}
                                </TimelineSeparator>

                                <TimelineContent>
                                    <div
                                        className={
                                            i % 2
                                                ? classes.timelineContentOdd
                                                : classes.timelineContentEven
                                        }
                                    >
                                        <Typography className={classes.status}>
                                            {statusesMap[event.status]}
                                        </Typography>
                                        <Typography className={classes.timestamp}>
                                            {moment(event.date).format('D MMM HH:mm')}
                                        </Typography>
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                        ))}

                        {fulfillment_events.length === 1 && (
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot variant="outlined" />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <div className={classes.timelineContentEven}></div>
                                </TimelineContent>
                            </TimelineItem>
                        )}
                    </Timeline>
                </div>
            )}
        </div>
    )
}

Fulfillment = withStyles(fulfillmentSyles)(Fulfillment)

const fulfillmentsSyles = (theme) => ({
    ...commonStyles,
    fulfillmentSection: {
        overflow: 'hidden',
    },
})

class Fulfillments extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            fulfillments: [],
            selectedFulfillment: {},
            fulfillmentDialogState: 'hide', // hide | edit | view,
            shippingProviders: [],
        }

        this.elm = React.createRef()
    }

    componentDidMount(prevProps) {
        this.fetchFulfillments()
        this.fetchShippingProviders()
    }

    fetchFulfillments = () => {
        const { sale, fetchFulfillmentsUrl } = this.props
        get(fetchFulfillmentsUrl.replace('sale_pk', sale.id)).then((res) => {
            this.setState({ fulfillments: res.results })
        })
    }

    fetchShippingProviders = () => {
        const { sale, fetchShippingProvidersUrl } = this.props

        get(fetchShippingProvidersUrl.replace('store_pk', sale.shop.store.id)).then((res) => {
            this.setState({ shippingProviders: res.results })
        })
    }

    handleFulfillmentAction = (action, data) => {
        if (action === 'view' || action === 'edit') {
            this.setState({ fulfillmentDialogState: action, selectedFulfillment: data })
        } else if (action === 'label') {
            window.open(
                `${this.props.fulfillmentShippingLabelURL.replace('fulfillment_pk', data.id)}`,
                '_blank'
            )
        }
    }

    handleDialogClose = (state) => {
        this.setState({ fulfillmentDialogState: 'hide' })
        if (state === 'shipping_initiated') {
            this.fetchFulfillments()
            this.props.onFulfillmentStatusChange()
        }
    }

    render() {
        const { classes, fulfillmentStatuses, sale, initiateShippingUrl } = this.props
        const { fulfillments, shippingProviders, fulfillmentDialogState, selectedFulfillment } =
            this.state
        const fulfillmentStatusesMap = fulfillmentStatuses.reduce(
            (statusMap, status) => ((statusMap[status.status] = status.label), statusMap),
            {}
        )
        const canInitiateShipping =
            sale.status === 'pending' &&
            sale?.shop?.channel?.has_integrated_shipping === false &&
            shippingProviders.length > 0

        return (
            <Paper
                className={classNames(
                    classes.fulfillmentSection,
                    classes.basicInfo,
                    classes.section
                )}
                ref={this.elm}
            >
                <Typography variant="subtitle1" className={classes.sectionTitle}>
                    {gettext('Fulfillments')}
                </Typography>

                {fulfillments &&
                    fulfillments.map((item) => (
                        <Fulfillment
                            key={item.id}
                            data={item}
                            statusesMap={fulfillmentStatusesMap}
                            baseWidth={this.elm.current.offsetWidth || 400}
                            canInitiateShipping={canInitiateShipping}
                            onAction={this.handleFulfillmentAction}
                        />
                    ))}
                {fulfillmentDialogState !== 'hide' && (
                    <FulfillmentDialog
                        initiateShippingUrl={initiateShippingUrl}
                        open={fulfillmentDialogState !== 'hide'}
                        mode={fulfillmentDialogState === 'edit' ? 'edit' : 'view'}
                        fulfillment={selectedFulfillment}
                        shippingProviders={shippingProviders}
                        sale={sale}
                        onClose={this.handleDialogClose}
                    />
                )}
            </Paper>
        )
    }
}

Fulfillments = withStyles(fulfillmentsSyles)(Fulfillments)

export default Fulfillments
