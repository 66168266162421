import React from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'

import { getTranslation } from '../../../tools/utils'

import TableToolbar from './table-toolbar'

class StockReportTable extends React.Component {
    baseColumns = [
        { id: 'image', align: 'left', label: '', sort: false, width: 80 },
        { id: 'sku', align: 'left', label: gettext('SKU'), width: 175, sort: false },
        { id: 'name', align: 'left', label: gettext('Name'), sort: false },
        { id: 'quantity', align: 'center', label: gettext('Quantity'), sort: true, width: 150 },
    ]

    isSelected = (rowId) => {
        return this.props.selected.includes(rowId)
    }

    /* eslint-disable @typescript-eslint/no-empty-function */
    renderCells = () => {}

    renderToolbarLeft = () => {}

    renderToolbarRight = () => {}
    /* eslint-enable @typescript-eslint/no-empty-function */

    render() {
        const {
            classes,
            language,
            stores,
            staticRoot,
            orderBy,
            dataSource,
            pagination,
            loading,
            filterValues,
            selected,
            onFilterChange,
            onSelectAllClick,
            onRequestSort,
            onRowChecked,
        } = this.props

        const emptyRows =
            pagination.rowsPerPage - Math.min(pagination.rowsPerPage, dataSource.length)

        const pagingProps = {
            component: 'div',
            backIconButtonProps: {
                'aria-label': gettext('Previous'),
            },
            nextIconButtonProps: {
                'aria-label': gettext('Next'),
            },
            rowsPerPageOptions: [25],
            ...pagination,
        }

        return (
            <React.Fragment>
                <TableToolbar
                    className={classes.toolbar}
                    numSelected={selected.length}
                    stores={stores}
                    filterOptions={this.filterOptions}
                    filterValues={filterValues}
                    loading={loading}
                    leftSection={this.renderToolbarLeft()}
                    rightSection={
                        <React.Fragment>
                            {this.renderToolbarRight()}{' '}
                            {!selected.length && (
                                <TablePagination
                                    {...pagingProps}
                                    className={classes.topPagination}
                                />
                            )}
                        </React.Fragment>
                    }
                    onFilterChange={onFilterChange}
                />

                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <TableHead className={classes.tableHeader}>
                            <TableRow>
                                <TableCell className={classes.tableCell} style={{ width: 70 }}>
                                    <Checkbox
                                        indeterminate={
                                            selected.length > 0 &&
                                            selected.length < dataSource.length
                                        }
                                        checked={
                                            dataSource.length !== 0 &&
                                            selected.length === dataSource.length
                                        }
                                        onChange={onSelectAllClick}
                                    />
                                </TableCell>

                                {this.baseColumns.concat(this.columns).map((column) => {
                                    const isActive = orderBy.replace('-', '') === column.id
                                    const sortDirection = orderBy.startsWith('-') ? 'desc' : 'asc'
                                    return (
                                        <TableCell
                                            className={classes.tableCell}
                                            key={column.id}
                                            align={column.align}
                                            sortDirection={isActive && sortDirection}
                                            width={column.width}
                                        >
                                            {column.sort ? (
                                                <TableSortLabel
                                                    active={isActive}
                                                    direction={sortDirection}
                                                    onClick={() =>
                                                        onRequestSort(
                                                            sortDirection === 'desc'
                                                                ? column.id
                                                                : `-${column.id}`
                                                        )
                                                    }
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            ) : (
                                                column.label
                                            )}
                                        </TableCell>
                                    )
                                }, this)}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {!loading && dataSource.length === 0 && (
                                <TableRow>
                                    <TableCell
                                        colSpan={8}
                                        className={classNames(
                                            classes.noProductMessage,
                                            classes.tableCell
                                        )}
                                    >
                                        <img
                                            className={classes.noProductsIcon}
                                            src={staticRoot + 'web/img/product-gray.png'}
                                        />
                                        <Typography
                                            className={classes.noProductsText}
                                            variant="subtitle1"
                                        >
                                            {gettext('No products to display')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}

                            {dataSource.map((row) => {
                                const stock_item = row.stock_item
                                let product = null
                                if (stock_item.stock_units?.length) {
                                    product = stock_item.stock_units[0]?.product
                                }

                                const rowId = stock_item.id
                                const isSelected = this.isSelected(rowId)

                                const productImageUrl =
                                    product?.cover_image_url ||
                                    staticRoot + 'web/img/placeholder.png'

                                const productName = product
                                    ? getTranslation(product, language, 'name')
                                    : ''

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        key={rowId}
                                        selected={isSelected}
                                    >
                                        <TableCell padding="checkbox" className={classes.tableCell}>
                                            <Checkbox
                                                checked={isSelected}
                                                onChange={(event, checked) =>
                                                    onRowChecked(event, checked, rowId)
                                                }
                                            />
                                        </TableCell>

                                        <TableCell className={classes.tableCell}>
                                            <img
                                                className={classes.productImg}
                                                src={productImageUrl}
                                                height={75}
                                            />
                                        </TableCell>

                                        <TableCell className={classes.tableCell}>
                                            <Typography noWrap={true} variant="body2">
                                                {stock_item.sku}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            component="th"
                                            scope="row"
                                            padding="none"
                                            className={classes.tableCell}
                                        >
                                            <Typography noWrap={true} variant="body2">
                                                {productName}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={classNames(
                                                classes.tableCell,
                                                classes.centerAlign
                                            )}
                                        >
                                            <Typography noWrap={true} variant="body2">
                                                {row.quantity}
                                            </Typography>
                                        </TableCell>

                                        {this.renderCells(row)}
                                    </TableRow>
                                )
                            })}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>

                <TablePagination {...pagingProps} />
            </React.Fragment>
        )
    }
}

export default StockReportTable
