import { z } from 'zod'

import { makeTranslationsSchema } from './translations'

export const Dimension = z.object({
    id: z.number(),
    index: z.number(),
    options: z.array(
        z.object({
            id: z.number(),
            index: z.number(),
            translations: makeTranslationsSchema(z.object({ option: z.string() })),
        })
    ),
    translations: makeTranslationsSchema(z.object({ name: z.string() })),
})
export type Dimension = z.infer<typeof Dimension>
