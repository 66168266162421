import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    tableSkeleton: {
        display: "flex",
        flexDirection: "column"
    },
    row: {
        width: "100%",
        display: "flex"
    },
    cell: {
        flex: "1 0 auto",
        border: "1px solid white"
    }
}))

export default function TableSkeleton({ rowsNum, colsNum, height }) {
    const classes = useStyles()
    return (
        <div className={classes.tableSkeleton}>
            {[...Array(rowsNum)].map((row, index) => (
                <div
                    key={index}
                    className={classes.row}
                >
                    {[...Array(colsNum)].map((_, rowIndex) => (
                        <div
                            key={rowIndex}
                            className={classes.cell}
                        >
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                height={height}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>

    )
}