import { connect } from 'react-redux'

import Reports from '../components/reports'

const mapStateToProps = (state) => ({
    stores: state.stores,
    userID: state.initial.user.id,
    language: state.initial.language,
    staticRoot: state.initial.staticRoot,
    stockUnitsForUserAPIURL: state.initial.endpoints.stockUnitsForUserAPIURL,
    productsForUserAPIURL: state.initial.endpoints.productsForUserAPIURL,
    analyticsSummaryAPIURL: state.initial.endpoints.analyticsSummaryAPIURL,
    analyticsTimeSeriesTableAPIURL: state.initial.endpoints.analyticsTimeSeriesTableAPIURL,
    analyticsTimeSeriesChartAPIURL: state.initial.endpoints.analyticsTimeSeriesChartAPIURL,
})

export default connect(mapStateToProps)(Reports)
