import type { TypedUseSelectorHook } from 'react-redux'
import { useSelector } from 'react-redux'

import type { Shop } from './common/schemas/shop'
import type { Store } from './common/schemas/store'

type User = {
    id: number
    email: string
    first_name: string
    last_name: string
    username: string
    is_demo: boolean
}

export type StoreWithShops = Store & {
    languages: string[]
    shops: Shop[]
}

type ContentType = {
    id: number
    app_label: string
    model: string
}

type State = {
    initial: {
        staticFilesDomain: string
        pageURLs: Record<string, string>
        endpoints: Record<string, string>
        user: User
        accountOwner: User
        loggedIn: boolean
        language: string
        staticRoot: string
        stores: StoreWithShops[]
        contentTypes: ContentType[]
        fulfillmentStatuses: Array<{ status: string; label: string }>
        saleStatuses: Array<{ status: string; label: string }>
    }
    login: {
        loggedIn: boolean
    }
    stores: StoreWithShops[]
}

export const useAppSelector: TypedUseSelectorHook<State> = useSelector
