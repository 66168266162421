import { connect } from 'react-redux'

import Sale from '../components/sale'

const mapStateToProps = (state) => ({
    stores: state.initial.stores.map((store) => ({
        ...store,
        shops: store.shops.map((shop) => ({ ...shop, store_id: store.id })), // set store_id to shop for reference
    })),
    language: state.initial.language,
    userID: state.initial.user.id,
    saleAPIURL: state.initial.endpoints.saleAPIURL,
    cancelSaleAPIURL: state.initial.endpoints.cancelSaleAPIURL,
    saleCreateAPIURL: state.initial.endpoints.saleCreateAPIURL,
    stockUnitsForShopAPIURL: state.initial.endpoints.stockUnitsForShopAPIURL,
    stockUnitsForStoreAPIURL: state.initial.endpoints.stockUnitsForStoreAPIURL,
    productAPIURL: state.initial.endpoints.productAPIURL,
    saleFulfillmentsAPIURL: state.initial.endpoints.saleFulfillmentsAPIURL,
    shippingProvidersForStoreAPIURL: state.initial.endpoints.shippingProvidersForStoreAPIURL,
    fulfillmentInitiateShippingAPIURL: state.initial.endpoints.fulfillmentInitiateShippingAPIURL,
    fulfillmentShippingLabelURL: state.initial.endpoints.fulfillmentShippingLabelURL,
    saleNotesAPIURL: state.initial.endpoints.saleNotesAPIURL,
    saleNoteAPIURL: state.initial.endpoints.saleNoteAPIURL,
    paymentMethods: state.initial.paymentMethods,
    saleStatusMap: state.initial.saleStatuses.reduce(
        (m, status) => ((m[status.status] = status.label), m),
        {}
    ),
    fulfillmentStatuses: state.initial.fulfillmentStatuses,
})

export default connect(mapStateToProps)(Sale)
