import { useWatch } from 'react-hook-form'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { useProductFormContext } from '../hooks/useProductForm'

import TableStockUnitsCoverage from './table-stock-units-coverage'

type Props = {
    open: boolean
    onClose: () => void
}

export default function DialogStockUnitCoverage({ open, onClose }: Props) {
    const { control } = useProductFormContext()
    const [stockUnits, channelProducts] = useWatch({
        control,
        name: ['stock_units', 'channel_products'],
        exact: true,
    })

    return (
        <Dialog open={open}>
            <DialogTitle>{gettext('Stock unit coverage')}</DialogTitle>

            <DialogContent>
                <TableStockUnitsCoverage
                    stockUnits={stockUnits}
                    channelProducts={channelProducts}
                />
            </DialogContent>

            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    {gettext('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
