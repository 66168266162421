import { z } from 'zod'

import { Store } from './store'

const Channel = z.object({
    categories_are_shop_level: z.boolean(),
    channel_icon_url: z.string(),
    channel_square_icon_url: z.string(),
    country_code: z.string().nullable(),
    has_categories: z.boolean(),
    has_category_attributes: z.boolean(),
    has_channel_manager: z.boolean(),
    has_integrated_shipping: z.boolean(),
    id: z.number(),
    name: z.string(),
    selectable: z.boolean(),
    allow_duplicate_skus: z.boolean(),
})
export type Channel = z.infer<typeof Channel>

export const Shop = z.object({
    can_create_sale_manually: z.boolean(),
    channel: Channel,
    country_code: z.string().nullable(),
    date_created: z.string().optional(),
    date_synced: z.string(),
    id: z.number(),
    local_language: z.string().optional(),
    shop_name: z.string(),
    should_adjust_stock_for_sales_from_shop: z.boolean(),
    should_sync_sales_from_shop: z.boolean(),
    should_sync_stock_to_shop: z.boolean(),
    status: z.string(),
    status_display_name: z.string(),
    store: Store.optional(),
    timezone: z.string().optional(),
    extra_info: z.object({ is_mall_shop: z.boolean().optional() }).nullish(),
})
export type Shop = z.infer<typeof Shop>
