import type { AttributeOption } from '../types'

import type { ShopeeLogisticsProvider } from '~/common/schemas/logistics-shopee'

export function isStringArray(value: unknown): value is string[] {
    return Array.isArray(value) && value.length > 0 && value.every((v) => typeof v === 'string')
}

export function isOption(value: unknown): value is AttributeOption {
    return (
        typeof value === 'object' &&
        value !== null &&
        'label' in value &&
        'value' in value &&
        'inactive' in value
    )
}

export function isOptionArray(value: unknown): value is AttributeOption[] {
    return Array.isArray(value) && value.length > 0 && value.every(isOption)
}

function isLogisticsProvider(value: unknown): value is ShopeeLogisticsProvider {
    return typeof value === 'object' && value !== null && 'logistic_id' in value
}

export function isLogisticsProviderArray(value: unknown): value is ShopeeLogisticsProvider[] {
    return Array.isArray(value) && value.length > 0 && value.every(isLogisticsProvider)
}
