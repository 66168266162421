import { shopee, lazada, CHANNEL_ID } from '~/consts'
import { type ChannelValidator } from '~/types'

export function createChannelValidator(channelId: number): ChannelValidator {
    switch (channelId) {
        case CHANNEL_ID.Shopee:
            return shopee.createChannelValidator()
        case CHANNEL_ID.Lazada:
            return lazada.createChannelValidator()
        default:
            return createCommonChannelValidator()
    }
}

function createCommonChannelValidator(): ChannelValidator {
    return {
        validateChannelStockUnitPrice: (value) => {
            return parseFloat(value) > 0 || gettext('Price required')
        },
    }
}
