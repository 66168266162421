import Switch from '@material-ui/core/Switch'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import PropTypes from 'prop-types'

import { useStyles } from './styles'

export default function PickPack({ checked, onChange }) {
    const classes = useStyles()

    return (
        <TableRow>
            <TableCell className={classes.tableCell}>
                <div className={classes.tableCellContainer}>
                    <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                        {gettext('Pick/Pack')}
                    </Typography>
                    <Typography variant="subtitle2" style={{ marginRight: 15 }}>
                        {gettext(
                            'User can perform pick/pack actions on shops they have permission for'
                        )}
                    </Typography>
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.checkContainer}>
                    <Switch checked={checked} onChange={(_, checked) => onChange(checked)} />
                </div>
            </TableCell>
        </TableRow>
    )
}

PickPack.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}
