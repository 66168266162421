import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'

import classNames from 'classnames'

const customerInfoStyles = (theme) => ({
    container: {},
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: 5,
        color: theme.palette.grey[700],
    },
    fieldValue: {
        fontSize: '0.875rem',
        color: theme.palette.grey[700],
    },
    name: {
        fontWeight: 'bold',
        color: theme.palette.grey[900],
    },
})

let CustomerInfo = ({ classes, data }) => {
    const { name, phone, email, tax_id_number } = data

    return (
        <div className={classes.container}>
            <div className={classes.row}>
                <Typography className={classNames(classes.fieldValue, classes.name)}>
                    {name}
                </Typography>
            </div>

            {tax_id_number && (
                <div className={classes.row}>
                    <Typography className={classes.fieldValue}>
                        {gettext('Tax ID:')} {tax_id_number}
                    </Typography>
                </div>
            )}

            {email && (
                <div className={classes.row}>
                    <EmailIcon fontSize="small" className={classes.icon} />
                    <Typography className={classes.fieldValue}>{email}</Typography>
                </div>
            )}

            {phone && (
                <div className={classes.row}>
                    <PhoneIcon fontSize="small" className={classes.icon} />
                    <Typography className={classes.fieldValue}>{phone}</Typography>
                </div>
            )}
        </div>
    )
}

export default withStyles(customerInfoStyles)(CustomerInfo)
