import { connect } from 'react-redux'

import CancelButton from '../components/sale/cancel-button'

const mapStateToProps = (state) => ({
    cancelSaleAPIURL: state.initial.endpoints.cancelSaleAPIURL,
    cancelSaleReasons: state.initial.cancelSaleReasons,
})

export default connect(mapStateToProps)(CancelButton)
