export const languageLabels = {
    th: 'ไทย',
    en: 'English',
    vi: 'Tiếng Việt',
}

export const ChannelId = Object.freeze({
    Lazada: 1,
    LazadaVN: 12,
    Shopee: 2,
    JDCentral: 4,
    Shopify: 5,
    Magneto: 6,
    WooCommerce: 7,
    Facebook: 9,
    TikTok: 14,
})

export const channelProductFieldInfo = {
    [ChannelId.Lazada]: {
        name: {
            minLength: 2,
            maxLength: 255,
        },
        description: {
            minLength: 0,
            maxLength: 25000,
        },
        optionName: {
            maxLength: 20,
        },
        maxImages: 8,
        maxImagesLevel: 'product_or_stock_unit',
    },
    [ChannelId.LazadaVN]: {
        name: {
            minLength: 10,
            maxLength: 255,
        },
        description: {
            minLength: 0,
            maxLength: 25000,
        },
        optionName: {
            maxLength: 20,
        },
        maxImages: 8,
        maxImagesLevel: 'product_or_stock_unit',
    },
    [ChannelId.Shopee]: {
        name: {
            minLength: 30,
            maxLength: 120,
        },
        description: {
            minLength: 25,
            maxLength: 5000,
        },
        optionName: {
            maxLength: 20,
        },
        maxImages: 10, // 9 at product level + 1 at variation level for 2 tier items
        maxImagesLevel: 'product_and_stock_unit',
    },
    [ChannelId.JDCentral]: {
        name: {
            minLength: 21,
            maxLength: 120,
        },
        description: {
            minLength: 25,
            maxLength: 5000,
        },
        optionName: {
            maxLength: 20,
        },
        maxImages: 10,
        maxImagesLevel: 'product_or_stock_unit',
    },
    [ChannelId.Shopify]: {
        name: {
            minLength: 1,
            maxLength: 255,
        },
        description: {
            minLength: 1,
            maxLength: 5000,
        },
        optionName: {
            maxLength: 255,
        },
        maxImages: 250,
        maxImagesLevel: 'product',
    },
    [ChannelId.Magneto]: {
        name: {
            minLength: 1,
            maxLength: 200,
        },
        description: {
            minLength: 1,
            maxLength: 5000,
        },
        optionName: {
            maxLength: 255,
        },
        maxImages: 10,
        maxImagesLevel: 'product',
    },
    [ChannelId.WooCommerce]: {
        name: {
            minLength: 1,
            maxLength: 200,
        },
        description: {
            minLength: 1,
            maxLength: 5000,
        },
        optionName: {
            maxLength: 255, // check real value
        },
        maxImages: 10,
        maxImagesLevel: 'product',
    },
    [ChannelId.Facebook]: {
        name: {
            minLength: 1,
            maxLength: 200,
        },
        description: {
            minLength: 1,
            maxLength: 5000,
        },
        maxImages: 8,
        maxImagesLevel: 'product',
    },
    [ChannelId.TikTok]: {
        name: {
            minLength: 1,
            maxLength: 255,
        },
        description: {
            minLength: 1,
            maxLength: 10000,
        },
        maxImages: 9, // 9 at product level, 3 at SKU level
        maxImagesLevel: 'product_and_stock_unit',
    },
}

export const channelProductFieldInfoForMallShops = {
    [ChannelId.Shopee]: {
        // Shopee Mall shop
        name: {
            minLength: 30,
            maxLength: 255,
        },
        description: {
            minLength: 25,
            maxLength: 6000,
        },
        maxImages: 10, // 9 at product level + 1 at variation level for 2 tier items
        maxImagesLevel: 'product_and_stock_unit',
    },
}

export const currencySymbols = {
    THB: '฿',
    VND: 'đ',
}

export const countryCurrencies = {
    th: 'THB',
    vn: 'VND',
}

export const MAX_SKU_LENGTH = 100
export const MAX_VIRTUALIZED_HEIGHT = 420

export const PATHS = {
    dashboardOverview: '/home/dashboard/overview',
    dashboardReports: '/home/dashboard/reports',
    sales: '/home/sales/process',
    sale: '/home/sale/:sale_id',
    pickPackBatches: '/home/sales/pick-pack-batches',
    pickPackBatch: '/home/sales/pick-pack-batches/:pick_pack_batch_id',
    products: '/home/products/catalog',
    product: '/home/stores/:store_id/products/:product_id',
    mergeRecommendations: '/home/stores/:store_id/merge-recommendations',
    createProduct: '/home/products/create/stores/:store_id',
    copyShopProducts: '/home/products/copy/stores/:store_id',
    mergeProduct: '/home/products/merge/:merge_product_id',
    stockManager: '/home/stock/manager',
    stockReport: '/home/stock/report',
    discounts: '/home/discounts',
    discount: '/home/discount/:discount_id',
    stockAdjustmentGroups: '/home/stock/adjustment-groups',
    stockAdjustmentGroup: '/home/stock/adjustment-groups/:group_id',
    createDiscount: '/home/discount/create/stores/:store_id',
    addChannelSelectChannel: '/home/channels/add/',
    addChannelSelectStore: '/home/channels/:channel_id/add/stores/',
    addChannelInstructions: '/home/channels/:channel_id/stores/:store_id/add/instructions/',
    addChannelResult: '/home/channels/:channel_id/add/result',
    integrations: '/home/integrations',
    productImportIssues: '/home/shop/:shop_id/product-import-issues',
    settings: '/home/settings',
    shopsDashboard: '/dashboard/shops',
    exports: '/home/sales/exports',
    home: '/home',
    accessDenied: '/home/403',
}

export const DEFAULT_MAX_PACKAGE_DIMENSION = 200

export const DEFAULT_DIMENSION_OPTION_MAX_LENGTH = 255

// IMPORTANT! WE USE HARDCODED PERMISSIONS, IF BACKEND PERMISSIONS CHANGE YOU'LL NEED TO UPDATE THE CODES IN permissionCodes
export const permissionCodes = {
    modules: ['module.analytics', 'module.products', 'module.sales', 'module.settings'],
    account: [
        'account.users.view',
        'account.users.edit',
        'account.stores.view',
        'account.stores.edit',
        'account.shops.view',
        'account.shops.edit',
        'account.products.view',
        'account.products.edit',
        'account.sales.view',
        'account.sales.edit',
        'account.analytics.view',
        'account.analytics.edit',
        'account.pickpack.view',
        'account.pickpack.edit',
    ],
    store: [
        'store.shops.view',
        'store.shops.edit',
        'store.products.view',
        'store.products.edit',
        'store.sales.view',
        'store.sales.edit',
    ],
    location: ['location.pickpack.view', 'location.pickpack.edit'],
    shop: ['shop.sales.view', 'shop.sales.edit'],
}

export const MAX_PRODUCT_IMAGES = 8
export const MAX_PRODUCT_VIDEOS = 8
export const MAX_SKU_IMAGES = 5

export const MAX_IMAGE_UPLOAD_SIZE = 1024 * 1024 * 10
export const MAX_VIDEO_UPLOAD_SIZE = 1024 * 1024 * 20
export const ACCEPT_IMAGE_TYPE = 'image/jpeg, image/png'
export const ACCEPT_VIDEO_TYPE = 'video/mp4'

export const ChannelsWithChat = ['Shopee', 'Lazada', 'TikTok']
export const AppType = {
    ERP: 'erp',
    CHAT: 'chat'
}
