import grey from '@material-ui/core/colors/grey'
import { withStyles, useTheme } from '@material-ui/core/styles'

const styles = (theme) => ({
    channelIcons: {
        display: 'flex',
        alignItems: 'center',
    },
    channelIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    channelIconImg: {
        height: 20,
        marginRight: 2,
    },
    productsForChannelCountContainer: {
        border: '1px solid ' + grey[500],
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 2px',
        marginRight: 5,
    },
    productsForChannelCount: {
        margin: 0,
    },
})

const ChannelIcons = (props) => {
    const { classes, channels, hasErrors } = props
    const theme = useTheme()

    const sortedChannels = channels.sort((channel1, channel2) =>
        channel1.id > channel2.id ? 1 : -1
    )

    const uniqueChannelIDs = [...new Set(channels.map((channel) => channel.id))]
    const uniqueChannels = uniqueChannelIDs.map((channelID) =>
        sortedChannels.find((channel) => channel.id == channelID)
    )

    const channelProductsCount = {}

    channels.forEach((channel) => {
        if (channelProductsCount.hasOwnProperty(channel.id)) {
            channelProductsCount[channel.id] += 1
        } else {
            channelProductsCount[channel.id] = 1
        }
    })

    const channelIcons = uniqueChannels.map((channel) => (
        <div className={classes.channelIcon} key={channel.id}>
            <img className={classes.channelIconImg} src={channel.channel_square_icon_url} />
            {channelProductsCount[channel.id] > 1 && (
                <div className={classes.productsForChannelCountContainer}>
                    <p className={classes.productsForChannelCount}>
                        {channelProductsCount[channel.id]}
                    </p>
                </div>
            )}
        </div>
    ))

    if (hasErrors) {
        const errorIndicator = (
            <div
                key="error"
                style={{
                    backgroundColor: theme.palette.error.main,
                    width: 14,
                    height: 14,
                    borderRadius: 7,
                    flexShrink: 0,
                }}
            />
        )

        channelIcons.push(errorIndicator)
    }

    return <div className={classes.channelIcons}>{channelIcons}</div>
}

export default withStyles(styles)(ChannelIcons)
