import type { ProductFormSchema } from '../hooks/useProductForm'

type ChannelProduct = Pick<
    ProductFormSchema['channel_products'][0],
    'shop' | 'shouldCreateChannelProduct' | 'status'
>

export function getRequiredLanguages(channelProducts: ChannelProduct[], isNewProduct: boolean) {
    const languageSet = channelProducts
        .filter((cp) => !!cp?.shop?.local_language)
        .reduce((acc, cp) => {
            if (!isNewProduct || cp.shouldCreateChannelProduct) {
                acc.add(cp.shop.local_language)
            }

            return acc
        }, new Set<string>())

    return [...languageSet].sort((a, b) => a.localeCompare(b))
}
