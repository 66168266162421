import { Fragment } from 'react'
import { useFormState, type FieldError, type Ref } from 'react-hook-form'
import { Quill } from 'react-quill'
import { type FlatScrollIntoViewLocation, type VirtuosoHandle } from 'react-virtuoso'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ErrorIcon from '@material-ui/icons/Error'
import Alert from '@material-ui/lab/Alert'

import { useProductFormContext } from '../hooks/useProductForm'
import {
    useProductPageActions,
    useProductPageVirtualizationContainers,
} from '../hooks/useProductPageStore'
import { isVirtualizationContainerKey } from '../hooks/useVirtualizationContainer'

import { languageLabels } from '~/constants'

const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.error.main,
    },
    list: {
        maxWidth: 480,
        maxHeight: 500,
        overflowY: 'auto',

        '& .MuiListItem-root': {
            display: 'block',
        },

        '& .MuiAlert-root': {
            backgroundColor: 'transparent',

            '&.MuiAlert-standardError': {
                color: theme.palette.error.light,
            },

            '&.MuiAlert-standardSuccess': {
                color: theme.palette.success.main,
            },
        },
    },
}))

const sections = [
    {
        label: 'Product',
        keys: [
            'translations',
            'images',
            'package_width',
            'package_length',
            'package_height',
            'package_weight',
            'stock_units',
        ],
    },
    {
        label: 'Channels',
        keys: ['channel_products', 'channels', 'channel_attribute_values'] as const,
    },
]

type Props<T extends HTMLElement> = {
    open: boolean
    anchor: T
    onOpen: () => void
    onClose: () => void
}

export default function ErrorSummary<T extends HTMLElement>({
    anchor,
    open,
    onOpen,
    onClose,
}: Props<T>) {
    const classes = useStyles()
    const { goToError, traverseError, getErrorPrefix } = useProductPageErrors()
    const { assignArrowContainerRef } = useProductPageActions()
    const virtualizationContainers = useProductPageVirtualizationContainers()
    const { control } = useProductFormContext()
    const { errors } = useFormState({ control })

    type Error = ReturnType<typeof traverseError>[number]

    function focusError(element: HTMLElement) {
        requestAnimationFrame(() => {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            element.focus({ preventScroll: true })
        })
    }

    async function handleErrorClick(error: Error) {
        if (!error.paths) {
            return
        }

        await goToError(error.paths)

        const name = error.paths?.join('.')
        const nativeElement = document.querySelector(`[name="${name}"]`)
        if (nativeElement instanceof HTMLInputElement) {
            assignArrowContainerRef(nativeElement.parentElement)
            focusError(nativeElement)
            return
        }

        // This should represent section title container of error message for a field
        // Should be manually assigned using `ref()`
        if (error.ref instanceof HTMLElement) {
            assignArrowContainerRef(error.ref)
            focusError(error.ref)
            return
        }

        const focusResult: unknown = error.ref?.focus?.()
        if (focusResult instanceof Quill) {
            assignArrowContainerRef(focusResult.root.parentElement)
            focusError(focusResult.root)
            return
        }

        assignArrowContainerRef(null)
    }

    function canDisplayArrow(error: Error) {
        if (error.ref) {
            return true
        }

        const rootPath = error.paths?.[0]
        if (!rootPath) {
            return false
        }

        if (!isVirtualizationContainerKey(rootPath)) {
            return false
        }

        return virtualizationContainers.has(rootPath)
    }

    return (
        <>
            <Button className={classes.button} startIcon={<ErrorIcon />} onClick={onOpen}>
                {gettext('Errors')}
            </Button>

            <Box clone mb={2} zIndex="modal">
                <Popper open={open} placement="top-end" anchorEl={anchor}>
                    <ClickAwayListener
                        onClickAway={() => {
                            onClose()
                            assignArrowContainerRef(null)
                        }}
                    >
                        <Paper>
                            <List dense disablePadding className={classes.list}>
                                {Object.keys(errors).length === 0 ? (
                                    <ListItem>
                                        <Alert severity="success">{gettext('Ready to save')}</Alert>
                                    </ListItem>
                                ) : (
                                    sections
                                        .filter(({ keys }) => keys.some((key) => key in errors))
                                        .map(({ label, keys }) => (
                                            <ListItem disableGutters key={label}>
                                                <List disablePadding>
                                                    <ListSubheader disableSticky>
                                                        {label}
                                                    </ListSubheader>
                                                    {keys.map((key) => (
                                                        <Fragment key={key}>
                                                            {traverseError(key).map(
                                                                (error, index) => {
                                                                    const errorPrefix =
                                                                        getErrorPrefix(
                                                                            error.paths ?? []
                                                                        )

                                                                    return (
                                                                        <ListItem
                                                                            button
                                                                            disableGutters
                                                                            key={index}
                                                                            onClick={() =>
                                                                                void handleErrorClick(
                                                                                    error
                                                                                )
                                                                            }
                                                                        >
                                                                            <Alert
                                                                                severity="error"
                                                                                action={
                                                                                    canDisplayArrow(
                                                                                        error
                                                                                    ) && (
                                                                                        <IconButton
                                                                                            color="inherit"
                                                                                            size="small"
                                                                                        >
                                                                                            <ArrowForwardIcon />
                                                                                        </IconButton>
                                                                                    )
                                                                                }
                                                                            >
                                                                                {errorPrefix
                                                                                    ? errorPrefix +
                                                                                      ' - '
                                                                                    : ''}
                                                                                {error.message}
                                                                            </Alert>
                                                                        </ListItem>
                                                                    )
                                                                }
                                                            )}
                                                        </Fragment>
                                                    ))}
                                                </List>
                                            </ListItem>
                                        ))
                                )}
                            </List>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </Box>
        </>
    )
}

function useProductPageErrors() {
    const { control, getValues } = useProductFormContext()
    const { errors } = useFormState({ control })
    const { changeTab, openChannelProductDialog } = useProductPageActions()
    const virtualizationContainers = useProductPageVirtualizationContainers()

    function isNestedError(
        paths: string[]
    ): paths is [root: keyof typeof errors, ...rest: string[]] {
        return paths.length > 1 && paths[0] in errors
    }

    function traverseError(
        key: string,
        errs: typeof errors | (typeof errors)[keyof typeof errors] = errors,
        paths?: string[]
    ): Array<{ message?: string; ref?: Ref; paths?: string[] }> {
        function isFieldError(error: unknown): error is FieldError {
            return typeof error === 'object' && error !== null && 'message' in error
        }

        if (!(key in errs)) {
            return []
        }

        const error = errs[key as keyof typeof errs]
        const currentPaths = paths ? [...paths, key] : [key]
        if (!error) {
            return []
        }

        const results = [] as Array<{ message?: string; ref?: Ref; paths?: string[] }>
        // Indicates that the error contains an array/object with keys as numbers
        if (isFieldError(error)) {
            results.push({ message: error.message, ref: error.ref, paths: currentPaths })
        }

        const nestedErrors = Object.entries(error)
            .flatMap(([errKey, errValue]) => {
                if (typeof errValue === 'object' && errValue !== null && errKey !== 'ref') {
                    return traverseError(errKey, error, currentPaths)
                }

                return undefined
            })
            .filter((e) => e !== undefined) as typeof results

        results.push(...nestedErrors)

        return results
    }

    function scrollContainer(
        container: VirtuosoHandle,
        options: Pick<FlatScrollIntoViewLocation, 'index' | 'done'>
    ) {
        container.scrollIntoView({
            ...options,
            align: 'start',
            behavior: 'smooth',
        })
    }

    async function goToError(paths: string[]) {
        return new Promise<void>((resolve) => {
            if (!isNestedError(paths)) {
                return resolve()
            }

            const [root, next] = paths
            const index = parseInt(next)

            switch (root) {
                case 'translations':
                    changeTab(next)
                    return resolve()
                case 'channel_products': {
                    if (paths.includes('error_message')) {
                        return resolve()
                    }

                    if (paths.includes('price')) {
                        const priceContainer = virtualizationContainers.get('pricing')
                        const priceIndex = paths.lastIndexOf('price')
                        // Get the index of the channel stock unit in the paths
                        // This index is used in the virtualization
                        const pathIndex = parseInt(paths[priceIndex - 1])

                        if (isNaN(pathIndex) || !priceContainer) {
                            return resolve()
                        }

                        return scrollContainer(priceContainer, {
                            index: pathIndex,
                            done: () => resolve(),
                        })
                    }

                    return openChannelProductDialog(index)
                }
                case 'stock_units': {
                    if (isNaN(index)) {
                        return resolve()
                    }

                    if (paths.includes('fulfillment_locations')) {
                        const fulfillmentContainer =
                            virtualizationContainers.get('fulfillment_option')
                        if (!fulfillmentContainer) {
                            return resolve()
                        }

                        return scrollContainer(fulfillmentContainer, {
                            index,
                            done: () => resolve(),
                        })
                    }

                    const stockUnitContainer = virtualizationContainers.get('stock_unit')
                    if (!stockUnitContainer) {
                        return resolve()
                    }

                    return scrollContainer(stockUnitContainer, {
                        index,
                        done: () => resolve(),
                    })
                }

                default:
                    return resolve()
            }
        })
    }

    function getErrorPrefix(paths: string[]): string {
        if (!isNestedError(paths)) {
            return ''
        }

        switch (paths[0]) {
            case 'translations':
                return [
                    gettext('Basic info'),
                    languageLabels[paths[1] as keyof typeof languageLabels],
                ].join(' - ')
            case 'stock_units': {
                const stockUnitIndex = parseInt(paths[1])
                return interpolate(gettext('Stock unit %s'), [stockUnitIndex + 1])
            }
            case 'channels': {
                const channelIndex = parseInt(paths[1])
                return getValues(`channels.${channelIndex}.name`)
            }
            case 'channel_products': {
                const channelProductIndex = parseInt(paths[1])
                const shopName = getValues(`channel_products.${channelProductIndex}.shop.shop_name`)
                const nestedPrefix = getErrorPrefix(paths.slice(2))

                return [shopName, nestedPrefix].filter(Boolean).join(' - ')
            }
            default:
                return ''
        }
    }

    return { errors, traverseError, goToError, getErrorPrefix }
}
