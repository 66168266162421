import { connect } from 'react-redux'

import ChannelPicker from '../components/channel-picker'

const mapStateToProps = (state) => ({
    channelsAPIURL: state.initial.endpoints.channelsAPIURL,
    language: state.initial.language,
    userID: state.initial.user.id,
})

export default connect(mapStateToProps)(ChannelPicker)
