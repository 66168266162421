type StockUnitForBulkEdit = {
    stock_item: {
        package_weight?: number | null
        package_height?: number | null
        package_length?: number | null
        package_width?: number | null
    }
}

/**
 * Checks whether it's appropriate to bulk edit package information for a set of stock units.
 *
 * This function considers it appropriate to bulk edit if every specified field
 * (`package_weight`, `package_height`, `package_length`, `package_width`)
 * has at most one unique value across all stock items in the input array.
 *
 * @param stockUnits - An array of stock units to check for bulk editing suitability.
 * @returns - Returns `true` if it's appropriate to bulk edit the package information, otherwise returns `false`.
 *
 * @example
 *
 * const stockUnits = [
 *   { stock_item: { package_weight: 1, package_height: 2, package_length: 3, package_width: 4 } },
 *   { stock_item: { package_weight: 1, package_height: 2, package_length: 3, package_width: 4 } },
 *   { stock_item: { package_weight: 1, package_height: 2, package_length: 3, package_width: 4 } }
 * ];
 *
 * const result = shouldBulkEditPackageInfo(stockUnits); // returns true
 *
 */
export function shouldBulkEditPackageInfo(stockUnits: StockUnitForBulkEdit[]) {
    const stock_items = stockUnits.map((su) => su.stock_item)
    const uniqueValuesByField = {
        package_weight: new Set<number>(),
        package_height: new Set<number>(),
        package_length: new Set<number>(),
        package_width: new Set<number>(),
    } as const

    stock_items.forEach((si) => {
        Object.entries(uniqueValuesByField).forEach(([key, value]) => {
            const fieldValue = si[key as keyof typeof uniqueValuesByField]
            if (typeof fieldValue === 'number') {
                value.add(fieldValue)
            }
        })
    })

    return Object.values(uniqueValuesByField).every((set) => set.size <= 1)
}
