import React from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'

import classNames from 'classnames'
import Cookie from 'js-cookie'
import PropTypes from 'prop-types'

import { copyObject } from '../../tools/utils'

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        position: 'relative',
    },
    prompt: {
        marginTop: 24,
        display: 'flex',
        alignItems: 'center',
        padding: '20px 30px',
    },
    shopInfo: {
        textAlign: 'center',
        borderRight: '1px solid ' + grey[200],
        paddingTop: 25,
        paddingBottom: 25,
        paddingRight: 25,
        marginRight: 25,
        minWidth: 300,
    },
    promptMessage: {
        display: 'flex',
        alignItems: 'center',
    },
    promptInfoIcon: {
        height: 30,
        color: grey[500],
        marginRight: 10,
    },
    channelIcon: {
        width: 100,
    },
    toolbar: {
        position: 'fixed',
    },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
    },
    tableHeader: {
        borderTop: '1px solid ' + grey[200],
    },
    tableHeaderRow: {},
    tableCell: {
        padding: '10px 30px',
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    warningIcon: {
        color: orange[500],
        fontSize: 20,
        marginRight: 5,
    },
    issueCell: {
        display: 'flex',
    },
    issue: {
        color: orange[500],
    },
    infoIcon: {
        height: 20,
        color: grey[500],
    },
    deleteIcon: {
        color: grey[500],
    },
    deleteError: {
        color: theme.palette.error.main,
        marginRight: 10,
    },
    deleteProgress: {
        marginRight: 10,
        color: theme.palette.secondary.main,
    },
    confirmDeleteButton: {
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
    },
})

const columns = [
    { id: 'product', align: 'left', label: gettext('Product'), sort: false, width: '50%' },
    { id: 'sku', align: 'left', label: 'SKU', sort: false, width: '20%' },
    { id: 'issue', align: 'left', label: gettext('Issue'), sort: false, width: '25%' },
    { id: 'delete', align: 'center', label: gettext('Delete'), sort: false, width: '100' },
    { id: 'tip', align: 'left', label: gettext('Tip'), sort: false, width: 100 },
]

class ProductImportIssuesTableHead extends React.PureComponent {
    render() {
        const { classes } = this.props

        return (
            <TableHead className={classes.tableHeader}>
                <TableRow className={classes.tableHeaderRow}>
                    {columns.map((column) => {
                        return (
                            <TableCell
                                className={classes.tableCell}
                                key={column.id}
                                align={column.align}
                                width={column.width}
                            >
                                {column.label}
                            </TableCell>
                        )
                    }, this)}
                </TableRow>
            </TableHead>
        )
    }
}

ProductImportIssuesTableHead = withStyles(styles)(ProductImportIssuesTableHead)

class ProductImportIssues extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            shop: null,
            rowsPerPage: 25,
            productImportIssues: [],
            count: 0,
            page: 0,
            maxPageFetched: 0,
            loading: true,
            deleteConfirmationOpen: false,
            issueToDelete: null,
            deleting: false,
            deleteError: false,
        }

        this.fetchShop = this.fetchShop.bind(this)
        this.fetchProductImportIssues = this.fetchProductImportIssues.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleDeleteConfirmation = this.handleDeleteConfirmation.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    componentDidMount() {
        const { productImportIssuesAPIURL } = this.props
        this.fetchProductImportIssues(productImportIssuesAPIURL, true)

        this.fetchShop()
    }

    fetchShop() {
        const { shop_id } = this.props.match.params
        const { shopAPIURL } = this.props

        return fetch(shopAPIURL.replace('shop_pk', shop_id))
            .then((response) => response.json())
            .then((shop) => {
                this.setState({ shop })
            })
    }

    fetchProductImportIssues(url, replace, page = null) {
        const { shop_id } = this.props.match.params

        return fetch(url.replace('shop_pk', shop_id))
            .then((response) => response.json())
            .then((data) => {
                let productImportIssues

                if (replace) {
                    productImportIssues = data.results
                } else {
                    productImportIssues = copyObject(this.state.productImportIssues)
                    productImportIssues = productImportIssues.concat(data.results)
                }

                const newState = {
                    count: data.count,
                    next: data.next,
                    previous: data.previous,
                    productImportIssues: productImportIssues,
                    loading: false,
                }

                if (page === 0) {
                    newState['page'] = 0
                    newState['maxPageFetched'] = 0
                } else if (page > 0) {
                    newState['page'] = page
                }

                this.setState(newState)
            })
    }

    handleChangePage(event, page) {
        let pageChangeURL

        if (page > this.state.page) {
            pageChangeURL = this.state.next
        } else {
            pageChangeURL = this.state.previous
        }

        const needsFetch = page > this.state.maxPageFetched

        if (needsFetch) {
            this.setState(
                {
                    maxPageFetched: page,
                    loading: true,
                },
                () => {
                    this.fetchProductImportIssues(pageChangeURL, false, page)
                }
            )
        } else {
            this.setState({
                page: page,
            })
        }
    }

    handleDelete(issueID) {
        return (e) => {
            const issueToDelete = this.state.productImportIssues.find(
                (issue) => issue.id == issueID
            )

            this.setState({
                issueToDelete,
                deleteConfirmationOpen: true,
            })
        }
    }

    handleDeleteConfirmation() {
        const { deleteChannelProductByIDAPIURL } = this.props

        const params = {
            product_id: this.state.issueToDelete.product_id,
        }

        this.setState({ deleting: true }, () => {
            fetch(
                deleteChannelProductByIDAPIURL.replace('shop_pk', this.state.issueToDelete.shop.id),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'X-CSRFToken': Cookie.get('csrftoken'),
                    },
                    body: JSON.stringify(params),
                }
            ).then((response) => {
                if (response.ok) {
                    this.setState({
                        deleting: false,
                        deleteConfirmationOpen: false,
                    })
                } else {
                    this.setState({
                        deleting: false,
                        deleteError: true,
                    })
                }
            })
        })
    }

    refresh() {
        this.setState(
            {
                productImportIssues: [],
                count: 0,
                page: 0,
                maxPageFetched: 0,
            },
            () => {
                const { productImportIssuesAPIURL } = this.props
                this.fetchProductImportIssues(productImportIssuesAPIURL, true)
            }
        )
    }

    render() {
        const { classes, staticRoot } = this.props
        const { rowsPerPage, page } = this.state
        const emptyRows =
            rowsPerPage -
            Math.min(rowsPerPage, this.state.productImportIssues.length - page * rowsPerPage)

        const displayedIssues = this.state.productImportIssues.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        )

        return (
            <React.Fragment>
                <Paper className={classes.prompt}>
                    <div className={classes.shopInfo}>
                        {this.state.shop && (
                            <React.Fragment>
                                <img
                                    className={classes.channelIcon}
                                    src={this.state.shop.channel.channel_icon_url}
                                />
                                <Typography>{this.state.shop.shop_name}</Typography>
                            </React.Fragment>
                        )}
                    </div>
                    <div className={classes.promptMessage}>
                        <InfoIcon className={classes.promptInfoIcon} />
                        <Typography>
                            {gettext(
                                'The following products could not be imported into Oakra. Once you address the issue for each product it will automatically be imported.'
                            )}
                        </Typography>
                    </div>
                </Paper>
                <Paper className={classes.root}>
                    <div className={classes.headerContainer}>
                        {this.state.loading && (
                            <LinearProgress className={classes.progress} color="secondary" />
                        )}
                        <TablePagination
                            className={classes.topPagination}
                            component="div"
                            count={this.state.count}
                            rowsPerPage={25}
                            page={this.state.page}
                            backIconButtonProps={{
                                'aria-label': gettext('Previous'),
                            }}
                            nextIconButtonProps={{
                                'aria-label': gettext('Next'),
                            }}
                            onPageChange={this.handleChangePage}
                            rowsPerPageOptions={[25]}
                        />
                    </div>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <ProductImportIssuesTableHead className={classes.tableHead} />
                            <TableBody>
                                {!this.state.loading && this.state.productImportIssues.length == 0 && (
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            className={classNames(
                                                classes.noProductMessage,
                                                classes.tableCell
                                            )}
                                        >
                                            <img
                                                className={classes.noProductsIcon}
                                                src={staticRoot + 'web/img/product-gray.png'}
                                            />
                                            <Typography
                                                className={classes.noProductsText}
                                                variant="subtitle1"
                                            >
                                                {gettext(
                                                    'This shop has not had any product syncing issues.'
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {displayedIssues.map((issue) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={issue.id}>
                                            <TableCell className={classes.tableCell}>
                                                <Link
                                                    href={issue.url}
                                                    variant="body2"
                                                    color="secondary"
                                                    style={{ cursor: 'pointer' }}
                                                    target="_blank"
                                                >
                                                    {issue.name}
                                                </Link>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Typography variant="body2">{issue.sku}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div className={classes.issueCell}>
                                                    <WarningIcon className={classes.warningIcon} />
                                                    <Typography
                                                        className={classes.issue}
                                                        variant="body2"
                                                    >
                                                        {issue.status_description}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <IconButton
                                                    className={classes.deleteButton}
                                                    onClick={this.handleDelete(issue.id)}
                                                >
                                                    <DeleteIcon className={classes.deleteIcon} />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Tooltip
                                                    className={classes.toolTip}
                                                    title={issue.tip}
                                                    placement="left"
                                                >
                                                    <InfoIcon className={classes.infoIcon} />
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        component="div"
                        count={this.state.count}
                        rowsPerPage={rowsPerPage}
                        page={this.state.page}
                        backIconButtonProps={{
                            'aria-label': gettext('Previous'),
                        }}
                        nextIconButtonProps={{
                            'aria-label': gettext('Next'),
                        }}
                        onPageChange={this.handleChangePage}
                        rowsPerPageOptions={[25]}
                    />
                </Paper>
                {this.state.issueToDelete && (
                    <Dialog open={this.state.deleteConfirmationOpen} onClose={this.refresh}>
                        <DialogTitle>{gettext('Delete confirmation')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {interpolate(
                                    gettext(
                                        'This product will be immediately deleted from "%(shopName)s" on %(channelName)s.'
                                    ),
                                    {
                                        shopName: this.state.issueToDelete.shop.shop_name,
                                        channelName: this.state.issueToDelete.shop.channel.name,
                                    },
                                    true
                                )}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {this.state.deleteError && (
                                <Typography className={classes.deleteError}>
                                    {gettext('Error')}
                                </Typography>
                            )}
                            {this.state.deleting && (
                                <CircularProgress className={classes.deleteProgress} size={25} />
                            )}
                            <Button
                                onClick={() => {
                                    this.setState({
                                        deleteConfirmationOpen: false,
                                        issueToDelete: null,
                                    })
                                }}
                                color="inherit"
                                disabled={this.state.deleting}
                            >
                                {gettext('Cancel')}
                            </Button>
                            <Button
                                classes={{ outlined: classes.confirmDeleteButton }}
                                variant="outlined"
                                onClick={this.handleDeleteConfirmation}
                                disabled={this.state.deleting}
                            >
                                {gettext('Delete')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </React.Fragment>
        )
    }
}

ProductImportIssues.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ProductImportIssues)
