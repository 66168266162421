import React from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'

import Cookie from 'js-cookie'
import PropTypes from 'prop-types'

import { getPath } from '../../oakra/components/base-app'
import { post } from '../../tools/request'
import { copyObject } from '../../tools/utils'

import { getChannelShippingInfoManager } from './channel-shipping-info-managers'

const styles = (theme) => ({
    dialog: {},
    initialLoadingProgress: {
        alignSelf: 'center',
    },
    dialogContent: {
        minHeight: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    dialogActions: {
        paddingRight: 24,
        paddingLeft: 24,
        paddingBottom: 15,
        marginTop: 0,
    },
    ordersToInclude: {
        marginTop: 30,
    },
    maxOrdersMessage: {
        marginTop: 10,
    },
    shippingInitFieldsProgress: {
        alignSelf: 'center',
        margin: '0 auto',
    },
    shippingInfoTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15,
    },
    shippingInfoTitleText: {
        color: grey[500],
        marginLeft: 5,
    },
    shopFields: {
        marginBottom: 20,
    },
    channelIcon: {
        width: 75,
        alignSelf: 'center',
    },
    pickPackBatch: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submitProgress: {
        marginRight: 10,
    },
    initShippingInfoError: {
        color: theme.palette.error.main,
        fontSize: '0.8rem',
        lineHeight: 1.2,
    },
})

const manualShippingShop = {
    id: 0,
    channel: { name: 'Other' },
    shop_name: gettext('Manual / Webstore orders'),
}

class ShippingInfoManager extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            initShippingInfo: {},
            initShippingInfoFields: null,
            initShippingInfoError: '',
            initShippingInfoResult: {},
            fieldInfo: {},
            loading: false,
        }

        this.reset = this.reset.bind(this)
        this.fetchInitShippingInfoFields = this.fetchInitShippingInfoFields.bind(this)
        this.handleInitShippingInfoChange = this.handleInitShippingInfoChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        if (this.props.salesFilter) {
            this.setState({ loading: true }, this.fetchInitShippingInfoFields)
        }
    }

    componentDidUpdate() {
        if (!this.state.initShippingInfoFields && !this.state.loading && this.props.salesFilter) {
            this.setState({ loading: true }, this.fetchInitShippingInfoFields)
        }
    }

    reset() {
        this.setState({
            initShippingInfo: {},
            initShippingInfoFields: null,
            fieldInfo: {},
            loading: false,
        })
    }

    fetchInitShippingInfoFields() {
        const { initShippingInfoFieldsAPIURL, salesFilter } = this.props

        fetch(initShippingInfoFieldsAPIURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRFToken': Cookie.get('csrftoken'),
            },
            body: JSON.stringify({ sales_filter: salesFilter }),
        })
            .then((response) => response.json())
            .then((data) => {
                const fieldInfo = copyObject(this.state.fieldInfo)
                const initShippingInfo = copyObject(this.state.initShippingInfo)

                data.forEach((shopFields) => {
                    if (shopFields.field_info) {
                        const shopPK = shopFields.shop?.id || 0
                        initShippingInfo[shopPK] = {}

                        fieldInfo[shopPK] = shopFields.field_info
                    }
                })

                this.setState({
                    initShippingInfoFields: data,
                    initShippingInfo,
                    fieldInfo,
                    loading: false,
                })
            })
    }

    handleInitShippingInfoChange(shopID) {
        return (initShippingInfoForShop) => {
            this.setState((state) => {
                const initShippingInfo = copyObject(state.initShippingInfo)
                initShippingInfo[shopID] = initShippingInfoForShop
                return { initShippingInfo }
            })
        }
    }

    getPayload(initShippingInfo) {
        // Turn value objs on initShippingInfo into actual values
        const { salesFilter } = this.props

        const payload = { sales_filter: salesFilter }
        const initShippingInfoToSubmit = copyObject(initShippingInfo)

        Object.entries(initShippingInfoToSubmit).forEach(([shopPK, initShippingInfoForShop]) => {
            Object.entries(initShippingInfoForShop).forEach(([field, valueObj]) => {
                if (field === 'pickup_time_id_by_shipping_provider') {
                    const valueField = this.state.fieldInfo[shopPK][field]['value_field']
                    initShippingInfoToSubmit[shopPK][field] = Object.keys(valueObj).reduce(
                        (result, shippingProviderId) => {
                            result[shippingProviderId] = valueObj[shippingProviderId][valueField]
                            return result
                        },
                        {}
                    )
                } else if (typeof valueObj === 'object') {
                    const valueField = this.state.fieldInfo[shopPK][field]['value_field']
                    initShippingInfoToSubmit[shopPK][field] = valueObj[valueField]
                } else {
                    initShippingInfoToSubmit[shopPK][field] = valueObj
                }
            })
        })

        payload['init_shipping_info'] = initShippingInfoToSubmit

        return payload
    }

    handleSubmit() {
        const { initShippingInfoAPIURL } = this.props

        window.analytics.track('Confirm order shipping', {
            page: window.location.href,
            feature: 'order',
            mode: 'pc',
            trigger: 'button click',
        })

        const postData = this.getPayload(this.state.initShippingInfo)

        this.setState({ loading: true }, () => {
            post(initShippingInfoAPIURL, postData).then(({ ok, ...response }) => {
                if (ok) {
                    if (
                        response.pick_pack_batch_id &&
                        (!response.errors || response.errors.length === 0)
                    ) {
                        window.location.href = getPath('pickPackBatch').replace(
                            'pick_pack_batch_id',
                            response.pick_pack_batch_id
                        )
                    } else {
                        this.setState({
                            loading: false,
                            initShippingInfoResult: response,
                        })
                    }
                } else {
                    this.setState({
                        loading: false,
                        initShippingInfoError: gettext(
                            'There was a problem submiting shipping info for orders. If this issue persists, please contact us'
                        ),
                    })
                }
            })
        })
    }

    handleGoToPickPack = () => {
        window.location = getPath('pickPackBatch').replace(
            'pick_pack_batch_id',
            this.state.initShippingInfoResult.pick_pack_batch_id
        )
    }

    render() {
        const { classes, handleCloseShippingInfoManager } = this.props
        const { initShippingInfoResult } = this.state
        let screen = 'loading'
        let initShippingInfoFields = []
        if (this.state.initShippingInfoFields) {
            screen = 'init'
            this.state.initShippingInfoFields.forEach((item) => {
                if (item.field_info) {
                    initShippingInfoFields.push(item)
                }
            })
        }
        if (initShippingInfoResult.errors?.length) {
            screen = 'error'
        }

        return (
            <Dialog
                className={classes.dialog}
                open={this.props.open}
                onClose={() => {
                    this.reset()
                    handleCloseShippingInfoManager()
                }}
                maxWidth="xs"
                fullWidth
            >
                <React.Fragment>
                    <DialogTitle>{gettext('Confirm shipping')}</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        {screen === 'error' && (
                            <div style={{ marginBottom: 24 }}>
                                <Alert
                                    style={{ border: 'none', color: 'inherit' }}
                                    severity="warning"
                                    variant="outlined"
                                >
                                    {gettext('There were issues confirming shipping for orders')}
                                </Alert>
                                <div style={{ paddingLeft: 24 }}>
                                    <ul>
                                        {initShippingInfoResult.errors.map((item) => (
                                            <li key={item.sale_id}>
                                                {item.sale_id}: {item.message}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        {screen === 'init' && (
                            <div>
                                {initShippingInfoFields.map((shopFields) => {
                                    const shop = shopFields.shop || manualShippingShop
                                    const InitShippingInfo = getChannelShippingInfoManager(
                                        shop.channel.name
                                    )

                                    return (
                                        <React.Fragment key={shop.id}>
                                            <div className={classes.shopFields}>
                                                <div className={classes.shippingInfoTitle}>
                                                    {shop.channel?.channel_icon_url && (
                                                        <img
                                                            className={classes.channelIcon}
                                                            src={shop.channel.channel_icon_url}
                                                        />
                                                    )}
                                                    <Typography
                                                        className={classes.shippingInfoTitleText}
                                                    >
                                                        {shop.shop_name}
                                                    </Typography>
                                                </div>
                                                <InitShippingInfo
                                                    initShippingInfoFields={
                                                        shopFields.init_shipping_info
                                                    }
                                                    initShippingInfo={
                                                        this.state.initShippingInfo[shop.id]
                                                    }
                                                    fieldInfo={this.state.fieldInfo[shop.id]}
                                                    handleInfoChange={this.handleInitShippingInfoChange(
                                                        shop.id
                                                    )}
                                                    key={shop.id}
                                                />
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        )}

                        {screen === 'loading' && (
                            <CircularProgress
                                className={classes.shippingInitFieldsProgress}
                                color="secondary"
                                size={30}
                            />
                        )}
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        {screen === 'init' && (
                            <>
                                {this.state.initShippingInfoError && (
                                    <div>
                                        <Typography className={classes.initShippingInfoError}>
                                            {this.state.initShippingInfoError}
                                        </Typography>
                                    </div>
                                )}

                                <div>
                                    {this.state.loading && (
                                        <CircularProgress
                                            className={classes.submitProgress}
                                            color="secondary"
                                            size={20}
                                        />
                                    )}
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disabled={
                                            !this.state.initShippingInfoFields || this.state.loading
                                        }
                                        className={classes.shippingInfoConfirmButton}
                                        onClick={this.handleSubmit}
                                    >
                                        {gettext('Confirm')}
                                    </Button>
                                </div>
                            </>
                        )}
                        {screen === 'error' && (
                            <div>
                                <Button color="secondary" onClick={handleCloseShippingInfoManager}>
                                    {gettext('Close')}
                                </Button>

                                {initShippingInfoResult.pick_pack_batch_id && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ marginLeft: 16 }}
                                        onClick={this.handleGoToPickPack}
                                    >
                                        {gettext('Go to pick pack')}
                                    </Button>
                                )}
                            </div>
                        )}
                    </DialogActions>
                </React.Fragment>
            </Dialog>
        )
    }
}

ShippingInfoManager.propTypes = {
    open: PropTypes.bool.isRequired,
    handleCloseShippingInfoManager: PropTypes.func.isRequired,
    salesFilter: PropTypes.object.isRequired,
}

export default withStyles(styles)(ShippingInfoManager)
