
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'


const SnackbarAlert = (props) => {
    const { variant, severity, children, ...snackbarProps } = props

    return (
        <Snackbar {...snackbarProps}>
            <Alert variant={variant} severity={severity}>
                {children}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarAlert
