import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

type FilterValue = {
    value: string | number
    label: string
}

type Filter = {
    field: string
    lookup: string
    select_multiple: boolean
    can_select_exclusive?: boolean
    values: FilterValue[]
}

/**
 * Props for the SearchFilter component.
 */
type Props = {
    /**
     * An array of filter values.
     */
    filterValues: Filter[]
    /**
     * The placeholder text for the search input.
     */
    searchPlaceholder?: string
    /**
     * The debounce time for the search input in milliseconds.
     */
    debounceTime?: number
}

export default function SearchFilter({ filterValues, searchPlaceholder, debounceTime }: Props) {
    const { filter, updateFilter, removeFilter, toggleExclusivity } = useFilters(debounceTime)

    return (
        <FilterContainer sx={{ gap: 2 }}>
            <FilterInput
                value={filter.get('search')}
                type="search"
                label={gettext('Search')}
                size="small"
                variant="outlined"
                placeholder={searchPlaceholder}
                onChange={(e) => {
                    const value = e.target.value
                    if (value) {
                        updateFilter('search', [value])
                    } else {
                        removeFilter('search')
                    }
                }}
            />

            {filterValues.map((filterValue) => (
                <FilterValue
                    key={filterValue.lookup}
                    value={filter.get(filterValue.lookup)}
                    exclusive={
                        filter.get(`${filterValue.lookup}__exclusive`)?.some((v) => v === 'True') ??
                        false
                    }
                    filter={filterValue}
                    onChange={updateFilter}
                    onRemove={removeFilter}
                    onToggleExclusivity={toggleExclusivity}
                />
            ))}
        </FilterContainer>
    )
}

export function FilterValue({
    value,
    filter,
    exclusive,
    onChange,
    onRemove,
    onToggleExclusivity,
}: {
    value: string[] | undefined
    filter: Filter
    exclusive: boolean
    onChange: (lookup: string, value: string[]) => void
    onRemove: (lookup: string) => void
    onToggleExclusivity: (lookup: string) => void
}) {
    const displayValue = useMemo(() => {
        const existingValues = new Set(value)
        const matchingValues = filter.values.filter((v) => existingValues.has(v.value.toString()))
        if (!matchingValues.length) {
            return filter.select_multiple ? [] : null
        }

        if (matchingValues.length === 1 && filter.select_multiple === false) {
            return matchingValues[0]
        }

        return matchingValues
    }, [filter, value])

    return (
        <FilterContainer>
            <Autocomplete
                fullWidth
                value={displayValue}
                filterSelectedOptions
                disableCloseOnSelect={filter.select_multiple}
                options={filter.values}
                multiple={filter.select_multiple}
                getOptionLabel={(option) => option.label}
                ChipProps={{ size: 'small' }}
                renderInput={(inputProps) => (
                    <FilterInput {...inputProps} label={filter.field} size="small" />
                )}
                onChange={(_, value) => {
                    if (!value || (Array.isArray(value) && value.length === 0)) {
                        onRemove(filter.lookup)
                        return
                    }

                    onChange(
                        filter.lookup,
                        Array.isArray(value)
                            ? value.map((v) => v.value.toString())
                            : [value.value.toString()]
                    )
                }}
            />
            {filter.can_select_exclusive && (
                <FormControlLabel
                    checked={exclusive}
                    label={
                        <Typography variant="body2" color="textSecondary">
                            {gettext('Exclusive')}
                        </Typography>
                    }
                    onChange={(_) => {
                        onToggleExclusivity(filter.lookup)
                    }}
                    control={<Checkbox size="small" />}
                />
            )}
        </FilterContainer>
    )
}

const FilterContainer = styled('div')({
    display: 'inline-flex',
    flexWrap: 'wrap',
})

const FilterInput = styled(TextField)(({ theme }) => ({
    minWidth: theme.spacing(20),
}))

/**
 * Custom hook for managing filters.
 * @param debounceTime The debounce time in milliseconds in order to update the URL. Default is 200ms.
 */
function useFilters(debounceTime = 200) {
    const history = useHistory()
    const [filter, setFilter] = useState(() => {
        const params = new URLSearchParams(location.search)
        const searchParams = new Map<string, string[]>()

        for (const [key, value] of params) {
            if (!searchParams.has(key)) {
                searchParams.set(key, [])
            }

            searchParams.get(key)?.push(value)
        }

        return searchParams
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            const params = new URLSearchParams()

            for (const [key, values] of filter) {
                for (const value of values) {
                    params.append(key, value)
                }
            }

            history.replace({
                search: params.toString(),
            })
        }, debounceTime)

        return () => {
            clearTimeout(timer)
        }
    }, [debounceTime, filter, history])

    return {
        /**
         * Represents the filter used for most filter values search
         */
        filter,
        /**
         * Removes a filter given a lookup field.
         * @param lookup - The lookup value of the filter to be removed.
         */
        removeFilter: useCallback((lookup: string) => {
            return setFilter((filter) => {
                const newFilter = new Map(filter)
                newFilter.delete(lookup)

                return newFilter
            })
        }, []),
        /**
         * Updates the filter with the specified lookup and value.
         * @param lookup - The lookup to update.
         * @param value - The new value for the lookup.
         */
        updateFilter: useCallback((lookup: string, value: string[]) => {
            return setFilter((filter) => {
                return new Map(filter.set(lookup, value))
            })
        }, []),
        /**
         * Toggles the exclusivity of a lookup.
         *
         * @param lookup - The lookup field to toggle exclusivity for.
         */
        toggleExclusivity: useCallback((lookup: string) => {
            return setFilter((filter) => {
                const exclusiveKey = `${lookup}__exclusive`
                const exclusive = filter.get(exclusiveKey)?.some((v) => v === 'True') ?? false
                if (exclusive) {
                    filter.delete(exclusiveKey)
                    return new Map(filter)
                }

                return new Map(filter.set(exclusiveKey, ['True']))
            })
        }, []),
    }
}
