import { type ShopeeLogisticsProvider } from '~/common/schemas/logistics-shopee'
import { DEFAULT_MAX_PACKAGE_DIMENSION } from '~/consts'

export function getProviderLimits(providers: ShopeeLogisticsProvider[]) {
    return providers.reduce(
        (curr, provider) => ({
            height: getMaxDimensionValueAndName(provider, 'height', curr.height),
            length: getMaxDimensionValueAndName(provider, 'length', curr.length),
            width: getMaxDimensionValueAndName(provider, 'width', curr.width),
            weight: getAllowedWeightLimits(provider, curr.weight),
        }),
        {
            height: { maxLogisticName: '', maxValue: DEFAULT_MAX_PACKAGE_DIMENSION },
            length: { maxLogisticName: '', maxValue: DEFAULT_MAX_PACKAGE_DIMENSION },
            width: { maxLogisticName: '', maxValue: DEFAULT_MAX_PACKAGE_DIMENSION },
            weight: {
                minLogisticName: '',
                minValue: 0,
                maxLogisticName: '',
                maxValue: DEFAULT_MAX_PACKAGE_DIMENSION,
            },
        }
    )
}

function getMaxDimensionValueAndName(
    provider: ShopeeLogisticsProvider,
    dimension: 'height' | 'length' | 'width',
    current: {
        maxLogisticName: string
        maxValue: number
    }
) {
    const value = provider.item_max_dimension?.[dimension]
    const unit = provider.item_max_dimension?.unit
    if (!unit || !value) {
        return current
    }

    const maxValue = toCM(value, unit)
    if (maxValue < current.maxValue) {
        return { maxLogisticName: provider.logistic_name, maxValue }
    }

    return current
}

function getAllowedWeightLimits(
    provider: ShopeeLogisticsProvider,
    current: {
        minLogisticName: string
        minValue: number
        maxLogisticName: string
        maxValue: number
    }
) {
    const result = { ...current }
    const maxWeight = provider.weight_limits?.item_max_weight ?? current.maxValue
    const minWeight = provider.weight_limits?.item_min_weight ?? current.minValue
    if (maxWeight && maxWeight < current.maxValue) {
        result.maxLogisticName = provider.logistic_name
        result.maxValue = maxWeight
    }

    if (minWeight && minWeight > current.minValue) {
        result.minLogisticName = provider.logistic_name
        result.minValue = minWeight
    }

    return result
}

function toCM(value: number, unit: string) {
    switch (unit) {
        case 'mm':
            return value / 10
        case 'cm':
            return value
        case 'dm':
            return value * 10
        case 'm':
            return value * 100
        default:
            throw new Error('Invalid unit')
    }
}
