// Sale Customer
import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PhoneIcon from '@material-ui/icons/Phone'

import classNames from 'classnames'

import SearchInput from '../../../customers/components/search-input'
import { commonStyles, SALE_FORM_MODES } from '../sale'

import CustomerSummaryDialog from './customer-summary-dialog'

const useCustomerStyles = makeStyles((theme) => ({
    ...commonStyles,
    customerContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    taxInvoiceRequested: {
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: 4,
        padding: '4px 9px',
        fontSize: 12,
        lineHeight: 20,
        fontWeight: 600,
        color: theme.palette.secondary.main,
    },
}))

const CustomerSection = ({ mode, sale, onChange }) => {
    const classes = useCustomerStyles()
    const [showCustomerDialog, setShowCustomerDialog] = useState(false)
    const handleCustomerChange = (data) => {
        if (!data) return

        const {
            default_shipping_address,
            default_billing_address,
            default_invoice_address,
            ...customer
        } = data

        onChange({
            customer,
            shipping_address: default_shipping_address,
            billing_address: default_billing_address,
            invoice_address: default_invoice_address,
        })
    }

    const isAdd = mode === SALE_FORM_MODES.add
    const hasCustomer = !!sale.customer
    let editableCustomerFields
    if (
        isAdd ||
        (sale.can_edit_status && ['waiting_for_stock', 'unpaid', 'pending'].includes(sale.status))
    ) {
        editableCustomerFields = [
            'customer',
            'shipping_address',
            'billing_address',
            'invoice_address',
        ]
    } else {
        editableCustomerFields = ['billing_address', 'invoice_address']
    }

    return (
        <Paper
            className={classNames(classes.basicInfo, classes.section, classes.customerContainer)}
        >
            <div className={classes.actionBar}>
                <Typography variant="subtitle1" className={classes.sectionTitle}>
                    {gettext('Customer')}
                </Typography>

                {hasCustomer && (
                    <Button
                        size="small"
                        color="secondary"
                        onClick={() => setShowCustomerDialog(true)}
                    >
                        {gettext('Edit')}
                    </Button>
                )}
            </div>

            {hasCustomer ? (
                <div className={classes.bodyText}>
                    <CustomerInfo sale={sale} />
                </div>
            ) : (
                <React.Fragment>
                    <SearchInput
                        onSelect={handleCustomerChange}
                        onCustomerCreate={handleCustomerChange}
                    />
                </React.Fragment>
            )}

            {!isAdd && sale.is_tax_invoice_requested && (
                <div style={{ display: 'flex', alignItems: 'center', height: 50 }}>
                    <div>
                        <span className={classes.taxInvoiceRequested}>
                            {gettext('Tax Invoice')}
                        </span>
                    </div>
                </div>
            )}

            {showCustomerDialog && (
                <CustomerSummaryDialog
                    editableFields={editableCustomerFields}
                    data={sale}
                    onChange={onChange}
                    onClose={() => setShowCustomerDialog(false)}
                />
            )}
        </Paper>
    )
}

export default CustomerSection

const customerInfoStyles = (theme) => ({
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: 5,
    },
    fieldValue: {
        fontSize: '0.875rem',
    },
    tab: {
        minWidth: 72,
        minHeight: 36,
        fontSize: '0.8rem',
    },
    tabs: {
        minHeight: 36,
    },
})

let CustomerInfo = ({ sale, classes }) => {
    const { customer, shipping_address } = sale
    let fieldValues, phone

    if (customer && shipping_address) {
        fieldValues = [
            shipping_address.contact_name || customer.name,
            shipping_address.address1,
            shipping_address.address2,
            `${shipping_address.district || ''} ${shipping_address.city || ''} ${
                shipping_address.province || ''
            }`,
            `${shipping_address.country || ''} ${shipping_address.post_code || ''}`,
            customer.email,
        ]
        phone = shipping_address.phone || customer.phone
    } else {
        // old fields
        fieldValues = [
            sale.customer_name,
            sale.address1,
            sale.address2,
            sale.address3,
            sale.address4,
            sale.address5,
            sale.city,
            `${sale.province || ''} ${sale.post_code || ''}`,
            sale.country,
            sale.email,
        ]
        phone = sale.phone_number
    }

    fieldValues = fieldValues.map((text) => (text || '').trim()).filter((i) => i)

    return (
        <div className={classes.bodyText}>
            {fieldValues.map((fieldValue, i) => (
                <div key={i} className={classes.row}>
                    <Typography className={classes.fieldValue}>{fieldValue}</Typography>
                </div>
            ))}
            {phone && (
                <div className={classes.row}>
                    <PhoneIcon fontSize="small" className={classes.icon} />
                    <Typography className={classes.fieldValue}>{phone.trim()}</Typography>
                </div>
            )}
        </div>
    )
}

CustomerInfo = withStyles(customerInfoStyles)(CustomerInfo)
