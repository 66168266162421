import type { ChannelValidator } from '../types'

export const FIELD_INFO = {
    name: {
        minLength: 20,
        maxLength: 120,
    },
    description: {
        minLength: 25,
        maxLength: 5000,
    },
    optionName: {
        maxLength: 20,
    },
    maxImages: 10, // 9 at product level + 1 at variation level for 2 tier items
    maxImagesLevel: 'product_and_stock_unit',
} as const

export const FIELD_INFO_MALL = {
    name: {
        minLength: 20,
        maxLength: 255,
    },
    description: {
        minLength: 25,
        maxLength: 6000,
    },
    maxImages: 10, // 9 at product level + 1 at variation level for 2 tier items
    maxImagesLevel: 'product_and_stock_unit',
} as const

export function createChannelValidator(): ChannelValidator {
    const MAX_PRICE_RATIO = 5

    return {
        validateChannelStockUnitPrice: (value, { channel_stock_units: channelStockUnits }) => {
            const price = parseFloat(value)
            if (!price) {
                return gettext('Price required')
            }

            const prices = channelStockUnits.reduce<number[]>((acc, curr) => {
                const price = parseFloat(curr.price)
                if (price > 0) {
                    acc.push(price)
                }

                return acc
            }, [])
            const min = Math.min(...prices)
            const max = Math.max(...prices)
            const priceRatioExceeded = Math.max(price / min, max / price) > MAX_PRICE_RATIO

            return (
                priceRatioExceeded === false ||
                gettext(
                    `Price ratio exceeded (ratio: ${MAX_PRICE_RATIO}, min: ${min}, max: ${max})`
                )
            )
        },
    }
}
