import { memo, useCallback, useMemo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'

import type { ProductFormSchema } from '../hooks/useProductForm'

const ROW_HEIGHT = 15
const CELL_MARGIN = 2

const useStyles = makeStyles((theme) => ({
    stockUnitsCoverage: {
        width: '100%',
        tableLayout: 'fixed',
    },
    headerRow: {},
    columnTitleCell: {
        marginRight: 3,
        padding: 5,
    },
    shopNameContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    channelIcon: {
        width: 15,
        height: 15,
        marginRight: 10,
    },
    columnTitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '0.9rem',
    },
    row: {
        marginBottom: CELL_MARGIN,
        lineHeight: ROW_HEIGHT,
    },
    rowTitle: {
        fontSize: '0.9rem',
        border: '3px solid transparent',
        padding: 3,
    },
    coverageCell: {
        border: '3px solid white',
        padding: 3,
    },
    coverageCellActive: {
        backgroundColor: theme.palette.primary.main,
    },
    coverageCellInactive: {
        backgroundColor: theme.palette.grey[300],
    },
}))

type Props = {
    channelProducts: ProductFormSchema['channel_products']
    stockUnits: ProductFormSchema['stock_units']
}

function TableStockUnitsCoverage({ channelProducts, stockUnits }: Props) {
    const classes = useStyles()
    const stockUnitsMoreThanChannelProducts = useMemo(
        () => stockUnits.length > channelProducts.length,
        [channelProducts.length, stockUnits.length]
    )

    const renderTableHeadCell = useCallback(() => {
        return stockUnitsMoreThanChannelProducts
            ? channelProducts.map((cp, index) => (
                  <TableCell key={cp.id || index} className={classes.columnTitleCell}>
                      <div className={classes.shopNameContainer}>
                          <img
                              className={classes.channelIcon}
                              src={cp.shop.channel.channel_square_icon_url}
                          />
                          <Typography className={classes.columnTitle}>
                              {cp.shop.shop_name}
                          </Typography>
                      </div>
                  </TableCell>
              ))
            : stockUnits.map((su, index) => (
                  <TableCell key={su.id || index} className={classes.columnTitleCell}>
                      {su.sku}
                  </TableCell>
              ))
    }, [
        channelProducts,
        classes.channelIcon,
        classes.columnTitle,
        classes.columnTitleCell,
        classes.shopNameContainer,
        stockUnits,
        stockUnitsMoreThanChannelProducts,
    ])

    const renderTableBodyCell = useCallback(() => {
        return stockUnitsMoreThanChannelProducts
            ? stockUnits.map((su, suIndex) => (
                  <TableRow className={classes.row} key={su.id || suIndex}>
                      <TableCell className={classes.rowTitle}>{su.sku}</TableCell>
                      {channelProducts.map((cp, cpIndex) => {
                          const csu = cp.channel_stock_units[suIndex]
                          return (
                              <TableCell
                                  key={cp.id || cpIndex}
                                  className={classNames(
                                      classes.coverageCell,
                                      csu.placeholder
                                          ? classes.coverageCellInactive
                                          : classes.coverageCellActive
                                  )}
                              />
                          )
                      })}
                  </TableRow>
              ))
            : channelProducts.map((cp, cpIndex) => (
                  <TableRow className={classes.row} key={cp.id || cpIndex}>
                      <TableCell className={classes.rowTitle}>
                          <div className={classes.shopNameContainer}>
                              <img
                                  className={classes.channelIcon}
                                  src={cp.shop.channel.channel_square_icon_url}
                              />
                              <Typography className={classes.columnTitle}>
                                  {cp.shop.shop_name}
                              </Typography>
                          </div>
                      </TableCell>
                      {stockUnits.map((su, suIndex) => {
                          const csu = cp.channel_stock_units[suIndex]
                          return (
                              <TableCell
                                  key={su.id || suIndex}
                                  className={classNames(
                                      classes.coverageCell,
                                      csu.placeholder
                                          ? classes.coverageCellInactive
                                          : classes.coverageCellActive
                                  )}
                              />
                          )
                      })}
                  </TableRow>
              ))
    }, [
        channelProducts,
        classes.channelIcon,
        classes.columnTitle,
        classes.coverageCell,
        classes.coverageCellActive,
        classes.coverageCellInactive,
        classes.row,
        classes.rowTitle,
        classes.shopNameContainer,
        stockUnits,
        stockUnitsMoreThanChannelProducts,
    ])

    return (
        <Table>
            <TableHead>
                <TableRow className={classes.headerRow}>
                    <TableCell className={classes.columnTitleCell}></TableCell>
                    {renderTableHeadCell()}
                </TableRow>
            </TableHead>

            <TableBody>{renderTableBodyCell()}</TableBody>
        </Table>
    )
}

export default memo(TableStockUnitsCoverage)
