import { connect } from 'react-redux'

import MergeRecommendations from '../components/merge-recommendations'

const mapStateToProps = (state) => ({
    mergeRecommendationsAPIURL: state.initial.endpoints.mergeRecommendationsAPIURL,
    storeAPIURL: state.initial.endpoints.storeAPIURL,
    language: state.initial.language,
    userID: state.initial.user.id,
    staticRoot: state.initial.staticRoot,
})

const matchDispatchToProps = (dispatch) => ({
    updateStore: (store) => {
        dispatch(updateStore(store))
    },
})

export default connect(mapStateToProps, matchDispatchToProps)(MergeRecommendations)
