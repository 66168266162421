import { connect } from 'react-redux'

import { addStore, deleteStore } from '../../oakra/actions/stores'
import CreateNewStore from '../components/create-new-store'

const mapStateToProps = (state) => ({
    storeAPIURL: state.initial.endpoints.storeAPIURL,
    storeCreateAPIURL: state.initial.endpoints.storeCreateAPIURL,
    accountOwnerID: state.initial.accountOwner.id,
    stores: state.stores,
})

const matchDispatchToProps = (dispatch) => ({
    addStore: (store) => {
        dispatch(addStore(store))
    },
    deleteStore: (storeID) => {
        dispatch(deleteStore(storeID))
    },
})

export default connect(mapStateToProps, matchDispatchToProps)(CreateNewStore)
