import { useState, useEffect, useRef, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Link,
    useLocation,
    useHistory,
} from 'react-router-dom'

import MuiAppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles, styled } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'

import { setUser } from '@sentry/react'
import Cookie from 'js-cookie'
import { SnackbarProvider, MaterialDesignContent } from 'notistack'

import { changeLoginState } from '../actions/login'
import CreateNewStore from '../containers/create-new-store'
import Login from '../containers/login'
import SignUp from '../containers/signup'

import LanguageButton from './language-button.js'

import { useLanguage } from '~/common/hooks'
import { useAppSelector } from '~/store'

const NAVBAR_WIDTH = 200

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    appBarRight: {
        display: 'flex',
    },
    logo: {
        width: 100,
    },
    logoContainer: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    mainPanel: {
        position: 'relative',
        display: 'flex',
    },
    navBar: {
        backgroundColor: 'white',
        paddingTop: 30,
    },
    navLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    navItem: {
        paddingLeft: 26,
    },
    navBarItemLabel: {
        paddingLeft: 5,
    },
    itemText: {
        fontWeight: 400,
    },
    navSubItem: {
        paddingLeft: 26,
    },
    navBarSubItemLabel: {
        paddingLeft: 5,
    },
    subItemText: {
        fontWeight: 400,
        marginLeft: 40,
    },
    rightPanel: {
        flexGrow: 1,
        height: '100vh',
        padding: '0 20px 20px 20px',
        backgroundColor: theme.palette.grey[100],
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
    },
    snackbarRoot: {
        '&:has(#product-detail)': {
            bottom: theme.spacing(8),
        },
    },
    drawer: {
        width: NAVBAR_WIDTH,
        flexShrink: 0,

        '& .MuiDrawer-paper': {
            width: NAVBAR_WIDTH,
        },
    },
}))

/* eslint-disable react/no-children-prop */
function SidebarItem({ root, to, icon: Icon, label }) {
    const classes = useStyles()

    return (
        <Route
            path={root ? root : to}
            exact={!root}
            children={({ match }) => (
                <Link to={to} className={classes.navLink}>
                    <ListItem button className={classes.navItem}>
                        <ListItemIcon>
                            <Icon color={match ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText
                            className={classes.navBarItemLabel}
                            primary={label}
                            primaryTypographyProps={{
                                variant: 'subtitle1',
                                color: match ? 'primary' : 'textPrimary',
                                className: classes.itemText,
                            }}
                        />
                    </ListItem>
                </Link>
            )}
        />
    )
}

function SidebarSubItem({ to, label }) {
    const classes = useStyles()

    return (
        <Route
            path={to}
            exact={true}
            children={({ match }) => (
                <Link to={to} className={classes.navLink}>
                    <ListItem button dense className={classes.navSubItem}>
                        <ListItemText
                            className={classes.navBarSubItemLabel}
                            primary={label}
                            primaryTypographyProps={{
                                variant: 'subtitle2',
                                color: match ? 'primary' : 'textPrimary',
                                className: classes.subItemText,
                            }}
                        />
                    </ListItem>
                </Link>
            )}
        />
    )
}

const SidebarSubsection = ({ subItems }) =>
    subItems.map((subItem) => (
        <SidebarSubItem key={subItem.to} to={subItem.to} label={subItem.label} />
    ))

export const PATHS = Object.freeze({
    dashboardOverview: '/home/dashboard/overview',
    dashboardReports: '/home/dashboard/reports',
    sales: '/home/sales/process',
    initShipping: '/home/sales/init-shipping',
    sale: '/home/sale/:sale_id',
    pickPackBatches: '/home/sales/pick-pack-batches',
    pickPackBatch: '/home/sales/pick-pack-batches/:pick_pack_batch_id',
    products: '/home/products/catalog',
    product: '/home/stores/:store_id/products/:product_id',
    mergeRecommendations: '/home/stores/:store_id/merge-recommendations',
    createProduct: '/home/products/create/stores/:store_id',
    priceManager: '/home/products/prices',

    discounts: '/home/discounts',
    discount: '/home/discount/:discount_id',
    createDiscount: '/home/discount/create/stores/:store_id',

    stockManager: '/home/stock/manager',
    stockReport: '/home/stock/report',
    stockAdjustmentGroups: '/home/stock/adjustment-groups',
    stockAdjustmentGroup: '/home/stock/adjustment-groups/:stock_adjustment_group_pk',
    createStockAdjustmentGroup: '/home/stock/adjustment-groups/create/stores/:store_id',
    channelStockItems: '/home/stock/channel-stock-items',
    channelStockBreakdown: '/home/stock/channel-stock-breakdown',

    addChannelSelectChannel: '/home/channels/add/',
    addChannelSelectStore: '/home/channels/:channel_id/add/stores/',
    addChannelInstructions: '/home/channels/:channel_id/stores/:store_id/add/instructions/',
    addChannelResult: '/home/channels/:channel_id/add/result',
    integrations: '/home/integrations',
    productImportIssues: '/home/shop/:shop_id/product-import-issues',
    settings: '/home/settings',
    settingsStores: '/home/settings/stores',

    shopsDashboard: '/dashboard/shops',
    exports: '/home/sales/exports',

    // Jaymart
    jaymartSettings: '/home/jaymart/settings',

    // Chat
    chat: '/home/__chat', //TODO: Remove underscore when ready

    home: '/home',
    accessDenied: '/home/403',
})

export function getPath(name) {
    const path = PATHS[name]
    return path.replace(/:/g, '')
}

function ScrollToTop() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return null
}

function LoggedInRoute({ component: Component, loggedIn, redirectPath, ...rest }) {
    const history = useHistory()
    const prevPath = useRef('')
    const user = useAppSelector((state) => state.initial.user)

    useEffect(() => {
        const unsubscribe = history.listen((listener) => {
            if (listener.pathname === prevPath.current) {
                return
            }

            if (prevPath.current) {
                window.analytics.page({
                    referrer: prevPath.current,
                })
            } else {
                window.analytics.page()
            }

            prevPath.current = listener.pathname
        })

        return () => unsubscribe()
    }, [history])

    useEffect(() => {
        if (!loggedIn || !user) {
            setUser(null)
            return
        }

        setUser({ id: user.id, email: user.email })
    }, [loggedIn, user])

    let pathname = '/login'
    if (!loggedIn && redirectPath) {
        pathname = `/login/?redirect_path=${redirectPath}`
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                loggedIn === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: pathname, state: { from: props.location } }} />
                )
            }
        />
    )
}

function AppBar() {
    const history = useHistory()
    const language = useLanguage()
    const dispatch = useDispatch()
    const loggedIn = useAppSelector((state) => state.login.loggedIn)
    const staticRoot = useAppSelector((state) => state.initial.staticRoot)
    const loginUrl = useAppSelector((state) => state.initial.pageURLs.loginURL)
    const { logoutAPIURL, setLanguageURL } = useAppSelector((state) => state.initial.endpoints)

    const classes = useStyles()
    const [appBarMenuEl, setAppBarMenuEl] = useState(null)
    const open = !!appBarMenuEl

    async function logout() {
        await fetch(logoutAPIURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRFToken': Cookie.get('csrftoken'),
            },
        })

        dispatch(changeLoginState(false))
        history.push(loginUrl)
    }

    function handleClose() {
        setAppBarMenuEl(null)
    }

    return (
        <MuiAppBar position="fixed" className={classes.appBar}>
            <Toolbar variant="dense">
                <a href="/" className={classes.logoContainer}>
                    <img className={classes.logo} src={staticRoot + 'web/img/oakra.png'} />
                </a>
                <div className={classes.appBarRight}>
                    <LanguageButton currentLanguage={language} setLanguageURL={setLanguageURL} />
                    {loggedIn && (
                        <>
                            <IconButton
                                color="inherit"
                                aria-owns={open ? 'menu-appbar' : undefined}
                                aria-haspopup="true"
                                onClick={(e) => setAppBarMenuEl(e.currentTarget)}
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                open={open}
                                anchorEl={appBarMenuEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                getContentAnchorEl={null}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleClose()
                                        history.push(PATHS.settingsStores)
                                    }}
                                >
                                    <ListItemIcon>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText>{gettext('Settings')}</ListItemText>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        logout()
                                    }}
                                >
                                    <ListItemIcon>
                                        <ExitToAppIcon />
                                    </ListItemIcon>
                                    <ListItemText>{gettext('Logout')}</ListItemText>
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </div>
            </Toolbar>
        </MuiAppBar>
    )
}

export function FrameWithSidebar({ navSections, children }) {
    const classes = useStyles()
    const userPermissions = useAppSelector((state) => state.initial.user.permissions)

    const navSectionsToDisplay = navSections.filter((item) => {
        return !item.permission || userPermissions.some((p) => p.codename === item.permission)
    })

    return (
        <Box display="flex">
            <aside>
                <Drawer variant="permanent" anchor="left" className={classes.drawer}>
                    <Toolbar variant="dense" />
                    <List className={classes.navBar} component="nav">
                        {navSectionsToDisplay.map((section, index) => (
                            <Fragment key={index}>
                                <SidebarItem
                                    root={section.root}
                                    to={section.to}
                                    icon={section.icon}
                                    label={section.label}
                                />
                                {!!section.subItems && (
                                    <Route
                                        path={section.root}
                                        exact={false}
                                        render={() => (
                                            <SidebarSubsection subItems={section.subItems} />
                                        )}
                                    />
                                )}
                            </Fragment>
                        ))}
                    </List>
                </Drawer>
            </aside>

            <main className={classes.rightPanel}>
                <Toolbar variant="dense" />
                {children}
            </main>
        </Box>
    )
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: theme.palette.primary.main,
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: theme.palette.error.main,
    },
    '&.notistack-MuiContent-info': {
        backgroundColor: theme.palette.secondary.main,
    },
}))

export function BaseApp({ frameComponent, loggedInRoutePath }) {
    const loggedIn = useAppSelector((state) => state.login.loggedIn)
    const redirectPath = useAppSelector((state) => state.initial.redirectPath)

    const classes = useStyles()

    return (
        <Router>
            <ScrollToTop />
            <CssBaseline />
            <SnackbarProvider
                classes={{ containerRoot: classes.snackbarRoot }}
                Components={{
                    success: StyledMaterialDesignContent,
                    error: StyledMaterialDesignContent,
                    info: StyledMaterialDesignContent,
                }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
                <AppBar />
                <LoggedInRoute
                    loggedIn={loggedIn}
                    redirectPath={redirectPath}
                    path={loggedInRoutePath}
                    component={frameComponent}
                />
                <Route path="/login" component={Login} />
                <Route path="/signup" component={SignUp} exact />
                <Route path="/signup/create-new-store" component={CreateNewStore} exact />
            </SnackbarProvider>
        </Router>
    )
}
