import { PATHS } from '~/oakra/components/base-app'

type PathKeys = keyof typeof PATHS

type ExtractParams<S extends string> = string extends S
    ? never
    : S extends `${infer _Start}:${infer Param}/${infer Rest}`
      ? { [k in Param | keyof ExtractParams<Rest>]: string }
      : S extends `${infer _Start}:${infer Param}`
        ? { [k in Param]: string }
        : NonNullable<unknown>

type PathsNeedingParams = {
    [K in PathKeys]: (typeof PATHS)[K] extends `${string}:${string}` ? K : never
}[PathKeys]

export function getPath<K extends Exclude<PathKeys, PathsNeedingParams>>(path: K): string
export function getPath<K extends PathsNeedingParams>(
    path: K,
    params: ExtractParams<(typeof PATHS)[K]>
): string

export function getPath<K extends PathKeys>(
    path: K,
    params?: ExtractParams<(typeof PATHS)[K]>
): string {
    let pathValue: string = PATHS[path]

    if (params) {
        Object.entries(params).forEach(([key, value]) => {
            pathValue = pathValue.replace(`:${key}`, value)
        })
    }

    return pathValue
}
