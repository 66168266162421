import { useCallback, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { Virtuoso } from 'react-virtuoso'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Bundle from './bundle'
import BundleItemSelector from './bundle-item-selector'

import { MAX_VIRTUALIZED_HEIGHT } from '~/constants'
import { type ProductFormSchema, useProductFormContext } from '~/products/hooks/useProductForm'
import { useProductPage } from '~/products/hooks/useProductPage'
import { computeStockUnitItemKey } from '~/products/utils/stock-unit'

type BundleItem = ProductFormSchema['stock_units'][0]['stock_item']['bundle_items'][0]

const useStyles = makeStyles((theme) => ({
    bundleManagerDetails: {
        borderTopColor: theme.palette.grey[200],
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
    },
}))

export default function ProductBundles() {
    const { expanded, toggleExpanded } = useExpandBundle()
    const classes = useStyles()
    const { control, setValue, getValues } = useProductFormContext()
    const [selectedStockUnitIndex, setSelectedStockUnitIndex] = useState(-1)

    const stockUnits = useWatch({ control, name: 'stock_units', exact: true })

    const handleBundleItemSelectorClose = useCallback(() => {
        setSelectedStockUnitIndex(-1)
    }, [])

    const handleBundleItemDelete = useCallback(
        (stockUnitIndex: number, bundleItemIndex: number) => {
            setValue(
                `stock_units.${stockUnitIndex}.stock_item.bundle_items`,
                getValues(`stock_units.${stockUnitIndex}.stock_item.bundle_items`).filter(
                    (_, index) => index !== bundleItemIndex
                )
            )

            setValue(
                `stock_units.${stockUnitIndex}.stock_item.is_bundle`,
                !!getValues(`stock_units.${stockUnitIndex}.stock_item.bundle_items`).length
            )
        },
        [getValues, setValue]
    )

    const handleBundleStockItemAdd = useCallback(
        (bundleItem: BundleItem) => {
            setValue(`stock_units.${selectedStockUnitIndex}.stock_item.is_bundle`, true)
            setValue(`stock_units.${selectedStockUnitIndex}.stock_item.bundle_items`, [
                ...getValues(`stock_units.${selectedStockUnitIndex}.stock_item.bundle_items`),
                bundleItem,
            ])
        },
        [getValues, selectedStockUnitIndex, setValue]
    )

    return (
        <>
            <div>
                <Accordion expanded={expanded} onChange={toggleExpanded}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{gettext('Bundles')}</Typography>
                    </AccordionSummary>

                    <AccordionDetails className={classes.bundleManagerDetails}>
                        <Virtuoso
                            style={{ height: MAX_VIRTUALIZED_HEIGHT }}
                            data={stockUnits}
                            computeItemKey={computeStockUnitItemKey}
                            itemContent={(index) => (
                                <Bundle
                                    index={index}
                                    onBundleItemDelete={handleBundleItemDelete}
                                    onBundleItemSelectorOpen={setSelectedStockUnitIndex}
                                />
                            )}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>

            <BundleItemSelector
                open={selectedStockUnitIndex > -1}
                onAdd={handleBundleStockItemAdd}
                onClose={handleBundleItemSelectorClose}
            />
        </>
    )
}

function useExpandBundle() {
    const { isNewProduct, productInfo } = useProductPage()
    const [expanded, setExpanded] = useState(() => {
        if (isNewProduct) {
            return false
        }

        return productInfo.product.stock_units.some((stockUnit) => stockUnit.stock_item.is_bundle)
    })

    const toggleExpanded = useCallback(() => {
        setExpanded((prevExpanded) => !prevExpanded)
    }, [])

    return { expanded, toggleExpanded }
}
