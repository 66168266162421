import { permissionCodes } from '~/constants'

export function buildScopePermissions(scopedPermissions) {
    return scopedPermissions.reduce((permissions, code) => {
        const permission = code.split('.')
        permission.shift() // Remove prefix
        const scope = permission[0]
        const name = permission[1]

        if (!permissions[scope]) {
            permissions[scope] = {}
        }

        if (!permissions[scope][name]) {
            permissions[scope][name] = code
        }

        return permissions
    }, {})
}

/**
 *
 * @param stores required
 * @param permissionCodes required
 * @param accountId optional
 * @returns {*}
 */
export function buildAvailablePermissions(stores, accountId, groupId = undefined) {
    const defaultConfig = {
        stores: [],
        shops: [],
        account: [],
        group: [], // Same as account permissions
        modules: [],
    }

    return stores.reduce((config, store) => {
        config.modules.push({
            accountId,
            permissions: buildScopePermissions(permissionCodes.modules),
        })
        config.account.push({
            accountId,
            permissions: buildScopePermissions(permissionCodes.account),
        })
        config.group.push({ groupId, permissions: buildScopePermissions(permissionCodes.account) })
        config.stores.push({
            storeId: store?.id,
            storeName: store?.name,
            permissions: buildScopePermissions(permissionCodes.store),
        })
        store.shops.forEach((shop) => {
            config.shops.push({
                storeId: store?.id,
                shopId: shop?.id,
                channelId: shop?.channel?.id,
                shopName: shop?.shop_name,
                channelName: shop?.channel?.name,
                permissions: buildScopePermissions(permissionCodes.shop),
            })
        })

        return config
    }, defaultConfig)
}
