import { useEffect, useMemo, useState } from 'react'

import RefreshIcon from '@mui/icons-material/Refresh'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Skeleton from '@mui/material/Skeleton'
import type { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { isEqual } from 'lodash-es'

import { getChannelShippingInfoManager } from './channel-shipping-info-managers'

import {
    queryKeys,
    api,
    type InitShippingInfoFieldsResponse,
    type InitShippingInfo,
    type FieldInfo,
    type InitShippingInfoRequest,
} from '~/api'
import { useUser } from '~/common/hooks'
import { formatDate } from '~/utils/date'

const useStyles = makeStyles<Theme>((theme) => ({
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dialogActions: {
        paddingRight: 24,
        paddingLeft: 24,
        paddingBottom: 15,
        marginTop: 0,
    },
    shippingInfoSection: {
        flexGrow: 1,
        display: 'block',
    },
    noOrdersMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 30,
    },
    shippingInitFieldsProgress: {
        display: 'grid',
        placeItems: 'center',
        height: '100%',
    },
    shippingInfoTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    shippingInfoTitleText: {
        color: theme.palette.grey[500],
        marginLeft: 5,
    },
    channelIcon: {
        width: 75,
        alignSelf: 'center',
    },
    submitProgress: {
        marginRight: 10,
    },
    initShippingInfoError: {
        color: theme.palette.error.main,
        fontSize: '0.8rem',
        lineHeight: 1.2,
    },
    filter: {
        marginBottom: theme.spacing(3),
    },
    refreshDialogContent: {
        padding: '20px 40px',
    },
    shippingProviderImage: {
        maxWidth: 80,
        maxHeight: 30,
    },
}))

const manualShippingShop = {
    id: 0,
    channel: { name: 'Other', channel_icon_url: '' },
    shop_name: gettext('Manual / Webstore orders'),
} as const

export default function PageInitShipping() {
    const classes = useStyles()
    const { lastRefreshTime, mustRefresh, setLastRefreshTime } = useLastRefreshTime()

    const [filter, setFilter] = useState<Record<string, string>>(createInitialFilter)
    const isFilterEmpty = Object.keys(filter).length === 0

    const [isConfirming, setIsConfirming] = useState(false)

    const { data, refetch, isLoading } = useSaleIdsQuery(filter)
    const salePksLength = data?.body.length ?? 0
    const canConfirm = salePksLength > 0

    const {
        mutate,
        isLoading: initShippingInfoLoading,
        isError,
        isSuccess,
        data: initShippingInfoResult,
        error: initShippingInfoError,
        reset: resetInitShipping,
    } = api.sales.initShippingInfo.useMutation()
    const isResolved = isSuccess || isError
    const initShippingInfoErrors = initShippingInfoResult?.body.errors ?? []
    const resultHasErrors = initShippingInfoErrors.length > 0
    const amountCompleted = salePksLength - initShippingInfoErrors.length

    const {
        data: initShippingInfoFieldsData,
        isLoading: initShippingInfoFieldsLoading,
        fieldInfoByShopId,
        initShippingInfoByShopId,
        changeInitShippingInfo,
    } = useInitShippingInfoFields(data?.body)
    const hasOrders = !!initShippingInfoFieldsData?.body.length

    return (
        <>
            <Dialog open disableEscapeKeyDown fullScreen scroll="paper">
                <DialogTitle className={classes.dialogTitle}>
                    <Typography variant="h6">{gettext('Confirm Shipping')}</Typography>
                    {lastRefreshTime > 0 && (
                        <Typography color="textSecondary" variant="caption">
                            {gettext('Last refresh time')}: {formatDate(lastRefreshTime, 'HH:mm')}
                        </Typography>
                    )}
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <InitShippingFilters filter={filter} onFilterUpdate={setFilter} />
                    <InitShippingOrders
                        filter={filter}
                        isFilterEmpty={isFilterEmpty}
                        initShippingInfoFields={initShippingInfoFieldsData?.body}
                        initShippingInfoFieldsLoading={initShippingInfoFieldsLoading}
                        initShippingInfoByShopId={initShippingInfoByShopId}
                        fieldInfoByShopId={fieldInfoByShopId}
                        onInfoChange={changeInitShippingInfo}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!canConfirm}
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsConfirming(true)}
                    >
                        {gettext('Ship')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isConfirming}>
                <DialogTitle>{gettext('Confirm')}</DialogTitle>
                <DialogContent>
                    {!isResolved && (
                        <Typography>
                            {interpolate(
                                gettext('You are about to confirm shipping for %s%s orders'),
                                [isFilterEmpty ? 'all ' : '', salePksLength]
                            )}
                        </Typography>
                    )}

                    {initShippingInfoResult && (
                        <Typography>
                            {interpolate(gettext('Completed %s out of %s orders'), [
                                salePksLength,
                                amountCompleted,
                            ])}
                        </Typography>
                    )}

                    {(resultHasErrors || initShippingInfoError) && (
                        <Alert severity="warning">
                            {gettext('Shipping confirmation was not successful for some orders')}
                        </Alert>
                    )}

                    {initShippingInfoError && (
                        <Typography color="error">
                            {gettext(
                                'There was a problem submiting shipping info for orders. If this issue persists, please contact us'
                            )}
                        </Typography>
                    )}

                    {resultHasErrors && (
                        <List>
                            {initShippingInfoResult?.body.errors.map((item) => (
                                <ListItem key={item.sale_id}>
                                    {item.sale_id}: {item.message}
                                </ListItem>
                            ))}
                        </List>
                    )}
                </DialogContent>
                <DialogActions>
                    {isResolved ? (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setIsConfirming(false)
                                resetInitShipping()
                                void refetch()
                            }}
                        >
                            {gettext('Close')}
                        </Button>
                    ) : (
                        <>
                            <Button onClick={() => setIsConfirming(false)}>Cancel</Button>
                            <Button
                                disabled={initShippingInfoLoading}
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    if (!data || !initShippingInfoFieldsData) {
                                        return
                                    }

                                    window.analytics.track('Confirm order shipping', {
                                        page: window.location.href,
                                        feature: 'order',
                                        mode: 'mobile',
                                        trigger: 'button click',
                                    })

                                    mutate(
                                        {
                                            body: {
                                                sales_filter: { included_sale_pks: data.body },
                                                init_shipping_info: toInitShippingInfoPayload(
                                                    initShippingInfoByShopId,
                                                    fieldInfoByShopId
                                                ),
                                            },
                                        },
                                        {
                                            onSettled: () => {
                                                setLastRefreshTime(0)
                                            },
                                        }
                                    )
                                }}
                            >
                                {gettext('Confirm')}
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog open={mustRefresh}>
                <DialogContent className={classes.refreshDialogContent}>
                    <Button
                        disabled={isLoading || !hasOrders}
                        startIcon={<RefreshIcon />}
                        onClick={() => refetch()}
                    >
                        {gettext('Refresh')}
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    )
}

type InitShippingFiltersProps = {
    filter: Record<string, string>
    onFilterUpdate: (value: Record<string, string>) => void
}

function InitShippingFilters({ filter, onFilterUpdate }: InitShippingFiltersProps) {
    const classes = useStyles()
    const user = useUser()

    const { data, isLoading, error } = api.sales.filterValues.useQuery(
        queryKeys.sales.filterValues(user.id).queryKey,
        {
            params: { userId: user.id },
        }
    )

    const filterValues = useMemo(() => {
        const selectedLookup = new Set(['shop', 'shop__channel'])

        return data?.body.filter((item) => selectedLookup.has(item.lookup)) ?? []
    }, [data])

    if (isLoading) {
        return (
            <Grid container component="section" spacing={2} className={classes.filter}>
                {Array(2).map((_, i) => (
                    <Grid item key={i} xs={12}>
                        <Skeleton variant="rectangular" height={56} width="100%" />
                    </Grid>
                ))}
            </Grid>
        )
    }

    if (error) {
        return (
            <Grid container component="section" spacing={2} className={classes.filter}>
                <Grid item xs={12}>
                    <Typography color="error">
                        {error instanceof Error ? error.message : gettext('Unknown error')}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    if (!data) {
        return null
    }

    return (
        <Grid container component="section" spacing={2} className={classes.filter}>
            {filterValues.map((item) => (
                <Grid item key={item.lookup} xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id={`label-${item.lookup}`}>{item.field}</InputLabel>
                        <Select
                            value={filter[item.lookup] ?? ''}
                            label={item.field}
                            multiple={item.select_multiple}
                            onChange={(e) => {
                                // There should only be one lookup in mobile page
                                onFilterUpdate({
                                    ...createInitialFilter(),
                                    [item.lookup]: e.target.value,
                                })
                            }}
                        >
                            {item.values.map((value) => (
                                <MenuItem key={value.value} value={value.value}>
                                    {value.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            ))}
        </Grid>
    )
}

type InitShippingOrdersProps = {
    filter: Record<string, string>
    isFilterEmpty: boolean
    initShippingInfoFields?: InitShippingInfoFieldsResponse
    initShippingInfoFieldsLoading: boolean
    fieldInfoByShopId: Record<number, FieldInfo>
    initShippingInfoByShopId: Record<number, InitShippingInfo>
    onInfoChange: (shopId: number, value: InitShippingInfo) => void
}

function InitShippingOrders({
    filter,
    isFilterEmpty,
    initShippingInfoFields,
    initShippingInfoFieldsLoading,
    fieldInfoByShopId,
    initShippingInfoByShopId,
    onInfoChange,
}: InitShippingOrdersProps) {
    const classes = useStyles()
    const { data, isLoading } = useSaleIdsQuery(filter)

    if (isLoading) {
        return (
            <Grid container component="section" className={classes.shippingInfoSection}>
                <Grid item xs={12}>
                    Loading...
                </Grid>
            </Grid>
        )
    }

    if (!data) {
        return null
    }

    return (
        <Grid container component="section" className={classes.shippingInfoSection}>
            <Grid item xs={12}>
                {data.body.length > 0 ? (
                    <Typography>
                        {interpolate(gettext('You are about to confirm shipping for %s%s orders'), [
                            isFilterEmpty ? 'all ' : '',
                            data.body.length,
                        ])}
                    </Typography>
                ) : (
                    <div className={classes.noOrdersMessage}>
                        <Typography>{gettext('No orders available to ship')}</Typography>
                    </div>
                )}
            </Grid>

            <InitShippingProviders
                initShippingInfoFields={initShippingInfoFields}
                isLoading={initShippingInfoFieldsLoading}
                fieldInfoByShopId={fieldInfoByShopId}
                initShippingInfoByShopId={initShippingInfoByShopId}
                onInfoChange={onInfoChange}
            />
        </Grid>
    )
}

type InitShippingProvidersProps = {
    initShippingInfoFields?: InitShippingInfoFieldsResponse
    isLoading: boolean
    fieldInfoByShopId: Record<number, FieldInfo>
    initShippingInfoByShopId: Record<number, InitShippingInfo>
    onInfoChange: (shopId: number, updated: InitShippingInfo) => void
}

function InitShippingProviders({
    initShippingInfoFields,
    isLoading,
    fieldInfoByShopId,
    initShippingInfoByShopId,
    onInfoChange,
}: InitShippingProvidersProps) {
    const classes = useStyles()

    if (isLoading) {
        return (
            <Grid item xs={12} className={classes.shippingInitFieldsProgress}>
                <CircularProgress color="secondary" size={30} />
            </Grid>
        )
    }

    if (!initShippingInfoFields) {
        return null
    }

    return (
        <>
            <Grid item xs={12} component={List}>
                {initShippingInfoFields.map((fields) => {
                    if (!fields.init_shipping_info) {
                        return null
                    }

                    const shop = fields.shop ?? manualShippingShop
                    const ChannelShippingInfoManager = getChannelShippingInfoManager(
                        shop.channel.name
                    )
                    const initShippingInfo = initShippingInfoByShopId[shop.id]

                    return (
                        <ListItem disableGutters key={shop.id}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.shippingInfoTitle}>
                                    {shop.channel?.channel_icon_url && (
                                        <img
                                            className={classes.channelIcon}
                                            src={shop.channel.channel_icon_url}
                                        />
                                    )}
                                    <Typography className={classes.shippingInfoTitleText}>
                                        {shop.shop_name}
                                    </Typography>
                                </Grid>
                                {initShippingInfo && (
                                    <Grid item xs={12}>
                                        <ChannelShippingInfoManager
                                            initShippingInfoFields={fields.init_shipping_info}
                                            initShippingInfo={initShippingInfo}
                                            fieldInfo={fieldInfoByShopId[shop.id]}
                                            handleInfoChange={(shipingInfo: InitShippingInfo) => {
                                                onInfoChange(shop.id, shipingInfo)
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </ListItem>
                    )
                })}
            </Grid>
        </>
    )
}

const REFRESH_PERIOD_SECONDS = 120
/**
 * Custom React hook to manage refresh logic based on a time period.
 *
 * @param periodMs - The refresh period in milliseconds. Defaults to 1000 * REFRESH_PERIOD_SECONDS.
 *
 * @returns An object containing:
 * - `lastRefreshTime`: The last time the data was refreshed, represented as a timestamp.
 * - `mustRefresh`: A boolean indicating whether a refresh is required.
 * - `setLastRefreshTime`: A function to update the last refresh time.
 *
 * @example
 * const { lastRefreshTime, mustRefresh, setLastRefreshTime } = useLastRefreshTime(1000);
 */
function useLastRefreshTime(periodMs = 1000 * REFRESH_PERIOD_SECONDS) {
    const [lastRefreshTime, setLastRefreshTime] = useState(0)
    const [mustRefresh, setMustRefresh] = useState(false)

    useEffect(() => {
        if (!lastRefreshTime) {
            return
        }

        setMustRefresh(false)

        const timer = setTimeout(() => {
            setMustRefresh(true)
        }, periodMs)

        return () => {
            clearTimeout(timer)
        }
    }, [lastRefreshTime, periodMs])

    return {
        lastRefreshTime,
        mustRefresh,
        setLastRefreshTime,
    }
}

function useSaleIdsQuery(filter: Record<string, string>) {
    const user = useUser()

    return api.sales.ids.useQuery(queryKeys.sales.ids(user.id, filter).queryKey, {
        params: { userId: user.id },
        query: filter,
    })
}

function useInitShippingInfoFields(saleIds?: number[]) {
    const [initShippingInfoByShopId, setShippingInfoByShopId] = useState(
        {} as Record<number, InitShippingInfo>
    )

    const { mutate, isLoading, data, reset } = api.sales.initShippingInfoFields.useMutation()
    useEffect(() => {
        if (!saleIds?.length) {
            reset()
            setShippingInfoByShopId({})
            return
        }

        mutate(
            { body: { sales_filter: { included_sale_pks: saleIds } } },
            {
                onSuccess: (data) => {
                    setShippingInfoByShopId((prev) => {
                        const newInitShippingInfo = data.body.reduce(
                            (acc, current) => {
                                const shopId = current.shop?.id
                                if (shopId) {
                                    acc[shopId] = {}
                                }
                                return acc
                            },
                            {} as Record<number, InitShippingInfo>
                        )

                        return { ...prev, ...newInitShippingInfo }
                    })
                },
            }
        )
    }, [reset, mutate, saleIds])

    const fieldInfoByShopId = useMemo(() => {
        const record = {} as Record<number, FieldInfo>
        if (!data) {
            return record
        }

        return data.body.reduce((acc, current) => {
            const fieldInfo = current.field_info
            if (fieldInfo) {
                const shopId = current.shop?.id ?? 0
                acc[shopId] = fieldInfo
            }

            return acc
        }, record)
    }, [data])

    function changeInitShippingInfo(shopId: number, updatedShippingInfo: InitShippingInfo) {
        setShippingInfoByShopId((prev) => {
            return isEqual(prev[shopId], updatedShippingInfo)
                ? prev
                : {
                      ...prev,
                      [shopId]: updatedShippingInfo,
                  }
        })
    }

    return {
        data,
        isLoading,
        fieldInfoByShopId,
        initShippingInfoByShopId,
        changeInitShippingInfo,
    }
}

function createInitialFilter() {
    const today = new Date()
    const twoWeeksMs = 2 * 7 * 24 * 60 * 60 * 1000
    const twoWeeksAgo = new Date(today.getTime() - twoWeeksMs)

    return {
        date_created_before: formatDate(today, 'YYYY-MM-DD'),
        date_created_after: formatDate(twoWeeksAgo, 'YYYY-MM-DD'),
        status: 'pending',
    }
}

function toInitShippingInfoPayload(
    initShippingInfoByShopId: Record<number, InitShippingInfo>,
    fieldInfoByShopId: Record<number, FieldInfo>
): InitShippingInfoRequest {
    const result: InitShippingInfoRequest = {}

    Object.entries(initShippingInfoByShopId).forEach(([shopKey, initShippingInfoForShop]) => {
        Object.entries(initShippingInfoForShop).forEach(([field, value]) => {
            const shopId = parseInt(shopKey)
            const fieldInfo = fieldInfoByShopId[shopId][field]
            result[shopId] = {}

            // Special handling for some fields like pickup_time_id_by_shipping_provider where we need additional logic to generate the value instead of valueObj[valueField]
            if (field === 'pickup_time_id_by_shipping_provider' && isSelectFieldInfo(fieldInfo)) {
                // {shippingProviderID: {date: 12312312, pickup_time_id: "12312312"}}
                const valueObj = value as Record<string, Record<string, string>>

                result[shopId].pickup_time_id_by_shipping_provider = Object.keys(valueObj).reduce(
                    (acc, shippingProviderId) => {
                        acc[parseInt(shippingProviderId)] =
                            valueObj[shippingProviderId][fieldInfo.value_field]

                        return acc
                    },
                    {} as Record<number, string>
                )
            } else if (
                typeof value === 'object' &&
                value !== null &&
                isSelectFieldInfo(fieldInfo)
            ) {
                const valueObj = value as Record<string, string | number>
                result[shopId][field] = valueObj[fieldInfo.value_field]
            } else {
                result[shopId][field] = value
            }
        })
    })

    return result
}

function isSelectFieldInfo(field: unknown): field is {
    input_type: 'select'
    label: string
    label_field: string
    value_field: string
} {
    return (
        typeof field === 'object' &&
        field !== null &&
        'input_type' in field &&
        'label' in field &&
        'label_field' in field &&
        'value_field' in field &&
        field.input_type === 'select'
    )
}
