import React from 'react'
import ReactQuill, { Quill } from 'react-quill'

import TextField from '@material-ui/core/TextField'

import Cookie from 'js-cookie'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'

import { debounce } from '../../tools/utils'
import 'react-quill/dist/quill.snow.css'

export class DebouncedTextField extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: null,
            initialized: false,
        }

        this.onChange = this.onChange.bind(this)
        this.onChangeDebounced = props.onChange ? debounce(this.props.onChange, 500) : null

        this.onChangePromise = null
    }

    componentDidMount() {
        // Single quote case required for string attributes to load correct on Attribute component
        if (
            this.props.value !== '' &&
            this.props.value !== null &&
            this.props.value !== undefined &&
            !this.state.value &&
            !this.state.initialized
        ) {
            this.setState({
                value: this.props.value,
                initialized: true,
            })
        }
    }

    componentDidUpdate() {
        if (
            this.props.value !== '' &&
            this.props.value !== null &&
            this.props.value !== undefined &&
            !this.state.value &&
            !this.state.initialized
        ) {
            this.setState({
                value: this.props.value,
                initialized: true,
            })
        }
    }

    onChange(e) {
        e.persist()

        if (this.onChangeDebounced) {
            this.setState({ value: e.target.value }, () => {
                this.promise = this.onChangeDebounced(e)
            })
        }
    }

    render() {
        const { onChange, value, ...passedProps } = this.props

        return (
            <TextField
                {...passedProps}
                value={
                    this.state.value === null || this.state.value === undefined
                        ? ''
                        : this.state.value
                }
                onChange={this.onChange}
            />
        )
    }
}

export class DebouncedQuill extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: props.value,
            initialized: false,
        }

        this.onChange = this.onChange.bind(this)
        this.onChangeDebounced = props.onChange ? debounce(props.onChange, 500) : null

        this.promise = null

        this.modules = {
            toolbar: {
                container: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['link', 'image'],
                    ['clean'],
                ],
                handlers: {
                    image: props.imageHandler,
                },
            },
            imageDropAndPaste: {
                handler: this.imageHandler,
            },
        }
        Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
    }

    getValue = (value) => (value !== '<p><br></p>' ? value : null)

    componentDidMount() {
        if (
            this.props.value !== null &&
            this.props.value !== undefined &&
            Boolean(this.props.value) &&
            !this.state.value &&
            !this.state.initialized
        ) {
            this.setState({
                value: this.getValue(this.props.value),
                initialized: true,
            })
        }
    }

    componentDidUpdate() {
        if (
            this.props.value !== null &&
            this.props.value !== undefined &&
            Boolean(this.props.value) &&
            !this.state.value &&
            !this.state.initialized
        ) {
            this.setState({
                value: this.getValue(this.props.value),
                initialized: true,
            })
        }
    }

    onChange(content, delta, source, editor) {
        const normalizedContent = this.getValue(content)
        this.setState({ value: normalizedContent }, () => {
            if (this.onChangeDebounced) {
                this.promise = this.onChangeDebounced(normalizedContent, delta, source, editor)
            }
        })
    }

    imageHandler = (dataUrl, type, imageData) => {
        const file = imageData.toFile('tmp_image.png')
        this.descriptionImageHandler([file])
    }

    getDescriptionImageUploadURLs = (imagesData, callback) => {
        fetch(this.props.uploadImageSignatureAPIURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRFToken': Cookie.get('csrftoken'),
            },
            body: JSON.stringify(imagesData),
        })
            .then((response) => response.json())
            .then((uploadURLs) => {
                callback(uploadURLs)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    descriptionImageHandler = (filesArray) => {
        const { quillRef, staticFilesDomain } = this.props

        const maxAllowedSize = 5 * 1024 * 1024

        const imageFiles = filesArray.filter(
            (f) => /^image\//.test(f.type) && f.size < maxAllowedSize
        )

        const infoForFiles = imageFiles.map((image) => ({
            filename: image.name,
            content_type: image.type,
        }))

        this.getDescriptionImageUploadURLs(infoForFiles, (uploadURLs) => {
            imageFiles.forEach((imageToUpload) => {
                const uploadURL = uploadURLs.find(
                    (urlInfo) => urlInfo.original_filename == imageToUpload.name
                )

                fetch(uploadURL.presigned_url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': imageToUpload.type,
                    },
                    body: imageToUpload,
                })
                    .then((response) => {
                        const editor = quillRef.current.getEditor()
                        const range = editor.getSelection(true)
                        const imageURL = staticFilesDomain.concat(uploadURL.filename)
                        editor.insertEmbed(range.index, 'image', imageURL)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
        })
    }

    render() {
        const { onChange, value, quillRef, ...passedProps } = this.props

        return (
            <ReactQuill
                {...passedProps}
                value={this.state.value || ''}
                onChange={this.onChange}
                modules={this.modules}
                ref={quillRef}
            />
        )
    }
}
