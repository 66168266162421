import grey from '@material-ui/core/colors/grey'
import { lighten } from '@material-ui/core/styles/colorManipulator'

export const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        position: 'relative',
    },
    toolbar: {
        position: 'fixed',
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
    },
    tableHeader: {
        borderTop: '1px solid ' + grey[200],
        height: 60,
    },
    tableCell: {
        padding: '0 12px',
    },
    rightAlign: {
        textAlign: 'right',
    },
    centerAlign: {
        textAlign: 'center',
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    checkboxColumnHeader: {
        width: 70,
    },
    checkboxRowCell: {
        padding: '0 12px',
    },
    productLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    imgPlaceholder: {
        height: 50,
        width: 50,
    },
    productImg: {
        minHeight: 50,
        height: 50,
        width: 50,
        marginTop: 5,
        marginBottom: 5,
        borderRadius: 5,
    },

    noProductMessage: {
        textAlign: 'center',
        padding: 75,
    },
    noProductsText: {
        color: grey[400],
    },

    toolbarActionButton: {
        alignSelf: 'center',
        flexShrink: 0,
    },

    numberInput: {
        paddingTop: 7,
        paddingBottom: 7,
    },

    inlineText: {
        display: 'inline-block',
        padding: '14px 6px',
    },
})

export const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        minHeight: 67,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    spacer: {
        flex: '1 1 auto',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },
    topPagination: {},
    title: {
        flex: '0 1 auto',
        flexWrap: 'wrap',
    },
    toolbarItems: {
        display: 'flex',
        alignItems: 'baseline',
        flexWrap: 'wrap',
        marginTop: 10,
        marginBottom: 10,
    },
    searchBar: {
        width: 150,
        marginTop: 2.5,
        marginBottom: 2.5,
        marginRight: 7,
    },
    statusSelectorSelect: {
        height: 38,
    },
    actions: {
        display: 'flex',
        flex: '0 0 auto',
    },
})
