import { useRouteMatch } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'

import { type ApiContract, type InferResponseBody, api, queryKeys } from '~/api'
import { useLanguage, useUser } from '~/common/hooks'
import Product from '~/products/components/product'
import ProductSkeleton from '~/products/components/product-skeleton'
import { ProductPageProvider } from '~/products/hooks/useProductPage'
import { useAppSelector } from '~/store'

export default function ProductEditPage() {
    const { params } = useRouteMatch<{ store_id: string; product_id: string }>()
    const storeId = +params.store_id
    const productId = +params.product_id
    if (isNaN(storeId) || isNaN(productId)) {
        throw new Error('Invalid product id or store id')
    }

    const store = useAppSelector(
        ({ initial: { stores } }) => stores.find((store) => store.id === storeId) ?? stores[0]
    )

    const {
        status,
        product,
        stockRecords,
        userLocations,
        productDiscounts,
        activeChannelStockUnitDiscounts,
        channelAttributeValues,
    } = useProductDetails({ productId })

    if (status === 'loading') {
        return <ProductSkeleton />
    } else if (status === 'error') {
        return <Typography color="error">An error has occurred</Typography>
    }

    return (
        <ProductPageProvider
            isNewProduct={false}
            store={store}
            product={product}
            stockRecords={stockRecords}
            locations={userLocations}
            productDiscounts={productDiscounts}
            activeChannelStockUnitDiscounts={activeChannelStockUnitDiscounts}
            channelAttributeValues={channelAttributeValues}
        >
            <Product />
        </ProductPageProvider>
    )
}

function useProductDetails({ productId = 0 }) {
    const language = useLanguage()
    const user = useUser()
    const defaultOptions = { enabled: productId !== 0, refetchOnWindowFocus: false }

    const { data: productData, status: productStatus } = api.products.detail.useQuery(
        queryKeys.products.detail(productId).queryKey,
        { params: { id: productId } },
        { ...defaultOptions, staleTime: Infinity }
    )

    const channelAttributeValuesQueries = api.channels.attributeValues.useQueries({
        queries:
            productData?.body.channel_products.map(({ id }) => ({
                ...defaultOptions,
                queryKey: queryKeys.channels.attributeValues({ channelProductId: id }).queryKey,
                params: { channelProductId: id },
                query: { language },
                enabled: !!productData?.body,
            })) ?? [],
    })

    const { data: userLocationsData, status: userLocationsStatus } = api.users.locations.useQuery(
        queryKeys.users.locations(user.id).queryKey,
        { params: { id: user.id } },
        { ...defaultOptions }
    )

    const { data: stockRecordsData, status: stockRecordsStatus } =
        api.products.stockRecords.useQuery(
            queryKeys.products.stockRecords(productId).queryKey,
            { params: { id: productId } },
            { ...defaultOptions }
        )

    const { data: productDiscountsData, status: productDiscountsStatus } =
        api.products.discounts.useQuery(
            queryKeys.products.discounts(productId).queryKey,
            { params: { id: productId } },
            { ...defaultOptions }
        )

    const {
        data: activeChannelStockUnitDiscountsData,
        status: activeChannelStockUnitDiscountsStatus,
    } = api.products.activeChannelStockUnitDiscounts.useQuery(
        queryKeys.products.activeChannelStockUnitDiscounts(productId).queryKey,
        { params: { id: productId } },
        { ...defaultOptions }
    )

    if (
        productStatus === 'loading' ||
        userLocationsStatus === 'loading' ||
        stockRecordsStatus === 'loading' ||
        productDiscountsStatus === 'loading' ||
        activeChannelStockUnitDiscountsStatus === 'loading' ||
        channelAttributeValuesQueries.some((query) => query.isLoading)
    ) {
        return { status: 'loading' as const }
    } else if (
        productData?.status !== 200 ||
        userLocationsData?.status !== 200 ||
        stockRecordsData?.status !== 200 ||
        productDiscountsData?.status !== 200 ||
        activeChannelStockUnitDiscountsData?.status !== 200 ||
        channelAttributeValuesQueries.some((query) => query.isError)
    ) {
        return { status: 'error' as const }
    }

    return {
        status: 'success' as const,
        userLocations: userLocationsData.body,
        product: productData.body,
        stockRecords: stockRecordsData.body,
        productDiscounts: productDiscountsData.body,
        activeChannelStockUnitDiscounts: activeChannelStockUnitDiscountsData.body,
        channelAttributeValues: channelAttributeValuesQueries.reduce(
            (acc, query) => {
                if (query.status !== 'success' || query.data.body.products.length === 0) {
                    return acc
                }

                const channelAttributeValues = query.data.body
                const channelId = productData.body.channel_products.find((cp) => {
                    return cp.id === channelAttributeValues.products[0].channel_product
                })?.shop.channel.id

                if (channelId) {
                    acc[channelId] = channelAttributeValues
                }

                return acc
            },
            {} as Record<number, InferResponseBody<ApiContract['channels']['attributeValues']>>
        ),
    }
}
