import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import grey from '@material-ui/core/colors/grey'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import classNames from 'classnames'
import { enqueueSnackbar } from 'notistack'

import { countryCurrencies, currencySymbols } from '../../constants'
import { getPath } from '../../oakra/components/base-app'
import { post, patch } from '../../tools/request'
import { copyObject } from '../../tools/utils'
import Notes from '../containers/notes'

import Fulfillments from './sale/fulfillments'
import SaleCustomer from './sale/sale-customer'
import SaleHeader from './sale/sale-header'
import SaleHistory from './sale/sale-history'
import SaleItemSelector from './sale/sale-item-selector'
import SaleItems from './sale/sale-items'
import SaleSummary from './sale/sale-summary'

import { useAppSelector } from '~/store'

export const formatAmount = (amount, currency) =>
    `${currencySymbols[currency]} ${parseFloat(amount).toFixed(2)}`

export const SALE_FORM_MODES = {
    update: 'update',
    add: 'add',
}

/**
 * @deprecated Please use useCommonSaleStyles below
 */
export const commonStyles = {
    section: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 20,
        paddingRight: 20,
    },
    sectionTitle: {
        fontSize: '0.9rem',
        fontWeight: 600,
    },
    actionBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bodyText: {
        color: grey[700],
    },
    secondaryButton: {
        color: grey[700],
    },
}

export const useCommonSaleStyles = makeStyles((theme) => ({
    section: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 20,
        paddingRight: 20,
    },
    sectionTitle: {
        fontSize: '0.9rem',
        fontWeight: 600,
    },
    actionBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bodyText: {
        color: theme.palette.grey[700],
    },
    secondaryButton: {
        color: theme.palette.grey[700],
    },
}))

// Main Sale Container
const useSalesStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 900,
        marginTop: theme.spacing(3),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    panel: {
        padding: 5,
    },
    middlePanel: {
        minHeight: 200,
    },
}))

const getSanitisedSale = ({
    shop,
    sale_line_items,
    status,
    payment_method_type,
    currency,
    discount,
    discount_percent,
    shipping_fee,
    cod_fee,
    customer,
    shipping_address,
    billing_address,
    invoice_address,
    is_tax_invoice_requested,
}) => {
    const data = {
        shop,
        sale_line_items: sale_line_items.map(
            ({ stock_unit, unit_price, num_sold, item_level_discount }) => ({
                stock_unit,
                unit_price: Number(unit_price),
                num_sold: Number(num_sold),
                discounts: Number(item_level_discount)
                    ? [
                          {
                              discount_type: 'manual',
                              name: gettext('Manual'),
                              amount: Number(item_level_discount),
                          },
                      ]
                    : [],
            })
        ),
        status,
        payment_method_type,
        currency,
        discounts: [],
        shipping_fees: [],
        fees: [],
        customer: customer.id,
        shipping_address: shipping_address && shipping_address.id,
        billing_address: billing_address && billing_address.id,
        invoice_address: invoice_address && invoice_address.id,
        is_tax_invoice_requested,
    }

    if (discount_percent) {
        const subTotal = sale_line_items.reduce(
            (total, item) =>
                total +
                Number(item.unit_price || item.price_paid) * Number(item.num_sold) -
                (Number(item.item_level_discount) || 0),
            0
        )
        if (discount_percent) {
            discount = (discount_percent * subTotal) / 100
        }
    }

    if (discount) {
        data.discounts.push({
            discount_type: 'manual',
            name: gettext('Manual'),
            amount: discount,
        })
    }

    if (shipping_fee) {
        data.shipping_fees.push({
            amount: shipping_fee,
            total: shipping_fee,
        })
    }

    if (cod_fee) {
        data.fees.push({
            fee_type: 'cod',
            name: gettext('COD'),
            amount: cod_fee,
            total: cod_fee,
        })
    }

    return data
}

/**
 * TODO: Refactor this component to 2 page components, new (/sale/new) and edit (/sale/:sale_id)
 * The page components should identify the current mode, then render the page with approriate props
 * Currently this component acts as 2 pages, which might be difficult to maintain
 */
export default function Sale() {
    const {
        saleAPIURL,
        saleCreateAPIURL,
        stockUnitsForShopAPIURL,
        stockUnitsForStoreAPIURL,
        productAPIURL,
        saleFulfillmentsAPIURL,
        shippingProvidersForStoreAPIURL,
        fulfillmentInitiateShippingAPIURL,
        fulfillmentShippingLabelURL,
    } = useAppSelector((state) => state.initial.endpoints)
    const paymentMethods = useAppSelector((state) => state.initial.paymentMethods)
    const fulfillmentStatuses = useAppSelector((state) => state.initial.fulfillmentStatuses)
    const language = useAppSelector((state) => state.initial.language)
    const stores = useAppSelector((state) => {
        return state.initial.stores.map((store) => ({
            ...store,
            shops: store.shops.map((shop) => ({ ...shop, store_id: store.id })), // set store_id to shop for reference
        }))
    })
    const saleStatusMap = useAppSelector((state) => {
        return new Map(state.initial.saleStatuses.map(({ status, label }) => [status, label]))
    }, shallowEqual)

    const match = useRouteMatch()
    const classes = useSalesStyles()
    const [sale, setSale] = useState({})
    const [saleHasChanged, setSaleHasChanged] = useState(false)
    const updateSaleState = useCallback((data, hasChanged) => {
        setSale((prevSale) => {
            return data
                ? {
                      ...prevSale,
                      ...data,
                  }
                : data
        })
        if (hasChanged !== undefined) {
            setSaleHasChanged(hasChanged)
        }
    }, [])

    const saleID = match.params.sale_id
    const mode = saleID === 'new' ? SALE_FORM_MODES.add : SALE_FORM_MODES.update
    const isAdd = mode === SALE_FORM_MODES.add
    const history = useHistory()

    const [availableSaleStatusMap, setAvailableSaleStatusMap] = useState(
        ['unpaid', 'pending', 'waiting_for_stock', 'completed'].reduce(
            (m, status) => ((m[status] = saleStatusMap.get(status)), m),
            {}
        )
    )

    const [saleItemSelectorOpen, setSaleItemSelectorOpen] = useState(false)
    const [saleItemSelectorSearchQuery, setSaleItemSelectorSearchQuery] = useState('')
    const [saleItemSelectorDisabledStockUnits, setSaleItemSelectorDisabledStockUnits] = useState([])

    const paymentMethodDetails = useMemo(() => {
        return {
            types: paymentMethods.map((method) => method.payment_method_type),
            names: paymentMethods.reduce(
                (names, method) => ((names[method.payment_method_type] = method.label), names),
                {}
            ),
            icons: paymentMethods.reduce(
                (icons, method) => ((icons[method.payment_method_type] = method.icon_url), icons),
                {}
            ),
        }
    }, [paymentMethods])

    const calculateItemLevelDiscount = (sale) => {
        sale.sale_line_items.forEach(sli => {
            sli.item_level_discount = sli.discounts ? sli.discounts.reduce((total, d) => total + Number(d.amount), 0) : 0
        })
    }
    const fetchSale = useCallback(
        (pk) => {
            fetch(saleAPIURL.replace('sale_pk', pk))
                .then((response) => response.json())
                .then((sale) => {
                    calculateItemLevelDiscount(sale)
                    updateSaleState(sale, false)
                    setAvailableSaleStatusMap(
                        [sale.status, ...sale.next_statuses].reduce(
                            (m, status) => ((m[status] = saleStatusMap.get(status)), m),
                            {}
                        )
                    )
                })
        },
        [saleAPIURL, saleStatusMap, updateSaleState]
    )

    const onInitialSearchFieldChange = (searchText) => {
        const disabledStockUnits =
            sale?.sale_line_items?.length > 0
                ? sale.sale_line_items.map((item) => item.stock_unit.id)
                : []

        setSaleItemSelectorDisabledStockUnits(disabledStockUnits)
        setSaleItemSelectorSearchQuery(searchText)
        setSaleItemSelectorOpen(true)
    }

    const onSearchFieldChange = (searchText) => {
        setSaleItemSelectorSearchQuery(searchText)
    }

    const hideSaleItemSelector = () => {
        setSaleItemSelectorOpen(false)
        setSaleItemSelectorSearchQuery('')
    }

    const handleCustomerAddressChange = (data) => {
        updateSaleState(data, true)
    }

    const handleShopChange = (newShop) => {
        if (newShop.id === sale?.shop?.id) {
            return
        }

        const currency = countryCurrencies[newShop.country_code]

        if (sale?.sale_line_items?.length) {
            alertPrompt(gettext('Changing the shop will remove all current items.')).then((ok) => {
                if (ok) {
                    updateSaleState({ currency, shop: newShop, sale_line_items: [] }, true)
                }
            })
        } else {
            updateSaleState({ currency, shop: newShop, sale_line_items: [] }, true)
        }
    }

    const handleSaleStatusChange = (newStatus) => {
        updateSaleState({ status: newStatus }, true)
    }

    const handleValueChange = (values) => {
        updateSaleState(values, true)
    }

    const handleSalePaymentMethodChange = (newType) => {
        const updateSaleParams = { payment_method_type: newType }
        if (['cod', 'other'].includes(newType) && sale.status === 'unpaid') {
            updateSaleParams.status = 'pending'
        }
        updateSaleState(updateSaleParams, true)
    }

    const handleSaleSummaryChange = (newData) => {
        updateSaleState(newData, true)
    }

    const handleStockUnitSelected = (stockUnit) => {
        hideSaleItemSelector()
        modifyStockUnitQuantityInSale(stockUnit, 1)
    }

    const updateSaleLineItemDiscount = (stockUnit, discount) => {
        const slis = sale.sale_line_items.map((sli) => {
            if (sli?.stock_unit?.id === stockUnit.id) {
                return {
                    ...sli,
                    item_level_discount: discount,
                }
            }
            return sli
        })

        updateSaleState(
            {
                sale_line_items: slis,
            },
            true
        )
    }

    const updateUnitPrice = (stockUnit, unitPrice) => {
        const slis = sale.sale_line_items.map((sli) => {
            if (sli?.stock_unit?.id === stockUnit.id) {
                return {
                    ...sli,
                    unit_price: unitPrice,
                }
            }
            return sli
        })

        updateSaleState(
            {
                sale_line_items: slis,
            },
            true
        )
    }

    const modifyStockUnitQuantityInSale = (stockUnit, quantityDelta) => {
        const existingSaleLineItems = sale.sale_line_items || []

        const modifySale = (stockUnit, itemPrice = false) => {
            const newSaleLineItems = modifyStockUnitQuantityInSaleLineItems(
                existingSaleLineItems,
                stockUnit,
                quantityDelta,
                itemPrice
            )

            const shipping_fee = getShippingFeeFromLineItems(sale, newSaleLineItems)
            let total_for_items = getTotalFromLineItems(newSaleLineItems)
            let discount = sale.discount || 0
            let cod_fee = sale.cod_fee || 0

            if (sale.discounts?.length) {
                discount = sale.discounts.reduce((total, item) => total + Number(item.amount), 0)
            }
            const total = total_for_items + cod_fee + shipping_fee - discount
            const newSale = {
                sale_line_items: newSaleLineItems,
                items_count: getItemsCountFromLineItems(newSaleLineItems),
                shipping_fee: shipping_fee,
                total: total,
            }

            updateSaleState(newSale, true)

            if (existingSaleLineItems.length > newSaleLineItems.length) {
                enqueueSnackbar(gettext(`Item ${stockUnit.sku} was removed`), { variant: 'info' })
            }
        }

        if (!getStockUnitInSaleLineItems(stockUnit, existingSaleLineItems)) {
            getPriceForChannelStockUnit(stockUnit, sale?.shop?.id).then((price) =>
                modifySale(stockUnit, price)
            )
        } else {
            modifySale(stockUnit)
        }
    }

    const getStockUnitInSaleLineItems = (stockUnit, saleLineItems) =>
        saleLineItems.find((item) => item?.stock_unit?.id === stockUnit.id)

    const getPriceForChannelStockUnit = (stockUnit) =>
        new Promise((resolve) => {
            const productId = stockUnit.product.id
            fetch(productAPIURL.replace('product_pk', productId))
                .then((response) => response.json())
                .then((product) => {
                    let channelProduct = product.channel_products.find((cp) => cp.shop.id)
                    let channelStockUnit = null
                    if (channelProduct) {
                        channelStockUnit = channelProduct.channel_stock_units.find(
                            (csu) => csu.stock_unit.id === stockUnit.id
                        )
                    }
                    if (!channelStockUnit) {
                        // Even if a channelStockUnit with a price is not available for the selected shop
                        // we still want to give the user a hint about what price to use, so we get it from
                        // a channelStockUnit for a different shop
                        for (let i = 0; i < product.channel_products.length; i += 1) {
                            const cp = product.channel_products[i]
                            channelStockUnit = cp.channel_stock_units.find(
                                (csu) => csu.stock_unit.id === stockUnit.id
                            )
                            if (channelStockUnit) {
                                break
                            }
                        }
                    }
                    resolve(channelStockUnit?.price)
                })
        })

    const modifyStockUnitQuantityInSaleLineItems = (
        saleLineItems,
        stockUnit,
        quantityToAdd = 1,
        stockUnitPrice
    ) => {
        // Firstly check if a saleLineItem with the stockUnit to be added already exists
        ////// const existingLineItem = getStockUnitInSaleLineItems(stockUnit, saleLineItems)
        const saleLineItemsCopy = copyObject(saleLineItems),
            existingLineItem = getStockUnitInSaleLineItems(stockUnit, saleLineItemsCopy)

        // Increase quantity on existing item by quantityToAdd. Also remove item if it now has num_sold = 0
        if (existingLineItem) {
            existingLineItem.num_sold += quantityToAdd
            return saleLineItemsCopy.filter((item) => item.num_sold)

            // Add a new line item
        } else {
            return [
                ...saleLineItemsCopy,
                {
                    stock_unit: stockUnit,
                    num_sold: quantityToAdd,
                    price_paid: stockUnitPrice,
                    price_original: stockUnitPrice,
                    unit_price: stockUnitPrice,
                    status: 'pending', // TODO - check this
                    tax_amount: '0.00', // TODO - check this
                    tax_rate: 0, // TODO - check this
                    taxable: true, // TODO - check this
                },
            ]
        }
    }

    const getItemsCountFromLineItems = (lineItems) =>
        lineItems.reduce((count, item) => count + item.num_sold, 0)

    const getTotalFromLineItems = (lineItems) =>
        lineItems.reduce(
            (total, { unit_price, price_paid, num_sold, item_level_discount }) =>
                total +
                parseFloat(unit_price || price_paid) * num_sold -
                (parseFloat(item_level_discount) || 0),
            0
        )

    const getShippingFeeFromLineItems = (sale) => {
        const isFeeSet = (sale.shipping_fee ?? null) !== null
        return isFeeSet ? sale.shipping_fee : null
    }

    const handleSaveClick = () => {
        if (!checkSaleValid(sale)) return
        if (mode === SALE_FORM_MODES.add) {
            addNewSale(sale)
        } else {
            updateSale(sale)
        }
    }

    const handleSaleCanceled = () => {
        history.push(getPath('sales'))
    }

    const handleCancelConfirmationSubmitted = () => {
        history.push(getPath('sales'))
    }

    const saleHasItems = (sale) => {
        return sale?.sale_line_items && sale.sale_line_items.length > 0
    }

    const checkSaleValid = (sale) => {
        const checks = {
            'Please select customer': (sale) => !!sale.customer,
            'Please select shipping address': (sale) => !!sale.shipping_address,
            'Please select sale status': (sale) => !!sale.status,
            'Please select payment method': (sale) => !!sale.payment_method_type,
            'Please add at least one sale item': saleHasItems,
        }
        for (let [warning, check] of Object.entries(checks)) {
            if (!check(sale)) {
                enqueueSnackbar(gettext(warning), { variant: 'error' })
                return false
            }
        }
        return true
    }

    function showResponseError(res) {
        let msg = gettext(
            'There was a problem processing the sale. If this issue persists, please contact us.'
        )

        if (res?.error?.error) {
            if (typeof res.error.error === 'string') {
                msg = res.error.error
            } else if (Array.isArray(res.error.error) && res.error.error.length > 0) {
                msg = res.error.error[0]
            }
        }

        enqueueSnackbar(msg, { variant: 'error' })
    }

    const [submitting, setSubmitting] = useState(false)

    const addNewSale = (sale) => {
        setSubmitting(true)
        post(saleCreateAPIURL, getSanitisedSaleForAdd(sale))
            .then((response) => {
                setSubmitting(false)
                if (response.ok) {
                    window.location = getPath('sale').replace('sale_id', response.id)
                } else {
                    showResponseError(response)
                }
            })
            .catch(() => {
                setSubmitting(false)
                showResponseError()
            })
    }

    const getSanitisedSaleForAdd = (sale) => getSanitisedSale(sale)

    const updateSale = (sale) => {
        setSubmitting(true)
        patch(saleAPIURL.replace('sale_pk', sale.id), getSanitisedSaleForUpdate(sale))
            .then((response) => {
                setSubmitting(false)
                if (response.ok) {
                    enqueueSnackbar(gettext('Sale is updated'), { variant: 'success' })
                    fetchSale(saleID)
                } else {
                    showResponseError(response)
                }
            })
            .catch(() => {
                setSubmitting(false)
                showResponseError()
            })
    }

    const getSanitisedSaleForUpdate = (sale) => getSanitisedSale(sale)

    const [alertDialogOpen, setAlertDialogOpen] = useState(false)
    const [alertDialogMessage, setAlertDialogMessage] = useState('')
    const [alertDialogCloseHandler, setAlertDialogCloseHandler] = useState()

    const alertPrompt = (message) => {
        return new Promise((resolve) => {
            setAlertDialogOpen(false)
            setAlertDialogMessage(message)
            setAlertDialogCloseHandler(() => (result) => {
                setAlertDialogOpen(false)
                resolve(result)
            })
            setAlertDialogOpen(true)
        })
    }

    useEffect(() => {
        if (!isAdd) {
            fetchSale(saleID)
            return
        }

        // a new, empty sale
        updateSaleState(
            {
                can_edit_items: true,
                can_edit_status: true,
                is_tax_invoice_requested: false,
            },
            false
        )
    }, [isAdd, saleID, fetchSale, updateSaleState])

    const handleFulfillmentStatusChange = () => {
        fetchSale(saleID)
    }

    if (!sale) {
        return null
    }

    return (
        <div className={classes.container}>
            <Grid alignItems="flex-start" container>
                <Grid item xs={12} className={classes.panel}>
                    <SaleHeader
                        sale={sale}
                        saleHasChanged={saleHasChanged}
                        mode={mode}
                        submitting={submitting}
                        stores={stores}
                        onShopChange={handleShopChange}
                        onSaveClick={handleSaveClick}
                        onStatusChange={handleSaleStatusChange}
                        onValueChange={handleValueChange}
                        onSaleCanceled={handleSaleCanceled}
                        onSubmitCancelConfirmation={handleCancelConfirmationSubmitted}
                        saleStatusMap={availableSaleStatusMap}
                    />
                </Grid>
                <Grid item container xs={12} md={sale.id ? 8 : 12}>
                    <Grid item xs={6} className={classNames(classes.panel, classes.middlePanel)}>
                        <SaleCustomer
                            sale={sale}
                            // editClickHandler={showCustomerEditor}
                            mode={mode}
                            onChange={handleCustomerAddressChange}
                        />
                    </Grid>
                    <Grid item xs={6} className={classNames(classes.panel, classes.middlePanel)}>
                        <SaleSummary
                            sale={sale}
                            paymentMethodDetails={paymentMethodDetails}
                            onPaymentMethodChange={handleSalePaymentMethodChange}
                            onSaleSummaryChange={handleSaleSummaryChange}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.panel}>
                        <SaleItems
                            sale={sale}
                            mode={mode}
                            language={language}
                            searchQuery={saleItemSelectorSearchQuery}
                            onSearchFieldChange={onInitialSearchFieldChange}
                            onStockUnitQuantityChange={modifyStockUnitQuantityInSale}
                            onDiscountChange={updateSaleLineItemDiscount}
                            onUnitPriceChange={updateUnitPrice}
                        />
                    </Grid>
                    {!!sale.id && (
                        <Grid item xs={12} className={classes.panel}>
                            <Fulfillments
                                fetchFulfillmentsUrl={saleFulfillmentsAPIURL}
                                fetchShippingProvidersUrl={shippingProvidersForStoreAPIURL}
                                initiateShippingUrl={fulfillmentInitiateShippingAPIURL}
                                fulfillmentShippingLabelURL={fulfillmentShippingLabelURL}
                                sale={sale}
                                fulfillmentStatuses={fulfillmentStatuses}
                                onFulfillmentStatusChange={handleFulfillmentStatusChange}
                            />
                        </Grid>
                    )}
                </Grid>
                {!!sale.id && (
                    <Grid item container xs={12} md={4}>
                        {sale?.sale_events?.length ? (
                            <Grid item xs={12} className={classes.panel}>
                                <SaleHistory
                                    saleEvents={sale.sale_events}
                                    saleStatusMap={saleStatusMap}
                                />
                            </Grid>
                        ) : undefined}
                        <Grid item xs={12} className={classes.panel}>
                            <Notes saleId={sale.id} storeId={sale?.shop?.store?.id} />
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <SaleItemSelector
                handleSelected={handleStockUnitSelected}
                open={saleItemSelectorOpen}
                handleClose={hideSaleItemSelector}
                language={language}
                sale={sale}
                searchQuery={saleItemSelectorSearchQuery}
                stockUnitsForShopAPIURL={stockUnitsForShopAPIURL}
                stockUnitsForStoreAPIURL={stockUnitsForStoreAPIURL}
                disabledStockUnits={saleItemSelectorDisabledStockUnits}
                onSearchFieldChange={onSearchFieldChange}
            />

            <AlertDialog
                message={alertDialogMessage}
                open={alertDialogOpen}
                onClose={alertDialogCloseHandler}
            />
        </div>
    )
}

export function InactiveSalePanel({ text, onClick }) {
    return (
        <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
        >
            <Button color="secondary" onClick={onClick}>
                <AddIcon />
                {text}
            </Button>
        </Box>
    )
}

function AlertDialog({
    message,
    okText = gettext('OK'),
    cancelText = gettext('Cancel'),
    open = false,
    onClose,
}) {
    return (
        <Dialog
            open={open}
            onClose={() => onClose?.(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={() => onClose?.(false)}>
                    {cancelText}
                </Button>
                <Button color="secondary" onClick={() => onClose?.(true)}>
                    {okText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
