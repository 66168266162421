import { useCallback, useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'

import { AdminToggle, PermissionLevel, Shops, Stores, ModulePermission } from './sections'
import useUserPermissions, { type ExistingPermission, type Permission } from './useUserPermissions'

import { toTitleCase } from '~/utils'

export const formatScopeName = (scope: string) => toTitleCase(scope)
export const formatShopName = ({
    shopName,
    channelName,
}: {
    shopName: string
    channelName: string
}) => `${shopName} - ${channelName}`

type Props = {
    permissionsMode: 'group' | 'account'
    targetId?: number
    showAdminToggle?: boolean
    disableAdminToggle?: boolean
    currentPermissions?: ExistingPermission[]
    onUpdatePermissions: (
        permissions: Permission[] | ((permissions: Permission[]) => Permission[])
    ) => void
}

const modules = [
    {
        name: 'Analytics',
        description: 'User can view all analytics',
        permissions: ['module.analytics'],
    },
    {
        name: 'Orders',
        description: 'User can view and manage orders',
        permissions: ['module.sales'],
    },
    {
        name: 'Products',
        description: 'User can view and manage products, stock, prices',
        permissions: ['module.products'],
    },
    {
        name: 'Admin/Settings',
        description: 'User can view and manage users and groups',
        permissions: ['module.settings'],
    }
]

export default function UserPermissions({
    permissionsMode,
    targetId,
    showAdminToggle = false,
    disableAdminToggle = false,
    currentPermissions = [],
    onUpdatePermissions,
}: Props) {
    const [permissionLevel, setPermissionLevel] = useState('')

    const {
        availablePermissions,
        hasAllPermissions,
        toggleAdminPermissions,
        checkHasPermission,
        updatePermissions,
    } = useUserPermissions({
        targetId,
        permissionLevel,
        permissions: currentPermissions,
        onUpdatePermissions,
    })

    useEffect(() => {
        setPermissionLevel(() => {
            switch (permissionsMode) {
                case 'account':
                    return hasAllPermissions ? 'account' : 'store'
                case 'group':
                    return 'store'
                default:
                    return ''
            }
        })
    }, [hasAllPermissions, permissionsMode])

    const renderPermissionSelect = useCallback(() => {
        switch (permissionLevel) {
            case 'store':
                return (
                    <Stores
                        permissions={availablePermissions.stores}
                        checkHasPermission={checkHasPermission}
                        onPermissionChanged={updatePermissions}
                    />
                )
            case 'shop':
                return (
                    <Shops
                        permissions={availablePermissions.shops}
                        checkHasPermission={checkHasPermission}
                        onPermissionChanged={updatePermissions}
                    />
                )

            default:
                return null
        }
    }, [availablePermissions, checkHasPermission, permissionLevel, updatePermissions])

    const hasModulePermissions = (permissions: string[]) => {
        return permissions.every((permission) => checkHasPermission(permission, targetId))
    }

    return (
        <Box component={TableContainer} sx={{ maxHeight: '50vh', overflow: 'auto', mt: 2 }}>
            <Table size="small">
                <TableBody>
                    {!hasAllPermissions && modules.map(m => (
                        <ModulePermission
                            key={m.name}
                            {...m}
                            checked={hasModulePermissions(m.permissions)}
                            onChange={(permissions, checked) => {
                                updatePermissions({
                                    value: checked,
                                    codenames: permissions,
                                    contentModel: 'user',
                                })
                            }}
                        />
                        )
                    )}
                </TableBody>
            </Table>

            <Table size="small">
                <TableBody>
                    {showAdminToggle && (
                        <AdminToggle
                            permissionsMode={permissionsMode}
                            checked={hasAllPermissions}
                            disabled={disableAdminToggle}
                            onChange={toggleAdminPermissions}
                        />
                    )}
                    {!hasAllPermissions && (
                        <PermissionLevel
                            value={permissionLevel}
                            onChange={({ value }) => {
                                setPermissionLevel(value)
                            }}
                        />
                    )}
                </TableBody>
            </Table>

            {!hasAllPermissions && renderPermissionSelect()}

        </Box>
    )
}