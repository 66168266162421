import React from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

import { DebouncedTextField } from '../../../oakra/components/inputs'

const styles = (theme) => ({
    dialogRoot: {
        minWidth: 600,
        minHeight: 400,
    },
    dialogContentRoot: {
        padding: '12px 30px',
    },
    numberInput: {
        width: 50,
    },
    inputField: {
        marginLeft: 8,
        marginRight: 8,
    },
})

class SettingDialog extends React.Component {
    state = {
        should_calculate_low_stock: false,
        should_calculate_slow_selling: false,
        days_till_empty_threshold: null,
        units_sold_during_period_threshold: null,
        slow_selling_period_size: null,
        apply_to: 'input_pks',
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.name.startsWith('should')
                ? event.target.checked
                : event.target.value,
        })
    }

    handleSubmit = () => {
        const state = this.state
        const { onClose, onSubmit } = this.props

        const data = {
            should_calculate_low_stock: state.should_calculate_low_stock,
            should_calculate_slow_selling: state.should_calculate_slow_selling,
        }

        if (state.should_calculate_low_stock && state.days_till_empty_threshold > 0) {
            data.days_till_empty_threshold = state.days_till_empty_threshold
        }

        if (state.should_calculate_slow_selling) {
            if (state.units_sold_during_period_threshold > 0) {
                data.units_sold_during_period_threshold = state.units_sold_during_period_threshold
            }

            if (state.slow_selling_period_size > 0) {
                data.slow_selling_period_size = state.slow_selling_period_size
            }
        }

        onSubmit({ data, apply_to: state.apply_to })
    }

    render() {
        const { classes, open, onClose } = this.props
        const state = this.state

        return (
            <Dialog open={open} classes={{ paper: classes.dialogRoot }}>
                <DialogTitle>{gettext('Bulk update alert settings')}</DialogTitle>
                <DialogContent classes={{ root: classes.dialogContentRoot }}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.should_calculate_low_stock}
                                    onChange={this.handleChange}
                                    name="should_calculate_low_stock"
                                />
                            }
                            label={gettext('Enable calculate low stock')}
                        />
                    </FormGroup>

                    {state.should_calculate_low_stock && (
                        <FormGroup row>
                            <Tooltip
                                title={gettext(
                                    'Let this field be empty if you do not want to update this threshold'
                                )}
                            >
                                <FormControlLabel
                                    control={
                                        <DebouncedTextField
                                            margin="dense"
                                            variant="outlined"
                                            type="number"
                                            value={state.days_till_empty_threshold}
                                            name="days_till_empty_threshold"
                                            onChange={this.handleChange}
                                            className={classes.inputField}
                                            inputProps={{ className: classes.numberInput }}
                                        />
                                    }
                                    label={gettext('Alert when days till empty less than')}
                                    labelPlacement="start"
                                    style={{ marginLeft: 2 }}
                                />
                            </Tooltip>
                        </FormGroup>
                    )}

                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.should_calculate_slow_selling}
                                    onChange={this.handleChange}
                                    name="should_calculate_slow_selling"
                                />
                            }
                            label={gettext('Enable calculate slow selling')}
                        />
                    </FormGroup>

                    {state.should_calculate_slow_selling && (
                        <FormGroup row>
                            <Tooltip
                                title={gettext(
                                    'Let this field be empty if you do not want to update this threshold'
                                )}
                            >
                                <FormControlLabel
                                    control={
                                        <DebouncedTextField
                                            margin="dense"
                                            variant="outlined"
                                            type="number"
                                            value={state.units_sold_during_period_threshold}
                                            name="units_sold_during_period_threshold"
                                            onChange={this.handleChange}
                                            className={classes.inputField}
                                            inputProps={{ className: classes.numberInput }}
                                        />
                                    }
                                    label={gettext('Alert when sold units less than')}
                                    labelPlacement="start"
                                    style={{ marginLeft: 2 }}
                                />
                            </Tooltip>

                            <FormControlLabel
                                control={
                                    <DebouncedTextField
                                        margin="dense"
                                        variant="outlined"
                                        type="number"
                                        value={state.slow_selling_period_size}
                                        name="slow_selling_period_size"
                                        onChange={this.handleChange}
                                        className={classes.inputField}
                                        inputProps={{ className: classes.numberInput }}
                                    />
                                }
                                label={gettext('in')}
                                labelPlacement="start"
                            />

                            <FormControlLabel
                                control={<span></span>}
                                label={gettext('days')}
                                style={{ marginLeft: 10 }}
                            />
                        </FormGroup>
                    )}

                    <FormGroup row style={{ marginTop: 16 }}>
                        <FormLabel component="legend">
                            Choose an option that you want to apply
                        </FormLabel>
                        <RadioGroup
                            name="apply_to"
                            value={state.apply_to}
                            onChange={this.handleChange}
                        >
                            <FormControlLabel
                                value="input_pks"
                                control={<Radio />}
                                label={gettext('Apply to sellected items')}
                            />
                            <FormControlLabel
                                value="all_filtered"
                                control={<Radio />}
                                label={gettext('Apply to all filtered items')}
                            />
                            <FormControlLabel
                                value="exclude_input_pks"
                                control={<Radio />}
                                label="Apply to all filtered items except the selected"
                            />
                        </RadioGroup>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleSubmit} color="primary">
                        {gettext('Apply')}
                    </Button>

                    <Button onClick={onClose} color="secondary">
                        {gettext('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(SettingDialog)
