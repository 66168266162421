import React from 'react'
import { Link } from 'react-router-dom'

import 'moment/locale/en-gb'
import 'moment/locale/th'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import { grey, red } from '@material-ui/core/colors'
import LinearProgress from '@material-ui/core/LinearProgress'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DescriptionIcon from '@material-ui/icons/Description'
import GetAppIcon from '@material-ui/icons/GetApp'
import SendIcon from '@material-ui/icons/Send'

import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import { currencySymbols } from '../../constants'
import { getPath } from '../../oakra/components/base-app'
import DateSelectButton from '../../oakra/components/date-select-button'
import FilterSelect from '../../oakra/components/filter-select'
import Select from '../../oakra/components/select'
import SnackbarAlert from '../../oakra/components/snackbar-alert'
import { debounce } from '../../tools/utils'
import ShippingInfoManager from '../containers/shipping-info-manager'

import SaleCSVExportSelector from './sale-csv-export-selector'



const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    toolbar: {
        position: 'fixed',
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
    },
    tableHeader: {
        borderTop: '1px solid ' + grey[200],
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell: {
        padding: '3px 12px',
        wordWrap: 'break-word',
        fontSize: '0.85rem',
    },
    storeSelector: {
        width: '150px',
    },
    searchBar: {
        width: 400,
    },
    generateLabelDialog: {
        textAlign: 'center',
    },
    generateLabels: {
        alignSelf: 'flex-end',
    },
    generateLabelProgress: {},
    noSalesMessage: {
        textAlign: 'center',
        padding: 75,
    },
    noSalesText: {
        color: grey[400],
    },
    saleRow: {},
    imgPlaceholder: {
        height: 40,
    },
    tableText: {
        fontSize: '0.85rem',
    },
    saleLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    saleImage: {
        width: 40,
    },
    saleTotal: {
        textAlign: 'center',
    },
    saleAmount: {},
    numItems: {
        color: grey[600],
    },
    shippingColumn: {
        textAlign: 'center',
    },
    locationText: {},
    status: {
        textAlign: 'center',
    },
    statusUpdatedTime: {
        color: grey[600],
    },
    statusDisplayName: {},
    channel: {
        textAlign: 'center',
    },
    channelIcon: {
        alignSelf: 'flex-start',
        width: 25,
    },
    shipByDateColumn: {
        textAlign: 'center',
    },
    shipByDate: {
        color: grey[600],
        border: `1px solid ${grey[300]}`,
        borderRadius: '4px',
        fontSize: 10,
        padding: 3,
    },
    shipByDateOverdue: {
        border: `1px solid ${red[100]}`,
        color: red[400],
    },
    pagingInfo: {
        height: 48,
        padding: '14px 32px',
        textAlign: 'right',
    },
    tableContainer: {
        maxHeight: 'calc(100vh - 170px)',
    },
})

const columns = [
    { id: 'image', label: '', sort: false, centerAlign: false, width: 70 },
    {
        id: 'sale_id',
        label: gettext('Sale ID'),
        sort: false,
        centerAlign: false,
        minWidth: 100,
    },
    {
        id: 'location',
        label: gettext('Location'),
        sort: false,
        centerAlign: false,
        minWidth: 100,
    },
    {
        id: 'shop_name',
        label: gettext('Shop name'),
        sort: false,
        centerAlign: false,
        minWidth: 100,
    },
    {
        id: 'sale_total',
        label: gettext('Sale total'),
        sort: false,
        centerAlign: true,
        width: 110,
    },
    {
        id: 'shipping_provider',
        label: gettext('Shipping'),
        sort: false,
        centerAlign: true,
        width: 130,
    },
    {
        id: 'is_tax_invoice_requested',
        label: gettext('Tax Invoice'),
        sort: false,
        centerAlign: true,
        width: 95,
    },
    {
        id: 'status',
        label: gettext('Status'),
        sort: false,
        centerAlign: true,
        minWidth: 100,
    },
    {
        id: 'channel',
        label: gettext('Channel'),
        sort: false,
        centerAlign: true,
        width: 90,
    },
]

const orderByOptions = [
    { value: '-date_created', label: gettext('Latest first') },
    { value: 'date_created', label: gettext('Earliest first') },
    { value: '-ship_by_date', label: gettext('Latest first - Ship By') },
    { value: 'ship_by_date', label: gettext('Earlier first - Ship By') },
]

class SaleTableHeadWithoutStyle extends React.PureComponent {
    render() {
        const { onSelectAllClick, selectAllState, classes } = this.props

        return (
            <TableHead className={classes.tableHeader}>
                <TableRow>
                    <TableCell className={classes.tableCell} style={{ width: 60 }}>
                        <Checkbox
                            indeterminate={selectAllState === 'indeterminate'}
                            checked={selectAllState === 'checked'}
                            onChange={onSelectAllClick}
                            size="small"
                        />
                    </TableCell>
                    {columns.map((column) => {
                        return (
                            <TableCell
                                key={column.id}
                                padding={column.disablePadding ? 'none' : 'default'}
                                style={{
                                    textAlign: column.centerAlign && 'center',
                                    width: column.width ? column.width : 'auto',
                                    minWidth: column.minWidth ? column.minWidth : 0,
                                }}
                                className={classes.tableCell}
                            >
                                {column.label}
                            </TableCell>
                        )
                    }, this)}
                </TableRow>
            </TableHead>
        )
    }
}

SaleTableHeadWithoutStyle.propTypes = {
    selectAllState: PropTypes.string.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
}

const SaleTableHead = withStyles(styles)(SaleTableHeadWithoutStyle)

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 67,
        zIndex: 3,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },
    title: {
        flex: '0 1 auto',
        flexWrap: 'wrap',
    },
    toolbarItems: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 10,
        alignItems: 'center',
        marginBottom: 7,
        marginRight: 10,
    },
    toolbarTextField: {},
    orderSearch: {
        width: 150,
        marginTop: 2.5,
        marginBottom: 2.5,
        marginRight: 7,
        marginLeft: 7,
    },
    productSearch: {
        width: 150,
        marginTop: 2.5,
        marginBottom: 2.5,
        marginRight: 7,
    },
    filters: {
        marginTop: 10,
        marginBottom: 10,
        flexWrap: 'wrap',
    },
    clearFilter: {
        fontSize: 13,
        marginLeft: 7,
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'flex',
        flex: '0 0 auto',
    },
    selectActionButton: {
        alignSelf: 'center',
        flexShrink: 0,
    },
    topPagination: {
        marginLeft: 15,
    },
    paginationActions: {
        marginLeft: 5,
    },
    paginationToolbar: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    numTotal: {
        fontSize: 12,
        marginLeft: 4,
        color: grey[600],
    },
    chronoOrderSwitch: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
})

class SaleTableToolbarWithoutStyle extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectActionAnchorEl: null,
            dateButtonText: gettext('Past 2 weeks'),
            orderBy: '-date_created',
        }

        this.handleOpenSelectAction = this.handleOpenSelectAction.bind(this)
        this.handleCloseSelectAction = this.handleCloseSelectAction.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
        this.handleOpenShippingInfoManager = this.handleOpenShippingInfoManager.bind(this)
    }

    handleOrderByChange = (option) => {
        this.setState(
            {
                orderBy: option.value,
            },
            () => {
                this.props.handleSort(this.state.orderBy)
            }
        )
    }
    handleOpenSelectAction(event) {
        this.setState({ selectActionAnchorEl: event.currentTarget })
    }

    handleCloseSelectAction() {
        this.setState({ selectActionAnchorEl: null })
    }

    handleOpenShippingInfoManager() {
        return () => {
            this.setState({ selectActionAnchorEl: null }, () => {
                this.props.handleOpenShippingInfoManager()
            })
        }
    }

    handleDateChange(dateStart, dateEnd, buttonText) {
        this.props.handleDateTimeChange(dateStart, dateEnd)
        this.setState({ dateButtonText: buttonText })
    }

    render() {
        const {
            numSelected,
            numTotal,
            handleShipSelected,
            handleExportSelected,
            searchHandler,
            idValueHandler,
            classes,
            handleExportSales,
            shipSelectedSalesEnabled,
            shipAllCurrentFilterEnabled,
        } = this.props
        const { handleFilterChange, handleClearFilters } = this.props
        const { filterOptions, filterValues } = this.props
        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                {this.props.loading && (
                    <LinearProgress className={classes.progress} color="secondary" />
                )}
                <div className={classes.title}>
                    {numSelected > 0 ? (
                        <Typography color="inherit" variant="subtitle1">
                            {interpolate(pgettext('Number selected', '%s selected'), [numSelected])}
                        </Typography>
                    ) : (
                        <div className={classes.toolbarItems}>
                            <DateSelectButton
                                startDate={filterValues.date_created_after}
                                endDate={filterValues.date_created_before}
                                handleDateChange={this.handleDateChange}
                                initialButtonText={this.state.dateButtonText}
                                disabled={filterValues.id_value != ''}
                            />
                            <TextField
                                id="id_value"
                                type="search"
                                className={classes.orderSearch}
                                value={filterValues.id_value}
                                margin="normal"
                                onChange={idValueHandler}
                                variant="outlined"
                                placeholder={gettext('Order/Tracking ID')}
                                InputProps={{
                                    classes: {
                                        root: classes.toolbarTextField,
                                    },
                                    style: { height: 30, fontSize: 13 },
                                }}
                                inputProps={{ style: { paddingLeft: 9 } }}
                            />
                            <TextField
                                id="search"
                                type="search"
                                className={classes.productSearch}
                                value={filterValues.search}
                                margin="normal"
                                disabled={filterValues.id_value != ''}
                                onChange={searchHandler}
                                variant="outlined"
                                placeholder={gettext('SKU/Product Name')}
                                InputProps={{
                                    classes: {
                                        root: classes.toolbarTextField,
                                    },
                                    style: { height: 30, fontSize: 13 },
                                }}
                                inputProps={{ style: { paddingLeft: 9 } }}
                            />
                            {filterOptions.map((field) => (
                                <FilterSelect
                                    key={field.lookup}
                                    field={field}
                                    disabled={filterValues.id_value != ''}
                                    value={filterValues[field.lookup]}
                                    handleFilterChange={handleFilterChange}
                                />
                            ))}
                            <Button className={classes.clearFilter} onClick={handleClearFilters}>
                                {gettext('Clear filters')}
                            </Button>
                            {numTotal > 0 && (
                                <span className={classes.numTotal}>
                                    {interpolate(gettext('%s orders'), [numTotal])}
                                </span>
                            )}
                        </div>
                    )}
                </div>
                <div className={classes.actions}>
                    {numSelected > 0 ? (
                        <React.Fragment>
                            <Button color="secondary" onClick={handleExportSelected}>
                                {gettext('Export selected')}
                            </Button>
                            <Button
                                color="secondary"
                                onClick={handleShipSelected}
                                disabled={!shipSelectedSalesEnabled}
                            >
                                {gettext('Ship selected')}
                            </Button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div style={{ display: 'block', textAlign: 'right', padding: 10 }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.selectActionButton}
                                    aria-owns={
                                        this.state.selectActionAnchorEl ? 'actions-menu' : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={this.handleOpenSelectAction}
                                >
                                    {gettext('Actions')}
                                    <ArrowDropDownIcon />
                                </Button>

                                <Select
                                    key="sortBy"
                                    defaultValue="-date_created"
                                    containerStyle={{ marginRight: 0, marginTop: 5 }}
                                    width={175}
                                    isMulti={false}
                                    isClearable={false}
                                    options={orderByOptions}
                                    value={orderByOptions.find(o => o.value === this.state.orderBy)}
                                    placeholder={gettext("Order by")}
                                    onChange={this.handleOrderByChange}
                                />
                            </div>

                            <Menu
                                id="actions-menu"
                                anchorEl={this.state.selectActionAnchorEl}
                                open={Boolean(this.state.selectActionAnchorEl)}
                                onClose={this.handleCloseSelectAction}
                            >
                                <MenuItem
                                    onClick={this.handleOpenShippingInfoManager()}
                                    disabled={!shipAllCurrentFilterEnabled}
                                >
                                    <ListItemIcon>
                                        <SendIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={gettext('Ship current filter orders')} />
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        this.setState({ selectActionAnchorEl: null })
                                        handleExportSales()
                                    }}
                                >
                                    <ListItemIcon>
                                        <GetAppIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={gettext('Export sales')} />
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to={getPath('sale').replace('sale_id', 'new')}
                                >
                                    <ListItemIcon>
                                        <DescriptionIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={gettext('New sale')} />
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )}
                </div>
            </Toolbar>
        )
    }
}

SaleTableToolbarWithoutStyle.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
}

const SaleTableToolbar = withStyles(toolbarStyles)(SaleTableToolbarWithoutStyle)

class Sales extends React.PureComponent {
    constructor(props) {
        super(props)

        moment.locale(props.language)

        this.state = {
            selected: new Set(),
            rowsPerPage: 50,
            sales: [],
            count: 0,
            page: 0,
            maxPageFetched: 0,
            pendingCount: 0,
            loading: true,
            shippingInfoManagerOpen: false,
            filterOptions: [],
            filterValues: {
                date_created_after: moment().subtract(13, 'days'),
                date_created_before: moment(),
                id_value: '',
                search: '',
            },
            dateMissingSnackbarOpen: false,
            exportSelectorOpen: false,
            exportParamsString: '',
            pageWithAllSelect: {},

            isSelectAll: false,
            selectedSalePKs: new Set(),
            unselectedSalePKs: new Set(),
            selectionAnchorIndex: -1,
            orderBy: null,
        }

        this.salesContainerRef = React.createRef()

        this.filtersToQueryString = this.filtersToQueryString.bind(this)
        this.fetchSaleData = this.fetchSaleData.bind(this)
        this.fetchFilterOptions = this.fetchFilterOptions.bind(this)
        this.getDataURL = this.getDataURL.bind(this)
        this.handleOpenShippingInfoManager = this.handleOpenShippingInfoManager.bind(this)
        this.handleCloseShippingInfoManager = this.handleCloseShippingInfoManager.bind(this)
        this.handleSort = this.handleSort.bind(this)
        this.handleShipSelected = this.handleShipSelected.bind(this)
        this.handleExportSelected = this.handleExportSelected.bind(this)
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this)
        this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
        this.searchHandler = this.searchHandler.bind(this)
        this.idValueHandler = this.idValueHandler.bind(this)
        this.isSelected = this.isSelected.bind(this)
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.clearFilters = this.clearFilters.bind(this)
        this.handleDateTimeChange = this.handleDateTimeChange.bind(this)
        this.fetchSaleDataDebounced = debounce(this.fetchSaleData, 500)
        this.handleExportSelectorClose = this.handleExportSelectorClose.bind(this)
        this.handleExportSelectorOpen = this.handleExportSelectorOpen.bind(this)
        this.getExportFormats = this.getExportFormats.bind(this)
        this.handleTableScroll = debounce(this.handleTableScroll, 100)
    }

    componentDidMount(prevProps) {
        this.fetchFilterOptions(() => {
            this.fetchSaleData(this.getDataURL(), false, 0)
        })
    }

    filtersToQueryString(filters = null) {
        let filtersToUse = filters || this.state.filterValues

        filtersToUse = filtersToUse.id_value ? { id_value: filtersToUse.id_value } : filtersToUse

        return Object.entries(filtersToUse)
            .filter(([, option]) => Boolean(option))
            .map(([fieldLookup, option]) => {
                if (moment.isMoment(option)) {
                    return `${fieldLookup}=${encodeURIComponent(option.format('YYYY-MM-DD'))}`
                } else {
                    return `${fieldLookup}=${encodeURIComponent(option.value || option)}`
                }
            })
            .join('&')
    }

    fetchSaleData(url, replace, page = null, filtersToUse = null) {
        if (!url.includes('?')) {
            const query = this.filtersToQueryString(filtersToUse)
            if (query) {
                url += '?' + query
            }
        }
        if (this.state.orderBy && !url.includes('order_by')) {
            if (url.includes('?')) {
                url += '&order_by=' + this.state.orderBy
            } else {
                url += '?order_by=' + this.state.orderBy
            }
        }

        return fetch(url).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    let sales
                    if (replace) {
                        sales = data.results
                    } else {
                        sales = this.state.sales.concat(data.results)
                    }

                    const newState = {
                        count: data.count,
                        next: data.next,
                        previous: data.previous,
                        sales: sales,
                        loading: false,
                    }

                    if (page === 0) {
                        newState['page'] = 0
                        newState['maxPageFetched'] = 0
                        newState['pageWithAllSelect'] = {}
                    } else if (page > 0) {
                        newState['page'] = page
                    }

                    this.setState(newState)
                })
            }
        })
    }

    fetchFilterOptions(callback) {
        const { salesFilterValuesAPIURL, accountOwnerID } = this.props
        let url = salesFilterValuesAPIURL.replace('user_pk', accountOwnerID)
        const { date_created_after, date_created_before } = this.state.filterValues
        url += `?date_created_after=${date_created_after.format('YYYY-MM-DD')}&date_created_before=${date_created_before.format('YYYY-MM-DD')}`

        fetch(url).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const filterValues = { ...this.state.filterValues }
                    data.forEach((field) => {
                        if (field.lookup == 'status') {
                            const pendingOption = field.values.find((v) => v.value == 'pending')
                            filterValues[field.lookup] = pendingOption
                        } else {
                            filterValues[field.lookup] = null
                        }
                    })
                    this.setState(
                        {
                            filterOptions: data,
                            filterValues,
                        },
                        callback
                    )
                })
            }
        })
    }

    getDataURL() {
        // In the future this should fetch sales for shops
        // the user has permission for
        const { accountOwnerID, salesAPIURL } = this.props
        return salesAPIURL.replace('user_pk', accountOwnerID)
    }

    handleExportSelected() {
        this.setState({ exportSelectorOpen: true })
    }

    handleShipSelected() {
        this.handleOpenShippingInfoManager()
    }

    handleOpenShippingInfoManager() {
        this.setState({
            shippingInfoManagerOpen: true,
            salesFilter: this.getSalesFilter(),
        })
    }

    handleCloseShippingInfoManager() {
        this.fetchSaleData(this.getDataURL(), true, 0)

        this.setState({
            shippingInfoManagerOpen: false,
        })
    }

    handleSort(orderBy) {
        if (this.salesContainerRef.current) {
            // reset scroll to top
            this.salesContainerRef.current.scrollTop = 0
        }
        this.setState(
            {
                orderBy,
                loading: true,
            },
            () => {
                this.fetchSaleData(this.getDataURL(), true, 0)
            }
        )
    }

    handleSelectAllClick(event, checked) {
        const { isSelectAll } = this.state
        this.setState({
            isSelectAll: !isSelectAll,
            selectedSalePKs: new Set(),
            unselectedSalePKs: new Set(),
            selectionAnchorIndex: -1,
        })
    }

    handleCheckboxClick(event, saleID, rowIndex) {
        const checked = event.target.checked
        const { selectedSalePKs, unselectedSalePKs, isSelectAll, selectionAnchorIndex } = this.state
        if (isSelectAll) {
            if (checked) {
                unselectedSalePKs.delete(saleID)
            } else {
                unselectedSalePKs.add(saleID)
            }
            this.setState({ unselectedSalePKs: new Set(unselectedSalePKs) })
        } else {
            const newState = {}
            if (event.shiftKey) {
                if (selectionAnchorIndex >= 0) {
                    // range selection
                    selectedSalePKs.clear()
                    const startIndex = Math.min(selectionAnchorIndex, rowIndex)
                    const stopIndex = Math.max(selectionAnchorIndex, rowIndex)
                    this.state.sales.forEach((sale, index) => {
                        if (index >= startIndex && index <= stopIndex) {
                            selectedSalePKs.add(sale.id)
                        }
                    })
                }
            } else {
                if (checked) {
                    selectedSalePKs.add(saleID)
                    newState.selectionAnchorIndex = rowIndex
                } else {
                    selectedSalePKs.delete(saleID)
                    newState.selectionAnchorIndex = -1
                }
            }
            this.setState({ ...newState, selectedSalePKs: new Set(selectedSalePKs) })
        }
    }

    handleChangePage(page) {
        let pageChangeURL

        if (page > this.state.page) {
            pageChangeURL = this.state.next
        } else {
            pageChangeURL = this.state.previous
        }

        const needsFetch = page > this.state.maxPageFetched

        if (needsFetch) {
            this.setState(
                {
                    maxPageFetched: page,
                    loading: true,
                },
                () => {
                    this.fetchSaleData(pageChangeURL, false, page)
                }
            )
        } else {
            this.setState({
                page: page,
            })
        }
    }

    loadNextPage = () => {
        const { page, next } = this.state
        if (next) {
            const nextPage = page + 1
            this.handleChangePage(nextPage)
        }
    }

    isSelected(salePK) {
        const { selectedSalePKs, unselectedSalePKs, isSelectAll } = this.state
        if (isSelectAll) {
            return !unselectedSalePKs.has(salePK)
        }
        return selectedSalePKs.has(salePK)
    }

    searchHandler(event) {
        const searchTerm = event.target.value
        const newFilters = { ...this.state.filterValues, search: searchTerm }
        this.setState({ filterValues: newFilters })
        this.fetchSaleDataDebounced(this.getDataURL(), true, 0, newFilters)
    }

    idValueHandler(event) {
        const idValue = event.target.value
        const newFilters = { ...this.state.filterValues, id_value: idValue }
        this.setState({ filterValues: newFilters })
        this.fetchSaleDataDebounced(this.getDataURL(), true, 0, newFilters)
    }

    handleFilterChange(field) {
        return (option) => {
            if (this.salesContainerRef.current) {
                // reset scroll to top
                this.salesContainerRef.current.scrollTop = 0
            }
            const filterValues = { ...this.state.filterValues }
            if (option) {
                filterValues[field.lookup] = option
            } else {
                delete filterValues[field.lookup]
            }

            this.setState(
                {
                    filterValues,
                    page: 0,
                },
                () => {
                    this.fetchSaleData(this.getDataURL(), true, 0)
                }
            )
        }
    }

    clearFilters(refresh = true) {
        const filterValues = { ...this.state.filterValues }
        const noClear = ['date_created_after', 'date_created_before']
        const textFields = ['search', 'id_value']

        Object.keys(filterValues).forEach((fieldLookup) => {
            if (!noClear.includes(fieldLookup))
                filterValues[fieldLookup] = textFields.includes(fieldLookup) ? '' : null
        })

        this.setState(
            {
                filterValues,
                page: 0,
            },
            () => {
                refresh && this.fetchSaleData(this.getDataURL(), true, 0)
            }
        )
    }

    handleDateTimeChange(startDate, endDate) {
        const filterValues = { ...this.state.filterValues }
        filterValues['date_created_after'] = startDate
        filterValues['date_created_before'] = endDate
        this.setState({ filterValues, page: 0 }, () => {
            this.fetchFilterOptions()
            this.fetchSaleData(this.getDataURL(), true, 0)
        })
    }

    handleExportSelectorOpen() {
        this.setState({
            exportSelectorOpen: true,
            salesFilter: this.getSalesFilter(),
        })
    }

    handleExportSelectorClose() {
        const newState = { exportSelectorOpen: false }
        this.setState(newState)
    }

    getExportFormats() {
        return this.props.saleCSVFormats
    }

    handleTableScroll = () => {
        const salesContainerRef = this.salesContainerRef
        if (salesContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = salesContainerRef.current
            const scrollPercent = (2 * clientHeight - scrollHeight + scrollTop) / clientHeight
            if (scrollPercent >= 0.6 && !this.state.loading) {
                // triggered after scroll over 60% of the table
                this.loadNextPage()
            }
        }
    }

    getSalesFilter = () => {
        const { selectedSalePKs, unselectedSalePKs } = this.state

        let filter
        if (selectedSalePKs && selectedSalePKs.size > 0) {
            filter = { included_sale_pks: Array.from(selectedSalePKs) }
        } else {
            filter = queryString.parse(this.filtersToQueryString(), {parseBooleans: true})
            if (unselectedSalePKs && unselectedSalePKs.size > 0) {
                filter['excluded_sale_pks'] = Array.from(unselectedSalePKs)
            }
        }
        return filter
    }

    getSelectedCount = () => {
        const { selectedSalePKs, unselectedSalePKs, isSelectAll, count } = this.state
        if (isSelectAll) {
            return count - unselectedSalePKs.size
        }
        return selectedSalePKs.size
    }

    render() {
        const { classes, language, staticRoot, exportSalesURL } = this.props
        const {
            rowsPerPage,
            count,
            sales,
            shippingInfoManagerOpen,
            filterValues,
            isSelectAll,
            selectedSalePKs,
            unselectedSalePKs,
            salesFilter,
            exportSelectorOpen,
        } = this.state
        const hasMultiplePages = count > rowsPerPage
        const displayedSales = sales

        const currentTime = moment()

        let shipSelectedSalesEnabled = false
        if (isSelectAll) {
            shipSelectedSalesEnabled = filterValues.status?.value === 'pending'
        } else {
            shipSelectedSalesEnabled =
                selectedSalePKs.size > 0 &&
                sales
                    .filter((sale) => selectedSalePKs.has(sale.id))
                    .every((sale) => sale.status === 'pending')
        }

        const shipAllCurrentFilterEnabled = filterValues.status?.value === 'pending'

        const isLastPage = !this.state.next
        let selectAllState = 'unchecked'
        if (isSelectAll) {
            selectAllState = unselectedSalePKs.size === 0 ? 'checked' : 'indeterminate'
        }

        return (
            <Paper className={classes.root}>
                {shippingInfoManagerOpen && (
                    <ShippingInfoManager
                        open={shippingInfoManagerOpen}
                        handleCloseShippingInfoManager={this.handleCloseShippingInfoManager}
                        salesFilter={salesFilter}
                    />
                )}
                <SaleTableToolbar
                    className={classes.toolbar}
                    numSelected={this.getSelectedCount()}
                    shipSelectedSalesEnabled={shipSelectedSalesEnabled}
                    shipAllCurrentFilterEnabled={shipAllCurrentFilterEnabled}
                    stores={this.props.stores}
                    handleShipSelected={this.handleShipSelected}
                    handleExportSelected={this.handleExportSelectorOpen}
                    searchHandler={this.searchHandler}
                    idValueHandler={this.idValueHandler}
                    numTotal={this.state.count}
                    page={this.state.page}
                    loading={this.state.loading}
                    shippingInfoManagerOpen={shippingInfoManagerOpen}
                    handleOpenShippingInfoManager={this.handleOpenShippingInfoManager}
                    filterOptions={this.state.filterOptions}
                    filterValues={this.state.filterValues}
                    handleFilterChange={this.handleFilterChange}
                    handleClearFilters={this.clearFilters}
                    handleDateTimeChange={this.handleDateTimeChange}
                    handleClearDateTime={this.handleClearDateTime}
                    handleExportSales={this.handleExportSelectorOpen}
                    handleSort={this.handleSort}
                    language={language}
                />
                <TableContainer
                    className={classes.tableContainer}
                    onScroll={this.handleTableScroll}
                    ref={this.salesContainerRef}
                >
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <SaleTableHead
                            className={classes.tableHead}
                            onSelectAllClick={this.handleSelectAllClick}
                            selectAllState={selectAllState}
                        />
                        <TableBody>
                            {!this.state.loading && this.state.sales.length == 0 && (
                                <TableRow>
                                    <TableCell
                                        colSpan={9}
                                        className={classNames(
                                            classes.noSalesMessage,
                                            classes.tableCell
                                        )}
                                    >
                                        <img
                                            className={classes.noSalesIcon}
                                            src={staticRoot + 'web/img/order.png'}
                                        />
                                        <Typography
                                            className={classes.noSalesText}
                                            variant="subtitle1"
                                        >
                                            {gettext('No orders to display')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}

                            {displayedSales.map((sale, rowIndex) => {
                                const isSelected = this.isSelected(sale.id)
                                const saleTotal = sale.total || 0.0
                                const saleLink = getPath('sale').replace('sale_id', sale.id)

                                return (
                                    <TableRow
                                        className={classes.saleRow}
                                        hover
                                        role="checkbox"
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        key={sale.id}
                                        selected={isSelected}
                                    >
                                        <TableCell padding="checkbox" className={classes.tableCell}>
                                            <Checkbox
                                                checked={isSelected}
                                                onClick={e => this.handleCheckboxClick(e, sale.id, rowIndex)}
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Link to={saleLink} className={classes.saleLink}>
                                                <img
                                                    className={classes.saleImage}
                                                    src={sale.cover_image_url}
                                                    height={40}
                                                />
                                            </Link>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Link to={saleLink} className={classes.saleLink}>
                                                <Typography
                                                    className={classes.tableText}
                                                    variant="body2"
                                                >
                                                    {sale.sale_id}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Link
                                                to={saleLink}
                                                className={classes.saleLink}
                                                target="_blank"
                                            >
                                                <Typography
                                                    className={classNames(
                                                        classes.tableText,
                                                        classes.locationText
                                                    )}
                                                    variant="body2"
                                                >
                                                    {sale.shipping_address
                                                        ? `${sale.shipping_address.city} - ${sale.shipping_address.province}`
                                                        : `${sale.city} - ${sale.province}`}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Link to={saleLink} className={classes.saleLink}>
                                                <Typography
                                                    className={classes.tableText}
                                                    variant="body2"
                                                >
                                                    {sale.shop.shop_name}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={classNames(
                                                classes.saleTotal,
                                                classes.tableCell
                                            )}
                                        >
                                            <Link to={saleLink} className={classes.saleLink}>
                                                <Typography
                                                    className={classNames(
                                                        classes.saleAmount,
                                                        classes.tableText
                                                    )}
                                                    variant="body2"
                                                >
                                                    {currencySymbols[sale.currency] +
                                                        (+saleTotal).toFixed(2)}
                                                </Typography>
                                                <Typography
                                                    className={classNames(
                                                        classes.numItems,
                                                        classes.tableText
                                                    )}
                                                    variant="body2"
                                                >
                                                    {interpolate(
                                                        ngettext(
                                                            '%s item',
                                                            '%s items',
                                                            +sale.items_count
                                                        ),
                                                        [+sale.items_count]
                                                    )}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={classNames(
                                                classes.shippingColumn,
                                                classes.tableCell
                                            )}
                                        >
                                            <Link to={saleLink} className={classes.saleLink}>
                                                <Typography className={classes.tableText}>
                                                    {sale.shipping_provider}
                                                </Typography>

                                                {!!sale.ship_by_date && (
                                                    <Typography
                                                        component="span"
                                                        className={classNames(
                                                            classes.tableText,
                                                            classes.shipByDate,
                                                            {
                                                                [classes.shipByDateOverdue]:
                                                                    currentTime >=
                                                                    moment(sale.ship_by_date),
                                                            }
                                                        )}
                                                        variant="body2"
                                                    >
                                                        {gettext('Before')}{' '}
                                                        {moment(sale.ship_by_date).format('D MMM')}
                                                    </Typography>
                                                )}
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={classNames(
                                                classes.channel,
                                                classes.tableCell
                                            )}
                                        >
                                            {sale.is_tax_invoice_requested && (
                                                <CheckCircleIcon color="secondary" />
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={classNames(
                                                classes.status,
                                                classes.tableCell
                                            )}
                                        >
                                            <Link to={saleLink} className={classes.saleLink}>
                                                <Typography
                                                    className={classNames(
                                                        classes.tableText,
                                                        classes.statusDisplayName
                                                    )}
                                                >
                                                    {sale.status_display_name}
                                                </Typography>
                                                <Typography
                                                    className={classNames(
                                                        classes.tableText,
                                                        classes.statusUpdatedTime
                                                    )}
                                                    variant="body2"
                                                >
                                                    {moment(sale.date_updated).format(
                                                        'D MMM HH:mm'
                                                    )}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={classNames(
                                                classes.channel,
                                                classes.tableCell
                                            )}
                                        >
                                            <Link to={saleLink} className={classes.saleLink}>
                                                <img
                                                    className={classes.channelIcon}
                                                    src={sale.channel_square_icon_url}
                                                />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}

                            {hasMultiplePages && (
                                <TableRow>
                                    <TableCell colSpan={9}>
                                        <div style={{ textAlign: 'center' }}>
                                            {isLastPage ? (
                                                <span>{gettext('All data loaded.')}</span>
                                            ) : (
                                                <CircularProgress size={28} />
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <SnackbarAlert
                    open={this.state.dateMissingSnackbarOpen}
                    severity="info"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {gettext('Please select a date range')}
                </SnackbarAlert>
                <SaleCSVExportSelector
                    open={exportSelectorOpen}
                    formats={this.getExportFormats()}
                    onComplete={this.handleExportSelectorClose}
                    onClose={this.handleExportSelectorClose}
                    exportURL={exportSalesURL}
                    staticRoot={staticRoot}
                    salesFilter={salesFilter}
                />
            </Paper>
        )
    }
}

export default withStyles(styles)(Sales)
