import Select from 'react-select'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useTheme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    exclusiveText: {
        fontSize: '0.85rem',
        marginLeft: -5,
        color: theme.palette.grey[600],
    },
}))

function FilterSelect({ field, value, handleFilterChange, handleFilterSelectExclusive, disabled }) {
    const theme = useTheme()
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Select
                key={field.lookup}
                defaultValue={field.defaultValues || null}
                isMulti={field.select_multiple}
                isClearable={true}
                isDisabled={disabled}
                options={field.values}
                value={value}
                placeholder={field.field}
                onChange={handleFilterChange(field)}
                styles={{
                    container: (provided) => ({
                        ...provided,
                        marginRight: 7,
                        marginTop: 2.5,
                        marginBottom: 2.5,
                        fontSize: 13,
                    }),
                    control: (provided, state) => ({
                        ...provided,
                        minHeight: 30,
                        width: 150,
                        borderColor: state.isFocused ? theme.palette.primary.main : 'hsl(0,0%,80%)',
                        boxShadow: state.isFocused ? theme.palette.primary.main : 'none',
                        '&:hover': {
                            borderColor: theme.palette.primary.main,
                        },
                    }),
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        padding: 4,
                    }),
                    clearIndicator: (provided) => ({
                        ...provided,
                        padding: 4,
                    }),
                    valueContainer: (provided) => ({
                        ...provided,
                        padding: '0px 6px',
                    }),
                    input: (provided) => ({
                        ...provided,
                        margin: 0,
                        padding: 0,
                    }),
                    placeholder: (provided) => ({
                        ...provided,
                        paddingTop: 1,
                    }),
                    menu: (provided) => ({
                        ...provided,
                        zIndex: 3,
                    }),
                }}
            />
            {field.can_select_exclusive && (
                <FormControlLabel
                    classes={{
                        root: classes.exclusiveSelectContainer,
                        label: classes.exclusiveText,
                    }}
                    control={
                        <Checkbox
                            checked={field.exclusive}
                            onChange={handleFilterSelectExclusive(field)}
                            size="small"
                        />
                    }
                    label={gettext('Exclusive')}
                />
            )}
        </div>
    )
}

export default FilterSelect
