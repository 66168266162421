import { useState, useEffect, useCallback, useMemo } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'

import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import Dialog from '../../../components/Dialog'
import NonFieldErrors from '../../../components/NonFieldErrors'
import * as groupService from '../../../services/groups.service'
import { buildErrorProps } from '../../../utils'
import { getApiErrorMessage } from '../../../utils/apiClient'
import { UserPermissions } from '../Users/user-permissions'

import GroupMembers from './group-members'

import { api, queryKeys } from '~/api'

export default function GroupDetail({
    accountOwnerId,
    isCreateGroup = true,
    groupId = undefined,
    open = false,
    onClose,
}) {
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()

    const [activeTab, setActiveTab] = useState('members')

    const [group, setGroup] = useState({
        id: undefined,
        name: '',
        members: [],
        is_admin: false,
    })
    const { data } = api.groups.detail.useQuery(
        queryKeys.groups.detail(groupId).queryKey,
        { params: { id: groupId } },
        { enabled: !!groupId && !isCreateGroup }
    )
    useEffect(() => {
        if (data?.body) {
            setGroup(data.body)
        }
    }, [data?.body])

    const [selectedPermissions, setSelectedPermissions] = useState([])
    const { data: permissionsData } = api.groups.permissions.useQuery(
        queryKeys.groups.permissions(groupId).queryKey,
        { params: { id: groupId } },
        { enabled: !!groupId && !isCreateGroup }
    )
    useEffect(() => {
        if (permissionsData?.body) {
            setSelectedPermissions(permissionsData.body)
        }
    }, [permissionsData?.body])

    const permissionsModified = useMemo(() => {
        // If no permission data, assume it is a new group and return true
        if (!permissionsData) {
            return true
        }

        const currentPermissions = permissionsData.body.map(({ codename }) => codename)
        const newPermissions = selectedPermissions.map(({ codename }) => codename)

        return (
            currentPermissions.length !== newPermissions.length ||
            currentPermissions.some((codename) => !newPermissions.includes(codename))
        )
    }, [permissionsData, selectedPermissions])

    const [saving, setSaving] = useState(false)
    const [errors, setErrors] = useState([])

    function handleNameChange(e) {
        setGroup({ ...group, name: e.target.value })
    }

    async function saveGroup() {
        if (!group?.name) {
            console.error('Group name is required')
            return
        }

        setSaving(true)

        try {
            let record
            if (isCreateGroup) {
                const newGroup = await groupService.createGroup(accountOwnerId, group.name)
                record = newGroup
            } else {
                const { members: _members, ...data } = group
                const updatedGroup = await groupService.updateGroupById(group.id, data)
                record = updatedGroup
            }

            const queriesToInvalidate = [
                queryClient.invalidateQueries({
                    queryKey: queryKeys.groups.detail(groupId).queryKey,
                }),
            ]

            if (permissionsModified) {
                await groupService.updateGroupPermissions(record.id, selectedPermissions)
                queriesToInvalidate.push(
                    queryClient.invalidateQueries({
                        queryKey: queryKeys.groups.permissions(groupId).queryKey,
                    })
                )
            }

            await queryClient.invalidateQueries(queriesToInvalidate)
            setGroup(record)
            onClose(record)
            enqueueSnackbar(gettext('Group successfully updated'), { variant: 'success' })
        } catch (err) {
            console.error(err)
            setErrors((value) => [...value, getApiErrorMessage(err)])
        } finally {
            setSaving(false)
        }
    }

    const renderTab = useCallback(() => {
        switch (activeTab) {
            case 'members':
                return <GroupMembers group={group} members={group.members} />
            case 'permissions':
                return (
                    <UserPermissions
                        disableAdminToggle
                        targetId={group.id}
                        permissionsMode="group"
                        showAdminToggle={group?.is_admin}
                        currentPermissions={selectedPermissions}
                        onUpdatePermissions={setSelectedPermissions}
                    />
                )
            default:
                return null
        }
    }, [activeTab, group, selectedPermissions])

    return (
        <Dialog
            open={open}
            fullScreen={false}
            title={isCreateGroup ? gettext('Create group') : gettext('Edit group')}
            actions={
                <>
                    <Button onClick={() => onClose(null)} color="secondary">
                        {gettext('Cancel')}
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={saving}
                        startIcon={saving && <CircularProgress size={14} color="secondary" />}
                        onClick={saveGroup}
                    >
                        {isCreateGroup ? gettext('Create group') : gettext('Save')}
                    </Button>
                </>
            }
        >
            {Array.isArray(errors) && errors.length > 0 && <NonFieldErrors errors={errors} />}
            <Box sx={{ mb: 3 }}>
                <TextField
                    fullWidth
                    label={gettext('Name')}
                    placeholder={gettext('Name')}
                    color="secondary"
                    margin="dense"
                    value={group?.name || ''}
                    onChange={handleNameChange}
                    disabled={group?.is_admin}
                    {...buildErrorProps(errors, 'name')}
                />
            </Box>
            <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)}>
                <Tab label="Members" value="members" selected={activeTab === 'members'} />
                <Tab
                    label="Permissions"
                    value="permissions"
                    selected={activeTab === 'permissions'}
                />
            </Tabs>

            {renderTab()}
        </Dialog>
    )
}

GroupDetail.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isCreateGroup: PropTypes.bool,
    accountOwnerId: PropTypes.number,
    groupId: PropTypes.number,
}
