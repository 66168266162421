import { useMemo } from 'react'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import {
    useReactTable,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
} from '@tanstack/react-table'

import { type ApiResponses } from '~/api'

type User = Extract<ApiResponses['users']['accountUserListing'], { status: 200 }>['body'][0]

type Props = {
    users: Array<User>
    onEdit?: (user: User) => void
    onDelete?: (user: User) => void
}

const columnHelper = createColumnHelper<User>()

export default function UserList({ users, onEdit, onDelete }: Props) {
    const columns = useMemo(
        () => [
            columnHelper.accessor((row) => `${row?.first_name || ''} ${row?.last_name || ''}`, {
                header: gettext('Name'),
            }),
            columnHelper.accessor('username', { header: gettext('Username') }),
            columnHelper.accessor('email', { header: gettext('Email') }),
            columnHelper.display({
                id: 'Edit',
                size: 80,
                header: () => <Box textAlign="center">{gettext('Edit')}</Box>,
                cell: ({ row }) => (
                    <IconButton onClick={() => onEdit?.(row.original)}>
                        <EditIcon />
                    </IconButton>
                ),
            }),
            columnHelper.display({
                id: 'Delete',
                size: 80,
                header: () => <Box textAlign="center">{gettext('Delete')}</Box>,
                cell: ({ row }) => (
                    <IconButton onClick={() => onDelete?.(row.original)}>
                        <DeleteIcon />
                    </IconButton>
                ),
            }),
        ],
        [onEdit, onDelete]
    )

    const table = useReactTable({
        data: users,
        columns,
        defaultColumn: { size: 0, minSize: 0 },
        getCoreRowModel: getCoreRowModel(),
        getRowId: (row) => row.id.toString(),
    })

    return (
        <TableContainer>
            <Table aria-label="User table" size="small">
                <TableHead>
                    {table.getHeaderGroups().map((group) => (
                        <TableRow key={group.id}>
                            {group.headers.map((header) => (
                                <TableCell key={header.id} width={header.getSize() || undefined}>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map((row) => (
                        <TableRow key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
