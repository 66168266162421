import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Dropzone from 'react-dropzone'

import Backdrop from '@material-ui/core/Backdrop'
import grey from '@material-ui/core/colors/grey'
import Fade from '@material-ui/core/Fade'
import LinearProgress from '@material-ui/core/LinearProgress'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import classNames from 'classnames'
import Cookie from 'js-cookie'
import PropTypes from 'prop-types'

const styles = (theme) => ({
    imageManager: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
    },
    dropzone: {
        height: 80,
        width: 150,
        backgroundColor: grey[100],
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        overflow: 'hidden',
    },
    dropzoneActive: {
        backgroundColor: grey[400],
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
    },
    dropMessage: {
        fontSize: '1.2em',
    },
    promptMessage: {
        fontSize: '0.8rem',
        marginLeft: 5,
        textAlign: 'center',
    },
    preview: {
        display: 'flex',
        paddingLeft: 15,
        marginLeft: 15,
    },
    previewImageContainer: {
        position: 'relative',
        outline: 'none',
        marginRight: 15,
        '&:hover .action-icon': {
            visibility: 'visible',
        },
    },
    deleteIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        cursor: 'pointer',
        visibility: 'hidden',
    },
    enlargeIcon: {
        position: 'absolute',
        right: 30,
        top: 0,
        cursor: 'pointer',
        visibility: 'hidden',
        fontSize: 28,
    },
    previewImage: {
        height: 80,
        cursor: 'pointer',
    },
    progress: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
    },
})

const reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

const copyImages = (images) => {
    const newImages = JSON.parse(JSON.stringify(images))

    newImages.forEach((image) => {
        const file = images.find((i) => i.name == image.name).file
        image.file = file
    })

    return newImages
}

const imageStatus = {
    READY_TO_UPLOAD: 'ready_to_upload',
    UPLOADING: 'uploading',
    UPLOADED: 'uploaded',
}

class ImageManager extends React.Component {
    constructor(props) {
        super(props)

        this.getUploadURLs = this.getUploadURLs.bind(this)
        this.onDrop = this.onDrop.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.handleDeleteImage = this.handleDeleteImage.bind(this)
        this.uploadImages = this.uploadImages.bind(this)

        this.state = {
            enlargeImage: null,
            showEnlargeImageModal: false,
        }
    }

    componentDidUpdate(prevProps) {
        const { images } = this.props

        if (prevProps.images.length != images.length) {
            const imagesToUpload = images.filter(
                (image) => image.status == imageStatus.READY_TO_UPLOAD
            )

            this.uploadImages(imagesToUpload)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(nextProps.images) != JSON.stringify(this.props.images)) {
            return true
        }

        if (nextProps.disabled != this.props.disabled) {
            return true
        }

        if (nextState.showEnlargeImageModal !== this.state.showEnlargeImageModal) {
            return true
        }

        return false
    }

    onDrop(newImages) {
        const { maxImages, excludeImages } = this.props

        if (maxImages && this.props.images.length + newImages.length > maxImages) {
            return
        }

        let alreadyAddedImageNames = this.props.images.map((i) => i.name)

        if (excludeImages) {
            alreadyAddedImageNames += excludeImages.map((i) => i.name)
        }

        const newImageObjs = newImages
            .filter((image) => !alreadyAddedImageNames.includes(image.name))
            .map((image) => ({
                name: image.name,
                preview: URL.createObjectURL(image),
                display_order: image.display_order,
                type: image.type,
                status: imageStatus.READY_TO_UPLOAD,
                file: image,
            }))

        let images = copyImages(this.props.images)
        images = images.concat(newImageObjs)

        this.props.imagesChangedHandler(images)
    }

    onDragEnd(result) {
        if (!result.destination) {
            return
        }

        const images = reorder(this.props.images, result.source.index, result.destination.index)

        this.props.imagesChangedHandler(images)
    }

    handleDeleteImage(index) {
        return () => {
            let images = copyImages(this.props.images)
            images.splice(index, 1)
            this.props.imagesChangedHandler(images)
        }
    }

    getUploadURLs(imagesData, callback) {
        const { uploadImageSignatureAPIURL, storeID } = this.props

        fetch(uploadImageSignatureAPIURL.replace('store_id', storeID), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRFToken': Cookie.get('csrftoken'),
            },
            body: JSON.stringify(imagesData),
        })
            .then((response) => response.json())
            .then((uploadURLs) => {
                callback(uploadURLs)
            })
            .catch((error) => {
                console.log(`Error getting upload urls for ${imagesData}`)
            })
    }

    uploadImages(imagesToUpload) {
        const { imagesChangedHandler } = this.props

        const infoForFiles = imagesToUpload.map((image) => ({
            filename: image.name,
            content_type: image.type,
        }))

        this.getUploadURLs(infoForFiles, (uploadURLs) => {
            const images = copyImages(this.props.images)

            images
                .filter((image) => image.status == imageStatus.READY_TO_UPLOAD)
                .forEach((image) => {
                    image.status = imageStatus.UPLOADING
                })

            imagesChangedHandler(images, () => {
                imagesToUpload.forEach((imageToUpload) => {
                    const uploadURL = uploadURLs.find(
                        (urlInfo) => urlInfo.original_filename == imageToUpload.name
                    )

                    fetch(uploadURL.presigned_url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': imageToUpload.type,
                        },
                        body: imageToUpload.file,
                    })
                        .then((response) => {
                            const images = copyImages(this.props.images)
                            const image = images.find((i) => i.name == imageToUpload.name)
                            image.status = imageStatus.UPLOADED
                            image.image = uploadURL.filename
                            imagesChangedHandler(images)
                        })
                        .catch((error) => {
                            console.log(`Image upload error for ${imageToUpload.name}`)
                            console.log(error)

                            const images = copyImages(this.props.images)
                            const imageIndex = images.findIndex((i) => i.name == imageToUpload.name)
                            images.splice(imageIndex, 1)
                            imagesChangedHandler(images)
                        })
                })
            })
        })
    }

    handleShowEnlargeImage = (image) => {
        this.setState({
            enlargeImage: image.preview || this.props.staticFilesDomain + image.image,
            showEnlargeImageModal: true,
        })
    }

    render() {
        const { classes, staticFilesDomain, disabled } = this.props
        const { showEnlargeImageModal, enlargeImage } = this.state

        return (
            <div className={classes.imageManager}>
                {!disabled && (
                    <Dropzone onDrop={this.onDrop} maxSize={5000000} accept="image/jpeg, image/png">
                        {({ getRootProps, getInputProps, isDragActive }) => (
                            <div
                                className={classNames(classes.dropzone, {
                                    [classes.dropzoneActive]: isDragActive,
                                })}
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div className={classes.message}>
                                    {isDragActive ? (
                                        <Typography className={classes.dropMessage} variant="body2">
                                            {gettext('Drop images here')}
                                        </Typography>
                                    ) : (
                                        <React.Fragment>
                                            <AddIcon />
                                            <Typography
                                                className={classes.promptMessage}
                                                variant="body2"
                                            >
                                                {gettext(
                                                    'Drop image files here or click to select files'
                                                )}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                )}
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div
                                className={classes.preview}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {this.props.images.map((image, index) => (
                                    <Draggable
                                        key={image.name || image.id}
                                        draggableId={String(image.name || image.id)}
                                        isDragDisabled={disabled}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                className={classes.previewImageContainer}
                                                key={String(image.name || image.id)}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <CancelIcon
                                                    className={classNames(
                                                        'action-icon',
                                                        classes.deleteIcon
                                                    )}
                                                    onClick={this.handleDeleteImage(index)}
                                                />
                                                <img
                                                    className={classes.previewImage}
                                                    src={
                                                        image.preview ||
                                                        staticFilesDomain + image.image
                                                    }
                                                    onClick={() =>
                                                        this.handleShowEnlargeImage(image)
                                                    }
                                                />
                                                {image.status == imageStatus.UPLOADING && (
                                                    <LinearProgress
                                                        className={classes.progress}
                                                        color="secondary"
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={showEnlargeImageModal}
                    onClose={() => this.setState({ showEnlargeImageModal: false })}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={showEnlargeImageModal}>
                        <div className={classes.paper}>
                            {enlargeImage && (
                                <img
                                    src={enlargeImage}
                                    style={{ maxWidth: '90vw', maxHeight: '90vh' }}
                                />
                            )}
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}

ImageManager.propTypes = {
    storeID: PropTypes.number.isRequired,
    images: PropTypes.array.isRequired,
    excludeImages: PropTypes.array,
    imagesChangedHandler: PropTypes.func.isRequired,
    maxImages: PropTypes.number,
    disabled: PropTypes.bool,
}

ImageManager.defaultProps = {
    disabled: false,
}

export default withStyles(styles)(ImageManager)
