import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'

import AddressInfo from '../../../customers/components/address-info'
import CustomerInfo from '../../../customers/components/cutomer-info'
import AddressManagerDialog from '../../../customers/containers/address-manager-dialog'
import SearchDialog from '../../../customers/containers/search-dialog'

const styles = (theme) => ({
    dialog: {},
    dialogContent: {
        minHeight: 330,
    },
    dialogActions: {
        paddingRight: 24,
        paddingLeft: 24,
        paddingBottom: 15,
        marginTop: 0,
    },

    customer: {
        padding: '2px 2px 24px 2px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        marginBottom: 12,
    },
    addresses: {},
    address: {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 8,
        padding: '2px 12px 12px 12px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },

    actionBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        // fontWeight: 500,
    },
    selectAddress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
})

const addressFields = {
    shipping_address: gettext('Shipping address'),
    billing_address: gettext('Billing address'),
    invoice_address: gettext('Invoice address'),
}

class CustomerSummary extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showSearchDialog: false,
            selectingAddress: null,
        }
    }

    handleClose = () => {
        this.props.onClose()
    }

    handleSearchDialogClose = (data) => {
        this.setState({
            showSearchDialog: false,
        })

        if (!data) return

        const {
            default_shipping_address,
            default_billing_address,
            default_invoice_address,
            ...customer
        } = data

        this.props.onChange({
            customer,
            shipping_address: default_shipping_address,
            billing_address: default_billing_address,
            invoice_address: default_invoice_address,
        })
    }

    handleAddressSelect = (data) => {
        if (!data) return

        const { selectingAddress } = this.state

        this.props.onChange({
            [selectingAddress]: data,
        })

        this.setState({ selectingAddress: null })
    }

    render() {
        const { showSearchDialog, selectingAddress } = this.state
        const {
            classes,
            data,
            editableFields = ['customer', 'shipping_address', 'billing_address', 'invoice_address'],
        } = this.props
        const { customer } = data

        return (
            <Dialog
                open
                fullWidth
                maxWidth="md"
                className={classes.dialog}
                onClose={this.handleClose}
            >
                <DialogTitle>{gettext('Select customer and addresses')}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="subtitle1">{gettext('Customer info')}</Typography>

                            {editableFields.includes('customer') && (
                                <Button
                                    color="secondary"
                                    style={{ margin: '-4px 0 0 12px' }}
                                    onClick={() => this.setState({ showSearchDialog: true })}
                                >
                                    {gettext('Change')}
                                </Button>
                            )}
                        </div>

                        <div className={classes.customer}>
                            {customer && <CustomerInfo data={customer} />}

                            {showSearchDialog && (
                                <SearchDialog onClose={this.handleSearchDialogClose} />
                            )}
                        </div>

                        <Typography variant="subtitle1" style={{ marginBottom: 12 }}>
                            {gettext('Addresses for sale')}
                        </Typography>

                        <div className={classes.addresses}>
                            <Grid container spacing={2}>
                                {Object.keys(addressFields).map((k, index) => {
                                    const address = data[k]

                                    return (
                                        <Grid key={index} item xs={12} sm={4}>
                                            <div className={classes.address}>
                                                <div className={classes.actionBar}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        className={classes.title}
                                                    >
                                                        {addressFields[k]}
                                                    </Typography>

                                                    {address && editableFields.includes(k) && (
                                                        <Button
                                                            color="secondary"
                                                            onClick={() =>
                                                                this.setState({
                                                                    selectingAddress: k,
                                                                })
                                                            }
                                                        >
                                                            {gettext('Change')}
                                                        </Button>
                                                    )}
                                                </div>

                                                {address && <AddressInfo data={address} />}

                                                {!address && editableFields.includes(k) && (
                                                    <div className={classes.selectAddress}>
                                                        <Button
                                                            color="secondary"
                                                            onClick={() =>
                                                                this.setState({
                                                                    selectingAddress: k,
                                                                })
                                                            }
                                                        >
                                                            <AddIcon /> {gettext('Select address')}
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </Grid>
                                    )
                                })}
                            </Grid>

                            {!!selectingAddress && (
                                <AddressManagerDialog
                                    allowSelect
                                    customerId={customer.id}
                                    dialogTitle={`Select address to be ${addressFields[
                                        selectingAddress
                                    ].toLowerCase()}`}
                                    onSelect={this.handleAddressSelect}
                                    onClose={() => this.setState({ selectingAddress: null })}
                                />
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button variant="contained" color="secondary" onClick={this.handleClose}>
                        {gettext('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

CustomerSummary = withStyles(styles)(CustomerSummary)

export default CustomerSummary
