import Box from '@material-ui/core/Box'
import Switch from '@material-ui/core/Switch'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import PropTypes from 'prop-types'

import { useStyles } from './styles'

export default function AdminToggle({
    permissionsMode = '',
    checked = false,
    disabled = false,
    onChange,
}) {
    const classes = useStyles()
    return (
        <TableRow>
            <TableCell className={classes.tableCell}>
                <div className={classes.tableCellContainer}>
                    <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                        {gettext('Give Admin Permissions')}
                    </Typography>
                    <Typography variant="subtitle2" style={{ marginRight: 15 }}>
                        {gettext(`Allow this ${permissionsMode} to perform all system actions`)}
                    </Typography>
                </div>
            </TableCell>
            <TableCell>
                <Box display="flex" alignItems="end" flexDirection="column">
                    <Switch
                        checked={checked}
                        disabled={disabled}
                        onChange={(_, checked) => onChange(checked)}
                    />
                </Box>
            </TableCell>
        </TableRow>
    )
}

AdminToggle.propTypes = {
    permissionsMode: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
}
