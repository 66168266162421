import { memo, useMemo, useRef } from 'react'

import ArrowForward from '@mui/icons-material/ArrowRight'
import Box from '@mui/material/Box'

import { useProductPageArrowContainerRef } from '../hooks/useProductPageStore'

export default memo(function ErrorPointer() {
    const arrowSize = 64
    const arrowContainer = useProductPageArrowContainerRef()
    const ref = useRef<HTMLDivElement>(null)
    const parent = ref.current?.parentElement

    const boundingRect = arrowContainer?.getBoundingClientRect() ?? { left: 0, top: 0 }
    const parentBoundingRect = parent?.getBoundingClientRect() ?? { left: 0, top: 0 }
    const adjustedArrowPositionX = (arrowSize * 3) / 4
    const adjustedArrowPositionY = useMemo(() => {
        let position = 16
        if (arrowContainer) {
            const name = arrowContainer.getAttribute('data-form-name')
            // Adjust the arrow position for the logistics.value table row, it should be slightly lower than the default position
            if (name?.includes('logistics.value')) {
                position = 0
            }
        }

        return position
    }, [arrowContainer])

    return (
        <Box
            ref={ref}
            sx={{
                display: arrowContainer ? 'block' : 'none',
                position: 'absolute',
                color: (theme) => theme.palette.error.main,
                animation: 'bounce 2s ease-in-out infinite',
                zIndex: (theme) => theme.zIndex.modal,
                left: boundingRect.left - parentBoundingRect.left - adjustedArrowPositionX,
                top: boundingRect.top - parentBoundingRect.top - adjustedArrowPositionY,
                '@keyframes bounce': {
                    '0%': {
                        transform: 'translateX(0)',
                    },
                    '50%': {
                        transform: 'translateX(-16px)',
                    },
                    '100%': {
                        transform: 'translateX(0)',
                    },
                },
            }}
        >
            <ArrowForward sx={{ fontSize: arrowSize }} />
        </Box>
    )
})
