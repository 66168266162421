import { type ChangeEvent, useCallback, useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'

import PricingTable from './active-discount-table'

import { type ChannelStockUnitDiscount } from '~/common/schemas/product'
import { DebouncedTextField } from '~/oakra/components/inputs'
import { type ProductFormSchema, useProductFormContext } from '~/products/hooks/useProductForm'

const FIELD_WIDTH = 125

export const usePricingStyles = makeStyles((theme) => ({
    pricesErrorMessage: {
        color: theme.palette.error.main,
        marginLeft: 15,
        fontSize: '0.9rem',
    },
    pricingSection: {
        marginTop: 25,
        width: 'auto',
    },
    pricingSectionTitle: {
        marginBottom: 20,
    },
    pricingRow: {
        display: 'flex',
        alignItems: 'center',
    },
    setAllRow: {
        marginBottom: 15,
        paddingBottom: 15,
        borderBottom: '1px solid lightgray',
    },
    setAllMessage: {
        marginRight: 15,
        width: 265,
        color: 'gray',
    },
    priceField: {
        height: 35,
    },
    pricingDisabledMessage: {
        marginTop: 15,
        marginBottom: 15,
        fontSize: '0.9rem',
        color: 'grey',
    },
}))

export default function ActiveDiscountPricing() {
    const classes = usePricingStyles()

    const { control, getValues, setValue } = useProductFormContext()
    const stockUnits = useWatch({ control, name: 'stock_units', exact: true })
    const channelProducts = useWatch({
        control,
        name: 'channel_products',
        exact: true,
        defaultValue: [],
    })
    const productDiscounts = useWatch({
        control,
        name: 'product_discounts',
        exact: true,
        defaultValue: [],
    })
    const activeCSUDs = useWatch({
        control,
        name: 'active_channel_stock_unit_discounts',
        exact: true,
        defaultValue: [],
    })

    const channelProductWithDiscounts = useMemo(() => {
        const channelProductIdsWithDiscount = activeCSUDs.reduce((cpSet, csud) => {
            if (cpSet.has(csud.channel_product_id)) {
                return cpSet
            }
            cpSet.add(csud.channel_product_id)
            return cpSet
        }, new Set<number>())

        const shopIds = new Set<number>()
        return channelProducts.filter((cp) => {
            if (!cp.shop) return false
            if (shopIds.has(cp.shop.id) || !channelProductIdsWithDiscount.has(Number(cp.id))) {
                return false
            }
            shopIds.add(cp.shop.id)
            return true
        })
    }, [channelProducts, activeCSUDs])

    const discountIndexById = useMemo(
        () => Object.fromEntries(productDiscounts.map((d, index) => [d.discount.id, index])),
        [productDiscounts]
    )

    const activeDiscountIndexLookup = useMemo(() => {
        const map = new Map()
        activeCSUDs.forEach((csud: ChannelStockUnitDiscount, index: number) => {
            const key = `${csud.shop_id}_${csud.stock_unit_id}`
            const value = map.get(key) ?? []
            map.set(key, [...value, index])
        })
        return map
    }, [activeCSUDs])

    const discountIndexByCSUId = useMemo(
        () =>
            Object.fromEntries(
                activeCSUDs.map((csud) => [
                    csud.channel_stock_unit_id,
                    discountIndexById[csud.discount_id],
                ])
            ),
        [activeCSUDs, discountIndexById]
    )

    const handleSetPriceForAll = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = parseFloat(e.target.value)
            if (isNaN(value)) {
                return
            }

            getValues('active_channel_stock_unit_discounts')?.forEach((csud, index) => {
                setValue(`active_channel_stock_unit_discounts.${index}.price`, e.target.value)
            })
        },
        [getValues, setValue]
    )

    const handleSetPriceForChannelProduct = useCallback(
        (cp) => (e: ChangeEvent<HTMLInputElement>) => {
            const value = parseFloat(e.target.value)
            if (isNaN(value)) {
                return
            }

            cp.channel_stock_units.forEach(
                (
                    csu: ProductFormSchema['channel_products'][number]['channel_stock_units'][number]
                ) => {
                    if (csu.placeholder) {
                        return
                    }
                    const indexes: number[] =
                        activeDiscountIndexLookup.get(`${cp.shop.id}_${csu.stock_unit?.id}`) ?? []
                    indexes.forEach((index) => {
                        setValue(
                            `active_channel_stock_unit_discounts.${index}.price`,
                            e.target.value
                        )
                    })
                }
            )
        },
        [setValue, activeDiscountIndexLookup]
    )

    const handleSetPriceForStockUnits = useCallback(
        (sku: string) => (e: ChangeEvent<HTMLInputElement>) => {
            const value = parseFloat(e.target.value)
            if (isNaN(value)) {
                return
            }

            getValues('channel_products').forEach((cp) => {
                cp.channel_stock_units.forEach((csu) => {
                    if (csu.placeholder || csu.stock_unit?.sku !== sku) {
                        return
                    }

                    const indexes: number[] =
                        activeDiscountIndexLookup.get(`${cp.shop.id}_${csu.stock_unit?.id}`) ?? []
                    indexes.forEach((index) => {
                        setValue(
                            `active_channel_stock_unit_discounts.${index}.price`,
                            e.target.value
                        )
                    })
                })
            })
        },
        [getValues, setValue, activeDiscountIndexLookup]
    )

    if (!activeCSUDs || activeCSUDs.length === 0) {
        return <div style={{ padding: '20px 0' }}>{gettext('No active discount')}</div>
    }

    return (
        <div>
            <div className={classes.pricingSection}>
                {(stockUnits.length > 1 || channelProductWithDiscounts.length > 1) && (
                    <div className={classNames(classes.pricingRow, classes.setAllRow)}>
                        <div>
                            <Typography className={classes.setAllMessage} variant="body2">
                                {gettext('Use this field to set all prices at once')}
                            </Typography>
                        </div>

                        <Box width={FIELD_WIDTH}>
                            <DebouncedTextField
                                type="number"
                                variant="outlined"
                                margin="dense"
                                placeholder={gettext('Set all')}
                                InputProps={{ className: classes.priceField }}
                                onChange={handleSetPriceForAll}
                                inputProps={{ min: 0 }}
                            />
                        </Box>
                    </div>
                )}

                <PricingTable
                    channelProducts={channelProductWithDiscounts}
                    stockUnits={stockUnits}
                    fieldWidth={FIELD_WIDTH}
                    activeDiscountIndexLookup={activeDiscountIndexLookup}
                    discountIndexByCSUId={discountIndexByCSUId}
                    onSetPriceForChannelProduct={handleSetPriceForChannelProduct}
                    onSetPriceForStockUnits={handleSetPriceForStockUnits}
                />
            </div>
        </div>
    )
}
