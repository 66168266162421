import { z } from 'zod'

export const makeTranslationsSchema = <T extends z.ZodType<Record<string, string | null>>>(
    schema: T
) => {
    return z.record(schema)
}

export const Translations = makeTranslationsSchema(
    z.object({
        description: z.string().nullish(),
        name: z.nullable(z.string()).transform((value) => value ?? ''),
        short_description: z.string().nullish(),
    })
)
export type Translations = z.infer<typeof Translations>
