import React from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import red from '@material-ui/core/colors/red'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import Alert from '@material-ui/lab/Alert'

import { post } from '../../../tools/request'

const styles = (theme) => ({
    dialogRoot: {
        minWidth: 400,
        minHeight: 200,
    },
    loadingIcon: {
        verticalAlign: 'middle',
        marginRight: 8,
    },
    alert: {
        color: theme.palette.error.main,
        border: `1px solid ${red[100]}`,
        paddingTop: 2,
        paddingBottom: 2,
    },
    button: {
        height: 27,
        fontSize: '0.85em',
    },
})

class SaleCancelConfirmation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            cancel_rejected_reason:
                props.saleCancelRejectedReasons?.length > 0
                    ? props.saleCancelRejectedReasons[0].value
                    : null,
            showDialog: false,
            error: null,
            isSubmittingAccept: false,
            isSubmittingReject: false,
        }
    }

    handleChange = (e) => {
        const { value } = e.target
        this.setState({ cancel_rejected_reason: value })
    }

    handleSubmitReject = () => {
        const { cancel_rejected_reason } = this.state
        const { saleId, submitSaleCancelConfirmationAPIURL, onSubmitConfirmation } = this.props

        if (!cancel_rejected_reason) {
            this.setState({ error: gettext('Please select cancellation rejected reason') })
            return
        }

        this.setState({ isSubmittingReject: true, error: null })

        post(submitSaleCancelConfirmationAPIURL.replace('sale_pk', saleId), {
            accept: false,
            cancel_rejected_reason,
        }).then((response) => {
            this.setState({ isSubmittingReject: false })

            if (response.ok) {
                this.setState({ showDialog: false })
                onSubmitConfirmation && onSubmitConfirmation({ accept: false })
            } else {
                this.setState({ error: response.error })
            }
        })
    }

    handleAccept = () => {
        const { saleId, submitSaleCancelConfirmationAPIURL, onSubmitConfirmation } = this.props

        this.setState({ isSubmittingAccept: true, error: null })

        post(submitSaleCancelConfirmationAPIURL.replace('sale_pk', saleId), {
            accept: true,
        }).then((response) => {
            this.setState({ isSubmittingAccept: false })

            if (response.ok) {
                onSubmitConfirmation && onSubmitConfirmation({ accept: true })
            } else {
                this.setState({ error: response.error })
            }
        })
    }

    handleReject = () => {
        this.setState({
            showDialog: true,
        })
    }

    handleClose = () => {
        this.setState({ showDialog: false, error: null })
    }

    render() {
        const {
            cancel_rejected_reason,
            error,
            isSubmittingReject,
            isSubmittingAccept,
            showDialog,
        } = this.state
        const { classes, saleCancelRejectedReasons } = this.props
        const buttons = (
            <React.Fragment>
                {isSubmittingAccept && (
                    <CircularProgress className={classes.loadingIcon} color="secondary" size={16} />
                )}

                <Button
                    color="secondary"
                    className={classes.button}
                    disabled={isSubmittingAccept}
                    onClick={this.handleReject}
                >
                    {gettext('Reject Cancel')}
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    style={{ marginLeft: 8 }}
                    disabled={isSubmittingAccept}
                    onClick={this.handleAccept}
                >
                    {gettext('Accept Cancel')}
                </Button>
            </React.Fragment>
        )
        return (
            <React.Fragment>
                <Alert
                    severity="error"
                    classes={{ standardError: classes.alert }}
                    action={buttons}
                    icon={<ReportProblemIcon />}
                >
                    {gettext('Customer has requested this order be canceled')}
                </Alert>

                <Dialog open={showDialog} classes={{ paper: classes.dialogRoot }}>
                    <DialogTitle>{gettext('Select cancellation rejected reason')}</DialogTitle>

                    <DialogContent>
                        {error && (
                            <Alert severity="error" style={{ marginBottom: 10 }}>
                                {error}
                            </Alert>
                        )}

                        <TextField
                            select
                            size="small"
                            variant="outlined"
                            value={cancel_rejected_reason}
                            style={{ width: '100%' }}
                            placeholder={gettext('Select cancellation rejected reason')}
                            onChange={this.handleChange}
                        >
                            {saleCancelRejectedReasons.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </DialogContent>

                    <DialogActions>
                        {isSubmittingReject && (
                            <CircularProgress
                                className={classes.loadingIcon}
                                color="secondary"
                                size={16}
                            />
                        )}

                        <Button
                            onClick={this.handleClose}
                            color="secondary"
                            size="small"
                            disabled={isSubmittingReject}
                        >
                            {gettext('Close')}
                        </Button>

                        <Button
                            onClick={this.handleSubmitReject}
                            variant="contained"
                            color="secondary"
                            size="small"
                            disabled={isSubmittingReject}
                        >
                            {gettext('Submit')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(SaleCancelConfirmation)
