import { useState } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'

import Dialog from './Dialog'

const useStyles = makeStyles((theme) => ({
    deleteButton: {
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
    },
    spinner: {
        marginRight: 5,
    },
}))

export default function DialogDeleteConfirm({ open, onConfirm, onClose, title, children }) {
    const classes = useStyles()
    const [deleting, setDeleting] = useState(false)

    const handleDelete = async () => {
        try {
            setDeleting(true)
            await onConfirm()
            setDeleting(false)
            onClose(true)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Dialog
            open={open}
            title={title || gettext('Confirm delete')}
            actions={
                <>
                    {deleting && (
                        <CircularProgress className={classes.spinner} size={26} color="secondary" />
                    )}
                    <Button
                        onClick={() => {
                            onClose(false)
                        }}
                        color="secondary"
                        disabled={deleting}
                    >
                        {gettext('Cancel')}
                    </Button>
                    <Button
                        className={classes.deleteButton}
                        onClick={handleDelete}
                        variant="outlined"
                        disabled={deleting}
                    >
                        {gettext('Delete')}
                    </Button>
                </>
            }
        >
            {children}
        </Dialog>
    )
}

DialogDeleteConfirm.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    actions: PropTypes.node.isRequired,
    fullScreen: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
