import { Suspense, lazy } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import AssessmentIcon from '@material-ui/icons/Assessment'
import DescriptionIcon from '@material-ui/icons/Description'
import ListAltIcon from '@material-ui/icons/ListAlt'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import StoreIcon from '@material-ui/icons/Store'
import SwapCallsIcon from '@material-ui/icons/SwapCalls'

import Dashboard from '../../analytics/containers/dashboard'
import Reports from '../../analytics/containers/reports'
import Exports from '../../exports/containers/exports'
import PickPackBatch from '../../locations/containers/pick-pack-batch'
import PickPackBatches from '../../locations/containers/pick-pack-batches'
import ChannelInstructions from '../../platforms/containers/channel-instructions'
import ChannelPicker from '../../platforms/containers/channel-picker'
import ChannelResult from '../../platforms/containers/channel-result'
import Integrations from '../../platforms/containers/integrations'
import ProductImportIssues from '../../platforms/containers/product-import-issues'
import StorePicker from '../../platforms/containers/store-picker'
import Products from '../../products/components/products'
import MergeRecommendations from '../../products/containers/merge-recommendations'
import PriceManager from '../../products/containers/price-manager'
import StockReport from '../../products/containers/stock-report'
import Discount from '../../promotions/containers/discount'
import Discounts from '../../promotions/containers/discounts'
import InitShipping from '../../sales/components/init-shipping'
import Sale from '../../sales/containers/sale'
import Sales from '../../sales/containers/sales'
import Settings from '../../settings/containers/settings'
import StockAdjustmentGroup from '../../stock-adjustment-groups/containers/group'
import StockAdjustmentGroups from '../../stock-adjustment-groups/containers/groups'

import { BaseApp, FrameWithSidebar, PATHS } from './base-app'

import { JaymartSettings } from '~/integrations/jaymart/components/settings'
import AccessDeniedPage from '~/pages/access-denied'
import HomePage from '~/pages/home'
import ProductEditPage from '~/pages/product-edit'
import ProductCreatePage from '~/pages/product-new'
import ChannelStockBreakdownPage from '~/pages/stock/channel-stock-breakdown'
import StockManagerPage from '~/pages/stock/stock-manager'
import { useAppSelector } from '~/store'
import { ThemeProvider } from '~/theme-v5'
import { getPath } from '~/utils/getPath'

const ChatPage = lazy(() => import('~/pages/chat'))

const navSections = [
    {
        permission: 'module.analytics',
        root: '/home/dashboard',
        to: '/home/dashboard/overview',
        icon: AssessmentIcon,
        label: gettext('Analytics'),
        subItems: [
            {
                to: '/home/dashboard/overview',
                label: gettext('Overview'),
            },
            {
                to: '/home/dashboard/reports',
                label: gettext('Reports'),
            },
        ],
    },
    {
        permission: 'module.sales',
        root: '/home/sales',
        to: '/home/sales/process',
        icon: DescriptionIcon,
        label: gettext('Orders'),
        subItems: [
            {
                to: '/home/sales/process',
                label: gettext('Orders'),
            },
            {
                to: '/home/sales/pick-pack-batches',
                label: gettext('Batches'),
            },
            {
                to: '/home/sales/exports',
                label: gettext('Exports'),
            },
        ],
    },
    {
        permission: 'module.products',
        root: '/home/products',
        to: '/home/products/catalog',
        icon: StoreIcon,
        label: gettext('Products'),
        subItems: [
            {
                to: '/home/products/catalog',
                label: gettext('Catalog'),
            },
            {
                to: '/home/products/prices',
                label: gettext('Prices'),
            },
        ],
    },
    {
        permission: 'module.stock',
        root: '/home/stock',
        to: '/home/stock/manager',
        icon: ListAltIcon,
        label: gettext('Stock'),
        subItems: [
            {
                to: '/home/stock/manager',
                label: gettext('Stock'),
            },
            {
                to: getPath('channelStockBreakdown'),
                label: gettext('By shop'),
            },
            {
                to: '/home/stock/report',
                label: gettext('Stock Report'),
            },
            {
                to: '/home/stock/adjustment-groups',
                label: gettext('Stock Adjustments'),
            },
        ],
    },
    {
        permission: 'module.products',
        root: '/home/discounts',
        to: '/home/discounts',
        icon: LocalOfferIcon,
        label: gettext('Discounts'),
    },
    {
        permission: 'module.settings',
        root: '/home/integrations',
        to: '/home/integrations',
        icon: SwapCallsIcon,
        label: gettext('Integrations'),
    },
]

const theme = createTheme({
    palette: {
        primary: {
            main: '#41C49C',
            contrastText: '#fff',
        },
        secondary: {
            main: '#4B84C3',
        },
        error: {
            main: '#F25073',
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: ['Roboto', 'Lato', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        subtitle1: {
            fontWeight: 400,
            fontSize: '1rem',
        },
        body2: {
            fontSize: '0.9rem',
        },
    },
    overrides: {
        MuiTableRow: {
            head: {
                height: 10,
            },
        },
        MuiTableCell: {
            root: {
                padding: '10px 16px 10px 16px',
            },
            head: {
                lineHeight: '0.9rem',
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '0.8em',
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 40,
            },
        },
    },
})

function RouteWithPermission({ component: Component, permission, render, ...rest }) {
    const userPermissions = useAppSelector((state) => state.initial.user.permissions)
    return (
        <Route
            {...rest}
            render={(props) =>
                !permission || userPermissions.some((p) => p.codename === permission) ? (
                    Component ? (
                        <Component {...props} />
                    ) : (
                        render(props)
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: PATHS.accessDenied,
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    )
}

export default function App(props) {
    const frameComponent = () => (
        <FrameWithSidebar navSections={navSections}>
            <Route path={PATHS.home} component={HomePage} exact />
            <Route path={PATHS.accessDenied} component={AccessDeniedPage} exact />

            <RouteWithPermission
                permission="module.analytics"
                path={PATHS.dashboardOverview}
                component={Dashboard}
                exact
            />
            <RouteWithPermission
                permission="module.analytics"
                path={PATHS.dashboardReports}
                component={Reports}
                exact
            />

            <RouteWithPermission
                permission="module.sales"
                path={PATHS.sales}
                component={Sales}
                exact
            />
            <RouteWithPermission
                permission="module.sales"
                path={PATHS.exports}
                component={Exports}
                exact
            />
            <RouteWithPermission
                permission="module.sales"
                path={PATHS.initShipping}
                component={InitShipping}
            />
            <RouteWithPermission
                permission="module.sales"
                path={PATHS.pickPackBatches}
                component={PickPackBatches}
                exact
            />
            <RouteWithPermission
                permission="module.sales"
                path={PATHS.pickPackBatch}
                component={PickPackBatch}
                exact
            />
            <RouteWithPermission
                permission="module.sales"
                path={PATHS.sale}
                component={Sale}
                exact
            />

            <RouteWithPermission
                permission="module.products"
                path={PATHS.products}
                component={Products}
                exact
            />
            <RouteWithPermission
                permission="module.products"
                path={PATHS.product}
                component={ProductEditPage}
                exact
            />
            <RouteWithPermission
                permission="module.products"
                path={PATHS.priceManager}
                component={PriceManager}
                exact
            />
            <RouteWithPermission
                permission="module.products"
                path={PATHS.stockReport}
                component={StockReport}
                exact
            />
            <RouteWithPermission
                permission="module.products"
                path={PATHS.createProduct}
                component={ProductCreatePage}
                exact
            />
            <RouteWithPermission
                permission="module.products"
                path={PATHS.mergeRecommendations}
                component={MergeRecommendations}
                exact
            />
            <RouteWithPermission
                permission="module.products"
                path={PATHS.discounts}
                component={Discounts}
                exact
            />
            <RouteWithPermission
                permission="module.products"
                path={PATHS.discount}
                component={Discount}
                exact
            />
            <RouteWithPermission
                permission="module.products"
                path={PATHS.createDiscount}
                component={Discount}
                exact
            />

            <RouteWithPermission
                permission="module.stock"
                path={PATHS.stockManager}
                component={StockManagerPage}
                exact
            />
            <RouteWithPermission
                permission="module.stock"
                path={PATHS.stockReport}
                component={StockReport}
                exact
            />
            <RouteWithPermission
                permission="module.stock"
                path={PATHS.channelStockBreakdown}
                component={ChannelStockBreakdownPage}
                exact
            />

            <RouteWithPermission
                permission="module.stock"
                path={PATHS.stockAdjustmentGroups}
                component={StockAdjustmentGroups}
                exact
            />
            <RouteWithPermission
                permission="module.stock"
                path={PATHS.stockAdjustmentGroup}
                component={StockAdjustmentGroup}
                exact
            />
            <RouteWithPermission
                permission="module.stock"
                path={PATHS.createStockAdjustmentGroup}
                component={StockAdjustmentGroup}
                exact
            />

            <RouteWithPermission
                permission="module.settings"
                path={PATHS.addChannelSelectStore}
                component={StorePicker}
                exact
            />
            <RouteWithPermission
                permission="module.settings"
                path={PATHS.addChannelSelectChannel}
                component={ChannelPicker}
                exact
            />
            <RouteWithPermission
                permission="module.settings"
                path={PATHS.addChannelInstructions}
                component={ChannelInstructions}
                exact
            />
            <RouteWithPermission
                permission="module.settings"
                path={PATHS.addChannelResult}
                component={ChannelResult}
                exact
            />
            <RouteWithPermission
                permission="module.settings"
                path={PATHS.integrations}
                component={Integrations}
            />
            <RouteWithPermission
                permission="module.settings"
                path={PATHS.productImportIssues}
                component={ProductImportIssues}
            />
            <RouteWithPermission
                permission="module.settings"
                path={PATHS.settings}
                component={Settings}
            />
            <RouteWithPermission
                permission="module.settings"
                path={PATHS.jaymartSettings}
                component={JaymartSettings}
            />
            <RouteWithPermission
                permission="module.chat"
                path={PATHS.chat}
                render={() => (
                    <Suspense fallback="">
                        <ChatPage />
                    </Suspense>
                )}
            />
        </FrameWithSidebar>
    )
    // We wrap BaseApp with v4 and v5 Theme Providers as we gradually transition to v5
    return (
        <ThemeProvider>
            <MuiThemeProvider theme={theme}>
                <BaseApp loggedInRoutePath="/home" frameComponent={frameComponent} {...props} />
            </MuiThemeProvider>
        </ThemeProvider>
    )
}
