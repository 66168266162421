import { connect } from 'react-redux'

import PickPackBatch from '../components/pick-pack-batch'

const mapStateToProps = (state) => ({
    stores: state.initial.stores,
    language: state.initial.language,
    userID: state.initial.user.id,
    pickListURL: state.initial.endpoints.pickListURL,
    pickPackBatchAPIURL: state.initial.endpoints.pickPackBatchAPIURL,
    pickPackBatchAllLabelsAvailableAPIURL:
        state.initial.endpoints.pickPackBatchAllLabelsAvailableAPIURL,
    createPickPackBatchDocumentsAPIURL: state.initial.endpoints.createPickPackBatchDocumentsAPIURL,
    createPickPackBatchLabelsAPIURL: state.initial.endpoints.createPickPackBatchLabelsAPIURL,
})

export default connect(mapStateToProps)(PickPackBatch)
