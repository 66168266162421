import { createContext, useContext, type ReactNode } from 'react'
import { type VirtuosoHandle } from 'react-virtuoso'

import { createStore, useStore } from 'zustand'

import { type VirtualizationContainerKey } from './useVirtualizationContainer'

export type ChannelProductDialog = 'channel-products' | 'channel-products-delete'
export type BaseDialog =
    | 'delete-confirmation'
    | 'stock-units-coverage'
    | 'add-shops'
    | 'category-picker'
    | 'save-inactive'

export type Position = Partial<{
    top: number
    left: number
    bottom: number
    right: number
}>

type State = {
    tab: string
    dialog: ChannelProductDialog | BaseDialog | null
    arrowContainerRef: HTMLElement | null
    virtualizationContainers: Map<VirtualizationContainerKey, VirtuosoHandle>
    selectedChannelProductIndex: number
    actions: {
        changeTab: (tab: string) => void
        openDialog: (type: BaseDialog) => void
        openChannelProductDialog: (channelProductIndex: number, type?: ChannelProductDialog) => void
        resetSelectedChannelProductIndex: () => void
        closeDialog: () => void
        assignArrowContainerRef: (arrowContainerRef: HTMLElement | null) => void
        assignVirtualizationContainer: (
            key: VirtualizationContainerKey,
            handle: VirtuosoHandle
        ) => void
    }
}

export function createProductStore() {
    return createStore<State>()((set) => ({
        tab: 'en',
        dialog: null,
        arrowContainerRef: null,
        virtualizationContainers: new Map(),
        selectedChannelProductIndex: -1,
        actions: {
            changeTab: (tab) => set({ tab }),
            closeDialog: () => set({ dialog: null }),
            openChannelProductDialog: (channelProductIndex, dialog = 'channel-products') => {
                set({
                    dialog: dialog,
                    selectedChannelProductIndex: channelProductIndex,
                })
            },
            resetSelectedChannelProductIndex: () => set({ selectedChannelProductIndex: -1 }),
            openDialog: (dialog) => set({ dialog }),
            assignArrowContainerRef: (arrowContainerRef) => {
                set({
                    arrowContainerRef,
                })
            },
            assignVirtualizationContainer: (key, handle) => {
                set((state) => {
                    const virtualizationContainers = new Map(state.virtualizationContainers)

                    return { virtualizationContainers: virtualizationContainers.set(key, handle) }
                })
            },
        },
    }))
}

export type ProductStore = ReturnType<typeof createProductStore>

export const ProductStoreContext = createContext<ProductStore | null>(null)

function useProductStoreContext() {
    const context = useContext(ProductStoreContext)
    if (!context) {
        throw new Error('This hook must be used within a ProductStoreProvider')
    }

    return context
}

export function useProductPageTab() {
    return useStore(useProductStoreContext(), (state) => state.tab)
}

export function useProductPageDialog() {
    return useStore(useProductStoreContext(), (state) => state.dialog)
}

export function useProductPageArrowContainerRef() {
    return useStore(useProductStoreContext(), (state) => state.arrowContainerRef)
}

export function useProductPageActions() {
    return useStore(useProductStoreContext(), (state) => state.actions)
}

export function useProductPageSelectedChannelProductIndex() {
    return useStore(useProductStoreContext(), (state) => state.selectedChannelProductIndex)
}

export function useProductPageVirtualizationContainers() {
    return useStore(useProductStoreContext(), (state) => state.virtualizationContainers)
}

export function ProductStoreProvider({
    children,
    store,
}: {
    children: ReactNode
    store: ProductStore
}) {
    return <ProductStoreContext.Provider value={store}>{children}</ProductStoreContext.Provider>
}
