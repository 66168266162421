import React from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DescriptionIcon from '@material-ui/icons/Description'

import Cookie from 'js-cookie'
import moment from 'moment'

import OrderProcessor from '../../sales/containers/order-processor'
import { copyObject } from '../../tools/utils'
import PickListProcessor from '../containers/pick-list-processor'

const styles = (theme) => ({
    pickPackBatchInfo: {
        marginTop: 20,
    },
    header: {
        display: 'flex',
        padding: '15px 30px 0px 30px',
    },
    lowerHeader: {
        display: 'flex',
        borderBottom: '1px solid lightgray',
        color: grey[600],
        size: '0.875rem',
        padding: '0px 30px 15px 30px',
    },
    mainSection: {
        display: 'flex',
        padding: '15px 30px',
    },
    infoSection: {
        paddingRight: 30,
        paddingTop: 20,
        paddingBottom: 20,
    },
    infoItem: {
        marginBottom: 30,
    },
    infoItemLabel: {
        fontWeight: 500,
        marginBottom: 5,
    },
    infoSubSection: {
        display: 'flex',
    },
    infoSubSectionItem: {
        marginRight: 40,
        '&:last-child': {
            marginRight: 0,
        },
    },
    statusIndicator: {
        display: 'flex',
    },
    statusIndicatorDot: {
        marginRight: 10,
    },
    printSection: {
        display: 'flex',
        flexDirection: 'column',
        borderLeft: '1px solid lightgray',
        paddingLeft: 30,
        paddingTop: 20,
        paddingBottom: 20,
    },
    createDocumentsActions: {
        display: 'flex',
        alignItems: 'center',
    },
    documentOptionsFormControl: {},
    documentOptionsGroup: {
        flexDirection: 'row',
        width: 300,
    },
    documentOptionLabel: {
        fontSize: '0.9rem',
    },
    createDocumentButton: {
        width: 300,
        marginLeft: 10,
    },
    labelsContainer: {
        marginTop: 10,
        marginBottom: 10,
    },
    labels: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        marginBottom: 15,
    },
    labelActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    shippingLabelWithItemListGroup: {
        flexDirection: 'row',
        width: 300,
        fontSize: '0.9rem',
    },
    shippingLabelWithItemListLabel: {
        fontSize: '0.9rem',
    },
    createShippingLabelsButton: {
        width: 300,
        marginLeft: 10,
    },
    documentLinks: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
    },
    documentsLoading: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
    },
    creatingDocumentsText: {
        marginLeft: 10,
    },
    creatingDocumentsErrorText: {
        color: theme.palette.error.main,
    },
    creatingPickPackBatchMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    creatingPickPackBatchText: {
        marginTop: 10,
        color: grey[700],
    },
    creatingShippingLabelsText: {
        marginTop: 10,
        marginBottom: 20,
        color: grey[700],
        lineHeight: 1.3,
        textAlign: 'center',
    },
    pickPackBatchButton: {
        marginBottom: 20,
        cursor: 'pointer',
        width: 300,
    },
    documentButton: {
        marginRight: 20,
        color: grey[600],
        textTransform: 'none',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid lightgray',
        padding: '15px 30px',
    },
    actionButton: {
        color: 'white',
        marginRight: 20,
    },
})

const statusIndicatorColors = {
    created: grey[300],
    in_progress: orange[300],
    completed: '#41C49C',
}

const POLLING_TIMEOUT = 30000
const POLLING_INTERVAL = 1000

class PickPackBatch extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            pickPackBatch: null,
            pickPackBatchLoading: true,
            allShippingLabelsAvailable: false,
            packListChecked: false,
            taxInvoiceChecked: false,
            creatingShippingLabels: false,
            creatingDocuments: false,
            createShippingLabelsError: '',
            createDocumentsError: '',
            displayConfirmCompletion: false,
            displayPickListProcessor: false,
            displayOrderProcessor: false,
            showShippingLabelNotAvailableMessage: false,
            shippingLabelNotAvailableMessage: '',
            includeItemListChecked: true,
            verticalOnlyChecked: false,
        }
        this.shippingLabelsAvailablePollInterval = null
        this.shippingLabelsPollInterval = null
        this.documentsPollInterval = null

        this.fetchPickPackBatch = this.fetchPickPackBatch.bind(this)
        this.checkShippingLabelsAvailable = this.checkShippingLabelsAvailable.bind(this)
        this.beginPollingShippingLabelsAvailable =
            this.beginPollingShippingLabelsAvailable.bind(this)
        this.handleCheckDocumentOption = this.handleCheckDocumentOption.bind(this)
        this.handleCreateDocuments = this.handleCreateDocuments.bind(this)
        this.handleCreateLabels = this.handleCreateLabels.bind(this)
        this.beginPollingDocuments = this.beginPollingDocuments.bind(this)
        this.beginPollingShippingLabels = this.beginPollingShippingLabels.bind(this)
        this.handleAllOrdersProcessed = this.handleAllOrdersProcessed.bind(this)
        this.handleConfirmation = this.handleConfirmation.bind(this)
        this.updatePickPackBatch = this.updatePickPackBatch.bind(this)
        this.handleOpenPickListProcessor = this.handleOpenPickListProcessor.bind(this)
        this.handleClosePickListProcessor = this.handleClosePickListProcessor.bind(this)
        this.handleOpenOrderProcessor = this.handleOpenOrderProcessor.bind(this)
        this.handleCloseOrderProcessor = this.handleCloseOrderProcessor.bind(this)

        moment.locale(props.language)
    }

    componentDidMount() {
        this.fetchPickPackBatch(this.beginPollingShippingLabelsAvailable)
    }

    fetchPickPackBatch(callback) {
        const { pick_pack_batch_id } = this.props.match.params
        const { pickPackBatchAPIURL } = this.props

        fetch(pickPackBatchAPIURL.replace('pick_pack_batch_pk', pick_pack_batch_id))
            .then((response) => response.json())
            .then((pickPackBatch) => {
                this.setState({ pickPackBatch }, callback)
            })
    }

    checkShippingLabelsAvailable(callback) {
        const { pick_pack_batch_id } = this.props.match.params
        const { pickPackBatchAllLabelsAvailableAPIURL } = this.props

        fetch(
            pickPackBatchAllLabelsAvailableAPIURL.replace('pick_pack_batch_pk', pick_pack_batch_id)
        )
            .then((response) => response.json())
            .then((result) => {
                callback(result)
            })
    }

    beginPollingShippingLabelsAvailable() {
        if (this.shippingLabelsAvailablePollInterval) {
            clearInterval(this.shippingLabelsAvailablePollInterval)
        }

        this.shippingLabelsAvailablePollInterval = setInterval(() => {
            this.checkShippingLabelsAvailable((result) => {
                if (result.all_shipping_labels_available) {
                    clearInterval(this.shippingLabelsAvailablePollInterval)
                    this.setState({ allShippingLabelsAvailable: true })
                } else {
                    if (result.reason) {
                        clearInterval(this.shippingLabelsAvailablePollInterval)
                        this.setState({
                            allShippingLabelsAvailable: false,
                            showShippingLabelNotAvailableMessage: true,
                            shippingLabelNotAvailableMessage: result.reason,
                        })
                    }
                }
            })
        }, 3000)
    }

    handleCheckDocumentOption(e) {
        const option = e.target.value

        this.setState({ [option]: !this.state[option] })
    }

    beginPollingShippingLabels() {
        if (this.shippingLabelsPollInterval) {
            clearInterval(this.shippingLabelsPollInterval)
        }

        let startTime = new Date().getTime()

        this.shippingLabelsPollInterval = setInterval(() => {
            if (new Date().getTime() - startTime > POLLING_TIMEOUT) {
                clearInterval(this.shippingLabelsPollInterval)
                this.shippingLabelsPollInterval = null

                this.setState({
                    creatingShippingLabels: false,
                    createShippingLabelsError: gettext(
                        'Generating shipping labels took long than expected. Please refresh the page and try again.'
                    ),
                })
                return
            }
            this.fetchPickPackBatch(() => {
                const shippingLabelsReadyStatus = this.state.pickPackBatch.documents
                    .filter(
                        (doc) => doc.document_type === 'shipping_labels' && doc.status !== 'failed'
                    )
                    .map((doc) => doc.is_ready)
                const allShippingLabelsReady = shippingLabelsReadyStatus.every((status) => status)

                const failedFileNames = this.state.pickPackBatch.documents
                    .filter((doc) => doc.status === 'failed')
                    .map((doc) => doc.name)

                if (allShippingLabelsReady) {
                    clearInterval(this.shippingLabelsPollInterval)
                    this.shippingLabelsPollInterval = null

                    let createShippingLabelsError = ''
                    if (failedFileNames.length > 0) {
                        createShippingLabelsError = interpolate(
                            gettext('Failed to generate files: %s. Please try again later.'),
                            [failedFileNames]
                        )
                    }
                    this.setState({
                        creatingShippingLabels: false,
                        createShippingLabelsError: createShippingLabelsError,
                    })
                }
            })
        }, POLLING_INTERVAL)
    }

    beginPollingDocuments() {
        if (this.documentsPollInterval) {
            clearInterval(this.documentsPollInterval)
        }
        let startTime = new Date().getTime()
        this.documentsPollInterval = setInterval(() => {
            if (new Date().getTime() - startTime > POLLING_TIMEOUT) {
                clearInterval(this.shippingLabelsPollInterval)
                this.shippingLabelsPollInterval = null

                this.setState({
                    creatingDocuments: false,
                    createDocumentsError: gettext(
                        'Documents generation timed out. Please refresh the page and try again later. If this issue persists, please contact us.'
                    ),
                })
                return
            }
            this.fetchPickPackBatch(() => {
                const documentsReadyStatus = this.state.pickPackBatch.documents
                    .filter((doc) => doc.document_type === 'documents' && doc.status !== 'failed')
                    .map((doc) => doc.is_ready)
                const allDocumentsReady = documentsReadyStatus.every((status) => status)

                const failedFileNames = this.state.pickPackBatch.documents
                    .filter((doc) => doc.status === 'failed')
                    .map((doc) => doc.name)

                if (allDocumentsReady) {
                    clearInterval(this.documentsPollInterval)
                    this.documentsPollInterval = null

                    let createDocumentsError = ''
                    if (failedFileNames.length > 0) {
                        createDocumentsError = interpolate(
                            gettext(
                                'Failed to generate files: %s. Please try again later. If this issue persists, please contact us.'
                            ),
                            [failedFileNames]
                        )
                    }

                    this.setState({
                        creatingDocuments: false,
                        createDocumentsError: createDocumentsError,
                    })
                }
            })
        }, POLLING_INTERVAL)
    }

    handleCreateDocuments() {
        const { createPickPackBatchDocumentsAPIURL } = this.props

        const pickPackBatch = copyObject(this.state.pickPackBatch)

        let maxID
        if (pickPackBatch.documents.length > 0) {
            const docIDs = pickPackBatch.documents.map((doc) => doc.id)
            maxID = Math.max(docIDs)
        } else {
            maxID = 0
        }

        pickPackBatch.documents.push({ id: maxID + 1, is_ready: false })

        const creatingDocumentsState = {
            creatingDocuments: true,
            packListChecked: false,
            taxInvoiceChecked: false,
            createDocumentsError: '',
        }

        const data = {
            pack_list: this.state.packListChecked,
            tax_invoice: this.state.taxInvoiceChecked,
        }

        this.setState(creatingDocumentsState, () => {
            let url = createPickPackBatchDocumentsAPIURL.replace(
                'pick_pack_batch_pk',
                this.state.pickPackBatch.id
            )

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRFToken': Cookie.get('csrftoken'),
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.beginPollingDocuments()
                })
        })
    }

    handleCreateLabels() {
        const { createPickPackBatchLabelsAPIURL } = this.props

        const pickPackBatch = copyObject(this.state.pickPackBatch)

        let maxID
        if (pickPackBatch.documents.length > 0) {
            const docIDs = pickPackBatch.documents.map((doc) => doc.id)
            maxID = Math.max(docIDs)
        } else {
            maxID = 0
        }

        pickPackBatch.documents.push({ id: maxID + 1, is_ready: false })

        const creatingShippingLabelsState = {
            creatingShippingLabels: true,
            createShippingLabelsError: '',
        }

        const data = {
            with_item_list: this.state.includeItemListChecked,
            vertical_format: this.state.verticalOnlyChecked,
        }

        this.setState(creatingShippingLabelsState, () => {
            let url = createPickPackBatchLabelsAPIURL.replace(
                'pick_pack_batch_pk',
                this.state.pickPackBatch.id
            )

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRFToken': Cookie.get('csrftoken'),
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.beginPollingShippingLabels()
                })
        })
    }

    updatePickPackBatch(data) {
        const { pickPackBatchAPIURL } = this.props

        fetch(pickPackBatchAPIURL.replace('pick_pack_batch_pk', this.state.pickPackBatch.id), {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRFToken': Cookie.get('csrftoken'),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((pickPackBatch) => {
                this.setState({ pickPackBatch })
            })
    }

    handleAllOrdersProcessed() {
        this.setState({ displayConfirmCompletion: true })
    }

    handleConfirmation(confirm) {
        return (event) => {
            if (confirm) {
                this.updatePickPackBatch({ status: 'completed' })
            }

            this.setState({ displayConfirmCompletion: false })
        }
    }

    handleOpenPickListProcessor() {
        const newState = { displayPickListProcessor: true }

        if (this.state.pickPackBatch.status === 'created') {
            const pickPackBatch = copyObject(this.state.pickPackBatch)
            pickPackBatch.status = 'in_progress'
            newState['pickPackBatch'] = pickPackBatch
            this.updatePickPackBatch({ status: 'in_progress' })
        }

        this.setState(newState)
    }

    handleClosePickListProcessor() {
        this.setState({ displayPickListProcessor: false })
    }

    handleOpenOrderProcessor() {
        const newState = { displayOrderProcessor: true }

        if (this.state.pickPackBatch.status === 'created') {
            const pickPackBatch = copyObject(this.state.pickPackBatch)
            pickPackBatch.status = 'in_progress'
            newState['pickPackBatch'] = pickPackBatch
            this.updatePickPackBatch({ status: 'in_progress' })
        }

        this.setState(newState)
    }

    handleCloseOrderProcessor() {
        const state = {
            displayOrderProcessor: false,
        }

        this.setState(state)
    }

    render() {
        const { classes, pickListURL } = this.props
        const pickPackBatchInfo = this.state.pickPackBatch
            ? `${this.state.pickPackBatch.location.name} - ${moment(
                  this.state.pickPackBatch.date_created
              ).format('D MMM HH:mm')}`
            : ''

        return (
            <Paper className={classes.pickPackBatchInfo}>
                <div className={classes.header}>
                    <Typography>{`${gettext('Pick/pack batch')} ${
                        this.props.match.params?.pick_pack_batch_id
                    }`}</Typography>
                </div>
                <div className={classes.lowerHeader}>{pickPackBatchInfo}</div>
                <div className={classes.mainSection}>
                    <div className={classes.infoSection}>
                        <div className={classes.infoItem}>
                            <Typography variant="body2" className={classes.infoItemLabel}>
                                {gettext('Status')}
                            </Typography>
                            {this.state.pickPackBatch && (
                                <div className={classes.statusIndicator}>
                                    <div
                                        className={classes.statusIndicatorDot}
                                        style={{
                                            backgroundColor:
                                                statusIndicatorColors[
                                                    this.state.pickPackBatch.status
                                                ],
                                            width: 16,
                                            height: 16,
                                            borderRadius: 8,
                                        }}
                                    />
                                    <Typography className={classes.tableText} variant="body2">
                                        {this.state.pickPackBatch.status_display_name}
                                    </Typography>
                                </div>
                            )}
                        </div>

                        <div className={classes.infoItem}>
                            <Typography variant="body2" className={classes.infoItemLabel}>
                                {gettext('Date created')}
                            </Typography>
                            {this.state.pickPackBatch && (
                                <Typography variant="body2">
                                    {moment(this.state.pickPackBatch.date_created).format('lll')}
                                </Typography>
                            )}
                        </div>

                        <div className={classes.infoSubSection}>
                            <div className={classes.infoSubSectionItem}>
                                <Typography variant="body2" className={classes.infoItemLabel}>
                                    {gettext('Orders')}
                                </Typography>
                                {this.state.pickPackBatch && (
                                    <Typography variant="body2">
                                        {this.state.pickPackBatch.num_fulfillments}
                                    </Typography>
                                )}
                            </div>

                            <div className={classes.infoSubSectionItem}>
                                <Typography variant="body2" className={classes.infoItemLabel}>
                                    {gettext('Total items')}
                                </Typography>
                                {this.state.pickPackBatch && (
                                    <Typography variant="body2">
                                        {this.state.pickPackBatch.num_items}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={classes.printSection}>
                        <Button
                            className={classes.pickPackBatchButton}
                            target="_blank"
                            href={
                                this.state.pickPackBatch
                                    ? pickListURL.replace(
                                          'pick_pack_batch_pk',
                                          this.state.pickPackBatch.id
                                      )
                                    : ''
                            }
                            variant="outlined"
                            color="primary"
                            disabled={!this.state.pickPackBatch}
                        >
                            {gettext('Print pick list')}
                        </Button>
                        <Typography variant="subtitle2">{gettext('Shipping labels')}</Typography>
                        <div className={classes.labels}>
                            <div className={classes.labelActions}>
                                <FormControl component="fieldset">
                                    <FormGroup className={classes.shippingLabelWithItemListGroup}>
                                        <FormControlLabel
                                            classes={{
                                                label: classes.shippingLabelWithItemListLabel,
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={this.state.includeItemListChecked}
                                                    onChange={this.handleCheckDocumentOption}
                                                    value="includeItemListChecked"
                                                    disabled={this.state.creatingShippingLabels}
                                                />
                                            }
                                            label={gettext('Include Item list')}
                                        />
                                        <FormControlLabel
                                            classes={{
                                                label: classes.shippingLabelWithItemListLabel,
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={this.state.verticalOnlyChecked}
                                                    onChange={this.handleCheckDocumentOption}
                                                    value="verticalOnlyChecked"
                                                    disabled={this.state.creatingShippingLabels}
                                                />
                                            }
                                            label={gettext('Vertical only')}
                                        />
                                    </FormGroup>
                                </FormControl>
                                <Button
                                    className={classes.createDocumentButton}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        this.fetchPickPackBatch(this.handleCreateLabels)
                                    }}
                                    disabled={
                                        !this.state.pickPackBatch ||
                                        !this.state.allShippingLabelsAvailable
                                    }
                                >
                                    {gettext('Create shipping labels')}
                                </Button>
                            </div>

                            {!this.state.allShippingLabelsAvailable &&
                                !this.state.shippingLabelNotAvailableMessage && (
                                    <div className={classes.documentsLoading}>
                                        <CircularProgress
                                            className={classes.currentPickPackBatchProgress}
                                            color="secondary"
                                            size={15}
                                        />
                                        <Typography
                                            variant="body2"
                                            className={classes.creatingDocumentsText}
                                        >
                                            {gettext(
                                                'Checking if shipping labels are available. If this does not change, please check back a little later.'
                                            )}
                                        </Typography>
                                    </div>
                                )}

                            {this.state.creatingShippingLabels && (
                                <div className={classes.documentsLoading}>
                                    <CircularProgress
                                        className={classes.currentPickPackBatchProgress}
                                        color="secondary"
                                        size={15}
                                    />
                                    <Typography
                                        variant="body2"
                                        className={classes.creatingDocumentsText}
                                    >
                                        {gettext(
                                            'Please wait, it may take several minutes to generate your labels'
                                        )}
                                    </Typography>
                                </div>
                            )}

                            {this.state.showShippingLabelNotAvailableMessage && (
                                <div className={classes.documentsLoading}>
                                    <Typography
                                        variant="body2"
                                        className={classes.creatingDocumentsText}
                                    >
                                        {this.state.shippingLabelNotAvailableMessage}
                                    </Typography>
                                </div>
                            )}

                            {this.state.allShippingLabelsAvailable &&
                                !!this.state.createShippingLabelsError && (
                                    <Typography
                                        variant="body2"
                                        className={classes.creatingDocumentsErrorText}
                                    >
                                        {this.state.createShippingLabelsError}
                                    </Typography>
                                )}

                            <div className={classes.documentLinks}>
                                {this.state.pickPackBatch &&
                                    this.state.pickPackBatch.documents &&
                                    this.state.pickPackBatch.documents
                                        .filter(
                                            (doc) =>
                                                doc.is_ready &&
                                                doc.document_type === 'shipping_labels'
                                        )
                                        .map((doc) => (
                                            <Button
                                                className={classes.documentButton}
                                                target="_blank"
                                                href={doc.url}
                                                key={doc.id}
                                                startIcon={<DescriptionIcon />}
                                            >
                                                {doc.name}
                                            </Button>
                                        ))}
                            </div>
                        </div>

                        <Typography variant="subtitle2">{gettext('Documents')}</Typography>
                        <div className={classes.createDocumentsActions}>
                            <FormControl
                                component="fieldset"
                                className={classes.documentOptionsFormControl}
                            >
                                <FormGroup className={classes.documentOptionsGroup}>
                                    <FormControlLabel
                                        classes={{ label: classes.documentOptionLabel }}
                                        control={
                                            <Checkbox
                                                checked={this.state.packListChecked}
                                                onChange={this.handleCheckDocumentOption}
                                                value="packListChecked"
                                                disabled={this.state.creatingDocuments}
                                            />
                                        }
                                        label={gettext('Pack list')}
                                    />
                                    <FormControlLabel
                                        classes={{ label: classes.documentOptionLabel }}
                                        control={
                                            <Checkbox
                                                checked={this.state.taxInvoiceChecked}
                                                onChange={this.handleCheckDocumentOption}
                                                value="taxInvoiceChecked"
                                                disabled={this.state.creatingDocuments}
                                            />
                                        }
                                        label={gettext('Tax invoice')}
                                    />
                                </FormGroup>
                            </FormControl>
                            <Button
                                className={classes.createDocumentButton}
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    this.fetchPickPackBatch(this.handleCreateDocuments)
                                }}
                                disabled={
                                    !this.state.pickPackBatch ||
                                    (!this.state.packListChecked && !this.state.taxInvoiceChecked)
                                }
                            >
                                {gettext('Create documents')}
                            </Button>
                        </div>

                        {this.state.creatingDocuments && (
                            <div className={classes.documentsLoading}>
                                <CircularProgress
                                    className={classes.currentPickPackBatchProgress}
                                    color="secondary"
                                    size={15}
                                />
                                <Typography
                                    variant="body2"
                                    className={classes.creatingDocumentsText}
                                >
                                    {gettext(
                                        'Please wait, it may take several minutes to generate this document'
                                    )}
                                </Typography>
                            </div>
                        )}
                        {!!this.state.createDocumentsError && (
                            <Typography
                                variant="body2"
                                className={classes.creatingDocumentsErrorText}
                            >
                                {this.state.createDocumentsError}
                            </Typography>
                        )}

                        <div className={classes.documentLinks}>
                            {this.state.pickPackBatch &&
                                this.state.pickPackBatch.documents &&
                                this.state.pickPackBatch.documents
                                    .filter(
                                        (doc) => doc.is_ready && doc.document_type === 'documents'
                                    )
                                    .map((doc) => (
                                        <Button
                                            className={classes.documentButton}
                                            target="_blank"
                                            href={doc.url}
                                            key={doc.id}
                                            startIcon={<DescriptionIcon />}
                                        >
                                            {doc.name}
                                        </Button>
                                    ))}
                        </div>
                    </div>
                </div>
                <div className={classes.footer}>
                    <Button
                        className={classes.actionButton}
                        color="primary"
                        variant="contained"
                        disabled={
                            !this.state.pickPackBatch ||
                            this.state.pickPackBatch.status === 'completed'
                        }
                        onClick={this.handleAllOrdersProcessed}
                    >
                        {gettext('Confirm all orders processed')}
                    </Button>
                    <Button
                        className={classes.actionButton}
                        color="primary"
                        variant="contained"
                        disabled={!this.state.pickPackBatch}
                        onClick={this.handleOpenPickListProcessor}
                    >
                        {gettext('Gather items')}
                    </Button>
                    <Button
                        className={classes.actionButton}
                        color="primary"
                        variant="contained"
                        disabled={!this.state.pickPackBatch}
                        onClick={this.handleOpenOrderProcessor}
                    >
                        {gettext('Pack orders')}
                    </Button>
                </div>
                <Dialog className={classes.dialog} open={this.state.displayConfirmCompletion}>
                    <DialogTitle>{gettext('Complete pick pack batch')}</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Typography variant="body2">
                            {gettext('Have you packed all orders for this pick pack batch?')}
                        </Typography>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button color="secondary" onClick={this.handleConfirmation(false)}>
                            {gettext('No')}
                        </Button>
                        <Button color="secondary" onClick={this.handleConfirmation(true)}>
                            {gettext('Yes')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <PickListProcessor
                    open={this.state.displayPickListProcessor}
                    pickPackBatch={this.state.pickPackBatch}
                    handleClose={this.handleClosePickListProcessor}
                />
                <OrderProcessor
                    open={this.state.displayOrderProcessor}
                    pickPackBatch={this.state.pickPackBatch}
                    handleClose={this.handleCloseOrderProcessor}
                />
            </Paper>
        )
    }
}

export default withStyles(styles)(PickPackBatch)
