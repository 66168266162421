import { type ReactNode } from 'react'

import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'

export const theme = createTheme({
    palette: {
        primary: {
            main: '#41C49C',
            contrastText: '#fff',
        },
        secondary: {
            main: '#4B84C3',
        },
        error: {
            main: '#F25073',
        },
    },
    typography: {
        fontFamily: ['Roboto', 'Lato', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        subtitle1: {
            fontWeight: 400,
            fontSize: '1rem',
        },
        body2: {
            fontSize: '0.9rem',
        },
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                asterisk: ({ theme }) => ({
                    color: theme.palette.error.main,
                    fontSize: '1.2em',
                }),
            },
        },
        MuiTableRow: {
            styleOverrides: {
                head: {
                    height: 10,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '10px 16px 10px 16px',
                },
                head: {
                    lineHeight: '0.9rem',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '0.8em',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 40,
                },
            },
        },
    },
})

export type Theme = typeof theme

export function ThemeProvider({ children }: { children: ReactNode }) {
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}
