import React from 'react'
import { Link } from 'react-router-dom'

import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'

import { getPath } from '../../oakra/components/base-app'

import DateSelectButton from '~/oakra/components/date-select-button'
import { debounce } from '~/tools/utils'

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        minHeight: 67,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    spacer: {
        flex: '1 1 auto',
    },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },
    title: {
        flex: '0 0 auto',
    },
    toolbarItems: {
        display: 'flex',
        alignItems: 'baseline',
    },
    search: {
        width: 150,
        marginTop: 2.5,
        marginBottom: 2.5,
        marginLeft: 7,
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'flex',
    },
})

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    toolbar: {
        position: 'fixed',
    },
    table: {
        width: '100%',
    },
    tableHeader: {
        borderTop: '1px solid ' + grey[200],
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell: {
        padding: '10px 34px',
        wordWrap: 'break-word',
    },
    noPickPackBatchesMessage: {
        textAlign: 'center',
        padding: 75,
    },
    noPickPackBatchesText: {
        color: grey[400],
    },
    pickPackBatchRow: {},
    pickPackBatchLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    status: {
        textAlign: 'center',
    },
    statusIndicator: {
        display: 'flex',
    },
    statusIndicatorDot: {
        marginRight: 10,
    },
})

const statusIndicatorColors = {
    created: grey[300],
    in_progress: orange[300],
    completed: '#41C49C',
}

const columns = [
    { id: 'id', label: gettext('ID'), centerAlign: false },
    { id: 'location', label: gettext('Location'), centerAlign: false },
    { id: 'date_created', label: gettext('Date'), centerAlign: false },
    { id: 'num_fulfillments', label: gettext('Orders'), centerAlign: false },
    { id: 'num_items', label: gettext('Items'), centerAlign: false },
    { id: 'status', label: gettext('Status'), centerAlign: false },
]

class PickPackBatchesTableHeadWithoutStyle extends React.PureComponent {
    render() {
        const { classes } = this.props

        return (
            <TableHead className={classes.tableHeader}>
                <TableRow>
                    {columns.map((column) => {
                        return (
                            <TableCell
                                key={column.id}
                                padding={column.disablePadding ? 'none' : 'default'}
                                style={{
                                    textAlign: column.centerAlign && 'center',
                                    width: column.width ? column.width : 'auto',
                                    minWidth: column.minWidth ? column.minWidth : 0,
                                }}
                                className={classes.tableCell}
                            >
                                {column.label}
                            </TableCell>
                        )
                    }, this)}
                </TableRow>
            </TableHead>
        )
    }
}

PickPackBatchesTableHeadWithoutStyle.propTypes = {
    rowCount: PropTypes.number.isRequired,
}

const PickPackBatchesTableHead = withStyles(styles)(PickPackBatchesTableHeadWithoutStyle)

class PickPackBatchesTableToolbarWithoutStyle extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            dateButtonText: gettext('Past 2 weeks'),
        }
    }

    handleDateChange = (dateStart, dateEnd, buttonText) => {
        this.props.handleDateTimeChange(dateStart, dateEnd)
        this.setState({ dateButtonText: buttonText })
    }
    render() {
        const {
            handleChangePage,
            page,
            count,
            classes,
            orderIdValueHandler,
            pickPackBatchIdValueHandler,
            searchHandler,
            filterValues,
            loading,
        } = this.props

        return (
            <Toolbar className={classes.root}>
                {this.props.loading && (
                    <LinearProgress className={classes.progress} color="secondary" />
                )}
                <DateSelectButton
                    startDate={filterValues.date_created_after}
                    endDate={filterValues.date_created_before}
                    handleDateChange={this.handleDateChange}
                    initialButtonText={gettext('Past 2 weeks')}
                    disabled={filterValues.pick_pack_batch_id_value !== ''}
                />
                <TextField
                    id="order_id_value"
                    type="search"
                    className={classes.search}
                    value={filterValues.order_id_value}
                    disabled={filterValues.pick_pack_batch_id_value !== ''}
                    margin="normal"
                    onChange={orderIdValueHandler}
                    variant="outlined"
                    placeholder={gettext('Order/Tracking ID')}
                    InputProps={{
                        classes: {
                            root: classes.toolbarTextField,
                        },
                        style: { height: 30, fontSize: 13 },
                    }}
                    inputProps={{ style: { paddingLeft: 9 } }}
                />
                <TextField
                    id="pick_pack_batch_id_value"
                    type="search"
                    className={classes.search}
                    value={filterValues.pick_pack_batch_id_value}
                    margin="normal"
                    onChange={pickPackBatchIdValueHandler}
                    variant="outlined"
                    placeholder={gettext('Pick Pack Batch ID')}
                    InputProps={{
                        classes: {
                            root: classes.toolbarTextField,
                        },
                        style: { height: 30, fontSize: 13 },
                    }}
                    inputProps={{ style: { paddingLeft: 9 } }}
                />
                <TextField
                    id="search"
                    type="search"
                    className={classes.search}
                    value={filterValues.search}
                    margin="normal"
                    disabled={filterValues.pick_pack_batch_id_value !== ''}
                    onChange={searchHandler}
                    variant="outlined"
                    placeholder={gettext('SKU/Product Name')}
                    InputProps={{
                        classes: {
                            root: classes.toolbarTextField,
                        },
                        style: { height: 30, fontSize: 13 },
                    }}
                    inputProps={{ style: { paddingLeft: 9 } }}
                />
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    <TablePagination
                        className={classes.topPagination}
                        component="div"
                        count={count}
                        rowsPerPage={25}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': gettext('Previous'),
                        }}
                        nextIconButtonProps={{
                            'aria-label': gettext('Next'),
                        }}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[25]}
                    />
                </div>
            </Toolbar>
        )
    }
}

PickPackBatchesTableToolbarWithoutStyle.propTypes = {
    classes: PropTypes.object.isRequired,
}

const PickPackBatchesTableToolbar = withStyles(toolbarStyles)(
    PickPackBatchesTableToolbarWithoutStyle
)

class PickPackBatchesWithoutStyle extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            rowsPerPage: 25,
            pickPackBatches: [],
            count: 0,
            page: 0,
            maxPageFetched: 0,
            loading: true,
            filterValues: {
                date_created_after: moment().subtract(13, 'days'),
                date_created_before: moment(),
                order_id_value: '',
                pick_pack_batch_id_value: '',
                search: '',
            },
        }

        this.fetchPickPackBatchesData = this.fetchPickPackBatchesData.bind(this)
        this.fetchPickPackBatchesDataDebounced = debounce(this.fetchPickPackBatchesData, 500)
        this.handleChangePage = this.handleChangePage.bind(this)

        moment.locale(props.language)
    }

    getDataURL = () => {
        // In the future this should fetch batches assigned to the current user
        // The user on the PickPackBath may still be the account owner
        const { pickPackBatchesAPIURL, accountOwnerID } = this.props
        return pickPackBatchesAPIURL.replace('user_pk', accountOwnerID)
    }

    filtersToQueryString = (filters = false) => {
        let filtersToUse = filters || this.state.filterValues

        filtersToUse = filtersToUse.pick_pack_batch_id_value
            ? { pick_pack_batch_id_value: filtersToUse.pick_pack_batch_id_value }
            : filtersToUse

        return Object.entries(filtersToUse)
            .filter(([fieldLookup, option]) => Boolean(option))
            .map(([fieldLookup, option]) => {
                if (moment.isMoment(option)) {
                    return `${fieldLookup}=${encodeURIComponent(option.format('YYYY-MM-DD'))}`
                } else {
                    return `${fieldLookup}=${encodeURIComponent(option.value || option)}`
                }
            })
            .join('&')
    }

    searchHandler = (event) => {
        const searchTerm = event.target.value
        const newFilters = { ...this.state.filterValues, search: searchTerm }
        this.setState({ filterValues: newFilters })
        this.fetchPickPackBatchesDataDebounced(this.getDataURL(), true, 0, newFilters)
    }

    orderIdValueHandler = (event) => {
        const orderIdValue = event.target.value
        const newFilters = { ...this.state.filterValues, order_id_value: orderIdValue }
        this.setState({ filterValues: newFilters })
        this.fetchPickPackBatchesDataDebounced(this.getDataURL(), true, 0, newFilters)
    }

    pickPackBatchIdValueHandler = (event) => {
        const pickPackBatchIDValue = event.target.value
        const newFilters = {
            ...this.state.filterValues,
            pick_pack_batch_id_value: pickPackBatchIDValue,
        }
        this.setState({ filterValues: newFilters })
        this.fetchPickPackBatchesDataDebounced(this.getDataURL(), true, 0, newFilters)
    }

    handleFilterChange = (field) => {
        return (option) => {
            const filterValues = { ...this.state.filterValues }
            if (option) {
                filterValues[field.lookup] = option
            } else {
                delete filterValues[field.lookup]
            }

            this.setState(
                {
                    filterValues,
                    page: 0,
                },
                () => {
                    this.fetchPickPackBatchesDataDebounced(this.getDataURL(), true, 0)
                }
            )
        }
    }

    handleDateTimeChange = (startDate, endDate) => {
        const filterValues = { ...this.state.filterValues }
        filterValues['date_created_after'] = startDate
        filterValues['date_created_before'] = endDate
        this.setState({ filterValues, page: 0 }, () => {
            this.fetchPickPackBatchesDataDebounced(this.getDataURL(), true, 0)
        })
    }

    componentDidMount(prevProps) {
        this.fetchPickPackBatchesDataDebounced(this.getDataURL(), false, 0)
    }

    fetchPickPackBatchesData(url, replace, page = null, filtersToUse = false) {
        if (!url.includes('?')) {
            const queryString = this.filtersToQueryString(filtersToUse)
            if (queryString) {
                url += '?' + queryString
            }
        }

        return fetch(url).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    let pickPackBatches
                    if (replace) {
                        pickPackBatches = data.results
                    } else {
                        pickPackBatches = this.state.pickPackBatches.concat(data.results)
                    }

                    const newState = {
                        count: data.count,
                        next: data.next,
                        previous: data.previous,
                        pickPackBatches: pickPackBatches,
                        loading: false,
                    }

                    if (page === 0) {
                        newState['page'] = 0
                        newState['maxPageFetched'] = 0
                    } else if (page > 0) {
                        newState['page'] = page
                    }

                    this.setState(newState)
                })
            }
        })
    }

    handleChangePage(event, page) {
        let pageChangeURL

        if (page > this.state.page) {
            pageChangeURL = this.state.next
        } else {
            pageChangeURL = this.state.previous
        }

        const needsFetch = page > this.state.maxPageFetched

        if (needsFetch) {
            this.setState(
                {
                    maxPageFetched: page,
                    loading: true,
                },
                () => {
                    this.fetchPickPackBatchesData(pageChangeURL, false, page)
                }
            )
        } else {
            this.setState({
                page: page,
            })
        }
    }

    render() {
        const { classes, staticRoot } = this.props
        const { rowsPerPage, page } = this.state
        const emptyRows =
            rowsPerPage -
            Math.min(rowsPerPage, this.state.pickPackBatches.length - page * rowsPerPage)

        const displayedPickPackBatches = this.state.pickPackBatches.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        )

        return (
            <Paper className={classes.root}>
                <PickPackBatchesTableToolbar
                    className={classes.toolbar}
                    handleChangePage={this.handleChangePage}
                    count={this.state.count}
                    page={this.state.page}
                    loading={this.state.loading}
                    filterValues={this.state.filterValues}
                    searchHandler={this.searchHandler}
                    orderIdValueHandler={this.orderIdValueHandler}
                    pickPackBatchIdValueHandler={this.pickPackBatchIdValueHandler}
                    handleDateTimeChange={this.handleDateTimeChange}
                />
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <PickPackBatchesTableHead
                            className={classes.tableHead}
                            rowCount={this.state.pickPackBatches.length}
                        />
                        <TableBody>
                            {!this.state.loading && this.state.pickPackBatches.length == 0 && (
                                <TableRow>
                                    <TableCell
                                        colSpan={5}
                                        className={classNames(
                                            classes.noPickPackBatchesMessage,
                                            classes.tableCell
                                        )}
                                    >
                                        <img
                                            className={classes.noSalesIcon}
                                            src={staticRoot + 'web/img/order.png'}
                                        />
                                        <Typography
                                            className={classes.noPickPackBatchesText}
                                            variant="subtitle1"
                                        >
                                            {gettext('No pick lists to display')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {displayedPickPackBatches.map((pickPackBatch) => {
                                const pickPackBatchLink = getPath('pickPackBatch').replace(
                                    'pick_pack_batch_id',
                                    pickPackBatch.id
                                )

                                return (
                                    <TableRow
                                        className={classes.pickPackBatchRow}
                                        hover
                                        tabIndex={-1}
                                        key={pickPackBatch.id}
                                    >
                                        <TableCell className={classes.tableCell}>
                                            <Link
                                                to={pickPackBatchLink}
                                                className={classes.pickPackBatchLink}
                                            >
                                                <Typography
                                                    className={classes.tableText}
                                                    variant="body2"
                                                >
                                                    {pickPackBatch.id}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Link
                                                to={pickPackBatchLink}
                                                className={classes.pickPackBatchLink}
                                            >
                                                <Typography
                                                    className={classes.tableText}
                                                    variant="body2"
                                                >
                                                    {pickPackBatch.location.name}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Link
                                                to={pickPackBatchLink}
                                                className={classes.pickPackBatchLink}
                                            >
                                                <Typography
                                                    className={classes.tableText}
                                                    variant="body2"
                                                >
                                                    {moment(pickPackBatch.date_created).format(
                                                        'D MMM HH:mm'
                                                    )}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Link
                                                to={pickPackBatchLink}
                                                className={classes.pickPackBatchLink}
                                            >
                                                <Typography
                                                    className={classes.tableText}
                                                    variant="body2"
                                                >
                                                    {pickPackBatch.num_fulfillments}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Link
                                                to={pickPackBatchLink}
                                                className={classes.pickPackBatchLink}
                                            >
                                                <Typography
                                                    className={classes.tableText}
                                                    variant="body2"
                                                >
                                                    {pickPackBatch.num_items}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Link
                                                to={pickPackBatchLink}
                                                className={classes.pickPackBatchLink}
                                            >
                                                <div className={classes.statusIndicator}>
                                                    <div
                                                        className={classes.statusIndicatorDot}
                                                        style={{
                                                            backgroundColor:
                                                                statusIndicatorColors[
                                                                    pickPackBatch.status
                                                                ],
                                                            width: 16,
                                                            height: 16,
                                                            borderRadius: 8,
                                                        }}
                                                    />
                                                    <Typography
                                                        className={classes.tableText}
                                                        variant="body2"
                                                    >
                                                        {pickPackBatch.status_display_name}
                                                    </Typography>
                                                </div>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    component="div"
                    count={this.state.count}
                    rowsPerPage={rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                        'aria-label': gettext('Previous'),
                    }}
                    nextIconButtonProps={{
                        'aria-label': gettext('Next'),
                    }}
                    onPageChange={this.handleChangePage}
                    rowsPerPageOptions={[25]}
                />
            </Paper>
        )
    }
}

const PickPackBatches = withStyles(styles)(PickPackBatchesWithoutStyle)

export default PickPackBatches
