import { type ReactNode, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { useProductFormContext } from '../hooks/useProductForm'

import type { ShopeeLogisticsProvider } from '~/common/schemas/logistics-shopee'

const currencySymbols = {
    th: '฿',
} as const

function isValidCountryCode(code: unknown): code is keyof typeof currencySymbols {
    return typeof code === 'string' && code in currencySymbols
}

type Props = {
    countryCode: string | null
    providersForShop: ShopeeLogisticsProvider[]
    onSave: (providers: ShopeeLogisticsProvider[]) => void
}

export function ShopeeLogisticsProviders({ countryCode, providersForShop, onSave }: Props) {
    const validCountryCode = isValidCountryCode(countryCode) ? countryCode : 'th'
    const [infoDialogOpen, setInfoDialogOpen] = useState(false)
    const [editableSelectedProvider, setEditableSelectedProvider] = useState<ShopeeLogisticsProvider | null>(null)

    function openDialog(logisticId: number) {
        const provider = providersForShop.find((p) => p.logistic_id === logisticId)
        if (!provider) {
            return
        }

        setInfoDialogOpen(true)
        setEditableSelectedProvider(provider)
    }

    function applyChanges() {
        if (!editableSelectedProvider) {
            return
        }

        const newProvidersForShop = providersForShop.map((p) => {
            if (p.logistic_id !== editableSelectedProvider.logistic_id) {
                return p
            }

            return {
                ...editableSelectedProvider,
                enabled: true,
                shipping_fee: editableSelectedProvider.is_free
                    ? undefined
                    : editableSelectedProvider.shipping_fee,
            }
        })

        setInfoDialogOpen(false)
        onSave(newProvidersForShop)
    }

    function toggleProvider(logisticId: number, checked: boolean) {
        if (checked) {
            const provider = providersForShop.find((p) => p.logistic_id === logisticId)
            const shouldOpenDialog =
                provider && provider.fee_type === 'CUSTOM_PRICE' && !provider.shipping_fee
            if (shouldOpenDialog) {
                openDialog(provider.logistic_id)
                return
            }
        }

        const newProvidersForShop = providersForShop.map((p) => {
            return p.logistic_id === logisticId
                ? { ...p, enabled: checked, is_free: p.is_free && checked }
                : p
        })

        onSave(newProvidersForShop)
    }

    const selectedProviders = providersForShop.filter((p) => p.enabled)
    const notSelectedProviders = providersForShop.filter((p) => !p.enabled)

    return (
        <>
            <Stack gap={2}>
                <LogisticProviderContainer
                    title={
                        <Typography variant="subtitle1">
                            {gettext('Enabled')}{' '}
                            <Typography component="span" color="error">
                                *
                            </Typography>
                        </Typography>
                    }
                >
                    {selectedProviders.length > 0 ? (
                        selectedProviders.map((provider) => (
                            <LogisticProvider
                                key={provider.logistic_id}
                                provider={provider}
                                countryCode={validCountryCode}
                                onToggle={toggleProvider}
                                onEdit={openDialog}
                            />
                        ))
                    ) : (
                        <Typography variant="body2">
                            {gettext('No shipping options enabled. You must enable at least one.')}
                        </Typography>
                    )}
                </LogisticProviderContainer>

                {notSelectedProviders.length > 0 && (
                    <LogisticProviderContainer
                        title={<Typography variant="subtitle1">{gettext('Disabled')}</Typography>}
                    >
                        {notSelectedProviders.map((provider) => (
                            <LogisticProvider
                                key={provider.logistic_id}
                                provider={provider}
                                countryCode={validCountryCode}
                                onToggle={toggleProvider}
                                onEdit={openDialog}
                            />
                        ))}
                    </LogisticProviderContainer>
                )}
            </Stack>

            {editableSelectedProvider && (
                <Dialog
                    open={infoDialogOpen}
                    TransitionProps={{ onExited: () => setEditableSelectedProvider(null) }}
                    onClose={() => setInfoDialogOpen(false)}
                >
                    <DialogTitle>{editableSelectedProvider.logistic_name}</DialogTitle>

                    <DialogContent>
                        <Stack direction="row" gap={1}>
                            <TextField
                                type="number"
                                size="small"
                                margin="dense"
                                variant="standard"
                                value={editableSelectedProvider.shipping_fee ?? '0'}
                                disabled={editableSelectedProvider.is_free}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {currencySymbols[validCountryCode]}
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                    setEditableSelectedProvider({
                                        ...editableSelectedProvider,
                                        shipping_fee: parseFloat(e.target.value),
                                    })
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={editableSelectedProvider.is_free}
                                        color="primary"
                                        onChange={(_, checked) => {
                                            setEditableSelectedProvider({
                                                ...editableSelectedProvider,
                                                shipping_fee: checked ? undefined : 0,
                                                is_free: checked,
                                            })
                                        }}
                                    />
                                }
                                label={gettext('I will cover the shipping fee')}
                            />
                        </Stack>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={() => {
                                setInfoDialogOpen(false)
                            }}
                        >
                            {gettext('Cancel')}
                        </Button>
                        <Button autoFocus color="primary" onClick={applyChanges}>
                            {editableSelectedProvider.enabled
                                ? gettext('Apply')
                                : gettext('Apply & Enable')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

function LogisticProviderContainer(props: { children: ReactNode; title: ReactNode }) {
    return (
        <Stack
            gap={2}
            sx={{ border: '1px solid', borderColor: 'grey.300', borderRadius: 1, padding: 2 }}
        >
            {props.title}
            <div>{props.children}</div>
        </Stack>
    )
}

type LogisticProviderProps = {
    provider: ShopeeLogisticsProvider
    countryCode: keyof typeof currencySymbols
    onToggle: (logisticId: number, checked: boolean) => void
    onEdit: (logisticId: number) => void
}

function LogisticProvider({ provider, countryCode, onToggle, onEdit }: LogisticProviderProps) {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            gap={1}
        >
            <Typography variant="body2">{provider.logistic_name}</Typography>
            <Stack flexShrink={0} direction="row" alignItems="center" gap={0.5}>
                <LogisticProviderAction
                    provider={provider}
                    countryCode={countryCode}
                    onEdit={onEdit}
                />
                <Switch
                    checked={provider.enabled}
                    color="primary"
                    onChange={(_, checked) => {
                        onToggle(provider.logistic_id, checked)
                    }}
                />
            </Stack>
        </Stack>
    )
}

type LogisticProviderActionProps = {
    provider: ShopeeLogisticsProvider
    countryCode: keyof typeof currencySymbols
    onEdit: (logisticId: number) => void
}

function LogisticProviderAction({ provider, countryCode, onEdit }: LogisticProviderActionProps) {
    const { watch } = useProductFormContext()
    const packageWeight = watch('package_weight')

    switch (provider.fee_type) {
        case 'CUSTOM_PRICE': {
            if (typeof provider.shipping_fee === 'undefined') {
                return null
            }

            return (
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="body2">
                        {currencySymbols[countryCode] + provider.shipping_fee}
                    </Typography>
                    <IconButton
                        size="small"
                        aria-label="Edit"
                        onClick={() => onEdit(provider.logistic_id)}
                    >
                        <EditIcon />
                    </IconButton>
                </Stack>
            )
        }

        case 'SIZE_INPUT': {
            if (!packageWeight) {
                return <Typography variant="body2">{gettext('Requires weight')}</Typography>
            }

            if (!provider.weight_limits) {
                return null
            }

            return packageWeight > provider.weight_limits.item_max_weight ? (
                <Typography variant="body2">{gettext('Overweight')}</Typography>
            ) : null
        }

        default:
            return null
    }
}
