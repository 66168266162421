import { useMemo, useState } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { grey } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'

import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'

import FormSearchField from '../../../components/FormSearchField'
import { useTableStyles } from '../../../theme'
import { UsersNotFound } from '../Users'
import { filterUsers } from '../utils'

import GroupAddUsers from './group-add-users'

import { api, queryKeys } from '~/api'

const useStyles = makeStyles(() => ({
    groups: {
        marginTop: 15,
    },
    groupsSectionTitle: {
        fontSize: '0.9rem',
        color: grey[700],
        fontWeight: 500,
        marginBottom: 10,
    },
    membersHeaderRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
    },
}))

export default function GroupMembers({ group, members, onRemoveUserFromGroup }) {
    const queryClient = useQueryClient()
    const classes = useStyles()
    const tableClasses = useTableStyles()

    const [isAddUserOpen, setIsAddUserOpen] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')

    const filteredMembers = useMemo(() => filterUsers(members, searchTerm), [members, searchTerm])

    const onClose = () => setIsAddUserOpen(false)

    const { mutate } = api.groups.removeUsers.useMutation({
        onSuccess: () => queryClient.invalidateQueries(queryKeys.groups.detail(group.id).queryKey),
    })

    function handleDeleteUser(user) {
        if (user.isNew) {
            onRemoveUserFromGroup(user)
            return
        }

        mutate({
            body: { users: [user.id] },
            params: { id: group.id },
        })
    }

    return (
        <div className={classes.groups}>
            <div className={classes.membersHeaderRow}>
                {/* <Typography className={classes.groupsSectionTitle}>{gettext("Group Members")}</Typography> */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <FormSearchField
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClear={() => setSearchTerm('')}
                    />
                    <Box sx={{ mt: { xs: 2, md: 0 }, ml: { xs: 0, md: 2 } }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                setIsAddUserOpen(true)
                            }}
                        >
                            {gettext('Add members')}
                        </Button>
                    </Box>
                </Box>
            </div>
            <TableContainer className={tableClasses.tableContainer}>
                <Table className={tableClasses.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{gettext('Name')}</TableCell>
                            <TableCell>{gettext('Username')}</TableCell>
                            <TableCell>{gettext('Email')}</TableCell>
                            <TableCell width={50} align="right">
                                {gettext('Remove')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredMembers.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <UsersNotFound />
                                </TableCell>
                            </TableRow>
                        )}
                        {filteredMembers.length > 0 &&
                            filteredMembers.map((user) => (
                                <TableRow key={`members_${user.id}`}>
                                    <TableCell className={tableClasses.tableCell}>
                                        {user.first_name} {user.last_name}
                                    </TableCell>
                                    <TableCell className={tableClasses.tableCell}>
                                        {user.username}
                                    </TableCell>
                                    <TableCell className={tableClasses.tableCell}>
                                        {user.email}
                                    </TableCell>
                                    <TableCell width={50} align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleDeleteUser(user)}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <GroupAddUsers open={isAddUserOpen} group={group} onClose={onClose} />
        </div>
    )
}

GroupMembers.propTypes = {
    group: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        members: PropTypes.array.isRequired,
        is_admin: PropTypes.bool.isRequired,
    }),
    members: PropTypes.array.isRequired,
    onRemoveUserFromGroup: PropTypes.func.isRequired,
}
