import type { ProductFormSchema } from '../hooks/useProductForm'

import type { Shop } from '~/common/schemas/shop'
import { channelProductFieldInfo, channelProductFieldInfoForMallShops } from '~/constants'

export function getFieldLengths(channelProducts: ProductFormSchema['channel_products']) {
    // TODO: Make channel product field info a dynamic value fetched from the API?

    const fieldInfos = channelProducts
        .map((cp) => {
            return cp.shouldCreateChannelProduct || !isNaN(cp.id)
                ? getFieldInfoByShop(cp.shop)
                : null
        })
        .filter(Boolean) as ReturnType<typeof getFieldInfoByShop>[]

    return fieldInfos.reduce(
        (acc, currentChannelInfo) => {
            return {
                name: {
                    minLength: Math.max(acc.name.minLength, currentChannelInfo.name.minLength),
                    maxLength: acc.name.maxLength
                        ? Math.min(acc.name.maxLength, currentChannelInfo.name.maxLength)
                        : currentChannelInfo.name.maxLength,
                },
                description: {
                    minLength: Math.max(
                        acc.description.minLength,
                        currentChannelInfo.description.minLength
                    ),
                    maxLength: acc.description.maxLength
                        ? Math.min(
                              acc.description.maxLength,
                              currentChannelInfo.description.maxLength
                          )
                        : currentChannelInfo.description.maxLength,
                },
            }
        },
        {
            name: { minLength: 0, maxLength: 0 },
            description: { minLength: 0, maxLength: 0 },
        }
    )
}

export function getFieldInfoByShop(shop: Shop) {
    const fieldInfoByChannel = shop.extra_info?.is_mall_shop
        ? channelProductFieldInfoForMallShops
        : channelProductFieldInfo
    return fieldInfoByChannel[shop.channel.id as keyof typeof fieldInfoByChannel]
}
