import { useState, memo, useMemo, useCallback, type ChangeEvent } from 'react'
import { useWatch } from 'react-hook-form'
import { TableVirtuoso, type TableComponents } from 'react-virtuoso'

import { makeStyles, type Theme } from '@material-ui/core/styles'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

import classNames from 'classnames'

import FormField from '~/common/components/form-field'
import { MAX_VIRTUALIZED_HEIGHT } from '~/consts'
import { DebouncedTextField } from '~/oakra/components/inputs'
import {
    type ProductFormSchema,
    type StockUnitFormSchema,
    useProductFormContext,
} from '~/products/hooks/useProductForm'
import { useVirtualizationContainer } from '~/products/hooks/useVirtualizationContainer'
import { createChannelValidator } from '~/products/utils/createChannelValidator'
import { computeStockUnitItemKey } from '~/products/utils/stock-unit'

const DEFAULT_FIELD_WIDTH = 125

export const usePricingStyles = makeStyles<Theme, { fieldWidth: number }>((theme) => ({
    headerRow: {
        backgroundColor: theme.palette.background.paper,
    },
    priceFieldColumn: {
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: (props) => props.fieldWidth,
        },
    },
    priceField: {
        height: 35,
    },
    rowPriceField: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0,
            marginRight: 0,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    },
    priceColumn: {
        maxWidth: (props) => props.fieldWidth,
        textAlign: 'center',
        verticalAlign: 'top',
    },
    firstColumn: {
        width: (props) => props.fieldWidth,
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
    },
    directionIcon: {
        color: theme.palette.grey[500],
    },
    pricingColumnHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    pricingChannelIconURL: {
        width: 20,
        marginRight: 5,
    },
    pricingColumnShopName: {
        fontSize: '0.9rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    skuPlaceholder: {
        color: theme.palette.grey[500],
    },
    skuColumn: {
        maxWidth: '13ch',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}))

type Props = {
    channelProducts: ProductFormSchema['channel_products']
    channelProductIndexById: Record<string, number>
    stockUnits: StockUnitFormSchema[]
    fieldWidth?: number
    onSetPriceForShop: (shopId: number) => (e: ChangeEvent<HTMLInputElement>) => void
    onSetPriceForStockUnits: (stockUnitIndex: number) => (e: ChangeEvent<HTMLInputElement>) => void
}

const components: TableComponents<StockUnitFormSchema> = {
    Table: (props) => (
        <table
            {...props}
            style={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                borderSpacing: '10px 0',
            }}
        />
    ),
    TableRow: (props) => <TableRow {...props} style={{ verticalAlign: 'initial' }} />,
    TableBody,
    TableHead,
    TableFoot: TableFooter,
}

export default memo(function PricingTable({
    channelProducts,
    stockUnits,
    fieldWidth = DEFAULT_FIELD_WIDTH,
    channelProductIndexById,
    onSetPriceForShop,
    onSetPriceForStockUnits,
}: Props) {
    const classes = usePricingStyles({ fieldWidth })
    const hasMultipleChannels = channelProducts.length > 1
    const [containerHeight, setContainerHeight] = useState(0)
    const ref = useVirtualizationContainer('pricing')

    const tableStyles = useMemo(
        () => ({
            height: containerHeight + 1,
            maxHeight: MAX_VIRTUALIZED_HEIGHT,
        }),
        [containerHeight]
    )

    const fixedHeaderContent = useCallback(() => {
        return (
            <>
                <tr className={classes.headerRow}>
                    <th scope="col" colSpan={hasMultipleChannels ? 2 : 1}></th>

                    {hasMultipleChannels &&
                        channelProducts.map((cp, cpIndex) => (
                            <th
                                key={cp.id || cpIndex}
                                scope="col"
                                className={classes.priceFieldColumn}
                            >
                                <div>
                                    <DebouncedTextField
                                        type="number"
                                        placeholder={gettext('Set all')}
                                        variant="outlined"
                                        margin="dense"
                                        InputProps={{ className: classes.priceField }}
                                        disabled={cp.is_promotion_period}
                                        inputProps={{ min: 0 }}
                                        onChange={onSetPriceForShop(cp.shop.id)}
                                    />
                                    <KeyboardArrowDownIcon className={classes.directionIcon} />
                                </div>
                            </th>
                        ))}
                </tr>
                <tr className={classes.headerRow}>
                    <th scope="col" colSpan={hasMultipleChannels ? 2 : 1} />
                    {channelProducts.map((cp, cpIndex) => (
                        <th key={cp.id || cpIndex} scope="col" className={classes.priceColumn}>
                            <div className={classes.pricingColumnHeader}>
                                <img
                                    alt={cp.shop.channel.name}
                                    className={classes.pricingChannelIconURL}
                                    src={cp.shop.channel.channel_square_icon_url}
                                />
                                <Typography className={classes.pricingColumnShopName}>
                                    {cp.shop.shop_name}
                                </Typography>
                            </div>
                        </th>
                    ))}
                </tr>
            </>
        )
    }, [
        channelProducts,
        classes.directionIcon,
        classes.headerRow,
        classes.priceColumn,
        classes.priceField,
        classes.priceFieldColumn,
        classes.pricingChannelIconURL,
        classes.pricingColumnHeader,
        classes.pricingColumnShopName,
        hasMultipleChannels,
        onSetPriceForShop,
    ])

    const itemContent = useCallback(
        (index: number) => (
            <>
                {channelProducts.length > 1 && (
                    <td>
                        <div className={classes.firstColumn}>
                            <DebouncedTextField
                                type="number"
                                placeholder={gettext('Set all')}
                                variant="outlined"
                                margin="dense"
                                InputProps={{ className: classes.priceField }}
                                inputProps={{ min: 0 }}
                                onChange={onSetPriceForStockUnits(index)}
                            />
                            <KeyboardArrowRightIcon className={classes.directionIcon} />
                        </div>
                    </td>
                )}

                <td>
                    <ProductPricingSkuColumn suIndex={index} fieldWidth={fieldWidth} />
                </td>

                {channelProducts.map((cp) => {
                    const channelProductIndex = channelProductIndexById[cp.id]

                    return (
                        <td key={cp.id} className={classes.priceColumn}>
                            <div>
                                <PriceField
                                    fieldWidth={fieldWidth}
                                    csuIndex={index}
                                    cpIndex={channelProductIndex}
                                />
                            </div>
                        </td>
                    )
                })}
            </>
        ),
        [
            channelProductIndexById,
            channelProducts,
            fieldWidth,
            classes.directionIcon,
            classes.firstColumn,
            classes.priceColumn,
            classes.priceField,
            onSetPriceForStockUnits,
        ]
    )

    return (
        <TableVirtuoso
            ref={ref}
            data={stockUnits}
            components={components}
            style={tableStyles}
            totalListHeightChanged={setContainerHeight}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={itemContent}
            computeItemKey={computeStockUnitItemKey}
        />
    )
})

function PriceField(props: { cpIndex: number; csuIndex: number; fieldWidth: number }) {
    const { cpIndex, csuIndex, fieldWidth } = props
    const classes = usePricingStyles({ fieldWidth })
    const { control } = useProductFormContext()
    const channelStockUnits = useWatch({
        control,
        name: `channel_products.${cpIndex}.channel_stock_units`,
    })

    const channelId = useWatch({ control, name: `channel_products.${cpIndex}.shop.channel.id` })
    const channelValidator = useMemo(() => createChannelValidator(channelId), [channelId])

    /**
     * Get all non empty dependencies, excluding itself (because it already validates its own field)
     */
    const nonEmptyDependencies = useMemo(() => {
        return channelStockUnits.reduce((acc, csu, index) => {
            if (parseInt(csu.price) > 0 && index !== csuIndex) {
                acc.push(`channel_products.${cpIndex}.channel_stock_units.${index}.price`)
            }

            return acc
        }, [] as Array<`channel_products.${number}.channel_stock_units.${number}.price`>)
    }, [channelStockUnits, cpIndex, csuIndex])

    const [isPromotionPeriod, placeholder, id, price] = useWatch({
        control,
        name: [
            `channel_products.${cpIndex}.is_promotion_period`,
            `channel_products.${cpIndex}.channel_stock_units.${csuIndex}.placeholder`,
            `channel_products.${cpIndex}.channel_stock_units.${csuIndex}.stock_unit.id`,
            `channel_products.${cpIndex}.channel_stock_units.${csuIndex}.price`,
        ],
    })
    const isDisabled = isPromotionPeriod || placeholder

    return (
        <>
            <FormField
                key={id} // forces react-hook-form to re-register
                fullWidth
                control={control}
                value={price}
                name={`channel_products.${cpIndex}.channel_stock_units.${csuIndex}.price`}
                type="number"
                shouldTransform={false}
                placeholder={gettext('Price')}
                disabled={isDisabled}
                helperText={<>&nbsp;</>}
                className={classes.rowPriceField}
                InputProps={{ className: classes.priceField }}
                inputProps={{ min: 0 }}
                rules={{
                    validate: {
                        validatePrices: (value: string, form) => {
                            if (isDisabled) {
                                return true
                            }

                            return channelValidator.validateChannelStockUnitPrice(
                                value,
                                form.channel_products[cpIndex]
                            )
                        },
                    },
                    deps: nonEmptyDependencies,
                }}
            />
        </>
    )
}

function ProductPricingSkuColumn(props: { suIndex: number; fieldWidth: number }) {
    const { suIndex, fieldWidth } = props
    const { control } = useProductFormContext()
    const sku = useWatch({ control, name: `stock_units.${suIndex}.sku`, exact: true })
    const classes = usePricingStyles({ fieldWidth })

    return (
        <Typography className={classNames(classes.skuColumn, !sku && classes.skuPlaceholder)}>
            {sku || `${gettext('Stock unit')} ${suIndex + 1}`}
        </Typography>
    )
}
