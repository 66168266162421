import { type ChangeEvent, useMemo, useCallback } from 'react'
import { useWatch } from 'react-hook-form'

import { orange, green, blue, lightBlue, lightGreen, red, yellow } from '@material-ui/core/colors'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import type { Theme } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

import classNames from 'classnames'
import moment from 'moment'

import FormField from '~/common/components/form-field'
import { getPath } from '~/oakra/components/base-app'
import { DebouncedTextField } from '~/oakra/components/inputs'
import { type ProductFormSchema, useProductFormContext } from '~/products/hooks/useProductForm'

const DEFAULT_FIELD_WIDTH = 125
const DISCOUNT_COLORS = [
    orange[400],
    green[400],
    blue[400],
    red[400],
    yellow[400],
    lightBlue[400],
    lightGreen[400],
    orange[600],
    green[600],
    blue[600],
    red[600],
    yellow[600],
    lightBlue[600],
    lightGreen[600],
]

export const usePricingStyles = makeStyles<Theme, { fieldWidth: number }>((theme) => ({
    table: {
        display: 'block',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        borderSpacing: '10px 0',
        paddingBottom: '1rem',
    },
    priceFieldColumn: {
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: (props) => props.fieldWidth,
        },
    },
    priceField: {
        height: 35,
    },
    priceRow: {
        verticalAlign: 'middle',
    },
    priceColumn: {
        maxWidth: (props) => props.fieldWidth,
        textAlign: 'center',
    },
    firstColumn: {
        width: (props) => props.fieldWidth,
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
    },
    directionIcon: {
        color: theme.palette.grey[500],
    },
    pricingColumnHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    pricingChannelIconURL: {
        width: 20,
        marginRight: 5,
    },
    pricingColumnShopName: {
        fontSize: '0.9rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    skuPlaceholder: {
        color: 'grey',
    },
    skuColumn: {
        maxWidth: '13ch',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    discountIndicator: {
        width: 7,
        height: 35,
        background: '#ccc',
        margin: '8px 0 0 0',
        borderRadius: '0 4px 4px 0',
    },
    csudField: {
        borderRadius: '4px 0 0 4px',
        '& > fieldset': {
            borderRight: 'none',
        },
    },

    discountInfo: {
        fontWeight: 'normal',
        padding: 10,
    },
    discountDateLabel: {
        color: 'rgba(0, 0, 0, 0.6)',
        marginTop: '8px',
        fontSize: '9px',
    },
    discountDate: {},
    discountLink: {
        fontSize: 14,
        color: '#000000',
        textDecoration: 'none',
        '&:hover': {
            color: orange[700],
        },
    },
}))

type ChannelProduct = ProductFormSchema['channel_products'][number]

type Props = {
    channelProducts: ChannelProduct[]
    activeDiscountIndexLookup: Map<string, number[]>
    discountIndexByCSUId: Record<string, number>
    stockUnits: ProductFormSchema['stock_units']
    fieldWidth?: number
    onSetPriceForChannelProduct: (
        cp: ProductFormSchema['channel_products'][number]
    ) => (e: ChangeEvent<HTMLInputElement>) => void
    onSetPriceForStockUnits: (sku: string) => (e: ChangeEvent<HTMLInputElement>) => void
}

export default function PricingTable({
    channelProducts,
    stockUnits,
    fieldWidth = DEFAULT_FIELD_WIDTH,
    discountIndexByCSUId,
    activeDiscountIndexLookup,
    onSetPriceForChannelProduct,
    onSetPriceForStockUnits,
}: Props) {
    const classes = usePricingStyles({ fieldWidth })
    const hasMultipleChannels = channelProducts.length > 1

    const channelStockUnitLookup = useMemo(() => {
        return channelProducts.reduce((map, cp) => {
            cp.channel_stock_units.forEach((csu) => {
                const key = `${cp.id}-${csu.stock_unit?.sku}`
                map.set(key, csu)
            })
            return map
        }, new Map<string, ChannelProduct['channel_stock_units'][number]>())
    }, [channelProducts])

    return (
        <table className={classes.table}>
            <tbody>
                <tr>
                    <th scope="col" colSpan={hasMultipleChannels ? 2 : 1}></th>

                    {hasMultipleChannels &&
                        channelProducts.map((cp, cpIndex) => (
                            <th
                                key={cp.id || cpIndex}
                                scope="col"
                                className={classes.priceFieldColumn}
                            >
                                <div>
                                    <DebouncedTextField
                                        placeholder={gettext('Set all')}
                                        variant="outlined"
                                        margin="dense"
                                        InputProps={{ className: classes.priceField }}
                                        onChange={onSetPriceForChannelProduct(cp)}
                                        disabled={cp.is_promotion_period}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                    <KeyboardArrowDownIcon className={classes.directionIcon} />
                                </div>
                            </th>
                        ))}
                </tr>

                <tr>
                    <td colSpan={hasMultipleChannels ? 2 : 1} />
                    {channelProducts.map((cp, cpIndex) => (
                        <td key={cp.id || cpIndex} className={classes.priceColumn}>
                            <div className={classes.pricingColumnHeader}>
                                <img
                                    alt={cp.shop.channel.name}
                                    className={classes.pricingChannelIconURL}
                                    src={cp.shop.channel.channel_square_icon_url}
                                />
                                <Typography className={classes.pricingColumnShopName}>
                                    {cp.shop.shop_name}
                                </Typography>
                            </div>
                        </td>
                    ))}
                </tr>

                {stockUnits.map((su, suIndex) => (
                    <tr key={su.id || suIndex} className={classes.priceRow}>
                        {channelProducts.length > 1 && (
                            <td>
                                <div className={classes.firstColumn}>
                                    <DebouncedTextField
                                        placeholder={gettext('Set all')}
                                        variant="outlined"
                                        margin="dense"
                                        InputProps={{ className: classes.priceField }}
                                        onChange={onSetPriceForStockUnits(su.sku)}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                    <KeyboardArrowRightIcon className={classes.directionIcon} />
                                </div>
                            </td>
                        )}

                        <td>
                            <ProductPricingSkuColumn suIndex={suIndex} fieldWidth={fieldWidth} />
                        </td>

                        {channelProducts.map((cp) => {
                            const key = `${cp.id}-${su.sku}`
                            const csu = channelStockUnitLookup.get(key)
                            if (!csu) {
                                return null
                            }

                            // Multiple active CSUDs may exist in cases where skus are present
                            // in multiple product listings for the shop
                            const csudIndexes =
                                activeDiscountIndexLookup.get(
                                    `${cp.shop.id}_${csu.stock_unit?.id}`
                                ) ?? []

                            return (
                                <td key={cp.id} className={classes.priceColumn}>
                                    <div>
                                        {csudIndexes.length && (
                                            <PriceField
                                                fieldWidth={fieldWidth}
                                                csudIndexes={csudIndexes}
                                                discountIndex={
                                                    discountIndexByCSUId[csu.id?.toString() ?? '']
                                                }
                                            />
                                        )}
                                    </div>
                                </td>
                            )
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip)

function PriceField(props: { csudIndexes: number[]; discountIndex: number; fieldWidth: number }) {
    const { csudIndexes: csudIndexes, discountIndex, fieldWidth } = props
    const classes = usePricingStyles({ fieldWidth })

    const { control, setValue } = useProductFormContext()
    const discount = useWatch({
        control,
        name: `product_discounts.${discountIndex}.discount`,
    })
    const handlePriceChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            csudIndexes.forEach((index) => {
                setValue(`active_channel_stock_unit_discounts.${index}.price`, event.target.value)
            })
        },
        [setValue, csudIndexes]
    )

    if (!csudIndexes.length) {
        return null
    }

    const color = DISCOUNT_COLORS[discountIndex % DISCOUNT_COLORS.length]
    const discountInfo = (
        <div className={classes.discountInfo}>
            <div>
                <a
                    href={getPath('discount').replace('discount_id', discount.id)}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.discountLink}
                >
                    {discount.name}
                </a>
            </div>
            {discount.start_date && (
                <>
                    <div className={classes.discountDateLabel}>Start: </div>
                    <div className={classes.discountDate}>
                        {moment(discount.start_date).format('dddd, D MMM YYYY, HH:mm')}
                    </div>
                </>
            )}
            {discount.end_date && (
                <>
                    <div className={classes.discountDateLabel}>End: </div>
                    <div className={classes.discountDate}>
                        {moment(discount.end_date).format('dddd, D MMM YYYY, HH:mm')}
                    </div>
                </>
            )}
        </div>
    )

    return (
        <div style={{ display: 'flex' }}>
            <FormField
                fullWidth
                control={control}
                name={`active_channel_stock_unit_discounts.${csudIndexes[0]}.price`}
                onChange={handlePriceChange}
                type="number"
                shouldTransform={false}
                placeholder={gettext('Discount Price')}
                InputProps={{ className: classNames(classes.priceField, classes.csudField) }}
                inputProps={{ min: 0 }}
            />
            <LightTooltip title={discountInfo} placement="top-start" interactive leaveDelay={1000}>
                <div className={classes.discountIndicator} style={{ background: color }}></div>
            </LightTooltip>
        </div>
    )
}

function ProductPricingSkuColumn(props: { suIndex: number; fieldWidth: number }) {
    const { suIndex, fieldWidth } = props
    const { control } = useProductFormContext()
    const sku = useWatch({ control, name: `stock_units.${suIndex}.sku`, exact: true })
    const classes = usePricingStyles({ fieldWidth })

    return (
        <Typography className={classNames(classes.skuColumn, !sku && classes.skuPlaceholder)}>
            {sku || `${gettext('Stock unit')} ${suIndex + 1}`}
        </Typography>
    )
}
