import { memo } from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import type { default as Quill } from 'quill'

import { useProductFormContext } from '../hooks/useProductForm'

import type { BasicProductInfoProps } from './basic-product-info'

import { FormField, FormRichText } from '~/common/components'
import { DEFAULT_MAX_SHORT_DESCRIPTION_LENGTH } from '~/consts'
import { stripHTMLTags } from '~/tools/utils'

export default memo(function BasicProductInfoItem({
    tab,
    language,
    nameMaxLength,
    descriptionMaxLength,
    field,
    channelProductIndex,
    required,
    hasShortDescription,
    shortDescriptionMaxLength = DEFAULT_MAX_SHORT_DESCRIPTION_LENGTH,
    onImageChange,
}: Omit<BasicProductInfoProps, 'languages' | 'onChangeTab'> & {
    language: string
    required: boolean
    onImageChange: (files: File[], quill: Quill) => Promise<void>
}) {
    const { control } = useProductFormContext()
    const nameField = isChannelProductField(field, channelProductIndex)
        ? (`channel_products.${channelProductIndex}.translations.${language}.name` as const)
        : (`translations.${language}.name` as const)

    const descriptionField = isChannelProductField(field, channelProductIndex)
        ? (`channel_products.${channelProductIndex}.translations.${language}.description` as const)
        : (`translations.${language}.description` as const)

    const shortDescriptionField = isChannelProductField(field, channelProductIndex)
        ? (`channel_products.${channelProductIndex}.translations.${language}.short_description` as const)
        : (`translations.${language}.short_description` as const)

    return (
        <Stack
            gap={2}
            role="tabpanel"
            hidden={tab !== language}
            sx={{
                display: tab !== language ? 'none' : 'flex',
                py: 2.5,
            }}
        >
            <div>
                <FormField
                    fullWidth
                    showTextCount
                    control={control}
                    name={nameField}
                    label={gettext('Name')}
                    margin="dense"
                    variant="outlined"
                    inputProps={{ maxLength: nameMaxLength }}
                    rules={{
                        required: {
                            value: required,
                            message: gettext('Name is required'),
                        },
                        maxLength: {
                            value: nameMaxLength,
                            message: gettext('Name is too long'),
                        },
                    }}
                />
            </div>

            {hasShortDescription && (
                <div>
                    <Typography variant="subtitle1" color="textSecondary">
                        {gettext('Short Description')}
                    </Typography>

                    <FormRichText
                        showMaxLength
                        control={control}
                        name={shortDescriptionField}
                        maxLength={shortDescriptionMaxLength}
                        rules={{
                            validate: {
                                maxLength: (value: string | null) => {
                                    return (
                                        !shortDescriptionField ||
                                        !value ||
                                        stripHTMLTags(value).length <= shortDescriptionMaxLength ||
                                        gettext('Short description is too long')
                                    )
                                },
                            },
                        }}
                    />
                </div>
            )}

            <div>
                <Typography variant="subtitle1" color="textSecondary">
                    {gettext('Description')}
                </Typography>

                <FormRichText
                    showMaxLength
                    control={control}
                    name={descriptionField}
                    maxLength={descriptionMaxLength}
                    rules={{
                        validate: (value: string) => {
                            const clean = stripHTMLTags(value)
                            if (!clean.length && required) {
                                return gettext('Description is required')
                            }

                            return (
                                clean.length <= descriptionMaxLength ||
                                gettext('Description is too long')
                            )
                        },
                    }}
                    onImageUpload={onImageChange}
                />
            </div>
        </Stack>
    )
})

function isChannelProductField(
    field: 'product' | 'channel_product',
    index: number | undefined
): index is number {
    return field === 'channel_product' && typeof index === 'number'
}
