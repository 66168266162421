import { connect } from 'react-redux'

import StockAdjustmentGroup from '../components/group'

const mapStateToProps = (state) => ({
    stockAdjustmentGroupAPIURL: state.initial.endpoints.stockAdjustmentGroupAPIURL,
    stockAdjustmentGroupCreateAPIURL: state.initial.endpoints.stockAdjustmentGroupCreateAPIURL,
    stockAdjustmentsUpdateAPIURL: state.initial.endpoints.stockAdjustmentsUpdateAPIURL,
    stockAdjustmentsAPIURL: state.initial.endpoints.stockAdjustmentsAPIURL,
    stores: state.initial.stores,
    language: state.initial.language,
    userID: state.initial.user.id,
    staticRoot: state.initial.staticRoot,
})

export default connect(mapStateToProps)(StockAdjustmentGroup)
