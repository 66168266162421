import { copyObject } from '../../tools/utils'
import { ADD_STORE, DELETE_STORE, UPDATE_STORE } from '../actions/stores'

const stores = (state = window.__INITIAL.stores, action) => {
    // inject stores data in base Django template
    let newState
    let index
    switch (action.type) {
        case ADD_STORE:
            newState = copyObject(state)
            newState.push(action.store)
            return newState
        case DELETE_STORE:
            newState = copyObject(state)
            index = newState.findIndex((store) => store.id == action.storeID)
            newState.splice(index, 1)
            return newState
        case UPDATE_STORE:
            newState = copyObject(state)
            index = newState.findIndex((store) => store.id == action.store.id)
            newState[index] = action.store
            return newState
        default:
            return state
    }
}

export default stores
