import { useCallback, useState } from 'react'
import { useWatch } from 'react-hook-form'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { type DragEndEvent } from '@dnd-kit/core/dist/types/events'
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'

import { type AssignedMedia, type Media, MediaStatus, MediaType } from '~/common/schemas/media'
import {
    ACCEPT_IMAGE_TYPE,
    ACCEPT_VIDEO_TYPE,
    MAX_IMAGE_UPLOAD_SIZE,
    MAX_VIDEO_UPLOAD_SIZE,
} from '~/constants'
import { MediaUploadError, useUploadMediaMutation } from '~/products/api/uploadImage'
import FileUploadButton from '~/products/components/file-upload-button'
import MediaCenter from '~/products/components/media-center'
import MediaPreview from '~/products/components/media-preview'
import { useProductFormContext } from '~/products/hooks/useProductForm'

const useStyles = makeStyles((theme) => ({
    uploadButton: {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid ' + theme.palette.grey[400],
    },
    selectFromSave: {
        fontSize: '0.8rem',
        textAlign: 'center',
        width: 100,
        height: 49,
        borderRadius: 0,
    },
    mediaManagerList: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
    },
    mediaSize: {
        width: 100,
        height: 100,
    },
    dropzone: {
        height: 49,
        width: 98,
        backgroundColor: theme.palette.grey[100],
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.8rem',
        borderRadius: 0,
    },
    dropzoneActive: {
        backgroundColor: theme.palette.grey[400],
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
    },
    promptMessage: {
        fontSize: '0.8rem',
        marginLeft: 5,
        textAlign: 'center',
    },
    dragZone: {
        display: 'flex',
        gap: 20,
        maxWidth: '100%',
        flexWrap: 'wrap',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
    },
}))

export type MediaShowcaseProps = {
    fieldPath: string
    mediaPath: string
    mediaType: string
    maxMediaObjs?: number
    disabled?: boolean
}

const toMedia = (assignedMedia: AssignedMedia): Media => ({
    id: assignedMedia.media_id,
    name: assignedMedia.name,
    file: assignedMedia.url,
    status: assignedMedia.status,
    media_type: assignedMedia.media_type,
    preview: assignedMedia.preview,
})

function MediaShowcase({
    fieldPath,
    mediaPath,
    mediaType,
    maxMediaObjs,
    disabled,
}: MediaShowcaseProps) {
    const classes = useStyles()

    const { control, setError, setValue, getValues } = useProductFormContext()

    const [showMediaCenter, setShowMediaCenter] = useState(false)

    const store = getValues('store')
    const { mutate } = useUploadMediaMutation(store.id)

    const getMediaObjs = useCallback(() => getValues(mediaPath) ?? [], [getValues, mediaPath])

    const getAssignedMediaObjs = useCallback(() => getValues(fieldPath), [getValues, fieldPath])

    const assignedMediaObjs = useWatch({ control, name: fieldPath, exact: true })

    const onUploadSuccess = (data: Map<string, { id: string; file: string }>) => {
        const updatedMediaObjs =
            getMediaObjs()?.map((media) => {
                if (media.media_type !== mediaType) {
                    return media
                }
                return {
                    ...media,
                    id: data.get(media.name ?? '')?.id ?? media.id,
                    file: data.get(media.name ?? '')?.file ?? media.file,
                    status: data.has(media.name ?? '') ? 'uploaded' : media.status,
                }
            }) || []
        setValue(mediaPath, updatedMediaObjs)

        const updatedAssignedMediaObjs =
            getAssignedMediaObjs()?.map((media, index) => ({
                ...media,
                url: data.get(media.name ?? '')?.file ?? media.url,
                media_id: data.get(media.name ?? '')?.id ?? media.media_id,
                display_order: index,
                status: data.has(media.name ?? '') ? 'uploaded' : media.status,
            })) || []
        setValue(fieldPath, updatedAssignedMediaObjs)
    }

    const onUploadError = (error) => {
        if (error instanceof MediaUploadError) {
            const validMediaObjs =
                getMediaObjs()?.filter(
                    (media) =>
                        media.media_type !== mediaType ||
                        (!error.mediaObjs.has(media.name ?? '') && !!media?.id)
                ) || []
            setValue(mediaPath, validMediaObjs)
            setError(mediaPath, {
                type: 'manual',
                message: error.message,
            })

            const validAssignedMediaObjs =
                getAssignedMediaObjs()?.filter(
                    (media) => !error.mediaObjs.has(media.name ?? '') && !!media?.id
                ) || []
            setValue(fieldPath, validAssignedMediaObjs)
            setError(fieldPath, {
                type: 'manual',
                message: error.message,
            })
        }
    }

    const handleDrag = (newMediaObjs: File[]) => {
        const assignedMediaObjs = getAssignedMediaObjs() || []
        const nextAssignedMediaLength = assignedMediaObjs.length + newMediaObjs.length
        if (maxMediaObjs && nextAssignedMediaLength > maxMediaObjs) {
            return
        }

        const mediaObjs = getMediaObjs() || []
        const mediaMap = new Map(mediaObjs?.map((media) => [media.name, media]))

        const uploadingMediaObjs = newMediaObjs
            .filter((m) => !mediaMap.has(m.name))
            .map((m, index) => ({
                name: m.name,
                preview: URL.createObjectURL(m),
                content_type: m.type,
                media_type: mediaType,
                status: MediaStatus.UPLOADING,
                file: m,
            }))

        const alreadyAddedAssignedMediaNames = new Set(assignedMediaObjs?.map((i) => i.name) || [])

        const newAssignedMediaObjs = newMediaObjs
            .filter((media) => !alreadyAddedAssignedMediaNames.has(media.name))
            .map((assignedMedia, index) => {
                if (mediaMap.has(assignedMedia.name)) {
                    const media = mediaMap.get(assignedMedia.name)
                    return {
                        media_id: media.id,
                        name: media.name,
                        display_order: assignedMediaObjs.length + index,
                        status: media.status,
                        url: media.file,
                        media_type: mediaType,
                    }
                } else {
                    return {
                        name: assignedMedia.name,
                        preview: URL.createObjectURL(assignedMedia),
                        display_order: assignedMediaObjs.length + index,
                        status: MediaStatus.UPLOADING,
                        media_type: mediaType,
                    }
                }
            })

        setValue(mediaPath, [...mediaObjs, ...uploadingMediaObjs])
        setValue(fieldPath, [...assignedMediaObjs, ...newAssignedMediaObjs])

        mutate(
            {
                mediaObjs: uploadingMediaObjs.map(({ name, content_type, media_type, file }) => ({
                    name,
                    content_type,
                    media_type,
                    file,
                })),
            },
            {
                onSuccess: onUploadSuccess,
                onError: onUploadError,
            }
        )
    }

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    )

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event
        const activeName = active.id
        const overName = over.id
        if (activeName === overName) {
            return
        }

        let fieldsMedia = getAssignedMediaObjs() || []
        const oldIndex = fieldsMedia.findIndex((item) => item.name === active.id)
        const newIndex = fieldsMedia.findIndex((item) => item.name === over.id)

        fieldsMedia = arrayMove(getAssignedMediaObjs() || [], oldIndex, newIndex)
        const reorderedAssignedMedia = fieldsMedia?.map((media, index) => {
            media.display_order = index
            return media
        })
        setValue(fieldPath, reorderedAssignedMedia)
    }

    const handleDelete = (index: number) => {
        const assignedMediaObjs = [...(getAssignedMediaObjs() || [])].filter((_, i) => i !== index)
        const reorderedValues =
            assignedMediaObjs?.map((value, index) => {
                value.display_order = index
                return value
            }) ?? []
        setValue(fieldPath, reorderedValues)
    }

    const handleSelect = (groupedMedia: Media[]) => {
        const assignedMediaObjs = getAssignedMediaObjs() || []
        const alreadyAddedAssignedMediaNames = new Set(assignedMediaObjs?.map((i) => i.name) || [])

        const mediaToAdd = groupedMedia.filter(
            (media) => !alreadyAddedAssignedMediaNames.has(media.name)
        )
        setValue(fieldPath, [
            ...assignedMediaObjs,
            ...mediaToAdd.map((media: Media, index: number) => ({
                name: media.name,
                display_order: assignedMediaObjs.length + index,
                type: media.content_type,
                status: media.status,
                url: media.file,
                media_id: media.id,
                media_type: media.media_type,
            })),
        ])
    }

    return (
        <div className={classes.mediaManagerList}>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={
                        assignedMediaObjs?.filter((item) => item.name)?.map((item) => item.name) ||
                        []
                    }
                >
                    <div className={classes.dragZone}>
                        {!disabled && (
                            <div className={classNames(classes.uploadButton, classes.mediaSize)}>
                                <div>
                                    <Button
                                        className={classes.selectFromSave}
                                        onClick={() => {
                                            setShowMediaCenter(!showMediaCenter)
                                        }}
                                    >
                                        {gettext('Select')}
                                    </Button>
                                </div>
                                <div>
                                    <FileUploadButton
                                        onDrop={handleDrag}
                                        accept={
                                            mediaType === MediaType.IMAGE
                                                ? ACCEPT_IMAGE_TYPE
                                                : ACCEPT_VIDEO_TYPE
                                        }
                                        className={classes.dropzone}
                                        maxSize={
                                            mediaType === MediaType.IMAGE
                                                ? MAX_IMAGE_UPLOAD_SIZE
                                                : MAX_VIDEO_UPLOAD_SIZE
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {assignedMediaObjs?.map((media, index) => (
                            <SortableMedia
                                key={media.id || media.name}
                                assignedMedia={media}
                                disabled={disabled}
                                onDelete={(m) => {
                                    handleDelete(index)
                                }}
                            />
                        ))}
                    </div>
                </SortableContext>
            </DndContext>
            <MediaCenter
                path={mediaPath}
                open={showMediaCenter}
                mediaType={mediaType}
                onClose={() => {
                    setShowMediaCenter(false)
                }}
                onAdd={handleSelect}
            />
        </div>
    )
}

export type SortableMediaProps = {
    assignedMedia: AssignedMedia
    onDelete?: (media: Media) => void
    disabled?: boolean
}

function SortableMedia({ assignedMedia, onDelete, disabled }: SortableMediaProps) {
    const classes = useStyles()
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: assignedMedia.name,
    })

    return (
        <div
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            key={assignedMedia.id || assignedMedia.name}
            style={{
                transform: CSS.Transform.toString(transform),
                transition: !disabled ? transition : null,
            }}
        >
            <MediaPreview
                className={classes.mediaSize}
                media={toMedia(assignedMedia)}
                hideSelect
                isExpandable
                clickToExpand
                onDelete={(media) => {
                    onDelete?.(media)
                }}
            />
        </div>
    )
}

export default MediaShowcase
