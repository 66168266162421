import { withStyles } from '@material-ui/core/styles'

import ImageManager from '../../../products/containers/image-manager'

const styles = (theme) => ({
    imageManager: {
        marginTop: 0,
        flexDirection: 'column',
    },
    dropzone: {
        order: 2,
        marginTop: 10,
        height: 50,
        width: '100%',
    },
    preview: {
        paddingLeft: 0,
        marginLeft: 0,
        order: 1,
        width: '100%',
        overflowX: 'auto',
    },
})

export default withStyles(styles)(ImageManager)
