import React from 'react'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import AddressEditor from '../containers/address-editor'

import AddressInfo from './address-info'

const styles = (theme) => ({
    address: {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 8,
        padding: '12px 12px 6px 12px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    actionBar: {},
    defaultLabel: {
        margin: '2px 2px 2px 0',
    },
    createAddress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        minHeight: 100,
    },
})

const defaltLabels = {
    is_default_shipping_address: gettext('Default shipping'),
    is_default_billing_address: gettext('Default billing'),
    is_default_invoice_address: gettext('Default invoice'),
}
class AddressManager extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editorState: null, // 'create' | 'update' | null
            editingItem: null,
        }
    }

    handleSelect = (item) => {
        const { onSelect } = this.props
        onSelect && onSelect(item)
    }

    handleEdit = (item) => {
        this.setState({
            editingItem: item,
            editorState: 'update',
        })
    }

    handleCreate = () => {
        const { customerId } = this.props

        this.setState({
            editingItem: { customer: customerId },
            editorState: 'create',
        })
    }

    handleEditorClose = (data) => {
        const { onChange } = this.props
        const { editorState } = this.state

        if (data) {
            onChange &&
                onChange({
                    address: data,
                    action: editorState,
                })
        }

        this.setState({
            editingItem: {},
            editorState: null,
        })
    }

    render() {
        const { classes, data, showDefaultLabel, allowSelect } = this.props
        const { editorState, editingItem } = this.state

        return (
            <Grid container spacing={2}>
                {data.map((address) => (
                    <Grid item xs={12} sm={4} key={address.id}>
                        <div className={classes.address}>
                            <AddressInfo data={address} />

                            <div className={classes.actionBar}>
                                {allowSelect && (
                                    <Button
                                        color="secondary"
                                        onClick={() => this.handleSelect(address)}
                                    >
                                        {gettext('Select')}
                                    </Button>
                                )}

                                <Button color="secondary" onClick={() => this.handleEdit(address)}>
                                    {gettext('Edit')}
                                </Button>
                            </div>

                            {showDefaultLabel && (
                                <div className={classes.defaultLabels}>
                                    {Object.keys(defaltLabels).map(
                                        (k) =>
                                            address[k] && (
                                                <Chip
                                                    size="small"
                                                    color="secondary"
                                                    variant="outlined"
                                                    className={classes.defaultLabel}
                                                    label={defaltLabels[k]}
                                                />
                                            )
                                    )}
                                </div>
                            )}
                        </div>
                    </Grid>
                ))}

                <Grid item xs={12} sm={4}>
                    <div className={classes.createAddress}>
                        <Button color="secondary" onClick={this.handleCreate}>
                            <AddIcon /> {gettext('Add address')}
                        </Button>
                    </div>
                </Grid>

                {editorState && (
                    <AddressEditor inputData={editingItem} onClose={this.handleEditorClose} />
                )}
            </Grid>
        )
    }
}

export default withStyles(styles)(AddressManager)
