import React from 'react'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import grey from '@material-ui/core/colors/grey'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SwapCallsIcon from '@material-ui/icons/SwapCalls'

import { getPath } from '../../oakra/components/base-app'

import AddChannelStepper from './add-channel-stepper'

const styles = (theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    stepper: {
        borderBottom: '1px solid ' + grey[200],
        padding: 20,
    },
    channelInstructions: {
        marginTop: 50,
        width: '75%',
    },
    channels: {
        display: 'flex',
        marginBottom: 50,
        marginTop: 30,
        justifyContent: 'center',
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 30,
    },
    channelCard: {
        width: 250,
        marginRight: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 20,
    },
    channelIcon: {
        width: '80%',
    },
    messageTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 5,
    },
    messageTitle: {
        color: theme.palette.primary.main,
        margin: 0,
    },
    checkIcon: {
        marginLeft: 7,
        fontSize: 25,
    },
    cardActions: {
        width: '100%',
        borderTop: '1px solid ' + grey[200],
    },
})

class ChannelResult extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}

        this.handleClickFinish = this.handleClickFinish.bind(this)
    }

    componentDidMount() {
        const { channelAPIURL } = this.props
        const { channel_id } = this.props.match.params

        fetch(channelAPIURL.replace('channel_id', channel_id))
            .then((response) => response.json())
            .then((channel) => {
                this.setState({ channel })
            })
    }

    handleClickFinish() {
        window.location.href = getPath('integrations')
    }

    render() {
        const { classes } = this.props

        const instructionMessage =
            'You successfully linked your %(channelName)s account! It may take several minutes for syncing to complete.'
        const instructionMessageFormat = ngettext(instructionMessage, instructionMessage, 1)

        return (
            <div className={classes.container}>
                <Paper className={classes.channelInstructions}>
                    <div className={classes.stepper}>
                        <AddChannelStepper activeStep={3} />
                    </div>
                    <div className={classes.cardContainer}>
                        {this.state.channel && (
                            <Card
                                key={this.state.channel.id}
                                className={classes.channelCard}
                                raised={false}
                            >
                                <img
                                    className={classes.channelIcon}
                                    src={this.state.channel.channel_icon_url}
                                />
                                <CardContent>
                                    <div className={classes.messageTitleContainer}>
                                        <Typography
                                            className={classes.messageTitle}
                                            gutterBottom
                                            variant="h5"
                                            component="h2"
                                        >
                                            {gettext('Success')}
                                        </Typography>
                                        <CheckCircleIcon
                                            className={classes.checkIcon}
                                            color="primary"
                                        />
                                    </div>
                                    <Typography component="p">
                                        {interpolate(
                                            instructionMessageFormat,
                                            {
                                                channelName: this.state.channel.name.replace(
                                                    /\b\w/g,
                                                    (l) => l.toUpperCase()
                                                ),
                                            },
                                            true
                                        )}
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <Button
                                        size="small"
                                        color="primary"
                                        onClick={this.handleClickFinish}
                                    >
                                        <SwapCallsIcon />
                                        {gettext('Back to Integrations')}
                                    </Button>
                                </CardActions>
                            </Card>
                        )}
                    </div>
                </Paper>
            </div>
        )
    }
}

ChannelResult = withStyles(styles)(ChannelResult)

export default ChannelResult
