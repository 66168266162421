import { useMemo, useState } from 'react'

import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import PropTypes from 'prop-types'

import FormSearchField from '../../../../../components/FormSearchField'
import { useTableStyles } from '../../../../../theme'
import { formatScopeName, formatShopName } from '../user-permissions'

import { useStyles } from './styles'

export default function Shops({ permissions, checkHasPermission, onPermissionChanged }) {
    const classes = useStyles()
    const tableClasses = useTableStyles()

    const [searchTerm, setSearchTerm] = useState('')

    const filteredPermissions = useMemo(() => {
        return permissions.filter((p) => {
            const text = searchTerm.toLowerCase()
            return (
                p.shopName?.toLowerCase().includes(text) ||
                p.channelName?.toLowerCase().includes(text)
            )
        })
    }, [permissions, searchTerm])

    return (
        <>
            <Table className={tableClasses.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3} className={classes.tableCell}>
                            <Box className={classes.tableCellContainer}>
                                <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                                    {gettext('Shops')}
                                </Typography>
                                <FormSearchField
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    onClear={() => setSearchTerm('')}
                                />
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
            {filteredPermissions.map(
                ({ shopId, shopName, channelName, permissions: currentPermissions }, idx) => (
                    <div key={`shop_permission_${shopId}_${idx}`}>
                        <Table className={tableClasses.table} size="small" key={shopId}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ marginRight: 15 }}>
                                                <Typography variant="subtitle2">
                                                    {formatShopName({ shopName, channelName })}
                                                </Typography>
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell width={50}>{gettext('View')}</TableCell>
                                    <TableCell width={50}>{gettext('Edit')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(currentPermissions).map((scope) => (
                                    <TableRow key={scope}>
                                        <TableCell className={tableClasses.tableCell}>
                                            {formatScopeName(scope)}
                                        </TableCell>
                                        {Object.keys(currentPermissions[scope]).map(
                                            (permission) => {
                                                const permissionCode =
                                                    currentPermissions[scope][permission]
                                                return (
                                                    <TableCell
                                                        key={permissionCode}
                                                        className={tableClasses.tableCell}
                                                    >
                                                        <Checkbox
                                                            color="secondary"
                                                            checked={checkHasPermission(
                                                                permissionCode,
                                                                shopId
                                                            )}
                                                            onChange={(_, checked) => {
                                                                onPermissionChanged({
                                                                    codenames: [permissionCode],
                                                                    value: checked,
                                                                    objectPk: shopId,
                                                                    contentModel: 'shop',
                                                                })
                                                            }}
                                                        />
                                                    </TableCell>
                                                )
                                            }
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                )
            )}
        </>
    )
}

Shops.propTypes = {
    permissions: PropTypes.array.isRequired,
    checkHasPermission: PropTypes.func.isRequired,
    onPermissionChanged: PropTypes.func.isRequired,
}
