import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'

import { post } from '../../../tools/request'

import { SaleLineItem as LineItem } from './sale-items'

const styles = (theme) => ({
    dialogRoot: {
        minWidth: 600,
        minHeight: 400,
    },
    dialogContentRoot: {},
    numberInput: {
        width: 50,
    },
    inputField: {
        marginLeft: 8,
        marginRight: 8,
    },
    logoImg: {
        height: 25,
    },
})

class FulfillmentDialog extends React.Component {
    constructor(props) {
        super(props)

        const { fulfillment, shippingProviders } = props
        let shippingProviderId = fulfillment?.shipping_provider?.id

        // select first as default
        if (!shippingProviderId && shippingProviders.length > 0) {
            shippingProviderId = shippingProviders[0].id
        }

        this.state = {
            shipping_provider_id: shippingProviderId,
            loading: false,
            error: null,
            createdTrackingNumber: null,
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSubmit = () => {
        const state = this.state
        const { initiateShippingUrl, fulfillment, onClose } = this.props

        const data = {
            shipping_provider_id: state.shipping_provider_id,
        }

        this.setState({ loading: true })

        return post(initiateShippingUrl.replace('fulfillment_pk', fulfillment.id), data).then(
            (res) => {
                if (res.ok) {
                    this.setState({
                        loading: false,
                        error: null,
                        createdTrackingNumber: res.tracking_number,
                    })
                    setTimeout(() => {
                        onClose('shipping_initiated')
                    }, 2500)
                } else {
                    this.setState({ loading: false, error: res.error })
                }
            }
        )
    }

    getLineItems = () => {
        const { fulfillment = {}, sale = {} } = this.props

        const flis = fulfillment.fulfillment_line_items || []

        const slis = {}
        sale.sale_line_items.map((sli) => {
            slis[sli.id] = sli
        })

        return flis.map((fli) => {
            const sli = slis[fli.sale_line_item]
            sli.num_sold = fli.quantity
            return sli
        })
    }

    render() {
        const {
            classes,
            fulfillment = {},
            sale = {},
            shippingProviders = [],
            open,
            mode,
            language = 'en',
            onClose,
        } = this.props
        const { shipping_provider_id, loading, error, createdTrackingNumber } = this.state
        const lines = this.getLineItems()

        return (
            <Dialog open={open} classes={{ paper: classes.dialogRoot }}>
                <DialogTitle>
                    {gettext('Fulfillment')} {fulfillment.fulfillment_id}
                </DialogTitle>

                <DialogContent classes={{ root: classes.dialogContentRoot }}>
                    {error && (
                        <Alert severity="error" style={{ marginBottom: 10 }}>
                            {error}
                        </Alert>
                    )}
                    {createdTrackingNumber && (
                        <Alert severity="success" style={{ marginBottom: 10 }}>
                            {interpolate(gettext('Created shipment with tracking number %s'), [
                                createdTrackingNumber,
                            ])}
                        </Alert>
                    )}

                    <Typography variant="body1" style={{ marginBottom: 10 }}>
                        {gettext('Shipping provider')}
                    </Typography>

                    {mode === 'view' ? (
                        <div>
                            <img
                                src={fulfillment.shipping_provider?.logo_url}
                                className={classes.logoImg}
                            />
                        </div>
                    ) : (
                        <RadioGroup
                            required
                            row
                            name="shipping_provider_id"
                            value={String(shipping_provider_id)}
                            onChange={this.handleChange}
                        >
                            {shippingProviders.map((item) => (
                                <FormControlLabel
                                    value={String(item.id)}
                                    control={<Radio />}
                                    label={<img src={item.logo_url} className={classes.logoImg} />}
                                />
                            ))}
                        </RadioGroup>
                    )}

                    <Typography variant="body1" style={{ marginTop: 10, marginBottom: 10 }}>
                        {gettext('Items')}
                    </Typography>
                    <div>
                        {lines.map((item, i) => (
                            <LineItem
                                key={i}
                                item={item}
                                sale={sale}
                                language={language}
                                isEditable={false}
                                currency={sale.currency}
                            />
                        ))}
                    </div>
                </DialogContent>

                <DialogActions>
                    {mode === 'view' ? (
                        <Button onClick={onClose} color="secondary">
                            {gettext('Close')}
                        </Button>
                    ) : (
                        <React.Fragment>
                            <Button onClick={onClose} color="secondary">
                                {gettext('Cancel')}
                            </Button>

                            <Button
                                onClick={this.handleSubmit}
                                variant="contained"
                                color="secondary"
                                disabled={loading}
                            >
                                {loading ? gettext('Creating') : gettext('Create')}
                            </Button>
                        </React.Fragment>
                    )}
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(FulfillmentDialog)
