import { connect } from 'react-redux'

import stockReport from '../components/stock-report/index'

const mapStateToProps = ({ stores, initial: { endpoints, language, user, staticRoot } }) => ({
    endpoints: {
        stockItemReportsForUserAPIURL: endpoints.stockItemReportsForUserAPIURL.replace(
            'user_pk',
            user.id
        ),
        stockItemReportsSummaryForUserAPIURL:
            endpoints.stockItemReportsSummaryForUserAPIURL.replace('user_pk', user.id),
        stockItemReportsExportForUserAPIURL: endpoints.stockItemReportsExportForUserAPIURL.replace(
            'user_pk',
            user.id
        ),
        stockItemReportBulkUpdateAPIURL: endpoints.stockItemReportBulkUpdateAPIURL.replace(
            'user_pk',
            user.id
        ),
        stockItemAPIURL: endpoints.stockItemAPIURL,
    },
    stores,
    language,
    user,
    staticRoot,
})

export default connect(mapStateToProps)(stockReport)
