// eslint-disable-next-line import/no-unresolved
import 'virtual:vite-plugin-sentry/sentry-config'
import { lazy, StrictMode, Suspense, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { legacy_createStore as createStore } from 'redux'

import App from './oakra/containers/app'
import rootReducer from './oakra/reducers'

const STALE_TIME = 1000 * 30 // 30 seconds
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: STALE_TIME,
            refetchOnWindowFocus: false,
        },
    },
})

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
    dist: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist,
    tracesSampleRate: 1.0,
    environment: import.meta.env.MODE,
})

const ReactQueryDevtoolsProduction = lazy(() =>
    import('@tanstack/react-query-devtools/production').then((d) => ({
        default: d.ReactQueryDevtools,
    }))
)

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__?.())

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        toggleDevTools: () => void
    }
}

function Oakra() {
    // FIXME: REMOVE THIS
    const [showDevtools, setShowDevtools] = useState(false)

    useEffect(() => {
        window.toggleDevTools = () => setShowDevtools((value) => !value)
    }, [])

    return (
        <StrictMode>
            <Sentry.ErrorBoundary fallback={<>An error has occurred</>}>
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools />
                    {showDevtools && (
                        <Suspense fallback={null}>
                            <ReactQueryDevtoolsProduction />
                        </Suspense>
                    )}
                    <Provider store={store}>
                        <App />
                    </Provider>
                </QueryClientProvider>
            </Sentry.ErrorBoundary>
        </StrictMode>
    )
}

ReactDOM.render(<Oakra />, document.getElementById('root'))
