import axios from 'axios'

/**
 *
 * @param {'users' | 'products' | 'auth' | 'stores' | 'channels' | 'groups' | 'sales' | 'locations' | 'fulfillment' | 'analytics' | 'promotions' | 'stock'} endpoint
 * @returns
 */
export function createAxiosInstance(endpoint) {
    return axios.create({
        baseURL: '/endpoints/' + endpoint,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
    })
}

export function getApiErrorMessage(err) {
    return err?.response?.data ?? err.message
}
