import { z } from 'zod'

/**
 * Composed by the following:
 *
 * @link https://open.shopee.com/documents/v2/v2.logistics.get_channel_list?module=95&type=1
 * @see LOGISTICS_ATTRIBUTES_V2_MAPPING
 */
export const ShopeeLogisticsProvider = z.object({
    weight_limits: z.optional(
        z.object({
            item_min_weight: z.number(),
            item_max_weight: z.number(),
        })
    ),
    volume_limit: z.object({
        item_min_volume: z.number(),
        item_max_volume: z.number(),
    }),
    has_cod: z.boolean(),
    item_max_dimension: z.optional(
        z.object({
            width: z.number(),
            length: z.number(),
            unit: z.string(),
            height: z.number(),
        })
    ),
    size_list: z.array(
        z.object({
            size_id: z.string(),
            name: z.string(),
            default_price: z.number(),
        })
    ),
    logistic_name: z.string(),
    logistic_id: z.number(),
    logistics_description: z.string(),
    fee_type: z.enum(['SIZE_INPUT', 'SIZE_SELECTION', 'FIXED_DEFAULT_PRICE', 'CUSTOM_PRICE']),
    enabled: z.boolean().default(false),
    is_free: z.boolean().default(false),
    shipping_fee: z.number().optional(),
    force_enabled: z.boolean().optional(),
    mask_channel_id: z.number(),
    block_seller_cover_shipping_fee: z.boolean(),
    support_cross_border: z.boolean(),
})
export type ShopeeLogisticsProvider = z.infer<typeof ShopeeLogisticsProvider>

export const ShopeeLogistics = z.record(z.number(), ShopeeLogisticsProvider.array())
export type ShopeeLogistics = z.infer<typeof ShopeeLogistics>
