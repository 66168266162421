import { useEffect, useRef } from 'react'
import { type VirtuosoHandle } from 'react-virtuoso'

import { useProductPageActions } from './useProductPageStore'

export type VirtualizationContainerKey = 'stock_unit' | 'pricing' | 'fulfillment_option'

export function useVirtualizationContainer(key: VirtualizationContainerKey) {
    const ref = useRef<VirtuosoHandle>(null)
    const { assignVirtualizationContainer } = useProductPageActions()

    useEffect(() => {
        if (!ref.current) {
            return
        }

        assignVirtualizationContainer(key, ref.current)
    }, [assignVirtualizationContainer, key])

    return ref
}

export function isVirtualizationContainerKey(key: string): key is VirtualizationContainerKey {
    return key === 'stock_unit' || key === 'pricing'
}
