import { useCallback, useState } from 'react'

import { type ProductFormSchema } from './useProductForm'

import { type ShopeeLogisticsProvider, type ShopeeLogistics } from '~/common/schemas/logistics-shopee'
import { useAppSelector } from '~/store'

type ChannelProducts = ProductFormSchema['channel_products']

export function useShopeeLogistics() {
    const [shopeeLogistics, setShopeeLogistics] = useState({} as ShopeeLogistics)

    const { shopeeLogisticsValuesAPIURL, shopeeLogisticsProvidersAPIURL } = useAppSelector(
        (state) => state.initial.endpoints
    )

    const fetchShopeeLogisticsProviders = useCallback(
        async (shopIDs: number[]) => {
            const uniqueShopIds = [...new Set(shopIDs)]
            const queryStringParams = uniqueShopIds.map((id) => `shop_id=${id}`)
            const queryString = queryStringParams.join('&')
            const url = `${shopeeLogisticsProvidersAPIURL}?${queryString}`

            const res = await fetch(url)
            const data = (await res.json()) as ShopeeLogistics

            setShopeeLogistics((value) => {
                const updatedLogistics = { ...value }

                Object.entries(data).forEach(([shopId, providers]) => {
                    const mergedLogistics: ShopeeLogisticsProvider[] = []
                    const shopIdInt = parseInt(shopId)

                    providers.forEach((provider) => {
                        provider.enabled = false
                        provider.is_free = false

                        const existingProvider = updatedLogistics[shopIdInt]?.find(
                            (v) => v.logistic_id === provider.logistic_id
                        )
                        const mergedValue = existingProvider
                            ? { ...provider, ...existingProvider }
                            : provider

                        mergedLogistics.push(mergedValue)
                    })

                    updatedLogistics[shopIdInt] = mergedLogistics
                })

                return updatedLogistics
            })
        },
        [shopeeLogisticsProvidersAPIURL]
    )

    const fetchShopeeLogisticsValues = useCallback(
        async (channelProductIds: number[]) => {
            const queryStringParams = channelProductIds.map((id) => `channel_product_id=${id}`)
            const queryString = queryStringParams.join('&')

            const url = `${shopeeLogisticsValuesAPIURL}?${queryString}`

            const response = await fetch(url)
            const logisticsValues = await response.json()

            setShopeeLogistics((value) => {
                const updatedLogistics = { ...value }

                Object.entries(updatedLogistics).forEach(([shopId, providers]) => {
                    const mergedLogistics: ShopeeLogisticsProvider[] = []
                    const shopIdInt = parseInt(shopId)

                    const fetchedValues = logisticsValues[shopIdInt]?.filter((v) => !!v)

                    providers.forEach((provider) => {
                        let mergedValue

                        if (fetchedValues) {
                            const fetchedValue = fetchedValues.find(
                                (v) => v.logistic_id === provider.logistic_id
                            )
                            if (fetchedValue) {
                                mergedValue = { ...provider, ...fetchedValue }
                                mergedLogistics.push(mergedValue)
                            } else {
                                mergedLogistics.push(provider)
                            }
                        } else {
                            mergedLogistics.push(provider)
                        }
                    })

                    updatedLogistics[shopIdInt] = mergedLogistics
                })

                return updatedLogistics
            })
        },
        [shopeeLogisticsValuesAPIURL]
    )

    const syncShopeeLogisticsShops = useCallback(
        async (shopeeProducts: ChannelProducts, shopeeLogistics: ShopeeLogistics) => {
            function _removeShopeeLogistics(shopIDs: number[]) {
                setShopeeLogistics((value) => {
                    const updated = { ...value }
                    shopIDs.forEach((shopID) => {
                        delete updated[shopID]
                    })

                    return updated
                })
            }

            const shopIDs = shopeeProducts.map((cp) => cp.shop.id)
            const currentShopIDs = Object.keys(shopeeLogistics).map((k) => parseInt(k))

            const shopsToRemoveIDs = currentShopIDs.filter((shopId) => !shopIDs.includes(shopId))
            const shopsToAddIDs = shopIDs.filter((shopId) => !currentShopIDs.includes(shopId))

            _removeShopeeLogistics(shopsToRemoveIDs)
            await fetchShopeeLogisticsProviders(shopsToAddIDs)
        },
        [fetchShopeeLogisticsProviders]
    )

    return {
        shopeeLogistics,
        updateShopeeLogistics: setShopeeLogistics,
        syncShopeeLogisticsShops,
        fetchShopeeLogisticsProviders,
        fetchShopeeLogisticsValues,
    }
}
