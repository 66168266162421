import React from 'react'

import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

const StockReportSummary = ({
    value,
    low_stock_item_count,
    slow_selling_item_count,
    highlight = {},
    onClick,
}) => {
    return (
        <Paper square>
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, value) => onClick(value)}
                aria-label="disabled tabs example"
            >
                <Tab
                    value="has_low_stock"
                    label={
                        <span>
                            {low_stock_item_count > 0 && (
                                <Chip size="small" label={low_stock_item_count} color="secondary" />
                            )}{' '}
                            {gettext('Low stock')}
                        </span>
                    }
                />
                <Tab
                    value="is_slow_selling"
                    label={
                        <span>
                            {slow_selling_item_count > 0 && (
                                <Chip
                                    size="small"
                                    label={slow_selling_item_count}
                                    color="secondary"
                                />
                            )}{' '}
                            {gettext('Slow selling')}
                        </span>
                    }
                />
                <Tab value="setting" label={gettext('Settings')} />
            </Tabs>
        </Paper>
    )
}

class StockReportSummaryContainer extends React.Component {
    state = { loading: false, data: {} }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        const { endpointUrl } = this.props

        this.setState({ loading: true })

        return fetch(endpointUrl)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ loading: false, data })
            })
    }

    render() {
        const { data } = this.state
        return <StockReportSummary {...this.props} {...data} />
    }
}

export default StockReportSummaryContainer
