import type { ProductFormSchema } from '../hooks/useProductForm'

import { getUniqueShopsFromChannelProducts } from '~/tools/utils'

/**
 * Returns a list of shops that are not already configured in the channel products.
 *
 * This function takes into account whether the channel allows duplicate SKUs.
 * If the channel allows duplicates, then shops that are already configured will
 * also be returned.
 *
 * If the channel does not support multiple stock units, then shops that are already
 * configured will not be returned.
 */
export function updateNotConfiguredShops(
    channelProducts: ProductFormSchema['channel_products'],
    channels: ProductFormSchema['channels'],
    shops: ProductFormSchema['store']['shops'],
    hasMultipleStockUnits: boolean
) {
    const shopIdsAlreadyConfigured = getUniqueShopsFromChannelProducts(channelProducts).map(
        ({ id }) => id
    )
    const channelById = new Map(channels.map((channel) => [channel.id, channel]))

    // Filter out shops that are already configured unless the channel allows duplicate SKUs
    return shops
        .filter((shop) => {
            const alreadyConfigured = shopIdsAlreadyConfigured.includes(shop.id)
            const channel = channelById.get(shop.channel.id)
            // If we don't have a channel, we consider the shop to be valid.
            if (!channel) {
                return true
            }

            if (
                hasMultipleStockUnits &&
                channel.category?.supports_multiple_stock_units === false
            ) {
                return false
            }

            return !alreadyConfigured || channel.allow_duplicate_skus
        })
        .map((shop) => ({
            ...shop,
            selected: false,
        }))
}
