import { useState, useMemo, useCallback } from 'react'
import { useWatch } from 'react-hook-form'
import { VirtuosoGrid } from 'react-virtuoso'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import styled from '@emotion/styled'
import classNames from 'classnames'

import { type Media, MediaStatus, MediaType } from '~/common/schemas/media'
import {
    ACCEPT_IMAGE_TYPE,
    ACCEPT_VIDEO_TYPE,
    MAX_IMAGE_UPLOAD_SIZE,
    MAX_VIRTUALIZED_HEIGHT,
    MAX_VIDEO_UPLOAD_SIZE,
} from '~/constants'
import { MediaUploadError, useUploadMediaMutation } from '~/products/api/uploadImage'
import FileUploadButton from '~/products/components/file-upload-button'
import MediaPreview from '~/products/components/media-preview'
import { useProductFormContext } from '~/products/hooks/useProductForm'

const useStyles = makeStyles((theme) => ({
    paper: {
        minHeight: 600,
    },
    mediaWrapper: {
        cursor: 'pointer',
        position: 'relative',
        display: 'inline-block',
    },
    upload: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid ' + theme.palette.grey[400],
        height: 150,
        width: 150,
    },
    dropzone: {
        backgroundColor: theme.palette.grey[100],
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        overflow: 'hidden',
        border: '1px solid ' + theme.palette.grey[400],
        height: 150,
        width: 150,
    },
    dropzoneActive: {
        backgroundColor: theme.palette.grey[400],
    },
    listContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
    },
}))

const computeItemKey = (index, data) => {
    return `item-${index}-${data.name}`
}

const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`
export type MediaCenterProps = {
    path: string
    open: boolean
    mediaType: string
    onClose?: () => void
    onAdd?: (mediaObjs: Media[]) => void
}

const getParentPath = (path) => {
    const parts = path.split('.')
    return parts.slice(0, parts.length - 1).join('.')
}

const joinPath = (path, name) => {
    return path ? `${path}.${name}` : name
}

function MediaCenter({ path, open, mediaType, onClose, onAdd }: MediaCenterProps) {
    const classes = useStyles()

    const { control, setError, setValue, getValues } = useProductFormContext()
    const mediaObjs = useWatch({ control, name: path, exact: true })

    const [selectedMediaObjs, setSelectedMediaObjs] = useState<Media[]>([])
    const getMediaObjs = useCallback(() => getValues(path) ?? [], [getValues, path])

    const mediaToRender = useMemo(() => {
        // Add upload button to the beginning of the grid
        return [
            {
                id: 0,
                file: '',
                name: 'upload',
                media_type: MediaType.UPLOAD,
            },
            ...(mediaObjs?.filter((media) => media.media_type === mediaType) || []),
        ]
    }, [mediaObjs, mediaType])

    const handleDelete = (removedMedia: Media) => {
        const parentPath = getParentPath(path)
        if (removedMedia.media_type === MediaType.IMAGE) {
            const childName = path.includes('channel_product')
                ? 'channel_stock_units'
                : 'stock_units'
            const childPath = joinPath(parentPath, childName)

            const children = getValues(childPath)
            children?.forEach((child, index) => {
                const originalImages = child?.images ?? []
                child.images =
                    child?.images?.filter((image) => removedMedia.id !== image.media_id) ?? []
                if (child.images.length !== originalImages.length) {
                    setValue(`${childPath}.${index}.images`, child.images)
                }
            })
            const imagesPath = joinPath(parentPath, 'images')
            const images =
                getValues(imagesPath)?.filter((image) => removedMedia.id !== image.media_id) ?? []
            setValue(imagesPath, images)
            setValue(path, getMediaObjs()?.filter((media) => media.id !== removedMedia.id) ?? [])
        } else if (removedMedia.media_type === MediaType.VIDEO) {
            const videosPath = joinPath(parentPath, 'videos')
            const videos =
                getValues(videosPath)?.filter((video) => removedMedia.id !== video.media_id) ?? []
            setValue(videosPath, videos)
            setValue(path, getMediaObjs()?.filter((media) => media.id !== removedMedia.id) ?? [])
        }
    }
    const store = getValues('store')
    const { mutate } = useUploadMediaMutation(store.id)

    const onUploadSuccess = (data: Map<string, { id: string; file: string }>) => {
        const updatedMediaObjs =
            getMediaObjs()?.map((media) => ({
                ...media,
                id: data.get(media.name ?? '')?.id ?? media.id,
                file: data.get(media.name ?? '')?.file ?? media.file,
                status: data.has(media.name ?? '') ? 'uploaded' : media.status,
            })) || []

        setValue(path, updatedMediaObjs)
    }

    const onUploadError = (error) => {
        if (error instanceof MediaUploadError) {
            const validMediaObjs =
                getMediaObjs()?.filter(
                    (media) => !error.mediaObjs.has(media.name ?? '') && !!media?.id
                ) || []

            setValue(path, validMediaObjs)
            setError(path, {
                type: 'manual',
                message: error.message,
            })
        }
    }
    const handleDrag = (newMediaObjs: File[]) => {
        const mediaObjs = getMediaObjs() || []
        const alreadyAddedMediaNames = new Set(mediaObjs?.map((i) => i.name) ?? [])

        const mediaObjsToUpload = newMediaObjs
            .filter((m) => !alreadyAddedMediaNames.has(m.name))
            .map((m, index) => ({
                name: m.name,
                preview: URL.createObjectURL(m),
                content_type: m.type,
                media_type: mediaType,
                status: MediaStatus.UPLOADING,
                file: m,
            }))

        setValue(path, [...mediaObjs, ...mediaObjsToUpload])

        mutate(
            {
                mediaObjs: mediaObjsToUpload.map(({ name, content_type, media_type, file }) => ({
                    name,
                    content_type,
                    media_type,
                    file,
                })),
            },
            {
                onSuccess: onUploadSuccess,
                onError: onUploadError,
            }
        )
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => {
                    onClose?.()
                }}
                maxWidth="md"
                fullWidth
                className={classes.paper}
            >
                <DialogTitle>{gettext('Media')}</DialogTitle>

                <DialogContent>
                    <VirtuosoGrid
                        overscan={8}
                        totalCount={mediaToRender.length}
                        components={{
                            List: ListContainer,
                        }}
                        data={mediaToRender}
                        style={{
                            height: MAX_VIRTUALIZED_HEIGHT,
                            gap: 15,
                        }}
                        itemContent={(index, media: Media) => (
                            <div key={media.name}>
                                {media.media_type === MediaType.UPLOAD && (
                                    <FileUploadButton
                                        onDrop={handleDrag}
                                        accept={
                                            mediaType === MediaType.IMAGE
                                                ? ACCEPT_IMAGE_TYPE
                                                : ACCEPT_VIDEO_TYPE
                                        }
                                        className={classNames(classes.mediaWrapper, classes.upload)}
                                        maxSize={
                                            mediaType === MediaType.IMAGE
                                                ? MAX_IMAGE_UPLOAD_SIZE
                                                : MAX_VIDEO_UPLOAD_SIZE
                                        }
                                    />
                                )}
                                {media.media_type !== MediaType.UPLOAD && (
                                    <MediaPreview
                                        media={media}
                                        onSelect={(selectedMedia: Media) => {
                                            setSelectedMediaObjs([
                                                ...selectedMediaObjs,
                                                selectedMedia,
                                            ])
                                        }}
                                        onUnSelect={(unSelectedMedia: Media) => {
                                            setSelectedMediaObjs(
                                                selectedMediaObjs.filter(
                                                    (selectedMedia) =>
                                                        unSelectedMedia.id !== selectedMedia.id ||
                                                        unSelectedMedia.name !== selectedMedia.name
                                                )
                                            )
                                        }}
                                        onDelete={handleDelete}
                                        isExpandable
                                    />
                                )}
                            </div>
                        )}
                        computeItemKey={computeItemKey}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            onAdd?.(selectedMediaObjs)
                            setSelectedMediaObjs([])
                            onClose?.()
                        }}
                    >
                        {gettext('Add')}
                    </Button>
                    <Button
                        color="secondary"
                        onClick={() => {
                            onClose?.()
                        }}
                    >
                        {gettext('Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MediaCenter
