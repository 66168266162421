import React, { useState, useRef, useEffect, useCallback } from 'react'
import ContentLoader from 'react-content-loader'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Checkbox from '@material-ui/core/Checkbox'
import grey from '@material-ui/core/colors/grey'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Paper from '@material-ui/core/Paper'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'
import AssessmentIcon from '@material-ui/icons/Assessment'

import { flatten as _flatten, uniqBy as _uniqBy } from 'lodash-es'
import moment from 'moment'
import qs from 'qs'
import {
    VictoryChart,
    VictoryAxis,
    VictoryLine,
    VictoryVoronoiContainer,
    VictoryTooltip,
    VictoryLabel,
    VictoryLegend,
} from 'victory'

import DateSelectButton from '../../oakra/components/date-select-button'
import {
    getTotalRevenue,
    getTotalNumSales,
    getOverallAOV,
    getChangePercent,
    getCurrentPeriodData,
    getPreviousPeriodData,
    abbreviateNumber,
    getDataWithAOV,
    getTickValues,
    numberWithCommas,
} from '../../tools/analytics'
import { copyObject, getTranslation, debounce, range } from '../../tools/utils'

import { ReportSelector, ReportSelectorAsync } from './dashboard-components'
import ChartTheme from './large-chart-theme'

const CHART_HEIGHT = 400 - 50
const PAPER_PADDING = 20
const DESIRED_NUMBER_OF_TICKS = 5

const PLACEHOLDER_SPEED = 0.5
const PLACEHOLDER_INTERVAL = 0.5

const TABLE_HEADER_PLACEHOLDER_HEIGHT = 38
const TABLE_ROW_PLACEHOLDER_HEIGHT = 27
const TABLE_ROW_BOTTOM_MARGIN = 7
const TABLE_ROW_SIDE_MARGIN = 7

const Tooltip = ({ x, y }) => {
    const theme = useTheme()

    return (
        <g>
            <circle
                cx={x}
                cy={y}
                r="4"
                stroke={theme.palette.secondary.main}
                strokeWidth={1.5}
                fill="white"
            />
        </g>
    )
}

const entityOptions = [
    { label: gettext('Account'), value: 'account' },
    { label: gettext('Store'), value: 'store' },
    { label: gettext('Shop'), value: 'shop' },
    { label: gettext('Product'), value: 'product' },
    { label: gettext('Stock Unit'), value: 'stock_unit' },
]

const periodSizeOptions = [
    { label: gettext('Day'), value: 'day' },
    { label: gettext('Week'), value: 'week' },
    { label: gettext('Month'), value: 'month' },
]

const groupByOptionsByEntity = {
    account: ['shop', 'channel', 'store', 'product'],
    store: ['shop', 'channel', 'product'],
    shop: ['product'],
    product: ['shop', 'channel'],
    stock_unit: ['shop', 'channel'],
}

const allGroupByOptions = [
    { label: gettext('Channel'), value: 'channel' },
    { label: gettext('Shop'), value: 'shop' },
    { label: gettext('Store'), value: 'store' },
    { label: gettext('Product'), value: 'product' },
]

const getGroupByOption = (value) => {
    return copyObject(allGroupByOptions.find((o) => o.value === value))
}

const dataTypes = [
    { label: gettext('Revenue'), value: 'revenue' },
    { label: gettext('Sales'), value: 'num_sales' },
    { label: gettext('AOV'), value: 'aov' },
]

const pageSizesOptions = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
]

const getChartData = (data, selectedDataType) => {
    return data.map((d) => ({
        x: moment(d.date).toDate(),
        y: d[selectedDataType],
        label: abbreviateNumber(parseFloat(d[selectedDataType]), 1),
    }))
}

const getChartLabel = (entity, optionForEntity) => {
    if (entity.value === 'account') {
        return entity.label
    } else {
        return `${entity.label} - ${optionForEntity.label}`
    }
}

const useDataTypeSummaryStyles = makeStyles({
    container: {
        border: `1px solid ${grey[400]}`,
        padding: 15,
        flex: '1 0 auto',
        cursor: 'pointer',
        borderRadius: 5,
        marginRight: 15,
    },
    labels: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    label: {
        color: grey[500],
    },
    figures: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    value: {
        fontSize: '1.3rem',
        fontWeight: 400,
    },
    change: {
        color: grey[500],
    },
})

const DataTypeSummary = ({ label, value, change, handleClick, selected, loading }) => {
    const classes = useDataTypeSummaryStyles()
    const theme = useTheme()

    let styles = null

    if (loading) {
        styles = {
            color: grey[400],
            borderColor: grey[400],
        }
    } else {
        if (selected) {
            styles = {
                color: theme.palette.secondary.main,
                backgroundColor: '#f2f7ff',
                borderColor: theme.palette.secondary.main,
            }
        }
    }

    return (
        <div className={classes.container} onClick={handleClick} style={styles}>
            <div className={classes.labels}>
                <Typography className={classes.label} style={styles}>
                    {label}
                </Typography>
            </div>
            <div className={classes.figures}>
                <Typography className={classes.value} style={styles}>
                    {loading ? '-' : value}
                </Typography>
                <Typography className={classes.change} style={styles}>
                    {change}
                </Typography>
            </div>
        </div>
    )
}

const useChartStyles = makeStyles({
    chartHeader: {
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
    },
    summaries: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
    },
    summariesSpacer: {
        flex: '2 0 auto',
    },
    chartLabel: {
        fontSize: '1.1rem',
        fontWeight: 500,
    },
    progressContainer: {},
})

const ReportChart = ({
    startDate,
    endDate,
    width,
    entity,
    periodSize,
    initialSelectedDataTypeValue,
    optionForEntity,
}) => {
    const analyticsTimeSeriesChartAPIURL = useSelector(
        (state) => state.initial.endpoints.analyticsTimeSeriesChartAPIURL
    )
    const classes = useChartStyles()
    const theme = useTheme()

    const [chartStartDate, setChartStartDate] = useState(
        moment().startOf('day').subtract(1, 'week')
    )
    const [chartEndDate, setChartEndDate] = useState(moment().startOf('day'))

    const [data, setData] = useState([])
    const [previousPeriodData, setPreviousPeriodData] = useState([])

    const [loading, setLoading] = useState(true)

    const [legendData, setLegendData] = useState([
        {
            name: dataTypes[0].label,
            symbol: { fill: theme.palette.secondary.main, type: 'minus' },
        },
    ])

    const [chartLabel, setChartLabel] = useState(() =>
        getChartLabel({ label: gettext('Account'), value: 'account' }, null, null)
    )

    const [selectedDataType, setSelectedDataType] = useState(() => {
        let initialSelectedDataType
        if (
            initialSelectedDataTypeValue &&
            ['revenue', 'num_sales', 'aov'].includes(initialSelectedDataTypeValue)
        ) {
            initialSelectedDataType = dataTypes.find(
                (dataType) => dataType.value === initialSelectedDataTypeValue
            )
        } else {
            initialSelectedDataType = dataTypes[0]
        }

        return initialSelectedDataType
    })

    const fetchChartData = useCallback(async () => {
        const endDateString = endDate.format('YYYY-MM-DD')
        const startDateString = startDate.format('YYYY-MM-DD')
        const queryString = `start_date=${startDateString}&end_date=${endDateString}&entity_id=${optionForEntity.value}&entity=${entity.value}`
        const url = `${analyticsTimeSeriesChartAPIURL}?${queryString}`

        const response = await fetch(url)
        const timeseriesData = await response.json()
        const fetchedData = getDataWithAOV(timeseriesData)
        const newData = getCurrentPeriodData(startDate, endDate, fetchedData)
        const newPreviousPeriodData = getPreviousPeriodData(startDate, endDate, fetchedData)

        setData(newData)
        setPreviousPeriodData(newPreviousPeriodData)
        setLoading(false)
        setLegendData([
            {
                name: selectedDataType.label,
                symbol: { fill: theme.palette.secondary.main, type: 'minus' },
            },
        ])
    }, [
        analyticsTimeSeriesChartAPIURL,
        endDate,
        entity.value,
        optionForEntity,
        selectedDataType.label,
        startDate,
        theme.palette.secondary.main,
    ])

    const handleChangeDataType = (dataType) => (e) => {
        setSelectedDataType(dataType)
        setLegendData([
            {
                name: dataType.label,
                symbol: { fill: theme.palette.secondary.main, type: 'minus' },
            },
        ])
    }

    useEffect(() => {
        if (entity.value !== 'account' && !optionForEntity) {
            return
        }

        setChartLabel(getChartLabel(entity, optionForEntity))
        setChartStartDate(startDate)
        setChartEndDate(endDate)
        setLoading(true)
        fetchChartData()
    }, [entity, periodSize, optionForEntity, startDate, endDate, fetchChartData])

    let rangeMax
    if (data.length > 0) {
        const YValues = data.map((d) => d[selectedDataType.value])
        const maxYValue = Math.max(...YValues)
        rangeMax = maxYValue === 0 ? 100 : maxYValue * 1.1
    } else {
        rangeMax = 100
    }

    const domainMin = chartStartDate.toDate()
    const domainMax = chartEndDate.toDate()

    const chartData = getChartData(data, selectedDataType.value)
    const tickValues = getTickValues(
        chartData.map((d) => d.x),
        DESIRED_NUMBER_OF_TICKS
    )

    return (
        <>
            <div className={classes.chartHeader}>
                <Typography className={classes.chartLabel}>{chartLabel}</Typography>
            </div>
            <div className={classes.summaries}>
                <DataTypeSummary
                    label={gettext('Total revenue')}
                    value={numberWithCommas(Math.round(getTotalRevenue(data)))}
                    change={getChangePercent(
                        getTotalRevenue(data),
                        getTotalRevenue(previousPeriodData)
                    )}
                    handleClick={handleChangeDataType(dataTypes[0])}
                    selected={selectedDataType.value === 'revenue'}
                    loading={data.length === 0}
                />
                <DataTypeSummary
                    label={gettext('Total Sales')}
                    value={numberWithCommas(Math.round(getTotalNumSales(data)))}
                    change={getChangePercent(
                        getTotalNumSales(data),
                        getTotalNumSales(previousPeriodData)
                    )}
                    handleClick={handleChangeDataType(dataTypes[1])}
                    selected={selectedDataType.value === 'num_sales'}
                    loading={data.length === 0}
                />
                <DataTypeSummary
                    label={gettext('Overall AOV')}
                    value={numberWithCommas(Math.round(getOverallAOV(data)))}
                    change={getChangePercent(
                        getOverallAOV(data),
                        getOverallAOV(previousPeriodData)
                    )}
                    handleClick={handleChangeDataType(dataTypes[2])}
                    selected={selectedDataType.value === 'aov'}
                    loading={data.length === 0}
                />
                <div className={classes.summariesSpacer}></div>
            </div>

            {loading && (
                <div className={classes.progressContainer}>
                    <ContentLoader
                        className={classes.placeholder}
                        width={width}
                        height={CHART_HEIGHT}
                        speed={PLACEHOLDER_SPEED}
                        interval={PLACEHOLDER_INTERVAL}
                    >
                        <rect x="0" y="0" rx="4" ry="4" width={width} height={CHART_HEIGHT} />
                    </ContentLoader>
                </div>
            )}
            {!loading && data.length > 0 && (
                <VictoryChart
                    height={CHART_HEIGHT}
                    width={width}
                    theme={ChartTheme}
                    containerComponent={<VictoryVoronoiContainer responsive={false} />}
                    domain={{ x: [domainMin, domainMax], y: [0, rangeMax] }}
                >
                    <VictoryLine
                        data={chartData}
                        interpolation="natural"
                        labelComponent={
                            <VictoryTooltip
                                pointerLength={0}
                                flyoutComponent={<Tooltip />}
                                labelComponent={
                                    <VictoryLabel
                                        dy={-10}
                                        backgroundStyle={{
                                            fill: 'white',
                                            stroke: theme.palette.secondary.main,
                                            rx: 2,
                                            ry: 2,
                                        }}
                                        backgroundPadding={6}
                                    />
                                }
                                text={(d) => {
                                    return `${moment(d.datum.x).format(
                                        'D MMM'
                                    )} - ${abbreviateNumber(d.datum.y, 1)}`
                                }}
                            />
                        }
                    />

                    <VictoryAxis
                        tickValues={tickValues}
                        tickFormat={(tick) => moment(tick).format('D MMM')}
                        scale={{ x: 'time' }}
                        style={{ grid: { stroke: 'none' } }}
                    />
                    <VictoryAxis tickFormat={(t) => abbreviateNumber(t, 1)} dependentAxis={true} />
                    <VictoryLegend
                        x={40}
                        y={0}
                        orientation="horizontal"
                        gutter={20}
                        data={legendData}
                    />
                </VictoryChart>
            )}
        </>
    )
}

const getColumns = (entity, summarizeByDate, groupBy) => {
    if (summarizeByDate) {
        if (entity.value === 'account') {
            return ['date', groupBy.value, 'revenue', 'units_sold', 'num_sales', 'aov']
        } else {
            return [
                'date',
                entity.value,
                groupBy.value,
                'revenue',
                'units_sold',
                'num_sales',
                'aov',
            ]
        }
    } else {
        if (entity.value === 'account') {
            return [groupBy.value, 'revenue', 'units_sold', 'num_sales', 'aov']
        } else {
            return [entity.value, groupBy.value, 'revenue', 'units_sold', 'num_sales', 'aov']
        }
    }
}

const getHeaderInfo = (entity, summarizeByDate, groupBy) => {
    const headerInfo = {
        [groupBy.value]: { align: 'left', label: groupBy.label },
        revenue: { align: 'right', label: gettext('Revenue') },
        units_sold: { align: 'right', label: gettext('Units') },
        num_sales: { align: 'right', label: gettext('Orders') },
        aov: { align: 'right', label: 'AOV' },
    }

    if (summarizeByDate) {
        headerInfo['date'] = { align: 'left', label: gettext('Date') }
    }
    if (entity.value !== 'account') {
        headerInfo[entity.value] = { align: 'left', label: entity.label }
    }

    return headerInfo
}

const useTableStyles = makeStyles({
    fieldLabel: {
        fontSize: '0.8rem',
        fontWeight: 500,
        marginLeft: 8,
        color: grey[600],
    },
    tableContainer: {
        marginTop: 0,
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${grey[300]}`,
        paddingBottom: 20,
    },
    leftSelectors: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    pageSizeContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    pageSizeLabel: {
        fontSize: '0.8rem',
        fontWeight: 500,
        marginRight: 8,
        color: grey[600],
    },
    tablePlaceholder: {
        marginTop: 20,
    },
    tableDate: {
        fontSize: '0.9rem',
    },
    checkboxLabel: {
        fontSize: '0.8rem',
        fontWeight: 500,
        color: grey[600],
    },
    noTableData: {
        marginTop: 10,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 300,
    },
    noTableDataMessageContainer: {
        display: 'flex',
    },
    noTableDataIcon: {
        color: grey[500],
    },
    noTableDataMessage: {
        color: grey[500],
        marginLeft: 10,
    },
})

const ReportTable = ({
    startDate,
    endDate,
    entity,
    groupBy,
    optionForEntity,
    width,
    handleGroupByChange,
}) => {
    const analyticsSummaryAPIURL = useSelector(
        (state) => state.initial.endpoints.analyticsSummaryAPIURL
    )
    const analyticsTimeSeriesTableAPIURL = useSelector(
        (state) => state.initial.endpoints.analyticsTimeSeriesTableAPIURL
    )
    const classes = useTableStyles()

    const [data, setData] = useState([])
    const [nextPageURL, setNextPageURL] = useState(null)
    const [dataCount, setDataCount] = useState(0)

    const [summarizeByDate, setSummarizeByDate] = useState(false)

    const [maxFetchedPage, setMaxFetchedPage] = useState(0)

    const [orderTableBy, setOrderTableBy] = React.useState('revenue')
    const [tableSortDirection, setTableSortDirection] = React.useState('desc')

    const [pageSize, setPageSize] = useState(pageSizesOptions[0])
    const [page, setPage] = React.useState(0)

    const [loading, setLoading] = useState(true)

    const columns = getColumns(entity, summarizeByDate, groupBy)
    const headerInfo = getHeaderInfo(entity, summarizeByDate, groupBy)

    const handleRequestSort = (property) => {
        const isCurrentlyDescending = orderTableBy === property && tableSortDirection === 'desc'
        setTableSortDirection(isCurrentlyDescending ? 'asc' : 'desc')
        setOrderTableBy(property)
    }

    const handlePageChange = (e, newPage) => {
        if (newPage <= maxFetchedPage) {
            setPage(newPage)
            return
        }

        setLoading(true)
        fetchTableDataNextPage()
        setMaxFetchedPage(newPage)
        setPage(newPage)
    }

    const fetchTableData = useCallback(async () => {
        setData([])
        const endDateString = endDate.format('YYYY-MM-DD')
        const startDateString = startDate.format('YYYY-MM-DD')

        let queryString
        if (groupBy) {
            queryString = `start_date=${startDateString}&end_date=${endDateString}&entity_id=${optionForEntity.value}&entity=${entity.value}&group_by=${groupBy.value}&page_size=${pageSize.value}&order_by=${orderTableBy}&order_direction=${tableSortDirection}`
        } else {
            queryString = `start_date=${startDateString}&end_date=${endDateString}&entity_id=${optionForEntity.value}&entity=${entity.value}&page_size=${pageSize.value}&order_by=${orderTableBy}&order_direction=${tableSortDirection}`
        }

        let url
        if (summarizeByDate) {
            url = `${analyticsTimeSeriesTableAPIURL}?${queryString}`
        } else {
            url = `${analyticsSummaryAPIURL}?${queryString}`
        }

        const response = await fetch(url)
        const paginatedData = await response.json()

        if (summarizeByDate) {
            setData(getDataWithAOV(paginatedData.results))
        } else {
            setData(paginatedData.results)
        }

        setDataCount(paginatedData.count)
        setNextPageURL(paginatedData.next)
        setMaxFetchedPage(0)
        setLoading(false)
    }, [
        analyticsSummaryAPIURL,
        analyticsTimeSeriesTableAPIURL,
        endDate,
        entity.value,
        groupBy,
        optionForEntity,
        orderTableBy,
        pageSize.value,
        startDate,
        summarizeByDate,
        tableSortDirection,
    ])

    const fetchTableDataNextPage = () => {
        fetch(nextPageURL)
            .then((response) => response.json())
            .then((paginatedData) => {
                let newData
                if (summarizeByDate) {
                    const dataWithAOV = getDataWithAOV(paginatedData.results)
                    newData = copyObject(data).concat(dataWithAOV)
                } else {
                    newData = copyObject(data).concat(paginatedData.results)
                }

                setData(newData)
                setNextPageURL(paginatedData.next)
                setLoading(false)
            })
    }

    useEffect(() => {
        if (optionForEntity) {
            setPage(0)
            setLoading(true)
            fetchTableData(true)
        }
    }, [
        groupBy,
        pageSize,
        summarizeByDate,
        optionForEntity,
        startDate,
        endDate,
        orderTableBy,
        tableSortDirection,
        fetchTableData,
    ])

    const emptyRows = pageSize.value - Math.min(pageSize.value, dataCount - page * pageSize.value)

    const startIndex = page * pageSize.value
    const endIndex = page * pageSize.value + pageSize.value
    const tableDataForPage = data.slice(startIndex, endIndex)

    const groupByOptionValues = groupByOptionsByEntity[entity.value]
    const groupByOptions = allGroupByOptions.filter((o) => groupByOptionValues.includes(o.value))

    return (
        <>
            <div className={classes.tableHeader}>
                <div className={classes.leftSelectors}>
                    <div className={classes.reportSelectorContainer}>
                        <Typography className={classes.fieldLabel}>
                            {gettext('Group by')}
                        </Typography>
                        <ReportSelector
                            options={groupByOptions}
                            handleChange={(groupBy) => {
                                handleGroupByChange(groupBy)
                                setSummarizeByDate(false)
                            }}
                            value={groupBy}
                            isSearchable={false}
                            isClearable={false}
                            isDisabled={groupByOptionValues.length === 0 || !optionForEntity}
                        />
                    </div>
                    {groupBy && (
                        <div className={classes.reportSelectorContainer}>
                            <FormControlLabel
                                classes={{ label: classes.checkboxLabel }}
                                control={
                                    <Checkbox
                                        color="secondary"
                                        checked={summarizeByDate}
                                        onChange={(e) => {
                                            setSummarizeByDate(e.target.checked)
                                        }}
                                        disabled={
                                            groupByOptionValues.length === 0 || !optionForEntity
                                        }
                                    />
                                }
                                labelPlacement="top"
                                label={gettext('By date')}
                            />
                        </div>
                    )}
                </div>
                {dataCount && (
                    <div className={classes.pageSizeContainer}>
                        <Typography className={classes.pageSizeLabel}>
                            {gettext('Page size')}
                        </Typography>
                        <ReportSelector
                            options={pageSizesOptions}
                            handleChange={(value) => {
                                setPageSize(value)
                            }}
                            value={pageSize.value}
                            isSearchable={false}
                            isClearable={false}
                        />
                        <TablePagination
                            component="div"
                            count={dataCount}
                            page={page}
                            rowsPerPage={pageSize.value}
                            rowsPerPageOptions={[]}
                            onPageChange={handlePageChange}
                            nextIconButtonProps={{
                                disabled:
                                    tableDataForPage.length === 0 ||
                                    (page + 1) * pageSize.value > dataCount,
                            }}
                            backIconButtonProps={{
                                disabled: tableDataForPage.length === 0 || page === 0,
                            }}
                        />
                    </div>
                )}
            </div>
            {loading && (
                <div className={classes.tablePlaceholder}>
                    <ContentLoader
                        className={classes.placeholder}
                        width={width}
                        height={
                            TABLE_HEADER_PLACEHOLDER_HEIGHT +
                            TABLE_ROW_BOTTOM_MARGIN +
                            pageSize.value *
                                (TABLE_ROW_PLACEHOLDER_HEIGHT + TABLE_ROW_BOTTOM_MARGIN)
                        }
                        speed={PLACEHOLDER_SPEED}
                        interval={PLACEHOLDER_INTERVAL}
                    >
                        <rect
                            x={TABLE_ROW_SIDE_MARGIN}
                            y="0"
                            rx="4"
                            ry="4"
                            width={
                                width > TABLE_ROW_SIDE_MARGIN
                                    ? width - TABLE_ROW_SIDE_MARGIN
                                    : width
                            }
                            height={TABLE_HEADER_PLACEHOLDER_HEIGHT}
                        />
                        {range(0, pageSize.value).map((i) => (
                            <rect
                                key={i}
                                x={TABLE_ROW_SIDE_MARGIN}
                                y={
                                    TABLE_HEADER_PLACEHOLDER_HEIGHT +
                                    TABLE_ROW_BOTTOM_MARGIN +
                                    i * (TABLE_ROW_PLACEHOLDER_HEIGHT + TABLE_ROW_BOTTOM_MARGIN)
                                }
                                rx="4"
                                ry="4"
                                width={
                                    width > TABLE_ROW_SIDE_MARGIN
                                        ? width - TABLE_ROW_SIDE_MARGIN
                                        : width
                                }
                                height={TABLE_ROW_PLACEHOLDER_HEIGHT}
                            />
                        ))}
                    </ContentLoader>
                </div>
            )}
            {!loading && tableDataForPage.length > 0 && (
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => {
                                    const columnInfo = headerInfo[column]

                                    return (
                                        <TableCell
                                            key={column}
                                            align={columnInfo.align}
                                            sortDirection={
                                                orderTableBy === column ? tableSortDirection : false
                                            }
                                        >
                                            <TableSortLabel
                                                active={!summarizeByDate && orderTableBy === column}
                                                direction={
                                                    orderTableBy === column
                                                        ? tableSortDirection
                                                        : 'asc'
                                                }
                                                onClick={(e) => {
                                                    handleRequestSort(column)
                                                }}
                                                hideSortIcon={summarizeByDate}
                                            >
                                                {columnInfo.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableDataForPage.map((row, index) => {
                                return (
                                    <TableRow hover key={index}>
                                        {summarizeByDate && (
                                            <TableCell align="left">
                                                <Typography className={classes.tableDate} noWrap>
                                                    {moment(row.date).format('YYYY-MM-DD')}
                                                </Typography>
                                            </TableCell>
                                        )}
                                        {entity.value !== 'account' && (
                                            <TableCell align="left">{row[entity.value]}</TableCell>
                                        )}
                                        <TableCell align="left">{row[groupBy.value]}</TableCell>
                                        <TableCell align="right">
                                            {numberWithCommas(Math.round(row['revenue']))}
                                        </TableCell>
                                        <TableCell align="right">
                                            {numberWithCommas(Math.round(row['units_sold']))}
                                        </TableCell>
                                        <TableCell align="right">
                                            {numberWithCommas(Math.round(row['num_sales']))}
                                        </TableCell>
                                        <TableCell align="right">
                                            {numberWithCommas(Math.round(row['aov']))}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            {emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={5} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {dataCount && (
                        <TablePagination
                            component="div"
                            count={dataCount}
                            page={page}
                            rowsPerPage={pageSize.value}
                            rowsPerPageOptions={[]}
                            onPageChange={handlePageChange}
                            nextIconButtonProps={{
                                disabled:
                                    tableDataForPage.length === 0 ||
                                    (page + 1) * pageSize.value > dataCount,
                            }}
                            backIconButtonProps={{
                                disabled: tableDataForPage.length === 0 || page === 0,
                            }}
                        />
                    )}
                </TableContainer>
            )}
            {data.length === 0 && !loading && (
                <div className={classes.noTableData}>
                    <div className={classes.noTableDataMessageContainer}>
                        <AssessmentIcon className={classes.noTableDataIcon} />
                        <Typography className={classes.noTableDataMessage}>
                            {gettext('No data found')}
                        </Typography>
                    </div>
                </div>
            )}
        </>
    )
}

const useStyles = makeStyles({
    container: {
        padding: 20,
    },
    controls: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    fieldLabel: {
        fontSize: '0.8rem',
        fontWeight: 500,
        marginLeft: 8,
        color: grey[600],
    },
    reportSelectorContainer: {
        marginLeft: 15,
    },
    chartPaper: {
        marginTop: 20,
        padding: 20,
        width: '100%',
        minHeight: CHART_HEIGHT,
    },
    tablePaper: {
        marginTop: 20,
        padding: 20,
    },
})

const Reports = () => {
    const stores = useSelector((state) => state.stores)
    const accountOwnerID = useSelector((state) => state.initial.accountOwner.id)
    const language = useSelector((state) => state.initial.language)
    const productsForUserAPIURL = useSelector(
        (state) => state.initial.endpoints.productsForUserAPIURL
    )
    const stockUnitsForUserAPIURL = useSelector(
        (state) => state.initial.endpoints.stockUnitsForUserAPIURL
    )
    const location = useLocation()
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

    const classes = useStyles()

    moment.locale(language)

    const [startDate, setStartDate] = useState(moment().startOf('day').subtract(1, 'week'))
    const [endDate, setEndDate] = useState(moment().startOf('day').subtract('1', 'day'))

    const [tempPeriodSize, setTempPeriodSize] = useState(periodSizeOptions[0])

    const initialEntity = { label: gettext('Account'), value: 'account' }
    const [entity, setEntity] = useState(initialEntity)
    const [tempEntity, setTempEntity] = useState(initialEntity)

    let initialGroupBy
    if (queryParams.group_by && ['shop', 'channel', 'product'].includes(queryParams.group_by)) {
        initialGroupBy = getGroupByOption(queryParams.group_by)
    } else {
        initialGroupBy = getGroupByOption(groupByOptionsByEntity[initialEntity.value][0])
    }

    const [groupBy, setGroupBy] = useState(initialGroupBy)
    const [tempGroupBy, setTempGroupBy] = useState(initialGroupBy)

    const [optionForEntity, setOptionForEntity] = useState({ value: accountOwnerID })

    const [paperWidth, setPaperWidth] = useState(null)

    const paperRef = useRef()

    useEffect(() => {
        setPaperWidth(paperRef.current.offsetWidth - PAPER_PADDING * 2)
    }, [])

    const handleDateChange = (newStartDate, newEndDate) => {
        setStartDate(newStartDate)
        setEndDate(newEndDate)
    }

    useEffect(() => {
        if (tempEntity.value !== 'account' && !optionForEntity) {
            return
        }

        setEntity(tempEntity)
        setGroupBy(tempGroupBy)
    }, [tempEntity, optionForEntity, startDate, tempGroupBy])

    const getOptionsForEntity = () => {
        let optionsForEntity

        if (tempEntity.value === 'channel') {
            const shops = _flatten(stores.map((store) => store.shops))
            const channels = shops.map((shop) => shop.channel)
            const uniqueChannels = _uniqBy(channels, 'name')
            const sortedUniqueChannels = uniqueChannels.sort((a, b) => a.name > b.name)
            optionsForEntity = sortedUniqueChannels.map((channel) => ({
                label: channel.name,
                value: channel.id,
            }))
        } else if (tempEntity.value === 'store') {
            const sortedStores = stores.sort((a, b) => a.name > b.name)
            optionsForEntity = sortedStores.map((store) => ({ label: store.name, value: store.id }))
        } else if (tempEntity.value === 'shop') {
            const shops = _flatten(stores.map((store) => store.shops))
            const sortedShops = shops.sort((a, b) => a.shop_name > b.shop_name)
            optionsForEntity = sortedShops.map((shop) => ({
                label: `${shop.shop_name} - ${shop.channel.name}`,
                value: shop.id,
            }))
        }

        return optionsForEntity
    }

    const loadOptions = (inputValue, callback) => {
        let url
        if (tempEntity.value === 'product') {
            url = productsForUserAPIURL
        } else {
            url = stockUnitsForUserAPIURL
        }
        url = url.replace('user_pk', accountOwnerID)
        const urlWithParam = `${url}?search=${inputValue}`

        return fetch(urlWithParam)
            .then((response) => response.json())
            .then((results) => {
                let options

                if (tempEntity.value === 'product') {
                    options = results.results.map((product) => ({
                        label: getTranslation(product, language, 'name'),
                        value: product.id,
                    }))
                } else {
                    options = results.results.map((stockUnit) => ({
                        label: stockUnit.sku,
                        value: stockUnit.id,
                    }))
                }
                callback(options.slice(0, 5))
            })
    }

    return (
        <div className={classes.container}>
            <div className={classes.controls}>
                <DateSelectButton
                    startDate={startDate}
                    endDate={endDate}
                    handleDateChange={handleDateChange}
                />
                <div className={classes.reportSelectorContainer}>
                    <Typography className={classes.fieldLabel}>{gettext('Entity')}</Typography>
                    <ReportSelector
                        options={entityOptions}
                        handleChange={(newEntity) => {
                            setTempEntity(newEntity)
                            setOptionForEntity(
                                newEntity.value === 'account' ? { value: accountOwnerID } : null
                            )
                            const newGroupByOptionValues = groupByOptionsByEntity[newEntity.value]

                            let newGroupBy
                            if (newGroupByOptionValues.includes(groupBy.value)) {
                                newGroupBy = copyObject(groupBy)
                            } else {
                                newGroupBy = getGroupByOption(newGroupByOptionValues[0])
                            }
                            setTempGroupBy(newGroupBy)
                        }}
                        value={tempEntity}
                        isSearchable={false}
                        clearValue={{ value: accountOwnerID }}
                    />
                </div>
                {['channel', 'store', 'shop'].includes(tempEntity.value) && (
                    <div className={classes.reportSelectorContainer}>
                        <Typography className={classes.fieldLabel}>{tempEntity.label}</Typography>
                        <ReportSelector
                            options={getOptionsForEntity(tempEntity)}
                            handleChange={(value) => setOptionForEntity(value)}
                            value={optionForEntity}
                            width={300}
                        />
                    </div>
                )}
                {['product', 'stock_unit'].includes(tempEntity.value) && (
                    <div className={classes.reportSelectorContainer}>
                        <Typography className={classes.fieldLabel}>{tempEntity.label}</Typography>
                        <ReportSelectorAsync
                            options={getOptionsForEntity(tempEntity)}
                            loadOptions={debounce(loadOptions, 500)}
                            handleChange={(value) => setOptionForEntity(value)}
                            value={optionForEntity}
                            width={300}
                        />
                    </div>
                )}
                {/* <div className={classes.reportSelectorContainer}>
                    <Typography className={classes.fieldLabel}>{gettext("Period size")}</Typography>
                    <ReportSelector
                        options={periodSizeOptions}
                        handleChange={newPeriodSize => {
                            setTempPeriodSize(newPeriodSize);
                        }}
                        value={tempPeriodSize}
                    />
                </div> */}
            </div>

            <Paper className={classes.chartPaper} ref={paperRef}>
                <ReportChart
                    startDate={startDate}
                    endDate={endDate}
                    width={paperWidth}
                    entity={tempEntity}
                    periodSize={tempPeriodSize}
                    optionForEntity={optionForEntity}
                    initialSelectedDataTypeValue={queryParams.data_type}
                />
            </Paper>

            <Paper className={classes.tablePaper}>
                <ReportTable
                    startDate={startDate}
                    endDate={endDate}
                    groupBy={groupBy}
                    entity={entity}
                    optionForEntity={optionForEntity}
                    width={paperWidth}
                    handleGroupByChange={(newGroupBy) => {
                        setGroupBy(newGroupBy)
                    }}
                />
            </Paper>
        </div>
    )
}

export default Reports
