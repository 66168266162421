import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'

import { api, queryKeys } from '~/api'
import { useAppSelector } from '~/store'

type Props = {
    open: boolean
    user: {
        id: number
        first_name: string
        last_name: string
        username: string
        email: string
    }
    onClose: () => void
    onExited: () => void
}

export default function UserDeleteConfirm({ open, user, onClose, onExited }: Props) {
    const accountOwnerId = useAppSelector((state) => state.initial.accountOwner.id)
    const { enqueueSnackbar } = useSnackbar()
    const queryClient = useQueryClient()
    const { mutate: deleteUser, isLoading: isDeleting } = api.users.delete.useMutation()

    function handleDeleteUser() {
        deleteUser(
            { params: { id: user.id } },
            {
                onSuccess: () => {
                    enqueueSnackbar(gettext('User deleted'), { variant: 'success' })
                    void queryClient.invalidateQueries({
                        queryKey: queryKeys.users.accountUserListing(accountOwnerId).queryKey,
                    })
                    onClose()
                },
                onError: (err) => {
                    console.error(err)
                    enqueueSnackbar(gettext('Something went wrong'), { variant: 'error' })
                },
            }
        )
    }

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth="sm"
            TransitionProps={{ onExited }}
            onClose={() => {
                if (!isDeleting) {
                    onClose()
                }
            }}
        >
            <DialogTitle>{gettext('Delete user')}</DialogTitle>
            <DialogContent>
                <Typography>{gettext('Are you sure you want to delete this user?')}</Typography>
                <Box clone sx={{ mt: 2, p: 2 }} color="grey.700">
                    <Paper elevation={0} variant="outlined">
                        <Typography>
                            {user.first_name} {user.last_name}
                        </Typography>
                        <Typography>{user.username}</Typography>
                        <Typography>{user.email}</Typography>
                    </Paper>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={isDeleting} onClick={onClose}>
                    {gettext('Cancel')}
                </Button>
                <Box clone color="error.main">
                    <Button disabled={isDeleting} onClick={handleDeleteUser}>
                        {isDeleting ? <CircularProgress size={16} /> : gettext('Delete')}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
