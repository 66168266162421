import fastCartesian from 'fast-cartesian'

/**
 * Could be numbers if only 1 dimension, or tuples of N number of dimensions
 */
export const getAllPossibleDimensionsOptions = (
    dimensions: Array<{ options: Array<{ id: string | number }> }>
) => {
    if (!dimensions.length) {
        return []
    }

    const optionsArrays = dimensions.map(({ options }) => options.map((option) => option.id))
    return fastCartesian(optionsArrays)
}
