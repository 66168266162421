import { useCallback, useMemo, useState } from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import CategoryPicker from './category-picker'
import { ChannelAttributes } from './product-attributes'

import { useLanguage } from '~/common/hooks'
import { type ChannelCategory } from '~/common/schemas/product'
import ProductSection from '~/products/components/product-info/product-section'
import { useProductFormContext } from '~/products/hooks/useProductForm'
import { getTranslation } from '~/tools/utils'

type Props = {
    isNewProduct: boolean
    categoryPickerOpen: boolean
    onCategoryPickerClose: () => void
    onCategoryPickerOpen: () => void
}

export default function ProductChannels({
    isNewProduct,
    categoryPickerOpen,
    onCategoryPickerOpen,
    onCategoryPickerClose,
}: Props) {
    const { getValues, setValue, watch } = useProductFormContext()
    const [channels, store, channelProducts] = watch(['channels', 'store', 'channel_products'])

    const getChannelIndexFromId = useCallback(
        (channelId: number) => channels.findIndex(({ id }) => id === channelId),
        [channels]
    )

    const channelsToShow = useMemo(() => {
        return channels.filter((channel) => {
            return (
                channelProducts
                    .filter((cp) => cp.images && !(isNewProduct && !cp.shouldCreateChannelProduct))
                    .some((cp) => cp.shop.channel.id === channel.id) && channel.has_categories
            )
        })
    }, [channels, channelProducts, isNewProduct])

    const [selectedChannelId, setSelectedChannelId] = useState(-1)
    const selectedChannel = useMemo(
        () => channelsToShow.find((channel) => channel.id === selectedChannelId) ?? null,
        [channelsToShow, selectedChannelId]
    )

    const updateChannelProducts = useCallback(
        (channelId: number, category: ChannelCategory) => {
            getValues('channel_products').forEach((cp, index) => {
                if (cp.shop.channel.id === channelId && cp.shop.channel.has_categories) {
                    setValue(`channel_products.${index}.category`, category)
                }
            })
        },
        [getValues, setValue]
    )

    const updateConfiguredShop = useCallback(() => {
        const shopIndex = store.shops.findIndex((shop) => shop.channel.id === selectedChannelId)
        if (shopIndex === -1) {
            return
        }

        const shop = store.shops[shopIndex]
        const notConfiguredShopIndex = getValues('notConfiguredShops').findIndex(
            (s) => s.id === shop.id
        )
        if (notConfiguredShopIndex === -1) {
            return
        }

        if (!shop.channel.allow_duplicate_skus) {
            setValue(`notConfiguredShops.${notConfiguredShopIndex}.selected`, true)
        }
    }, [store.shops, getValues, selectedChannelId, setValue])

    const handleProductCategoryPick = useCallback(
        (category: ChannelCategory) => {
            const channelIndex = getChannelIndexFromId(selectedChannelId)
            if (channelIndex === -1) {
                return
            }

            updateConfiguredShop()
            setValue(`channels.${channelIndex}.category`, category, { shouldValidate: true })

            setValue(
                'stock_units_editing_is_enabled',
                category.supports_multiple_stock_units ?? true
            )
            updateChannelProducts(selectedChannelId, category)
            onCategoryPickerClose()
        },
        [
            selectedChannelId,
            setValue,
            getChannelIndexFromId,
            updateConfiguredShop,
            updateChannelProducts,
            onCategoryPickerClose,
        ]
    )

    return (
        <Grid container spacing={3}>
            {channelsToShow.map((channel) => {
                return (
                    <Grid item key={channel.id} xs={12}>
                        {channelProducts
                            .filter((cp) => cp.shop.channel.id === channel.id)
                            .slice(0, 1)
                            .map((cp) => (
                                <ChannelSection
                                    key={cp.id}
                                    index={getChannelIndexFromId(channel.id)}
                                    shopId={cp.shop.id}
                                    onSelectCategory={(channelId) => {
                                        setSelectedChannelId(channelId)
                                        onCategoryPickerOpen()
                                    }}
                                />
                            ))}
                    </Grid>
                )
            })}

            {selectedChannel && (
                <CategoryPicker
                    display={categoryPickerOpen}
                    initialCategory={selectedChannel.category}
                    channel={selectedChannel}
                    shopsForChannel={channelProducts
                        .filter((cp) => cp.shop.channel.id === selectedChannel.id)
                        .map((cp) => cp.shop)}
                    onClose={onCategoryPickerClose}
                    onAfterClose={() => setSelectedChannelId(-1)}
                    onCategoryPick={handleProductCategoryPick}
                />
            )}
        </Grid>
    )
}

function ChannelSection({
    index,
    shopId,
    onSelectCategory,
}: {
    index: number
    shopId: number
    onSelectCategory: (channelId: number) => void
}) {
    const language = useLanguage()
    const { watch, formState, register } = useProductFormContext()
    const { ref } = register(`channels.${index}.category`, {
        required: gettext('Category is required'),
    })
    const channel = watch(`channels.${index}`)
    const channelError = formState.errors.channels?.[index]?.category?.message

    return (
        <ProductSection>
            <Box
                ref={ref}
                sx={{
                    py: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gridGap: '0.5rem',
                }}
            >
                <Box
                    component="img"
                    sx={{ width: 24, height: 24 }}
                    alt={channel.name}
                    src={channel.channel_square_icon_url}
                />
                <Typography>{channel.name}</Typography>
                <Typography hidden={!channelError} color="error" variant="caption">
                    {channelError}
                </Typography>
            </Box>
            <Divider sx={{ mx: -2 }} />
            <Box
                sx={{
                    pt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gridGap: '0.5rem',
                }}
            >
                <TextField
                    size="small"
                    margin="dense"
                    label={gettext('Select a category')}
                    value={
                        channel.category ? getTranslation(channel?.category, language, 'name') : ''
                    }
                    inputProps={{ sx: { cursor: 'pointer' } }}
                    onClick={() => onSelectCategory(channel.id)}
                />
                {channel.category?.supports_multiple_stock_units === false && (
                    <Typography variant="body2" color="textSecondary">
                        {interpolate(
                            gettext(
                                'This %s category does not support multiple stock units. You are only allowed to use one stock unit.'
                            ),
                            [channel.name]
                        )}
                    </Typography>
                )}
            </Box>
            {channel.has_category_attributes && channel.category && (
                <ChannelAttributes
                    shopId={shopId}
                    channelId={channel.id}
                    categoryId={channel.category.category_id}
                />
            )}
        </ProductSection>
    )
}
