import { type SetStateAction, useEffect, useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'

import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'
import moment from 'moment'

import ActiveDiscountPricing from './active-discount-pricing'
import { usePricingStyles } from './pricing-table'

import { useProductFormContext } from '~/products/hooks/useProductForm'

const useStyles = makeStyles({
    discountsTable: {
        marginTop: 25,
        marginBottom: 25,
    },
    discountSelect: {
        width: 300,
        marginRight: 15,
        marginBottom: 20,
    },
    sectionTitle: {
        marginBottom: 20,
    },
})

export default function ProductDiscounts() {
    const pricingClasses = usePricingStyles({ fieldWidth: 125 })
    const classes = useStyles()
    const { control } = useProductFormContext()
    const discounts = useWatch({
        control,
        name: 'product_discounts',
        exact: true,
        defaultValue: [],
    })
    const channelProducts = useWatch({ control, name: 'channel_products', exact: true })
    const stockUnits = useWatch({ control, name: 'stock_units', exact: true })

    const [selectedDiscountId, setSelectedDiscountId] = useState(-1)
    const [selectedTab, setSelectedTab] = useState('active')

    const selectedDiscount = useMemo(
        () => discounts.find((pd) => pd.id === selectedDiscountId),
        [discounts, selectedDiscountId]
    )

    useEffect(() => {
        if (!discounts.length) {
            return
        }
        setSelectedDiscountId((value) => {
            // Should only set the default value if it's not set yet
            if (value === -1) {
                return discounts[0].id
            }

            return value
        })
    }, [discounts])

    const stockUnitsWithDiscount = useMemo(() => {
        return stockUnits.filter((su) => {
            return selectedDiscount?.stock_unit_discounts.some((sud) => sud.stock_unit.id === su.id)
        })
    }, [stockUnits, selectedDiscount?.stock_unit_discounts])

    const channelProductsWithDiscount = useMemo(() => {
        if (!selectedDiscount) {
            return []
        }
        const channelProductIdsWithDiscount = selectedDiscount?.stock_unit_discounts.reduce(
            (set, sud) => {
                sud?.channel_stock_unit_discounts.forEach((csud) => {
                    set.add(csud.channel_stock_unit.channel_product.id)
                })
                return set
            },
            new Set<number>()
        )
        const shopIds = new Set<number>()

        return channelProducts.filter((cp) => {
            if (shopIds.has(cp.shop.id) || !channelProductIdsWithDiscount.has(Number(cp.id))) {
                return false
            }
            shopIds.add(cp.shop.id)
            return true
        })
    }, [channelProducts, selectedDiscount])

    const csudLookup = useMemo(() => {
        return selectedDiscount?.stock_unit_discounts.reduce((map, sud) => {
            const sku = sud.stock_unit.sku

            sud?.channel_stock_unit_discounts.forEach((csud) => {
                const key = `${csud.channel_stock_unit.channel_product.id}-${sku}`
                map.set(key, csud)
            })
            return map
        }, new Map())
    }, [selectedDiscount])

    if (!discounts.length) {
        return null
    }

    function handleTabChange(event: any, value: SetStateAction<string>): void {
        setSelectedTab(value)
    }

    return (
        <>
            <Divider />
            <div className={classes.discountsTable}>
                <Typography className={classes.sectionTitle} variant="subtitle2">
                    {gettext('Discount prices')}
                </Typography>

                <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    // aria-label="disabled tabs example"
                >
                    <Tab label="Active Discount" value="active" />
                    <Tab label="All Discounts" value="all" />
                </Tabs>
                {selectedTab === 'active' && (
                    <div>
                        <ActiveDiscountPricing />
                    </div>
                )}
                {selectedTab === 'all' && (
                    <div style={{ paddingTop: 20 }}>
                        <FormControl variant="outlined" className={classes.discountSelect}>
                            <InputLabel id="discounts">
                                {pgettext('The name of the discount', 'Discount')}
                            </InputLabel>
                            <Select
                                labelId="discounts"
                                value={selectedDiscountId}
                                labelWidth={200}
                                margin="dense"
                                onChange={(e) =>
                                    setSelectedDiscountId(parseInt(e.target.value as string))
                                }
                            >
                                {discounts.map((pd) => {
                                    const discountDisplayName = pd.discount.start_date
                                        ? `${pd.discount.name} ${moment(
                                              pd.discount.start_date
                                          ).format('l')}`
                                        : pd.discount.name

                                    return (
                                        <MenuItem key={pd.id} value={pd.id}>
                                            {discountDisplayName}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <table className={pricingClasses.table}>
                            <tbody>
                                <tr>
                                    <th>&nbsp;</th>

                                    {channelProductsWithDiscount.map((cp, cpIndex) => (
                                        <th
                                            key={cp.id || cpIndex}
                                            className={pricingClasses.priceColumn}
                                        >
                                            <div className={pricingClasses.pricingColumnHeader}>
                                                <img
                                                    className={pricingClasses.pricingChannelIconURL}
                                                    src={cp.shop.channel.channel_square_icon_url}
                                                />
                                                <Typography
                                                    className={pricingClasses.pricingColumnShopName}
                                                >
                                                    {cp.shop.shop_name}
                                                </Typography>
                                            </div>
                                        </th>
                                    ))}
                                </tr>

                                {stockUnitsWithDiscount.map((su, index) => {
                                    return (
                                        <tr key={su.id} className={pricingClasses.priceRow}>
                                            <td className={pricingClasses.skuColumn}>
                                                <Typography
                                                    className={classNames(
                                                        pricingClasses.skuColumn,
                                                        !su.sku && pricingClasses.skuPlaceholder
                                                    )}
                                                >
                                                    {su.sku ||
                                                        `${gettext('Stock unit')} ${index + 1}`}
                                                </Typography>
                                            </td>

                                            {channelProductsWithDiscount.map((cp, cpIndex) => {
                                                const key = `${cp.id}-${su.sku}`
                                                const channelStockUnitDiscount =
                                                    csudLookup?.get(key)

                                                return (
                                                    <td
                                                        key={cp.id || cpIndex}
                                                        className={pricingClasses.priceColumn}
                                                    >
                                                        <TextField
                                                            disabled
                                                            variant="outlined"
                                                            margin="dense"
                                                            InputProps={{
                                                                className:
                                                                    pricingClasses.priceField,
                                                            }}
                                                            value={
                                                                channelStockUnitDiscount?.price ||
                                                                ''
                                                            }
                                                            placeholder={gettext('Price')}
                                                            type="number"
                                                        />
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </>
    )
}
