import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import FilterSelect from '../../../oakra/components/filter-select'
import { debounce } from '../../../tools/utils'

import { toolbarStyles } from './style'

const TableToolbar = (props) => {
    const {
        numSelected,
        stores,
        classes,
        filterOptions,
        filterValues,
        rightSection,
        leftSection,
        onFilterChange,
    } = props

    const options = [
        {
            field: 'Store',
            lookup: 'store',
            select_multiple: false,
            values: stores.map((o) => ({ value: o.id, label: o.name })),
        },
    ].concat(filterOptions || [])

    const handleFilterChange = (field) => (option) =>
        onFilterChange({ [field.lookup]: option && option.value })
    const handleSearchChange = debounce(onFilterChange, 500)

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {props.loading && <LinearProgress className={classes.progress} color="secondary" />}

            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {interpolate(pgettext('Number selected', '%s selected'), [numSelected])}
                    </Typography>
                ) : (
                    <div className={classes.toolbarItems}>
                        <TextField
                            id="search"
                            type="search"
                            className={classes.searchBar}
                            margin="normal"
                            onChange={(e) => handleSearchChange({ search: e.target.value })}
                            variant="outlined"
                            placeholder={gettext('Search products')}
                            InputProps={{ style: { height: 30, fontSize: 13 } }}
                            inputProps={{ style: { paddingLeft: 9 } }}
                            defaultValue={filterValues.search}
                        />

                        {options.map((field) => (
                            <FilterSelect
                                key={field.lookup}
                                field={field}
                                value={field.values.find(
                                    (o) => o.value == filterValues[field.lookup]
                                )}
                                handleFilterChange={handleFilterChange}
                            />
                        ))}

                        {leftSection}
                    </div>
                )}
            </div>

            <div className={classes.spacer} />

            <div className={classes.actions}>{rightSection}</div>
        </Toolbar>
    )
}

TableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
}

export default withStyles(toolbarStyles)(TableToolbar)
