/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect, useState, useMemo } from 'react'
import DataGrid from 'react-data-grid'
import { useLocation } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { grey, red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import HistoryIcon from '@material-ui/icons/History'
import ViewModuleIcon from '@material-ui/icons/ViewModule'

import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'

import { useUpdateStockItemsMutation } from '../../products/api/updateStockItems'
import { useUpdateStockRecordsMutation } from '../../products/api/updateStockRecords'

import BundleItemModal from './bundle-item'
import EventHistory from './stock-record-adjustment-history'
import TableSkeleton from './table-skeleton'

import { api, queryKeys } from '~/api'
import useUser from '~/common/hooks/useUser'
import { type UserLocation } from '~/common/schemas/location'
import { type StockItemWithStockRecords } from '~/common/schemas/stock-record'

const useStyles = makeStyles((theme) => ({
    grid: {
        width: '100%',
        height: 'calc(100vh - 310px)', // not sure why the offset is so much
        '& .rdg-header-row .rdg-cell': {
            outline: 'none',
        },
    },
    stockSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexGrow: 1,
    },
    dataGridContainer: {
        display: 'flex',
        blockSize: '100%',
    },
    topSection: {
        display: 'flex',
        padding: '15px 20px',
        borderBottom: '1px solid lightgray',
    },
    progressContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 75,
    },
    coverImage: {
        width: 100,
        height: 100,
        marginRight: 20,
    },
    editCellMessage: {
        fontSize: '0.9rem',
        color: theme.palette.grey[500],
        marginBottom: 15,
    },
    action: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    savedMessage: {
        color: theme.palette.grey[500],
        marginRight: 15,
    },
    saveProgress: {
        marginRight: 15,
    },
    editorInput: {
        padding: '0 0.5rem',
        appearance: 'none',
        border: 'none',
        outline: 'none',
        width: '100%',
        height: '100%',
    },
    icon: {
        float: 'right',
        color: grey[500],
        marginTop: 6,
        marginRight: 7,
        cursor: 'pointer',
        display: 'none',
    },
    valueChanged: {
        color: red[500],
    },
    skuColumn: {
        background: grey[100],
        outline: 'none!important',
    },
    row: {
        '&:hover .action-icon': {
            display: 'inline',
        },
    },
    changeIndicator: {
        width: 6,
        height: 6,
        background: '#77bbf1',
        position: 'absolute',
        top: 15,
        right: 7,
        borderRadius: 10,
    },
    loadingProgress: {
        margin: 0,
        padding: 0,
    },
    skeletonContainer: {
        paddingTop: 20,
    },
}))

const SkuCell = ({ row, column, onIconClick }) => {
    const classes = useStyles()
    return (
        <div>
            <span>{row.sku}</span>
            <span className={classNames('action-icon', classes.icon)}>
                <ViewModuleIcon onClick={() => onIconClick(row)} />
            </span>
        </div>
    )
}

const QuantityCell = ({ row, column, onIconClick }) => {
    const classes = useStyles()
    const value = row[`${column.key}_quantity`]
    const isChanged = row[`${column.key}_changed`]
    const stockRecordID = row[`${column.key}_stock_record_id`]

    if (row.is_bundle) return null

    return (
        <div>
            <span>{value}</span>
            <span className={classNames('action-icon', classes.icon)}>
                {stockRecordID && (
                    <HistoryIcon onClick={() => onIconClick({ id: stockRecordID })} />
                )}
            </span>
            {isChanged && <div className={classes.changeIndicator}></div>}
        </div>
    )
}

const StockItemNameCell = ({ row }) => {
    const classes = useStyles()
    const value = row.name
    const isChanged = row.name_changed
    return (
        <div>
            <span>{value}</span>
            {isChanged && <div className={classes.changeIndicator}></div>}
        </div>
    )
}

function isAtBottom({ currentTarget }: React.UIEvent<HTMLDivElement>): boolean {
    return currentTarget.scrollTop + 10 >= currentTarget.scrollHeight - currentTarget.clientHeight
}

export default function StockManagerTable() {
    const classes = useStyles()

    const [selectedStockRecord, setSelectedStockRecord] = useState(null)
    const openHistoryModal = (stockRecord) => {
        setSelectedStockRecord(stockRecord)
    }

    const closeHistoryModal = () => {
        setSelectedStockRecord(null)
    }

    const [selectedStockItem, setSelectedStockItem] = useState(null)
    const openBundleModal = (stockItem) => {
        setSelectedStockItem(stockItem)
    }

    const closeBundleModal = (updatedStockItem) => {
        setSelectedStockItem(null)
    }

    const location = useLocation()
    const filterValues = queryString.parse(location.search)

    const { data: userLocationsResult, isLoading: isLoadingUserLocations } = useLocationQuery()
    const {
        data: stockItemsResult,
        isFetching: isLoadingStockItems,
        fetchNextPage,
    } = useStockItemsInfiniteQuery(filterValues)

    const isLoading = isLoadingStockItems || isLoadingUserLocations
    const stockItemsData = useMemo(
        () => stockItemsResult?.pages.flatMap((page) => page.results),
        [stockItemsResult?.pages]
    )

    const [rows, setRows] = useState<Row[]>([])
    const resetRows = () => {
        rows.map((row) => {
            Object.entries(row).map(([key, value]) => {
                if (String(key).includes('_changed') && value) {
                    row[key] = false
                }
            })
        })
        setRows(rows)
    }

    useEffect(() => {
        if (!stockItemsData || !userLocationsResult) {
            return
        }

        setRows(updateInitialRows(stockItemsData, userLocationsResult.body))
    }, [stockItemsData, userLocationsResult])

    const { enqueueSnackbar } = useSnackbar()
    const { isLoading: isSavingStockRecords, mutateAsync: updateStockRecords } =
        useUpdateStockRecordsMutation()
    const { isLoading: isSavingStockItems, mutateAsync: updateStockItems } =
        useUpdateStockItemsMutation()
    const isSaving = isSavingStockRecords || isSavingStockItems

    const isDataLoaded = !!stockItemsResult && !!userLocationsResult

    function handleSave() {
        if (!stockItemsData) {
            return
        }

        const promises = []
        const stockRecordsToUpdate = []
        const stockItemsToUpdate = []
        rows.map((row) => {
            Object.entries(row).map(([key, value]) => {
                if (String(key).includes('_changed') && value) {
                    const s = key.split('_')[0]
                    if (s === 'name') {
                        stockItemsToUpdate.push({
                            id: row.id,
                            name: row.name,
                        })
                    } else {
                        stockRecordsToUpdate.push({
                            id: row[`${s}_stock_record_id`],
                            quantity: row[`${s}_quantity`],
                            stock_item: { id: row.id },
                            location: { id: Number(s) },
                        })
                    }
                }
            })
        })

        // update stock record quantity
        if (stockRecordsToUpdate.length > 0) {
            const p = updateStockRecords(stockRecordsToUpdate)
            promises.push(p)
        }
        // update stock item name
        if (stockItemsToUpdate.length > 0) {
            const p = updateStockItems(stockItemsToUpdate)
            promises.push(p)
        }

        if (promises.length > 0) {
            window.analytics.track('Save stock from table', {
                page: window.location.href,
                feature: 'stock',
                sub_feature: 'update',
                type: 'bulk update',
                trigger: 'button click',
                update_count: promises.length,
            })

            void Promise.all(promises).then((reponses) => {
                resetRows()
                enqueueSnackbar(gettext('Saved success'), { variant: 'success' })
            })
        }
    }

    const userLocations = userLocationsResult?.body ?? []

    function handleScroll(event) {
        if (isLoading || !isAtBottom(event)) return
        void fetchNextPage()
    }

    return (
        <>
            {isDataLoaded ? (
                <>
                    <div className={classes.stockSection}>
                        <div className={classes.dataGridContainer}>
                            <DataGrid
                                rowKeyGetter={(row: Row) => row.sku}
                                className={classNames(classes.grid, 'rdg-light')}
                                columns={[
                                    {
                                        key: 'sku',
                                        name: 'SKU',
                                        disabled: true,
                                        frozen: true,
                                        minWidth: 200,
                                        resizable: true,
                                        formatter: (props) => (
                                            <SkuCell {...props} onIconClick={openBundleModal} />
                                        ),
                                        cellClass: classes.skuColumn,
                                    },
                                    {
                                        key: 'name',
                                        name: gettext('Name'),
                                        minWidth: 200,
                                        resizable: true,
                                        formatter: StockItemNameCell,
                                        editor: ({ row, onRowChange, onClose }) => (
                                            <input
                                                ref={(input) => input?.focus()}
                                                value={row.name}
                                                required
                                                className={classes.editorInput}
                                                onChange={(event) => {
                                                    onRowChange({
                                                        ...row,
                                                        name: event.target.value,
                                                        name_changed: true,
                                                    })
                                                }}
                                                onBlur={() => onClose(true, false)}
                                            />
                                        ),
                                    },
                                    ...userLocations.map((location) => ({
                                        key: location.id,
                                        name: location.name,
                                        minWidth: 150,
                                        resizable: true,
                                        formatter: (props) => (
                                            <QuantityCell
                                                {...props}
                                                onIconClick={openHistoryModal}
                                            />
                                        ),
                                        editor: ({ row, column, onRowChange, onClose }) =>
                                            row.is_bundle ? null : (
                                                <input
                                                    ref={(input) => input?.focus()}
                                                    value={row[
                                                        `${column.key}_quantity`
                                                    ]?.toString()}
                                                    type="number"
                                                    min="0"
                                                    className={classes.editorInput}
                                                    onChange={(event) => {
                                                        onRowChange({
                                                            ...row,
                                                            [`${column.key}_quantity`]:
                                                                event.target.valueAsNumber || 0,
                                                            [`${column.key}_changed`]: true,
                                                        })
                                                    }}
                                                    onBlur={() => onClose(true, false)}
                                                />
                                            ),
                                    })),
                                ]}
                                rows={rows}
                                rowClass={() => classes.row}
                                onRowsChange={setRows}
                                onScroll={handleScroll}
                            />
                        </div>
                    </div>
                    <div className={classes.action}>
                        <Button
                            variant="contained"
                            disabled={isSaving}
                            color="secondary"
                            style={{ minWidth: 100 }}
                            onClick={handleSave}
                        >
                            {isSaving ? (
                                <CircularProgress color="inherit" size={24} />
                            ) : (
                                gettext('Save')
                            )}
                        </Button>
                    </div>
                </>
            ) : (
                <div className={classes.skeletonContainer}>
                    <TableSkeleton rowsNum={10} colsNum={3} height={33} />
                </div>
            )}

            {!!selectedStockRecord && (
                <EventHistory
                    stockRecord={selectedStockRecord}
                    open={!!selectedStockRecord}
                    onClose={closeHistoryModal}
                />
            )}

            {!!selectedStockItem && (
                <BundleItemModal
                    stockItem={selectedStockItem}
                    open={!!selectedStockItem}
                    onClose={closeBundleModal}
                />
            )}
        </>
    )
}

type Row = {
    id: number
    sku: string
    name: string
    name_changed: boolean
    [key: string]: unknown
}

function updateInitialRows(
    stockItems: StockItemWithStockRecords[],
    locations: UserLocation[]
): Row[] {
    return stockItems.map((stockItem) => {
        const row = {
            id: stockItem.id,
            sku: stockItem.sku,
            is_bundle: stockItem.is_bundle,
            name: stockItem.name,
            name_changed: false,
        }
        const stockRecordsByLocationId = {}
        stockItem.stock_records.map((stockRecord) => {
            stockRecordsByLocationId[stockRecord.location_id] = stockRecord
        })

        locations.forEach((location) => {
            const stockRecord = stockRecordsByLocationId[location.id]
            row[`${location.id}_quantity`] = stockRecord?.quantity
            row[`${location.id}_stock_record_id`] = stockRecord?.id
            row[`${location.id}_changed`] = false
        })

        return row
    })
}

function useLocationQuery() {
    const user = useUser()
    return useQuery({
        queryKey: queryKeys.users.locations(user.id).queryKey,
        queryFn: () => api.users.locations.query({ params: { id: user.id } }),
    })
}

const FETCH_LIMIT = 25

function useStockItemsInfiniteQuery(params) {
    return useInfiniteQuery({
        enabled: true,
        queryKey: queryKeys.stock.stockRecords(params).queryKey,
        queryFn: ({ pageParam = 0 }) => {
            return api.stock.stockRecords
                .query({
                    query: {
                        ...params,
                        limit: FETCH_LIMIT,
                        offset: FETCH_LIMIT * pageParam,
                    },
                })
                .then((res) => res.body)
        },
        getNextPageParam: (lastPage) => {
            if (!lastPage.next) {
                return
            }

            const url = new URL(lastPage.next)
            const offset = parseInt(url.searchParams.get('offset') ?? '')
            if (isNaN(offset)) {
                return
            }

            return Math.ceil(offset / FETCH_LIMIT)
        },
    })
}
