import React from 'react'

import Button from '@material-ui/core/Button'
import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import { getPath } from '../../oakra/components/base-app'
import { copyObject, getTranslation } from '../../tools/utils'

import Switch from '@material-ui/core/Switch'

import ChannelIcons from './channel-icons'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        position: 'relative',
    },
    prompt: {
        marginTop: 24,
        display: 'flex',
        alignItems: 'center',
        padding: '20px 30px',
    },
    storeInfo: {
        textAlign: 'center',
        borderRight: '1px solid ' + grey[200],
        paddingTop: 25,
        paddingBottom: 25,
        paddingRight: 25,
        marginRight: 25,
        minWidth: 300,
    },
    promptMessage: {
        display: 'flex',
        alignItems: 'center',
    },
    promptInfoIcon: {
        height: 30,
        color: grey[500],
        marginRight: 10,
    },
    channelIcon: {
        width: 100,
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    showRequiredOnlySwitch: {
        marginLeft: 15,
    },
    toolbar: {
        position: 'fixed',
    },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
    },
    tableHeader: {
        borderTop: '1px solid ' + grey[200],
    },
    tableHeaderRow: {},
    tableCell: {
        padding: '10px 30px',
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    warningIcon: {
        color: orange[500],
        fontSize: 20,
        marginRight: 5,
    },
    issueCell: {
        display: 'flex',
    },
    checkIcon: {
        color: theme.palette.primary.main,
    },
    noProductMessage: {
        textAlign: 'center',
        padding: '40px 0',
    },
})

const REASONS = {
    overlapping_skus: gettext('SKUs overlap with other products'),
    missing_skus: gettext('Missing SKUs'),
}

const columns = [
    { id: 'product', align: 'left', label: gettext('Product'), sort: false, width: '50%' },
    { id: 'reason', align: 'left', label: gettext('Reason'), sort: false, width: '20%' },
    { id: 'required', align: 'center', label: gettext('Required'), sort: false, width: '5%' },
    { id: 'action', align: 'center', label: gettext('Action'), sort: false, width: '15%' },
    { id: 'channels', align: 'center', label: gettext('Channels'), sort: false, width: '10%' },
]

class MergeRecommendationsTableHead extends React.PureComponent {
    render() {
        const { classes } = this.props

        return (
            <TableHead className={classes.tableHeader}>
                <TableRow className={classes.tableHeaderRow}>
                    {columns.map((column) => {
                        return (
                            <TableCell
                                className={classes.tableCell}
                                key={column.id}
                                align={column.align}
                                width={column.width}
                            >
                                {column.label}
                            </TableCell>
                        )
                    }, this)}
                </TableRow>
            </TableHead>
        )
    }
}

MergeRecommendationsTableHead = withStyles(styles)(MergeRecommendationsTableHead)

class MergeRecommendations extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            store: null,
            rowsPerPage: 25,
            requiredMerges: [],
            count: 0,
            page: 0,
            maxPageFetched: 0,
            loading: true,
            showRequiredOnly: false,
        }

        this.fetchStore = this.fetchStore.bind(this)
        this.fetchMergeRecommendations = this.fetchMergeRecommendations.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleShowRequiredOnly = this.handleShowRequiredOnly.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    componentDidMount() {
        const { mergeRecommendationsAPIURL } = this.props

        this.fetchStore()
        this.refresh()
    }

    fetchStore() {
        const { storeAPIURL } = this.props
        const { store_id } = this.props.match.params

        fetch(storeAPIURL.replace('store_id', store_id))
            .then((response) => response.json())
            .then((store) => {
                this.setState({ store })
            })
    }

    fetchMergeRecommendations(url, replace, page = null) {
        const { store_id } = this.props.match.params

        let requestURL = url.replace('store_pk', store_id)
        if (this.state.showRequiredOnly) {
            if (!url.includes('required')) {
                requestURL = requestURL.concat('?required=true')
            }
        }

        return fetch(requestURL)
            .then((response) => response.json())
            .then((data) => {
                let requiredMerges

                if (replace) {
                    requiredMerges = data.results
                } else {
                    requiredMerges = copyObject(this.state.requiredMerges)
                    requiredMerges = requiredMerges.concat(data.results)
                }

                const newState = {
                    count: data.count,
                    next: data.next,
                    previous: data.previous,
                    requiredMerges: requiredMerges,
                    loading: false,
                }

                if (page === 0) {
                    newState['page'] = 0
                    newState['maxPageFetched'] = 0
                } else if (page > 0) {
                    newState['page'] = page
                }

                this.setState(newState)
            })
    }

    handleChangePage(event, page) {
        let pageChangeURL

        if (page > this.state.page) {
            pageChangeURL = this.state.next
        } else {
            pageChangeURL = this.state.previous
        }

        const needsFetch = page > this.state.maxPageFetched

        if (needsFetch) {
            this.setState(
                {
                    maxPageFetched: page,
                    loading: true,
                },
                () => {
                    this.fetchMergeRecommendations(pageChangeURL, false, page)
                }
            )
        } else {
            this.setState({
                page: page,
            })
        }
    }

    handleShowRequiredOnly(e) {
        const { mergeRecommendationsAPIURL } = this.props

        this.setState(
            {
                loading: true,
                showRequiredOnly: e.target.checked,
            },
            () => {
                this.fetchMergeRecommendations(mergeRecommendationsAPIURL, true)
            }
        )
    }

    refresh() {
        this.setState(
            {
                requiredMerges: [],
                count: 0,
                page: 0,
                maxPageFetched: 0,
            },
            () => {
                const { mergeRecommendationsAPIURL } = this.props
                this.fetchMergeRecommendations(mergeRecommendationsAPIURL, true)
            }
        )
    }

    render() {
        const { classes, language, staticRoot } = this.props
        const { rowsPerPage, page } = this.state
        const emptyRows =
            rowsPerPage -
            Math.min(rowsPerPage, this.state.requiredMerges.length - page * rowsPerPage)

        const displayedMerges = this.state.requiredMerges.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        )

        return (
            <React.Fragment>
                <Paper className={classes.prompt}>
                    <div className={classes.storeInfo}>
                        {this.state.store && (
                            <React.Fragment>
                                <Typography>{this.state.store.name}</Typography>
                            </React.Fragment>
                        )}
                    </div>
                    <div className={classes.promptMessage}>
                        <InfoIcon className={classes.promptInfoIcon} />
                        <Typography>
                            {gettext(
                                'These products could not be automatically merged during import. You must make required merges before using before the system.'
                            )}
                        </Typography>
                    </div>
                </Paper>
                <Paper className={classes.root}>
                    <div className={classes.headerContainer}>
                        {this.state.loading && (
                            <LinearProgress className={classes.progress} color="secondary" />
                        )}
                        <FormControlLabel
                            className={classes.showRequiredOnlySwitch}
                            control={
                                <Switch
                                    checked={this.state.showRequiredOnly}
                                    onChange={this.handleShowRequiredOnly}
                                    color="secondary"
                                />
                            }
                            label={gettext('Show required merges only')}
                        />
                        <TablePagination
                            className={classes.topPagination}
                            component="div"
                            count={this.state.count}
                            rowsPerPage={25}
                            page={this.state.page}
                            backIconButtonProps={{
                                'aria-label': gettext('Previous'),
                            }}
                            nextIconButtonProps={{
                                'aria-label': gettext('Next'),
                            }}
                            onPageChange={this.handleChangePage}
                            rowsPerPageOptions={[25]}
                        />
                    </div>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <MergeRecommendationsTableHead className={classes.tableHead} />
                            <TableBody>
                                {!this.state.loading && this.state.requiredMerges.length == 0 && (
                                    <TableRow>
                                        <TableCell
                                            colSpan={5}
                                            className={classNames(
                                                classes.noProductMessage,
                                                classes.tableCell
                                            )}
                                        >
                                            <img
                                                className={classes.noProductsIcon}
                                                src={staticRoot + 'web/img/product-gray.png'}
                                            />
                                            <Typography
                                                className={classes.noProductsText}
                                                variant="subtitle1"
                                            >
                                                {gettext(
                                                    'There are no more products to merge. You can now start using the system.'
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {displayedMerges.map((merge) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={merge.product.id}>
                                            <TableCell className={classes.tableCell}>
                                                <Typography variant="body2">
                                                    {getTranslation(
                                                        merge.product,
                                                        language,
                                                        'name'
                                                    )}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Typography variant="body2">
                                                    {REASONS[merge.reason]}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="center">
                                                {merge.required && (
                                                    <CheckCircleIcon
                                                        className={classes.checkIcon}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="center">
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    href={getPath('mergeProduct')
                                                        .replace(
                                                            'merge_product_id',
                                                            merge.product.id
                                                        )
                                                        .concat('?from=merge_recommendations')}
                                                >
                                                    {gettext('Merge')}
                                                </Button>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="center">
                                                <ChannelIcons
                                                    channels={merge.product.channel_products.map(
                                                        (cp) => cp.shop.channel
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        component="div"
                        count={this.state.count}
                        rowsPerPage={rowsPerPage}
                        page={this.state.page}
                        backIconButtonProps={{
                            'aria-label': gettext('Previous'),
                        }}
                        nextIconButtonProps={{
                            'aria-label': gettext('Next'),
                        }}
                        onPageChange={this.handleChangePage}
                        rowsPerPageOptions={[25]}
                    />
                </Paper>
            </React.Fragment>
        )
    }
}

MergeRecommendations.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MergeRecommendations)
