import { useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import Typography from '@mui/material/Typography'

import BasicProductInfo from '../basic-product-info'

import ProductSection from './product-section'

import { CHANNELS_WITH_SHORT_DESCRIPTION } from '~/consts'
import { useProductFormContext } from '~/products/hooks/useProductForm'
import { useCommonStyles } from '~/products/styles'
import { getFieldLengths } from '~/products/utils/getFieldLengths'
import { getRequiredLanguages } from '~/products/utils/getRequiredLanguages'

type Props = {
    isNewProduct: boolean
    tab: string
    onChangeTab: (newTab: string) => void
}

export default function BasicInfo({ isNewProduct, tab, onChangeTab }: Props) {
    const classes = useCommonStyles()
    const { control } = useProductFormContext()
    const channelProducts = useWatch({ control, name: 'channel_products' })

    const { name, description } = getFieldLengths(channelProducts)
    const languages = useMemo(
        () => [...getRequiredLanguages(channelProducts, isNewProduct), 'en'],
        [channelProducts, isNewProduct]
    )

    const hasShortDescription = useMemo(() => {
        return channelProducts.some((cp) => {
            return (
                (cp.shouldCreateChannelProduct || cp.status === 'active') &&
                CHANNELS_WITH_SHORT_DESCRIPTION.includes(cp.shop.channel.id as never)
            )
        })
    }, [channelProducts])

    return (
        <ProductSection>
            <Typography variant="subtitle1" className={classes.sectionTitle}>
                {gettext('Basic information')}
            </Typography>
            <BasicProductInfo
                field="product"
                tab={tab}
                languages={languages}
                descriptionMaxLength={description.maxLength}
                nameMaxLength={name.maxLength}
                hasShortDescription={hasShortDescription}
                onChangeTab={onChangeTab}
            />
        </ProductSection>
    )
}
