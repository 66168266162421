import { type ProductFormSchema } from '../../hooks/useProductForm'
import { isLogisticsProviderArray } from '../guards'
import { sortStockUnitsByDimensionOptions } from '../stock-unit'
import { updateChannels } from '../updateChannels'
import { updateNotConfiguredShops } from '../updateNotConfiguredShops'

import { type ApiResponses } from '~/api'
import { type UserLocation } from '~/common/schemas/location'
import { ShopeeLogisticsProvider } from '~/common/schemas/logistics-shopee'
import { MediaType, MediaStatus, type Media, type AssignedMedia } from '~/common/schemas/media'
import {
    type ProductDiscount,
    type Product,
    type ChannelStockUnitDiscount,
} from '~/common/schemas/product'
import { type StockItem } from '~/common/schemas/stock-item'
import { type StockRecord } from '~/common/schemas/stock-record'
import { type StockUnit } from '~/common/schemas/stock-unit'
import { type StoreWithShops } from '~/store'

type CompleteProductInfo = {
    product: ApiResponses['products']['detail']['body']
    store: StoreWithShops
    fulfillmentLocations: UserLocation[]
    stockRecords: StockRecord[]
    discounts: ProductDiscount[]
    activeChannelStockUnitDiscounts: ChannelStockUnitDiscount[]
    existingChannelAttributeValues: Record<
        number,
        ApiResponses['channels']['attributeValues']['body']
    >
}

export function toProductFormSchema({
    product,
    store,
    fulfillmentLocations,
    stockRecords,
    discounts,
    activeChannelStockUnitDiscounts,
    existingChannelAttributeValues,
}: CompleteProductInfo): ProductFormSchema {
    const languages = store.languages
    const stockItems = getStockItemsWithDimensions(product.stock_units)
    const largestStockItem = findLargestStockItem(stockItems)
    const translations = transformTranslations(product.translations, languages)
    const images = transformAssignedMediaObjects(product.images, MediaType.IMAGE)
    const videos = transformAssignedMediaObjects(product.videos ?? [], MediaType.VIDEO)
    const grouped_media = transformMediaObjects(product.grouped_media ?? [])

    // Update stock units on product load only if they exist to prevent empty properties
    const stockUnits = transformStockUnits(
        product.stock_units,
        product.channel_products,
        fulfillmentLocations,
        stockRecords
    )

    const channelProducts = transformChannelProducts({
        channelProducts: product.channel_products,
        productId: product.id,
        stockUnits,
        languages,
    })

    const channels = updateChannels(channelProducts)
    const notConfiguredShops = updateNotConfiguredShops(
        channelProducts,
        channels,
        store.shops,
        stockUnits.length > 1
    )

    const channelAttributeValues = transformChannelAttributeValues(
        existingChannelAttributeValues,
        channelProducts
    )

    return {
        ...product,
        channel_attribute_values: channelAttributeValues,
        package_height: largestStockItem?.package_height ?? product.package_height,
        package_length: largestStockItem?.package_length ?? product.package_length,
        package_weight: largestStockItem?.package_weight ?? product.package_weight,
        package_width: largestStockItem?.package_width ?? product.package_width,
        store,
        translations,
        grouped_media,
        images,
        videos,
        channel_products: channelProducts,
        stock_units: stockUnits,
        channels,
        notConfiguredShops,
        product_discounts: discounts,
        active_channel_stock_unit_discounts: activeChannelStockUnitDiscounts,
    }
}

function getStockItemsWithDimensions(stockUnits: StockUnit[]) {
    return stockUnits.reduce<StockItem[]>((acc, su) => {
        if (
            su.stock_item?.package_height &&
            su.stock_item.package_width &&
            su.stock_item.package_length &&
            su.stock_item.package_weight
        ) {
            acc.push(su.stock_item)
        }

        return acc
    }, [])
}

function findLargestStockItem(stockItems: StockItem[]) {
    return (
        stockItems
            .sort((a, b) => {
                if (
                    !a.package_height ||
                    !a.package_width ||
                    !a.package_length ||
                    !a.package_weight ||
                    !b.package_height ||
                    !b.package_width ||
                    !b.package_length ||
                    !b.package_weight
                ) {
                    return 0
                }

                const scoreA = a.package_height * a.package_width * a.package_length
                const scoreB = b.package_height * b.package_width * b.package_length

                if (scoreA === scoreB) {
                    return b.package_weight - a.package_weight // higher weight comes first if scores are equal
                }

                return scoreB - scoreA // higher score comes first
            })
            .at(0) ?? null
    )
}

function transformTranslations(currentTranslations: Product['translations'], languages: string[]) {
    return languages.reduce(
        (acc, language) => {
            if (!(language in currentTranslations)) {
                acc[language] = {
                    name: '',
                    description: '',
                }
            }

            return acc
        },
        { ...currentTranslations }
    )
}

function transformAssignedMediaObjects<T extends AssignedMedia>(
    currentMediaObjs: T[],
    mediaType: NonNullable<Media['media_type']>
) {
    return (
        currentMediaObjs?.map((m) => ({
            ...m,
            name: m.url,
            status: MediaStatus.UPLOADED,
            media_type: mediaType,
        })) ?? []
    )
}

function transformMediaObjects<T extends Media>(currentMediaObjs: T[]) {
    return (
        currentMediaObjs?.map((m) => ({
            ...m,
            name: m.file,
            status: MediaStatus.UPLOADED,
        })) ?? []
    )
}

function mapStockRecordsBySkuLocationID(records: StockRecord[]) {
    return records.reduce((acc, stockRecord) => {
        const key = `${stockRecord.stock_item.sku}/${stockRecord.location.id}`
        if (!acc.has(key)) {
            return acc.set(key, stockRecord)
        }
        return acc
    }, new Map<string, StockRecord>())
}

function transformStockUnits(
    currentStockUnits: Product['stock_units'],
    currentChannelProducts: Product['channel_products'],
    fulfillmentLocations: UserLocation[],
    stockRecords: StockRecord[] = []
): ProductFormSchema['stock_units'] {
    if (!currentStockUnits.length) {
        return []
    }

    const stockRecordsBySkuLocationID = mapStockRecordsBySkuLocationID(stockRecords)
    const stockUnits = currentStockUnits.map((su) => {
        const stockRecords = fulfillmentLocations.map(
            (location) =>
                stockRecordsBySkuLocationID.get(`${su.sku}/${location.id}`) ?? {
                    location,
                    quantity: 0,
                }
        )
        return {
            ...su,
            images: transformAssignedMediaObjects(su.images, MediaType.IMAGE),
            stock_records: stockRecords,
            fulfillment_locations: su.stock_item.is_bundle ? [] : fulfillmentLocations,
        }
    })

    const channelStockUnits = currentChannelProducts.reduce(
        (acc, cp) => [...acc, ...cp.channel_stock_units],
        [] as Product['channel_products'][0]['channel_stock_units']
    )

    const fulfillmentOptionsByStockUnitId = channelStockUnits.reduce((map, csu) => {
        const locations = csu.channel_stock_item?.fulfillment_stock_record_options.map(
            ({ location }) => location
        )
        if (locations) {
            map.set(csu.stock_unit.id, locations)
        }

        return map
    }, new Map<number, UserLocation[]>())

    return sortStockUnitsByDimensionOptions(
        stockUnits.map((su) => {
            const fulfillmentOptions = fulfillmentOptionsByStockUnitId.get(su?.id)
            if (!fulfillmentOptions) {
                return su
            }

            return {
                ...su,
                fulfillment_locations: [...fulfillmentOptions],
            }
        })
    )
}

/**
 * Return active channel products sorted by channel id or shop name
 * @param channelProducts - All channel products received from the API
 */
function getActiveChannelProducts(channelProducts: Product['channel_products']) {
    return channelProducts
        .filter((cp) => cp.status !== 'deleted')
        .sort((cp1, cp2) => {
            return cp1.shop.channel.id === cp2.shop.channel.id
                ? cp1.shop.shop_name.localeCompare(cp2.shop.shop_name)
                : cp1.shop.channel.id - cp2.shop.channel.id
        })
}

/**
 * Some channel products may be missing channel stock units for certain stock units
 * because we don't create missing channel stock units when merging products
 * @param channelProduct - Channel product received from the API
 * @param stockUnits Stock units received from the API
 */
function transformChannelStockUnits(
    channelProduct: Product['channel_products'][number],
    stockUnits: ProductFormSchema['stock_units']
) {
    const channelStockUnitById = new Map(
        channelProduct.channel_stock_units.map((csu) => [csu.stock_unit.id, csu])
    )

    return stockUnits.map((stockUnit) => {
        const channelStockUnit = channelStockUnitById.get(parseInt(stockUnit.id as string))

        return channelStockUnit
            ? {
                  ...channelStockUnit,
                  images: transformAssignedMediaObjects(
                      channelProduct.has_channel_specific_media
                          ? channelStockUnit.images.map((image) => ({
                                ...image,
                                status: MediaStatus.UPLOADED,
                                name: image.url,
                                media_type: MediaType.IMAGE,
                            }))
                          : [...channelStockUnit.images],
                      MediaType.IMAGE
                  ),
                  stock_unit: stockUnit,
                  placeholder: false,
              }
            : {
                  // Pad channel stock unit to show coverage
                  placeholder: true,
                  price: '0',
                  images: [],
                  stock_unit: stockUnit,
                  sku: stockUnit.sku,
                  channel_stock_item: { fulfillment_stock_record_options: [] },
                  channel_product: channelProduct.id,
              }
    }) satisfies ProductFormSchema['channel_products'][number]['channel_stock_units']
}

/**
 * Some channel products may be inactive if they are inactive duplicates that get imported & associated with an
 * existing product
 *
 * @param opts - Options
 * @param opts.channelProducts - All channel products received from the API
 * @param opts.stockUnits - Stock units received from the API
 * @param opts.productId - Product ID
 * @param opts.languages - Required shop languages
 */
function transformChannelProducts(opts: {
    channelProducts: Product['channel_products']
    stockUnits: ProductFormSchema['stock_units']
    productId: number
    languages: string[]
}): ProductFormSchema['channel_products'] {
    const { channelProducts, stockUnits, productId, languages } = opts
    return getActiveChannelProducts(channelProducts).map((cp) => {
        const updatedChannelProduct = {
            ...cp,
            translations: transformTranslations(cp.translations, languages),
            product: productId,
            grouped_media: cp.grouped_media ? transformMediaObjects(cp.grouped_media) : null,
            images: cp.has_channel_specific_media
                ? cp.images.map((image) => ({
                      ...image,
                      status: 'uploaded' as const,
                      name: image.url,
                      media_type: MediaType.IMAGE,
                  }))
                : [...cp.images],
            videos: cp.has_channel_specific_media
                ? cp?.videos?.map((video) => ({
                      ...video,
                      status: 'uploaded' as const,
                      name: video.url,
                      media_type: MediaType.VIDEO,
                  })) ?? []
                : [...(cp?.videos ?? [])],
            channel_stock_units: transformChannelStockUnits(cp, stockUnits),
            shouldCreateChannelProduct: false,
        }

        languages.forEach((language) => {
            if (!(language in updatedChannelProduct.translations)) {
                updatedChannelProduct.translations[language] = {
                    name: '',
                    description: '',
                }
            }
        })

        return updatedChannelProduct
    })
}

function transformChannelAttributeValues(
    existingValues: CompleteProductInfo['existingChannelAttributeValues'],
    channelProducts: ProductFormSchema['channel_products']
): ProductFormSchema['channel_attribute_values'] {
    const channelAttributeValues: ProductFormSchema['channel_attribute_values'] = {}
    const channelStockUnits = channelProducts.flatMap((cp) => cp.channel_stock_units)
    const stockUnitByChannelStockUnitId = new Map(channelStockUnits.map((csu) => [csu.id, csu]))

    Object.entries(existingValues).forEach(([channelId, attributeValues]) => {
        const productAttributeValues = attributeValues.products.reduce(
            (acc, productAttribute) => {
                if (isLogisticsProviderArray(productAttribute.value)) {
                    const logisticsAttributeValuesById = channelProducts.reduce((acc, cp) => {
                        if (cp.shop.channel.id === parseInt(channelId)) {
                            acc.set(`shop-${cp.shop.id}`, {
                                channel: 'shopee',
                                value: ShopeeLogisticsProvider.partial()
                                    .array()
                                    .parse(productAttribute.value),
                            })
                        }

                        return acc
                    }, new Map<`shop-${number}`, { value: Array<Partial<ShopeeLogisticsProvider>>; channel: 'shopee' }>())

                    acc[productAttribute.attribute.attribute_id] = {
                        value: Object.fromEntries(logisticsAttributeValuesById),
                    }
                } else {
                    acc[productAttribute.attribute.attribute_id] = productAttribute
                }

                return acc
            },
            {} as ProductFormSchema['channel_attribute_values'][number]['product']
        )

        const stockUnitAttributeValues = attributeValues.stock_units.reduce(
            (acc, stockUnitAttribute) => {
                const value =
                    typeof stockUnitAttribute.value === 'string'
                        ? stockUnitAttribute.value
                        : stockUnitAttribute.value.value ?? stockUnitAttribute.value.label

                const stockUnitId = stockUnitByChannelStockUnitId.get(
                    stockUnitAttribute.channel_stock_unit
                )?.stock_unit?.id
                if (stockUnitId) {
                    acc[stockUnitId] = {
                        ...acc[stockUnitId],
                        [stockUnitAttribute.attribute.attribute_id.toString()]: value,
                    }
                }

                return acc
            },
            {} as NonNullable<ProductFormSchema['channel_attribute_values'][string]['stockUnit']>
        )

        channelAttributeValues[channelId] = {
            product: productAttributeValues,
            stockUnit: stockUnitAttributeValues,
        }
    })

    return channelAttributeValues
}
