import React from 'react'

import { produce } from 'immer'
import { range } from 'lodash-es'

import { copyObject } from '../../tools/utils'

export const channelAttributesStatus = {
    PENDING: 'pending',
    COMPLETE: 'complete',
}

export const AttributesContext = React.createContext()

export class AttributesProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            categoryAttributes: {},
            channelAttributeValues: {},
            shopeeLogistics: {},
            loadingAttributeValues: false,
        }

        this.categoryAttributesPromises = {}

        this.hasCategoryAttributesPromise = this.hasCategoryAttributesPromise.bind(this)
        this.resetCategoryAttributesPromise = this.resetCategoryAttributesPromise.bind(this)

        this.updateAttributeValues = this.updateAttributeValues.bind(this)
        this.updateAttributeUnits = this.updateAttributeUnits.bind(this)
        this.checkMinimumRequirementsMet = this.checkMinimumRequirementsMet.bind(this)
        this.registerChannelProducts = this.registerChannelProducts.bind(this)
        this.idsForProductsWithAttributes = this.idsForProductsWithAttributes.bind(this)
        this.copyAttributesHandler = this.copyAttributesHandler.bind(this)
        this.hasSKUAttributes = this.hasSKUAttributes.bind(this)
        this.getChannelAttributeValues = this.getChannelAttributeValues.bind(this)
        this.getUniqueComboAttributeValuesID = this.getUniqueComboAttributeValuesID.bind(this)

        this.resetAttributeValues = this.resetAttributeValues.bind(this)
    }

    addCategoryAttributesPromise = (channelID, categoryID, categoryAttributesPromise) => {
        if (!(channelID in this.categoryAttributesPromises)) {
            this.categoryAttributesPromises[channelID] = {}
        }

        this.categoryAttributesPromises[channelID][categoryID] = categoryAttributesPromise
    }

    hasCategoryAttributesPromise(channelID, categoryID) {
        if (!(channelID in this.categoryAttributesPromises)) {
            return false
        }

        if (!(categoryID in this.categoryAttributesPromises[channelID])) {
            return false
        }

        return true
    }

    resetCategoryAttributesPromise(channelID, categoryID) {
        if (this.hasCategoryAttributesPromise(channelID, categoryID)) {
            delete this.categoryAttributesPromises[channelID][categoryID]
        }
    }

    fetchAttributesForCategory = (channelID, categoryID, shopID, callback = null) => {
        const { categoryAttributesAPIURL } = this.props

        const promise = new Promise((resolve, reject) => {
            fetch(
                categoryAttributesAPIURL
                    .replace('channel_pk', channelID)
                    .replace('category_id', categoryID) +
                    '?shop_id=' +
                    shopID
            )
                .then((response) => response.json())
                .then((attributesData) => {
                    const categoryAttributes = copyObject(this.state.categoryAttributes)
                    if (channelID in categoryAttributes) {
                        categoryAttributes[channelID][categoryID] = attributesData
                    } else {
                        categoryAttributes[channelID] = { [categoryID]: attributesData }
                    }

                    this.setState({ categoryAttributes }, () => {
                        resolve(attributesData)
                        if (callback) {
                            callback(attributesData)
                        }
                    })
                })
                .catch(() => {
                    reject()
                })
                .finally(() => {
                    this.resetCategoryAttributesPromise(channelID, categoryID)
                })
        })

        this.addCategoryAttributesPromise(channelID, categoryID, promise)

        return promise
    }

    attributesForCategory = (channelID, categoryID, shopID, callback) => {
        if (
            channelID in this.state.categoryAttributes &&
            categoryID in this.state.categoryAttributes[channelID]
        ) {
            callback(this.state.categoryAttributes[channelID][categoryID])
        } else if (this.hasCategoryAttributesPromise(channelID, categoryID)) {
            const promise = this.categoryAttributesPromises[channelID][categoryID]

            promise.then((categoryAttributes) => {
                callback(categoryAttributes)
            })
        } else {
            this.fetchAttributesForCategory(channelID, categoryID, shopID, (categoryAttributes) => {
                callback(categoryAttributes)
            })
        }
    }

    getChannelAttributeValues = async (_, __, channelProduct) => {
        return await Promise.all([
            this.fetchProductAttributeValues(channelProduct.id, channelProduct.shop.channel.id),
            this.fetchStockUnitAttributeValues(
                channelProduct.id,
                channelProduct.shop.channel.id,
                channelProduct.channel_stock_units
            ),
        ])
    }

    fetchProductAttributeValues = async (channelProductID, channelID) => {
        const { channelProductAttributesAPIURL, language } = this.props

        const url =
            channelProductAttributesAPIURL.replace('channel_product_id', channelProductID) +
            '?language=' +
            language

        const res = await fetch(url)
        const data = await res.json()

        return new Promise((resolve) => {
            this.setState(
                produce((draft) => {
                    draft.channelAttributeValues[channelID].fetchedAttributeValues = data

                    data.forEach((a) => {
                        draft.channelAttributeValues[channelID].attributes[
                            a.attribute.attribute_id
                        ] = a.value
                        draft.channelAttributeValues[channelID].attributeUnits[
                            a.attribute.attribute_id
                        ] = a.unit
                    })
                }),
                () => resolve()
            )
        })
    }

    fetchStockUnitAttributeValues = async (channelProductId, channelId, channelStockUnits) => {
        const { channelStockUnitAttributesAPIURL, language } = this.props

        const res = await fetch(
            channelStockUnitAttributesAPIURL.replace('channel_product_id', channelProductId) +
                '?language=' +
                language
        )

        const attributes = await res.json()
        const channelStockUnitAttributesById = new Map()
        attributes.forEach((a) => {
            const channelStockUnitAttributeValues =
                channelStockUnitAttributesById.get(a.channel_stock_unit) ?? []

            channelStockUnitAttributeValues.push(a)

            channelStockUnitAttributesById.set(
                a.channel_stock_unit,
                channelStockUnitAttributeValues
            )
        })

        return new Promise((resolve) => {
            this.setState(
                produce((draft) => {
                    draft.channelAttributeValues[channelId].stock_units.forEach((su, index) => {
                        const id = channelStockUnits[index]?.id ?? -1

                        su.fetchedAttributeValues = channelStockUnitAttributesById.get(id) ?? []
                        su.attributes = su.fetchedAttributeValues.reduce((acc, a) => {
                            acc[a.attribute.attribute_id] = a.value
                            return acc
                        }, {})
                    })
                }),
                () => resolve()
            )
        })
    }

    formatAttributeValues = (channelAttributeValues) => {
        let attributeValues
        const attributeValuesObj = channelAttributeValues.attributes
        const fetchedAttributeValues = channelAttributeValues.fetchedAttributeValues
        const attributeUnits = channelAttributeValues.attributeUnits || []

        if (fetchedAttributeValues) {
            attributeValues = fetchedAttributeValues.map((av) => ({
                attribute_id: av.attribute.attribute_id,
                value: av.value,
                unit: av.unit,
            }))
        } else {
            attributeValues = []
        }

        Object.entries(attributeValuesObj).forEach(([attributeID, attributeValue]) => {
            const fetchedAttributeValue = attributeValues.find(
                (av) => av.attribute_id === attributeID
            )

            let attributeValueData

            if (Array.isArray(attributeValue)) {
                attributeValueData = attributeValue.map((v) => v.value)
            } else if (typeof attributeValue == 'object' && attributeValue !== null) {
                attributeValueData = attributeValue.value
            } else {
                attributeValueData = attributeValue
            }

            if (fetchedAttributeValue) {
                fetchedAttributeValue.value = attributeValueData
            } else {
                attributeValues.push({
                    attribute_id: attributeID,
                    value: attributeValueData,
                })
            }
        })

        Object.entries(attributeUnits).forEach(([attributeID, unit]) => {
            const fetchedAttributeValue = attributeValues.find(
                (av) => av.attribute_id === attributeID
            )

            if (fetchedAttributeValue) {
                fetchedAttributeValue.unit = unit
            } else {
                attributeValues.push({
                    attribute_id: attributeID,
                    unit: unit,
                })
            }
        })

        // Final check for empty string values that slipped through
        return attributeValues.filter((av) => !!av.value)
    }

    formatChannelAttributeValuesForUpload = (channelProducts) => {
        const filteredChannelProducts = channelProducts.filter(
            (cp) =>
                cp.shop.channel.has_category_attributes &&
                (cp.shouldCreateChannelProduct || typeof cp.id == 'number')
        )

        return filteredChannelProducts.reduce((acc, cp) => {
            const dataForChannel = { stock_units: [] }
            const channelAttributeValues = this.state.channelAttributeValues[cp.shop.channel.id]
            const attributesValues = this.formatAttributeValues(channelAttributeValues)

            dataForChannel['product_attribute_values'] = attributesValues

            channelAttributeValues.stock_units.forEach((stockUnitChannelAttributeValues) => {
                const stockUnitAttributeValues = this.formatAttributeValues(
                    stockUnitChannelAttributeValues
                )
                dataForChannel.stock_units.push({
                    stock_unit_attribute_values: stockUnitAttributeValues,
                })
            })

            acc[cp.shop.channel.id] = dataForChannel

            return acc
        }, {})
    }

    formatChannelAttributeValues = (channelProducts) => {
        const channelAttributeValuesData = {}

        channelProducts
            .filter(
                (cp) =>
                    cp.shop.channel.has_category_attributes &&
                    (cp.shouldCreateChannelProduct || typeof cp.id == 'number')
            )
            .forEach((cp) => {
                const channelAttributeValues = this.state.channelAttributeValues[cp.shop.channel.id]
                const dataForChannel = {
                    stock_units: [],
                    product_attribute_values: this.formatAttributeValues(channelAttributeValues),
                }

                channelAttributeValues.stock_units.forEach((stockUnitChannelAttributeValues) => {
                    const stockUnitAttributeValues = this.formatAttributeValues(
                        stockUnitChannelAttributeValues
                    )
                    dataForChannel.stock_units.push({
                        stock_unit_attribute_values: stockUnitAttributeValues,
                    })
                })

                channelAttributeValuesData[cp.shop.channel.id] = dataForChannel
            })

        return channelAttributeValuesData
    }

    registerChannelProducts(channelProducts, callback) {
        const channelAttributeValues = copyObject(this.state.channelAttributeValues)

        const channelProductsToAdd = channelProducts.filter((cp) => {
            return !channelAttributeValues.hasOwnProperty(cp.shop.channel.id)
        })

        channelProductsToAdd.forEach((cp) => {
            channelAttributeValues[cp.shop.channel.id] = {
                __status: channelAttributesStatus.PENDING,
                attributes: {},
                attributeUnits: {},
                stock_units: cp.channel_stock_units.map((csu) => ({
                    attributes: {},
                    errors: {},
                })),
                errors: {},
            }
        })

        this.setState({ channelAttributeValues }, callback)
    }

    addStockUnit = (amount = 1) => {
        if (amount < 1) {
            return
        }

        this.setState(
            produce((draft) => {
                Object.values(draft.channelAttributeValues).forEach((values) => {
                    const newStockUnits = range(amount).map(() => ({ attributes: {}, errors: {} }))
                    values.stock_units.push(...newStockUnits)
                })
            })
        )
    }

    deleteStockUnits = (stockUnitIndexes) => {
        const indexSet = new Set(stockUnitIndexes)

        this.setState(
            produce((draft) => {
                Object.values(draft.channelAttributeValues).forEach((values) => {
                    values.stock_units = values.stock_units.filter(
                        (_, index) => !indexSet.has(index)
                    )
                })
            })
        )
    }

    replaceStockUnits = (stockUnits) => {
        this.setState(
            produce((draft) => {
                Object.values(draft.channelAttributeValues).forEach((values) => {
                    values.stock_units = stockUnits.map(() => ({ attributes: {}, errors: {} }))
                })
            })
        )
    }

    updateAttributeValues(channelID, categoryID, stockUnitIndex) {
        return (attributeID, value, callback) => {
            const channelAttributeValues = copyObject(this.state.channelAttributeValues)
            let obj

            if (typeof stockUnitIndex == 'number') {
                obj = channelAttributeValues[channelID].stock_units[stockUnitIndex]
            } else {
                obj = channelAttributeValues[channelID]
            }

            obj.attributes[attributeID] = value
            this.setState({ channelAttributeValues }, callback)
        }
    }

    updateAttributeUnits(channelID, categoryID, stockUnitIndex) {
        return (attributeID, value, callback) => {
            const channelAttributeValues = copyObject(this.state.channelAttributeValues)
            let obj

            if (typeof stockUnitIndex == 'number') {
                obj = channelAttributeValues[channelID].stock_units[stockUnitIndex]
            } else {
                obj = channelAttributeValues[channelID]
            }

            obj.attributeUnits[attributeID] = value
            this.setState({ channelAttributeValues }, callback)
        }
    }

    fetchShopeeLogisticsProviders = (shopIDs, callback) => {
        const { shopeeLogisticsProvidersAPIURL } = this.props
        const queryStringParams = shopIDs.map((shopID) => `shop_id=${shopID}`)
        const queryString = queryStringParams.join('&')
        const url = `${shopeeLogisticsProvidersAPIURL}?${queryString}`

        return fetch(url)
            .then((response) => response.json())
            .then((logisticsProviders) => {
                const shopeeLogistics = copyObject(this.state.shopeeLogistics)

                Object.entries(logisticsProviders).forEach(([shopID, providers]) => {
                    const mergedLogistics = []

                    providers.forEach((provider) => {
                        provider.enabled = false
                        provider.is_free = false

                        let mergedValue

                        if (shopeeLogistics.hasOwnProperty(shopID)) {
                            const alreadyFetchedProvider = shopeeLogistics[shopID].find(
                                (v) => v.logistic_id == provider.logistic_id
                            )
                            if (alreadyFetchedProvider) {
                                mergedValue = { ...provider, ...alreadyFetchedProvider }
                            } else {
                                mergedValue = provider
                            }
                        } else {
                            mergedValue = provider
                        }

                        mergedLogistics.push(mergedValue)
                    })

                    shopeeLogistics[shopID] = mergedLogistics
                })

                this.setState({ shopeeLogistics }, callback)
            })
    }

    fetchShopeeLogisticsValues = (channelProducts, callback) => {
        const { shopeeLogisticsValuesAPIURL } = this.props
        const queryStringParams = channelProducts.map((cp) => `channel_product_id=${cp.id}`)
        const queryString = queryStringParams.join('&')

        const url = `${shopeeLogisticsValuesAPIURL}?${queryString}`

        return fetch(url)
            .then((response) => response.json())
            .then((logisticsValues) => {
                const shopeeLogistics = copyObject(this.state.shopeeLogistics)

                Object.entries(shopeeLogistics).forEach(([shopID, providers]) => {
                    const mergedLogistics = []

                    let fetchedValues
                    if (logisticsValues.hasOwnProperty(shopID)) {
                        fetchedValues = logisticsValues[shopID].filter((v) => Boolean(v))
                    }

                    providers.forEach((provider) => {
                        let mergedValue

                        if (fetchedValues) {
                            const fetchedValue = fetchedValues.find(
                                (v) => v.logistic_id == provider.logistic_id
                            )
                            if (fetchedValue) {
                                mergedValue = { ...provider, ...fetchedValue }
                                mergedLogistics.push(mergedValue)
                            } else {
                                mergedLogistics.push(provider)
                            }
                        } else {
                            mergedLogistics.push(provider)
                        }
                    })

                    shopeeLogistics[shopID] = mergedLogistics
                })

                this.setState({ shopeeLogistics }, callback)
            })
    }

    updateShopeeLogistics = (shopeeLogistics, callback) => {
        const newShopeeLogistics = copyObject(shopeeLogistics)
        this.setState({ shopeeLogistics: newShopeeLogistics }, callback)
    }

    removeShopeeLogistics = (shopIDs, callback) => {
        const shopeeLogistics = copyObject(this.state.shopeeLogistics)
        shopIDs.forEach((shopID) => {
            delete shopeeLogistics[shopID]
        })
        this.setState({ shopeeLogistics }, callback)
    }

    syncShopeeLogisticsShops = (shopeeProducts) => {
        const shopIDs = shopeeProducts.map((cp) => cp.shop.id)
        const currentShopIDs = Object.keys(this.state.shopeeLogistics).map((k) => parseInt(k))

        const shopsToAddIDs = shopIDs.filter((shopID) => !currentShopIDs.includes(shopID))
        const shopsToRemoveIDs = currentShopIDs.filter((shopID) => !shopIDs.includes(shopID))

        this.removeShopeeLogistics(shopsToRemoveIDs, () => {
            this.fetchShopeeLogisticsProviders(shopsToAddIDs)
        })
    }

    getUniqueComboAttributeValuesID(channelID, categoryID, stockUnitIndex) {
        const uniqueComboStockUnitAttributes = this.state.categoryAttributes[channelID][
            categoryID
        ].filter(
            (attribute) =>
                attribute.attribute_id != 'logistics' &&
                attribute.sku_attribute &&
                attribute.unique_for_stock_unit
        )
        const stockUnitAttributesValues =
            this.state.channelAttributeValues[channelID].stock_units[stockUnitIndex].attributes

        const uniqueComboAttributeValues = uniqueComboStockUnitAttributes
            .map((attribute) => {
                const attributeID = attribute.attribute_id
                let attributeValue

                if (typeof stockUnitAttributesValues[attributeID] == 'object') {
                    attributeValue = stockUnitAttributesValues[attributeID].value
                } else {
                    attributeValue = stockUnitAttributesValues[attributeID]
                }

                return attributeValue
            })
            .filter((comboID) => Boolean(comboID))

        uniqueComboAttributeValues.sort()

        if (uniqueComboAttributeValues.length > 0) {
            return uniqueComboAttributeValues.join('-')
        }

        return null
    }

    checkMinimumRequirementsMet(channel, categoryID, shopeeLogistics) {
        this.setState(
            produce((draft) => {
                const channelAttributeValues = draft.channelAttributeValues
                const attributeValuesForChannel = this.clearErrors(
                    channelAttributeValues[channel.id]
                )
                const categoryAttributes = draft.categoryAttributes[channel.id][categoryID]
                const requiredProductAttributes = categoryAttributes.filter(
                    (attribute) =>
                        attribute.required &&
                        attribute.attribute_id !== 'logistics' &&
                        !attribute.sku_attribute
                )

                const requiredStockUnitAttributes = categoryAttributes.filter(
                    (attribute) =>
                        attribute.required &&
                        attribute.attribute_id !== 'logistics' &&
                        attribute.sku_attribute
                )
                const uniqueComboStockUnitAttributes = categoryAttributes.filter(
                    (attribute) =>
                        attribute.attribute_id !== 'logistics' &&
                        attribute.sku_attribute &&
                        attribute.unique_for_stock_unit
                )

                let complete = requiredProductAttributes.every(
                    ({ attribute_type, attribute_id }) =>
                        attribute_type !== 'option' ||
                        attributeValuesForChannel.attributes?.[attribute_id]?.value
                )

                const uniqueComboIDsForStockUnits = []

                for (let i = 0; i < attributeValuesForChannel.stock_units.length; i++) {
                    const stockUnitAttributes = attributeValuesForChannel.stock_units[i].attributes

                    for (let j = 0; j < requiredStockUnitAttributes.length; j++) {
                        const attribute = requiredStockUnitAttributes[j]
                        const attributeID = attribute.attribute_id
                        const attributeType = attribute.attribute_type

                        if (!(attributeID in stockUnitAttributes)) {
                            complete = false
                            break
                        }

                        if (!stockUnitAttributes[attributeID]) {
                            complete = false
                            break
                        }

                        if (attributeType === 'option') {
                            if (!stockUnitAttributes[attributeID].value) {
                                complete = false
                                break
                            }
                        }

                        if (attributeType === 'multi_option') {
                            if (stockUnitAttributes[attributeID].length === 0) {
                                complete = false
                                break
                            }
                        }
                    }

                    const uniqueComboID = this.getUniqueComboAttributeValuesID(
                        channel.id,
                        categoryID,
                        i
                    )
                    if (uniqueComboID) {
                        if (uniqueComboIDsForStockUnits.includes(uniqueComboID)) {
                            attributeValuesForChannel.stock_units
                                .filter((_, suIndex) => {
                                    const uniqueComboIDForStockUnit =
                                        this.getUniqueComboAttributeValuesID(
                                            channel.id,
                                            categoryID,
                                            suIndex
                                        )
                                    return uniqueComboIDForStockUnit === uniqueComboID
                                })
                                .forEach((stockUnitData) => {
                                    uniqueComboStockUnitAttributes.forEach((attribute) => {
                                        stockUnitData.errors[attribute.attribute_id] = gettext(
                                            'Each stock unit must have a unique combination'
                                        )
                                    })
                                })

                            complete = false
                            break
                        } else {
                            uniqueComboIDsForStockUnits.push(uniqueComboID)
                        }
                    }
                }

                if (channel.name === 'Shopee' && shopeeLogistics) {
                    complete = Object.values(shopeeLogistics).every((logistics) => {
                        return logistics.some((provider) => provider.enabled)
                    })
                }

                channelAttributeValues[channel.id]['__status'] = complete
                    ? channelAttributesStatus.COMPLETE
                    : channelAttributesStatus.PENDING
            })
        )
    }

    idsForProductsWithAttributes() {
        return Object.entries(this.state.channelAttributeValues)
            .filter(([productID, productInfo]) => Object.keys(productInfo.attributes).length > 0)
            .map(([productID, productInfo]) => parseInt(productID, 10))
    }

    copyAttributesHandler(targetProductID, pickedProductID, channelID, categoryID) {
        const channelAttributeValues = copyObject(this.state.channelAttributeValues)

        channelAttributeValues[targetProductID].attributes = copyObject(
            channelAttributeValues[pickedProductID].attributes
        )

        this.setState({
            channelAttributeValues: channelAttributeValues,
        })
    }

    hasSKUAttributes(channelID, categoryID) {
        const categoryData = this.state.categoryAttributes[channelID]?.[categoryID]
        if (!categoryData || categoryData.error) {
            return false
        }

        return categoryData.some((attribute) => !!attribute.sku_attribute)
    }

    clearErrors = (channelAttributeValues) => {
        return {
            ...channelAttributeValues,
            errors: {},
            stock_units: channelAttributeValues.stock_units.map((su) => ({
                ...su,
                errors: {},
            })),
        }
    }

    resetAttributeValues(callback) {
        const channelAttributeValues = copyObject(this.state.channelAttributeValues)

        Object.entries(channelAttributeValues).forEach(
            ([channelID, attributesValuesForChannel]) => {
                attributesValuesForChannel.__status = 'pending'
                attributesValuesForChannel.attributes = {}
                attributesValuesForChannel.attributeUnits = {}
                attributesValuesForChannel.errors = {}
                attributesValuesForChannel.stock_units = []
            }
        )

        this.setState({ channelAttributeValues }, callback)
    }

    render() {
        return (
            <AttributesContext.Provider
                value={{
                    categoryAttributes: this.state.categoryAttributes,
                    channelAttributeValues: this.state.channelAttributeValues,
                    attributesForCategory: this.attributesForCategory,
                    fetchAttributesForCategory: this.fetchAttributesForCategory,
                    updateAttributeValues: this.updateAttributeValues,
                    updateAttributeUnits: this.updateAttributeUnits,
                    registerChannelProducts: this.registerChannelProducts,
                    checkMinimumRequirementsMet: this.checkMinimumRequirementsMet,
                    idsForProductsWithAttributes: this.idsForProductsWithAttributes,
                    copyAttributesHandler: this.copyAttributesHandler,
                    hasSKUAttributes: this.hasSKUAttributes,
                    getChannelAttributeValues: this.getChannelAttributeValues,
                    addStockUnit: this.addStockUnit,
                    deleteStockUnits: this.deleteStockUnits,
                    formatAttributeValues: this.formatAttributeValues,
                    formatChannelAttributeValuesForUpload:
                        this.formatChannelAttributeValuesForUpload,
                    resetAttributeValues: this.resetAttributeValues,
                    shopeeLogistics: this.state.shopeeLogistics,
                    updateShopeeLogistics: this.updateShopeeLogistics,
                    removeShopeeLogistics: this.removeShopeeLogistics,
                    syncShopeeLogisticsShops: this.syncShopeeLogisticsShops,
                    fetchShopeeLogisticsProviders: this.fetchShopeeLogisticsProviders,
                    fetchShopeeLogisticsValues: this.fetchShopeeLogisticsValues,
                    replaceStockUnits: this.replaceStockUnits,
                    formatChannelAttributeValues: this.formatChannelAttributeValues,
                }}
            >
                {this.props.children}
            </AttributesContext.Provider>
        )
    }
}
