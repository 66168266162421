import { useCallback, useMemo } from 'react'

import { buildAvailablePermissions } from './builder'

import { type ApiResponses } from '~/api'
import { useAppSelector } from '~/store'

export type ExistingPermission = ApiResponses['users']['permissions']['body'][0]
type NewPermission = Omit<ExistingPermission, 'id' | 'name'>
export type Permission = NewPermission | ExistingPermission

type Props = {
    permissionLevel: string
    targetId?: number
    permissions: ExistingPermission[]
    onUpdatePermissions: (
        permissions: Permission[] | ((permissions: Permission[]) => Permission[])
    ) => void
}

export default function useUserPermissions({
    targetId,
    permissions,
    permissionLevel,
    onUpdatePermissions,
}: Props) {
    const stores = useAppSelector((state) => state.stores)
    const contentTypes = useAppSelector((state) => state.initial.contentTypes)

    const getContentTypeByModel = useCallback(
        (model: string | null) => contentTypes.find((ct) => ct.model === model),
        [contentTypes]
    )

    const availablePermissions = useMemo(
        () => buildAvailablePermissions(stores, targetId),
        [stores, targetId]
    )

    const availableModulePermissions = useMemo(
        () => Object.keys(availablePermissions.modules[0].permissions),
        [availablePermissions]
    )

    const mapAccountPermissions = useCallback(
        (currentTargetId?: number) => {
            const accountPermissions = availablePermissions.account[0]
            if (!accountPermissions?.permissions) {
                return []
            }

            return Object.keys(accountPermissions.permissions).reduce((result, permission) => {
                const codes: string[] = Object.values(
                    accountPermissions?.permissions[permission] || {}
                )

                codes.forEach((permissionCode) => {
                    result.push({
                        codename: permissionCode,
                        permission_type: permissionCode.startsWith('module') ? 'ui' : 'api',
                        content_type: getContentTypeByModel('user'),
                        object_pk: currentTargetId,
                    })
                })

                return result
            }, [] as Permission[])
        },
        [availablePermissions.account, getContentTypeByModel]
    )

    const allPermissions = useMemo(
        () => mapAccountPermissions(targetId),
        [mapAccountPermissions, targetId]
    )

    const hasAllPermissions = useMemo(() => {
        const matchingPermissions = allPermissions.filter((available) => {
            return (
                permissions.findIndex(
                    (accountPermission) => available.codename === accountPermission.codename
                ) > -1
            )
        })
        return allPermissions.length === matchingPermissions.length
    }, [allPermissions, permissions])

    function checkHasPermission(codename: string, id: number | undefined) {
        return permissions.some((permission) => {
            return permission.object_pk
                ? Number(permission.object_pk) === id && permission.codename === codename
                : permission.codename === codename
        })
    }

    function toggleAdminPermissions(checked: boolean) {
        const permissions = syncModulePermissions(allPermissions)
        return checked ? onUpdatePermissions(permissions) : onUpdatePermissions([])
    }

    function syncModulePermissions(permissions: Permission[]) {
        const updatedPermissions = [...permissions]
        // console.log('syncModulePermissions', updatedPermissions, availableModulePermissions)

        // availableModulePermissions.forEach((module) => {
        //     const moduleExisted = permissions.some((permission) =>
        //         permission.codename.includes(`module.${module}`)
        //     )

        //     const permissionExisted = permissions.some((permission) => {
        //         return permission.codename.includes(`.${module}.`)
        //     })

        //     if (moduleExisted && !permissionExisted) {
        //         updatedPermissions = updatedPermissions.filter((permission) => {
        //             return !permission.codename.includes(`module.${module}`)
        //         })
        //     }

        //     if (!moduleExisted && permissionExisted) {
        //         updatedPermissions.push({
        //             codename: `module.${module}`,
        //             permission_type: 'ui',
        //         })
        //     }
        // })

        return updatedPermissions
    }

    function updatePermissions({
        codenames,
        value,
        objectPk = targetId,
        contentModel = permissionLevel,
    }: {
        codenames: string[]
        value: boolean
        objectPk?: number
        contentModel?: string
    }) {
        const contentType = getContentTypeByModel(contentModel)

        onUpdatePermissions((prev) => {
            if (value) {
                const newPermissions = codenames.map((codename) => ({
                    codename,
                    permission_type: codename.startsWith('module') ? 'ui' : 'api' as 'api' | 'ui',
                    content_type: contentType,
                    object_pk: objectPk,
                }))

                return syncModulePermissions([...prev, ...newPermissions])
            }

            const updatedPermissions = prev.filter((permission) => {
                return !codenames.includes(permission.codename)
            })

            return syncModulePermissions(updatedPermissions)
        })
    }

    return {
        availablePermissions,
        hasAllPermissions,
        checkHasPermission,
        toggleAdminPermissions,
        updatePermissions,
    }
}
