type User = {
    last_name: string | null
    first_name: string | null
    username: string | null
    email: string
}

export function filterUsers<T extends User>(users: T[], searchTerm: string) {
    const text = searchTerm.toLowerCase()

    return text
        ? users.filter((u) => {
              return (
                  u.first_name?.toLowerCase().includes(text) ||
                  u.last_name?.toLowerCase().includes(text) ||
                  u.username?.toLowerCase().includes(text) ||
                  u.email.toLowerCase().includes(text)
              )
          })
        : users
}
