import { type ChannelValidator } from '~/types'

export const FIELD_INFO = {
    name: {
        minLength: 2,
        maxLength: 255,
    },
    description: {
        minLength: 0,
        maxLength: 25000,
    },
    optionName: {
        maxLength: 20,
    },
    maxImages: 8,
    maxImagesLevel: 'product_or_stock_unit',
} as const

export const FIELD_INFO_VN = {
    name: {
        minLength: 10,
        maxLength: 255,
    },
    description: {
        minLength: 0,
        maxLength: 25000,
    },
    optionName: {
        maxLength: 20,
    },
    maxImages: 8,
    maxImagesLevel: 'product_or_stock_unit',
} as const

export function createChannelValidator(): ChannelValidator {
    const FLEXI_COMBO_CATEGORY_ID = 14451

    return {
        validateChannelStockUnitPrice(value, { category }) {
            if (!category) {
                return gettext('Category required for validation')
            }

            const price = parseFloat(value)
            if (category.category_id === FLEXI_COMBO_CATEGORY_ID) {
                return price === 0 || gettext('Price must be 0')
            }

            return price > 0 || gettext('Price required')
        },
    }
}
