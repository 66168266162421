import { type MouseEvent } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

type Props = {
    open: boolean
    onConfirm: (e: MouseEvent) => void
    onClose: () => void
}

export default function SaveInactiveProductDialog({ open, onConfirm, onClose }: Props) {
    return (
        <Dialog fullWidth open={open} maxWidth="xs">
            <DialogTitle>{gettext('Save confirmation')}</DialogTitle>
            <DialogContent>
                {gettext(
                    'Saved changes will not appear in your channels because the product is inactive'
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{gettext('Cancel')}</Button>
                <Button color="secondary" variant="contained" onClick={onConfirm}>
                    {gettext('Continue')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
