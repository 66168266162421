import { z } from 'zod'

export const Store = z.object({
    id: z.number(),
    name: z.string(),
    owner: z.number(),
    sync_mode: z.string(),
    has_required_merges: z.boolean(),
})
export type Store = z.infer<typeof Store>
