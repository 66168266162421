import { memo, useCallback, useMemo, useState } from 'react'
import { useFormState, useWatch } from 'react-hook-form'
import { TableVirtuoso, type TableComponents } from 'react-virtuoso'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import FulfillmentOptionsRow from './fulfillment-options-row'

import { MAX_VIRTUALIZED_HEIGHT } from '~/constants'
import { type StockUnitFormSchema, useProductFormContext } from '~/products/hooks/useProductForm'
import { useProductPage } from '~/products/hooks/useProductPage'
import { useVirtualizationContainer } from '~/products/hooks/useVirtualizationContainer'
import { computeStockUnitItemKey } from '~/products/utils/stock-unit'

const useStyles = makeStyles((theme) => ({
    fulfillmentOptionsTitle: {
        display: 'flex',
    },
    fulfillmentOptionsError: {
        color: theme.palette.error.main,
        marginLeft: 10,
    },
    fulfillmentOptionsDetails: {
        flexDirection: 'column',
    },
    fulfillmentOptionsLegend: {
        display: 'flex',
        alignItems: 'center',
    },
    fulfillmentOptionsText: {
        fontSize: '0.9rem',
        color: theme.palette.grey[600],
    },
    fulfillmentOptionsLegendColor: {
        backgroundColor: theme.palette.primary.main,
        width: 15,
        height: 15,
        marginRight: 5,
    },
    headerRow: {
        backgroundColor: theme.palette.background.paper,
    },
    headerTitleCell: {
        textAlign: 'center',
        whiteSpace: 'nowrap',
        borderBottom: 'none',
    },
    regularColumn: {
        whiteSpace: 'nowrap',
        padding: '5px 10px',
        borderBottom: 'none',
    },
}))

const BORDER_SPACING = 4

const components: TableComponents<StockUnitFormSchema> = {
    Scroller: TableContainer,
    Table: (props) => (
        <Table
            {...props}
            style={{ borderSpacing: BORDER_SPACING, borderCollapse: 'separate', padding: '0.5rem' }}
        />
    ),
    TableRow,
    TableHead,
}

export default function FulfillmentOptions() {
    const classes = useStyles()
    const { productInfo } = useProductPage()
    const { locations } = productInfo

    const { getValues, setValue, trigger, control } = useProductFormContext()
    const stockUnits = useWatch({ control, name: 'stock_units' })

    const [isExpanded, setIsExpanded] = useState(false)
    const [containerHeight, setContainerHeight] = useState(0)
    const horizontalSpacing = BORDER_SPACING * 2
    const stockUnitSpacing = stockUnits.length * BORDER_SPACING
    const ref = useVirtualizationContainer('fulfillment_option')

    const renderHeaderContent = useCallback(
        () => (
            <TableRow className={classes.headerRow}>
                <TableCell className={classes.regularColumn} />
                <TableHeadCell />
            </TableRow>
        ),
        [classes.headerRow, classes.regularColumn]
    )

    return (
        <div>
            <Accordion expanded={isExpanded} onChange={(_, expanded) => setIsExpanded(expanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FulfillmentSummary />
                </AccordionSummary>

                <AccordionDetails className={classes.fulfillmentOptionsDetails}>
                    <div className={classes.fulfillmentOptionsLegend}>
                        <div className={classes.fulfillmentOptionsLegendColor} />
                        <Typography className={classes.fulfillmentOptionsText}>
                            {gettext('Item can be fulfilled from this location')}
                        </Typography>
                    </div>

                    <TableVirtuoso
                        ref={ref}
                        data={stockUnits}
                        components={components}
                        style={{
                            height: containerHeight + horizontalSpacing + stockUnitSpacing,
                            maxHeight: MAX_VIRTUALIZED_HEIGHT,
                        }}
                        fixedHeaderContent={renderHeaderContent}
                        totalListHeightChanged={setContainerHeight}
                        computeItemKey={computeStockUnitItemKey}
                        itemContent={(index) => <FulfillmentOptionsRow index={index} />}
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const TableHeadCell = memo(function TableHeadCell() {
    const classes = useStyles()
    const { productInfo } = useProductPage()

    return (
        <>
            {productInfo.locations.map((location) => (
                <TableCell key={location.id} className={classes.headerTitleCell}>
                    <Typography>{location.name}</Typography>
                </TableCell>
            ))}
        </>
    )
})

function FulfillmentSummary() {
    const classes = useStyles()
    const { control } = useProductFormContext()
    const { errors } = useFormState({ control, name: 'stock_units' })

    const fulfillmentErrorMessage = useMemo(() => {
        let message = ''

        errors.stock_units?.forEach?.((su) => {
            if (message) {
                return
            }

            if (su?.fulfillment_locations?.root?.type === 'incompleteFulfillment') {
                message = su.fulfillment_locations.root.message ?? ''
            }
        })

        return message
    }, [errors.stock_units])

    return (
        <div className={classes.fulfillmentOptionsTitle}>
            <Typography>{gettext('Fulfillment options')}</Typography>
            {fulfillmentErrorMessage && (
                <Typography className={classes.fulfillmentOptionsError}>
                    {gettext('You must select at least one fulfillment location')}
                </Typography>
            )}
        </div>
    )
}
