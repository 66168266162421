import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
    tableCell: {
        paddingTop: '0.5rem',
        paddingBottom: '1rem',
    },
    tableCellContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
    },
    checkContainer: {
        display: 'flex',
        alignItems: 'end',
        flexDirection: 'column',
    },
}))
