import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import moment from 'moment'

import { getPath } from '../../oakra/components/base-app'
import DateSelectButton from '../../oakra/components/date-select-button'
import {
    getTotalRevenue,
    getTotalNumSales,
    getOverallAOV,
    getCurrentPeriodData,
    getPreviousPeriodData,
    daysInRange,
    getChangePercent,
    abbreviateNumber,
} from '../../tools/analytics'
import { getTranslation } from '../../tools/utils'
import { SummaryChartCard, StatsTableCard } from '../containers/dashboard-components'

// Dashboard

const styles = (theme) => ({
    container: {
        padding: 15,
    },
    title: {
        fontSize: '1.5rem',
        marginBottom: 15,
    },
    controls: {
        marginBottom: 20,
    },
    row: {
        display: 'flex',
        marginBottom: 15,
    },
})

class Dashboard extends React.PureComponent {
    constructor(props) {
        super(props)

        moment.locale(props.language)

        const summaryEndDate = moment().subtract(1, 'days')
        const summaryStartDate = moment().subtract(7, 'days')

        this.state = {
            summaryStartDate: summaryStartDate,
            summaryEndDate: summaryEndDate,
            summaryData: [],
            previousPeriodSummaryData: [],
            revenueByShopData: [],
            revenueByChannelData: [],
            revenueByProductData: [],
        }

        this.fetchSummaryData = this.fetchSummaryData.bind(this)
        this.fetchRevenueByShopData = this.fetchRevenueByShopData.bind(this)
        this.fetchRevenueByChannelData = this.fetchRevenueByChannelData.bind(this)
        this.fetchRevenueByProductData = this.fetchRevenueByProductData.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
    }

    componentDidMount() {
        this.fetchSummaryData()
        this.fetchRevenueByShopData()
        this.fetchRevenueByChannelData()
        this.fetchRevenueByProductData()
    }

    fetchSummaryData() {
        const { analyticsTimeSeriesChartAPIURL, accountOwnerID } = this.props
        const days = daysInRange(this.state.summaryStartDate, this.state.summaryEndDate)

        const startDate = this.state.summaryStartDate
            .clone()
            .subtract(days, 'days')
            .format('Y-MM-DD')
        const endDate = this.state.summaryEndDate.clone().format('Y-MM-DD')
        const urlWithParam = `${analyticsTimeSeriesChartAPIURL}?entity=account&entity_id=${accountOwnerID}&start_date=${startDate}&end_date=${endDate}`

        fetch(urlWithParam)
            .then((response) => response.json())
            .then((data) => {
                // setTimeout(() => {
                this.setState({
                    summaryData: getCurrentPeriodData(
                        this.state.summaryStartDate,
                        this.state.summaryEndDate,
                        data
                    ),
                    previousPeriodSummaryData: getPreviousPeriodData(
                        this.state.summaryStartDate,
                        this.state.summaryEndDate,
                        data,
                        true
                    ),
                })
                // }, 5000);
            })
    }

    fetchRevenueByShopData() {
        const { shopsRevenueSummaryAPIURL } = this.props

        const startDate = this.state.summaryStartDate.startOf('day').format('Y-MM-DDTHH:mm:ssZ')
        const endDate = this.state.summaryEndDate.startOf('day').format('Y-MM-DDTHH:mm:ssZ')
        const urlWithParam = `${shopsRevenueSummaryAPIURL}?start_date=${startDate}&end_date=${endDate}`

        fetch(urlWithParam)
            .then((response) => response.json())
            .then((revenueByShopData) => {
                // setTimeout(() => {
                this.setState({ revenueByShopData })
                // }, 3000);
            })
    }

    fetchRevenueByChannelData() {
        const { channelsRevenueSummaryAPIURL } = this.props

        const startDate = this.state.summaryStartDate.startOf('day').format('Y-MM-DDTHH:mm:ssZ')
        const endDate = this.state.summaryEndDate.startOf('day').format('Y-MM-DDTHH:mm:ssZ')
        const urlWithParam = `${channelsRevenueSummaryAPIURL}?start_date=${startDate}&end_date=${endDate}`

        fetch(urlWithParam)
            .then((response) => response.json())
            .then((revenueByChannelData) => {
                // setTimeout(() => {
                this.setState({ revenueByChannelData })
                // }, 3000);
            })
    }

    fetchRevenueByProductData() {
        const { productsRevenueSummaryAPIURL } = this.props

        const startDate = this.state.summaryStartDate.startOf('day').format('Y-MM-DDTHH:mm:ssZ')
        const endDate = this.state.summaryEndDate.startOf('day').format('Y-MM-DDTHH:mm:ssZ')
        const urlWithParam = `${productsRevenueSummaryAPIURL}?start_date=${startDate}&end_date=${endDate}`

        fetch(urlWithParam)
            .then((response) => response.json())
            .then((revenueByProductData) => {
                // setTimeout(() => {
                this.setState({ revenueByProductData })
                // }, 3000);
            })
    }

    handleDateChange(startDate, endDate) {
        this.setState(
            {
                summaryStartDate: startDate,
                summaryEndDate: endDate,
                summaryData: [],
                revenueByShopData: [],
                revenueByChannelData: [],
                revenueByProductData: [],
            },
            () => {
                this.fetchSummaryData()
                this.fetchRevenueByShopData()
                this.fetchRevenueByChannelData()
                this.fetchRevenueByProductData()
            }
        )
    }

    render() {
        const { classes, language } = this.props

        const totalRevenueData = this.state.summaryData.map((d) => ({
            x: moment(d.date).toDate(),
            y: parseFloat(d.revenue),
            label: abbreviateNumber(parseFloat(d.revenue), 1),
        }))

        const totalOrdersData = this.state.summaryData.map((d) => ({
            x: moment(d.date).toDate(),
            y: parseFloat(d.num_sales),
            label: abbreviateNumber(parseFloat(d.num_sales), 1),
        }))

        const AOVData = this.state.summaryData.map((d) => {
            const aov = d.num_sales > 0 ? parseFloat(d.revenue) / d.num_sales : 0
            const roundedAOV = Math.round(aov * 100) / 100

            return {
                x: moment(d.date).toDate(),
                y: roundedAOV,
                label: abbreviateNumber(roundedAOV, 1),
            }
        })

        const previousTotalRevenueData = this.state.previousPeriodSummaryData.map((d) => ({
            x: moment(d.date).toDate(),
            y: parseFloat(d.revenue),
            label: abbreviateNumber(parseFloat(d.revenue), 1),
        }))

        const previousTotalOrdersData = this.state.previousPeriodSummaryData.map((d) => ({
            x: moment(d.date).toDate(),
            y: parseFloat(d.num_sales),
            label: abbreviateNumber(parseFloat(d.num_sales), 1),
        }))

        const previousAOVData = this.state.previousPeriodSummaryData.map((d) => {
            const aov = d.num_sales > 0 ? parseFloat(d.revenue) / d.num_sales : 0
            const roundedAOV = Math.round(aov * 100) / 100

            return {
                x: moment(d.date).toDate(),
                y: roundedAOV,
                label: abbreviateNumber(roundedAOV, 1),
            }
        })

        const revenueByShopData = this.state.revenueByShopData.map((d) => ({
            label: d.shop.shop_name,
            value: d.total_revenue,
            iconURL: d.shop.channel.channel_square_icon_url,
        }))

        const revenueByChannelData = this.state.revenueByChannelData.map((d) => ({
            label: d.channel.name,
            value: d.total_revenue,
            iconURL: d.channel.channel_square_icon_url,
        }))

        const revenueByProductData = this.state.revenueByProductData.map((d) => ({
            label: getTranslation(d.product, language, 'name'),
            value: d.total_revenue,
        }))

        const daysInPeriod = daysInRange(this.state.summaryStartDate, this.state.summaryEndDate)

        return (
            <div className={classes.container}>
                <Typography className={classes.title}>{gettext('Overview')}</Typography>
                <div className={classes.controls}>
                    <DateSelectButton
                        startDate={this.state.summaryStartDate}
                        endDate={this.state.summaryEndDate}
                        handleDateChange={this.handleDateChange}
                    />
                </div>
                <div className={classes.row}>
                    <SummaryChartCard
                        title={gettext('Total revenue')}
                        statTotal={`฿${Math.round(
                            getTotalRevenue(this.state.summaryData)
                        ).toLocaleString()}`}
                        change={getChangePercent(
                            getTotalRevenue(this.state.summaryData),
                            getTotalRevenue(this.state.previousPeriodSummaryData)
                        )}
                        data={totalRevenueData}
                        previousPeriodData={previousTotalRevenueData}
                        reportLink={`${getPath('dashboardReports')}?data_type=revenue`}
                        daysInPeriod={daysInPeriod}
                    />
                    <SummaryChartCard
                        title={gettext('Total orders')}
                        statTotal={getTotalNumSales(this.state.summaryData).toLocaleString()}
                        change={getChangePercent(
                            getTotalNumSales(this.state.summaryData),
                            getTotalNumSales(this.state.previousPeriodSummaryData)
                        )}
                        data={totalOrdersData}
                        previousPeriodData={previousTotalOrdersData}
                        reportLink={`${getPath('dashboardReports')}?data_type=num_sales`}
                        daysInPeriod={daysInPeriod}
                    />
                    <SummaryChartCard
                        title={gettext('Average order value')}
                        statTotal={`฿${getOverallAOV(this.state.summaryData).toLocaleString()}`}
                        change={getChangePercent(
                            getOverallAOV(this.state.summaryData),
                            getOverallAOV(this.state.previousPeriodSummaryData)
                        )}
                        data={AOVData}
                        previousPeriodData={previousAOVData}
                        reportLink={`${getPath('dashboardReports')}?data_type=aov`}
                        daysInPeriod={daysInPeriod}
                    />
                </div>
                <div className={classes.row}>
                    <StatsTableCard
                        cardTitle={gettext('Revenue by shop')}
                        labelTitle={gettext('Shop')}
                        valueTitle={interpolate(gettext('Revenue (%s)'), ['฿'])}
                        data={revenueByShopData}
                        reportLink={`${getPath('dashboardReports')}?group_by=shop`}
                    />
                    <StatsTableCard
                        cardTitle={gettext('Revenue by channel')}
                        labelTitle={gettext('Channel')}
                        valueTitle={interpolate(gettext('Revenue (%s)'), ['฿'])}
                        data={revenueByChannelData}
                        reportLink={`${getPath('dashboardReports')}?group_by=channel`}
                    />
                    <StatsTableCard
                        cardTitle={gettext('Top selling products')}
                        labelTitle={gettext('Product')}
                        valueTitle={interpolate(gettext('Revenue (%s)'), ['฿'])}
                        data={revenueByProductData}
                        reportLink={`${getPath('dashboardReports')}?group_by=product`}
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Dashboard)
