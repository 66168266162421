import { memo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    head: {
        '& .MuiTableCell-root': {
            width: 0,
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
        },
    },
}))

function StockUnitTableHead({ headers, children }) {
    const classes = useStyles()

    return (
        <TableHead className={classes.head}>
            <TableRow>
                {headers.map((header) => (
                    <TableCell key={header}>{header}</TableCell>
                ))}
                {children}
            </TableRow>
        </TableHead>
    )
}

StockUnitTableHead.propTypes = {
    headers: PropTypes.array.isRequired,
    children: PropTypes.node,
}

export default memo(StockUnitTableHead)
