import { connect } from 'react-redux'

import CustomerEditor from '../components/customer-editor'

const mapStateToProps = ({ initial: { endpoints, user } }) => ({
    customersAPIURL: endpoints.customersAPIURL.replace('user_pk', user.id),
    customerAPIURL: endpoints.customerAPIURL,
})

export default connect(mapStateToProps)(CustomerEditor)
