// Sale Customer Editor

import React from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { get } from '../../tools/request'
import CustomerEditor from '../containers/customer-editor'

import AddressManager from './address-manager'
import CustomerInfo from './cutomer-info'

const styles = (theme) => ({
    dialog: {},
    dialogContent: {
        minHeight: 330,
    },
    dialogActions: {
        paddingRight: 24,
        paddingLeft: 24,
        paddingBottom: 15,
        marginTop: 0,
    },

    customer: {
        padding: '2px 2px 18px 2px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        marginBottom: 12,
        display: 'flex',
    },
    addresses: {},
    address: {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 8,
        padding: 6,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },

    actionBar: {
        margin: '-8px 0 0 12px',
    },
    title: {
        fontWeight: 500,
    },
    selectAddress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    loadingIndicator: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 250,
    },
})

class CustomerSummary extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showEditorDialog: false,
            selectingAddress: null,
            data: null,
            loading: true,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        const { customerAPIURL, customerId } = this.props

        this.setState({ loading: true })

        get(customerAPIURL.replace('customer_pk', customerId), {
            expand: 'default_shipping_address,default_shipping_address,default_shipping_address,addresses',
        }).then((res) => {
            if (res.ok) {
                this.setState({ data: res })
            }
            this.setState({ loading: false })
        })
    }

    handleClose = () => {
        this.props.onClose(this.state.data)
    }

    handleEditorDialogClose = (data) => {
        this.setState({
            showEditorDialog: false,
        })

        if (data) {
            this.fetchData()
        }
    }

    handleAddressChange = (data) => {
        this.fetchData()
    }

    render() {
        const { showEditorDialog, data, loading } = this.state
        const { classes, mode } = this.props

        return (
            <Dialog
                open
                fullWidth
                maxWidth="md"
                className={classes.dialog}
                onClose={this.handleClose}
            >
                <DialogTitle>{gettext('Customer info')}</DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {loading && !data ? (
                        <div className={classes.loadingIndicator}>
                            <CircularProgress size={25} />
                        </div>
                    ) : (
                        <div>
                            <div className={classes.customer}>
                                <CustomerInfo data={data} />

                                <div className={classes.actionBar}>
                                    <Button
                                        color="secondary"
                                        onClick={() => this.setState({ showEditorDialog: true })}
                                    >
                                        {gettext('Edit')}
                                    </Button>
                                </div>

                                {showEditorDialog && (
                                    <CustomerEditor
                                        inputData={data}
                                        mode="update_customer"
                                        onClose={this.handleEditorDialogClose}
                                    />
                                )}
                            </div>

                            <Typography variant="subtitle1" style={{ marginBottom: 12 }}>
                                {gettext("Customer's addresses")}
                            </Typography>

                            <div className={classes.addresses}>
                                <AddressManager
                                    customerId={data.id}
                                    data={data.addresses}
                                    showDefaultLabel
                                    onChange={this.handleAddressChange}
                                />
                            </div>
                        </div>
                    )}
                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                    <Button variant="contained" color="secondary" onClick={this.handleClose}>
                        {gettext('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

CustomerSummary = withStyles(styles)(CustomerSummary)

export default CustomerSummary
