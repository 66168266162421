import React from 'react'

import Button from '@material-ui/core/Button'
import grey from '@material-ui/core/colors/grey'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'

import classNames from 'classnames'
import Cookie from 'js-cookie'

import { getPath } from './base-app'

const styles = {
    createNewStore: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 48px)',
    },
    createNewStoreCard: {
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 40,
        paddingRight: 40,
        width: 500,
        display: 'flex',
        flexDirection: 'column',
    },
    createNewStoreMessage: {
        marginBottom: 20,
    },
    createNewStoreSubMessage: {
        marginBottom: 10,
    },
    addStoreNameRow: {
        display: 'flex',
    },
    addStoreName: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
    },
    storeName: {
        flex: 1,
    },
    addStoreButton: {
        height: 56,
        marginLeft: 10,
    },
    stores: {
        marginTop: 30,
    },
    store: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: '1px solid ' + grey[300],
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    storeName: {
        flex: '0 0 300px',
    },
    storeNameText: {
        fontSize: '1rem',
    },
    actions: {
        flexBasis: 50,
    },
    continueButton: {
        color: 'white',
        width: 250,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 20,
    },
}

class CreateNewStore extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            newStoreName: '',
            newStoreNameError: '',
        }

        this.handleNewStoreNameChange = this.handleNewStoreNameChange.bind(this)
        this.handleAddNewStore = this.handleAddNewStore.bind(this)
        this.handleDeleteStore = this.handleDeleteStore.bind(this)
        this.handlePressEnter = this.handlePressEnter.bind(this)
        this.handleContinue = this.handleContinue.bind(this)
    }

    handleNewStoreNameChange(event) {
        this.setState({
            newStoreName: event.target.value,
        })
    }

    handleAddNewStore() {
        const { accountOwnerID, storeCreateAPIURL, addStore } = this.props

        if (!this.state.newStoreName || this.state.newStoreName.length < 5) {
            this.setState({
                newStoreNameError: gettext('Store name must be more than 5 characters.'),
            })
            return
        }

        const newStoreData = {
            owner: accountOwnerID,
            name: this.state.newStoreName,
            sync_mode: 'receiving',
        }

        fetch(storeCreateAPIURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRFToken': Cookie.get('csrftoken'),
            },
            body: JSON.stringify(newStoreData),
        })
            .then((response) => response.json())
            .then((store) => {
                this.setState(
                    {
                        newStoreName: null,
                        newStoreNameError: null,
                    },
                    () => {
                        addStore(store)
                    }
                )
            })
    }

    handleDeleteStore(store) {
        return (event) => {
            const { storeAPIURL, deleteStore, stores } = this.props
            deleteStore(store.id)

            fetch(storeAPIURL.replace('store_id', store.id), {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRFToken': Cookie.get('csrftoken'),
                },
            }).then(() => {})
        }
    }

    handlePressEnter(event) {
        if (event.key === 'Enter') {
            this.handleAddNewStore()
            event.preventDefault()
        }
    }

    handleContinue() {
        window.location.href = getPath('integrations')
    }

    render() {
        const { classes, stores } = this.props

        return (
            <div className={classes.createNewStore}>
                <Paper className={classes.createNewStoreCard}>
                    <Typography className={classes.createNewStoreMessage} variant="h5">
                        {gettext('Start by adding your stores')}
                    </Typography>
                    <Typography className={classes.createNewStoreSubMessage}>
                        {gettext(
                            'A store is defined by one type of product. For example, you may have a store selling the same beauty products on both Lazada and Shopee. In Oakra, this is considered one store and you only need to add it once.'
                        )}
                    </Typography>
                    <Typography className={classes.createNewStoreSubMessage}>
                        {gettext(
                            'Or you may have one Shopee store selling beauty products and another one selling clothes. These are considered two different stores in Oakra. Please add them separately.'
                        )}
                    </Typography>
                    <div className={classes.addStoreName}>
                        <div className={classes.addStoreNameRow}>
                            <TextField
                                className={classNames(classes.field, classes.storeName)}
                                variant="outlined"
                                label={gettext('Store name')}
                                value={this.state.newStoreName || ''}
                                onChange={this.handleNewStoreNameChange}
                                onKeyPress={this.handlePressEnter}
                                error={Boolean(this.state.newStoreNameError)}
                                helperText={this.state.newStoreNameError}
                                inputProps={{ maxLength: 50 }}
                            />
                            <Button
                                color="primary"
                                className={classes.addStoreButton}
                                onClick={this.handleAddNewStore}
                            >
                                {gettext('Add')}
                            </Button>
                        </div>
                    </div>

                    {stores.length > 0 && (
                        <div className={classes.stores}>
                            <div className={classes.store} key="header">
                                <div className={classes.storeName}>
                                    <Typography className={classes.headerText} variant="subtitle2">
                                        {gettext('Shop')}
                                    </Typography>
                                </div>
                                <div className={classes.actions}></div>
                            </div>
                            {stores.map((store) => (
                                <div className={classes.store} key={store.id}>
                                    <div className={classes.storeName}>
                                        <Typography className={classes.storeNameText}>
                                            {store.name}
                                        </Typography>
                                    </div>
                                    <div className={classes.actions}>
                                        <IconButton
                                            color="secondary"
                                            onClick={this.handleDeleteStore(store)}
                                        >
                                            <DeleteIcon className={classes.rightIcon} />
                                        </IconButton>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {stores.length > 0 && (
                        <Button
                            className={classes.continueButton}
                            variant="contained"
                            color="primary"
                            onClick={this.handleContinue}
                        >
                            {gettext('Continue')}
                        </Button>
                    )}
                </Paper>
            </div>
        )
    }
}

export default withStyles(styles)(CreateNewStore)
