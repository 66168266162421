import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PhoneIcon from '@material-ui/icons/Phone'

const addressInfoStyles = (theme) => ({
    container: {},
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: 5,
    },
    fieldValue: {
        fontSize: '0.875rem',
        color: theme.palette.grey[700],
    },
})

let AddressInfo = ({ classes, data }) => {
    const fieldValues = [
        data.contact_name,
        data.address1,
        data.address2,
        `${data.district || ''} ${data.city || ''} ${data.province || ''}`,
        `${data.country || ''} ${data.post_code || ''}`,
    ]
        .map((text) => (text || '').trim())
        .filter(Boolean)

    const { phone } = data

    return (
        <div className={classes.container}>
            {fieldValues.map((fieldValue, i) => (
                <div key={i} className={classes.row}>
                    <Typography className={classes.fieldValue}>{fieldValue}</Typography>
                </div>
            ))}
            {phone && (
                <div className={classes.row}>
                    <PhoneIcon fontSize="small" className={classes.icon} />
                    <Typography className={classes.fieldValue}>{phone.trim()}</Typography>
                </div>
            )}
        </div>
    )
}

export default withStyles(addressInfoStyles)(AddressInfo)
