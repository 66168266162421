import React from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { withStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'

import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'

import ChannelIcons from '../../products/components/channel-icons'
import { debounce, getTranslation, copyObject } from '../../tools/utils'

import DiscountSummaryList from './discount-summary'

const styles = () => ({
    topBar: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid ' + grey[200],
        padding: '10px 20px',
        flex: '0 0 auto',
    },
    progress: {
        width: '100%',
        height: 4,
    },
    title: {
        marginRight: 20,
        marginTop: 8,
    },
    searchBar: {
        width: 325,
        height: 38,
    },
    table: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
    },
    tableHeader: {
        borderBottom: '1px solid ' + grey[200],
    },
    tableHeaderNameCell: {
        paddingTop: 14,
        paddingLeft: 8,
    },
    tableRow: {
        display: 'flex',
        '&:hover': {
            backgroundColor: grey[200],
        },
        '&:last-child': {
            borderBottom: 'none',
        },
        paddingTop: 5,
        paddingBottom: 5,
        outline: 'none',
        borderBottom: '1px solid ' + grey[200],
        cursor: 'pointer',
    },
    tableRowSpan: {
        width: '100%',
    },
    tableCell: {},
    tableWrapper: {
        overflowX: 'auto',
    },
    tableBody: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
    },
    noProductsMessage: {
        width: '100%',
        textAlign: 'center',
        marginTop: 50,
    },
    noProductsIcon: {
        width: 75,
    },
    noProductsText: {
        color: grey[600],
    },
    checkboxColumnCell: {
        paddingLeft: 10,
        width: 70,
        flex: '0 0 auto',
    },
    imageColumnCell: {
        width: 75,
        flex: '0 0 auto',
    },
    nameColumnCell: {
        paddingRight: 20,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    nameColumnName: {
        flexGrow: 1,
        paddingLeft: 8,
    },
    samePeriodDiscountInfo: {
        width: '25%',
        textAlign: 'right',
    },
    samePeriodDiscountsList: {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        color: grey[500],
    },
    bottomBar: {
        borderTop: '1px solid ' + grey[200],
        alignItems: 'center',
        justifyContent: 'flex-end',
        display: 'flex',
        padding: '10px 20px',
        flex: '0 0 auto',
    },
    button: {
        marginLeft: 15,
    },
    addButton: {
        color: 'white',
    },
    spacer: {
        flex: '1 1 auto',
    },
    copyFromContent: {
        textAlign: 'center',
    },
    copyFromList: {
        maxHeight: 310,
    },
    notAvailable: {
        color: grey[400],
    },
    notAvailableImage: {
        opacity: 0.4,
    },
    hasDiscountBlock: {
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        textTransform: 'none',
    },
    extraInfo: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
    },
})

class StyledProductsTableHead extends React.PureComponent {
    render() {
        const { onSelectAllClick, numSelected, rowCount, classes, excludedProductsCount } =
            this.props

        return (
            <div className={classes.tableHeader}>
                <div className={classes.tableRow}>
                    <div
                        className={classNames(classes.tableCell, classes.checkboxColumnCell)}
                        width={70}
                    >
                        <Checkbox
                            indeterminate={
                                numSelected > 0 && numSelected < rowCount - excludedProductsCount
                            }
                            checked={
                                rowCount !== 0 && numSelected === rowCount - excludedProductsCount
                            }
                            onChange={onSelectAllClick}
                        />
                    </div>
                    <div
                        className={classNames(classes.tableCell, classes.imageColumnCell)}
                        width={100}
                    ></div>
                    <div
                        className={classNames(
                            classes.tableCell,
                            classes.nameColumnCell,
                            classes.tableHeaderNameCell
                        )}
                    >
                        <Typography variant="body2">{gettext('Name')}</Typography>
                    </div>
                </div>
            </div>
        )
    }
}

StyledProductsTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const ProductsTableHead = withStyles(styles)(StyledProductsTableHead)

class ProductSelector extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            orderBy: '-date_created',
            selected: [],
            rowsPerPage: 25,
            products: [],
            count: 0,
            page: 0,
            maxPageFetched: 0,
            loading: false,
            showCopyFromDialog: false,
            copyFromDiscounts: [],
            loadingCopyFromProducts: false,
            displayProductDiscounts: [],
            showProductDiscountsDialog: false,
        }

        this.fetchProductData = this.fetchProductData.bind(this)
        this.fetchDiscountsData = this.fetchDiscountsData.bind(this)
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this)
        this.handleChecked = this.handleChecked.bind(this)
        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.isSelected = this.isSelected.bind(this)
        this.searchHandler = this.searchHandler.bind(this)
        this.handleOpenCopyFromDialog = this.handleOpenCopyFromDialog.bind(this)
        this.handleCancelCopyFromDialog = this.handleCancelCopyFromDialog.bind(this)
        this.handleCopyFromDiscount = this.handleCopyFromDiscount.bind(this)

        this.fetchProductDataDebounced = debounce(this.fetchProductData, 500)

        this.copyFromScrollRef = React.createRef()
    }

    componentDidMount() {
        const { productsForStoreSummaryAPIURL, startDate, endDate } = this.props

        if (startDate && endDate) {
            this.fetchProductData(productsForStoreSummaryAPIURL, true)
        }

        this.fetchDiscountsData()
    }

    componentDidUpdate(prevProps) {
        const { productsForStoreSummaryAPIURL, startDate, endDate } = this.props

        if (prevProps.startDate && prevProps.endDate && startDate && endDate) {
            if (
                (!moment(startDate).isSame(moment(prevProps.startDate)) ||
                    !moment(endDate).isSame(moment(prevProps.endDate))) &&
                !this.state.loading
            ) {
                this.fetchProductData(productsForStoreSummaryAPIURL, true)
            }
        }
    }

    async fetchProductData(url, replace, page = null, searchTerm = null) {
        const { storeID, startDate, endDate } = this.props

        if (!url.includes('order_by')) {
            url += '?order_by=' + this.state.orderBy
        }

        if (searchTerm) {
            url += '&search=' + searchTerm
        }

        url += '&status=active'

        url += '&discount_exclusion_start_date=' + encodeURIComponent(startDate)
        url += '&discount_exclusion_end_date=' + encodeURIComponent(endDate)

        this.setState({ loading: true })

        const response = await fetch(url.replace('store_pk', storeID))
        const data = await response.json()
        let products
        if (replace) {
            products = data.results
        } else {
            products = this.state.products.concat(data.results)
        }
        const newState = {
            count: data.count,
            next: data.next,
            previous: data.previous,
            products: products,
            loading: false,
        }
        if (page === 0) {
            newState['page'] = 0
            newState['maxPageFetched'] = 0
        } else if (page > 0) {
            newState['page'] = page
        }
        this.setState(newState)
    }

    fetchDiscountsData() {
        const { discountsForStoreAPIURL, storeID } = this.props

        if (this.state.copyFromLoading) return

        this.setState({ copyFromLoading: true }, async () => {
            let url = discountsForStoreAPIURL.replace('store_pk', storeID)

            url += '?order_by=-start_date'

            const response = await fetch(url)
            const data = await response.json()
            const discounts = copyObject(this.state.copyFromDiscounts).concat(data)
            const newState = {
                copyFromDiscounts: discounts,
            }
            this.setState(newState)
        })
    }

    handleSelectAllClick(_, checked) {
        if (checked) {
            const newSelectedProducts = copyObject(this.state.products).filter(
                (p) => !this.props.excludedProductIDs.has(p.id)
            )

            this.setState({ selected: newSelectedProducts })
        } else {
            this.setState({ selected: [] })
        }
    }

    handleChecked(_, checked, product) {
        const selectedProductIDs = this.state.selected.map((p) => p.id)
        let newSelected = copyObject(this.state.selected)

        if (checked) {
            if (!selectedProductIDs.includes(product.id)) {
                newSelected.push(product)
            }
        } else {
            newSelected = newSelected.filter((p) => p.id !== product.id)
        }

        this.setState({ selected: newSelected })
    }

    handleSelectRow(product) {
        const selectedProductIDs = this.state.selected.map((p) => p.id)
        let newSelected = copyObject(this.state.selected)

        if (selectedProductIDs.includes(product.id)) {
            newSelected = newSelected.filter((p) => p.id !== product.id)
        } else {
            newSelected.push(product)
        }

        this.setState({ selected: newSelected })
    }

    handleChangePage(_, page) {
        let pageChangeURL

        if (page > this.state.page) {
            pageChangeURL = this.state.next
        } else {
            pageChangeURL = this.state.previous
        }

        const needsFetch = page > this.state.maxPageFetched

        if (needsFetch) {
            this.setState(
                {
                    maxPageFetched: page,
                    loading: true,
                },
                () => {
                    this.fetchProductData(pageChangeURL, false, page)
                }
            )
        } else {
            this.setState({
                page: page,
            })
        }
    }

    isSelected(productID) {
        const productIDs = this.state.selected.map((p) => p.id)
        return productIDs.includes(productID)
    }

    searchHandler(event) {
        const { productsForStoreSummaryAPIURL } = this.props

        const searchTerm = event.target.value

        this.fetchProductDataDebounced(productsForStoreSummaryAPIURL, true, 0, searchTerm)
    }

    handleOpenCopyFromDialog() {
        this.setState({ showCopyFromDialog: true })
    }

    handleCancelCopyFromDialog() {
        this.setState({ showCopyFromDialog: false })
    }

    handleCopyFromDiscount(discountID = null) {
        const { productDiscountsAPIURL, handleSelectedProducts } = this.props
        return () => {
            if (discountID) {
                this.setState({ loadingCopyFromProducts: true }, () => {
                    fetch(productDiscountsAPIURL.replace('discount_id', discountID))
                        .then((response) => response.json())
                        .then((productDiscounts) => {
                            const products = productDiscounts.map((pd) => pd.product)

                            this.setState(
                                {
                                    loadingCopyFromProducts: false,
                                    showCopyFromDialog: false,
                                },
                                () => {
                                    handleSelectedProducts(products)
                                }
                            )
                        })
                })
            } else {
                this.setState(
                    {
                        showCopyFromDialog: false,
                    },
                    () => {
                        handleSelectedProducts([])
                    }
                )
            }
        }
    }

    handleOpenProductDiscounts = (product) => {
        return (event) => {
            this.setState({
                displayProductDiscounts: product.same_period_discounts,
                showProductDiscountsDialog: true,
            })
            event.stopPropagation()
        }
    }

    handleCloseProductDiscounts = () => {
        this.setState({ showProductDiscountsDialog: false })
    }

    onProductDiscountDialogExited = () => {
        this.setState({ displayProductDiscounts: [] })
    }

    render() {
        const {
            classes,
            display,
            handleSelectedProducts,
            excludedProductIDs,
            language,
            staticRoot,
            discountID,
        } = this.props
        const { rowsPerPage, page } = this.state

        const emptyRows =
            rowsPerPage - Math.min(rowsPerPage, this.state.products.length - page * rowsPerPage)

        //const displayedProducts = this.state.products.filter(p => !excludedProductIDs.includes(p.id)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        const displayedProducts = this.state.products.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        )
        return (
            <Dialog
                open={display}
                fullWidth={true}
                maxWidth="md"
                onClose={() => {
                    handleSelectedProducts([])
                    this.setState({ selected: [] })
                }}
            >
                <div className={classes.topBar}>
                    <Typography className={classes.title} variant="subtitle1">
                        {gettext('Add products to discount')}
                    </Typography>

                    <TextField
                        autoFocus
                        id="search"
                        type="search"
                        className={classes.searchBar}
                        margin="normal"
                        onChange={this.searchHandler}
                        variant="outlined"
                        placeholder={gettext('Search products (name, SKU, etc)')}
                        InputProps={{ style: { height: '38px' } }}
                    />
                    <div className={classes.spacer} />
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={this.handleOpenCopyFromDialog}
                    >
                        {gettext('Copy from')}
                    </Button>
                    <TablePagination
                        className={classes.topPagination}
                        component="div"
                        count={this.state.count}
                        rowsPerPage={25}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': gettext('Previous'),
                        }}
                        nextIconButtonProps={{
                            'aria-label': gettext('Next'),
                        }}
                        onPageChange={this.handleChangePage}
                        rowsPerPageOptions={[25]}
                    />
                </div>
                {this.state.loading && (
                    <LinearProgress className={classes.progress} color="secondary" />
                )}
                <div className={classes.table} aria-labelledby="tableTitle">
                    <ProductsTableHead
                        className={classes.tableHead}
                        numSelected={this.state.selected.length}
                        orderBy={this.state.orderBy}
                        onSelectAllClick={this.handleSelectAllClick}
                        rowCount={this.state.products.length}
                        excludedProductsCount={excludedProductIDs.size}
                    />
                    <div className={classes.tableBody}>
                        {!this.state.loading && this.state.products.length === 0 && (
                            <div className={classes.noProductsMessage}>
                                <img
                                    className={classes.noProductsIcon}
                                    src={staticRoot + 'web/img/product-gray.png'}
                                />
                                <Typography className={classes.noProductsText} variant="subtitle1">
                                    {gettext('No products found')}
                                </Typography>
                            </div>
                        )}
                        {displayedProducts.map((product) => {
                            const isSelected = this.isSelected(product.id)
                            const notAvailable = excludedProductIDs.has(product.id)
                            const samePeriodDiscounts = product.same_period_discounts.filter(
                                (d) => d.id !== discountID
                            )
                            const isInSamePeriodDiscount =
                                !notAvailable && samePeriodDiscounts.length > 0
                            return (
                                <div
                                    className={classes.tableRow}
                                    role="checkbox"
                                    aria-checked={isSelected}
                                    tabIndex={-1}
                                    key={product.id}
                                    selected={isSelected}
                                    onClick={() => {
                                        if (!notAvailable) {
                                            this.handleSelectRow(product)
                                        }
                                    }}
                                    title={
                                        notAvailable
                                            ? gettext('Product is already in this discount')
                                            : ''
                                    }
                                >
                                    <div
                                        className={classNames(
                                            classes.tableCell,
                                            classes.checkboxColumnCell
                                        )}
                                    >
                                        <Checkbox
                                            disabled={notAvailable}
                                            checked={isSelected}
                                            onChange={(event, checked) =>
                                                this.handleChecked(
                                                    event,
                                                    checked,
                                                    copyObject(product)
                                                )
                                            }
                                        />
                                    </div>
                                    <div
                                        className={classNames(
                                            classes.tableCell,
                                            classes.imageColumnCell
                                        )}
                                    >
                                        <img
                                            className={classNames(
                                                classes.productImg,
                                                notAvailable ? classes.notAvailableImage : ''
                                            )}
                                            style={{ width: '100%' }}
                                            src={product.cover_image_url}
                                            height={50}
                                        />
                                    </div>
                                    <div
                                        scope="row"
                                        className={classNames(
                                            classes.tableCell,
                                            classes.nameColumnCell
                                        )}
                                    >
                                        <Typography
                                            className={classNames([
                                                notAvailable ? classes.notAvailable : '',
                                                classes.nameColumnName,
                                            ])}
                                            variant="body1"
                                        >
                                            {getTranslation(product, language, 'name')}
                                        </Typography>
                                        <div className={classes.extraInfo}>
                                            <ChannelIcons
                                                channels={product.channel_products.map(
                                                    (cp) => cp.shop.channel
                                                )}
                                            />
                                            {isInSamePeriodDiscount && (
                                                <Button
                                                    className={classNames([
                                                        classes.hasDiscountBlock,
                                                    ])}
                                                    onClick={this.handleOpenProductDiscounts(
                                                        product
                                                    )}
                                                >
                                                    <InfoIcon
                                                        style={{
                                                            paddingBottom: 4,
                                                            color: grey[500],
                                                        }}
                                                    />

                                                    <Typography
                                                        variant="body2"
                                                        style={{
                                                            paddingLeft: 5,
                                                            color: grey[500],
                                                        }}
                                                    >
                                                        {gettext(
                                                            'Has discounts in selected date range'
                                                        )}
                                                    </Typography>
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {emptyRows > 0 && (
                            <div
                                className={classes.tableRowSpan}
                                style={{ height: 49 * emptyRows }}
                            ></div>
                        )}
                    </div>
                </div>
                <div className={classes.bottomBar}>
                    <Typography>
                        {interpolate(gettext('%s selected'), [this.state.selected.length])}
                    </Typography>
                    <Button
                        className={classes.button}
                        color="primary"
                        onClick={() => {
                            handleSelectedProducts([])
                            this.setState({ selected: [] })
                        }}
                    >
                        {gettext('Cancel')}
                    </Button>
                    <Button
                        className={classNames(classes.button, classes.addButton)}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleSelectedProducts(copyObject(this.state.selected))
                            this.setState({ selected: [] })
                        }}
                    >
                        {gettext('Add products')}
                    </Button>
                </div>
                <Dialog
                    maxWidth="sm"
                    open={this.state.showCopyFromDialog}
                    onClose={this.handleCancelCopyFromDialog}
                >
                    <DialogTitle>{gettext('Copy from another discount')}</DialogTitle>
                    <DialogContent className={classes.copyFromContent} ref={this.copyFromScrollRef}>
                        <List className={classes.copyFromList}>
                            {this.state.copyFromDiscounts.map((discount) => (
                                <ListItem
                                    button
                                    key={discount.id}
                                    onClick={this.handleCopyFromDiscount(discount.id)}
                                >
                                    {discount.name}
                                </ListItem>
                            ))}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        {this.state.loadingCopyFromProducts && (
                            <CircularProgress size={20} color="secondary" />
                        )}
                        <Button
                            color="primary"
                            onClick={this.handleCancelCopyFromDialog}
                            disabled={this.state.loadingCopyFromProducts}
                        >
                            {gettext('Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth="sm"
                    open={this.state.showProductDiscountsDialog}
                    onClose={this.handleCloseProductDiscounts}
                    TransitionProps={{ onExited: this.onProductDiscountDialogExited }}
                >
                    <DialogContent>
                        <DiscountSummaryList discounts={this.state.displayProductDiscounts} />
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={this.handleCloseProductDiscounts}>
                            {gettext('Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
        )
    }
}

export default withStyles(styles)(ProductSelector)
