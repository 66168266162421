import { connect } from 'react-redux'

import Discounts from '../components/discounts'

const mapStateToProps = (state) => ({
    discountsForUserAPIURL: state.initial.endpoints.discountsForUserAPIURL,
    discountsForStoreAPIURL: state.initial.endpoints.discountsForStoreAPIURL,
    discountAPIURL: state.initial.endpoints.discountAPIURL,
    stores: state.stores,
    accountOwnerID: state.initial.accountOwner.id,
    language: state.initial.language,
})

export default connect(mapStateToProps)(Discounts)
