import { useCallback } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import AddIcon from '@material-ui/icons/Add'

import StockUnitTableHead from './stock-unit-table-head'

import { useDimensionHelpers } from '~/products/hooks/useDimensionHelpers'

const hasMultipleDimensions = (
    combos: InactiveOptionsCombo | InactiveOptionsCombo[]
): combos is (string | number)[] => Array.isArray(combos)

type InactiveOptionsCombo = string | number | (string | number)[]

type Props = {
    open: boolean
    dimensionHeaders: string[]
    inactiveOptionsCombos: InactiveOptionsCombo[]
    onClose: () => void
    onAdd: (combos: (string | number)[]) => void
}
function ProductStockUnitAddDialog({
    open,
    dimensionHeaders,
    inactiveOptionsCombos,
    onClose,
    onAdd,
}: Props) {
    const { getDimensionOptionName } = useDimensionHelpers()

    const handleClick = useCallback(
        (combos: InactiveOptionsCombo) => {
            onAdd(hasMultipleDimensions(combos) ? combos : [combos])

            if (inactiveOptionsCombos.length === 1) {
                onClose()
            }
        },
        [inactiveOptionsCombos.length, onAdd, onClose]
    )

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{gettext('Add stock unit')}</DialogTitle>

            <DialogContent>
                <TableContainer>
                    <Table>
                        <StockUnitTableHead headers={dimensionHeaders}>
                            <TableCell aria-label="Add stock unit" />
                        </StockUnitTableHead>

                        <TableBody>
                            {inactiveOptionsCombos.map((combos) => (
                                <TableRow key={JSON.stringify(combos)}>
                                    {hasMultipleDimensions(combos) ? (
                                        combos.map((optionId, dimensionIndex) => (
                                            <TableCell key={dimensionIndex}>
                                                {getDimensionOptionName(dimensionIndex, optionId)}
                                            </TableCell>
                                        ))
                                    ) : (
                                        <TableCell>{getDimensionOptionName(0, combos)}</TableCell>
                                    )}
                                    <TableCell>
                                        <Button
                                            color="secondary"
                                            startIcon={<AddIcon />}
                                            onClick={() => handleClick(combos)}
                                        >
                                            {gettext('Add')}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>

            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    {gettext('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProductStockUnitAddDialog
