import { useWatch } from 'react-hook-form'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import FormField from '~/common/components/form-field'
import { useProductFormContext } from '~/products/hooks/useProductForm'

const useStyles = makeStyles((theme) => ({
    stockItemBundle: {
        marginBottom: 15,
        paddingBottom: 15,
        borderBottomColor: theme.palette.grey[200],
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
    },
    bundleSKUTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15,
    },
    bundleSKULabel: {
        marginRight: 10,
    },
    bundleTable: {
        maxWidth: '50%',
        marginBottom: 15,
    },
    bundleUnitsField: {
        width: 75,
        height: 35,
    },
}))

type Props = {
    index: number
    onBundleItemSelectorOpen: (index: number) => void
    onBundleItemDelete: (stockUnitIndex: number, bundleItemIndex: number) => void
}

export default function Bundle({ index, onBundleItemDelete, onBundleItemSelectorOpen }: Props) {
    const { control, getValues } = useProductFormContext()
    const sku = useWatch({ control, name: `stock_units.${index}.sku`, exact: true })
    const hasBundles = useWatch({
        control,
        name: `stock_units.${index}.stock_item.is_bundle`,
        exact: true,
    })

    const classes = useStyles()

    return (
        <div className={classes.stockItemBundle}>
            <div className={classes.bundleSKUTitle}>
                <Typography className={classes.bundleSKULabel} variant="subtitle2">
                    {gettext('Bundle SKU')}
                </Typography>
                <Typography>{sku}</Typography>
            </div>

            {hasBundles && (
                <Table className={classes.bundleTable} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{gettext('SKU')}</TableCell>
                            <TableCell>{gettext('Units')}</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {getValues(`stock_units.${index}.stock_item.bundle_items`).map(
                            (item, itemIndex) => (
                                <TableRow key={item.id || itemIndex}>
                                    <TableCell>{item.member_bundle_item.sku}</TableCell>
                                    <TableCell>
                                        <FormField
                                            control={control}
                                            name={`stock_units.${index}.stock_item.bundle_items.${itemIndex}.num_of_units`}
                                            type="number"
                                            inputProps={{ min: 1 }}
                                            className={classes.bundleUnitsField}
                                            InputProps={{
                                                className: classes.bundleUnitsField,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => onBundleItemDelete(index, itemIndex)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            )}

            <Button startIcon={<AddIcon />} onClick={() => onBundleItemSelectorOpen(index)}>
                {gettext('Add bundled item')}
            </Button>
        </div>
    )
}
