import React from 'react'

import Button from '@material-ui/core/Button'
import grey from '@material-ui/core/colors/grey'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import Cookie from 'js-cookie'

import { copyObject } from '../../tools/utils'

const styles = () => ({
    addStoreMessage: {
        marginBottom: 10,
    },
    storesContainer: {
        padding: 30,
    },
    stores: {
        width: 600,
        marginTop: 30,
    },
    store: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: '1px solid ' + grey[300],
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    storeName: {
        flex: '0 0 300px',
    },
    storeNameText: {
        fontSize: '1rem',
    },
    shop: {
        display: 'flex',
    },
    shopIcon: {
        width: 25,
        marginRight: 5,
    },
    actions: {
        flexBasis: 50,
    },
    addStoreButton: {
        marginTop: 15,
    },
    deleteMessage: {
        marginBottom: 10,
    },
})

class StoreSettings extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            displayAddNewStoreDialog: false,
            displayDeleteConfirmation: false,
            deleteConfirmationStore: null,
            deleteConfirmationError: null,
            newStoreName: null,
            loading: false,
            newStoreError: null,
        }

        this.addStoreHandler = this.addStoreHandler.bind(this)
        this.deleteStoreHandler = this.deleteStoreHandler.bind(this)
        this.handleAddNewStoreDialogClose = this.handleAddNewStoreDialogClose.bind(this)
        this.handleDeleteConfirmationClose = this.handleDeleteConfirmationClose.bind(this)
        this.handleTextFieldChange = this.handleTextFieldChange.bind(this)
        this.handleAddNewStore = this.handleAddNewStore.bind(this)
        this.handleDeleteStore = this.handleDeleteStore.bind(this)
        this.handleDeleteConfirmationTextFieldChange =
            this.handleDeleteConfirmationTextFieldChange.bind(this)
    }

    addStoreHandler() {
        const { storeCreateAPIURL } = this.props

        this.setState({ displayAddNewStoreDialog: true })
    }

    deleteStoreHandler(store) {
        const storeCopy = copyObject(store)
        return () => {
            this.setState({
                deleteConfirmationStore: storeCopy,
                displayDeleteConfirmation: true,
            })
        }
    }

    handleAddNewStore() {
        const { accountOwnerID, storeCreateAPIURL, addStore } = this.props

        if (this.state.newStoreName.length < 5) {
            this.setState({ newStoreError: gettext('Store name must be more than 5 characters.') })
            return
        }

        const newStoreData = {
            owner: accountOwnerID,
            name: this.state.newStoreName,
            sync_mode: 'receiving',
        }

        this.setState({ loading: true }, () => {
            fetch(storeCreateAPIURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRFToken': Cookie.get('csrftoken'),
                },
                body: JSON.stringify(newStoreData),
            })
                .then((response) => response.json())
                .then((store) => {
                    addStore(store)

                    this.setState({
                        loading: false,
                        newStoreName: null,
                        displayAddNewStoreDialog: false,
                    })
                })
        })
    }

    handleAddNewStoreDialogClose() {
        this.setState({ displayAddNewStoreDialog: false })
    }

    handleDeleteConfirmationClose() {
        this.setState({
            deleteConfirmationShopID: null,
            displayDeleteConfirmation: false,
        })
    }

    handleDeleteStore() {
        const { storeAPIURL, deleteStore, stores } = this.props

        const storeToDelete = stores.find(
            (store) => store.id == this.state.deleteConfirmationStore.id
        )

        if (storeToDelete.name == this.state.deleteConfirmationShopString) {
            fetch(storeAPIURL.replace('store_id', this.state.deleteConfirmationStore.id), {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRFToken': Cookie.get('csrftoken'),
                },
            }).then(() => {
                deleteStore(this.state.deleteConfirmationStore.id)
                this.setState({
                    deleteConfirmationShopID: null,
                    displayDeleteConfirmation: false,
                })
            })
        } else {
            this.setState({
                deleteConfirmationError: gettext('Enter the exact name of your store.'),
            })
        }
    }

    handleTextFieldChange(event) {
        this.setState({ newStoreName: event.target.value })
    }

    handleDeleteConfirmationTextFieldChange(event) {
        this.setState({ deleteConfirmationShopString: event.target.value })
    }

    render() {
        const { classes, stores } = this.props

        return (
            <div className={classes.storesContainer}>
                <Typography className={classes.addStoreMessage}>
                    {gettext(
                        'If you run multiple online stores selling different types of products, add them below.'
                    )}
                </Typography>
                <Typography className={classes.addStoreMessage}>
                    {gettext(
                        'A store is defined by one type of product. For example, you may have a store selling the same beauty products on both Lazada and Shopee. In Oakra, this is considered one store and you only need to add it once.'
                    )}
                </Typography>
                <Typography className={classes.addStoreMessage}>
                    {gettext(
                        'Or you may have one Shopee store selling beauty products and another one selling clothes. These are considered two different stores in Oakra. Please add them separately.'
                    )}
                </Typography>

                <div className={classes.stores}>
                    <div className={classes.store} key="header">
                        <div className={classes.storeName}>
                            <Typography className={classes.headerText} variant="subtitle2">
                                {gettext('Shop')}
                            </Typography>
                        </div>
                        <div className={classes.shops}>
                            <Typography className={classes.headerText} variant="subtitle2">
                                {gettext('Connected channels')}
                            </Typography>
                        </div>
                        <div className={classes.actions}></div>
                    </div>
                    {stores.map((store) => (
                        <div className={classes.store} key={store.id}>
                            <div className={classes.storeName}>
                                <Typography className={classes.storeNameText}>
                                    {store.name}
                                </Typography>
                            </div>
                            <div className={classes.shops}>
                                {store.shops.map((shop) => (
                                    <img
                                        className={classes.shopIcon}
                                        src={shop.channel.channel_square_icon_url}
                                        key={shop.id}
                                    />
                                ))}
                            </div>
                            <div className={classes.actions}>
                                <IconButton
                                    color="secondary"
                                    onClick={this.deleteStoreHandler(store)}
                                >
                                    <DeleteIcon className={classes.rightIcon} />
                                </IconButton>
                            </div>
                        </div>
                    ))}
                </div>
                <Button
                    className={classes.addStoreButton}
                    variant="outlined"
                    color="primary"
                    onClick={this.addStoreHandler}
                >
                    <AddIcon className={classes.rightIcon} />
                    {gettext('Add store')}
                </Button>

                <Dialog
                    open={this.state.displayDeleteConfirmation}
                    onClose={this.handleDeleteConfirmationClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {gettext('Delete this store?')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            id="alert-dialog-description"
                            className={classes.deleteMessage}
                        >
                            {gettext(
                                'Deleting this store will remove ALL related product and order data from Oakra. Only proceed if you are absolutely sure.'
                            )}
                        </DialogContentText>
                        <DialogContentText
                            id="alert-dialog-description"
                            className={classes.deleteMessage}
                        >
                            {gettext('It will not affect information on your linked channels.')}
                        </DialogContentText>
                        <DialogContentText
                            id="alert-dialog-description"
                            className={classes.deleteMessage}
                        >
                            {interpolate(gettext('Enter "%s" to confirm.'), [
                                this.state.deleteConfirmationStore
                                    ? this.state.deleteConfirmationStore.name
                                    : '',
                            ])}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={pgettext('Store name')}
                            fullWidth
                            value={this.state.deleteConfirmationShopString || ''}
                            onChange={this.handleDeleteConfirmationTextFieldChange}
                            error={Boolean(this.state.deleteConfirmationError)}
                            helperText={this.state.deleteConfirmationError}
                            inputProps={{ maxLength: 50 }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteConfirmationClose} color="primary">
                            {gettext('Cancel')}
                        </Button>
                        <Button onClick={this.handleDeleteStore} color="secondary" autoFocus>
                            {gettext('Delete')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.displayAddNewStoreDialog}
                    onClose={this.handleAddNewStoreDialogClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <DialogTitle id="form-dialog-title">{gettext('Add new store')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {gettext('Enter the name of a new store')}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={pgettext('Store name')}
                            fullWidth
                            value={this.state.newStoreName || ''}
                            onChange={this.handleTextFieldChange}
                            error={Boolean(this.state.newStoreError)}
                            helperText={this.state.newStoreError}
                            inputProps={{ maxLength: 50 }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAddNewStoreDialogClose} color="primary">
                            {gettext('Cancel')}
                        </Button>
                        <Button onClick={this.handleAddNewStore} color="secondary">
                            {gettext('Add')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(styles)(StoreSettings)
