import type { ProductFormSchema } from '../hooks/useProductForm'

import type { Channel } from '~/common/schemas/shop'
import { getUniqueChannelsFromChannelProducts } from '~/tools/utils'

export function updateChannels(channelProducts: ProductFormSchema['channel_products']) {
    return getUniqueChannelsFromChannelProducts(channelProducts).map((channel: Channel) => {
        const channelProductForChannel = channelProducts.find(
            (cp) => cp.shop.channel.id === channel.id
        )

        return {
            ...channel,
            category: channelProductForChannel?.category,
        }
    }) satisfies ProductFormSchema['channels']
}
