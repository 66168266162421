import { connect } from 'react-redux'

import SignUp from '../components/signup'

const mapStateToProps = (state) => ({
    sendConfirmationEmailAPIURL: state.initial.endpoints.sendConfirmationEmailAPIURL,
    signUpAPIURL: state.initial.endpoints.signUpAPIURL,
    staticRoot: state.initial.staticRoot,
})

export default connect(mapStateToProps)(SignUp)
