import { z } from 'zod'

const apiError = z.object({
    error: z.union([
        z.string(),
        z.array(z.string()),
        z.record(z.union([z.array(z.string()), z.string()])),
    ]),
    request_id: z.string(),
})

export class ApiError extends Error {
    constructor(
        public message: string,
        public requestId: string
    ) {
        super(message)
    }
}

export async function handleFetchError(response: Response): Promise<never> {
    const data = await z.promise(apiError).parse(response.json())

    throw new ApiError(getErrorMessage(data), data.request_id)
}

function getErrorMessage({ error }: z.infer<typeof apiError>) {
    switch (true) {
        case typeof error === 'string':
            return error
        case Array.isArray(error):
            return error.join(', ')
        case typeof error === 'object':
            return getMessageFromErrorObject(error)
        default:
            return 'Unknown error'
    }
}

function getMessageFromErrorObject(error: Record<string, string | string[]>) {
    return Object.values(error)
        .map((value) => {
            if (Array.isArray(value)) {
                return value.join(', ')
            }

            return value
        })
        .join('\n')
}
