import { connect } from 'react-redux'

import SaleCancelConfirmation from '../components/sale/sale-cancel-confirmation'

const mapStateToProps = (state) => ({
    submitSaleCancelConfirmationAPIURL: state.initial.endpoints.submitSaleCancelConfirmationAPIURL,
    saleCancelRejectedReasons: state.initial.saleCancelRejectedReasons,
})

export default connect(mapStateToProps)(SaleCancelConfirmation)
