import { connect } from 'react-redux'

import AddressEditor from '../components/address-editor'

const mapStateToProps = ({ initial: { endpoints, user } }) => ({
    addressesAPIURL: endpoints.addressesAPIURL.replace('user_pk', user.id),
    addressAPIURL: endpoints.addressAPIURL,
})

export default connect(mapStateToProps)(AddressEditor)
