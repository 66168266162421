import { useCallback, useContext, useMemo, useState } from 'react'
import { useFieldArray, useFormState, useWatch, type FieldArrayWithId } from 'react-hook-form'
import { TableVirtuoso, type TableComponents } from 'react-virtuoso'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'

import { isEqual } from 'lodash-es'

import { type ProductFormSchema, useProductFormContext } from '../../hooks/useProductForm'
import { useCommonStyles } from '../../styles'

import ProductStockUnitAddDialog from './product-stock-unit-add-dialog'
import StockUnitRow from './stock-unit-row'

import { FormField } from '~/common/components'
import { useLanguage } from '~/common/hooks'
import { MAX_VIRTUALIZED_HEIGHT } from '~/constants'
import { AttributesContext } from '~/products/components/attributes-provider'
import { useProductPage } from '~/products/hooks/useProductPage'
import { useVirtualizationContainer } from '~/products/hooks/useVirtualizationContainer'
import { computeStockUnitItemKey, createStockUnit } from '~/products/utils/stock-unit'
import { getTranslation } from '~/tools/utils'
import { getAllPossibleDimensionsOptions } from '~/utils/getAllPossibleDimensionsOptions'
import { makeDimensionOptions } from '~/utils/makeDimensionOptions'

const cleanSKU = (sku: string) => sku?.trim().replace(/\s+/g, ' ') ?? ''

const VirtuosoTableComponents: TableComponents<
    FieldArrayWithId<ProductFormSchema, 'stock_units', 'key'>
> = {
    Scroller: TableContainer,
    Table: (props) => (
        <Table {...props} style={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableBody,
    TableRow,
    TableFoot: TableFooter,
}

export default function ProductStockUnits() {
    const commonClasses = useCommonStyles()
    const language = useLanguage()
    const [isAdding, setIsAdding] = useState(false)
    const {
        canEdit,
        stockUnits,
        activeStockUnits,
        inactiveOptionsCombos,
        addStockUnit,
        removeStockUnit,
    } = useStockUnitsField()

    const { control } = useProductFormContext()
    const dimensions = useWatch({ control, name: 'dimensions' })
    const hasDimensions = dimensions.length > 0
    const dimensionHeaders = useMemo(
        () => dimensions.map((dimension) => getTranslation(dimension, language, 'name')),
        [dimensions, language]
    )

    const handleClose = useCallback(() => {
        setIsAdding(false)
    }, [])

    const [containerHeight, setContainerHeight] = useState(0)
    const ref = useVirtualizationContainer('stock_unit')

    const renderHeaderContent = useCallback(() => {
        return (
            <TableRow style={{ backgroundColor: 'white' }}>
                {dimensionHeaders.map((header) => (
                    <TableCell key={header}>{header}</TableCell>
                ))}
                <TableCell scope="col" colSpan={2}>
                    SKU
                </TableCell>
            </TableRow>
        )
    }, [dimensionHeaders])

    const renderFooterContent = useCallback(() => {
        return (
            <TableRow
                style={{
                    display: inactiveOptionsCombos.length === 0 ? 'none' : 'table-row',
                }}
            >
                {/* colSpan is number of dimensions + SKU column + Action column */}
                <TableCell colSpan={dimensions.length + 2}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            startIcon={<AddIcon />}
                            color="secondary"
                            onClick={() => setIsAdding(true)}
                        >
                            {gettext('Add stock unit')}
                        </Button>
                    </Box>
                </TableCell>
            </TableRow>
        )
    }, [dimensions.length, inactiveOptionsCombos.length])

    const { errors } = useFormState({ control, name: 'stock_units', exact: true })

    return (
        <Paper id="stock_units" className={commonClasses.section}>
            <Box
                sx={{ display: 'flex', alignItems: 'center', gridGap: 16 }}
                className={commonClasses.sectionTitle}
            >
                <Typography variant="subtitle1">{gettext('Stock units')}</Typography>
                {errors.stock_units?.root && (
                    <Typography variant="body2" color="error">
                        {errors.stock_units.root.message}
                    </Typography>
                )}
            </Box>

            <Box sx={{ my: 2, maxWidth: '50%' }}>
                {hasDimensions ? (
                    <TableVirtuoso
                        ref={ref}
                        style={{ height: containerHeight + 1, maxHeight: MAX_VIRTUALIZED_HEIGHT }}
                        data={activeStockUnits}
                        components={VirtuosoTableComponents}
                        computeItemKey={computeStockUnitItemKey}
                        totalListHeightChanged={setContainerHeight}
                        fixedHeaderContent={renderHeaderContent}
                        fixedFooterContent={renderFooterContent}
                        itemContent={(_, stockUnit) => (
                            <StockUnitRow
                                index={stockUnits.findIndex((su) => su.id === stockUnit.id)}
                                id={stockUnit.id}
                                canEdit={canEdit}
                                hasOnlyOne={activeStockUnits.length === 1}
                                options={stockUnit.dimension_options}
                                onDelete={removeStockUnit}
                            />
                        )}
                    />
                ) : (
                    <FormField
                        control={control}
                        name="stock_units.0.sku"
                        variant="outlined"
                        size="small"
                        placeholder="SKU"
                        rules={{ required: gettext('SKU is required') }}
                    />
                )}
            </Box>

            <ProductStockUnitAddDialog
                open={isAdding}
                dimensionHeaders={dimensionHeaders}
                inactiveOptionsCombos={inactiveOptionsCombos}
                onAdd={addStockUnit}
                onClose={handleClose}
            />
        </Paper>
    )
}

function useStockUnitsField() {
    const {
        addStockUnit: addStockUnitToAttributes,
        deleteStockUnits: deleteStockUnitsFromAttributes,
    } = useContext(AttributesContext)
    const { productInfo } = useProductPage()
    const { locations } = productInfo
    const { control, getValues, setValue, setError } = useProductFormContext()
    const { isSubmitting } = useFormState({ control, name: 'stock_units' })
    const { fields, remove, insert } = useFieldArray({
        control,
        name: 'stock_units',
        keyName: 'key',
        rules: {
            validate: {
                hasSkus: (values) => {
                    if (!isSubmitting) {
                        return true
                    }

                    const suIndexWithErrors = values.reduce<number[]>((acc, su, index) => {
                        const sku = cleanSKU(su.sku)
                        if (!sku) {
                            acc.push(index)
                        }

                        return acc
                    }, [])

                    requestAnimationFrame(() => {
                        suIndexWithErrors.forEach((index) => {
                            setError(`stock_units.${index}.sku`, {
                                type: 'required',
                                message: gettext('SKU is required'),
                            })
                        })
                    })

                    return (
                        suIndexWithErrors.length === 0 || gettext('Some stock units contain errors')
                    )
                },
            },
        },
    })

    const allPossibleOptions = useAllPossibleOptions()
    const stockUnitMap = useMemo(() => {
        return fields.reduce((acc, su) => {
            const key = su.dimension_options.map(({ id }) => id?.toString() ?? '').join('|')
            return key ? acc.set(key, su) : acc
        }, new Map<string, (typeof fields)[number]>())
    }, [fields])

    const [activeStockUnits, inactiveOptionsCombos] = useMemo(() => {
        const activeUnits: typeof fields = []
        const inactiveCombos: Array<string | number | Array<string | number>> = []

        // Remove all skus that are no longer in the options
        allPossibleOptions?.forEach((combos) => {
            const comboKey = combos.join('|')
            const stockUnit = stockUnitMap.get(comboKey)

            if (stockUnit) {
                activeUnits.push(stockUnit)
            } else {
                inactiveCombos.push(combos)
            }
        })

        return [activeUnits, inactiveCombos]
    }, [allPossibleOptions, stockUnitMap])

    const findStockUnitIndexByDimensionOptions = useCallback(
        (options: Array<string | number>) => {
            return allPossibleOptions.findIndex((combos) => {
                return isEqual(combos, options)
            })
        },
        [allPossibleOptions]
    )

    const addStockUnit = useCallback(
        (optionIds: Array<string | number>) => {
            const product = getValues()
            const dimensions = getValues('dimensions')
            const dimensionOptions = makeDimensionOptions(optionIds ?? [], dimensions)
            const stockUnit = createStockUnit(dimensionOptions, locations, product)
            const index = findStockUnitIndexByDimensionOptions(optionIds)
            if (index === -1) {
                return
            }

            addStockUnitToAttributes(stockUnit)
            insert(index, stockUnit)

            const channelProducts = getValues('channel_products')
            const updatedChannelProducts = channelProducts.map((cp) => ({
                ...cp,
                channel_stock_units: [
                    ...cp.channel_stock_units,
                    {
                        price: '0',
                        images: [],
                        placeholder: false,
                        stock_unit: stockUnit,
                        sku: stockUnit.sku,
                        channel_stock_item: { fulfillment_stock_record_options: [] },
                        fulfillment_locations: locations.map((l) => l.location_id),
                    },
                ],
            }))

            setValue('channel_products', updatedChannelProducts)
        },
        [
            getValues,
            locations,
            findStockUnitIndexByDimensionOptions,
            addStockUnitToAttributes,
            insert,
            setValue,
        ]
    )

    const removeStockUnit = useCallback(
        (index: number) => {
            remove(index)
            deleteStockUnitsFromAttributes([index])

            const channelProducts = getValues('channel_products').map((cp) => ({
                ...cp,
                channel_stock_units: cp.channel_stock_units.filter(
                    (_, csuIndex) => csuIndex !== index
                ),
            }))

            setValue('channel_products', channelProducts)
        },
        [deleteStockUnitsFromAttributes, getValues, remove, setValue]
    )

    return {
        canEdit: getValues('stock_units_editing_is_enabled'),
        stockUnits: fields,
        activeStockUnits,
        inactiveOptionsCombos,
        addStockUnit,
        removeStockUnit,
    }
}

function useAllPossibleOptions() {
    const { control } = useProductFormContext()
    const dimensions = useWatch({ control, name: 'dimensions' })

    return getAllPossibleDimensionsOptions(dimensions)
}
