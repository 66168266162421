import { useMemo, useState } from 'react'

import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import PropTypes from 'prop-types'

import FormSearchField from '../../../../../components/FormSearchField'
import { useTableStyles } from '../../../../../theme'
import { formatScopeName } from '../user-permissions'

import { useStyles } from './styles'

export default function Stores({ permissions, checkHasPermission, onPermissionChanged }) {
    const classes = useStyles()
    const tableClasses = useTableStyles()

    const [searchTerm, setSearchTerm] = useState('')

    const filteredPermissions = useMemo(() => {
        const search = searchTerm.toLowerCase()
        return permissions.filter(({ storeName }) => storeName?.toLowerCase().includes(search))
    }, [permissions, searchTerm])

    return (
        <>
            <Table className={tableClasses.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3} className={classes.tableCell}>
                            <Box className={classes.tableCellContainer}>
                                <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                                    {gettext('Stores')}
                                </Typography>
                                <FormSearchField
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    onClear={() => setSearchTerm('')}
                                />
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
            {filteredPermissions.map(({ storeId, storeName, permissions: currentPermissions }) => (
                <Table className={tableClasses.table} size="small" key={storeId}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Box py={2}>
                                    <Typography variant="h6">{storeName}</Typography>
                                </Box>
                            </TableCell>
                            <TableCell width={50}>{gettext('View')}</TableCell>
                            <TableCell width={50}>{gettext('Edit')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(currentPermissions).map(([scope, scopedPermissions]) => (
                            <TableRow key={scope}>
                                <TableCell className={tableClasses.tableCell}>
                                    {formatScopeName(scope)}
                                </TableCell>

                                {Object.values(scopedPermissions).map((codename) => (
                                    <TableCell key={codename} className={tableClasses.tableCell}>
                                        <Checkbox
                                            color="secondary"
                                            checked={checkHasPermission(codename, storeId)}
                                            onChange={(_, checked) => {
                                                onPermissionChanged({
                                                    codenames: [codename],
                                                    value: checked,
                                                    objectPk: parseInt(storeId),
                                                    contentModel: 'store',
                                                })
                                            }}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ))}
        </>
    )
}

Stores.propTypes = {
    permissions: PropTypes.array.isRequired,
    checkHasPermission: PropTypes.func.isRequired,
    onPermissionChanged: PropTypes.func.isRequired,
}
