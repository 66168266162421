import { type ChangeEvent, useCallback, useMemo } from 'react'
import { useFormState, useWatch } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'

import PricingTable from './pricing-table'
import ProductDiscounts from './product-discounts'
import ProductSection from './product-section'

import { DebouncedTextField } from '~/oakra/components/inputs'
import { useProductFormContext } from '~/products/hooks/useProductForm'
import { useCommonStyles } from '~/products/styles'
import type { Theme } from '~/theme-v5'

const FIELD_WIDTH = 125

export const usePricingStyles = makeStyles<Theme>((theme) => ({
    pricesErrorMessage: {
        color: theme.palette.error.main,
        marginLeft: 15,
        fontSize: '0.9rem',
    },
    pricingSection: {
        marginTop: 25,
        width: 'auto',
    },
    pricingSectionTitle: {
        marginBottom: 20,
    },
    pricingRow: {
        display: 'flex',
        alignItems: 'center',
    },
    setAllRow: {
        marginBottom: 15,
        paddingBottom: 15,
        borderBottom: '1px solid lightgray',
    },
    setAllMessage: {
        marginRight: 15,
        width: 265,
        color: 'gray',
    },
    priceField: {
        height: 35,
    },
    pricingDisabledMessage: {
        marginTop: 15,
        marginBottom: 15,
        fontSize: '0.9rem',
        color: 'grey',
    },
}))

type Props = {
    isNewProduct: boolean
}

export default function ProductPricing({ isNewProduct }: Props) {
    const commonClasses = useCommonStyles()
    const classes = usePricingStyles()
    const { control, getValues, setValue, register } = useProductFormContext()
    const stockUnits = useWatch({ control, name: 'stock_units', exact: true })
    const channelProducts = useWatch({ control, name: 'channel_products' })
    const { ref } = register('channel_products', {
        validate: {
            incompletePricing: (value) => {
                return (
                    value
                        .flatMap((cp) => cp.channel_stock_units)
                        .filter((csu) => csu.placeholder === false)
                        .some((csu) => parseInt(csu.price) > 0) ||
                    gettext('Please complete pricing information.')
                )
            },
        },
    })

    const filteredChannelProducts = useMemo(() => {
        const shopIds = new Set<number>()
        const channelProductByShop: Record<number, (typeof channelProducts)[number]> = {}

        // Each shop will have 1 price column, choose the latest channel product to render to avoid old channel product with missing stock units
        channelProducts.forEach((cp) => {
            channelProductByShop[cp.shop.id] = cp

            if (shopIds.has(cp.shop.id)) {
                return
            }

            if (!isNewProduct || cp.shouldCreateChannelProduct) {
                shopIds.add(cp.shop.id)
            }
        })

        return [...shopIds].map((shopId) => channelProductByShop[shopId])
    }, [isNewProduct, channelProducts])

    const channelProductIndexById = useMemo(
        () => Object.fromEntries(channelProducts.map((cp, index) => [cp.id, index])),
        [channelProducts]
    )

    const isAnyChannelProductInPromotionPeriod = useMemo(
        () => channelProducts.some((cp) => cp.is_promotion_period),
        [channelProducts]
    )

    const setPriceBulk = useCallback(
        (value: number, params: { shopId?: number; stockUnitIndex?: number } = {}) => {
            const channelProducts = getValues('channel_products')
            const updatedChannelProducts = channelProducts.map((cp) => {
                if (params.shopId && params.shopId !== cp.shop.id) {
                    return cp
                }

                return {
                    ...cp,
                    channel_stock_units: cp.channel_stock_units.map((csu, csuIndex) => {
                        if (
                            csu.placeholder ||
                            (params.stockUnitIndex && params.stockUnitIndex !== csuIndex)
                        ) {
                            return csu
                        }

                        return { ...csu, price: value.toString() }
                    }),
                }
            })

            setValue('channel_products', updatedChannelProducts)
        },
        [getValues, setValue]
    )

    const handleSetPriceForAll = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = parseFloat(e.target.value)
            if (isNaN(value)) {
                return
            }

            setPriceBulk(value)
        },
        [setPriceBulk]
    )

    const handleSetPriceForShop = useCallback(
        (shopId: number) => (e: ChangeEvent<HTMLInputElement>) => {
            const value = parseFloat(e.target.value)
            if (isNaN(value)) {
                return
            }

            setPriceBulk(value, { shopId })
        },
        [setPriceBulk]
    )

    const handleSetPriceForStockUnits = useCallback(
        (stockUnitIndex: number) => (e: ChangeEvent<HTMLInputElement>) => {
            const value = parseFloat(e.target.value)
            if (isNaN(value)) {
                return
            }

            setPriceBulk(value, { stockUnitIndex })
        },
        [setPriceBulk]
    )

    const { errors } = useFormState({ control, name: `channel_products`, exact: true })
    const channelStockUnitsErrors = errors.channel_products

    return (
        <ProductSection>
            <div ref={ref} className={commonClasses.sectionTitle}>
                <Typography variant="subtitle1">{gettext('Pricing')}</Typography>
                {channelStockUnitsErrors?.type === 'incompletePricing' && (
                    <Typography className={classes.pricesErrorMessage}>
                        {channelStockUnitsErrors?.message}
                    </Typography>
                )}
            </div>

            <div className={classes.pricingSection}>
                <Typography className={classes.pricingSectionTitle} variant="subtitle2">
                    {gettext('Original prices')}
                </Typography>
                {(stockUnits.length > 1 || filteredChannelProducts.length > 1) && (
                    <div className={classNames(classes.pricingRow, classes.setAllRow)}>
                        <div>
                            <Typography className={classes.setAllMessage} variant="body2">
                                {gettext('Use this field to set all prices at once')}
                            </Typography>
                        </div>

                        <Box width={FIELD_WIDTH}>
                            <DebouncedTextField
                                type="number"
                                variant="outlined"
                                margin="dense"
                                placeholder={gettext('Set all')}
                                InputProps={{ className: classes.priceField }}
                                onChange={handleSetPriceForAll}
                                inputProps={{ min: 0 }}
                            />
                        </Box>
                    </div>
                )}

                <PricingTable
                    channelProducts={filteredChannelProducts}
                    stockUnits={stockUnits}
                    fieldWidth={FIELD_WIDTH}
                    channelProductIndexById={channelProductIndexById}
                    onSetPriceForShop={handleSetPriceForShop}
                    onSetPriceForStockUnits={handleSetPriceForStockUnits}
                />
            </div>

            {isAnyChannelProductInPromotionPeriod && (
                <Typography className={classes.pricingDisabledMessage}>
                    {gettext(
                        'Price editing is disabled for products participating in platform promotions'
                    )}
                </Typography>
            )}

            <ProductDiscounts />
        </ProductSection>
    )
}
