import { useMutation } from '@tanstack/react-query'
import Cookies from 'js-cookie'

import { useAppSelector } from '~/store'

export async function deleteProduct(productAPIURL: string, productId: number) {
    const res = await fetch(productAPIURL.replace('product_pk', productId.toString()), {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRFToken': Cookies.get('csrftoken') ?? '',
        },
    })

    if (!res.ok) {
        throw new Error('Failed to delete product')
    }
}

export function useDeleteProductMutation() {
    const productAPIURL = useAppSelector((state) => state.initial.endpoints.productAPIURL)

    return useMutation({
        mutationFn: (productId: number) => deleteProduct(productAPIURL, productId),
    })
}
