import { type ChangeEvent, memo, useMemo } from 'react'
import { type FieldValues, useController, type UseControllerProps } from 'react-hook-form'

import TextField from '@material-ui/core/TextField'
import type { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const transform = {
    input: (value: number) => (isNaN(value) || value === 0 ? '' : value.toString()),
    output: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const output = parseFloat(e.target.value)
        return isNaN(output) ? 0 : output
    },
}

type Props<T extends FieldValues> = TextFieldProps &
    UseControllerProps<T> & {
        showTextCount?: boolean
        shouldTransform?: boolean
        showErrorText?: boolean
    }

function FormField<T extends FieldValues>({
    name,
    control,
    defaultValue,
    rules,
    type,
    helperText,
    label,
    showTextCount,
    shouldUnregister,
    shouldTransform = true,
    onChange,
    onBlur,
    showErrorText = true,
    ...rest
}: Props<T>) {
    const {
        fieldState: { error },
        field: { ref, ...field },
    } = useController({ name, control, defaultValue, shouldUnregister, rules })
    const isNumberField = useMemo(() => type === 'number', [type])

    return (
        <>
            <TextField
                {...field}
                value={field.value ?? ''}
                inputRef={ref}
                type={type}
                variant="outlined"
                margin="dense"
                label={label}
                error={!!error}
                helperText={showErrorText ? error?.message ?? helperText : ''}
                onChange={(e) => {
                    field.onChange(isNumberField && shouldTransform ? transform.output(e) : e)
                    onChange?.(e)
                }}
                onBlur={(e) => {
                    field.onBlur()
                    onBlur?.(e)
                }}
                {...rest}
            />
            {showTextCount && (
                <Typography variant="body2" color="textSecondary">
                    {field.value?.length ?? 0}/{rest.inputProps?.maxLength ?? '-'}
                </Typography>
            )}
        </>
    )
}

export default memo(FormField) as typeof FormField
