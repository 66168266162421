import { useEffect, useState } from 'react'
import { useFieldArray, useFormState, useWatch } from 'react-hook-form'
import { Virtuoso } from 'react-virtuoso'

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { useCommonStyles } from '../../styles'

import ProductSection from './product-section'

import { MediaStatus, MediaType } from '~/common/schemas/media'
import { MAX_PRODUCT_IMAGES, MAX_PRODUCT_VIDEOS } from '~/constants'
import MediaShowcase from '~/products/components/media-showcase'
import ProductStockUnitImages from '~/products/components/product-info/product-stock-unit-images'
import { type ProductFormSchema, useProductFormContext } from '~/products/hooks/useProductForm'
import { computeStockUnitItemKey } from '~/products/utils/stock-unit'
import { getMediaIDSet, getMediaNameSet } from '~/products/utils/updateChannelProductMedia'

const useStyles = makeStyles(() => ({
    stockUnitImages: {
        minHeight: 300,
        maxHeight: 440,
    },
}))

export default function ProductMedia() {
    const commonClasses = useCommonStyles()
    const classes = useStyles()
    const { control, setValue, getValues, register } = useProductFormContext()
    const { ref: imagesRef } = register('images')
    const { ref: videosRef } = register('videos')

    const { fields: images } = useFieldArray({
        control,
        name: 'images',
        keyName: 'imageId',
        rules: {
            validate: {
                minLength: (value) => {
                    return value.length > 0 || gettext('A least one image required')
                },
                maxLength: (value) => {
                    return (
                        value.length <= MAX_PRODUCT_IMAGES ||
                        gettext(`Max ${MAX_PRODUCT_IMAGES} images`)
                    )
                },
            },
        },
    })
    const { fields: videos } = useFieldArray({
        control,
        name: 'videos',
        keyName: 'videoId',
        rules: {
            validate: {
                maxLength: (value) => {
                    return (
                        value.length <= MAX_PRODUCT_VIDEOS ||
                        gettext(`Max ${MAX_PRODUCT_VIDEOS} videos`)
                    )
                },
            },
        },
    })

    const { errors } = useFormState({ control, name: ['images', 'videos'] })
    const imageError = errors.images?.root
    const videoError = errors.videos?.root

    const stockUnits = useWatch({ control, name: 'stock_units', exact: true })
    const shouldShowStockUnitsImages = stockUnits.length > 1
    const [stockUnitImageHeight, setStockUnitImageHeight] = useState(0)

    useEffect(() => {
        const readyToUpdate =
            images.length > 0 &&
            images.every((image) => image.status === MediaStatus.UPLOADED) &&
            (!videos ||
                videos.length === 0 ||
                (videos.length > 0 &&
                    videos.every((image) => image.status === MediaStatus.UPLOADED)))

        if (!readyToUpdate) {
            return
        }

        const channelProducts = getValues('channel_products')
        setValue('channel_products', updateChannelProductMedia(channelProducts, images, videos))
    }, [getValues, images, videos, setValue])

    return (
        <ProductSection>
            <Typography className={commonClasses.sectionTitle} variant="subtitle1">
                {gettext('Product media')}
            </Typography>

            <Box sx={{ mt: 2, position: 'relative' }}>
                <Typography innerRef={videosRef} variant="body1">
                    {gettext('Videos')}
                </Typography>
                <Typography className={commonClasses.productImageHelperText} variant="caption">
                    {gettext('Max 8 videos')}
                </Typography>

                {videoError && (
                    <Typography className={commonClasses.productImageErrorText} variant="caption">
                        {videoError.message}
                    </Typography>
                )}

                <MediaShowcase
                    mediaPath="grouped_media"
                    fieldPath="videos"
                    mediaType={MediaType.VIDEO}
                    maxMediaObjs={MAX_PRODUCT_VIDEOS}
                />
            </Box>

            <Box sx={{ mt: 2, position: 'relative' }}>
                <Typography innerRef={imagesRef} data-error-field="images" variant="body1">
                    {gettext('Product images')}
                </Typography>
                <Typography className={commonClasses.productImageHelperText} variant="caption">
                    {gettext('Max 8 images.')}
                </Typography>

                {imageError && (
                    <Typography className={commonClasses.productImageErrorText} variant="caption">
                        {imageError.message}
                    </Typography>
                )}
                <MediaShowcase
                    mediaPath="grouped_media"
                    fieldPath="images"
                    mediaType={MediaType.IMAGE}
                    maxMediaObjs={MAX_PRODUCT_IMAGES}
                />
            </Box>

            {shouldShowStockUnitsImages && (
                <Box mt={2}>
                    <Typography variant="body1">{gettext('Stock units images')}</Typography>
                    <Typography variant="caption">
                        {gettext('Optional. Upload images specific to a stock unit (max 5 images)')}
                    </Typography>

                    <Virtuoso
                        data={stockUnits}
                        className={classes.stockUnitImages}
                        style={{ height: stockUnitImageHeight }}
                        computeItemKey={computeStockUnitItemKey}
                        itemContent={(index) => <ProductStockUnitImages index={index} />}
                        totalListHeightChanged={setStockUnitImageHeight}
                    />
                </Box>
            )}
        </ProductSection>
    )
}

function updateChannelProductMedia(
    channelProducts: ProductFormSchema['channel_products'],
    images: ProductFormSchema['images'],
    videos: ProductFormSchema['videos']
) {
    const imageIds = getMediaIDSet(images)
    const imageNames = getMediaNameSet(images)
    const videoIds = getMediaIDSet(videos)
    const videoNames = getMediaNameSet(videos)

    return channelProducts.map((cp) => {
        if (cp.has_channel_specific_media) {
            return cp
        }

        // Remove deleted images
        const channelProductImages = cp.images.filter((cpi) => {
            return cpi.product_image?.id
                ? imageIds.has(cpi.product_image.id)
                : imageNames.has(cpi.product_image?.name ?? '')
        })

        const channelProductImagesMap = new Map(
            channelProductImages.map((cpi) => [
                cpi.product_image?.id ?? cpi.product_image?.name ?? '',
                cpi,
            ])
        )

        // Update or add images
        images.forEach((image) => {
            const channelProductImage = channelProductImagesMap.get(image.id ?? -1)

            if (channelProductImage) {
                channelProductImage.display_order = image.display_order
            } else {
                channelProductImages.push(image)
            }
        })

        // Remove deleted videos
        const channelProductVideos =
            cp?.videos?.filter((cpi) => {
                return cpi?.product_video?.id
                    ? videoIds.has(cpi.product_video.id)
                    : videoNames.has(cpi.product_video?.name ?? '')
            }) ?? []

        const channelProductVideosMap = new Map(
            channelProductVideos.map((cpi) => [
                cpi.product_video?.id ?? cpi.product_video?.name ?? '',
                cpi,
            ])
        )

        // Update or add videos
        videos?.forEach((video) => {
            const channelProductVideo = channelProductVideosMap.get(video.id ?? -1)

            if (channelProductVideo) {
                channelProductVideo.display_order = video.display_order
            } else {
                channelProductVideos.push(video)
            }
        })

        return {
            ...cp,
            images: [...channelProductImages].sort(
                (cpi1, cpi2) => cpi1.display_order - cpi2.display_order
            ),
            videos: [...channelProductVideos].sort(
                (cpi1, cpi2) => cpi1.display_order - cpi2.display_order
            ),
        }
    })
}
