import React from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'

import classNames from 'classnames'

import { copyObject, isValidBarcodeCharacter } from '../../tools/utils'

const styles = (theme) => ({
    dialog: {
        height: 'calc(100% - 75px)',
    },
    loadingDialogContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dialogContent: {},
    dialogTitle: {
        borderBottom: '1px solid lightgray',
    },
    stockUnitInfo: {
        display: 'flex',
        padding: 30,
    },
    coverImage: {
        width: '25vw',
        minWidth: '25vw',
        marginRight: 50,
    },
    rightPanel: {},
    productName: {},
    stockUnitSection: {
        display: 'flex',
        marginTop: 50,
    },
    stockUnitSubSection: {
        marginRight: 50,
    },
    stockUnitSubSectionTitle: {
        color: grey[500],
        fontWeight: 600,
    },
    stockUnitSubSectionText: {},
    row: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 50,
    },
    scanIcon: {
        marginLeft: 50,
        fontSize: 60,
    },
    unitsRequired: {},
    unitsRequiredTitle: {
        color: grey[500],
        fontWeight: 600,
    },
    prompt: {
        color: grey[500],
        marginTop: 20,
        fontSize: '1.2rem',
    },
    scanIcon: {
        fontSize: 60,
        marginRight: 10,
    },
    scannedMessage: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 60,
    },
    scanErrorText: {
        color: theme.palette.error.main,
    },
    confirmedText: {
        color: theme.palette.primary.main,
    },
    actionButton: {},
    nextButton: {
        color: 'white',
    },
    dialogActions: {
        borderTop: '1px solid lightgray',
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 15,
    },
    soundSwitchContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
    },
    soundSwitchText: {
        color: grey[600],
    },
    spacer: {
        flex: '1 1 auto',
    },
    counterText: {
        color: grey[600],
        marginRight: 10,
    },
    allConfirmed: {
        display: 'flex',
        alignItems: 'center',
    },
    allConfirmedIcon: {
        fontSize: 60,
        marginRight: 15,
    },
    allConfirmedIconText: {
        color: grey[600],
    },
})

class PickListProcessor extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            items: [],
            currentItemIndex: 0,
            showConfirmed: false,
            showError: false,
            soundOn: true,
            complete: false,
        }

        this.fetchItems = this.fetchItems.bind(this)
        this.handleManualConfirm = this.handleManualConfirm.bind(this)
        this.onEnter = this.onEnter.bind(this)
        this.onExit = this.onExit.bind(this)
        this.isValidScan = this.isValidScan.bind(this)
        this.next = this.next.bind(this)
        this.back = this.back.bind(this)
        this.handleToggleSound = this.handleToggleSound.bind(this)

        this.keyDownListener = null

        this.buffer = ''
    }

    componentDidMount() {
        const { staticRoot } = this.props

        this.scanSuccessSound = new Audio(staticRoot + 'web/audio/Scanner - success.mp3')
        this.scanErrorSound = new Audio(staticRoot + 'web/audio/Scanner - error.mp3')
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open != this.props.open) {
            this.fetchItems()
        }
    }

    fetchItems() {
        const { pickPackBatch, pickPackBatchItemsAPIURL } = this.props

        if (pickPackBatch) {
            fetch(pickPackBatchItemsAPIURL.replace('pick_pack_batch_pk', pickPackBatch.id)).then(
                (response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            data.forEach((item) => {
                                item.confirmed = false
                            })

                            this.setState({
                                items: data,
                                loading: false,
                            })
                        })
                    }
                }
            )
        }
    }

    handleManualConfirm() {
        this.next()
    }

    next() {
        const { handleClose } = this.props

        const items = copyObject(this.state.items)
        const currentItem = items[this.state.currentItemIndex]
        const nextItemIndex = this.state.currentItemIndex + 1

        if (nextItemIndex >= this.state.items.length) {
            this.setState({ complete: true }, () => {
                setTimeout(() => {
                    handleClose()
                }, 3000)
            })
        } else if (currentItem.confirmed) {
            this.setState({
                currentItemIndex: nextItemIndex,
                showConfirmed: false,
                showError: false,
            })
        } else {
            currentItem.confirmed = true

            this.setState({ items, showConfirmed: true, showError: false }, () => {
                setTimeout(() => {
                    this.setState({
                        items: items,
                        currentItemIndex: nextItemIndex,
                        showConfirmed: false,
                        showError: false,
                    })
                }, 1000)
            })
        }
    }

    back() {
        const previousItemIndex = this.state.currentItemIndex - 1

        this.setState({
            currentItemIndex: previousItemIndex,
        })
    }

    onEnter() {
        this.keyDownListener = document.addEventListener('keydown', (event) => {
            if (isValidBarcodeCharacter(event.key)) {
                this.buffer += event.key
            }

            if (event.key == 'Enter') {
                if (this.isValidScan(this.buffer)) {
                    if (this.state.soundOn) {
                        this.scanSuccessSound.play()
                    }

                    this.next()
                } else {
                    if (this.state.soundOn) {
                        this.scanErrorSound.play()
                    }

                    this.setState({ showError: true })
                }

                this.buffer = ''
            }
        })
    }

    onExit() {
        document.removeEventListener('keydown', this.keyDownListener)
        this.keyDownListener = null

        if (this.state.complete) {
            this.setState({
                complete: false,
                currentItemIndex: 0,
            })
        }
    }

    isValidScan(scannedValue) {
        const currentItem = this.state.items[this.state.currentItemIndex]

        return scannedValue == currentItem.barcode
    }

    handleToggleSound(event) {
        this.setState({ soundOn: event.target.checked })
    }

    render() {
        const { classes, language, open, handleClose } = this.props

        const item = this.state.items[this.state.currentItemIndex]

        return (
            <Dialog
                classes={{ paper: classes.dialog }}
                open={open}
                maxWidth="xl"
                fullWidth={true}
                onEnter={this.onEnter}
                onExit={this.onExit}
            >
                <DialogTitle className={classes.dialogTitle}>
                    {gettext('Gather pick list items')}
                </DialogTitle>
                {this.state.loading && !this.state.complete && (
                    <DialogContent className={classes.loadingDialogContent}>
                        <CircularProgress
                            className={classes.initialLoadingSpinner}
                            variant="indeterminate"
                            size={60}
                            color="secondary"
                        />
                    </DialogContent>
                )}
                {this.state.complete && (
                    <DialogContent className={classes.loadingDialogContent}>
                        <div className={classes.allConfirmed}>
                            <CheckCircleIcon className={classes.allConfirmedIcon} color="primary" />
                            <Typography variant="h5" className={classes.allConfirmedIconText}>
                                {gettext('Confirmed all items in pick list')}
                            </Typography>
                        </div>
                    </DialogContent>
                )}
                {!this.state.loading && !this.state.complete && (
                    <DialogContent className={classes.dialogContent}>
                        {this.state.items.length > 0 && (
                            <div className={classes.stockUnitInfo}>
                                <img className={classes.coverImage} src={item.cover_image} />
                                <div className={classes.rightPanel}>
                                    <Typography variant="h5" className={classes.productName}>
                                        {item.product_name}
                                    </Typography>
                                    <div className={classes.stockUnitSection}>
                                        <div className={classes.stockUnitSubSection}>
                                            <Typography
                                                className={classes.stockUnitSubSectionTitle}
                                                variant="h5"
                                            >
                                                {gettext('SKU')}
                                            </Typography>
                                            <Typography
                                                className={classes.stockUnitSubSectionText}
                                                variant="h5"
                                            >
                                                {item.sku}
                                            </Typography>
                                        </div>
                                        <div className={classes.stockUnitSubSection}>
                                            <Typography
                                                className={classes.stockUnitSubSectionTitle}
                                                variant="h5"
                                            >
                                                {gettext('SKU name')}
                                            </Typography>
                                            <Typography
                                                className={classes.stockUnitSubSectionText}
                                                variant="h5"
                                            >
                                                {item.sku_name || gettext('None')}
                                            </Typography>
                                        </div>
                                        <div className={classes.stockUnitSubSection}>
                                            <Typography
                                                className={classes.stockUnitSubSectionTitle}
                                                variant="h5"
                                            >
                                                {gettext('Barcode')}
                                            </Typography>
                                            <Typography
                                                className={classes.stockUnitSubSectionText}
                                                variant="h5"
                                            >
                                                {item.barcode || gettext('Not set')}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={classes.row}>
                                        <div className={classes.unitsRequired}>
                                            <Typography
                                                className={classes.unitsRequiredTitle}
                                                variant="h5"
                                            >
                                                {gettext('Units required')}
                                            </Typography>
                                            <Typography
                                                className={classes.unitsRequiredText}
                                                variant="h3"
                                            >
                                                {item.total_num_sold}
                                            </Typography>
                                        </div>
                                        {(this.state.showConfirmed || item.confirmed) && (
                                            <div className={classes.scannedMessage}>
                                                <CheckCircleIcon
                                                    className={classes.scanIcon}
                                                    color="primary"
                                                />
                                                <Typography
                                                    className={classes.confirmedText}
                                                    variant="h5"
                                                >
                                                    {gettext('Confirmed')}
                                                </Typography>
                                            </div>
                                        )}

                                        {this.state.showError && (
                                            <div className={classes.scannedMessage}>
                                                <WarningIcon
                                                    className={classes.scanIcon}
                                                    color="error"
                                                />
                                                <Typography
                                                    className={classes.scanErrorText}
                                                    variant="h5"
                                                >
                                                    {gettext(
                                                        'Does not match barcode for this product'
                                                    )}
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                    <Typography variant="h5" className={classes.prompt}>
                                        {gettext(
                                            'Once you have collected the required number of this product, scan the product or press Next'
                                        )}
                                    </Typography>
                                </div>
                            </div>
                        )}
                    </DialogContent>
                )}
                <DialogActions className={classes.dialogActions}>
                    <Button
                        className={classes.actionButton}
                        color="secondary"
                        onClick={handleClose}
                        size="large"
                    >
                        {gettext('Close')}
                    </Button>
                    <div className={classes.soundSwitchContainer}>
                        <Switch
                            checked={this.state.soundOn}
                            onChange={this.handleToggleSound}
                            color="primary"
                        />
                        <Typography variant="subtitle1" className={classes.soundSwitchText}>
                            {gettext('Sound')}
                        </Typography>
                    </div>
                    <div className={classes.spacer} />
                    {this.state.items.length > 0 && (
                        <Typography variant="subtitle1" className={classes.counterText}>{`${
                            this.state.currentItemIndex + 1
                        } of ${this.state.items.length}`}</Typography>
                    )}
                    <Button
                        className={classes.actionButton}
                        color="primary"
                        variant="outlined"
                        onClick={this.back}
                        disabled={this.state.loading}
                        size="large"
                    >
                        {gettext('Back')}
                    </Button>
                    <Button
                        className={classNames(classes.actionButton, classes.nextButton)}
                        color="primary"
                        variant="contained"
                        onClick={this.handleManualConfirm}
                        disabled={this.state.loading}
                        size="large"
                    >
                        {gettext('Next')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(PickListProcessor)
