import { useWatch } from 'react-hook-form'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useMutation } from '@tanstack/react-query'
import Cookies from 'js-cookie'

import { useProductFormContext } from '~/products/hooks/useProductForm'
import { useProductPageActions } from '~/products/hooks/useProductPageStore'
import { useAppSelector } from '~/store'

type Props = {
    open: boolean
    channelProductIndex: number
    onDeleted: () => void
}

function useDeleteChannelProductMutation() {
    const channelProductAPIURL = useAppSelector(
        (state) => state.initial.endpoints.channelProductAPIURL
    )

    const { mutate, error, isLoading } = useMutation<void, Error, number>({
        mutationFn: async (channelProductId: number) => {
            const res = await fetch(
                channelProductAPIURL.replace('channel_product_id', channelProductId.toString()),
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken') ?? '',
                    },
                }
            )

            if (!res.ok) {
                throw new Error('Failed to delete channel product')
            }
        },
    })

    return { mutate, error, isLoading }
}

function DialogChannelProductDelete({ open, channelProductIndex, onDeleted }: Props) {
    const { mutate, error, isLoading } = useDeleteChannelProductMutation()
    const { resetSelectedChannelProductIndex, closeDialog } = useProductPageActions()
    const { control } = useProductFormContext()
    const channelProductForDeletion = useWatch({
        control,
        name: `channel_products.${channelProductIndex}`,
    })

    function deleteChannelProduct() {
        if (typeof channelProductForDeletion.id === 'string') {
            // New channel product, no need to call API
            onDeleted()
            return
        }

        mutate(channelProductForDeletion.id, {
            onSuccess: () => {
                onDeleted()
            },
        })
    }

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth="xs"
            TransitionProps={{ onExited: resetSelectedChannelProductIndex }}
            onClose={closeDialog}
        >
            <DialogTitle>{gettext('Delete confirmation')}</DialogTitle>
            <DialogContent>
                <Typography gutterBottom variant="body2">
                    {channelProductForDeletion.id
                        ? interpolate(
                              gettext(
                                  'Are you sure you want to remove this channel product? The item will immediately be removed from %(shop)s - %(channel)s.'
                              ),
                              {
                                  shop: channelProductForDeletion.shop.shop_name,
                                  channel: channelProductForDeletion.shop.channel.name,
                              },
                              true
                          )
                        : gettext('Are you sure you want to remove this channel product?')}
                </Typography>

                {error && <Typography color="error">{error.message}</Typography>}
            </DialogContent>

            <DialogActions>
                {isLoading && <CircularProgress size={22} color="secondary" />}
                <Button color="secondary" disabled={isLoading} onClick={closeDialog}>
                    {gettext('Cancel')}
                </Button>
                <Button
                    color="error"
                    variant="outlined"
                    disabled={isLoading}
                    onClick={deleteChannelProduct}
                >
                    {gettext('Delete')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogChannelProductDelete
