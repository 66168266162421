import React from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CancelIcon from '@material-ui/icons/Cancel'
import InfoIcon from '@material-ui/icons/Info'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'
import classNames from 'classnames'
import Cookie from 'js-cookie'
import moment from 'moment'
import PropTypes from 'prop-types'

import { getPath } from '../../oakra/components/base-app'
import { get, post } from '../../tools/request'
import { debounce, getTranslation, translationsIncludeTerm, copyObject } from '../../tools/utils'
import ProductSelector from '../containers/product-selector'

import 'moment/locale/en-gb'
import 'moment/locale/th'

const styles = (theme) => ({
    section: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 20,
        marginBottom: 20,
    },
    sectionTitle: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid ' + grey[200],
        marginLeft: -20,
        marginRight: -20,
        paddingLeft: 20,
        paddingBottom: 10,
    },
    titleMessage: {
        marginLeft: 20,
        color: grey[500],
    },
    textField: {
        marginRight: 25,
    },
    nameField: {
        width: 250,
    },
    discountFields: {
        display: 'flex',
        alignItems: 'baseline',
        padding: '20px 10px',
    },
    channelDiscountMessage: {
        alignSelf: 'center',
    },
    clearIcon: {
        fontSize: 20,
    },
    orText: {
        marginRight: 20,
        color: grey[500],
    },
    toolbar: {
        position: 'fixed',
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
    },
    tableHeader: {
        borderTop: '1px solid ' + grey[200],
    },
    tableCell: {
        padding: '5px 12px',
    },
    tableCellSmallText: {
        padding: '5px 12px',
        fontSize: 12,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    checkboxColumnHeader: {
        width: 70,
    },
    checkboxRowCell: {
        padding: '0 12px',
    },
    channelIcon: {
        width: 50,
        marginBottom: 5,
        display: 'block',
    },
    originalPriceColumn: {
        textAlign: 'center',
    },
    bottomPagination: {
        marginBottom: 50,
    },
    bottomBar: {
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        backgroundColor: 'white',
        marginLeft: -20,
        marginRight: -20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid ' + grey[200],
        zIndex: 999,
        justifyContent: 'space-between',
        width: 'calc(100% - 200px)',
    },
    saveActions: {
        display: 'flex',
        alignItems: 'center',
    },
    savedMessage: {
        color: grey[500],
        marginRight: 15,
    },
    hasErrorsMessage: {
        color: theme.palette.error.main,
        marginRight: 15,
    },
    saveProgress: {
        marginRight: 15,
    },
    saveButton: {
        color: 'white',
    },
    noProductsMessage: {
        textAlign: 'center',
        paddingTop: 75,
        paddingBottom: 75,
        paddingLeft: 24,
    },
    noProductsText: {
        color: grey[400],
    },
    tableAddButton: {
        marginTop: 20,
    },
    channelIconImg: {
        display: 'inline',
        paddingRight: 4,
        height: 20,
        marginRight: 2,
    },
    channelIconText: {
        fontSize: 12,
        fontWeight: 500,
    },
    channelTitleCell: {
        padding: '14px 10px 10px',
    },
    channelTitleBordered: {
        borderLeft: '1px solid #eee',
    },
    channelTitleBlock: {
        display: 'flex',
        alignItems: 'center',
    },
    truncatedDescription: {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        'word-break': 'none',
        overflow: 'hidden',
        hyphens: 'auto',
    },
})

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        minHeight: 67,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    searchBar: {
        width: 350,
        marginLeft: 20,
        marginTop: 0,
        marginBottom: 0,
    },
    bulkField: {
        marginRight: 0,
        marginLeft: 15,
        marginTop: 0,
        marginBottom: 0,
        width: 150,
    },
    spacer: {
        flex: '1 1 auto',
    },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },
    topPagination: {},
    title: {
        flex: '0 0 auto',
    },
    toolbarItems: {
        display: 'flex',
        alignItems: 'baseline',
    },
    actions: {
        display: 'flex',
        color: theme.palette.text.secondary,
    },
    addButton: {
        height: 38,
        alignSelf: 'center',
        width: 175,
    },
})

const columns = [
    { id: 'image', align: 'left', label: '', sort: false, width: 80 },
    { id: 'name', align: 'left', label: gettext('Name'), sort: false, width: 280 },
    { id: 'stock_unit', align: 'left', label: gettext('Stock unit'), sort: true, width: 150 },
    { id: 'percent_discount', align: 'left', label: gettext('% discount'), sort: true, width: 90 },
]

const sortByShop = (channelStockUnitDiscounts) => {
    return channelStockUnitDiscounts.sort((a, b) => {
        return a.shopID > b.shopID ? 1 : b.shopID > a.shopID ? -1 : 0
    })
}

const sortShopsByID = (shops) => {
    return shops.sort((shopA, shopB) => {
        return shopA.id > shopB.id ? 1 : shopB.id > shopA.id ? -1 : 0
    })
}

// There may be multile channel stock unit discounts for one shop, this function take 1 for 1 shop
const filterDuplicate = (stockUnitDiscount) => {
    const existingShopIDs = new Set()
    const newStockUnitDiscount = copyObject(stockUnitDiscount)
    newStockUnitDiscount.channel_stock_unit_discounts =
        newStockUnitDiscount.channel_stock_unit_discounts.filter((csud) => {
            if (existingShopIDs.has(csud.shopID)) {
                return false
            } else {
                existingShopIDs.add(csud.shopID)
                return true
            }
        })
    return newStockUnitDiscount
}

const rowsPerPage = 25

class DiscountProductTableHeadWithoutStyle extends React.PureComponent {
    render() {
        const { onSelectAllClick, numSelected, rowCount, shops, classes } = this.props

        return (
            <TableHead className={classes.tableHeader}>
                <TableRow>
                    {[{ width: 70 }, ...columns].map(({ width }, index) => (
                        <TableCell {...(width && { width })} key={index}></TableCell>
                    ))}
                    {shops.map((shop) => (
                        <TableCell
                            className={classNames(
                                classes.channelTitleBordered,
                                classes.channelTitleCell
                            )}
                            colSpan={2}
                            key={shop.id}
                        >
                            <div className={classes.channelTitleBlock}>
                                <img
                                    className={classes.channelIconImg}
                                    src={shop.channel.channel_square_icon_url}
                                />
                                <Typography className={classes.channelIconText}>
                                    {shop.shop_name}
                                </Typography>
                            </div>
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    <TableCell className={classes.tableCell}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount !== 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {columns.map((column) => {
                        return (
                            <TableCell
                                className={classes.tableCellSmallText}
                                key={column.id}
                                align={column.align}
                                width={column.width}
                            >
                                {column.label}
                            </TableCell>
                        )
                    }, this)}
                    {shops.map((shop) => (
                        <React.Fragment key={shop.channel.id}>
                            <TableCell
                                className={classNames(
                                    classes.tableCellSmallText,
                                    classes.channelTitleBordered
                                )}
                                align="left"
                                width={75}
                            >
                                {gettext('Price')}
                            </TableCell>
                            <TableCell
                                className={classes.tableCellSmallText}
                                align="left"
                                width={75}
                            >
                                {gettext('Discount price')}
                            </TableCell>
                        </React.Fragment>
                    ))}
                </TableRow>
            </TableHead>
        )
    }
}

DiscountProductTableHeadWithoutStyle.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const DiscountProductTableHead = withStyles(styles)(DiscountProductTableHeadWithoutStyle)

class DiscountProductsTableToolbarWithoutStyle extends React.PureComponent {
    render() {
        const {
            store,
            channel,
            numSelected,
            selectedShopID,
            handleChangePage,
            handleAddProducts,
            handleRemoveProducts,
            searchHandler,
            page,
            count,
            classes,
            loading,
            bulkDiscountPercentage,
            handleBulkDiscountPercentChanged,
            disabledBulkEdit,
        } = this.props

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                {this.props.loading && (
                    <LinearProgress className={classes.progress} color="secondary" />
                )}
                <div className={classes.title}>
                    {numSelected > 0 ? (
                        <Typography color="inherit" variant="subtitle1">
                            {interpolate(pgettext('Number selected', '%s selected'), [numSelected])}
                        </Typography>
                    ) : (
                        <Typography variant="subtitle1" className={classes.sectionTitle}>
                            {gettext('Discount products')}
                        </Typography>
                    )}
                </div>
                <TextField
                    id="search"
                    type="search"
                    className={classes.searchBar}
                    margin="normal"
                    onChange={searchHandler}
                    variant="outlined"
                    placeholder={gettext('Search products')}
                    InputProps={{ style: { height: '38px' } }}
                />
                <TextField
                    className={classes.bulkField}
                    variant="outlined"
                    value={bulkDiscountPercentage}
                    onChange={handleBulkDiscountPercentChanged}
                    label={gettext('Bulk discount')}
                    margin="normal"
                    type="number"
                    disabled={disabledBulkEdit}
                    inputProps={{
                        style: { textAlign: 'right' },
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        style: { height: '38px' },
                    }}
                />
                <Tooltip
                    title={gettext(
                        'Bulk discount will be applied to all products in this discount.'
                    )}
                    placement="right"
                >
                    <IconButton>
                        <InfoIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    {numSelected > 0 ? (
                        <React.Fragment>
                            <Button color="secondary" onClick={handleRemoveProducts}>
                                {gettext('Remove from discount')}
                            </Button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.addButton}
                                onClick={handleAddProducts}
                                disabled={!store || Boolean(channel)}
                            >
                                <AddIcon />
                                {gettext('Add products')}
                            </Button>
                            <TablePagination
                                className={classes.topPagination}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': gettext('Previous'),
                                }}
                                nextIconButtonProps={{
                                    'aria-label': gettext('Next'),
                                }}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[rowsPerPage]}
                            />
                        </React.Fragment>
                    )}
                </div>
            </Toolbar>
        )
    }
}

DiscountProductsTableToolbarWithoutStyle.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
}

const DiscountProductsTableToolbar = withStyles(toolbarStyles)(
    DiscountProductsTableToolbarWithoutStyle
)

class Discount extends React.PureComponent {
    constructor(props) {
        super(props)

        const storeID = this.props.match.params.store_id

        this.state = {
            discount: {
                name: '',
                start_date: null,
                end_date: null,
                num_products: '',
                store: storeID ? parseInt(storeID, 10) : null,
                channel: null,
                status: 'upcoming',
                is_active: true,
            },
            errors: {
                name: '',
                start_date: '',
                end_date: '',
                bulkDiscountPercentage: '',
                bulkDiscountAmount: '',
            },
            shops: [],
            bulkDiscountPercentage: 0,
            bulkDiscountAmount: 0,
            productDiscounts: [],
            newDiscountProducts: [],
            selected: [],
            page: 0,
            loading: true,
            newDiscount: props.location.pathname.includes('create'),
            displayProductSelector: false,
            saving: false,

            maxPageFetched: 0,
            count: 0,
            next: '',
            previous: '',
            addedProductIDs: new Set(),
            removedProductIDs: new Set(),
            allProductIDs: new Set(),
            discountPricesData: {},
        }

        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleDateTimeChange = this.handleDateTimeChange.bind(this)
        this.clearDatePicker = this.clearDatePicker.bind(this)
        this.fetchDiscount = this.fetchDiscount.bind(this)
        this.fetchProductDiscounts = this.fetchProductDiscounts.bind(this)
        this.handleAddProducts = this.handleAddProducts.bind(this)
        this.handleRemoveProducts = this.handleRemoveProducts.bind(this)
        this.handleChecked = this.handleChecked.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this)
        this.handleSelectedProducts = this.handleSelectedProducts.bind(this)
        this.handleDiscountPercentChanged = this.handleDiscountPercentChanged.bind(this)
        this.handleDiscountPriceChanged = this.handleDiscountPriceChanged.bind(this)
        this.handleBulkDiscountPercentChanged = this.handleBulkDiscountPercentChanged.bind(this)
        this.isSelected = this.isSelected.bind(this)
        this.searchHandler = this.searchHandler.bind(this)
        this.searchProducts = this.searchProducts.bind(this)

        this.saveHandler = this.saveHandler.bind(this)
        this.createDiscount = this.createDiscount.bind(this)
        this.saveProductDiscounts = this.saveProductDiscounts.bind(this)
        this.searchProductsDebounced = debounce(this.searchProducts, 500)
    }

    componentDidMount() {
        const copyDiscountId = this.getCopyFromDiscountId()
        if (this.state.newDiscount) {
            this.setState({ loading: false })
        } else {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.fetchDiscount(() => {})

            this.fetchProductDiscounts()
        }
    }

    getCopyFromDiscountId = () => {
        const location = this.props.location
        const queryParams = new URLSearchParams(location.search)
        return queryParams.get('copy_discount_id')
    }

    fetchDiscount(callback) {
        const { discount_id } = this.props.match.params
        const { discountAPIURL } = this.props

        fetch(discountAPIURL.replace('discount_id', discount_id))
            .then((response) => response.json())
            .then(({ product_pks, ...discount }) => {
                this.setState({ discount, allProductIDs: new Set(product_pks) }, callback)
            })
    }

    fetchProductDiscounts(url, page = 0, searchTerm = null, callback = null) {
        if (!url) {
            const { discount_id } = this.props.match.params
            const { simplifiedProductDiscountsAPIURL } = this.props
            url = simplifiedProductDiscountsAPIURL.replace('discount_id', discount_id)
        }
        this.setState({ loading: true })
        let { shops } = this.state
        const shopIDs = new Set(shops.map((shop) => shop.id))
        const requestParams = searchTerm ? { search: searchTerm } : null
        get(url, requestParams).then((data) => {
            let productDiscounts = data.results
            productDiscounts.forEach((pd) => {
                pd.stock_unit_discounts.forEach((sud) => {
                    sud.channel_stock_unit_discounts.forEach((csud) => {
                        const shop = csud.channel_stock_unit.channel_product.shop
                        if (!shopIDs.has(shop.id)) {
                            shopIDs.add(shop.id)
                            shops.push(shop)
                        }
                        csud.shopID = shop.id
                        csud.errors = {}
                    })

                    const allPricesSame = sud.channel_stock_unit_discounts.every(
                        (csud, i, arr) => csud.price === arr[0].price
                    )

                    if (allPricesSame && sud.channel_stock_unit_discounts.length > 0) {
                        const channelStockUnitDiscount = sud.channel_stock_unit_discounts[0]
                        const percentDiscount =
                            1 -
                            channelStockUnitDiscount.price /
                                channelStockUnitDiscount.channel_stock_unit.price
                        sud.percentDiscount = parseInt(Math.round(percentDiscount * 100), 10)
                    } else {
                        sud.percentDiscount = null
                    }
                })
            })

            const csuDiscountPriceMap = new Map()
            productDiscounts.forEach((pd) => {
                pd.stock_unit_discounts.forEach((sud) => {
                    sud.channel_stock_unit_discounts.forEach((csud) => {
                        csuDiscountPriceMap.set(csud.channel_stock_unit.id, csud.price)

                        const shopID = csud.channel_stock_unit.channel_product.shop.id
                        if (!shopIDs.has(shopID)) {
                            shopIDs.add(shopID)
                            shops.push(csud.channel_stock_unit.channel_product.shop)
                        }
                    })
                })
            })
            shops = sortShopsByID(shops)

            productDiscounts = productDiscounts.map((pd) => {
                const stockUnitDiscounts = this.makePlaceholderStockUnitDiscounts(
                    pd.product.stock_units,
                    shops,
                    pd.product,
                    csuDiscountPriceMap
                )
                return {
                    ...pd,
                    stock_unit_discounts: stockUnitDiscounts,
                }
            })
            if (page > 0) {
                productDiscounts = this.state.productDiscounts.concat(productDiscounts)
            }

            this.setState(
                {
                    productDiscounts,
                    shops: shops,
                    loading: false,
                    page: page,
                    maxPageFetched: page,
                    count: data.count,
                    next: data.next,
                    previous: data.previous,
                },
                callback
            )
        })
    }

    addPlaceholderStockUnitDiscounts(productDiscounts, shops) {
        productDiscounts.forEach((pd) => {
            const stockUnitIdsInStockUnitDiscounts = pd.stock_unit_discounts.map(
                (sud) => sud.stock_unit.id
            )
            const stockUnitsWithMissingStockUnitDiscounts = pd.product.stock_units.filter(
                (su) => !stockUnitIdsInStockUnitDiscounts.includes(su.id)
            )
            if (stockUnitsWithMissingStockUnitDiscounts.length) {
                const placeholderStockUnitDiscounts = this.makePlaceholderStockUnitDiscounts(
                    stockUnitsWithMissingStockUnitDiscounts,
                    shops,
                    pd.product,
                    null
                )
                const allStockUnitDiscounts = [
                    ...pd.stock_unit_discounts,
                    ...placeholderStockUnitDiscounts,
                ]
                pd.stock_unit_discounts = allStockUnitDiscounts
            }
        })
    }

    handleNameChange(event) {
        const discount = copyObject(this.state.discount)
        const name = event.target.value
        discount.name = name

        const errors = copyObject(this.state.errors)

        if (name) {
            errors.name = ''
        } else {
            errors.name = gettext('Cannot be empty')
        }

        this.setState({ discount, errors })
    }

    handleDateTimeChange(field) {
        const startDateTooEarlyMessage = 'Start time must be after %(minStartDate)s today'
        const startDateTooEarlyMessageFormat = ngettext(
            startDateTooEarlyMessage,
            startDateTooEarlyMessage,
            1
        )

        return (date) => {
            const errors = copyObject(this.state.errors)

            const startDate = this.state.discount.start_date
            const endDate = this.state.discount.end_date
            const minStartDate = moment().add(2, 'hours')

            const discount = copyObject(this.state.discount)

            if (
                (field == 'start_date' && date.isAfter(endDate)) ||
                (field == 'end_date' && date.isBefore(startDate))
            ) {
                errors.start_date = gettext('Cannot be after end date')
                errors.end_date = gettext('Cannot be before start date')
            } else if (date.isBefore(minStartDate)) {
                errors.start_date = interpolate(
                    startDateTooEarlyMessageFormat,
                    { minStartDate: minStartDate.format('HH:mm') },
                    true
                )
            } else {
                errors.start_date = ''
                errors.end_date = ''
                discount[field] = date.format()
            }

            // automatically set end_date to start date if we're setting start_date and end_date is empty
            if (field == 'start_date' && discount.end_date == null) {
                discount.end_date = discount.start_date
            }

            this.setState({ discount, errors })
        }
    }

    handleChecked(event, checked, productDiscount) {
        const selectedProductIDs = this.state.selected.map((pd) => pd.product.id)
        let newSelected = copyObject(this.state.selected)

        if (checked) {
            if (!selectedProductIDs.includes(productDiscount.product.id)) {
                newSelected.push(productDiscount)
            }
        } else {
            newSelected = newSelected.filter((pd) => pd.product.id != productDiscount.product.id)
        }

        this.setState({ selected: newSelected })
    }

    handleChangePage(_, page) {
        if (page <= this.state.maxPageFetched) {
            this.setState({ page })
        } else {
            this.fetchProductDiscounts(this.state.next, page)
        }
    }

    handleSelectAllClick(event, checked) {
        if (checked) {
            this.setState((state) => ({ selected: copyObject(this.state.productDiscounts) }))
        } else {
            this.setState({ selected: [] })
        }
    }

    handleAddProducts() {
        this.setState({ displayProductSelector: true })
    }

    handleRemoveProducts() {
        const { addedProductIDs, removedProductIDs, selected, newDiscountProducts } = this.state
        selected.map((pd) => {
            const productID = pd.product.id
            if (addedProductIDs.has(productID)) {
                addedProductIDs.delete(productID)
            } else {
                removedProductIDs.add(productID)
            }
        })
        this.setState({
            selected: [],
            removedProductIDs,
            addedProductIDs,
            newDiscountProducts: newDiscountProducts.filter((pd) =>
                addedProductIDs.has(pd.product.id)
            ),
        })
    }

    clearDatePicker(field) {
        const discount = copyObject(this.state.discount)

        return (e) => {
            e.stopPropagation()
            discount[field] = null
            this.setState({ discount })
        }
    }

    handleSelectedProducts(products) {
        const shopIDs = new Set(this.state.shops.map((s) => s.id))
        let shops = copyObject(this.state.shops)
        const { removedProductIDs, addedProductIDs } = this.state

        const newProducts = []
        products.forEach((p) => {
            const productID = p.id
            p.channel_products.forEach((cp) => {
                const shopID = cp.shop.id
                if (!shopIDs.has(shopID)) {
                    shopIDs.add(shopID)
                    shops.push(cp.shop)
                }
            })
            if (removedProductIDs.has(productID)) {
                // this product's removed before and now added back
                removedProductIDs.delete(p.id)
            } else {
                addedProductIDs.add(p.id)
                newProducts.push(p)
            }
        })
        shops = sortShopsByID(shops)

        const addedProductDiscounts = newProducts.map((p) => {
            const stockUnitDiscounts = this.makePlaceholderStockUnitDiscounts(
                p.stock_units,
                shops,
                p,
                null
            )
            const newProductDiscount = {
                product: p,
                stock_unit_discounts: stockUnitDiscounts,
            }
            return newProductDiscount
        })

        let newDiscountProducts = copyObject(this.state.newDiscountProducts)
        newDiscountProducts = newDiscountProducts.concat(addedProductDiscounts)

        this.setState({
            shops,
            displayProductSelector: false,
            addedProductIDs,
            removedProductIDs,
            newDiscountProducts,
        })
    }

    makePlaceholderStockUnitDiscounts(stockUnits, shops, product, priceMap) {
        return stockUnits.map((su) => {
            const channelStockUnitDiscounts = []

            shops.forEach((shop) => {
                const channelProducts = product.channel_products.filter(
                    (cp) => cp.shop.id === shop.id
                )

                if (channelProducts.length > 0) {
                    channelProducts.forEach((channelProduct) => {
                        const channelStockUnit = channelProduct.channel_stock_units.find(
                            (csu) => csu.stock_unit.id === su.id
                        )
                        if (channelStockUnit) {
                            // Need child channel_product on channel_stock_unit
                            // but must delete channel_stock_units on channel_product to avoid circular object
                            const channelProductCopy = copyObject(channelProduct)
                            delete channelProductCopy.channel_stock_units

                            channelStockUnit.channel_product = channelProductCopy
                            channelStockUnit.stock_unit = channelStockUnit.stock_unit.id

                            channelStockUnitDiscounts.push({
                                shopID: shop.id,
                                channel_stock_unit: channelStockUnit,
                                price: priceMap?.get(channelStockUnit.id) || null,
                                errors: {},
                            })
                        }
                    })
                } else {
                    channelStockUnitDiscounts.push({
                        shopID: shop.id,
                        channel_stock_unit: null,
                        price: null,
                        errors: {},
                    })
                }
            })

            sortByShop(channelStockUnitDiscounts)

            return {
                stock_unit: su,
                channel_stock_unit_discounts: channelStockUnitDiscounts,
                percentDiscount: 0,
                errors: {},
            }
        })
    }

    handleBulkDiscountPercentChanged(event) {
        let value = event.target.value

        if (value.includes('-') || value.length > 2) {
            return
        }

        const { discountPricesData } = this.state
        const productDiscounts = copyObject(this.state.productDiscounts)
        const newDiscountProducts = copyObject(this.state.newDiscountProducts)
        const discountPercent = parseFloat(value) / 100

        productDiscounts.concat(newDiscountProducts).forEach((pd) => {
            pd.stock_unit_discounts.forEach((sud) => {
                if (value) {
                    sud.channel_stock_unit_discounts
                        .filter((csud) => Boolean(csud.channel_stock_unit))
                        .forEach((csud) => {
                            let newDiscountPrice = Math.round(
                                csud.channel_stock_unit.price * (1 - discountPercent)
                            )

                            if (newDiscountPrice < 0) {
                                newDiscountPrice = 0
                            }

                            csud.errors = {}
                            csud.price = newDiscountPrice
                            discountPricesData[`${sud.stock_unit.id}_${csud.shopID}`] =
                                newDiscountPrice
                        })
                }

                sud.percentDiscount = parseFloat(value)
            })
        })

        if (value) {
            value = parseFloat(value)
        }

        this.setState({
            productDiscounts,
            newDiscountProducts,
            discountPricesData,
            bulkDiscountPercentage: value,
        })
    }

    updateDiscountsWithNewPercent = (productDiscounts, percent, productID, sku) => {
        const productDiscount = productDiscounts.find((pd) => pd.product.id == productID)
        if (!productDiscount) return
        const stockUnitDiscount = productDiscount.stock_unit_discounts.find(
            (sud) => sud.stock_unit.sku == sku
        )
        if (!stockUnitDiscount) return
        const channelStockUnitDiscounts = stockUnitDiscount.channel_stock_unit_discounts
        const discountPercent = !percent ? 0 : parseFloat(percent) / 100
        const { discountPricesData } = this.state

        channelStockUnitDiscounts
            .filter((csud) => Boolean(csud.channel_stock_unit))
            .forEach((csud) => {
                let newDiscountPrice = Math.round(
                    csud.channel_stock_unit.price * (1 - discountPercent)
                )

                if (newDiscountPrice < 0) {
                    newDiscountPrice = 0
                }

                csud.price = newDiscountPrice
                csud.errors = {}
                discountPricesData[`${stockUnitDiscount.stock_unit.id}_${csud.shopID}`] =
                    newDiscountPrice
            })

        stockUnitDiscount.percentDiscount = parseInt(percent)
        this.setState({ discountPricesData })
    }

    handleDiscountPercentChanged(productID, sku) {
        return (event) => {
            const value = event.target.value
            if (value.includes('-') || value.length > 2) {
                return
            }

            const productDiscounts = copyObject(this.state.productDiscounts)
            this.updateDiscountsWithNewPercent(productDiscounts, value, productID, sku)

            const newDiscountProducts = copyObject(this.state.newDiscountProducts)
            this.updateDiscountsWithNewPercent(newDiscountProducts, value, productID, sku)
            this.setState({
                productDiscounts: productDiscounts,
                newDiscountProducts: newDiscountProducts,
            })
        }
    }

    updateDiscountsWithNewPrice = (productDiscounts, newDiscountPrice, productID, sku, shopID) => {
        const productDiscount = productDiscounts.find((pd) => pd.product.id === productID)
        if (!productDiscount) return
        const stockUnitDiscount = productDiscount.stock_unit_discounts.find(
            (sud) => sud.stock_unit.sku === sku
        )
        if (!stockUnitDiscount) return
        const channelStockUnitDiscounts = stockUnitDiscount.channel_stock_unit_discounts.filter(
            (csud) => csud.shopID === shopID
        )

        channelStockUnitDiscounts.forEach((channelStockUnitDiscount) => {
            channelStockUnitDiscount.price = newDiscountPrice
            channelStockUnitDiscount.errors = {}

            if (newDiscountPrice === '') {
                channelStockUnitDiscount.price = null
            } else if (newDiscountPrice > channelStockUnitDiscount.channel_stock_unit.price) {
                channelStockUnitDiscount.errors.discountAmount = gettext(
                    'Larger than original price'
                )
            }
        })

        stockUnitDiscount.percentDiscount = null
    }

    handleDiscountPriceChanged(product, stockUnit, shopID) {
        const productID = product.id
        const sku = stockUnit.sku

        return (event) => {
            if (event.target.value.includes('-')) {
                return
            }

            const { discountPricesData } = this.state
            discountPricesData[`${stockUnit.id}_${shopID}`] =
                event.target.value === '' ? null : parseFloat(event.target.value)
            this.setState({
                discountPricesData,
            })

            const newDiscountProducts = copyObject(this.state.newDiscountProducts)
            const productDiscounts = copyObject(this.state.productDiscounts)
            const newDiscountPrice = event.target.value === '' ? '' : parseFloat(event.target.value)

            this.updateDiscountsWithNewPrice(
                productDiscounts,
                newDiscountPrice,
                productID,
                sku,
                shopID
            )

            this.updateDiscountsWithNewPrice(
                newDiscountProducts,
                newDiscountPrice,
                productID,
                sku,
                shopID
            )

            this.setState({
                productDiscounts,
                newDiscountProducts,
            })
        }
    }

    isSelected(productID) {
        const selectedProductIDs = this.state.selected.map((pd) => pd.product.id)
        return selectedProductIDs.includes(productID)
    }

    searchProducts(searchTerm) {
        this.setState({ searchTerm })
        this.fetchProductDiscounts(null, 0, searchTerm)
    }

    searchHandler(event) {
        const searchTerm = event.target.value

        this.searchProductsDebounced(searchTerm)
    }

    productDiscountHasErrors = () => {
        const discountWithError = this.state.productDiscounts.filter((productDiscount) => {
            const stockUnitDiscountWithErrors = productDiscount.stock_unit_discounts.filter(
                (stockUnitDiscount) => {
                    const csuds = stockUnitDiscount.channel_stock_unit_discounts.filter((csud) => {
                        return csud?.errors && Object.keys(csud.errors).length > 0
                    })
                    return csuds?.length > 0
                }
            )
            return stockUnitDiscountWithErrors?.length > 0
        })

        return discountWithError?.length > 0
    }

    discountComplete = () => {
        const {
            name: name_error,
            start_date: start_date_error,
            end_date: end_date_error,
        } = this.state.errors
        const { name, start_date, end_date } = this.state.discount

        return (
            (!name_error || !start_date_error || !end_date_error) &&
            !!name &&
            !!start_date &&
            !!end_date
        )
    }

    createDiscount(copyDiscountId) {
        const { discountCreateAPIURL } = this.props
        const payload = {
            ...this.state.discount,
        }
        if (copyDiscountId) {
            payload.copy_discount_id = copyDiscountId
        }
        return post(discountCreateAPIURL, payload)
    }

    saveProductDiscounts() {
        const { updateProductDiscountsAPIURL } = this.props
        const productDiscounts = copyObject(this.state.productDiscounts)

        productDiscounts.forEach((pd) => {
            delete pd.product.channel_products
            delete pd.product.stock_units

            pd.discount = this.state.discount.id
            pd.stock_unit_discounts.forEach((sud) => {
                sud.channel_stock_unit_discounts = sud.channel_stock_unit_discounts.filter((csud) =>
                    Boolean(csud.channel_stock_unit)
                )
            })
        })

        const { discountPricesData, removedProductIDs } = this.state
        const requestData = {
            channel_stock_unit_discounts: Object.entries(discountPricesData).map(([key, value]) => {
                const subs = key.split('_')
                return {
                    stock_unit_id: Number(subs[0]),
                    shop_id: Number(subs[1]),
                    price: value,
                }
            }),
            deleted_product_ids: Array.from(removedProductIDs),
        }

        return fetch(updateProductDiscountsAPIURL.replace('discount_pk', this.state.discount.id), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRFToken': Cookie.get('csrftoken'),
            },
            body: JSON.stringify(requestData),
        })
    }

    saveHandler() {
        window.analytics.track('Update discount', {
            page: window.location.href,
            feature: 'promotion',
            sub_feature: 'discount',
            trigger: 'button click',
        })

        const copyDiscountId = this.getCopyFromDiscountId()

        this.setState({ saving: true }, () => {
            if (this.state.newDiscount) {
                if (copyDiscountId) {
                    this.createDiscount(copyDiscountId).then((createdDiscount) => {
                        window.location.href = getPath('discount').replace(
                            'discount_id',
                            createdDiscount.id
                        )
                    })
                } else {
                    this.createDiscount().then((discount) => {
                        this.setState({ discount }, () => {
                            this.saveProductDiscounts().then(() => {
                                window.location.href = getPath('discounts')
                            })
                        })
                    })
                }
            } else {
                this.saveProductDiscounts().then(() => {
                    window.location.href = getPath('discounts')
                })
            }
        })
    }

    getDisplayProductDiscounts = () => {
        const { page, newDiscountProducts, productDiscounts, removedProductIDs } = this.state
        let displayedProductDiscounts = productDiscounts
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .filter((pd) => !removedProductIDs.has(pd.product.id))

        if (page > 0 || newDiscountProducts.length === 0) {
            return displayedProductDiscounts
        }

        // add new product discounts to first page
        const { searchTerm } = this.state
        let filteredNewDiscountProducts = newDiscountProducts
        if (searchTerm) {
            filteredNewDiscountProducts = newDiscountProducts.filter((pd) => {
                if (translationsIncludeTerm(pd.product.translations, searchTerm)) {
                    return true
                }

                return pd.stock_unit_discounts.some((sud) => {
                    if (
                        sud.stock_unit.name &&
                        sud.stock_unit.name.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                        return true
                    }

                    if (
                        sud.stock_unit.sku &&
                        sud.stock_unit.sku.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                        return true
                    }
                    return false
                })
            })
        }

        return filteredNewDiscountProducts.concat(displayedProductDiscounts)
    }

    getExistedProductIDs = () => {
        const { allProductIDs, addedProductIDs, removedProductIDs } = this.state
        return new Set(
            [...allProductIDs, ...addedProductIDs].filter(
                (productID) => !removedProductIDs.has(productID)
            )
        )
    }

    render() {
        const { classes, language, staticRoot, stores } = this.props
        const { selected, page, count, newDiscountProducts } = this.state

        const storeName = this.state.discount.store
            ? this.props.stores.find((store) => store.id == this.state.discount.store).name
            : ''

        const displayedProductDiscounts = this.getDisplayProductDiscounts()

        moment.locale(language)

        const channelName =
            this.state.discount.channel &&
            this.state.discount.channel.name.charAt(0).toUpperCase() +
                this.state.discount.channel.name.slice(1)
        const channelDiscountMessage = interpolate(
            gettext(
                'This discount was automatically generated from %s. It can be edited, but no further products may be added. Any price changes made will go live immediately.'
            ),
            [channelName]
        )
        const isAddingProductsDisabled = ['WooCommerce', 'Lazada'].includes(
            this.state.discount.channel?.name
        )

        const isCopy = !!this.getCopyFromDiscountId()

        let title
        if (this.state.newDiscount) {
            title = isCopy ? gettext('Copy Discount (%s)') : gettext('New Discount (%s)')
        } else {
            title = gettext('Discount (%s)')
        }
        title = interpolate(title, [storeName])

        return (
            <React.Fragment>
                <Paper className={classes.section}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>
                        {title}
                        <Tooltip
                            title={gettext(
                                'Name and dates cannot be changed after discount is saved.'
                            )}
                            placement="right"
                        >
                            <IconButton>
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                    <div className={classes.discountFields}>
                        <TextField
                            className={classNames(classes.nameField, classes.textField)}
                            label={gettext('Name')}
                            autoFocus
                            margin="dense"
                            name="name"
                            type="text"
                            variant="outlined"
                            value={this.state.discount.name}
                            onChange={this.handleNameChange}
                            error={Boolean(this.state.errors.name)}
                            helperText={this.state.errors.name}
                            disabled={!this.state.newDiscount}
                        />
                        {isAddingProductsDisabled ? (
                            <Typography className={classes.channelDiscountMessage} variant="body2">
                                {channelDiscountMessage}
                            </Typography>
                        ) : (
                            <React.Fragment>
                                <MuiPickersUtilsProvider
                                    className={classNames(classes.textField, classes.dateField)}
                                    utils={MomentUtils}
                                    locale={language}
                                    value={this.state.discount.start_date}
                                    moment={moment}
                                >
                                    <DateTimePicker
                                        value={this.state.discount.start_date}
                                        label={gettext('Start date')}
                                        margin="dense"
                                        className={classNames(classes.textField, classes.dateField)}
                                        onChange={this.handleDateTimeChange('start_date')}
                                        format="lll"
                                        disabled={!this.state.newDiscount}
                                        error={Boolean(this.state.errors.start_date)}
                                        helperText={this.state.errors.start_date}
                                        disablePast={true}
                                        maxDate={
                                            this.state.discount.end_date
                                                ? this.state.discount.end_date
                                                : moment().add(5, 'y')
                                        }
                                        inputVariant="outlined"
                                        InputProps={{
                                            endAdornment: this.state.newDiscount && (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={this.clearDatePicker('start_date')}
                                                    >
                                                        <CancelIcon className={classes.clearIcon} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider
                                    className={classNames(classes.textField, classes.dateField)}
                                    utils={MomentUtils}
                                    locale={language}
                                    value={this.state.discount.end_date}
                                    moment={moment}
                                >
                                    <DateTimePicker
                                        value={this.state.discount.end_date}
                                        label={gettext('End date')}
                                        margin="dense"
                                        className={classNames(classes.textField, classes.dateField)}
                                        onChange={this.handleDateTimeChange('end_date')}
                                        format="lll"
                                        disabled={!this.state.newDiscount}
                                        error={Boolean(this.state.errors.end_date)}
                                        helperText={this.state.errors.end_date}
                                        disablePast={true}
                                        maxDate={moment().add(5, 'y')}
                                        inputVariant="outlined"
                                        InputProps={{
                                            endAdornment: this.state.newDiscount && (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={this.clearDatePicker('end_date')}
                                                    >
                                                        <CancelIcon className={classes.clearIcon} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </React.Fragment>
                        )}
                    </div>
                </Paper>
                {(isAddingProductsDisabled || this.discountComplete()) && !isCopy && (
                    <Paper>
                        <DiscountProductsTableToolbar
                            className={classes.toolbar}
                            store={this.state.discount.store}
                            channel={this.state.discount.channel}
                            numSelected={selected.length}
                            handleChangePage={this.handleChangePage}
                            handleEndDiscountNow={this.handleEndDiscountNow}
                            handleRemoveProducts={this.handleRemoveProducts}
                            handleAddProducts={this.handleAddProducts}
                            bulkDiscountPercentage={this.state.bulkDiscountPercentage}
                            handleBulkDiscountPercentChanged={this.handleBulkDiscountPercentChanged}
                            disabledBulkEdit={Boolean(this.state.discount.channel)}
                            count={count}
                            page={page}
                            loading={this.state.loading}
                            searchHandler={this.searchHandler}
                        />
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <DiscountProductTableHead
                                    className={classes.tableHead}
                                    numSelected={selected.length}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    rowCount={this.state.productDiscounts.length}
                                    shops={this.state.shops}
                                />
                                <TableBody>
                                    {!this.state.loading &&
                                        displayedProductDiscounts.length === 0 && (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={8}
                                                    className={classNames(
                                                        classes.noProductsMessage,
                                                        classes.tableCell
                                                    )}
                                                >
                                                    <img
                                                        className={classes.noProductsIcon}
                                                        src={
                                                            staticRoot + 'web/img/product-gray.png'
                                                        }
                                                    />
                                                    <Typography
                                                        className={classes.noProductsText}
                                                        variant="subtitle1"
                                                    >
                                                        {gettext('No products found')}
                                                    </Typography>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        className={classes.tableAddButton}
                                                        onClick={this.handleAddProducts}
                                                        disabled={
                                                            !this.state.discount.store ||
                                                            isAddingProductsDisabled
                                                        }
                                                    >
                                                        <AddIcon />
                                                        {gettext('Add products')}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    {displayedProductDiscounts.map((productDiscount) => {
                                        const isSelected = this.isSelected(
                                            productDiscount.product.id
                                        )
                                        const numStockUnits =
                                            productDiscount.product.stock_units.length
                                        const firstStockUnitDiscount = filterDuplicate(
                                            productDiscount.stock_unit_discounts[0]
                                        )
                                        const restStockUnitDiscounts =
                                            productDiscount.stock_unit_discounts
                                                .slice(1)
                                                .map((stockUnitDiscount) => {
                                                    return filterDuplicate(stockUnitDiscount)
                                                })

                                        const productDesc = getTranslation(
                                            productDiscount.product,
                                            language,
                                            'name'
                                        )

                                        return (
                                            <React.Fragment key={productDiscount.product.id}>
                                                <TableRow
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={productDiscount.product.id}
                                                    selected={isSelected}
                                                >
                                                    <TableCell
                                                        padding="checkbox"
                                                        className={classes.tableCell}
                                                        rowSpan={numStockUnits}
                                                    >
                                                        <Checkbox
                                                            checked={isSelected}
                                                            onChange={(event, checked) =>
                                                                this.handleChecked(
                                                                    event,
                                                                    checked,
                                                                    productDiscount
                                                                )
                                                            }
                                                        />
                                                    </TableCell>

                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        className={classes.tableCell}
                                                        rowSpan={numStockUnits}
                                                    >
                                                        <img
                                                            className={classes.productImg}
                                                            src={
                                                                productDiscount.product
                                                                    .cover_image_url
                                                            }
                                                            height={50}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.tableCell}
                                                        rowSpan={numStockUnits}
                                                    >
                                                        <Typography
                                                            className={classes.truncatedDescription}
                                                            variant="body2"
                                                            title={productDesc}
                                                        >
                                                            {productDesc}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Typography variant="body2">
                                                            {firstStockUnitDiscount.stock_unit.sku}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <TextField
                                                            value={
                                                                firstStockUnitDiscount.percentDiscount ||
                                                                ''
                                                            }
                                                            onChange={this.handleDiscountPercentChanged(
                                                                productDiscount.product.id,
                                                                firstStockUnitDiscount.stock_unit
                                                                    .sku
                                                            )}
                                                            inputProps={{
                                                                style: {
                                                                    textAlign: 'right',
                                                                    fontSize: '0.9rem',
                                                                },
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </TableCell>
                                                    {firstStockUnitDiscount.channel_stock_unit_discounts.map(
                                                        (csud, index) => (
                                                            <React.Fragment key={index}>
                                                                <TableCell
                                                                    className={classNames(
                                                                        classes.tableCell,
                                                                        classes.originalPriceColumn
                                                                    )}
                                                                >
                                                                    <Typography variant="body2">
                                                                        {csud.channel_stock_unit
                                                                            ? Math.round(
                                                                                  csud
                                                                                      .channel_stock_unit
                                                                                      .price
                                                                              )
                                                                            : ''}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell
                                                                    className={classes.tableCell}
                                                                >
                                                                    {csud.channel_stock_unit && (
                                                                        <TextField
                                                                            value={
                                                                                csud.price
                                                                                    ? Math.round(
                                                                                          csud.price
                                                                                      )
                                                                                    : ''
                                                                            }
                                                                            onChange={this.handleDiscountPriceChanged(
                                                                                productDiscount.product,
                                                                                firstStockUnitDiscount.stock_unit,
                                                                                csud.shopID
                                                                            )}
                                                                            error={Boolean(
                                                                                csud.errors
                                                                                    .discountAmount
                                                                            )}
                                                                            helperText={
                                                                                csud.errors
                                                                                    .discountAmount
                                                                            }
                                                                            disabled={!csud}
                                                                            inputProps={{
                                                                                style: {
                                                                                    textAlign:
                                                                                        'right',
                                                                                    fontSize:
                                                                                        '0.9rem',
                                                                                },
                                                                            }}
                                                                        />
                                                                    )}
                                                                </TableCell>
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                </TableRow>
                                                {restStockUnitDiscounts.length > 0 &&
                                                    restStockUnitDiscounts.map((sud) => {
                                                        return (
                                                            <TableRow
                                                                key={
                                                                    productDiscount.product.id.toString() +
                                                                    sud.stock_unit.id.toString()
                                                                }
                                                                selected={isSelected}
                                                            >
                                                                <TableCell
                                                                    className={classes.tableCell}
                                                                >
                                                                    <Typography variant="body2">
                                                                        {sud.stock_unit.sku}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell
                                                                    className={classes.tableCell}
                                                                >
                                                                    <TextField
                                                                        value={
                                                                            sud.percentDiscount ||
                                                                            ''
                                                                        }
                                                                        onChange={this.handleDiscountPercentChanged(
                                                                            productDiscount.product
                                                                                .id,
                                                                            sud.stock_unit.sku
                                                                        )}
                                                                        inputProps={{
                                                                            style: {
                                                                                textAlign: 'right',
                                                                                fontSize: '0.9rem',
                                                                            },
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    %
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                {sud.channel_stock_unit_discounts.map(
                                                                    (csud, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <TableCell
                                                                                className={classNames(
                                                                                    classes.tableCell,
                                                                                    classes.originalPriceColumn
                                                                                )}
                                                                            >
                                                                                <Typography variant="body2">
                                                                                    {csud.channel_stock_unit
                                                                                        ? Math.round(
                                                                                              csud
                                                                                                  .channel_stock_unit
                                                                                                  .price
                                                                                          )
                                                                                        : ''}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                className={
                                                                                    classes.tableCell
                                                                                }
                                                                            >
                                                                                {csud.channel_stock_unit && (
                                                                                    <TextField
                                                                                        value={
                                                                                            csud.price
                                                                                                ? Math.round(
                                                                                                      csud.price
                                                                                                  )
                                                                                                : ''
                                                                                        }
                                                                                        onChange={this.handleDiscountPriceChanged(
                                                                                            productDiscount.product,
                                                                                            sud.stock_unit,
                                                                                            csud.shopID
                                                                                        )}
                                                                                        error={Boolean(
                                                                                            csud
                                                                                                .errors
                                                                                                .discountAmount
                                                                                        )}
                                                                                        helperText={
                                                                                            csud
                                                                                                .errors
                                                                                                .discountAmount
                                                                                        }
                                                                                        disabled={
                                                                                            !csud
                                                                                        }
                                                                                        inputProps={{
                                                                                            style: {
                                                                                                textAlign:
                                                                                                    'right',
                                                                                                fontSize:
                                                                                                    '0.9rem',
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </TableCell>
                                                                        </React.Fragment>
                                                                    )
                                                                )}
                                                            </TableRow>
                                                        )
                                                    })}
                                            </React.Fragment>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            className={classes.bottomPagination}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': gettext('Previous'),
                            }}
                            nextIconButtonProps={{
                                'aria-label': gettext('Next'),
                            }}
                            onPageChange={this.handleChangePage}
                            rowsPerPageOptions={[rowsPerPage]}
                        />
                    </Paper>
                )}
                <div className={classes.bottomBar}>
                    <Button component={Link} to={getPath('discounts')} color="primary">
                        <ArrowBackIcon />
                        {gettext('Back to discounts')}
                    </Button>
                    <div className={classes.saveActions}>
                        {this.state.hasErrors && (
                            <Typography className={classes.hasErrorsMessage}>
                                {gettext('Errors')}
                            </Typography>
                        )}
                        {this.state.saved && !this.state.hasErrors && (
                            <Typography className={classes.savedMessage}>
                                {gettext('Saved')}
                            </Typography>
                        )}
                        {this.state.saving && (
                            <CircularProgress className={classes.saveProgress} size={25} />
                        )}
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.saveButton}
                            onClick={this.saveHandler}
                            disabled={
                                (!this.discountComplete() && !isAddingProductsDisabled) ||
                                this.productDiscountHasErrors() ||
                                this.state.saving
                            }
                        >
                            {isCopy ? gettext('Create Copy') : gettext('Save Discount')}
                        </Button>
                    </div>
                </div>
                {this.state.discount.store && (
                    <ProductSelector
                        discountID={this.state.discount.id}
                        startDate={this.state.discount.start_date}
                        endDate={this.state.discount.end_date}
                        display={this.state.displayProductSelector}
                        productDiscounts={this.state.productDiscounts}
                        storeID={this.state.discount.store}
                        handleSelectedProducts={this.handleSelectedProducts}
                        excludedProductIDs={this.getExistedProductIDs()}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Discount)
