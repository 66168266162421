import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { post, put } from '../../tools/request'

const useStyles = makeStyles(() => ({
    dialog: {},
    dialogContent: {
        minHeight: 300,
    },
    dialogActions: {
        paddingRight: 24,
        paddingLeft: 24,
        paddingBottom: 15,
        marginTop: 0,
    },
}))

const CustomerEditor = ({ onClose, inputData, customersAPIURL, customerAPIURL, mode }) => {
    const classes = useStyles()
    const [inputDataState, setInputDataState] = useState({
        address: {},
        ...inputData,
    })
    const [submitting, setSubmitting] = useState(false)

    const handleValueChange = (event) => {
        const { name, value } = event.target

        if (name.startsWith('address.')) {
            setInputDataState({
                ...inputDataState,
                address: {
                    ...inputDataState.address,
                    [name.substr(8)]: value,
                },
            })
        } else {
            setInputDataState({
                ...inputDataState,
                [name]: value,
            })
        }
    }

    const handleClose = () => {
        onClose && onClose()
    }

    const handleSave = (e) => {
        e.preventDefault()

        const { id, address, ...customerData } = inputDataState

        setSubmitting(true)

        const promise =
            mode === 'update_customer'
                ? put(customerAPIURL.replace('customer_pk', id), {
                      name: customerData.name,
                      phone: customerData.phone,
                      email: customerData.email,
                      tax_id_number: customerData.tax_id_number,
                  })
                : post(customersAPIURL, {
                      ...customerData,
                      addresses: [
                          {
                              ...address,
                              is_default_shipping_address: true,
                              is_default_billing_address: true,
                              contact_name: address.contact_name || customerData.name,
                          },
                      ],
                  })

        promise.then((res) => {
            setSubmitting(false)

            if (res.ok) {
                onClose && onClose({ customer: res })
            }
        })
    }

    const inputProps = {
        onChange: handleValueChange,
        variant: 'standard',
        fullWidth: true,
    }

    const showAddressInputs = mode !== 'update_customer'

    return (
        <Dialog open fullWidth maxWidth="sm" className={classes.dialog}>
            <form onSubmit={handleSave}>
                <DialogTitle>
                    {mode === 'update_customer'
                        ? gettext('Update customer')
                        : gettext('Create customer')}
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                {...inputProps}
                                autoFocus
                                label={gettext('Name')}
                                name="name"
                                value={inputDataState.name}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...inputProps}
                                label={gettext('Phone')}
                                name="phone"
                                value={inputDataState.phone}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...inputProps}
                                label={gettext('Email')}
                                type="email"
                                name="email"
                                value={inputDataState.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...inputProps}
                                label={gettext('Tax ID')}
                                name="tax_id_number"
                                value={inputDataState.tax_id_number}
                            />
                        </Grid>
                    </Grid>

                    {showAddressInputs && (
                        <React.Fragment>
                            <Typography variant="subtitle1" style={{ marginTop: 24 }}>
                                {gettext('Default address')}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        {...inputProps}
                                        label={gettext('Address line 1')}
                                        name="address.address1"
                                        value={inputDataState.address.address1}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        {...inputProps}
                                        label={gettext('Address line 2')}
                                        name="address.address2"
                                        value={inputDataState.address.address2}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        {...inputProps}
                                        label={gettext('District')}
                                        name="address.district"
                                        value={inputDataState.address.district}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        {...inputProps}
                                        label={gettext('City')}
                                        name="address.city"
                                        value={inputDataState.address.city}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        {...inputProps}
                                        label={gettext('Province')}
                                        name="address.province"
                                        value={inputDataState.address.province}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        {...inputProps}
                                        label={gettext('Postal code')}
                                        name="address.post_code"
                                        value={inputDataState.address.post_code}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        {...inputProps}
                                        label={gettext('Country')}
                                        name="address.country"
                                        value={inputDataState.address.country}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        {...inputProps}
                                        label={gettext('Phone')}
                                        name="address.phone"
                                        value={inputDataState.address.phone}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        {...inputProps}
                                        label={gettext('Contact name')}
                                        name="address.contact_name"
                                        value={inputDataState.address.contact_name}
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button color="secondary" disabled={submitting} onClick={handleClose}>
                        {gettext('Cancel')}
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={submitting}
                    >
                        {gettext('Save')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default CustomerEditor
