import { z } from 'zod'

import { StockItem } from './stock-item'

import { AssignedMedia } from '~/common/schemas/media'
import { MAX_SKU_LENGTH } from '~/constants'

export const BundledStockUnit = z.object({
    bundled_stock_unit: z.object({
        id: z.number(),
        name: z.string(),
        product: z.object({
            id: z.number().optional(),
            translations: z.record(
                z.string(),
                z.object({
                    name: z.string(),
                })
            ),
        }),
        sku: z.string(),
        status: z.string(),
    }),
    id: z.number().optional(),
    listed_stock_unit: z.number().optional(),
    num_of_units: z.number(),
})
export type BundledStockUnit = z.infer<typeof BundledStockUnit>

const DimensionOption = z.object({
    id: z.number(),
    index: z.number(),
    product_dimension: z.object({
        index: z.number(),
    }),
})

/**
 * This regex is used to validate the SKU field. It allows all unicode characters
 * and the following special characters: -_"&(),.+!/
 * */
const skuRegex = new RegExp(/^[\p{L}\p{Mn}\p{Lo}\p{Zs}\p{N}\-_"&(),.+!/]+$/u)

export const StockUnit = z.object({
    dimension_options: DimensionOption.array(),
    id: z.number(),
    images: AssignedMedia.extend({ stock_unit: z.number() }).array(),
    product: z.number(),
    sku: z
        .string()
        .min(1, gettext('SKU is required'))
        .max(MAX_SKU_LENGTH, gettext(`SKU must have less than ${MAX_SKU_LENGTH} characters`))
        .regex(skuRegex, gettext('Invalid SKU')),
    status: z.enum(['active', 'inactive', 'deleted']).optional(),
    stock_item: StockItem,
})
export type StockUnit = z.infer<typeof StockUnit>
